import React from "react";
import CITIES from "../../../../../static/Shared/cities";
import {Autocomplete} from "../../Autocomplete";

const CitySelect = ({countryCode, stateCode, filteredCityNames, ...props}) => {
  let options =
    countryCode && stateCode
      ? CITIES.filter(
          (city) =>
            city.country_code === countryCode && city.state_code === stateCode
        )
      : [];

  options = filteredCityNames
    ? options.filter((option) => !filteredCityNames.includes(option.name))
    : options;

  return (
    <Autocomplete
      options={options.map((option) => ({
        id: option.state_code,
        text: option.name,
      }))}
      placeholder="Select City"
      {...props}
    />
  );
};

export default CitySelect;
