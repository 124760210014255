import {SwitchButton} from "../../../../Layout/Shared/SwitchButtonBlue";
import {GridTwoColumn} from "../../../../Layout/Shared/GridTwoColumn";
import IconPhoneBlue from "../../../../../assets/images/icon-phone.jpg";
import PhoneInput from "react-phone-input-2";

export const Calls = ({phoneNumber, status, onChange}) => {
  return (
    <GridTwoColumn>
      <div>
        <h4 className="font-weight-bold pb-2">Calls </h4>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et.
        </p>
        <div className="d-flex justify-content-start align-items-center">
          <img src={IconPhoneBlue} alt="" />
          <PhoneInput
            value={phoneNumber}
            disabled={true}
            inputClass={"readOnlyPhoneInput"}
            buttonClass={"readOnlyPhoneInputButton"}
          />
        </div>
      </div>
      <div>
        <SwitchButton status={status} onChange={onChange} />
      </div>
    </GridTwoColumn>
  );
};
