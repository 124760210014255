import React from "react";
import {SelectBoxBlue} from "../../../components/Layout/Shared/SelectBoxBlue";
import {list} from "../../../data/Contact/Business/list";
import {getMembers} from "../../../data/Contact/Business/Group/getMembers";
import {exportToCSV, exportToXLSX} from "../../../utils/export";

export const ExportContact = ({groupId}) => {
  const getContacts = async () => {
    const {data, hasSuccess} = groupId
      ? await getMembers(groupId)
      : await list();
    return hasSuccess ? data : [];
  };

  const handleChange = async ({target}) => {
    const contacts = await getContacts();
    if (target.value === "csv") {
      exportToCSV(contacts);
    } else if (target.value === "xlsx") {
      exportToXLSX(contacts);
    }
  };
  return (
    <>
      <SelectBoxBlue width="220px" height="44px" onChange={handleChange}>
        <option value="-1">Export Contact List</option>
        <option value="csv">Export Contact List .csv</option>
        <option value="xlsx">Export Contact List .xlsx</option>
        ))
      </SelectBoxBlue>
    </>
  );
};
