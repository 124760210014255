import React from "react";

const ChatIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3.28516C16.695 3.28516 20.52 6.66016 20.52 10.7852C20.52 14.9102 16.695 18.2852 12 18.2852C11.0371 18.3023 10.0784 18.1552 9.16498 17.8502L8.03998 17.4602L7.19998 18.3002C6.2734 19.1655 5.26917 19.9438 4.19998 20.6252C4.16564 20.6348 4.12932 20.6348 4.09498 20.6252V20.5352C4.88722 18.4756 4.96643 16.21 4.31998 14.1002C3.81625 13.0917 3.53491 11.9868 3.49498 10.8602C3.49498 6.70516 7.31998 3.36016 12.015 3.36016M12 1.41016C6.25498 1.41016 1.60498 5.62516 1.60498 10.8152C1.63886 12.1885 1.96618 13.5387 2.56498 14.7752C3.03988 16.4988 2.94517 18.3297 2.29498 19.9952C2.16625 20.3884 2.16394 20.812 2.28837 21.2066C2.4128 21.6012 2.65771 21.9469 2.98871 22.1952C3.31971 22.4434 3.72016 22.5818 4.13382 22.5907C4.54747 22.5997 4.95354 22.4788 5.29498 22.2452C6.45172 21.4918 7.53634 20.6332 8.53498 19.6802C9.65 20.0594 10.8224 20.2421 12 20.2202C17.745 20.2202 22.395 16.0052 22.395 10.8152C22.395 5.62516 17.745 1.41016 12 1.41016Z"
        fill="#BBBBBB"
      />
      <path
        d="M7.8001 12.6758C8.46284 12.6758 9.0001 12.1721 9.0001 11.5508C9.0001 10.9295 8.46284 10.4258 7.8001 10.4258C7.13736 10.4258 6.6001 10.9295 6.6001 11.5508C6.6001 12.1721 7.13736 12.6758 7.8001 12.6758Z"
        fill="#BBBBBB"
      />
      <path
        d="M12 12.6758C12.6628 12.6758 13.2 12.1721 13.2 11.5508C13.2 10.9295 12.6628 10.4258 12 10.4258C11.3373 10.4258 10.8 10.9295 10.8 11.5508C10.8 12.1721 11.3373 12.6758 12 12.6758Z"
        fill="#BBBBBB"
      />
      <path
        d="M16.2 12.6758C16.8627 12.6758 17.4 12.1721 17.4 11.5508C17.4 10.9295 16.8627 10.4258 16.2 10.4258C15.5373 10.4258 15 10.9295 15 11.5508C15 12.1721 15.5373 12.6758 16.2 12.6758Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export default ChatIcon;
