import React, {useState} from "react";
import * as Yup from "yup";
import ErrorField from "../../../../../Layout/Shared/ErrorField";
import {checkExistingEmail} from "../../../../../../data/Register/checkExistingEmail";

export const NewStaff = ({id, setValue, showHeaders, roles, groups, isInUse}) => {
  const [isValid, setIsValid] = useState(true);
  const [emailChecked, setEmailChecked] = useState(false);
  const [isEmailInUse, setIsEmailInUse] = useState(false);
  const emailSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const handleEmailChange = ({value}) => {
    emailSchema.isValid({email: value}).then((valid) => {
      setIsValid(valid);
      setValue(id, "email", valid ? value : "");
    });
    checkExistingEmail({email: value}).then((response) => {
      const data = response.isInUse
      setIsEmailInUse(data);
      isInUse(data);
      setEmailChecked(true);
    });
  };

  return (
    <div className="add-user-form-rows">
      <div>
        {showHeaders && (
          <div className="d-block font-weight-bold text-primary-blue pb-3 pl-0">
            Email Address
          </div>
        )}
        <div className="form-group mt-1 mb-4">
          <input
            type="email"
            className="custom-input"
            placeholder="email address"
            onChange={({target}) => handleEmailChange(target)}
          />
          {!isValid && <ErrorField message={"Invalid email address"} />}
          {emailChecked && isEmailInUse && (
            <ErrorField message={"This email is already registered."} />
          )}
        </div>
      </div>
      <div>
        {showHeaders && (
          <div className="d-block font-weight-bold text-primary-blue pb-3 pl-0">
            Designation / Role
          </div>
        )}
        <div className="form-group mt-1 mb-4">
          <select
            className="primary-select"
            onChange={({target}) => setValue(id, "role", target.value)}
            defaultValue="-1"
          >
            <option value="-1" disabled>
              Select Designation / Role
            </option>
            {roles.map((option) => (
              <option value={option._id}>{option.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        {showHeaders && (
          <div className="d-block font-weight-bold text-primary-blue pb-3 pl-0">
            Group / Team
          </div>
        )}
        <div className="form-group mt-1 mb-4">
          <select
            className="primary-select"
            onChange={({target}) => setValue(id, "group", target.value)}
            defaultValue="-1"
          >
            <option value="-1">Select Group / Team</option>
            {groups.map((option) => (
              <option value={option._id}>{option.name}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
