import React from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {InputGroup, RadiusInput} from "../Base/BaseStyles";

export function PlaceholderOption({component_key, dispatch, attributes}) {
  const input_text_placeholder = getAttribute("placeholder", attributes);
  return (
    <InputGroup className="input-group mt-3">
      <RadiusInput
        type="text"
        className="form-control"
        aria-label=""
        placeholder="Enter placeholder"
        value={input_text_placeholder}
        onChange={(event) =>
          dispatch({
            type: "placeholder_change",
            placeholder: event.target.value,
            key: component_key,
          })
        }
      />
    </InputGroup>
  );
}

export default PlaceholderOption;
