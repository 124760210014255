import React from "react";
import {FormComponentTypes} from "./FormEditReducerTypes";
import {
  AddElementDescription,
  AddElementTitle,
  DashedRectangle,
  EmptyComponentsTitle,
} from "./FormEditStyles";
import {getAttribute, sortComponents} from "./FormEditReducerHelpers";
import BaseIconsHover from "../Components/Base/BaseIconsHover";
import BaseIconsEdit from "../Components/Base/BaseIconsEdit";
import Base from "../Components/Base/Base";

export function checkSelectedColumnSize(attributes) {
  let selected_column_size = "";
  try {
    selected_column_size = getAttribute("selected_column_size", attributes);
  } catch (e) {
    selected_column_size = "1/1";
  }
  return selected_column_size;
}

function getComponents(components, dispatch) {
  const parsedComponents = [];
  const sortedComponents = sortComponents(components);
  for (const component of sortedComponents) {
    const ParsedComponentType = FormComponentTypes[component.type];
    const component_props = {
      attributes: component.attributes,
      component_key: component.key,
      dispatch: dispatch,
    };
    parsedComponents.push(
      <Base
        key={component.key + "_base"}
        title={component.title}
        component={
          <ParsedComponentType {...component_props} key={component.key} />
        }
        icons={
          getAttribute("confirmed", component.attributes) === "true" ? (
            <BaseIconsHover {...component_props} />
          ) : (
            <BaseIconsEdit {...component_props} valid={component.valid} />
          )
        }
        confirmed={getAttribute("confirmed", component.attributes)}
        dispatch={dispatch}
        component_key={component.key}
        hover={getAttribute("hover", component.attributes)}
        column_size={checkSelectedColumnSize(component.attributes)}
      />
    );
  }
  return parsedComponents;
}

export function Components({components, dispatch}) {
  //const [animationParent] = useAutoAnimate();
  const components_length = components.length;
  return (
    <div>
      {components_length ? (
        <div className="row">{getComponents(components, dispatch)}</div>
      ) : (
        <ul></ul>
      )}
      {!components_length && (
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <EmptyComponentsTitle className="d-flex justify-content-center">
              Start building your form
            </EmptyComponentsTitle>
            <DashedRectangle>
              <div className="p-5 d-flex flex-column">
                <AddElementTitle className="d-flex justify-content-center">
                  Add Elements
                </AddElementTitle>
                <AddElementDescription className="d-flex justify-content-center">
                  Click on elements from the left panel to create your form
                </AddElementDescription>
              </div>
            </DashedRectangle>
          </div>
          <div className="col-md-2"></div>
        </div>
      )}
    </div>
  );
}

export default Components;
