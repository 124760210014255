import React, {useEffect, useState} from "react";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import Header from "../../../components/Layout/Header/Header";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import FormTemplates from "../../../components/Form/Templates/templates.component";
import {getAllTemplateCategories} from "../../../data/Templates/getAllCategories";
import {TemplateTableLoader} from "../../../components/Layout/Shared/DataLoading/table-loader.component";

const FormTemplatesPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const init = async () => {
      const {data: response, hasSuccess} = await getAllTemplateCategories();
      hasSuccess && setCategories(response?.data);
      setIsLoading(false);
    };
    init();
  }, []);

  return (
    <section className="dashboard  bg-paleGray pb-5 pe-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>TEMPLATE CATEGORIES</PageTitle>
            </PageHeader>
            {isLoading ? (
              <TemplateTableLoader />
            ) : (
              <FormTemplates templates={categories} />
            )}
          </PageContainer>
        </div>
      </div>
    </section>
  );
};

export default FormTemplatesPage;
