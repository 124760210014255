import React, {useState, useEffect} from "react";
import {Formik, Form, Field} from "formik";
import {ServiceList} from "../../../Layout/Shared/ServiceList";
import {CustomButton} from "../../../Layout/Shared/Button";
import {LabelBlueBold} from "../../../Layout/Shared/LabelBlueBold";
import {useHistory, useParams} from "react-router";
import {InnerPageLeft} from "../../../../components/Layout/Shared/InnerPageLeft";
import {ContactCard} from "../../../../components/Contacts/Update/ContactCard";
import {getById} from "../../../../data/Contact/Business/getById";
import DataLoading from "../../../Layout/Shared/DataLoading";
import ErrorField from "../../../Layout/Shared/ErrorField";
import PhoneInput from "react-phone-input-2";
import {designations} from "../../../../static/Onboarding/business";
import {BusinessContactUpdateSchema} from "../../../../schemas/Contact/Business/update";
import {update} from "../../../../data/Contact/Business/update";
import {CountryInput} from "../../../Layout/Shared/CountryInput";
import "../../Create/index.scss";

export const EditContactDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {contactId} = useParams();
  const history = useHistory();
  const goToContactList = () => history.push(`/business/contact/list`);
  const [contact, setContact] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [groups, setGroups] = useState([]);
  const [photoUrl, setPhotoUrl] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const init = async () => {
      const {data, hasSuccess} = await getById(contactId);
      if (hasSuccess) {
        setContact(data);
        setIndustries(data.industries);
        setGroups(data.groups);
        setPhotoUrl(data.photoUrl);
        setIsFavorite(data.isFavorite);
      }
      setIsLoading(false);
    };
    contactId ? init() : setIsLoading(false);
  }, [contactId]);

  const validateForm = async (form) => {
    try {
      await BusinessContactUpdateSchema.validate(form);
    } catch (error) {
      return error;
    }
  };

  const getGroupIds = () => groups.map((group) => group._id);

  const handleSubmit = async () => {
    const validationResponse = await validateForm(contact);
    if (validationResponse && validationResponse.errors) {
      setError(validationResponse.errors.shift());
      return;
    }
    setIsLoading(true);
    const updateContact = {
      ...contact,
      industries,
      photoUrl,
      isFavorite,
      id: contact._id,
      groups: getGroupIds(),
    };
    const {hasSuccess} = await update(updateContact);
    if (hasSuccess) {
      setIsLoading(false);
      goToContactList();
    }
  };

  return isLoading ? (
    <DataLoading height="700px" />
  ) : (
    <div className="row m-2  bg-white border rounded-xl">
      <div className="col-md-12 p-5 border-bottom"></div>
      <div className="contact-left border-end">
        <InnerPageLeft>
          <ContactCard
            id={contact._id}
            fullName={`${contact.firstName} ${contact.lastName}`}
            created_at={contact.created_at}
            photoUrl={photoUrl}
            setPhotoUrl={setPhotoUrl}
            groups={groups}
            setGroups={setGroups}
            isFavorite={isFavorite}
            setIsFavorite={setIsFavorite}
            setIsMainLoading={setIsLoading}
          />
        </InnerPageLeft>
      </div>
      <div className="contact-right pl-0 pe-5  pt-5">
        <div className="row pb-5 add-contact-page">
          <div className="page-left ps-5 mb-5">
            <EditContactInformation
              contact={contact}
              setContact={setContact}
              error={error}
            />
          </div>
          <div className="page-right   pt-5 mt-5 ps-5 mb-5">
            <LabelBlueBold content="Select Services Category" />
            <ServiceList
              view={true}
              selectedIndustries={industries}
              setSelectedIndustries={setIndustries}
              isService={true}
            />
            <div className="col-12 mt-5 button-wrapper">
              <button
                className="btn btn-link font-weight-bold me-5 "
                onClick={goToContactList}
              >
                Cancel
              </button>
              <CustomButton
                title="Save Changes"
                size="189px"
                color="blue"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EditContactInformation = ({contact, setContact, error}) => {
  return (
    <div className="pl-4">
      <h2>Contact Details</h2>
      <div>
        <Formik
          enableReinitialize
          initialValues={contact}
          validateOnBlur={false}
        >
          {({values}) => (
            <>
              {setContact(values)}
              <Form>
                <div className="mt-5">
                  <LabelBlueBold content="Contact Name" />
                  <div className="d-flex justify-content-between">
                    <Field
                      className="custom-input me-2"
                      placeholder="First Name"
                      name="firstName"
                    />
                    <Field
                      className="custom-input ms-2 "
                      placeholder="Last Name"
                      name="lastName"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <LabelBlueBold content="Designation" />
                  <Field name="designation">
                    {({field: {value}, form: {setFieldValue}}) => (
                      <select
                        className="primary-select"
                        placeholder="Please select designation"
                        defaultValue={value}
                        onChange={({target}) =>
                          setFieldValue("designation", target.value)
                        }
                      >
                        <option value="-1" disabled>
                          Please select designation
                        </option>
                        {designations
                          .filter((designation) => designation.isContact)
                          .map((designation) => (
                            <option
                              key={designation.name}
                              value={designation.name}
                            >
                              {designation.name}
                            </option>
                          ))}
                      </select>
                    )}
                  </Field>
                </div>
                <div className="mt-4">
                  <LabelBlueBold content="Email Address" />
                  <Field
                    className="custom-input"
                    placeholder="Enter Email Address"
                    name="email"
                  />
                </div>
                <div className="mt-4 custom-phone">
                  <LabelBlueBold content="Mobile Number" />
                  <Field name="phone">
                    {({field: {value}, form: {setFieldValue}}) => (
                      <PhoneInput
                        enableSearch
                        placeholder="Enter Mobile Number"
                        country={"ca"}
                        value={value}
                        onChange={(number) => setFieldValue("phone", number)}
                      />
                    )}
                  </Field>
                </div>
                <div className="mt-4">
                  <LabelBlueBold content="Contact Address" />
                  <Field
                    className="custom-input"
                    placeholder="Client Address"
                    name="address"
                  />
                  <div className="d-flex justify-content-between mt-5">
                    <Field
                      className="custom-input me-2"
                      placeholder="City"
                      name="city"
                    />
                    <Field
                      className="custom-input ms-2 "
                      placeholder="State"
                      name="state"
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-5">
                    <Field name="country" className="custom-input ms-2 ">
                      {({field: {value}, form: {setFieldValue}}) => (
                        <CountryInput
                          name="country"
                          className="custom-input me-2"
                          placeholder="Country"
                          onSelect={({text}) => setFieldValue("country", text)}
                          defaultValue={value}
                        />
                      )}
                    </Field>
                    <div className="w-100 ms-2 ">
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Zip Code"
                        name="zipCode"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
        {error && <ErrorField message={JSON.stringify(error)} />}
      </div>
    </div>
  );
};
