/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from "react";
import {Formik, Form, Field} from "formik";
import PhoneInput from "react-phone-input-2";
import LocationImage from "../../../../assets/images/locationImage.png";
import steps from "../steps";
import BusinessOnboardingContext from "../../../../providers/Onboarding/business";
import {BusinessInformationSchema} from "../../../../schemas/Onboarding/Business/businessInformation";
import ErrorField from "../../../../components/Layout/Shared/ErrorField";

const BusinessInformations = ({goTo}) => {
  const {businessInformations} = useContext(BusinessOnboardingContext);
  const {businessInformation, setBusinessInformation} = businessInformations;

  const onSubmit = (values, {setSubmitting}) => {
    setBusinessInformation(values);
    setSubmitting(false);
    goTo(steps.YOURINFORMATION);
  };

  const handlePrev = () => goTo(steps.INDUSTRYSELECTION);

  return (
    <div className="tab active" id="BusinessIformation">
      <div className="row">
        <div className="col-xl-6  col-lg-6  pl-0">
          <div className="component first">
            <h2>
              <strong>Speak with service providers</strong>
            </h2>
            <div>
              <Formik
                initialValues={businessInformation}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={BusinessInformationSchema}
                onSubmit={onSubmit}
              >
                {({errors}) => (
                  <Form>
                    <div className="form-group">
                      <label>
                        <strong>Business Address</strong>
                      </label>
                      <Field
                        className="custom-input"
                        placeholder="Street Address"
                        name="address"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between">
                      <Field
                        className="custom-input w-50"
                        placeholder="City"
                        name="city"
                      />
                      <Field
                        className="custom-input w-50"
                        placeholder="State"
                        name="state"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-between">
                      <Field
                        className="custom-input w-50"
                        placeholder="Country"
                        name="country"
                      />
                      <Field
                        className="custom-input w-50"
                        placeholder="Zip Code"
                        name="zipCode"
                      />
                    </div>
                    <div className="form-group  custom-phone">
                      <label>
                        <strong>Phone Number</strong>
                      </label>
                      <Field name="phone">
                        {({field: {value}, form: {setFieldValue}}) => (
                          <PhoneInput
                            enableSearch
                            placeholder="Enter Mobile Number"
                            country={"ca"}
                            value={value}
                            onChange={(number) =>
                              setFieldValue("phone", number)
                            }
                          />
                        )}
                      </Field>
                      <ErrorField message={errors.address} />
                      <ErrorField message={errors.city} />
                      <ErrorField message={errors.state} />
                      <ErrorField message={errors.country} />
                      <ErrorField message={errors.zipCode} />
                    </div>
                    <div className="form-group d-flex justify-content-end align-items-center">
                      <a
                        href="javascript:void(0)"
                        className="btn-text-blue me-5 "
                        onClick={handlePrev}
                      >
                        Previous
                      </a>

                      <button
                        type="submit"
                        className="mt-0 ms-5  btn btn-blue-gradient btn-steps"
                      >
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-xl-6  col-lg-6 d-none d-md-none d-lg-none d-xl-block text-end">
          <div className="component second">
            <img src={LocationImage} alt="" className="pt-5 mt-5 img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInformations;
