import React, {Fragment} from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";

import {
  CheckboxLabel,
  FormCheck,
  OptionsInputGroup,
  RequireQuestionTextArea,
} from "../Base/BaseStyles";

export function RequireOption({component_key, dispatch, attributes}) {
  const require_text = getAttribute("require_text", attributes);
  const require = getAttribute("require", attributes);
  const checkValue = require === "true";
  return (
    <Fragment>
      <OptionsInputGroup className="input-group">
        <FormCheck className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            defaultChecked={checkValue}
            onChange={(event) =>
              dispatch({
                type: "require_change",
                require: !checkValue ? "true" : "false",
                key: component_key,
              })
            }
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            <CheckboxLabel>Require an answer to this question</CheckboxLabel>
          </label>
        </FormCheck>
      </OptionsInputGroup>
      {require === "true" ? (
        <OptionsInputGroup className="input-group">
          <RequireQuestionTextArea
            className="form-control"
            id="exampleFormControlTextarea1"
            placeholder="This question requires an answer."
            value={require_text}
            onChange={(event) =>
              dispatch({
                type: "require_text_change",
                require_text: event.target.value,
                key: component_key,
              })
            }
          ></RequireQuestionTextArea>
        </OptionsInputGroup>
      ) : (
        <OptionsInputGroup className="input-group"></OptionsInputGroup>
      )}
    </Fragment>
  );
}

export default RequireOption;
