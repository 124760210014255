import React, {useContext, useState} from "react";
import Dropzone from "react-dropzone-uploader";
import BusinessServiceContext from "../../../../../providers/Service/business";
import {buildUploadRequest} from "../../../../../data/Service/Business/uploadAttachment";
import {AttachmentsList} from "./list";
import "react-dropzone-uploader/dist/styles.css";
import "../../../../../styles/customComponents/customUploader.scss";

const Attachments = () => {
  const {
    values: {serviceId},
  } = useContext(BusinessServiceContext);
  const [renderCount, setRenderCount] = useState(0);

  const getUploadParams = async ({meta, file}) =>
    await buildUploadRequest(file, meta, serviceId);

  const handleChangeStatus = ({meta, remove}, status) => {
    if (status === "headers_received") {
      console.log(`${JSON.stringify(meta)} uploaded!`);
      remove();
      setRenderCount(renderCount + 1);
    } else if (status === "aborted") {
      console.log(`${meta.name}, upload failed...`);
    } else {
      console.log(`status: ${status}, name: ${meta.name}`);
    }
  };

  return (
    <section className="p-5">
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={true}
        inputContent={
          <span className="upload-files">
            Drop your file here or <span className="text-primary">browse</span>
          </span>
        }
        accept="image/*,.mp4,.pdf,.docx,.doc"
      />
      <AttachmentsList refresh={renderCount} serviceId={serviceId} />
    </section>
  );
};

export default Attachments;
