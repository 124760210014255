import React, {Fragment} from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import InputTextEdit from "./InputTextEdit";
import InputTextHover from "./InputTextHover";

export function InputText({component_key, attributes, dispatch}) {
  const confirmed = getAttribute("confirmed", attributes);
  return (
    <Fragment>
      {confirmed === "true" ? (
        <InputTextHover
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      ) : (
        <InputTextEdit
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      )}
    </Fragment>
  );
}

export default InputText;
