import React, { useEffect, useState } from 'react'
import { PageContainer } from '../../../components/Layout/Container/PageContainer'
import Header from '../../../components/Layout/Header/Header'
import { GenericPageLeft } from '../../../components/Layout/Shared/GenericPageLeft'
import { PageHeader } from '../../../components/Layout/Shared/PageHeader'
import { PageTitle } from '../../../components/Layout/Shared/PageTitle'
import Forms from '../../../components/Form/List/list.component'
import { getAllForms } from '../../../data/Forms/getAllForms'
import { TemplateTableLoader } from '../../../components/Layout/Shared/DataLoading/table-loader.component'

const ListFormPage = () => {
  const [renderCount, setRenderCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [forms, setForms] = useState([])

  useEffect(() => {
    getInitialData()
  }, [renderCount])

  const getInitialData = async () => {
    setIsLoading(true)
    const { data: response, hasSuccess } = await getAllForms()

    hasSuccess
      ? setForms(response)
      : console.log(JSON.parse(response).description)
    setIsLoading(false)
  }

  return (
    <section className="dashboard  bg-paleGray pb-5 pe-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>ALL FORMS</PageTitle>
            </PageHeader>

            {isLoading ? (
              <TemplateTableLoader />
            ) : (
              <Forms
                forms={forms?.data}
                refreshPage={() => setRenderCount(renderCount + 1)}
              />
            )}
          </PageContainer>
        </div>
      </div>
    </section>
  )
}

export default ListFormPage
