import styled from "styled-components";

export const StyledHeader = styled.header`
  flex: 1;
  display: flex;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #dee2e0;
  margin-bottom: 1.5rem;
  position: sticky;
  top: 61px;
  background-color: #fff;
  z-index: 99;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledLeftSide = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const StyledRightSide = styled.div`
  display: flex;
  button {
    min-width: 80px;
    width: auto;
    padding: 0 1.2rem;
    margin-left: 1rem;
  }
  *:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const StyledButton = styled.button`
  border-radius: 8px;
  background-color: #fff;
  border: solid 1px #dee2e6 !important;
  font-weight: 600;
  font-size: 14px;
  color: #495057;
  height: 44px;
`;
