import Fuse from "fuse.js";

export const attempSearch = (data, fields, term) =>
  new Fuse(data, {
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    keys: fields.filter((i) => typeof i !== "object"),
  })
    .search(term)
    .map((res) => res.item);

export const searchByField = (data, field, value) =>
  data.filter((item) =>
    `${item[field]}`.toLowerCase().includes(`${value}`.toLowerCase())
  );
