import React from "react";

const IconDocument = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24" 
      height="24"
      fill="#1b84e7" 
    >
      <path d="M 3 0 L 3 24 L 21 24 L 21 7 L 14 0 Z M 5 2 L 13 2 L 13 8 L 19 8 L 19 22 L 5 22 Z M 8 11 L 8 12 L 14 12 L 14 11 Z M 8 14 L 8 15 L 16 15 L 16 14 Z M 8 17 L 8 18 L 14 18 L 14 17 Z" />
    </svg>
  );
};

export default IconDocument;