import "./index.scss";
export const ErrorPage = ({code, message}) => {
  return (
    <div className="error-wrapper">
      <span>
        {code}
        <small>{message}</small>
      </span>
    </div>
  );
};
