import React from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./index.scss";
import {
  ComponentsContainer,
  LargeKeyValue,
  MiniKeyValue,
  MiniKeyValueRow,
  Title,
  Value
} from "./index.styled-components";
import { getFullNameFromObject } from "../../../../../utils/string";

const KeyValueComponent = ({ title, value, valueColor, valueBold }) => {
  return (
    <MiniKeyValue>
      <Title>{title}</Title>
      <Value color={valueColor} bold={valueBold}>
        {value}
      </Value>
    </MiniKeyValue>
  );
};

const ReadOnlySummary = ({ caseManagementCase, summary }) => {
  const createdByFullName = getFullNameFromObject(caseManagementCase.createdBy);
  const assignedToFullName = getFullNameFromObject(
    caseManagementCase.assignedTo
  );

  const toFormattedDate = dateAsString => {
    const date = new Date(dateAsString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  };

  const priorityColor = !caseManagementCase.priority
    ? ""
    : caseManagementCase.priority.toLowerCase() === "low"
    ? "rgb(255 193 8)"
    : caseManagementCase.priority.toLowerCase() === "medium"
    ? "rgb(13 110 253)"
    : caseManagementCase.priority.toLowerCase() === "high"
    ? "rgb(220 53 69)"
    : "";

  const statusColor = !caseManagementCase.status
    ? ""
    : caseManagementCase.status.toLowerCase() === "not started"
    ? "rgb(255 193 8)"
    : caseManagementCase.status.toLowerCase() === "completed"
    ? "rgb(25 135 84)"
    : caseManagementCase.status.toLowerCase() === "in progress"
    ? "rgb(13 110 253)"
    : "";

  return (
    <section className="p-5">
      <div>
        <ComponentsContainer>
          <MiniKeyValueRow>
            <KeyValueComponent
              title="CASE NO."
              value={`#${caseManagementCase.no}`}
              valueColor={"#1B83E7"}
              valueBold={true}
            />
            <KeyValueComponent title="TITLE" value={caseManagementCase.title} />
            <KeyValueComponent
              title="STATUS"
              value={caseManagementCase.status}
              valueColor={statusColor}
              valueBold={true}
            />
            <KeyValueComponent
              title="STAGE"
              value={caseManagementCase.stage?.title ?? "N/A"}
            />
            <KeyValueComponent
              title="PRIORITY"
              value={caseManagementCase.priority ?? "N/A"}
              valueColor={priorityColor}
              valueBold={true}
            />
          </MiniKeyValueRow>
          <MiniKeyValueRow>
            <KeyValueComponent
              title="TYPE"
              value={caseManagementCase.type?.title ?? "N/A"}
            />
            <KeyValueComponent title="CREATED BY" value={createdByFullName} />
            <KeyValueComponent title="ASSIGNED TO" value={assignedToFullName} />
            <KeyValueComponent
              title="START DATE"
              value={toFormattedDate(caseManagementCase.startDate)}
            />
            <KeyValueComponent
              title="DUE DATE"
              value={toFormattedDate(caseManagementCase.dueDate)}
            />
          </MiniKeyValueRow>
          <LargeKeyValue>
            <Title>SUMMARY</Title>
            <Value>{summary}</Value>
          </LargeKeyValue>
        </ComponentsContainer>
      </div>
    </section>
  );
};

export default ReadOnlySummary;
