import styled from "styled-components";
import * as theme from "../../../../../../../styles/theme"

export const StyledContainer = styled.div`
    border-radius: 8px;
    border: 1px solid rgb(222, 226, 230);
    padding: 0.7em 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
`;

export const StyledTitle = styled.h3`
    font-size: 14px;
    font-weight: 600;
    margin: 0;
`

export const StyledList = styled.ul``

export const StyledListItem = styled.li`
    padding: 0.2em 0;
`

export const StyledItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1em;
`

export const StyledItemActions = styled.div``

export const StyledItemActionButton = styled.button`
    background-color:transparent;
    color:${theme.azul}
`

export const StyledItemActionIcon = styled.i``