import React from "react";
import Modal from "../../../Layout/Shared/Modal";
import "../../../../styles/customComponents/shared.scss";
import "../index.scss";
import {CustomSelectFile} from "../../../Layout/Shared/SelectFile";

const buttonAddGroup = (
  <button className="btn btn-white-border-black ms-5 ">Add to Group</button>
);

export const ImportContact = ({onClose, onConfirm, file, setFile}) => {
  const handleFile = async ({files}) => setFile(files[0]);
  return (
    <Modal
      title="Import Contact"
      width="718px"
      height="378px"
      extra={buttonAddGroup}
      close={() => {
        onClose();
      }}
    >
      <div>
        <p className="mt-5 mb-5">
          To import contacts, select a CSV, a vCard or XLS files from your local
          drive.
        </p>

        <div className="pt-3 d-flex justify-content-start align-items-center">
          <CustomSelectFile
            id="contactsFile"
            accept=".xlsx, .xls, .csv, .vcf"
            onChange={(e) => handleFile(e.target)}
          />
          {file && (
            <div className="selected-file-label ms-5 ">
              <span>{file.name}</span>
            </div>
          )}
        </div>
        <div className="mt-5">
          No CSV or vCard file? <a href="create">Create Contact</a> instead.
        </div>
        <div className="d-flex justify-content-end align-items-center mt-4">
          <button
            onClick={() => {
              onClose();
            }}
            className="btn btn-text-blue me-4 "
          >
            Cancel
          </button>
          <button
            className="btn text-muted"
            onClick={() => {
              onClose();
              onConfirm();
            }}
            disabled={!file}
          >
            Import
          </button>
        </div>
      </div>
    </Modal>
  );
};
