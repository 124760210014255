import styled, {css} from "styled-components";

export const StyledHeading = styled.h1`
  ${({as}) => css`
    font-weight: 600;
    font-size: 26px;
    color: #495057;
    ${as === "h2" && "font-size: 22px;"}
    ${as === "h3" && "font-size: 18px;"}
    ${as === "h4" && "font-size: 16px;"}
    ${as === "h5" && "font-size: 14px;"}
    ${as === "h6" && "font-size: 12px;"}
  `}
`;
