import React from "react";
import Modal from "../../../Layout/Shared/Modal";
import "../../../../styles/customComponents/shared.scss";
import "../index.scss";
import SuccessBlue from "../../../../assets/images/big-success-blue.png";

export const ImportSuccess = ({ onClose, onConfirm, refresh }) => {
  return (
    <Modal
      title="Migrate Client from Contacts"
      width="718px"
      height="430px"
      close={() => {
        onClose();
        refresh();
      }}
    >
      <div className="d-flex align-items-center flex-column">
        <div>
          <img src={SuccessBlue} alt="" />
        </div>
        <span className="h1 mt-5 text-primary-blue">
          <strong>SUCCESS!</strong>
        </span>
        <p className="mt-5">Your invitations were sent successfully. </p>
        <button
          className="mt-3 btn-blue btn-small"
          onClick={() => {
            onClose();
            onConfirm();
            refresh();
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
