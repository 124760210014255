import React from "react";
import {BusinessProfile} from "./Profile/BusinessProfile";
import {OwnerProfile} from "./Profile/OwnerProfile";
import {Billing} from "./Account/Billing";
import {ChangePassword} from "./Account/ChangePassword";
import {Subscription} from "./Account/Subscription";
import {DurationToggle} from "./Account/Subscription/DurationToggle";
import {Invoice} from "./Account/Invoice";
import {CurrentTeam} from "./Team/CurrentTeam";
import {InvitedStaff} from "./Team/InvitedStaff";
import {Groups} from "./Team/Groups";
import {RolesAndPermissions} from "./Team/RolesAndPermissions";
import {MyProfile} from "./Profile/MyProfile";

export const profileTabs = [
  {
    name: "Business Profile",
    component: <BusinessProfile />,
    isDefault: true,
  },
  {
    name: "Owner Profile",
    component: <OwnerProfile />,
    isStaff: false,
    isDefault: true,
  },
  {
    name: "My Profile",
    component: <MyProfile />,
    isStaff: true,
  },
];
export const accountTabs = [
  {
    name: "Subscription",
    component: Subscription,
    headerActions: <DurationToggle />,
    isDefault: true,
  },
  {
    name: "Billing Preferences",
    component: Billing,
  },
  {
    name: "Invoices & Receipts",
    component: Invoice,
  },
  {
    name: "Change Password",
    component: ChangePassword,
  },
];
export const teamTabs = [
  {
    name: "Current Team",
    component: <CurrentTeam />,
    isDefault: true,
  },
  {
    name: "Invited Staff",
    component: <InvitedStaff />,
  },
  {
    name: "Groups",
    component: <Groups />,
  },
  {
    name: "Roles & Permissions",
    component: <RolesAndPermissions />,
  },
];

export const defaultProfileTab = profileTabs.find((tab) => tab.isDefault);
export const defaultAccountTab = accountTabs.find((tab) => tab.isDefault);
export const defaultTeamTab = teamTabs.find((tab) => tab.isDefault);
