import {Link} from "react-router-dom";
import styled, {css} from "styled-components";

export const StyledDropdownAction = styled.div`
  ${({isOpen}) => css`
    position: relative;
    ${isOpen
      ? css`
          border: 1px solid #1b84e7;
          border-radius: 20px 20px 0 0;
          border-bottom: 0;
        `
      : css`
          border: 1px solid #dee2e6;
          border-radius: 20px;
        `}
  `}
`;

export const StyledActionButton = styled.button`
  ${({isOpen}) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 1rem;
    width: 120px;
    border-radius: 20px;
    background: transparent;
    color: #1b84e7;
    font-weight: ${isOpen ? 700 : 500};
    border: 1px solid transparent !important;

    &:hover {
      outline: none !important;
      border: 1px solid transparent !important;
      box-shadow: none !important;
    }
    &:focus,
    &:active {
      outline: none !important;
      border: 1px solid transparent !important;
      box-shadow: none !important;
    }
    &:after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  `}
`;

export const StyledList = styled.ul`
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  width: 122px;
  border-radius: 0 0 20px 20px;
  transform: translate(-1px, 24px) !important;
  border: 1px solid #1b84e7;
  border-top: 0;
  z-index: 1;
  background: #fff;
`;

export const StyledListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    margin-top: 1rem;
  }
  &:last-child {
    margin-bottom: 1rem;
  }
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
  display: flex;
  height: 26px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0.5rem 0;
  color: #495057;
  &:hover {
    background: #f2f2f2;
    text-decoration: none;
  }
`;
