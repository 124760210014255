/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "../../Layout/Shared/Table";
import { Options } from "../../Layout/Shared/DropdownOptionsMenu";
import { ThreeDotIcon } from "../../../assets/icons/icon";
import { CustomButton } from "../../Layout/Shared/Button";
import { DeleteModal } from "../../Layout/Shared/Popups/Delete";
import { ShareModal } from "../../Layout/Shared/Popups/Share";
import { list } from "../../../data/Client/Business/get-list";
import {
  deactivateClient,
  activateClient
} from "../../../data/Client/Business/shared";
import DataLoading from "../../Layout/Shared/DataLoading";
import { FormattedDate } from "../../Layout/Shared/FormattedDate";
import DefaultImage from "../../../assets/images/default-profile-picture.png";
import { exportToCSV } from "../../../utils/export";
import { useBusinessClientListContext } from "../../../providers/Client/list";
import { getOneWeekAgoDate } from "../../../utils/date";
import { shareClient } from "../../../data/Client/Business/shared";

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const deleteList = async ids => {
  await delay(1000);
  return { hasSuccess: true };
};
const formatData = data => ({
  ...data,
  _id: data.id,
  designation: "Client"
});

const ClientsList = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedClientForDelete, setSelectedClientForDelete] = useState(null);
  const [selectedClientForShare, setSelectedClientForShare] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [showBulkDelete, setShowBulkDelete] = useState(false);
  const { getters, setters, refresh } = useBusinessClientListContext();
  const { clients, filter } = getters;
  const { setClients, setShowCreateGroup } = setters;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { hasSuccess, data } = await list();
      if (hasSuccess) {
        setClients(data.map(formatData));
        setFilteredClients(data.map(formatData));
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!filter) return;
    switch (filter) {
      case "All Clients":
        setFilteredClients(clients);
        break;
      case "Recently Added":
        const oneWeekAgoTime = getOneWeekAgoDate().getTime();
        setFilteredClients(
          clients.filter(
            client => new Date(client.created_at).getTime() > oneWeekAgoTime
          )
        );
        break;
      case "Favorites":
        setFilteredClients(clients.filter(client => client.isFavorite));
        break;
      case "Pending Invitation":
        setFilteredClients(clients.filter(client => !client.emailVerified));
        break;
      case "Immigrant Profiles":
        setFilteredClients(clients.filter(client => client.isImmigrant));
        break;
      case "Private Profiles":
        setFilteredClients(clients.filter(client => !client.isImmigrant));
        break;

      default:
        setFilteredClients(clients);
        break;
    }

    return () => {};
  }, [filter, clients]);

  const onSubmitShareModal = async (values, { setSubmitting }) => {
    values.emails = values.emails.split(",");
    values.emails = values.emails.map(email => email.trim());
    const { hasSuccess } = await shareClient(
      selectedClientForShare._id,
      values
    );
    if (hasSuccess) {
      setShowShare(false);
      setSubmitting(false);
      setSelectedClientForShare(null);
      setInfoMessage("The client shared successfully.");
    }
  };

  const handleDelete = async () => {
    if (!selectedClientForDelete) return;
    setIsLoading(true);
    const { hasSuccess } =
      selectedClientForDelete.status === "Active"
        ? await deactivateClient(selectedClientForDelete._id)
        : await activateClient(selectedClientForDelete._id);
    hasSuccess && refresh();
  };

  const handleBulkDelete = async () => {
    if (!filteredClients.some(client => client.isSelected)) return;
    setIsLoading(true);
    const { hasSuccess } = await deleteList(
      filteredClients
        .filter(client => client.isSelected)
        .map(client => client._id)
    );
    hasSuccess && refresh();
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setInfoMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [infoMessage]);

  const handleExport = async row => exportToCSV([row], "client");

  const handleBulkExport = async () => {
    if (!filteredClients.some(client => client.isSelected)) return;
    const exportedClients = filteredClients.filter(client => client.isSelected);
    exportToCSV(exportedClients, "clients");
  };

  const ActionsComponent = () => (
    <div className="d-flex justify-content-end align-items-center">
      <CustomButton
        color="transparent"
        title="Create Group"
        size="140px"
        onClick={() => setShowCreateGroup(true)}
      />
      <Options
        arrow={true}
        buttonTitle="Options"
        menuItems={
          <div>
            <button
              className="text-danger"
              onClick={() => setShowBulkDelete(true)}
              disabled={true}
              hidden={true}
            >
              Delete
            </button>
            <button onClick={handleBulkExport}>Export</button>
          </div>
        }
      />
    </div>
  );

  const Name = ({ row }) => (
    <div className="avatar-in-table">
      <div className="member">
        <div>
          <img
            src={
              row.profilePictureUrl && row.profilePictureUrl !== ""
                ? row.profilePictureUrl
                : DefaultImage
            }
            alt=""
          />
        </div>
      </div>
      <span>{`${row.firstName} ${row.lastName}`}</span>
    </div>
  );

  const CheckBox = ({ row }) => (
    <input
      type="checkbox"
      checked={row.isSelected}
      onChange={() => toggleChecked(row._id)}
    />
  );

  const toggleChecked = id =>
    setFilteredClients(
      filteredClients.map(item => {
        return {
          ...item,
          isSelected: item._id === id ? !item.isSelected : item.isSelected
        };
      })
    );

  const ActionButtons = ({ row }) => {
    const history = useHistory();
    const goToClientDetails = () => history.push(`/business/client/${row.id}`);
    return (
      <Options
        arrow={false}
        buttonTitle={ThreeDotIcon}
        menuItems={
          <div>
            <button onClick={goToClientDetails}>View/Edit</button>
            {row.status === "Accepted" && <button>Add to Group</button>}
            <button
              onClick={() => {
                setSelectedClientForShare(row);
                setShowShare(true);
              }}
            >
              Share
            </button>
            <button onClick={() => handleExport(row)}>Export</button>
            <button
              className="text-danger"
              onClick={() => {
                setSelectedClientForDelete(row);
                setShowDelete(true);
              }}
            >
              {row.status === "Active" ? "Deactivate" : "Activate"}
            </button>
          </div>
        }
      />
    );
  };

  const fields = [
    { render: CheckBox },
    { render: Name },
    "email",
    "phone",
    { render: ({ row }) => <FormattedDate date={row.updated_at} /> },
    {
      render: ({ row }) =>
        row.isImmigrant ? "Immigrant Profile" : "Private Profile"
    },
    { render: ({ row }) => (row.isInvitedByBusiness ? "Yes" : "No") },
    { render: ({ row }) => (row.emailVerified ? "Yes" : "No") },
    "status",
    { render: ActionButtons }
  ];
  const headers = [
    {
      displayName: "",
      sortingFieldName: "isSelected"
    },
    {
      displayName: "Full Name",
      sortingFieldName: "name"
    },
    {
      displayName: "Email",
      sortingFieldName: "email"
    },
    {
      displayName: "Phone",
      sortingFieldName: "phone"
    },
    {
      displayName: "Date Created",
      sortingFieldName: "created_at",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Type",
      sortingFieldName: "isImmigrant"
    },
    {
      displayName: "Invited",
      sortingFieldName: "isInvitedByBusiness"
    },
    {
      displayName: "Verified or Accepted",
      sortingFieldName: "emailVerified"
    },
    {
      displayName: "Status",
      sortingFieldName: "status"
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];
  const options = {
    data: filteredClients,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    headerAction: ActionsComponent
  };

  return (
    <div className="ps-5">
      {isLoading ? <DataLoading height="700px" /> : <Table options={options} />}
      {showDelete && (
        <DeleteModal
          height="260px"
          title={
            selectedClientForDelete?.status === "Active"
              ? "Deactivate Client"
              : "Activate Client"
          }
          text={
            selectedClientForDelete?.status === "Active"
              ? "You are about to deactivate this client from your client list. Deactivating this client removes all client’s information and ends all previous communication between you and the client."
              : "You are about to activate this client from your client list. Activating this client restores all client’s information and previous communication between you and the client."
          }
          buttonText={
            selectedClientForDelete?.status === "Active"
              ? "Deactivate"
              : "Activate"
          }
          onClose={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
      {showBulkDelete && (
        <DeleteModal
          height="260px"
          title="Delete Client(s)"
          text=" You are about to delete selected client(s) from your client list. Deleting
          these client(s) removes all client’s information and ends all previous
          communication between you and the client. 
          "
          buttonText="Delete"
          onClose={() => setShowBulkDelete(false)}
          onConfirm={handleBulkDelete}
        />
      )}
      {showShare && (
        <ShareModal
          title="Share Client"
          buttonText="Share"
          onClose={() => setShowShare(false)}
          onConfirm={onSubmitShareModal}
          onExport={() => {
            selectedClientForShare && handleExport(selectedClientForShare);
          }}
        />
      )}
      {infoMessage && (
        <div className="notifications">
          <div className="info">
            <span>Info</span>
            <p>{infoMessage}</p>
            <button onClick={() => setInfoMessage(null)}>&times;</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientsList;
