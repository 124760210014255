import ESignatureCommon from "./ESignatureCommon";

export function ESignatureEditTab({dispatch, attributes, component_key}) {
  return (
    <ESignatureCommon
      dispatch={dispatch}
      attributes={attributes}
      component_key={component_key}
    />
  );
}

export default ESignatureEditTab;
