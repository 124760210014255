import React from "react";
import {LabelBlueBold} from "../../../Layout/Shared/LabelBlueBold";
import {Formik, Form, Field} from "formik";
import ErrorField from "../../../Layout/Shared/ErrorField";
import PhoneInput from "react-phone-input-2";
import {designations} from "../../../../static/Onboarding/business";
import {CountryInput} from "../../../Layout/Shared/CountryInput";

export const ContactInformation = ({contact, setContact, error}) => {
  return (
    <div className="pl-4">
      <h2>Contact Details</h2>
      <div>
        <Formik
          enableReinitialize
          initialValues={contact}
          validateOnBlur={false}
        >
          {({values}) => (
            <>
              {setContact(values)}
              <Form>
                <div className="mt-5">
                  <LabelBlueBold content="Contact Name" />
                  <div className="d-flex justify-content-between">
                    <Field
                      className="custom-input me-2"
                      placeholder="First Name"
                      name="firstName"
                    />
                    <Field
                      className="custom-input ms-2 "
                      placeholder="Last Name"
                      name="lastName"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <LabelBlueBold content="Designation" />
                  <Field name="designation">
                    {({field: {value}, form: {setFieldValue}}) => (
                      <select
                        className="primary-select"
                        placeholder="Please select designation"
                        onChange={({target}) =>
                          setFieldValue("designation", target.value)
                        }
                        defaultValue="-1"
                      >
                        <option value="-1" disabled>
                          Please select designation
                        </option>
                        {designations
                          .filter((designation) => designation.isContact)
                          .map((designation) => (
                            <option
                              key={designation.name}
                              value={designation.name}
                            >
                              {designation.name}
                            </option>
                          ))}
                      </select>
                    )}
                  </Field>
                </div>
                <div className="mt-4">
                  <LabelBlueBold content="Email Address" />
                  <Field
                    className="custom-input"
                    placeholder="Enter Email Address"
                    name="email"
                  />
                </div>
                <div className="mt-4 custom-phone">
                  <LabelBlueBold content="Mobile Number" />
                  <Field name="phone">
                    {({field: {value}, form: {setFieldValue}}) => (
                      <PhoneInput
                        enableSearch
                        placeholder="Enter Mobile Number"
                        country={"ca"}
                        value={value}
                        onChange={(number) => setFieldValue("phone", number)}
                      />
                    )}
                  </Field>
                </div>
                <div className="mt-4">
                  <LabelBlueBold content="Contact Address" />
                  <Field
                    className="custom-input"
                    placeholder="Client Address"
                    name="address"
                  />
                  <div className="d-flex justify-content-between mt-5">
                    <Field
                      className="custom-input me-2"
                      placeholder="City"
                      name="city"
                    />
                    <Field
                      className="custom-input ms-2 "
                      placeholder="State"
                      name="state"
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-5">
                    <Field name="country">
                      {({field: {value}, form: {setFieldValue}}) => (
                        <CountryInput
                          name="country"
                          className="custom-input"
                          placeholder="Country"
                          onSelect={({text}) => setFieldValue("country", text)}
                        />
                      )}
                    </Field>
                    <div className="w-100 ms-2 ">
                      <Field
                        className="custom-input"
                        placeholder="Zip Code"
                        name="zipCode"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
        {error && <ErrorField message={JSON.stringify(error)} />}
      </div>
    </div>
  );
};
