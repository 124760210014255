import React, { useState } from "react";
import { Options } from "../../../Layout/Shared/DropdownOptionsMenu";
import { ThreeDotIcon } from "../../../../assets/icons/icon";
import useBusinessCaseManagementTasksListContext from "../../../../providers/CaseManagement/Tasks/list";
import { ConfirmDialogV2 } from "../../../Layout/Shared/Popups/ConfirmDialog";

export const ActionButtons = ({ task }) => {
  const { loading, loadTasks, setShowFormModal, taskFormData, taskModel } =
    useBusinessCaseManagementTasksListContext();
  const { load: loadTaskFormData } = taskFormData;
  const { load: loadTask, delete: deleteTask, cancel: cancelTask } = taskModel;
  const [showDelete, setShowDelete] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleOnEdit = async () => {
    setShowFormModal(true);
    await loadTaskFormData();
    await loadTask(task._id);
  };

  const handleOnCancel = async () => setShowCancel(!showCancel);
  const handleOnCancelConfirm = async () => {
    setSubmitting(true);
    const result = await cancelTask(task._id);
    if (result) loadTasks();
    setSubmitting(false);
    setShowCancel(!showCancel);
  };

  const handleOnDelete = async () => setShowDelete(!showDelete);
  const handleOnDeleteConfirm = async () => {
    setSubmitting(true);
    const result = await deleteTask(task._id);
    if (result) loadTasks();
    setShowDelete(!showDelete);
    setSubmitting(false);
  };

  return (
    <>
      {loading ? (
        <span className="dots-loading">{ThreeDotIcon}</span>
      ) : (
        <Options
          arrow={false}
          buttonTitle={ThreeDotIcon}
          menuItems={
            <div>
              <button hidden={task.cancelled} onClick={handleOnEdit}>
                Edit
              </button>
              <button hidden={task.cancelled} onClick={handleOnCancel}>
                Cancel
              </button>
              <button className="text-danger" onClick={handleOnDelete}>
                Delete
              </button>
            </div>
          }
        />
      )}
      {showDelete && (
        <ConfirmDialogV2
          title="Delete Task"
          body="You are about to delete this task from your task list. Deleting
          this task removes all task’s information and ends all previous
          communication between you and the task."
          confirmButtonText="Delete"
          onConfirm={handleOnDeleteConfirm}
          onClose={handleOnDelete}
          submitting={submitting}
        />
      )}
      {showCancel && (
        <ConfirmDialogV2
          title="Cancel Task"
          body="You are about to cancel this task. Are you sure you want to continue?"
          onConfirm={handleOnCancelConfirm}
          onClose={handleOnCancel}
          submitting={submitting}
        />
      )}
    </>
  );
};
