import React, { useState } from 'react'
import {
  StyledDocumentCard,
  StyledDownload,
  StyledIcon,
  StyledFileName,
  StyledDelete
} from "./AttachmentStyle";
import AttachmentDownloadIcon from "../../../../../../../assets/icons/AttachmentDownloadIcon";
import IconText from "../../../../../../../assets/icons/iconText";
import IconImage from "../../../../../../../assets/icons/iconImage";
import IconDocument from "../../../../../../../assets/icons/iconDocument";
import IconBSPdf from "../../../../../../../assets/icons/iconbsPdf";
import IconXls from "../../../../../../../assets/icons/iconXls";
import { IconDeleteRed } from "../../../../../../../assets/icons/iconDelete";
import { DeleteModal } from "../../../../../../Layout/Shared/Popups/Delete";

const AttachmentUpload = ({ fileName, value, valueType, handleRemoveAttachment, Key, isReadOnly }) => {
  const [ShowDelete, setShowDelete] = useState(false);

  const FileIcons = () => {
    const ImageTypes = new Set([
      'image/jpeg',
      'image/gif',
      'image/png',
      'image/svg+xml'
    ])
    const XlsTypes = new Set(["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"])
    if (ImageTypes.has(valueType)) {
      return <IconImage />
    }
    if (valueType === "application/pdf") {
      return <IconBSPdf />
    }
    if (valueType === "text/plain") {
      return <IconText />
    }
    if (XlsTypes.has(valueType)) {
      return <IconXls />
    }
    return <IconDocument />
  }
  const fileNames = fileName.length > 20 ? `${fileName.slice(0, 20)}...` : fileName
  return (
    <>
      <StyledDocumentCard key={fileName}>
        <StyledIcon>
          <FileIcons />
        </StyledIcon>
        <StyledFileName>{fileNames}</StyledFileName>
        <a download={fileName} href={value} rel="noreferrer" target="_blank">
          <StyledDownload>
            <AttachmentDownloadIcon />
          </StyledDownload>
        </a>
        {isReadOnly === undefined && <>
          <StyledDelete>
            <button onClick={() => setShowDelete(!ShowDelete)}
            >
              {IconDeleteRed}
            </button>
            {ShowDelete && (
              <DeleteModal
                title="Delete file"
                buttonText="Delete"
                cancel={true}
                text="This file will be permanently deleted."
                onClose={() => setShowDelete(!ShowDelete)}
                onConfirm={() => handleRemoveAttachment(value, Key)}
              />
            )}
          </StyledDelete>
        </>
        }
      </StyledDocumentCard>
    </>
  )
}
export default AttachmentUpload
