import "bootstrap/dist/js/bootstrap.min.js";
import React, {Fragment} from "react";

import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import HeadingEdit from "./HeadingEdit";
import HeadingHover from "./HeadingHover";

export function Heading({component_key, attributes, dispatch}) {
  const confirmed = getAttribute("confirmed", attributes);
  return (
    <Fragment>
      {confirmed === "true" ? (
        <HeadingHover
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      ) : (
        <HeadingEdit
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      )}
    </Fragment>
  );
}

export default Heading;
