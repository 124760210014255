import React, {useState} from "react";
import Calendar from "react-calendar";
import "./index.scss";
export const CalendarMonthly = () => {
  const [date, setDate] = useState(new Date());
  return (
    <div className="calendar-monthly">
      <Calendar onChange={setDate} value={date} />
    </div>
  );
};
