import React from "react";
import LogoBlueSmall from "../../assets/logo/logoSmall";
import Invitation from "../../components/Invitation/Client/index";
import "../../styles/pages/invitations.scss";

const InvitationPage = () => {
    return (
        <section className="invitations">
        <div className="left-side ">
          <LogoBlueSmall />
          <div className="inner-left ">
              Invitations
          </div>
        </div>
        <div className="right-side">
          <header>
          Invitations<small>You have received following invitations from various organizations.</small>
          </header>
          <div className="container-fluid">
            <div className="row">
             <Invitation />
            </div>
          </div>
        </div>
      </section>
    )
}

export default InvitationPage;