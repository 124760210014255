import React, {useState, useEffect, useContext} from "react";
import Modal from "../../../../Layout/Shared/Modal";
import {LabelBlueBold} from "../../../../Layout/Shared/LabelBlueBold";
import {CustomButton} from "../../../../Layout/Shared/Button";
import {Formik, Form, Field} from "formik";
import ErrorField from "../../../../Layout/Shared/ErrorField";
import {DeactivateAccountSchema} from "../../../../../schemas/AccountSettings/business";
import {useHistory} from "react-router-dom";
import {GlobalContext} from "../../../../../providers/global";
import {deactivateAccount} from "../../../../../data/Settings/Business/Account/deactivateAccount";
import "./index.scss";
export const DeactivateAccount = ({onClose, setIsSaving}) => {
  const history = useHistory();
  const {logout} = useContext(GlobalContext);
  const [errorMessage, setErrorMessage] = useState(null);

  const initialValues = {
    isArchived: "-1",
    backupEmail: "",
    leavingReason: "-1",
    description: "",
  };

  const handleOnSubmit = async (values) => {
    setIsSaving(true);
    const {hasSuccess, data} = await deactivateAccount(values);
    if (hasSuccess) {
      onClose();
      setIsSaving(false);
      logout();
      history.push("/login");
    } else {
      setIsSaving(false);
      setErrorMessage(JSON.stringify(data));
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setErrorMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [errorMessage]);

  return (
    <Modal
      title="Why would you like to deactivate this account?"
      width="900px"
      height="520px"
      close={onClose}
    >
      <div className="deactivate-window">
        <Formik
          initialValues={initialValues}
          validationSchema={DeactivateAccountSchema}
          onSubmit={handleOnSubmit}
        >
          {({errors}) => (
            <Form>
              <div className="row">
                <div className="col-4">
                  <LabelBlueBold content="Would you like archive this account to, in case you would like to come back?" />
                  <Field
                    className="primary-select mt-3"
                    as="select"
                    name="isArchived"
                  >
                    <option value="-1" disabled selected>
                      Select
                    </option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorField message={errors.isArchived} />
                </div>

                <div className="col-4">
                  <LabelBlueBold content="Enter email address to get a backup of this account’s data?" />
                  <Field
                    name="backupEmail"
                    type="email"
                    className="custom-input mt-3"
                    placeholder="destin.barton@hotmail.com"
                  />
                </div>

                <div className="col-4">
                  <LabelBlueBold content="Please select a reason below for leaving us" />
                  <Field
                    className="primary-select mt-3"
                    as="select"
                    name="leavingReason"
                  >
                    <option value="-1" disabled selected>
                      Select
                    </option>
                    <option value="This is temporary. I'll be back.">
                      This is temporary. I'll be back.
                    </option>
                    <option value="I spend too much time using The System.">
                      I spend too much time using The System.
                    </option>
                    <option value="My account was hacked.">
                      My account was hacked.
                    </option>
                    <option value="I don't understand how to use The System.">
                      I don't understand how to use The System.
                    </option>
                    <option value="I don't feel safe on The System.">
                      I don't feel safe on The System.
                    </option>
                    <option value="I don't find The System useful.">
                      I don't find The System useful.
                    </option>
                    <option value="I have another The System account.">
                      I have another The System account.
                    </option>
                    <option value="I receive too many emails, invitations and requests from The System.">
                      I receive too many emails, invitations and requests from
                      The System.
                    </option>
                    <option value="Other (please explain)">
                      Other (please explain)
                    </option>
                  </Field>
                  <ErrorField message={errors.leavingReason} />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 mt-3">
                  <LabelBlueBold content="Enter Description" />
                  <Field
                    name="description"
                    as="textarea"
                    rows="4"
                    className="custom-textarea"
                    placeholder="Please enter your comment"
                  />
                  <ErrorField message={errors.description} />
                </div>
                <div className="col-md-12 mt-5 d-flex  d-flex justify-content-end ">
                  <button className="btn-text-blue me-5 " onClick={onClose}>
                    Cancel
                  </button>
                  <CustomButton
                    color="red"
                    title="Request Deactivate"
                    size="220px"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <button
          className="link-btn btn-text-blue text-left"
          onClick="return false;"
        >
          Would like to talk to our support agent?
        </button>
        {errorMessage && (
          <div className="notifications">
            <div className="warning">
              <span>Warning</span>
              <p>{errorMessage}</p>
              <button onClick={() => setErrorMessage(null)}>&times;</button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
