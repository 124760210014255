import { validateComponent } from '../../../utils'

const checkboxValidator = (component) => {
  const {
    options: { isRequired, requiredErrorMessage, submission },
  } = component

  if (isRequired) {
    const validators = [
      {
        validationFunction: () => submission && submission.values.length > 0,
        validationKey: 'values',
        validationMessage: requiredErrorMessage,
      },
    ]
    validateComponent(component, validators)
  } else {
    component.isValid = true
  }

  return component.isValid
}

export default checkboxValidator
