import styled from "styled-components";

export const StyledContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;

export const StyledWrapper = styled.div`
  flex:1;
  display: flex;
  gap: 1rem;
`;