import styled, { css } from "styled-components";

export const StyledSelectedParticipantContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid "#e0e0e0";
`;

export const StyledSelectedParticipantBadge = styled.div`
  ${({ type, name, businessName }) => css`
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background-color: ${getColor(type)};
    color: #fff;
    position: relative;

    &:hover::after {
      content: "${getHoverText(type, name, businessName)}";
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #000;
      color: #fff;
      padding: 0.5rem;
      border-radius: 0.3rem;
      white-space: nowrap;
      z-index: 10;
    }
  `}

  span {
    font-size: 1rem;
    font-weight: 500;
    margin-left: 0.5rem;
  }
`;

const getColor = type => {
  switch (type) {
    case "business":
      return "#1b84e7";
    case "staff":
      return "#28a745";
    case "client":
      return "#ffc107";
    default:
      return "transparent";
  }
};

const getHoverText = (type, name, businessName) => {
  switch (type) {
    case "business":
      return `Business: ${businessName}`;
    case "staff":
      return `Staff: ${businessName}`;
    case "client":
      return `Client: ${name}`;
    default:
      return "";
  }
};

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
`;

export const StyledGroupNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const StyledGroupNameInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1.4rem;
  font-weight: 550;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

export const StyledGroupNameLabel = styled.label`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #1b84e7;
`;
