import React, {Fragment} from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {InputNumberEdit} from "./InputNumberEdit";
import InputNumberHover from "./InputNumberHover";

export function InputNumber({component_key, attributes, dispatch}) {
  const confirmed = getAttribute("confirmed", attributes);
  return (
    <Fragment>
      {confirmed === "true" ? (
        <InputNumberHover
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      ) : (
        <InputNumberEdit
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      )}
    </Fragment>
  );
}

export default InputNumber;
