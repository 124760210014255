import styled from 'styled-components'
import * as theme from '../../../../../styles/theme'
export const StyledContainer = styled.div``

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const StyledOptionItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 0.5em;
  grid-gap: 1em;
`
export const StyledInput = styled.input`
  width: 24px;
  height: 24px;
  appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25em;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  &:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
    background-color: ${theme.azul};
    border-color: ${theme.azul};
  }
  + span {
    font-size: 16px;
  }
`

export const StyledText = styled.span``
