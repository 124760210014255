import React, {useState} from "react";
import {tabs, defaultTab} from "../tabs";
import "./index.scss";
export const TabContainer = () => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const Component = selectedTab.component;
  return (
    <div className="bg-white  pb-5">
      <ul className="generic-page-tab">
        {tabs.map((tab) => (
          <li
            key={tab.name}
            className={selectedTab.name === tab.name ? "active" : null}
          >
            <button onClick={() => setSelectedTab(tab)}>{tab.name}</button>
          </li>
        ))}
      </ul>
      <section>
        <Component setSelectedTab={(tab) => setSelectedTab(tab)} />
      </section>
    </div>
  );
};
