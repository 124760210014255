import React from "react";
import { StyledComponentTitle } from "../../styles";

const Label = ({
  component: {
    options: { value }
  }
}) => {
  return <StyledComponentTitle>{value}</StyledComponentTitle>;
};

export default Label;
