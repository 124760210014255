export const alignments = ['left', 'center', 'right']
export const styles = ['H1', 'H2', 'H3']

const headingDefaultOptions = {
  alignment: alignments[0],
  style: styles[0],
  value: '',
  column: '1/1',
}

export default headingDefaultOptions
