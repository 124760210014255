/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from "react";
import {getAttachments} from "../../../../../data/Service/Business/getAttachments";
import {ListLoader} from "../../../../Layout/Shared/DataLoading";
import BusinessServiceContext from "../../../../../providers/Service/business";
import "./index.scss";
import {AttachmentList} from "../../../Shared/AttachmentList";

export const AttachmentsList = () => {
  const {service} = useContext(BusinessServiceContext);
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAttachmentList = async () => {
    setIsLoading(true);
    const {data, hasSuccess} = await getAttachments(service._id);
    if (hasSuccess) {
      setAttachments(data.attachments);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAttachmentList();
  }, []);

  return (
    <>
      {isLoading ? (
        <ListLoader height="450px" />
      ) : (
        <AttachmentList attachments={attachments} buttonTitle="View" />
      )}
    </>
  );
};
