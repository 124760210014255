import Details from "./Details";
import {AttachmentsList} from "./AttachmentsList";
import Forms from "./Forms";
import DetailCollaborate from "./DetailCollaborate";

export const tabs = [
  {
    name: "Service Details",
    component: Details,
    isDefault: true,
  },
  {
    name: "Attachments",
    component: AttachmentsList,
  },
  {
    name: "Forms",
    component: Forms,
  },
  {
    name: "Collaborate",
    component: DetailCollaborate,
  },
];

export const defaultTab = tabs.find((tab) => tab.isDefault);
