import React from "react";
export const IconDeleteRed = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="13"
    viewBox="0 0 18.667 24"
  >
    <g id="delete_1_" transform="translate(-42.667)">
      <g id="Group_1547" transform="translate(42.667)">
        <g id="Group_1546">
          <path
            fill="red"
            id="Path_2709"
            d="M64 101.333A2.666 2.666 0 0 0 66.667 104h10.666A2.666 2.666 0 0 0 80 101.333v-16H64z"
            className="cls-1"
            transform="translate(-62.667 -80)"
          />
          <path
            fill="red"
            id="Path_2710"
            d="M56.667 1.333L55.334 0h-6.667l-1.333 1.333h-4.667V4h18.667V1.333z"
            className="cls-1"
            transform="translate(-42.667)"
          />
        </g>
      </g>
    </g>
  </svg>
);