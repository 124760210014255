import React, {useState} from "react";
import Breadcrumb from "../../components/Layout/Breadcrumb/Breadcrumb";
import Stats from "../../components/Dashboard/Business/Stats/Stats";
import Progress from "../../components/Dashboard/Business/Progress/Progress";
// import GettingStarted from "../../components/Dashboard/Business/GettingStarted/index";
// import RecentMessages from "../../components/Dashboard/Shared/RecentMessages/index";
// import RecentActivity from "../../components/Dashboard/Shared/RecentActivity/index";
// import Enrolment from "../../components/Dashboard/Shared/Enrolment/index";
// import Shortcuts from "../../components/Dashboard/Business/Shortcuts/index";
import Header from "../../components/Layout/Header/Header";
import {RightToggler} from "../../components/Layout/Shared/RightToggler";
import "../../styles/pages/dashboard.scss";
// import {
//   ColumnLarge,
//   ColumnMedium,
// } from "../../components/Dashboard/Business/EmptyColumns/index";
import ManageTiles from "../../components/Dashboard/Business/ManageTiles/index";
import {ManageTile} from "../../assets/icons/icon";
import {GenericPageLeft} from "../../components/Layout/Shared/GenericPageLeft";

const DashboardPage = () => {
  const [showToggle, setShowToggle] = useState(false);
  const manageTiles = () => {
    return (
      <button onClick={() => setShowToggle(!showToggle)}>
        {ManageTile} Manage Tiles
      </button>
    );
  };
  const TilesActionButtons = () => (
    <div className="row">
      <div className="col-6  text-center">
        <button
          className="btn btn-blue-gradient btn-small m-0"
          onClick={() => setShowToggle(!showToggle)}
        >
          Add Widget
        </button>
      </div>
      <div className="col-6  text-center">
        <button className="btn   btn-transparent btn-small  ">
          Reset to Default
        </button>
      </div>
    </div>
  );
  return (
    <section className="dashboard pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <div className="right p-3">
            <section>
              <div className="row">
                <div className="col-lg-12">
                  <Breadcrumb action={manageTiles()} />
                </div>
                <div className="col-lg-12">
                  <Stats />
                  <div className="mt-1"></div>

                  <div className="row">
                    <Progress />
                    {/* <GettingStarted /> <RecentMessages /> <RecentActivity />
                    <Enrolment /> <Shortcuts />
                    <ColumnLarge />
                    <ColumnMedium /> */}
                  </div>

                  {showToggle && (
                    <RightToggler
                      active={true}
                      title="Manage Tiles"
                      size="460px"
                      titleClose={true}
                      close={() => setShowToggle(!showToggle)}
                      actionButtons={<TilesActionButtons />}
                    >
                      <ManageTiles />
                    </RightToggler>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardPage;
