import "./index.scss";
export const CustomSelectFile = ({label, size, id, ...rest}) => (
  <label
    htmlFor={id || "selectFile"}
    className="custom-select-file btn-blue text-center btn-small"
    style={{width: size}}
  >
    {label ? label : "Select File"}

    <input type="file" id={id || "selectFile"} {...rest} />
  </label>
);
