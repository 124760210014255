import React, { useState } from "react";
import { teamTabs, defaultTeamTab } from "../../tabs";
import { CustomButton } from "../../../../Layout/Shared/Button";
import { AddStaff } from "./AddStaff";
import { CreateGroupTeam } from "../Groups/CreateGroup";
import DataLoading from "../../../../Layout/Shared/DataLoading";
import "./index.scss";
import { DefineNewRole } from "./DefineNewRole";
import { get as getRoles } from '../../../../../data/Settings/Business/Team/getRoles'
import ErrorField from '../../../../../components/Layout/Shared/ErrorField'
import {StyledWarning} from "./TabContainer.style";

export const TabContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(defaultTeamTab);
  const [showAddUser, setShowAddUser] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const [hasError, setHasError] = useState(false);
  const message = 'To add Staff create Roles and Permissions first';

  const refreshTab = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1);
  };

  const handleOnAddStaffClick = async () => {
    const { hasSuccess, data } = await getRoles()
    if (hasSuccess) {
      const dataAsArray = Object.values(data)
      if (dataAsArray.length > 0) {
        setShowAddUser(true)
        setHasError(false)
      } else {
        setHasError(true)
        setTimeout(() => {
          setHasError(false);
      }, 5000);
        setShowAddUser(false)
      }
    }
  }
  return (
    <>
      {isLoading ? (
        <DataLoading height="750px" />
      ) : (<>
        {hasError && (
          <StyledWarning><ErrorField message={message} /></StyledWarning>
        )}
        <div className="bg-white rounded-xl border pb-5">
          <ul className="generic-page-tab">
            {teamTabs.map((tab) => (
              <li
                key={tab.name}
                className={selectedTab.name === tab.name ? "active" : null}
              >
                <button onClick={() => setSelectedTab(tab)}>{tab.name}</button>
              </li>
            ))}
            <div className="float-end mt-3 me-5 ">
              {selectedTab.name === "Groups" ? (
                <CustomButton
                  color="blue"
                  title="Create Group"
                  onClick={() => setCreateGroup(true)}
                />
              ) : selectedTab.name === "Roles & Permissions" ? (
                <CustomButton
                  color="blue"
                  title="Define New Role"
                  onClick={() => setShowRole(true)}
                />
              ) : (
                <CustomButton
                  color="blue"
                  title="Add Staff"
                  onClick={() => handleOnAddStaffClick()}
                />
              )}
            </div>
          </ul>
          <section>{selectedTab.component}</section>
          {showAddUser && (
            <AddStaff
              onClose={() => setShowAddUser(false)}
              refresh={refreshTab}
            />
          )}
          {createGroup && (
            <CreateGroupTeam
              onClose={() => setCreateGroup(false)}
              refresh={refreshTab}
            />
          )}
          {showRole && (
            <DefineNewRole
              onClose={() => setShowRole(false)}
              refresh={refreshTab}
            />
          )}
        </div>
      </>
      )}
    </>
  );
};