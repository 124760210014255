import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { ViewSwitch } from '../../Layout/Shared/ViewSwitch'
import FormContainerHeader from '../Shared/header.component'
import {
  StyledBody,
  StyledDescription,
  StyledGridCard,
  StyledGridList,
  StyledImageContainer,
  StyledLink,
  StyledTemplateCategoriesContainer,
  StyledTitle,
} from './templates.style'
import Table from '../../Layout/Shared/Table'

const options = {
  headers: [
    {
      displayName: 'Template category name',
      sortingFieldName: 'title',
    },
    {
      displayName: '',
      sortingFieldName: 'description',
    },
  ],
  fields: [
    {
      render: ({ row }) => (
        <StyledLink
          to={'/business/forms/templates/' + row.id}
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title={row.description}
        >
          {row.title}
        </StyledLink>
      ),
    },
    {
      render: () => <Fragment></Fragment>,
    },
  ],
  pagination: true,
  search: false,
  sort: true,
}

const FormTemplates = ({ templates = [] }) => {
  const [viewType, setViewType] = useState('list')
  return (
    <StyledTemplateCategoriesContainer>
      <FormContainerHeader flexEnd>
        <ViewSwitch
          grid={() => setViewType('grid')}
          list={() => setViewType('list')}
          active={viewType}
        />
      </FormContainerHeader>

      {viewType === 'list' ? (
        <Table options={{ data: templates, ...options }} />
      ) : (
        <StyledGridList>
          {templates?.map((template, index) => {
            const templateCard = (
              <StyledGridCard
                key={index}
                href={'/business/forms/templates/' + template.id}
              >
                <StyledImageContainer>
                  <img src={'/assets/' + template.image} alt={template.title} />
                </StyledImageContainer>

                <StyledBody>
                  <StyledTitle className="card-title pb-2">
                    {template.title}
                  </StyledTitle>
                  <StyledDescription className="card-text">
                    {template.description}
                  </StyledDescription>
                </StyledBody>
              </StyledGridCard>
            )
            return template.link ? (
              <Link key={template.id} to={template.link}>
                {templateCard}
              </Link>
            ) : (
              templateCard
            )
          })}
        </StyledGridList>
      )}
    </StyledTemplateCategoriesContainer>
  )
}

export default FormTemplates
