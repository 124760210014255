import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import FormTemplateCategory from "../../../../components/Form/Templates/category.component";
import {PageContainer} from "../../../../components/Layout/Container/PageContainer";
import {GenericPageLeft} from "../../../../components/Layout/Shared/GenericPageLeft";
import {PageHeader} from "../../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../../components/Layout/Shared/PageTitle";
import Header from "../../../../components/Layout/Header/Header";
import {getAllTemplates} from "../../../../data/Templates/getTemplates";
import {TemplateTableLoader} from "../../../../components/Layout/Shared/DataLoading/table-loader.component";

const FormTemplateCategoryPage = () => {
  const {slug} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const init = async () => {
      const {data: response, hasSuccess} = await getAllTemplates(slug);
      hasSuccess && setTemplates(response?.data);
      setIsLoading(false);
    };
    init();
  }, [slug]);

  return (
    <section className="dashboard  bg-paleGray pb-5 pe-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>TEMPLATES</PageTitle>
            </PageHeader>
            {isLoading ? (
              <TemplateTableLoader />
            ) : (
              <FormTemplateCategory templates={templates} />
            )}
          </PageContainer>
        </div>
      </div>
    </section>
  );
};

export default FormTemplateCategoryPage;
