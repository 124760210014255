import { formsApiHostName } from '../endpoint'
import { defaultHeaders } from '../headers'

export const updateForm = async (id, data) => {
  try {
    const result = await fetch(`${formsApiHostName}/forms/${id}`, {
      method: 'PUT',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(data),
    })
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    }
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    }
  }
}
