import {hostName} from "../../endpoint";
import {defaultHeaders} from "../../headers";

export const updateSettingsDetails = async (details) => {
  const result = await fetch(`${hostName}/business/update-setting-details`, {
    method: "PUT",
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify(details),
  });
  return result.json();
};
