import {LabelBlueBold} from "../../../../../Layout/Shared/LabelBlueBold";
import "./index.scss";
export const AppointmentSchedule = ({scheduleData}) => {
  return (
    <div className="ao-schedule">
      <LabelBlueBold content="Scheduled at" />
      <div className="details">
        <span>
          <span>Day</span> {scheduleData.day}
        </span>
        <span>
          <span>Date</span> {scheduleData.date}
        </span>
        <span>
          <span>Time</span> {scheduleData.time}
        </span>
      </div>
    </div>
  );
};
