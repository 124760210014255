export const FormComponentTypes = {
  Heading: 'heading',
  Label: 'label',
  InputText: 'inputText',
  InputNumber: 'inputNumber',
  RadioButton: 'radioButton',
  Checkbox: 'checkbox',
  Memo: 'memo',
  PageBreak: 'pageBreak',
  ESignature: 'eSignature',
  Attachment: 'attachment',
}

export const FormTypes = {
  Administrative: 'administrative',
  External: 'external',
  Survey: 'survey',
}

export const FormVisibilityOptions = {
  Me: 'me',
  Organisation: 'organisation',
  Template: 'template',
}
