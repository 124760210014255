import React from "react";
import {
  ColumnDropdown,
  ColumnDropdownMenu,
  DropdownText,
} from "../Base/BaseStyles";
import styled from "styled-components";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";

const DropdownLi = styled.li`
  cursor: pointer;
`;

export function getLis(lis, selected, component_key, dispatch) {
  const liElements = [];
  lis = lis.filter((li) => li !== selected);
  for (const li of lis) {
    liElements.push(
      <DropdownLi
        key={li}
        onClick={(event) => {
          event.preventDefault();
          dispatch({
            type: "column_size_dropdown_change",

            value: event.target.lastChild.nodeValue,
            key: component_key,
          });
        }}
      >
        <DropdownText className="dropdown-item">{li}</DropdownText>
      </DropdownLi>
    );
  }
  return liElements;
}

function checkColumnSizes(attributes) {
  let column_sizes = [];
  try {
    column_sizes = getAttribute("column_sizes", attributes);
  } catch (e) {
    column_sizes = ["1/1", "1/2", "1/3", "1/4"];
  }
  return column_sizes;
}

function checkSelectedColumnSize(attributes) {
  let selected_column_size = "";
  try {
    selected_column_size = getAttribute("selected_column_size", attributes);
  } catch (e) {
    selected_column_size = "1/1";
  }
  return selected_column_size;
}

export function Dropdown({attributes, dispatch, component_key}) {
  return (
    <div>
      <div className="dropdown">
        <ColumnDropdown
          className="btn dropdown-toggle formBuilder"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {checkSelectedColumnSize(attributes)}
        </ColumnDropdown>
        <ColumnDropdownMenu
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton1"
        >
          {getLis(
            checkColumnSizes(attributes),
            checkSelectedColumnSize(attributes),
            component_key,
            dispatch
          )}
        </ColumnDropdownMenu>
      </div>
    </div>
  );
}
