import * as Yup from "yup";

export const BusinessProfileSchema = Yup.object().shape({
  address: Yup.string()
    .min(5, "Address is too Short!")
    .max(50, "Address is too Long!")
    .required("Address is required"),
  city: Yup.string()
    .min(3, "City is too Short!")
    .max(15, "City is too Long!")
    .required("City is required"),
  state: Yup.string()
    .min(3, "State is too Short!")
    .max(15, "State is too Long!")
    .required("State is required"),
  country: Yup.string()
    .min(2, "Country is too Short!")
    .max(60, "Country is too Long!")
    .required("Country is required"),
  zipCode: Yup.string()
    .min(3, "Zip Code is too Short!")
    .max(15, "Zip Code is too Long!")
    .required("Zip Code is required"),
  phone: Yup.string()
    .notOneOf([""], "Phone is required!")
    .required("Phone is required"),
});

export const OwnerProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "First Name is too Short!")
    .max(30, "First Name is too Long!")
    .notOneOf([""], "First Name is required!")
    .required("First Name is required!"),
  lastName: Yup.string()
    .min(3, "Last Name is too Short!")
    .max(30, "Last Name is too Long!")
    .notOneOf([""], "Last Name is required!")
    .required("Last Name is required"),
  designation: Yup.string()
    .notOneOf(["-1"], "Please select a designation!")
    .required("Designation is required"),
  phone: Yup.string().required("Phone is required"),
});

export const StaffProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "First Name is too Short!")
    .max(30, "First Name is too Long!")
    .notOneOf([""], "First Name is required!")
    .required("First Name is required!"),
  lastName: Yup.string()
    .min(3, "Last Name is too Short!")
    .max(30, "Last Name is too Long!")
    .notOneOf([""], "Last Name is required!")
    .required("Last Name is required"),
  address: Yup.string()
    .min(10, "Address is too Short!")
    .max(50, "Address is too Long!")
    .notOneOf([""], "Address is required!")
    .required("Address is required"),

  phone: Yup.string().required("Phone is required"),
});
