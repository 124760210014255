import React from "react";
import {
  StyledLoaderContainer,
  StyledLoaderHeader,
  BackButtonLoader,
  ButtonGroupLoader,
  StyledLoaderBody,
  StyledLoaderLeft,
  ButtonLoader,
  StyledMockToolbar,
  StyledToolbarItem,
  StyledLoaderRight,
  StyledLoaderRightHeader,
  StyledLoaderRightBody,
  StyledLoaderRightBodyHeader,
  StyledToolbarTitle
} from "./styles";

const Loader = () => (
  <StyledLoaderContainer>
    <StyledLoaderHeader>
      <BackButtonLoader />
      <ButtonGroupLoader>
        {Array(6)
          .fill(0)
          .map((_, i) => (
            <ButtonLoader key={i} />
          ))}
      </ButtonGroupLoader>
    </StyledLoaderHeader>
    <StyledLoaderBody>
      <StyledLoaderLeft>
        <StyledToolbarTitle>
          <div></div>
          <span></span>
          <span></span>
        </StyledToolbarTitle>
        <StyledMockToolbar>
          {Array(9)
            .fill(0)
            .map((_, i) => (
              <StyledToolbarItem key={i}>
                <span></span>
                <div></div>
              </StyledToolbarItem>
            ))}
        </StyledMockToolbar>
      </StyledLoaderLeft>
      <StyledLoaderRight>
        <StyledLoaderRightHeader>
          <span></span>
          <div></div>
        </StyledLoaderRightHeader>

        <StyledLoaderRightBody>
          <p></p>
          <StyledLoaderRightBodyHeader>
            <span></span>
            <div></div>
          </StyledLoaderRightBodyHeader>
        </StyledLoaderRightBody>
      </StyledLoaderRight>
    </StyledLoaderBody>
  </StyledLoaderContainer>
);

export default Loader;
