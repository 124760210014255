import React, {useContext, useState} from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import BusinessServiceContext from "../../../../../providers/Service/business";
import {RenderHtml} from "../../../../Layout/Shared/RenderHtml";
import "react-mde/lib/styles/css/react-mde-all.css";
import {CustomTextArea} from "../../../../Layout/Shared/TextArea";
import ErrorField from "../../../../../components/Layout/Shared/ErrorField";
import "./index.scss";
const Summary = () => {
  const {
    values: {summary, shortDescription, errorSummary},
    setters: {setSummary, handleShortDesc},
  } = useContext(BusinessServiceContext);
  const [selectedTab, setSelectedTab] = useState("write");

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  return (
    <section className="p-5">
      <span className="d-flex pl-3 pb-4">General Service Information</span>
      <div>
        <CustomTextArea
          name="shortDescription"
          height="80px"
          maxlength="100"
          width="100%"
          placeholder="Short description (Max: 100 chars)"
          value={shortDescription}
          onChange={({target}) => handleShortDesc(target.value)}
        ></CustomTextArea>

        <div className="text-counter">
          Number of characters left
          <strong>{100 - shortDescription.length}</strong>
        </div>

        <span className="d-flex pl-3 mt-5 pb-2">Summary</span>
        <ReactMde
          value={summary}
          onChange={setSummary}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(converter.makeHtml(markdown))
          }
          childProps={{
            writeButton: {
              tabIndex: -1,
            },
          }}
        />
        <RenderHtml renderAs={"div"} html={converter.makeHtml(summary)} />
        {errorSummary && <ErrorField message={JSON.stringify(errorSummary)} />}
      </div>
    </section>
  );
};
export default Summary;
