import React, { useState, useEffect } from "react";
import Header from "../../../../components/Layout/Header/Header";
import { GenericPageLeft } from "../../../../components/Layout/Shared/GenericPageLeft";
import { PageContainer } from "../../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../../components/Layout/Shared/PageTitle";
import StageList from "../../../../components/CaseManagement/Stages/List";
import { getStages } from "../../../../data/CaseManagement/stages";
import DataLoading from "../../../../components/Layout/Shared/DataLoading";
import { CustomButton } from "../../../../components/Layout/Shared/Button";
import StageForm from "../../../../components/CaseManagement/Stages/Form";

const CaseManagementStagesListPage = () => {
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [stage, setStage] = useState({});

  const isEdit = stage && stage._id;

  const getData = async () => {
    setLoading(true);
    const { data, hasSuccess } = await getStages();
    hasSuccess ? setStages(data) : console.log(JSON.parse(data).description);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  const handleRefresh = async () => await getData();

  return (
    <section className="dashboard bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Case Management Stages</PageTitle>
              <div className="d-flex justify-content-end align-items-center">
                <CustomButton
                  color="blue"
                  title="Create Stage"
                  size="250px"
                  onClick={() => setShowFormModal(true)}
                />
              </div>
            </PageHeader>
            <div className="row m-2  bg-white border rounded-xl overflow-hidden">
              {loading ? (
                <DataLoading />
              ) : (
                <StageList
                  stages={stages}
                  setStage={setStage}
                  setShowFormModal={setShowFormModal}
                  refresh={handleRefresh}
                />
              )}
            </div>
            {showFormModal && (
              <StageForm
                title={isEdit ? "Edit Case Stage" : "Create Case Stage"}
                stage={stage}
                setStage={setStage}
                onClose={async () => {
                  setShowFormModal(false);
                  setStage({});
                }}
                onConfirm={async () => {
                  setShowFormModal(false);
                  setStage({});
                  await handleRefresh();
                }}
              />
            )}
          </PageContainer>
        </div>
      </div>
    </section>
  );
};
export default CaseManagementStagesListPage;
