import React from "react";

import KpiOption from "../CommonElements/KpiOption";
import BaselineOption from "../CommonElements/BaselineOption";
import QualitativeOption from "../CommonElements/QualitativeOption";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import RequireOption from "../CommonElements/RequireOption";
import {
  CheckboxOptionRadiusInput,
  CheckboxOptionTitle,
  BaseIcon,
  OptionDiv,
} from "../Base/BaseStyles";

export function CheckboxOptionsTab({component_key, attributes, dispatch}) {
  const options = getAttribute("options", attributes);
  const option_entry_title = getAttribute("option_entry_title", attributes);
  return (
    <div>
      <RequireOption
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <KpiOption
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      ></KpiOption>
      <BaselineOption
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <QualitativeOption
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <OptionDiv>
        <div className={"row"}>
          <CheckboxOptionTitle>Enter Data Options Below</CheckboxOptionTitle>
        </div>
        {options.map((option, i) => {
          return (
            <div className={"row"} key={component_key + "_option_" + i}>
              <div className={"col-11"}>
                <CheckboxOptionRadiusInput
                  type="text"
                  className="form-control"
                  aria-label=""
                  placeholder="Enter option"
                  onChange={(e) =>
                    dispatch({
                      type: "checkbox_change_option",
                      key: component_key,
                      option: option,
                      new_option: e.target.value,
                      index: i,
                    })
                  }
                  value={option}
                />
              </div>
              <div className={"col-1 d-flex align-items-center"}>
                <BaseIcon
                  clickable={true}
                  className={"bi bi-trash-fill"}
                  onClick={() =>
                    dispatch({
                      type: "checkbox_delete_option",
                      key: component_key,
                      option: option,
                    })
                  }
                />
              </div>
            </div>
          );
        })}
        <div className={"row"}>
          <div className={"col-11"}>
            <CheckboxOptionRadiusInput
              type="text"
              className="form-control"
              aria-label=""
              placeholder="Enter option"
              value={option_entry_title}
              onChange={(e) =>
                dispatch({
                  type: "checkbox_change_option_entry_title",
                  key: component_key,
                  title: e.target.value,
                })
              }
            />
          </div>
          <div className={"col-1 d-flex align-items-center"}>
            <BaseIcon
              clickable={true}
              className={"bi bi-plus-circle-fill"}
              onClick={() => {
                dispatch({
                  type: "checkbox_add_option",
                  key: component_key,
                  option: option_entry_title,
                });
              }}
            />
          </div>
        </div>
      </OptionDiv>
    </div>
  );
}

export default CheckboxOptionsTab;
