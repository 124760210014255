import React from "react";
import { StyledSearchContainer } from "./Search.style";

const Search = ({ value, onChange }) => {
  return (
    <StyledSearchContainer>
      <input
        className="custom-input search w-100 "
        action={{ icon: "search" }}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder="Search..."
      />
    </StyledSearchContainer>
  );
};

export default Search;
