/* eslint-disable jsx-a11y/anchor-is-valid */
import {Field, Form, Formik} from "formik";
import React from "react";
import PhoneInput from "react-phone-input-2";
import {LabelBlueBold} from "../../../components/Layout/Shared/LabelBlueBold";
import PhoneImage from "../../../assets/images/phoneImage.png";
import steps from "./steps";
import {StaffRegistrationInfo} from "../../../schemas/Staff/Business/staffRegistrationInfo";
import ErrorField from "../../../components/Layout/Shared/ErrorField";

const StaffInfo = ({goTo, staff, setStaff}) => {
  const handleOnSubmit = async (values) => {
    setStaff({...staff, ...values});
    goTo(steps.ADDRESS);
  };

  return (
    <div className="tab active" id="info">
      <div className="row">
        <div className="col-xl-6  col-lg-6  pl-0">
          <div className="component first">
            <h2>
              <span>Let’s get started, by setting up your account.</span>
            </h2>
            <div>
              <Formik
                initialValues={{
                  firstName: staff ? staff.firstName : "",
                  lastName: staff ? staff.lastName : "",
                  designation: staff ? staff.designation : "",
                  phone: staff ? staff.phone : "",
                }}
                validationSchema={StaffRegistrationInfo}
                onSubmit={handleOnSubmit}
              >
                {({errors, values}) => (
                  <>
                    <Form>
                      <div className="form-group">
                        <label>
                          <strong>Your Name</strong>
                        </label>
                        <div className=" d-flex justify-content-between">
                          <div>
                            <Field
                              type="text"
                              className="custom-input w-100"
                              placeholder="John"
                              id="firstName"
                              name="firstName"
                            />
                            <ErrorField message={errors.firstName} />
                          </div>
                          <div>
                            <Field
                              type="text"
                              className="custom-input w-100"
                              placeholder="Doe"
                              id="lastName"
                              name="lastName"
                            />
                            <ErrorField message={errors.lastName} />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          <strong>Designation</strong>
                        </label>
                        <Field
                          type="text"
                          className="custom-input"
                          placeholder="Sales Manager (Set by Owner)"
                          id="designation"
                          name="designation"
                          value={staff.designation}
                          disabled={true}
                        />
                        {/* <ErrorField message={errors.designation} /> */}
                      </div>
                      <div className="form-group custom-phone">
                        <LabelBlueBold content="Phone Number" />
                        <Field name="phone">
                          {({field: {value}, form: {setFieldValue}}) => (
                            <PhoneInput
                              enableSearch
                              placeholder="Enter Mobile Number"
                              country={"ca"}
                              value={value}
                              onChange={(number) =>
                                setFieldValue("phone", number)
                              }
                            />
                          )}
                        </Field>
                        <ErrorField message={errors.phone} />
                      </div>
                      <div className="form-group">
                        <div className="d-flex justify-content-end align-items-center mt-5">
                          <button
                            type="submit"
                            className="mt-0 btn btn-blue-gradient ms-5  btn-steps"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-md-none d-lg-none d-xl-block text-end">
          <div className="component second">
            <img src={PhoneImage} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default StaffInfo;
