import * as Yup from "yup";
import { getYesterday } from "./../../../utils/date";

const consultantSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(3, "Consultant name is too Short!")
    .max(50, "Consultant name is too Long!")
    .required("Consultant name is required"),
  email: Yup.string().email("Invalid email"),
  phone: Yup.string(),
  url: Yup.string().url("Invalid url")
});

export const BusinessCreateAppointmentSchema = Yup.object().shape({
  clientEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  firstName: Yup.string()
    .min(3, "First name is too Short!")
    .max(50, "First name is too Long!")
    .required("First name is required"),
  lastName: Yup.string()
    .min(3, "Last owner is too Short!")
    .max(50, "Last owner is too Long!")
    .required("Last owner is required"),
  appointmentDate: Yup.date()
    .min(getYesterday(), "Appointment date can not be in the the past!")
    .required("Apt. Date is required!"),
  consultant: consultantSchema
});

export const BusinessUpdateAppointmentSchema = Yup.object().shape({
  clientEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  firstName: Yup.string()
    .min(3, "First name is too Short!")
    .max(50, "First name is too Long!")
    .required("First name is required"),
  lastName: Yup.string()
    .min(3, "Last owner is too Short!")
    .max(50, "Last owner is too Long!")
    .required("Last owner is required"),
  appointmentDate: Yup.date()
    .min(getYesterday(), "Appointment date can not be in the the past!")
    .required("Apt. Date is required!"),
  consultant: consultantSchema
});
