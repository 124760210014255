import { isStaff } from "../utils/account";

export const goToAppForBusiness = (history, user) => {
  if (user.onboardingCompleted) {
    if (isStaff(user)) {
      history.push("/staff/dashboard");
    } else {
      history.push("/business/dashboard");
    }
  } else {
    history.push("/business/onboarding");
  }
};

export const goToAppForClient = (history, user) => {
  if (user.onboardingCompleted) {
    history.push("/client/dashboard");
  } else {
    history.push("/client/onboarding");
  }
};
