/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import { v4 as uuidv4 } from "uuid";
import { GenericPageLeft } from "../../../components/Layout/Shared/GenericPageLeft";
import Header from "../../../components/Layout/Header/Header";
import { PageContainer } from "../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../components/Layout/Shared/PageTitle";
import { TabContainer } from "../../../components/Services/Business/Update/TabContainer";
import BusinessServiceContext from "../../../providers/Service/business";
import { getServiceById } from "../../../data/Service/Business/getServiceById";
import DataLoading from "../../../components/Layout/Shared/DataLoading";
import { CustomButton } from "../../../components/Layout/Shared/Button";
import { ButtonAnalytics } from "../../../components/Layout/Shared/Button/Analytics";
import { updateService } from "../../../data/Service/Business/updateService";
import { LastUpdate } from "../../../components/Layout/Shared/LastUpdate";
import UpdateBusinessServiceSchema from "../../../schemas/Services/Business/update";
import ErrorField from "../../../components/Layout/Shared/ErrorField";
import MultipleCitySelection from "../../../components/Layout/Shared/MultipleCitySelection";
import { GlobalContext } from "../../../providers/global";
import { getProfileInfo } from "../../../data/ProfileSettings/Business/getProfileInfo";
import { GlobalInfo } from "../../../components/Layout/Shared/Modal/Info";
import { DEFAULT_SELECTION_VALUE } from "../../../utils/string";
import { string } from "yup";
import "./createService.scss";
import { ServiceTypeSelect } from "../../../components/Services/Business/Shared";

const UpdateService = () => {
  const history = useHistory();
  const { serviceId } = useParams();
  const [error, setError] = useState(null);

  const [serviceDetails, setServiceDetails] = useState({
    name: "",
    owner: "",
    email: "",
    phone: "",
    industry: DEFAULT_SELECTION_VALUE,
    type: DEFAULT_SELECTION_VALUE
  });
  const [attachmentId, setAttachmentId] = useState(uuidv4());
  const [summary, setSummary] = useState("");
  const [metaData, setMetaData] = useState({});
  const [schedule, setSchedule] = useState({
    days: [],
    holidays: [],
    timeZone: {
      timeZone: "EST",
      timeZoneName: "America/Toronto"
    }
  });
  const [users, setUsers] = useState([]);
  const [communicationChannel, setCommunicationChannel] = useState({
    appointments: false,
    calls: false,
    textAndMessages: false
  });
  const [forms, setForms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [availableLocations, setAvailableLocations] = useState([]);
  const [shortDescription, setShortDescription] = useState("");
  const { industries, setIndustries } = useContext(GlobalContext);
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const goToProfileSettings = () => history.push("/business/settings/profile");
  const isIndustriesEmpty = () => industries && industries.length === 0;

  const [errorSummary, setErrorSummary] = useState(null);

  const constructor = async () => {
    if (constructorHasRun) return;
    if (!industries) await loadIndustries();
    if (isIndustriesEmpty()) setShowInfo(true);
    setConstructorHasRun(true);
  };

  const loadIndustries = async () => {
    const { hasSuccess, data } = await getProfileInfo();
    if (!hasSuccess) return;
    const { details } = data;
    setIndustries(details.industries);
  };

  useEffect(() => {
    const init = async () => {
      const { data, hasSuccess } = await getServiceById(serviceId);
      if (hasSuccess) {
        setSummary(data.summary);
        setMetaData({ lastUpdate: data.updated_at });
        setServiceDetails({
          name: data.name,
          owner: data.owner,
          email: data.email,
          phone: data.phone,
          industry: data.industry,
          type: data.type
        });
        setAttachmentId(data.attachmentId);
        setCommunicationChannel(data.communicationChannel || {});
        setSchedule(data.schedule || []);
        setForms(data.forms || []);
        setUsers(data.users || []);
        setAvailableLocations(data.availableLocations || []);
        setShortDescription(data.shortDescription || "");
      }
      setIsLoading(false);
    };
    init();
  }, [serviceId]);

  useEffect(() => {}, [
    serviceId,
    serviceDetails,
    summary,
    forms,
    users,
    schedule,
    communicationChannel,
    availableLocations,
    shortDescription
  ]);

  const validateForm = async form => {
    try {
      await UpdateBusinessServiceSchema.validate(form);
    } catch (error) {
      return error;
    }
  };

  const validateShortDesc = async value => {
    try {
      await string()
        .required("General Service Information is required!")
        .min(10, "General Service Information is too Short!")
        .max(100, "General Service Information is too Long!")
        .validate(value);
    } catch (error) {
      return error;
    }
  };

  const handleShortDesc = async value => {
    setShortDescription(value);
    const validationResponse = await validateShortDesc(value);
    if (validationResponse && validationResponse.errors)
      setErrorSummary(validationResponse.errors.shift());
    else setErrorSummary(null);
  };

  const save = async () => {
    let validationResponse = await validateForm(serviceDetails);
    if (validationResponse && validationResponse.errors) {
      setError(validationResponse.errors.shift());
      return;
    }

    validationResponse = await validateShortDesc(shortDescription);
    if (validationResponse && validationResponse.errors) {
      setErrorSummary(validationResponse.errors.shift());
      return;
    }
    const values = {
      ...serviceDetails,
      summary,
      forms,
      users,
      schedule,
      communicationChannel,
      availableLocations,
      shortDescription
    };
    const { hasSuccess, data } = await updateService({
      ...values,
      id: serviceId
    });
    if (hasSuccess) {
      history.push("/business/service/list");
    } else {
      console.log(JSON.parse(data).description);
    }
  };

  constructor();
  return (
    <BusinessServiceContext.Provider
      value={{
        values: {
          serviceDetails,
          summary,
          forms,
          users,
          schedule,
          communicationChannel,
          serviceId,
          attachmentId,
          availableLocations,
          shortDescription,
          errorSummary
        },
        setters: {
          setServiceDetails,
          setSummary,
          setForms,
          setUsers,
          setSchedule,
          setCommunicationChannel,
          setAvailableLocations,
          handleShortDesc
        }
      }}
    >
      <>
        <section className="dashboard  bg-paleGray pb-5 pr-3">
          <Header />
          <div className="container-fluid mt-5 pt-3">
            <div className="row">
              <GenericPageLeft />
              <PageContainer>
                <PageHeader>
                  <PageTitle>{serviceDetails.name}</PageTitle>
                  <div className="d-flex justify-content-end align-items-center">
                    <LastUpdate date={metaData.lastUpdate} />
                    <CustomButton
                      color="blue"
                      title="Save"
                      size="160px"
                      onClick={() => save()}
                    />
                    <ButtonAnalytics />
                  </div>
                </PageHeader>
                <div className="row m-2  bg-white border rounded-xl create-service-main">
                  {isLoading ? (
                    <DataLoading height="500px" />
                  ) : (
                    <>
                      <div className="col-xl-3 col-lg-12 p-0 border-end">
                        <Formik initialValues={serviceDetails}>
                          {({ values }) => (
                            <Form>
                              {setServiceDetails(values)}
                              <div className="create-service-form border-bottom">
                                <div className="form">
                                  <div className="form-group">
                                    <Field
                                      className="custom-input"
                                      placeholder="Service Name"
                                      name="name"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <Field
                                      className="custom-input"
                                      placeholder="Service Owner"
                                      name="owner"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <Field
                                      className="custom-input"
                                      placeholder="Email Address"
                                      name="email"
                                    />
                                  </div>

                                  <div className="form-group  custom-phone">
                                    <Field name="phone">
                                      {({
                                        field: { value },
                                        form: { setFieldValue }
                                      }) => (
                                        <PhoneInput
                                          enableSearch
                                          placeholder="Phone Number"
                                          country={"ca"}
                                          value={value}
                                          onChange={number =>
                                            setFieldValue("phone", number)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </div>

                                  <div className="form-group">
                                    <Field name="industry">
                                      {({
                                        field: { value },
                                        form: { setFieldValue }
                                      }) => (
                                        <select
                                          className="primary-select"
                                          placeholder="Select Services Category"
                                          value={value}
                                          onChange={({ target }) =>
                                            setFieldValue(
                                              "industry",
                                              target.value
                                            )
                                          }
                                        >
                                          <option
                                            value={DEFAULT_SELECTION_VALUE}
                                          >
                                            Select Services Category
                                          </option>
                                          {!industries
                                            ? []
                                            : industries.map(
                                                (option, index) => (
                                                  <Fragment key={index}>
                                                    <option value={option}>
                                                      {option}
                                                    </option>
                                                  </Fragment>
                                                )
                                              )}
                                        </select>
                                      )}
                                    </Field>
                                  </div>

                                  <div className="form-group">
                                    <Field name="type">
                                      {({
                                        field: { value },
                                        form: { setFieldValue }
                                      }) => (
                                        <ServiceTypeSelect
                                          type={value}
                                          onChange={value =>
                                            setFieldValue("type", value)
                                          }
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                        {error && (
                          <ErrorField message={JSON.stringify(error)} />
                        )}
                        <div className="create-service-form border-bottom">
                          <MultipleCitySelection
                            locations={availableLocations}
                            setLocations={setAvailableLocations}
                          />
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-12 p-0">
                        <TabContainer />
                      </div>
                    </>
                  )}
                </div>
              </PageContainer>
            </div>
          </div>
        </section>
        {showInfo && (
          <GlobalInfo
            heading="Info"
            message="The business has not any industry so select at least one in order to update service. You are redirecting to Profile Settings."
            onClose={goToProfileSettings}
            showCloseButton={
              <button
                className="btn-blue btn-small mt-2"
                onClick={goToProfileSettings}
              >
                Go to profile settings
              </button>
            }
          />
        )}
      </>
    </BusinessServiceContext.Provider>
  );
};
export default UpdateService;
