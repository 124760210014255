import styled from "styled-components";

export const StyledSearchList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
`;

export const StyledSearchItem = styled.div`
  padding: 1.3rem 2rem;
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  a {
    color: #1b84e7;
    text-decoration: none;
    &:hover {
      text-decoration: underline !important;
    }
  }
`;
