import React, {useState} from "react";
import {Options} from "../../../Layout/Shared/DropdownOptionsMenu";
import {ThreeDotIcon} from "../../../../assets/icons/icon";
import {DeleteModal} from "../../../Layout/Shared/Popups/Delete";
import {useHistory} from "react-router-dom";
import {
  active,
  inActive,
  isActive,
  isInactive,
} from "../../../../constants/services";
import {updateService} from "../../../../data/Service/Business/updateService";
import {deleteService} from "../../../../data/Service/Business/delete";

export const ActionButtons = ({status, id, refreshPage}) => {
  const [showDelete, setshowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const goToServiceDetail = () => history.push(`/business/service/view/${id}`);

  const goToEditServicePage = () =>
    history.push(`/business/service/update/${id}`);  

  const updateServiceStatus = async (status) => {
    setIsLoading(true);
    await updateService({
      id,
      status,
    });
    refreshPage();
    setIsLoading(false);
  };

  const deleteServiceById = async () => {
    const {hasSuccess} = await deleteService(id);
    if (hasSuccess) {
      refreshPage();
      setshowDelete(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <span className="dots-loading">{ThreeDotIcon}</span>
      ) : (
        <Options
          arrow={false}
          buttonTitle={ThreeDotIcon}
          menuItems={
            <div>
              <button onClick={() => goToServiceDetail()}>View</button>
              <button onClick={() => goToEditServicePage()}>Edit</button>
              {isInactive(status) && (
                <button onClick={() => updateServiceStatus(active)}>
                  Activate
                </button>
              )}
              {isActive(status) && (
                <button onClick={() => updateServiceStatus(inActive)}>
                  Deactivate
                </button>
              )}
              <button
                className="text-danger"
                onClick={() => setshowDelete(!showDelete)}
              >
                Delete
              </button>
            </div>
          }
        />
      )}

      {showDelete && (
        <DeleteModal
          title="Delete Service"
          text=" You are about to delete this service from your service list. Deleting
          this client removes all client’s information and ends all previous
          communication between you and the client. 
          "
          buttonText="Delete"
          onConfirm={deleteServiceById}
          onClose={() => setshowDelete(false)}
        />
      )}
    </>
  );
};
