import React, {useState} from "react";
import Modal from "../../../Layout/Shared/Modal";
import {ScrollContent} from "../../../Layout/Shared/ScrollContent";
import {CustomButton} from "../../../Layout/Shared/Button";
import "../../../../styles/customComponents/shared.scss";
import "../index.scss";
import Table from "../../../Layout/Shared/Table";
import {createList} from "../../../../data/Contact/Business/createList";
import {ListLoader} from "../../../Layout/Shared/DataLoading";
import {validationSchema} from "../../../../schemas/Contact/Business/createList";
import ErrorField from "../../../Layout/Shared/ErrorField";

const buttonAddGroup = (
  <button className="btn btn-white-border-black ms-5 ">Add to Group</button>
);

export const ImportSelectedContact = ({
  onClose,
  onConfirm,
  contacts,
  setContacts,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const toString = (text) => String(text || "");
  const mapContacts = (contact) => ({
    firstName: toString(contact.firstName),
    lastName: toString(contact.lastName),
    designation: toString(contact.designation),
    email: toString(contact.email),
    phone: toString(contact.phone),
    address: toString(contact.address),
    city: toString(contact.city),
    state: toString(contact.state),
    country: toString(contact.country),
    zipCode: toString(contact.zipCode),
  });

  const validate = async (data) => {
    try {
      await validationSchema.validate(data);
    } catch (error) {
      return error;
    }
  };

  const handleSubmit = async () => {
    const createContacts = contacts
      .filter((contact) => contact.isSelected)
      .map(mapContacts);
    const validationResponse = await validate({
      contacts: createContacts,
    });
    if (validationResponse && validationResponse.errors) {
      setError(validationResponse.errors.shift());
      return;
    } else {
      setError(null);
    }
    setIsSaving(true);
    const {hasSuccess} = await createList(createContacts);
    if (hasSuccess) {
      onClose();
      onConfirm();
      setIsSaving(false);
    }
  };
  const Name = ({row}) => <span>{`${row.firstName} ${row.lastName}`}</span>;
  const CheckBox = ({row}) => (
    <input
      type="checkbox"
      checked={row.isSelected}
      onChange={() => toggleChecked(row.id)}
    />
  );
  const toggleChecked = (id) =>
    setContacts(
      contacts.map((item) => {
        return {
          ...item,
          isSelected: item.id === id ? !item.isSelected : item.isSelected,
        };
      })
    );
  const fields = [{render: CheckBox}, {render: Name}, "email", "phone"];
  const headers = [
    {
      displayName: "",
      sortingFieldName: "isSelected",
    },
    {
      displayName: "Name",
      sortingFieldName: "name",
    },
    {
      displayName: "Email",
      sortingFieldName: "email",
    },
    {
      displayName: "Phone",
      sortingFieldName: "phone",
    },
  ];
  const options = {
    data: contacts,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    itemsPerPage: 25,
    headerAction: () => <p></p>,
  };
  return (
    <Modal
      title="Import Contact"
      width="718px"
      height="700px"
      extra={buttonAddGroup}
      close={() => {
        onClose();
      }}
    >
      {isSaving ? (
        <ListLoader height="550px" />
      ) : (
        <div>
          <p className="mt-3 mb-5">
            You have imported
            <span className="text-primary-blue">
              {` ${
                contacts.filter((contact) => contact.isSelected).length
              } contact(s) `}
            </span>
            to add to your organization contact list. If you proceed, an
            Invitation with Instructions will be sent to the client’s email
            address.
          </p>
          <ScrollContent height="400px">
            <Table options={options} />
          </ScrollContent>
          <div>{error && <ErrorField message={error} />}</div>
          <div className="d-flex  align-items-center pt-5 mt-5">
            <span className="flex-grow-1">
              No CSV or vCard file? <a href="create">Create Contact</a> instead.
            </span>
            <button
              onClick={() => onClose()}
              className="btn btn-text-blue me-4 "
            >
              Cancel
            </button>
            <CustomButton
              title="Import"
              size="160px"
              color="blue"
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
