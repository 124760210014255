import DashboardPage from "../../pages/Staff/Dashboard";
import Registration from "../../pages/Staff/Registration";
import OnboardingPage from "../../pages/Staff/Onboarding";
import ResetPasswordPage from '../../pages/ResetPassword'

const staffPrefix = "staff";

export const staffRoutes = [
  {
    path: `/${staffPrefix}/registration`,
    component: Registration,
    isPrivate: false,
  },
  {
    path: `/${staffPrefix}/onboarding`,
    component: OnboardingPage,
    isPrivate: false,
  },
  {
    path: `/${staffPrefix}/reset-password/:token`,
    component: ResetPasswordPage,
    isPrivate: false,
  },
  {
    path: `/${staffPrefix}/dashboard`,
    component: DashboardPage,
    isPrivate: false,
  },
];
