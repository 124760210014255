import React, {useContext} from "react";
import BusinessServiceContext from "../../../../../providers/Service/business";
export const Industry = () => {
  const {service} = useContext(BusinessServiceContext);
  const {industry} = service;
  return (
    <>
      <div className="owner-details">
        <h4>Services Category</h4>
        <p>{industry}</p>
      </div>
    </>
  );
};
