import React from "react";
import UserInfo from "./UserInfo.component";

const ChatSidebarHeader = ({ showNewMessage, setShowNewMessage }) => (
  <>
    <UserInfo
      showNewMessage={showNewMessage}
      setShowNewMessage={setShowNewMessage}
    />
  </>
);

export default ChatSidebarHeader;
