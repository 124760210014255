import React, {Fragment} from "react";
import ESignatureCanvas from "./ESignatureCanvas";
import ESignatureAssigneesOptions from "./ESignatureAssigneesOptions";

export function ESignatureCommon({dispatch, attributes, component_key}) {
  return (
    <Fragment>
      <ESignatureCanvas
        dispatch={dispatch}
        attributes={attributes}
        component_key={component_key}
      />
      <br></br>
      <br></br>
      <hr></hr>
      <ESignatureAssigneesOptions
        dispatch={dispatch}
        attributes={attributes}
        component_key={component_key}
      />
    </Fragment>
  );
}

export default ESignatureCommon;
