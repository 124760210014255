import React, {Fragment} from "react";
import TextareaHover from "./TextareaHover";
import TextareaEdit from "./TextareaEdit";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";

export function Textarea({dispatch, attributes, component_key}) {
  const confirmed = getAttribute("confirmed", attributes);
  return (
    <Fragment>
      {confirmed === "true" ? (
        <TextareaHover
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      ) : (
        <TextareaEdit
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      )}
    </Fragment>
  );
}

export default Textarea;
