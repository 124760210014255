import React from "react";
import { CustomButton } from "../../Layout/Shared/Button";
import { ClientInformation } from "./Information";
import { useHistory } from "react-router-dom";
import { InnerPageLeft } from "../../Layout/Shared/InnerPageLeft";
import DataLoading from "../../Layout/Shared/DataLoading";
import "./index.scss";
import { useBusinessClientCreateContext } from "../../../providers/Client/create";

export const AddNewClient = props => {
  const history = useHistory();
  const goToCreateList = () => history.push(`/business/client/list`);
  const { getters, submit } = useBusinessClientCreateContext();
  const { isSubmitting, checkResult, checkedClient } = getters;

  const { isInUse, isInvitedByBusiness, isInvitedByAnotherBusiness } =
    checkResult;

  const handleInviteClientOnClick = async () => {
    const hasSuccess = await submit();
    if (hasSuccess) goToCreateList();
  };

  const disabled = !checkedClient || (isInUse && isInvitedByBusiness);

  return (
    <div className="row m-2  bg-white border rounded-xl">
      <div className="col-md-12 p-5 border-bottom"></div>
      {isSubmitting ? (
        <DataLoading />
      ) : (
        <>
          <div className="contact-left border-end">
            <InnerPageLeft>
              <div className="row"></div>
            </InnerPageLeft>
          </div>
          <div className="contact-right  pl-0 pe-5  pt-5">
            <div className="row pb-5 add-contact-page">
              <div className="page-left ps-5 mb-5">
                <ClientInformation />
              </div>
              <div className="page-right pt-5 mt-5 ps-5 mb-5">
                <div className="col-12 button-wrapper">
                  <button
                    className="btn btn-link font-weight-bold me-5 "
                    onClick={goToCreateList}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    title="Sent Invitation to Client"
                    size="189px"
                    color="blue"
                    onClick={handleInviteClientOnClick}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="col-12">
                {checkedClient && isInUse && (
                  <div className="alert alert-danger">
                    This email is in use.
                  </div>
                )}
                {checkedClient && isInvitedByBusiness && (
                  <div className="alert alert-danger">
                    This email is already in use with this business.
                  </div>
                )}
                {checkedClient && isInvitedByAnotherBusiness && (
                  <div className="alert alert-danger">
                    This email is in use with another business.
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
