import React, { useState } from "react";
import "../../../../../styles/customComponents/servicePageHeader.scss";
import { CustomButton } from "../../../../Layout/Shared/Button";
import { Options } from "../../../../Layout/Shared/DropdownOptionsMenu";
import Table from "../../../../Layout/Shared/Table";
import { ThreeDotIcon } from "../../../../../assets/icons/icon";
import { AddForms } from "./AddForms";
import { DeleteModal } from "../../../../Layout/Shared/Popups/Delete";
import useBusinessCaseManagementCaseFormContext from "../../../../../providers/CaseManagement/Cases/form";
import { FormattedDateTime } from "../../../../Layout/Shared/FormattedDate";
import { useHistory } from "react-router-dom";

const Forms = () => {
  const { forms: _ } = useBusinessCaseManagementCaseFormContext();
  const { forms, createForm, removeForm } = _;
  const history = useHistory();

  const goToSubmissions = id =>
    history.push(`/business/forms/${id}/submissions`);

  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const ActionButtons = ({ row }) => (
    <>
      <Options
        arrow={false}
        buttonTitle={ThreeDotIcon}
        menuItems={
          <div>
            <button
              className="text-danger"
              onClick={() => setShowDelete(!showDelete)}
            >
              Delete
            </button>
            <button
              type="button"
              onClick={() => {
                window.open(`/preview/${row._id}`, "_blank");
              }}
            >
              View
            </button>
            <button type="button" onClick={() => goToSubmissions(row._id)}>
              Submissions
            </button>
          </div>
        }
      />
      {showDelete && (
        <DeleteModal
          title="Delete Form"
          text="You are about to delete this form. Deleting this form will remove it from all cases it is associated with. Do you want to proceed?"
          buttonText="Delete"
          onConfirm={() => onDeleteForm(row._id)}
          onClose={() => setShowDelete(!showDelete)}
        />
      )}
    </>
  );
  const ActionsComponent = () => (
    <CustomButton
      color="blue"
      title="Add Form"
      size="140px"
      onClick={() => setShowModal(!showModal)}
    />
  );

  const fields = [
    "_id",
    "title",
    { render: ({ row }) => <FormattedDateTime date={row.updatedAt} /> },
    { render: ActionButtons }
  ];
  const headers = [
    {
      displayName: "Id",
      sortingFieldName: "_id",
      sortingOptions: { dataType: "number" }
    },
    {
      displayName: "Form Name",
      sortingFieldName: "tile"
    },
    {
      displayName: "Last Modified",
      sortingFieldName: "updatedAt"
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];

  const options = {
    data: forms || [],
    headers,
    fields,
    itemsPerPage: 25,
    pagination: true,
    search: true,
    sort: true,
    headerAction: ActionsComponent
  };

  const onDeleteForm = async id => await removeForm(id);

  const handleOnSave = async forms => {
    const ids = forms.map(form => form.id);
    await createForm({
      forms: ids
    });
  };

  return (
    <section className="p-5 ">
      <div className="service-page-header">
        <Table options={options} />
      </div>
      {showModal && (
        <AddForms
          selectedForms={forms}
          onSave={handleOnSave}
          onClose={() => setShowModal(!showModal)}
        />
      )}
    </section>
  );
};
export default Forms;
