export const CustomTextArea = ({width, height, children, ...rest}) => {
  return (
    <textarea
      className="custom-textarea"
      style={{width: width, height: height}}
      {...rest}
    >
      {children}
    </textarea>
  );
};
