import React from "react";
import {CustomButton} from "../../../../Layout/Shared/Button";
import Modal from "../../../../Layout/Shared/Modal";
import {AppointmentCompanyDetails} from "./Company";
import {AppointmentConsultantDetails} from "./Consultant";
import {AppointmentReminder} from "./Reminder";
import {AppointmentSchedule} from "./Schedule";
import CompanyLogo from "../../../../../assets/images/default-business-image.png";
import SampleAvatar from "../../../../../assets/images/sample-avatar.png";
import "./index.scss";
export const AppointmentOverview = ({goBack, onClose}) => {
  const companyData = {
    logo: CompanyLogo,
    name: "Ottawa World Skills",
    address: "219 Argyle Avenue",
    city: "Ottawa",
    country: "Canada",
    zipCode: "K3N 7H9",
  };
  const consultant = {
    avatar: SampleAvatar,
    name: "Michel Smith",
    email: "michel.smith@gmail.com",
    phone: "+1 975 5245 554",
  };
  const schedule = {
    day: "Thursday",
    date: "Mar 12, 2021",
    time: "11:00 AM",
  };

  return (
    <Modal
      title="Appointment Overview"
      width="920px"
      height="570px"
      close={onClose}
    >
      <div className="appointment-overview">
        <div className="overview-top">
          <AppointmentCompanyDetails companyData={companyData} />
          <AppointmentConsultantDetails consultantData={consultant} />
          <AppointmentSchedule scheduleData={schedule} />
        </div>
        <div className="overview-body">
          <AppointmentReminder />
        </div>
        <div className="overview-bottom">
          <div>
            <button className="btn-text-blue" onClick={goBack}>
              Go Back
            </button>
          </div>
          <button onClick={onClose} className="btn-text-blue">
            Cancel
          </button>
          <CustomButton
            title="Schedule Appointment"
            size="195px"
            color="blue"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};
