const inputNumberDefaultOptions = {
  value: '',
  column: '1/1',
  placeholderText: '',
  kpiText: '',
  baseLineText: '',
  isRequired: false,
  requiredErrorMessage: '',
}

export default inputNumberDefaultOptions
