/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import useBusinessCaseManagementCaseFormContext from "../../../../../providers/CaseManagement/Cases/form";
import { ListLoader } from "../../../../Layout/Shared/DataLoading";
import { CustomButton } from "../../../../Layout/Shared/Button";
import AttachedFile from "../../../../../assets/images/attached-icon.svg";
import "./index.scss";
import { DeleteIconRed } from "../../../../../assets/icons/icon";
import { DeleteModal } from "../../../../Layout/Shared/Popups/Delete";

export const AttachmentsList = () => {
  const { attachments: _ } = useBusinessCaseManagementCaseFormContext();
  const { attachments, loading, getAttachments, removeAttachment } = _;

  const [ShowDelete, setShowDelete] = useState(false);

  const deleteServiceAttachment = async key => {
    const hasSuccess = await removeAttachment(key);
    if (hasSuccess) getAttachments();
  };

  return (
    <>
      {loading ? (
        <ListLoader height="450px" />
      ) : (
        <section>
          <div>
            {attachments.length > 0 ? (
              <div className="col-xl-12 attached-files">
                <label className="h4 font-weight-bold">Attached Files</label>
                <ul className="file-list">
                  {attachments.map(file => (
                    <li key={file.name}>
                      <div>
                        <img src={AttachedFile} alt="" />
                      </div>
                      <div className="file-name">
                        {file.name}
                        <small>{file.size}KB</small>
                      </div>
                      <div>
                        <CustomButton
                          title="View"
                          size="98px"
                          href={file.location}
                          target="_blank"
                          color="blue"
                          onClick={() => window.open(file.location, "_blank")}
                        />
                        <button
                          className="btn btn-circle-red"
                          onClick={() => setShowDelete(!ShowDelete)}
                        >
                          {DeleteIconRed}
                        </button>
                        {ShowDelete && (
                          <DeleteModal
                            title="Delete file"
                            buttonText="Delete"
                            cancel={true}
                            text="This file will be permanently deleted."
                            onClose={() => setShowDelete(!ShowDelete)}
                            onConfirm={() => deleteServiceAttachment(file.key)}
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="col-xl-12">
                <label className="h4 font-weight-bold">No Attached Files</label>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};
