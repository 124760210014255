import { useHistory } from "react-router-dom";
import "./index.scss";
import ReactStars from "react-rating-stars-component";
import { Bell18x18, CalendarTick18x18 } from "../../../../../assets/icons/icon";
import { RenderHtml } from "../../../../Layout/Shared/RenderHtml";
import * as Showdown from "showdown";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export const ServiceBox = ({ services }) => {
  const history = useHistory();
  const goToServiceDetail = (id) => history.push(`/client/service/detail/${id}`, {
    relevant: services.find(service => service._id === id)
  });


  return (
    <div className="find-client-service">
      {services.map((service, index) => (
        <div className="service-box" key={index}>
          <div className="top">
            <button className="text-primary-blue" onClick = {() => goToServiceDetail(service._id)}>{service.name}</button>
            <div className="description">
              <RenderHtml
                renderAs={"div"}
                html={converter.makeHtml(service.shortDescription)}
              />
            </div>
            <div className="features">
              <span>
                {CalendarTick18x18}
                {service.schedule?.days?.length > 0 ? (
                  <span>{service.schedule?.days?.length} Day(s) a Week</span>
                ) : (
                  <span>N/A</span>
                )}
              </span>
              <span>
                {Bell18x18}
                {"125"}
              </span>
            </div>
          </div>
          <hr className="divider" />
          <div className="bottom">
            <div className="logo">
              <img src={service.businessLogo} alt="" />
            </div>
            <div>
              <h4>{service.businessName}</h4>
              <div className="rating-space">
                <ReactStars value={service.rating} size={18} />
                <span className="rating-count">{"125"}</span>
                <button onClick = {() => goToServiceDetail(service._id)}> View</button>
            </div>
          </div>
        </div>
        </div>
  ))
}
    </div >
  );
};
