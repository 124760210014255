import {React, useState, useEffect} from "react";
import {InlineText} from "../../../../Layout/Shared/Paragraph";
import {getTotalReviews} from "../../../../../data/Service/Business/getTotalReviews";
import {ListLoader} from "../../../../Layout/Shared/DataLoading";

export const ReviewTotals = ({renderCount}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totals, setTotals] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const init = async () => {
      const {data: response, hasSuccess} = await getTotalReviews();
      hasSuccess
        ? setTotals(response)
        : console.log(JSON.parse(response).description);
      setIsLoading(false);
    };
    init();
  }, [renderCount]);
  return (
    <>
      {isLoading ? (
        <ListLoader height="300px" />
      ) : (
        <>
          {totals.map((total, index) => (
            <InlineText
              key={index}
              text={total.name}
              rightValue={total.numberOfTotal}
            />
          ))}
        </>
      )}
    </>
  );
};
