import {contactApiHostName} from "../../endpoint";
import {defaultHeaders} from "../../headers";

export const share = async (contactId, shareInfo) => {
  try {
    const result = await fetch(
      `${contactApiHostName}/share-contact/${contactId}`,
      {
        method: "POST",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(shareInfo),
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    };
  }
};
