import {Consultant} from "../../Consultant";
import Avatar from "../../../../../../assets/images/sample-avatar.png";
import {ServiceSummary} from "../../../../Shared/Summary";
import {Schedule} from "../../../../Shared/Schedule";
import {CompanyProfile} from "../../../FindService/CompanyProfile";
import DefaultBusinessLogo from "../../../../../../assets/images/default-business-image.png";
import "./index.scss";
const data = {
  consultantName: "Michel Smith",
  job: "Immigration Consultant",
  email: "michel.smith@gmail.com",
  phone: "+1 975 5245 554",
  avatar: Avatar,
  time: "2021-03-16T10:30",
};
const schedule = {
  days: [
    {
      day: "Sunday",
      isSelected: true,
      start: "09:00 AM",
      end: "06:00 PM",
    },
    {
      day: "Monday",
      isSelected: false,
      start: "09:00 AM",
      end: "06:00 PM",
    },
  ],
  holidays: [
    {
      name: "Public Holiday",
      date: "2021-03-16T13:44:43.594+00:00",
      recurrence: "One Time",
    },
  ],
};
const companyData = {
  logo: DefaultBusinessLogo,
  companyName: "Webron Software",
  rating: "4",
  ratingCount: "215",
  city: "Ottawa",
  country: "Canada",
  companyStatus: true,
  distance: "250",
  timeOpen: "Mon-Fri, 9:00 AM",
  timeClose: "6:00 PM",
  languages: ["English", "German"],
};
export const ServiceDetails = () => {
  return (
    <div className="applied-service">
      <div className="wrapper">
        <div className="left-block pb-5">
          <div className="p-5">
            <ServiceSummary
              summary={
                "General Service Information saepe quis tempora voluptatum molestiae soluta General Service Information saepe quis tempora voluptatum molestiae soluta vel. Aut quo facere quisquam harum. General Service Information saepe quis "
              }
            />
            <div className="mt-5">
              <Schedule schedule={schedule} />
            </div>
          </div>
          <hr />
          <div className="p-5 mb-5">
            <CompanyProfile data={companyData} />
          </div>
        </div>

        <div className="right-block">
          <div className="consultant-area p-5">
            <Consultant data={data} isOnline={true} />
          </div>
          <div className="d-flex justify-content-center order-number">
            Order No: [68254]
          </div>
        </div>
      </div>
    </div>
  );
};
