import Modal from "../../../../../Layout/Shared/Modal";
import {CustomButton} from "../../../../../Layout/Shared/Button";
export const DeactivateStaff = ({onClose, username, onConfirm}) => {
  return (
    <Modal
      title=" Deactivate Staff"
      width="510px"
      height="280px"
      close={onClose}
    >
      <p>
        You are about to deactivate {username}’s account. Deactivating this
        staff revokes all access to your account and settings. <br />
        <br />
        Are you sure you want to continue?
      </p>
      <div className="mt-4 d-flex justify-content-end">
        <button className="btn-text-blue me-5 " onClick={onClose}>
          Cancel
        </button>
        <CustomButton
          color="blue"
          title="Deactivate"
          size="160px"
          onClick={onConfirm}
        />
      </div>
    </Modal>
  );
};
