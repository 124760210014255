/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import useBusinessCaseManagementCaseFormContext from "../../../../../providers/CaseManagement/Cases/form";
import { HeaderButtons } from "../../../../Layout/Shared/PageHeader/HeaderButtons";
import { CustomButton } from "../../../../Layout/Shared/Button";
import { FormattedDate } from "../../../../Layout/Shared/FormattedDate";
import {
  StatusBadge,
  PriorityBadge,
  CancelledBadge,
  DisplayNo
} from "../../../Shared";
import Table from "../../../../Layout/Shared/Table";
import { ActionButtons } from "./actions";
import TaskForm from "../../../Tasks/Form";
import { getFullNameFromObject } from "../../../../../utils/string";
import { ConfirmDialogV2 } from "../../../../Layout/Shared/Popups/ConfirmDialog";
import DataLoading from "../../../../Layout/Shared/DataLoading";

const Tasks = () => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [cancelId, setCancelId] = useState(null);
  const [showRemove, setShowRemove] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { tasks: _, caseFormData } = useBusinessCaseManagementCaseFormContext();
  const { tasks, load: loadTasks, taskModel, loading } = _;
  const { load: loadTask, delete: deleteTask, cancel: cancelTask } = taskModel;
  const { staffs = [], loading: caseFormDataLoading } = caseFormData;

  const handleOnEdit = async ({ _id }) => {
    setShowFormModal(true);
    await loadTask(_id);
  };

  const handleOnCreate = async () => {
    taskModel.reset();
    setShowFormModal(true);
  };

  const handleOnSubmit = async () => {
    const result = await taskModel.submit();
    if (result) {
      setShowFormModal(false);
      await loadTasks();
    }
  };

  const handleOnClose = () => {
    setShowFormModal(false);
    taskModel.reset();
  };

  const handleOnCancel = async ({ _id }) => setCancelId(_id);
  const handleOnCancelConfirm = async () => {
    setSubmitting(true);
    const result = await cancelTask(cancelId);
    if (result) loadTasks();
    setSubmitting(false);
    setCancelId(null);
  };

  const handleOnDelete = async ({ _id }) => setDeleteId(_id);
  const handleOnDeleteConfirm = async () => {
    setSubmitting(true);
    const result = await deleteTask(deleteId);
    if (result) loadTasks();
    setDeleteId(null);
    setSubmitting(false);
  };

  const handleOnRemove = async () => setShowRemove(!showRemove);

  const ActionsComponent = () => (
    <HeaderButtons>
      <CustomButton
        color="transparent"
        title="Create Task"
        size="130px"
        onClick={handleOnCreate}
      />
      <CustomButton
        color="blue"
        title="Add Task"
        size="130px"
        onClick={() => {}}
        disabled={true}
      />
    </HeaderButtons>
  );

  const fields = [
    {
      render: ({ row }) => <DisplayNo no={row.no} />
    },
    {
      render: ({ row }) => <DisplayNo no={row.case?.no} />
    },
    {
      render: ({ row }) => <>{getFullNameFromObject(row.case?.client)}</>
    },
    "title",
    {
      render: ({ row }) => (
        <>
          {row.description && row.description.length > 20
            ? `${row.description.substring(0, 20)} ...`
            : row.description}
        </>
      )
    },
    {
      render: ({ row }) => <StatusBadge status={row.status} />
    },
    {
      render: ({ row }) => <PriorityBadge priority={row.priority} />
    },
    {
      render: ({ row }) => <>{getFullNameFromObject(row.assignedTo)}</>
    },
    { render: ({ row }) => <FormattedDate date={row.dueDate} /> },
    {
      render: ({ row }) => <CancelledBadge cancelled={row.cancelled} />
    },
    {
      render: ({ row }) => (
        <ActionButtons
          task={row}
          handleOnEdit={() => handleOnEdit(row)}
          handleOnRemove={() => handleOnRemove(row)}
          handleOnDelete={() => handleOnDelete(row)}
          handleOnCancel={() => handleOnCancel(row)}
        />
      )
    }
  ];

  const headers = [
    {
      displayName: "Task No.",
      sortingFieldName: "no"
    },
    {
      displayName: "Case No.",
      sortingFieldName: "case.no"
    },
    {
      displayName: "Client Name",
      sortingFieldName: "client.firstName"
    },
    {
      displayName: "Task Title",
      sortingFieldName: "title"
    },
    {
      displayName: "Task Description",
      sortingFieldName: "type.description"
    },
    {
      displayName: "Status",
      sortingFieldName: "status"
    },
    {
      displayName: "Priority",
      sortingFieldName: "priority"
    },
    {
      displayName: "Assigned To",
      sortingFieldName: "assignedTo.firstName"
    },
    {
      displayName: "Due Date",
      sortingFieldName: "dueDate",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Cancelled",
      sortingFieldName: "cancelled"
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];

  const options = {
    data: tasks,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    headerAction: ActionsComponent
  };

  return (
    <>
      <section className="p-5 ">
        <div className="service-page-header">
          {loading ? (
            <DataLoading height="700px" />
          ) : (
            <Table options={options} />
          )}
        </div>
        {showFormModal && (
          <TaskForm
            onClose={handleOnClose}
            onConfirm={handleOnSubmit}
            task={taskModel.task}
            setTask={taskModel.setTask}
            processing={
              caseFormDataLoading || taskModel.loading || taskModel.submitting
            }
            staffs={staffs}
          />
        )}
        {deleteId && (
          <ConfirmDialogV2
            title="Delete Task"
            body="You are about to delete this task from your task list. Deleting
            this task removes all task’s information and ends all previous
            communication between you and the task."
            confirmButtonText="Delete"
            onConfirm={handleOnDeleteConfirm}
            onClose={() => setDeleteId(null)}
            submitting={submitting}
          />
        )}
        {cancelId && (
          <ConfirmDialogV2
            title="Cancel Task"
            body="You are about to cancel this task. Are you sure you want to continue?"
            onConfirm={handleOnCancelConfirm}
            onClose={() => setCancelId(null)}
            submitting={submitting}
          />
        )}
      </section>
    </>
  );
};

export default Tasks;
