import React from "react";
import { Switch } from "react-router-dom";
import routes from "../../config/routes";
import AppRoute from "../../components/App/Route";

const App = () => (
  <Switch>
    {routes.map(route => (
      <AppRoute
        key={route.path}
        path={route.path}
        component={route.component}
        isPrivate={route.isPrivate}
        module={route.module}
      />
    ))}
  </Switch>
);

export default App;
