import React, {useState, useEffect} from "react";
import {LabelBlueBold} from "../../../../../Layout/Shared/LabelBlueBold";
import {
  Features,
  Permissions,
  AccessLevels,
} from "./../.././../../../../static/Settings/Team/business";
import {CustomButton} from "../../../../../Layout/Shared/Button";
import {RightToggler} from "../../../../../Layout/Shared/RightToggler";
import {get as getAccessLevel} from "../../../../../../data/Settings/Business/Team/getAccessLevel";
import {create} from "../../../../../../data/Settings/Business/Team/createRole";
import {ListLoader} from "../../../../../Layout/Shared/DataLoading";
import {isEmptyString} from "../../../../../../utils/string";
import {order} from "../../../../../../utils/array";
import "./index.scss";

export const DefineNewRole = ({onClose, refresh}) => {
  const [features, setFeatures] = useState(Features);
  const [name, setName] = useState("");
  const [accessLevels, setAccessLevels] = useState([]);
  const [globalAccessLevel, setGlobalAccessLevel] = useState("Custom");
  const [isSaving, setIsSaving] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  useEffect(() => {
    const init = async () => {
      const {hasSuccess, data} = await getAccessLevel();
      if (hasSuccess) {
        const dataAsArray = Object.values(data);
        setAccessLevels(dataAsArray);
      }
    };
    init();
  }, []);

  const handleOnClick = (feature) => {
    const exceptFeatures = features
      .filter((_feature) => _feature.name !== feature.name)
      .map((_feature) => ({..._feature, show: false}));

    feature.show = !feature.show;

    setFeatures([...exceptFeatures, feature]);
  };

  const handleOnChange = (value, feature) => {
    const exceptFeatures = features.filter(
      (_feature) => _feature.name !== feature.name
    );
    setFeatures([...exceptFeatures, {...feature, level: value}]);

    // set global level
    const levels = [
      ...new Set([...exceptFeatures.map((feature) => feature.level), value]),
    ];
    setGlobalAccessLevel(levels.length === 1 ? levels[0] : "Custom");
  };

  const handleOnChecked = (feature, permission) => {
    const _feature = features.find((f) => f.name === feature.name);
    const _exceptFeatures = features.filter((f) => f.name !== feature.name);

    const _permission = _feature.permissions.find((p) => p.name === permission);
    const _exceptPermissions = _feature.permissions.filter(
      (p) => p.name !== permission
    );
    _permission.isActive = !_permission.isActive;

    const _newPermissions = [..._exceptPermissions, _permission];

    const _newFeature = {
      ..._feature,
      permissions: _newPermissions,
    };

    setFeatures([..._exceptFeatures, _newFeature]);
  };

  const getCheckedValue = (feature, permission) => {
    if (feature.level === "N/A") return false;

    const accessLevel = accessLevels.find((al) => al.name === feature.level);
    const _permission = accessLevel?.permissions.find(
      (p) => p.name === permission
    );
    if (_permission) return _permission.isActive;

    const _perm = feature.permissions.find((p) => p.name === permission);
    if (feature.level === "Custom" && _perm) return _perm.isActive;

    return false;
  };

  const handleSubmit = async () => {
    if (isEmptyString(name)) {
      setWarningMessage("Name must have a value!");
      return;
    } else if (name.length < 3) {
      setWarningMessage("Name is too Short!");
      return;
    }
    setIsSaving(true);
    const role = {
      name,
      features,
      globalAccessLevel,
    };
    const {hasSuccess} = await create(role);
    if (hasSuccess) {
      setIsSaving(false);
      onClose();
      refresh();
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setWarningMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [warningMessage]);
  const ManagerComponent = () => <span className="text-success">Manager</span>
  const EditorComponent = () => <span className="text-success">Editor</span>
  const ViewerComponent = () => <span className="text-success">Viewer</span>

  const featureLevelComponents = {
    Manager: ManagerComponent,
    Editor: EditorComponent,
    Viewer: ViewerComponent,
  }

  const InactiveComponent = () => (
    <span className="inactive">
      N/A
      <i></i>
    </span>
  )

  const resolveFeatureLevelComponent = (feature) => {
    const Component = featureLevelComponents[feature.level]
    return Component ? <Component feature={feature} /> : <InactiveComponent />
  }

  return (
    <RightToggler
      active={onClose ? true : false}
      arrow={true}
      size="500px"
      title="Define New Role"
      close={onClose}
    >
      {isSaving ? (
        <ListLoader height="1000px" />
      ) : (
        <>
          <div className="role-wrapper">
            <div className="role-header pt-4">
              <div>
                <LabelBlueBold content="Features" />
              </div>
              <div>
                <LabelBlueBold content="Access Level" />
              </div>
            </div>
            <div className="role-search">
              <LabelBlueBold content="Role Name" />
              <input
                type="text"
                className="custom-input"
                placeholder="Enter role name"
                value={name}
                onChange={({target}) => setName(target.value)}
              />
            </div>
            <div className="role-list">
              <ul>
                {features.sort(order).map((feature, index) => (
                  <li key={index}>
                    <div
                      onClick={() => handleOnClick(feature)}
                      className="head"
                    > {feature.level ? (
                      <span>{feature.name}</span>
                    ) : (
                      <span className="inactive">{feature.name}</span>
                    )}
                    {resolveFeatureLevelComponent(feature)}
                    </div>
                    <div
                      className={
                        feature.show
                          ? "access-permissions active"
                          : "access-permissions"
                      }
                    >
                      <div className="d-flex align-items-center">
                        <span className="flex-grow-1 text-primary-blue font-weight-bold">
                          Access Level / Permissions as
                        </span>
                        <select
                          className="primary-select bg-transparent shadow-none w-50"
                          defaultValue={feature.level}
                          onChange={({target}) =>
                            handleOnChange(target.value, feature)
                          }
                        >
                          {AccessLevels.map((accessLevel) => (
                            <option value={accessLevel.name}>
                              {accessLevel.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="feature-list mt-4">
                        <LabelBlueBold content="Features" />
                        <LabelBlueBold content="Access" />
                      </div>
                      <div className="permissions">
                        {Permissions.map((permission) => (
                          <>
                            <input
                              type="checkbox"
                              id={`${feature.name}-${permission}`}
                              name={`${feature.name}-${permission}`}
                              disabled={feature.level !== "Custom"}
                              checked={getCheckedValue(feature, permission)}
                              onChange={() =>
                                handleOnChecked(feature, permission)
                              }
                            />
                            <label
                              htmlFor={`${feature.name}-${permission}`}
                            >{`${feature.name} ${permission}`}</label>
                          </>
                        ))}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-between define-new-roles pt-3">
            <button className="btn-transparent-border-blue" onClick={onClose}>
              Cancel
            </button>
            <CustomButton
              color="blue"
              title="Create New Role"
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
      {warningMessage && (
        <div className="notifications">
          <div className="warning">
            <span>Warning</span>
            <p>{warningMessage}</p>
            <button onClick={() => setWarningMessage(null)}>&times;</button>
          </div>
        </div>
      )}
    </RightToggler>
  );
};