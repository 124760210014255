import React from 'react'
import ValidationText from '../../../Builder/Body/Components/Shared/ValidationText'
import { StyledComponentTitle, StyledRadiusInput } from '../../styles'
import { StyledContainer } from './styles'

const InputNumber = ({
  onChange,
  component: { options, validations },
  isReadOnly,
}) => {
  const { value, placeholderText, submission } = options

  const handleOnChange = (e) => {
    onChange({
      ...options,
      submission: {
        value: e.target.value,
      },
    })
  }

  return (
    <StyledContainer>
      <StyledComponentTitle>{value}</StyledComponentTitle>
      <StyledRadiusInput
        type="number"
        className="form-control"
        readOnly={isReadOnly}
        placeholder={placeholderText}
        value={submission?.value || ''}
        onChange={handleOnChange}
      />
      {validations?.value && (
        <ValidationText>{validations?.value}</ValidationText>
      )}
    </StyledContainer>
  )
}

export default InputNumber
