import "./index.scss";

export const CustomDropdown = ({children, margin = false}) => {
  return (
    <div className={margin ? "custom-dropdown" : "custom-dropdown ms-4 "}>
      {children}
    </div>
  );
};
export const DropdownItems = ({position, children}) => {
  return (
    <div className="dropdown-items" style={position}>
      {children}
    </div>
  );
};
