import React, {useState, useEffect} from "react";
import "./businessStats.scss";
import {TickBlue} from "../../../../assets/icons/icon";
import {StatLoading} from "../../../../components/Layout/Shared/DataLoading";
import {FormattedDate} from "../../../../components/Layout/Shared/FormattedDate";
import {getDashboardStats} from "../../../../data/Dashboard/business";
import {statsSettings} from "../../../../constants/stats";

const Stats = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState(null);
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await getDashboardStats();
      if (hasSuccess) {
        setStats(Object.values(data));
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const matchIcon = (title) => {
    const {icon} =
      statsSettings?.find((setting) => setting.title === title) || {};
    return icon ? icon : TickBlue;
  };
  return (
    <>
      {isLoading ? (
        <StatLoading height="80px" />
      ) : (
        <div className="row business-stats ">
          {stats?.map((stat) => (
            <div className="col-xl-3 col-lg-6 col-md-6 col-12" key={stat.title}>
              <div className="box">
                <div className="icon">{matchIcon(stat.title)}</div>
                <div className="content">
                  <h3>{stat.title}</h3>
                  <small>
                    <FormattedDate date={stat.lastUpdated} />
                  </small>
                </div>
                <div>
                  <span>{stat.count}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default Stats;
