import React from "react";
import Table from "../../../Layout/Shared/Table";
import { FormattedDate } from "../../../Layout/Shared/FormattedDate";
import { ActionButtons } from "./actions";
import "./index.scss";
import useBusinessCaseManagementTasksListContext from "../../../../providers/CaseManagement/Tasks/list";
import {
  StatusBadge,
  PriorityBadge,
  DisplayNo,
  CancelledBadge
} from "../../Shared";
import { getFullNameFromObject } from "../../../../utils/string";
import DataLoading from "../../../Layout/Shared/DataLoading";

const TaskList = () => {
  const { tasks, loading } = useBusinessCaseManagementTasksListContext();

  const fields = [
    {
      render: ({ row }) => <DisplayNo no={row.no} />
    },
    {
      render: ({ row }) => <DisplayNo no={row.case?.no} />
    },
    {
      render: ({ row }) => <>{getFullNameFromObject(row.case?.client)}</>
    },
    "title",
    {
      render: ({ row }) => (
        <>
          {row.description && row.description.length > 20
            ? `${row.description.substring(0, 20)} ...`
            : row.description}
        </>
      )
    },
    {
      render: ({ row }) => <StatusBadge status={row.status} />
    },
    {
      render: ({ row }) => <PriorityBadge priority={row.priority} />
    },
    {
      render: ({ row }) => <>{getFullNameFromObject(row.assignedTo)}</>
    },
    { render: ({ row }) => <FormattedDate date={row.dueDate} /> },
    {
      render: ({ row }) => <CancelledBadge cancelled={row.cancelled} />
    },
    {
      render: ({ row }) => <ActionButtons task={row} />
    }
  ];

  const headers = [
    {
      displayName: "Task No.",
      sortingFieldName: "no"
    },
    {
      displayName: "Case No.",
      sortingFieldName: "case.no"
    },
    {
      displayName: "Client Name",
      sortingFieldName: "client.firstName"
    },
    {
      displayName: "Task Title",
      sortingFieldName: "title"
    },
    {
      displayName: "Task Description",
      sortingFieldName: "type.description"
    },
    {
      displayName: "Status",
      sortingFieldName: "status"
    },
    {
      displayName: "Priority",
      sortingFieldName: "priority"
    },
    {
      displayName: "Assigned To",
      sortingFieldName: "assignedTo.firstName"
    },
    {
      displayName: "Due Date",
      sortingFieldName: "dueDate",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Cancelled",
      sortingFieldName: "cancelled"
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];

  const options = {
    data: tasks,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    headerAction: () => <p></p>
  };

  return (
    <div className="service-list">
      {loading ? <DataLoading height="700px" /> : <Table options={options} />}
    </div>
  );
};

export default TaskList;
