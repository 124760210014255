import "./index.scss";
export const ScrollContent = ({height, children, width}) => {
  return (
    <div
      className="auto-scroll-content"
      style={{maxHeight: height, width: width}}
    >
      {children}
    </div>
  );
};
