import {hostName} from "../../../endpoint";
import {defaultHeaders} from "../../../headers";

export const savePlanInformation = async (planInformation) => {
  const result = await fetch(`${hostName}/business/account-plan-information`, {
    method: "PUT",
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify(planInformation),
  });
  return result.json();
};
