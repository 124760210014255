/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState, useEffect } from "react";
import { getTasks } from "../../../data/CaseManagement/tasks";
import { useGlobalContext } from "../../global";
import { useTaskFormData, useTaskModel } from "./list.custom-hooks";

const BusinessCaseManagementTasksListContext = createContext(null);

const useBusinessCaseManagementTasksListContext = () => {
  const context = useContext(BusinessCaseManagementTasksListContext);
  if (!context)
    throw new Error(
      "useBusinessCaseManagementTasksListContext must be used within a BusinessCaseManagementTasksListProvider"
    );
  return context;
};

export const BusinessCaseManagementTasksListProvider = props => {
  const { currentUser } = useGlobalContext();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const taskFormData = useTaskFormData();
  const taskModel = useTaskModel(currentUser);

  const loadTasks = async () => {
    setLoading(true);
    const { data, hasSuccess } = await getTasks();
    if (hasSuccess) setTasks(data);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await loadTasks();
      taskFormData.load(true);
    })();
  }, []);

  const values = {
    tasks,
    loading,
    loadTasks,
    showFormModal,
    setShowFormModal,
    taskFormData,
    taskModel
  };

  return (
    <BusinessCaseManagementTasksListContext.Provider
      {...props}
      value={values}
    />
  );
};

export default useBusinessCaseManagementTasksListContext;
