import styled from 'styled-components'
import { StyledRadiusInput } from '../../styles'

export const StyledContainer = styled.div``

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  > :last-child {
    border-radius: 0 25px 25px 0;
  }
`

export const StyledDropdownContainer = styled.div`
  border: 1px solid #ced4da;
  box-shadow: 0 4px 6px 0 rgb(167 190 255 / 37%);
  overflow: hidden;
  padding-right: 1.5em;
  background-color: #fff;
`

export const StyledDropdown = styled.select`
  height: 32px;
  padding: 0 0.5em;
  border: none;
`

export const StyledDropdownOption = styled.option``

export const StyledInput = styled(StyledRadiusInput)`
  border-radius: 25px 0 0 25px;
  border-right: 0;
`
