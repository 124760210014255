/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useCallback, useEffect} from "react";
import Form from "react-bootstrap/Form";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Badge from "react-bootstrap/Badge";

import {_get} from "../../../../data/fetch";
import _ from "lodash";
import {AssigneeRemoveIconButton, InputGroup} from "../Base/BaseStyles";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {formsApiHostName} from "../../../../data/endpoint";

const me = {
  id: localStorage.getItem("userId"),
  email: localStorage.getItem("email"),
};

export function ESignatureAssigneesOptions({
  dispatch,
  attributes,
  component_key,
}) {
  const MySwal = withReactContent(Swal);

  const meChecked = getAttribute("me_checked", attributes) === "true";
  const setMeChecked = (val) =>
    dispatch({
      type: "esig_me_checked_change",
      key: component_key,
      value: val ? "true" : "false",
    });
  const othersChecked = getAttribute("others_checked", attributes) === "true";
  const setOthersChecked = (val) =>
    dispatch({
      type: "esig_others_checked_change",
      key: component_key,
      value: val ? "true" : "false",
    });
  const assignees_ids = getAttribute("assignees_ids", attributes);
  const assignees_emails = getAttribute("assignees_emails", attributes);
  const addAssignee = useCallback(
    (id, email) =>
      dispatch({type: "esig_add_assignee", key: component_key, id, email}),
    [dispatch, component_key]
  );
  const removeAssignee = useCallback(
    (id) => dispatch({type: "esig_remove_assignee", key: component_key, id}),
    [dispatch, component_key]
  );
  const clearAssignees = () =>
    dispatch({type: "esig_clear_assignees", key: component_key});

  const showAssigneesChecked =
    getAttribute("show_assignees_checked", attributes) === "true";
  const setShowAssigneesChecked = (val) =>
    dispatch({
      type: "esig_show_assignees_checked_change",
      key: component_key,
      value: val ? "true" : "false",
    });

  const assignees = assignees_ids?.map((assignee_id, index) => ({
    id: assignee_id,
    email: assignees_emails[index],
  }));

  const assigneesWithoutMe = assignees.filter(
    (assignee) => assignee.id !== me.id
  );

  const getOptions = useCallback(
    _.debounce((inputValue, callback) => {
      if (!inputValue) {
        return callback([]);
      }

      _get(`${formsApiHostName}/other/businesses`, {email: inputValue})
        .then((r) =>
          r.data.map((e) => ({
            value: e.email,
            label: e.email,
            id: e._id,
            email: e.email,
          }))
        )
        .then((data) => callback(data));
    }, 1000),
    []
  );

  const handleSelectChange = useCallback(
    (selectedOption) => {
      addAssignee(selectedOption.id, selectedOption.email);
    },
    [addAssignee]
  );

  useEffect(() => {
    if (meChecked) {
      addAssignee(me.id, me.email);
    } else {
      removeAssignee(me.id);
    }
  }, [meChecked, addAssignee, removeAssignee]);

  return (
    <Fragment>
      <h3 style={{marginLeft: "1.2em"}}>Who is signing?</h3>
      <InputGroup className="input-group" style={{marginTop: "0.5em"}}>
        <Form.Check
          className={"esignature-policy-checkbox"}
          style={{marginTop: "15px", width: "50%", marginLeft: "1.6em"}}
          type={"checkbox"}
          onChange={(e) => setMeChecked(e.target.checked)}
          checked={meChecked}
          label="Me"
        />
      </InputGroup>
      <InputGroup className="input-group" style={{marginTop: "0em"}}>
        <Form.Check
          className={"esignature-policy-checkbox"}
          style={{marginTop: "15px", width: "50%", marginLeft: "1.6em"}}
          type={"checkbox"}
          onChange={(e) => {
            const {checked} = e.target;
            if (!checked && assigneesWithoutMe.length > 0) {
              MySwal.fire({
                title: "Uncheck Others",
                text: "Recipients attached to others will be removed.\nDo you wish to continue?",
                showConfirmButton: true,
                showCancelButton: true,
              }).then((value) => {
                if (value.isConfirmed) {
                  clearAssignees();
                  setOthersChecked(false);
                  if (meChecked) addAssignee(me.id, me.email);
                }
              });
            } else {
              setOthersChecked(checked);
            }
          }}
          checked={othersChecked}
          label="Others"
        />
      </InputGroup>
      {othersChecked ? (
        <Fragment>
          <div className="row">
            <div className="col-md-6">
              <AsyncSelect
                styles={{
                  container: (container) => ({
                    ...container,
                    marginTop: "1.6em",
                    marginLeft: "1.6em",
                  }),
                }}
                maxMenuHeight={100}
                inputId={"assigneeAsyncSelect"}
                cacheOptions
                loadOptions={getOptions}
                value={{label: "", value: "", id: "", email: ""}}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          {assignees.length > 0 ? (
            <div>
              <span style={{marginLeft: "1.8em"}}>
                <b>Recipients({assignees.length})</b>
              </span>
              <br></br>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  marginLeft: "1.1em",
                }}
              >
                {assignees.map(({id, email}, index) => {
                  return (
                    <h3 key={id} style={{marginLeft: "0.3em"}}>
                      <Badge bg="secondary" className="assigneeBadge">
                        {email}
                        {id !== me.id ? (
                          <AssigneeRemoveIconButton
                            className="bi bi-x-circle-fill"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Remove Assignee"
                            onClick={() => removeAssignee(id)}
                          />
                        ) : null}
                      </Badge>
                    </h3>
                  );
                })}
              </div>
            </div>
          ) : null}
          <br></br>
          <br></br>
        </Fragment>
      ) : null}
      <InputGroup className="input-group" style={{marginTop: "30px"}}>
        <Form.Check
          className={"esignature-policy-checkbox"}
          style={{marginTop: "15px", width: "50%", marginLeft: "1.6em"}}
          type={"checkbox"}
          onChange={(e) => setShowAssigneesChecked(e.target.checked)}
          checked={showAssigneesChecked}
          label="Show Assignees"
        />
      </InputGroup>
    </Fragment>
  );
}

export default ESignatureAssigneesOptions;
