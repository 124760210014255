import {serviceApiHostName} from "../../endpoint";
import {defaultHeaders} from "../../headers";

export const getAttachments = async (serviceId) => {
  try {
    const result = await fetch(
      `${serviceApiHostName}/service/attachment/${serviceId}`,
      {
        method: "GET",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    };
  }
};
