import styled /*, { css } */ from "styled-components";

/*
export const StyledComponentExample = styled.div`
  ${({ theme, disabled, active }) => css`
    cursor: pointer;
    pointer-events: ${disabled ? "none" : "auto"};
    opacity: ${disabled ? 0.5 : 1};
    ${active &&
    css`
      border-left: 4px solid #1b84e7;
      background-color: #f5f7fa;
    `}
  `}
`;
*/
export const MiniKeyValue = styled.div`
  width: 200px;
  height: 75px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  font-size: 14px;
`;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 16px;
`;

export const Value = styled.div`
  color: ${({ color }) => color || "black"};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 16px;
`;

export const LargeKeyValue = styled.div`
  width: 418px;
  height: 176px;
  margin-top: 10px;
`;

export const ComponentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const MiniKeyValueRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
