import React, {useContext} from "react";
import BusinessServiceContext from "../../../../../providers/Service/business";
export const AvailableLocations = () => {
  const {service} = useContext(BusinessServiceContext);
  const {availableLocations} = service;
  return (
    <>
      {availableLocations?.length > 0 && (
        <div className="owner-details">
          <h4>Available Locations</h4>
          <ul className="">
            {availableLocations.map((location, index) => (
              <li key={index}>
                <span>{`${location?.city}, ${location?.state}, ${location?.country}`}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
