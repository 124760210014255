import {useBusinessAccountSettingsContext} from "../../../../../providers/Settings/Account/business";
import {Feature} from "../../../../../components/Layout/Shared/PaymentPlans/features";
import {Headers} from "../../../../../components/Layout/Shared/PaymentPlans/header";
import {
  planData,
  features,
} from "../../../../../components/Onboarding/Business/paymentPlanDetails";
import "../../../../Onboarding/Business/Plans/plans.scss";
export const Subscription = () => {
  const {
    values: {planInformation, isMonthly},
    setters: {setPlanInformation},
    saveSubscription,
  } = useBusinessAccountSettingsContext();

  const selectPlan = (plan) => {
    setPlanInformation(plan);
    let planForSave = {...plan};
    delete planForSave.featureList;
    saveSubscription(planForSave);
  };

  const isSelectedPlan = (planName) => planName === planInformation.name;

  return (
    <div className="row p-5 plans">
      <div
        className="payment-plans position-relative"
        style={{margin: "50px auto"}}
      >
        <div className="plan-type">
          <div>
            <h3 style={{marginBottom: "35px"}}>
              <strong>FEATURES</strong>
            </h3>
            <ul className="features mt-5 pt-5">
              {features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>

          {planData.map((plan, index) => (
            <div
              key={index}
              className={isSelectedPlan(plan.name) ? "active" : ""}
            >
              <section>
                <Headers isMonthly={isMonthly} plan={plan} />
                {isSelectedPlan(plan.name) ? (
                  <span className="mt-4 mb-4  btn-small border-0">
                    Current Plan
                  </span>
                ) : (
                  <button
                    className="mt-4 mb-4 btn-small btn-blue"
                    onClick={() => selectPlan(plan)}
                  >
                    Change
                  </button>
                )}
                <Feature plan={plan} />
              </section>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
