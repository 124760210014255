import React from "react";
import "./Breadcrumb.scss";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { useGlobalContext } from "../../../providers/global";

const InnerHeader = () => {
  const { currentUser } = useGlobalContext();
  return (
    <div className="container-fluid innerHeader ">
      <div className="row  pt-2 pb-3">
        <div className="col-lg-6 pl-0">
          <h3>
            Welcome,{" "}
            {capitalizeFirstLetter(
              currentUser.firstName || currentUser.businessName
            )}
          </h3>
        </div>
      </div>
    </div>
  );
};

const HeaderClient = () => {
  const { currentUser } = useGlobalContext();
  return (
    <div className="container-fluid innerHeader ">
      <div className="row  pt-2 pb-3">
        <div className="col-lg-6 pl-0">
          <h3>
            Welcome,{" "}
            {currentUser.firstName
              ? capitalizeFirstLetter(
                  currentUser.firstName || currentUser.businessName
                )
              : currentUser.email}
          </h3>
        </div>
        <div className="col-lg-6 text-end">
          <button></button>
        </div>
      </div>
    </div>
  );
};

const HeaderUser = () => {
  const { currentUser } = useGlobalContext();
  return (
    <div className="container-fluid innerHeader ">
      <div className="row  pt-2 pb-3">
        <div className="col-lg-6 pl-0">
          <h3>
            Welcome,{" "}
            {capitalizeFirstLetter(
              currentUser.firstName || currentUser.businessName
            )}
          </h3>
        </div>
        <div className="col-lg-6 text-end">
          <button></button>
        </div>
      </div>
    </div>
  );
};

export default InnerHeader;
export const InnerHeaderClient = HeaderClient;
export const InnerHeaderUser = HeaderUser;
