export const modules = {
  GLOBAL: "Global",
  SETTINGS: "Settings",
  CONTACTS: "Contacts",
  CALENDAR: "Calendar",
  SERVICES: "Services",
  FORMS: "Forms",
  SUBMISSIONS: "Submissions",
  MESSAGES: "Messages",
  CLIENTS: "Clients",
};
