import React from "react";
import {InnerHeaderUser} from "../../components/Layout/Breadcrumb/Breadcrumb";
import Stats from "../../components/Dashboard/User/Stats/Stats";
// import RecentFormSubmissions from "../../components/Dashboard/User/RecentFormSubmissions/index";
// import RecentMessages from "../../components/Dashboard/Shared/RecentMessages/index";
// import RecentActivity from "../../components/Dashboard/Shared/RecentActivity/index";
// import Enrolment from "../../components/Dashboard/Shared/Enrolment/index";
import Header from "../../components/Layout/Header/Header";
// import "../../styles/pages/dashboard.scss";
// import HelpArticles from "../../components/Dashboard/User/HelpArticles";
import Progress from "../../components/Dashboard/Business/Progress/Progress";
import {GenericPageLeft} from "../../components/Layout/Shared/GenericPageLeft";

const DashboardPage = () => {
  return (
    <section className="dashboard pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <div className="right">
            <section>
              <div className="row">
                <div className="col-lg-12">
                  <InnerHeaderUser />
                </div>
                <div className="col-lg-12">
                  <Stats />
                  <div className="row">
                    <Progress />
                    {/* <RecentFormSubmissions />
                    <RecentMessages />
                    <RecentActivity />
                    <Enrolment />
                    <HelpArticles /> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardPage;
