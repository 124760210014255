/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Formik, Form, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import PhoneImage from "../../../assets/images/phoneImage.png";
import steps from "./steps";
import ClientOnboardingContext from "../../../providers/Onboarding/client";
import { YourInformationSchema } from "../../../schemas/Onboarding/Client/yourInformation";
import ErrorField from "../../../components/Layout/Shared/ErrorField";
import { useGlobalContext } from "../../../providers/global";

const ClientInfo = ({ goTo }) => {
  const { yourInformations } = useContext(ClientOnboardingContext);
  const { yourInformation, setYourInformation } = yourInformations;
  const { currentUser } = useGlobalContext();

  const email = currentUser.email;

  const onSubmit = (values, _) => {
    setYourInformation(values);
    goTo(steps.ADDRESS);
  };

  return (
    <div className="tab active" id="info">
      <div className="row">
        <div className="col-xl-6  col-lg-6  pl-0">
          <div className="component first">
            <div>
              <Formik
                validationSchema={YourInformationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={yourInformation}
                onSubmit={onSubmit}
              >
                {({ errors }) => (
                  <Form>
                    <div className="form-group">
                      <label>
                        <strong>Your Name</strong>
                      </label>
                      <div className=" d-flex justify-content-between">
                        <Field
                          className="custom-input w-50"
                          placeholder="John"
                          name="firstName"
                        />
                        <Field
                          className="custom-input w-50"
                          placeholder="Doe"
                          name="lastName"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        <strong>Email Address</strong>
                      </label>
                      <input
                        disabled
                        className="custom-input"
                        placeholder={email}
                      />
                    </div>
                    <div className="form-group custom-phone">
                      <label>
                        <strong>Mobile Number</strong>
                      </label>
                      <Field name="phone">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <PhoneInput
                            enableSearch
                            placeholder="Enter Mobile Number"
                            country={"ca"}
                            value={value}
                            onChange={number => setFieldValue("phone", number)}
                          />
                        )}
                      </Field>
                      <ErrorField message={errors.firstName} />
                      <ErrorField message={errors.lastName} />
                      <ErrorField message={errors.phone} />
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="submit"
                        className="btn btn-blue-gradient btn-steps"
                      >
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-md-none d-lg-none d-xl-block text-end">
          <div className="component second">
            <img src={PhoneImage} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClientInfo;
