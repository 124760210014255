import React from 'react'
import { useParams } from 'react-router-dom'
import FormViewer from '../../../components/Form/Viewer'

const FormPreviewPage = () => {
  const { formId } = useParams()
  return <FormViewer formId={formId} />
}

export default FormPreviewPage
