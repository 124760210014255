import React from "react";
import "./manageTiles.scss";
import {
  TilesCalendar,
  TilesForms,
  TilesGroup,
  TilesMessage,
  TilesPieChart,
  TilesService,
} from "../../../../assets/icons/icon";
const ManageTiles = () => {
  return (
    <div className="right-sidebar">
      <div className="row">
        <div className="col-lg-12">
          <form>
            <div className="form-group  m-0">
              <input
                type="text"
                className="custom-input"
                placeholder="Search"
                id="searchValue"
              />
            </div>
          </form>
        </div>
        <div className="col-lg-12">
          <ul className="widget-list">
            <li>
              <div className="image">{TilesMessage}</div>
              <div className="content">
                MESSAGES
                <small>Get live feed of recent instant messages </small>
              </div>
            </li>

            <li className="active">
              <div className="image">{TilesForms}</div>
              <div className="content">
                FORM
                <small>View your recent form submissions. </small>
              </div>
            </li>
            <li>
              <div className="image">{TilesService}</div>
              <div className="content">
                SERVICES
                <small>Get live feed of recent instant messages </small>
              </div>
            </li>
            <li>
              <div className="image">{TilesGroup}</div>
              <div className="content">
                CONTACTS
                <small>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elite.
                </small>
              </div>
            </li>
            <li className="active">
              <div className="image">{TilesCalendar}</div>
              <div className="content">
                CALENDAR
                <small>Get live feed of recent instant messages </small>
              </div>
            </li>
            <li>
              <div className="image">{TilesPieChart}</div>
              <div className="content">
                DATA ANALYTICS
                <small>
                  Visualize your form items, display your analytics models on
                  your board.
                </small>
              </div>
            </li>
            <li>
              <div className="image">{TilesMessage}</div>
              <div className="content">
                MESSAGES
                <small>Get live feed of recent instant messages </small>
              </div>
            </li>
            <li>
              <div className="image">{TilesMessage}</div>
              <div className="content">
                MESSAGES
                <small>Get live feed of recent instant messages </small>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ManageTiles;
