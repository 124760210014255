/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useReducer, useState} from "react";

import {
  ComponentType,
  FormBuilderStateFromJSON,
} from "../FormEdit/FormEditReducerTypes";
import {useParams} from "react-router-dom";
import {FormViewButtonStyle, FormViewPageHeader} from "./FormViewStyles";
import {formsApiHostName} from "../../../data/endpoint";
import {_post} from "../../../data/fetch";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {Spinner} from "react-bootstrap";
import {formEditReducer} from "../FormEdit/FormEditReducer";
import FormViewWrapper from "./FormViewWrapper";
import {getFormById} from "../../../data/Forms/getFormById";
import {initialFormBuilderState} from "../FormEdit/FormEditReducerState";

const typeToSubmissionMap = {
  [ComponentType.HEADING]: (component) => ({...component, valid: true}),
  [ComponentType.LABEL]: (component) => ({...component, valid: true}),
  [ComponentType.INPUT_TEXT]: (component) => ({...component, valid: false}),
  [ComponentType.INPUT_NUMBER]: (component) => ({...component, valid: false}),
  [ComponentType.CHECKBOX]: (component) => ({...component, valid: false}),
  [ComponentType.RADIOBUTTON]: (component) => ({...component, valid: false}),
  [ComponentType.TEXTAREA]: (component) => ({...component, valid: true}),
  [ComponentType.PAGE_BREAK]: (component) => ({...component, valid: true}),
  [ComponentType.ESIGNATURE]: (component) => ({
    ...component,
    valid: true,
  }),
};

export function FormViewPage() {
  const MySwal = withReactContent(Swal);
  const [state, dispatch] = useReducer(
    formEditReducer,
    initialFormBuilderState
  );
  const [stateLoaded, setStateLoaded] = useState(false);
  const params = useParams();
  const {formId} = params;
  const [submissionPending, setSubmissionPending] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchFn = () => getFormById(formId);
    fetchFn().then((r) => {
      if (r.hasSuccess && r.data.data) {
        const newState = FormBuilderStateFromJSON(r.data.data.form);
        setStateLoaded(true);
        dispatch({
          type: "set_state",
          state: {
            ...newState,
            components: newState.components.map((component) =>
              typeToSubmissionMap[component.type](component)
            ),
            validation: true,
            formValid: false,
            formSaved: false,
          },
        });
      }
    });
  }, []);

  return !submitted ? (
    <Fragment>
      <FormViewPageHeader>{state.form_header.title}</FormViewPageHeader>
      <FormViewWrapper
        formBuilderState={state}
        dispatch={dispatch}
        currentView={"Desktop"}
        button={
          <FormViewButtonStyle
            className={"btn"}
            disabled={!stateLoaded || !state.formValid || submissionPending}
            onClick={() => {
              setSubmissionPending(true);
              _post(`${formsApiHostName}/forms/${formId}/submissions`, {
                status: "Published",
                form: state,
              })
                .then((r) => {
                  if (r.success) {
                    MySwal.fire({
                      title: "FormSubmission added!",
                      icon: "success",
                      showConfirmButton: false,
                      timer: 1000,
                    });
                  }
                })
                .catch(() =>
                  MySwal.fire({
                    title: "Failed to add submission!",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1000,
                  })
                )
                .finally(() => {
                  setSubmissionPending(false);
                  setSubmitted(true);
                });
            }}
          >
            {submissionPending ? (
              <div>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Submit"
            )}
          </FormViewButtonStyle>
        }
      />
    </Fragment>
  ) : (
    <h1 style={{textAlign: "center"}}>Thank you for your submission!</h1>
  );
}

export default FormViewPage;
