import {hostName} from "../../../endpoint";
import {defaultHeaders} from "../../../headers";

export const getInvitedStaff = async (token) => {
  try {
    const result = await fetch(
      `${hostName}/business/team-invited-staff/${token}`,
      {
        method: "GET",
        headers: {
          ...defaultHeaders,
        },
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    };
  }
};
