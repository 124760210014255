import axios from 'axios'
import { defaultHeaders } from './headers'

const _fetch = (method, url, data = {}, params = {}) =>
  axios({
    method: method,
    url: url,
    data: data || {},
    params: params || {},
    headers: { ...defaultHeaders, Authorization: `Bearer ${localStorage.getItem('access_token')}` },
  }).then((r) => r.data)

const _get = (url, params = {}) => _fetch('GET', url, {}, params)
const _post = (url, data) => _fetch('POST', url, data)
const _put = (url, data) => _fetch('PUT', url, data)

export { _fetch, _get, _post, _put }
