import {Fragment} from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import ComponentTabsHeader from "../ComponentTabs/ComponentTabsHeader";
import ComponentTabsContent from "../ComponentTabs/ComponentTabsContent";
import InputTextEditTab from "./InputTextEditTab";
import InputTextOptionsTab from "./InputTextOptionsTab";

export function InputTextEdit({component_key, attributes, dispatch}) {
  return (
    <Fragment>
      <ComponentTabsHeader
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <ComponentTabsContent
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
        EditTab={InputTextEditTab}
        OptionTab={InputTextOptionsTab}
      />
    </Fragment>
  );
}

export default InputTextEdit;
