import React from 'react'
import ComponentBox from '../Shared/Box'
import { StyledContainer, StyledPageBreak } from './styles'

const PageBreakComponent = ({
  onOrderButtonClick,
  onDeleteClick,
  onDuplicateClick,
}) => (
  <ComponentBox
    title="Add Page Break"
    columnEditable={false}
    onDeleteClick={onDeleteClick}
    onDuplicateClick={onDuplicateClick}
    onOrderButtonClick={onOrderButtonClick}
  >
    <StyledContainer>
      <StyledPageBreak />
    </StyledContainer>
  </ComponentBox>
)

export default PageBreakComponent
