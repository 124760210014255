import React from "react";
import {
  StyledAvatar,
  StyledChatCard,
  StyledUserInfo,
  StyledUserName
} from "../../Chats/ChatCard/ChatCard.style";

const defaultAvatar = "https://www.w3schools.com/howto/img_avatar.png";

const ContactCard = ({ contact, onClick }) => {
  const {
    type,
    email,
    name,
    profilePicture,
    logo,
    businessName,
    onboardingCompleted,
    isLoading
  } = contact;
  return (
    <StyledChatCard
      disabled={!onboardingCompleted || isLoading}
      onClick={() => {
        if (!isLoading) onClick(contact);
      }}
      type={type}
      name={businessName || name || email}
    >
      <StyledAvatar src={profilePicture || logo || defaultAvatar} />
      <StyledUserInfo>
        <StyledUserName>
          {isLoading ? "Loading..." : name || businessName || email}
        </StyledUserName>
      </StyledUserInfo>
    </StyledChatCard>
  );
};

export default ContactCard;
