import styled from 'styled-components'

export const StyledFieldWrapper = styled.div`
  margin-bottom: 2em;
`

export const StyledSelect = styled.select``

export const StyledOption = styled.option``

export const StyledCreateButton = styled.button`
  margin-top: 0 !important;
`
export const StyledLoader = styled.div`
  width: 100%;
  height: 44px;

  width: 100%;
  height: 44px;
  border-radius: 26px;
  box-shadow: 0 5px 15px 0 rgb(167 190 255 / 10%),
    0 5px 15px 0 rgb(167 190 255 / 20%) inset;
  border: solid 1px #dee2e6;
  background-color: #e6eaee;

  animation: loading 1s linear infinite;
  @keyframes loading {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`
