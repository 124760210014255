import React, {Fragment} from "react";
import STATES from "../../../../../static/Shared/states";
import {DEFAULT_SELECTION_VALUE} from "../../../../../utils/string";

const StateSelect = ({countryCode, ...props}) => {
  const options = countryCode
    ? STATES.filter((state) => state.country_code === countryCode)
    : [];

  return (
    <select
      className="primary-select ms-2 "
      placeholder="Select Province"
      defaultValue={DEFAULT_SELECTION_VALUE}
      {...props}
    >
      <option value={DEFAULT_SELECTION_VALUE}>Select Province</option>
      {options.map((option, index) => (
        <Fragment key={index}>
          <option value={option.state_code}>{option.name}</option>
        </Fragment>
      ))}
    </select>
  );
};

export default StateSelect;
