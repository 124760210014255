export const PageStatistics = ({title, children}) => (
  <div>
    <div className="d-block   ">
      <div className="d-block h4 fw-bold mt-5 ms-5 text-primary-blue pb-2">
        {title}
      </div>
      <div class="pt-4 px-5">
        <p>{children}</p>
      </div>
    </div>
  </div>
);
