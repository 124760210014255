import {ClientServiceDetails} from "./Details";
import {ClientServiceReviews} from "./Reviews";
import {ServiceForms} from "./Forms";

export const tabs = [
  {
    name: "Service Details",
    component: ClientServiceDetails,
    isDefault: true,
  },
  {
    name: "Reviews",
    component: ClientServiceReviews,
  },
  {
    name: "Forms",
    component: ServiceForms,
  },
];

export const defaultTab = tabs.find((tab) => tab.isDefault);
