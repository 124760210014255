import React from "react";
import {Redirect, Route} from "react-router-dom";
import {canAccess} from "../../utils/auth/permissions";

const AppRoutes = ({
  component: Component,
  path,
  isPrivate,
  module,
  ...rest
}) => {
  return (
    <Route
      path={path}
      render={(props) =>
        canAccess(module, isPrivate) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{pathname: "/no-access"}} />
        )
      }
      {...rest}
    />
  );
};

export default AppRoutes;
