import React, {useState} from "react";
import "../invitationList.scss";

const invitations = [
  {
    organization: "Sole Enterprises",
    invitedOn: "Nov 12, 2020",
    status: 0,
  },
  {
    organization: "Abc Limited",
    invitedOn: "Sep 18, 2020",
    status: 2,
  },
  {
    organization: "Alumai Ent LLC.",
    invitedOn: "Dec 8, 2020",
    status: 1,
  },
  {
    organization: "Brother’s Enterprises.",
    invitedOn: "May 22, 2020",
    status: 0,
  },
];

const InvitationList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const showMenu = () => setIsOpen(!isOpen);

  return (
    <div className="col-lg-12 pl-0">
      <h3>
        You can review, accept, decline, and delete any of the following
        informations. You can accept 5 invitations.
      </h3>
      <ul className="invitation-list-head mt-5">
        <li className="item">
          <div className="attribute pl-4" data-name="Organizations">
            Organizations
          </div>
          <div className="attribute pl-3" data-name="Invited on ">
            Invited on
          </div>
          <div className="attribute" data-name="Status">
            Status
          </div>
          <div className="attribute" data-name="Actions">
            Actions
          </div>
        </li>
      </ul>

      <ul className="invitation-list">
        {invitations.map((result) => (
          <li className="item" key={result.organization}>
            <div className="attribute" data-name="Organizations">
              {result.organization}
            </div>
            <div className="attribute" data-name="Invited on ">
              {result.invitedOn}
            </div>
            <div className="attribute" data-name="Status">
              {result.status === 0 ? (
                <span>Pending</span>
              ) : result.status === 1 ? (
                <span className="text-success">Accepted</span>
              ) : (
                <span className="text-danger">Blocked</span>
              )}
            </div>
            <div className="attribute" data-name="Actions">
              {result.status === 0 ? (
                <button className="btn btn-blue-gradient btn-x-small mt-0">
                  Accept Invitation
                </button>
              ) : null}
              <div className="actions">
                <button onClick={showMenu} className="btn btn-link pl-3- pr-3">
                  •••
                </button>
                {isOpen && (
                  <div className="menu">
                    <button className="menu-item">
                      Edit
                    </button>
                    <button className="menu-item">
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default InvitationList;
