import "../../../../styles/global.main.scss";

/*
export const CustomButton = ({ title, size, color, disabled, ...props }) => {
  return (
    <button
      {...props}
      className={
        color === "red"
          ? "btn-red btn-small mt-0"
          : color === "blue" && !disabled
          ? "btn-blue-gradient btn-small mt-0"
          : color === "blue" && disabled
          ? "btn-blue-disabled btn-small mt-0"
          : color === "solid-blue"
          ? "btn-blue btn-small mt-0"
          : color === "transparent"
          ? "btn-transparent-border-blue mt-0 me-3 "
          : color === "transparent-red"
          ? "btn-transparent-border-red mt-0 me-3 "
          : "btn-blue-gradient btn-small mt-0"
      }
      style={{ width: size }}
      disabled={disabled}
    >
      {title}
    </button>
  );
};
*/

export const CustomButton = ({ title, size, color, disabled, ...props }) => {
  const getClassNames = () => {
    let baseClass = "btn-small mt-0";
    let colorClass = "";

    switch (color) {
      case "red":
        colorClass = "btn-red";
        break;
      case "blue":
        colorClass = disabled ? "btn-blue-disabled" : "btn-blue-gradient";
        break;
      case "solid-blue":
        colorClass = "btn-blue";
        break;
      case "transparent":
        colorClass = disabled
          ? "btn-transparent-border-blue-disabled me-3"
          : "btn-transparent-border-blue me-3";
        break;
      case "transparent-red":
        colorClass = "btn-transparent-border-red me-3";
        break;
      default:
        colorClass = "btn-blue-gradient";
    }

    return `${baseClass} ${colorClass}`;
  };

  return (
    <button
      {...props}
      className={getClassNames()}
      style={{ width: size }}
      disabled={disabled}
    >
      {title}
    </button>
  );
};
