/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { attempSearch, searchByField } from "./search";
import { calculatePageCount, CustomPagination } from "./pagination";
import { compareByField } from "./sort";
import IconDown from "../../../../assets/icons/icon-sort-for-table.png";
import "./index.scss";

const Table = ({ options, history }) => {
  const {
    data = [],
    headers,
    fields,
    itemsPerPage = 10,
    pagination,
    search,
    sort,
    searchByFields,
    headerAction
  } = options;
  const HeaderActionComponent = headerAction ? headerAction : null;
  const itemPerPageOptions = [
    { key: 1, text: "5", value: 5 },
    { key: 2, text: "10", value: 10 },
    { key: 3, text: "25", value: 25 },
    { key: 4, text: "50", value: 50 },
    { key: 5, text: "100", value: 100 }
  ];
  const [itemPerPage, setItemPerPage] = useState(itemsPerPage || 10000);
  const [orderedData, setOrderedData] = useState([]);
  const [activePageData, setActivePageData] = useState([]);
  const [orderedBy, setOrderedBy] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    activePage: 1,
    boundaryRange: 1,
    siblingRange: 1,
    showEllipsis: true,
    showFirstAndLastNav: false,
    showPreviousAndNextNav: true,
    totalPages: calculatePageCount(data, itemsPerPage)
  });
  const sortByField = (field, sortingOptions) => {
    if (orderedBy === `Sorted Asc by ${field}`) {
      sortByDesc(field, sortingOptions);
    } else {
      sortByAsc(field, sortingOptions);
    }
  };

  const sortByAsc = (field, sortingOptions) => {
    setOrderedData(
      data.sort((a, b) => compareByField(a, b, field, sortingOptions, true))
    );
    setOrderedBy(`Sorted Asc by ${field}`);
    goToPage(1);
  };
  const sortByDesc = (field, sortingOptions) => {
    setOrderedData(
      data.sort((a, b) => compareByField(a, b, field, sortingOptions, false))
    );
    setOrderedBy(`Sorted Desc by ${field}`);
    goToPage(1);
  };
  const goToPage = pageNumber => {
    setPaginationOptions({ ...paginationOptions, activePage: pageNumber });
    setActivePageData(
      orderedData.slice(
        (pageNumber - 1) * itemPerPage,
        pageNumber * itemPerPage
      )
    );
  };
  // const goTo = (event, page) => {
  //   event.preventDefault();
  //   history.push(page);
  // };
  const handleSearch = ({ target }) => {
    const { value } = target;
    if (value !== "") {
      setIsSearching(true);
      const searchResult = attempSearch(data, fields, value);
      setActivePageData(searchResult);
    } else {
      setIsSearching(false);
      setOrderedData(data);
      setPaginationOptions({
        ...paginationOptions,
        totalPages: calculatePageCount(data, itemsPerPage),
        activePage: 1
      });
      goToPage(1);
    }
  };
  const handleSearchByField = (value, field) => {
    const searchResult = searchByField(data, field, value);
    setOrderedData(searchResult);
  };
  const handleSearchInputOnBlur = e => {
    e.target.value = "";
    setTimeout(() => {
      setOrderedData(data);
    }, 500);
  };

  useEffect(() => {
    setOrderedData(data);
    setPaginationOptions({
      ...paginationOptions,
      totalPages: calculatePageCount(data, itemsPerPage),
      activePage: 1
    });
  }, [data]);

  useEffect(() => {
    setActivePageData(orderedData.slice(0, itemPerPage));
  }, [orderedData]);

  useEffect(() => {
    setPaginationOptions({
      ...paginationOptions,
      totalPages: calculatePageCount(data, itemPerPage),
      activePage: 1
    });
    setActivePageData(orderedData.slice(0, itemPerPage));
  }, [itemPerPage]);

  return (
    <div className="table-responsive">
      <table className="custom-table mt-3">
        {HeaderActionComponent && (
          <thead>
            <tr>
              <th className="no-space pt-4 pb-4" colSpan={fields.length}>
                <div className="row">
                  <div className="col-8">
                    {search && (
                      <input
                        style={{}}
                        className="custom-input search w-100 "
                        action={{ icon: "search" }}
                        onChange={handleSearch}
                        placeholder="Search..."
                      />
                    )}
                  </div>
                  <div className="col-4 d-flex justify-content-end align-items-center">
                    <HeaderActionComponent />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        )}
        <thead>
          <tr>
            {headers &&
              headers.map((header, index) => (
                <th key={index} style={{ verticalAlign: "bottom" }}>
                  {sort ? (
                    <>
                      <span
                        onClick={() =>
                          sortByField(
                            header.sortingFieldName,
                            header.sortingOptions
                          )
                        }
                      >
                        {header.displayName}
                      </span>
                      {orderedBy ===
                        `Sorted Asc by ${header.sortingFieldName}` && (
                        <img src={IconDown} alt="" />
                      )}
                      {orderedBy ===
                        `Sorted Desc by ${header.sortingFieldName}` && (
                        <img
                          src={IconDown}
                          alt=""
                          style={{ transform: "rotate(180deg)" }}
                        />
                      )}
                    </>
                  ) : (
                    <span>{header.displayName}</span>
                  )}
                  <br />
                  {searchByFields && (
                    <input
                      style={{ width: "100%" }}
                      placeholder={`${header.displayName.substring(0, 10)}`}
                      onChange={e =>
                        handleSearchByField(
                          e.target.value,
                          header.sortingFieldName
                        )
                      }
                      onBlur={e => handleSearchInputOnBlur(e)}
                    />
                  )}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {activePageData &&
            activePageData.map((data, index) =>  {
              return <tr key={index} className={data?.isSelected ? "active" : ""}>
                {fields &&
                  fields.map((field, cellIndex) => (
                    <td key={cellIndex}>
                      {typeof field === "object" && field.render ? (
                        <field.render key={cellIndex} row={data} />
                      ) : (
                        <span>{data[field]}</span>
                      )}
                    </td>
                  ))}
              </tr>
            })}
          {data && data.length === 0 && (
            <tr>
              <td colSpan={fields.length}>
                <b style={{ float: "left", padding: "20px" }}>
                  No records found to display
                </b>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          {!isSearching && pagination && (
            <tr>
              <th colSpan={fields.length}>
                <span className="total-page">
                  Showing
                  <select
                    onChange={({ target }) => {
                      setItemPerPage(target.value);
                    }}
                    options={itemPerPageOptions}
                    defaultValue={itemPerPage}
                  >
                    {itemPerPageOptions.map(option => (
                      <option key={option.key}>{option.value}</option>
                    ))}
                  </select>
                  items per page
                </span>
                <CustomPagination
                  paginationOptions={paginationOptions}
                  goToPage={goToPage}
                />
              </th>
            </tr>
          )}
          {isSearching && (
            <tr>
              <th colSpan={fields.length}>
                <span
                  style={{ float: "right" }}
                >{`${activePageData.length} item(s) found`}</span>
              </th>
            </tr>
          )}
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
