import {serviceApiHostName} from "../../endpoint";
import {defaultHeaders} from "../../headers";

export const getReviews = async () => {
  try {
    const result = await fetch(`${serviceApiHostName}/review/service`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (error) {
    return {
      hasSuccess: false,
      data: error.message,
    };
  }
};
