import React, { useContext } from "react";
import BusinessServiceContext from "../../../../../providers/Service/business";
import "./index.scss";
import { OwnerDetails } from "./OwnerDetails";
import { Summary } from "./Summary";
import { CommunicationChannels } from "./CommunicationChannels";
import { ServiceSchedules } from "./ServiceSchedules";
import { AvailableLocations } from "./AvailableLocations";
import { ShortDescription } from "./Description";
import { Industry } from "./Industry";
import { SERVICE_TYPES } from "../../../../../constants/services";
const Details = () => {
  const { service } = useContext(BusinessServiceContext);
  const { _id } = service;

  const ServiceType = () =>
    service.type && (
      <div className="mt-5">
        <h4>Type</h4>
        <p>
          {SERVICE_TYPES.find(({ type }) => type === service.type)?.name || ""}
        </p>
      </div>
    );

  return (
    <section className="p-5">
      <div className="row service-details">
        <div className="col-md-12">
          <h2 className="service-id">Service ID: [{_id}]</h2>
        </div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-6">
              <OwnerDetails />
            </div>
            <div className="col-md-6 pl-1">
              <ShortDescription />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CommunicationChannels />
            </div>
            <div className="col-md-6 pl-1">
              <Summary />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <AvailableLocations />
              <Industry />
            </div>
            <div className="col-md-6 pl-1">
              <ServiceSchedules />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <ServiceType />
            </div>
            <div className="col-md-6 pl-1"></div>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>
    </section>
  );
};
export default Details;
