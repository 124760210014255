import {hostName} from "../../endpoint";
import {defaultHeaders} from "../../headers";

export const updateStaffDetails = async (details) => {
  const {firstName, lastName, phone, address, city, state, country, zipCode, profilePicture} =
    details;

  const result = await fetch(`${hostName}/business/update-staff-details`, {
    method: "PUT",
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify({
      firstName,
      lastName,
      phone,
      address,
      city,
      state,
      country,
      zipCode,
      profilePicture
    }),
  });
  return result.json();
};
