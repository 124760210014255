import "./index.scss";
import React, {useState} from "react";
import {ScrollContent} from "../../../../../Layout/Shared/ScrollContent";
import SampleAvatar from "../../../../../../assets/images/sample-avatar.png";
import SampleSharedFile from "../../../../../../assets/images/sample-shared-file.jpg";
import {BluePlusIcon} from "../../../../../../assets/icons/icon";
import {ShareModal} from "../../../../../Layout/Shared/Popups/Share";
export const Chat = () => {
  const [showShare, setshowShare] = useState(false);
  return (
    <div className="chat-wrapper">
      <ScrollContent height="calc(100% - 50px)">
        <div className="chat-box">
          <div className="date">Tue Jan 12, 2021</div>
          <ul className="messages">
            <li>
              <div className="incoming">
                <div className="avatar">
                  <img src={SampleAvatar} alt="" />
                </div>
                <div className="message">
                  <span>
                    Hodkiewicz Earline <span>10:30 AM</span>
                  </span>
                  <p>
                    Architecto laborum ea consequatur. Officiis et magni saepe
                    error totam culpa tenetur quidem. Quaerat et autem sunt
                    officiis eos corrupti porro perferendis aut. Schuster Nasir
                  </p>

                  <div className="attachment">
                    <div className="share-box">
                      <div className="file">
                        <img src={SampleSharedFile} alt="" />
                      </div>
                      <button className="border-end">View</button>
                      <button onClick={() => setshowShare(!showShare)}>
                        Share
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="sent">
                <div className="message">
                  <span>
                    10:30 AM <span>John Smith</span>
                  </span>
                  <p>
                    Sure I will check and update the document with details…
                    Thanks!
                  </p>
                </div>
                <div className="avatar">
                  <img src={SampleAvatar} alt="" />
                </div>
              </div>
            </li>
            <li>
              <div className="incoming">
                <div className="avatar">
                  <img src={SampleAvatar} alt="" />
                </div>
                <div className="message">
                  <span>
                    Hodkiewicz Earline <span>10:30 AM</span>
                  </span>
                  <p>
                    Architecto laborum ea consequatur. Officiis et magni saepe
                    error totam culpa tenetur quidem. Quaerat et autem sunt
                    officiis eos corrupti porro perferendis aut. Schuster Nasir
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="incoming">
                <div className="avatar">
                  <img src={SampleAvatar} alt="" />
                </div>
                <div className="message">
                  <span>
                    Hodkiewicz Earline <span></span>
                  </span>
                  <p className="typing">•••</p>
                </div>
              </div>
            </li>
            <li>
              <div className="sent">
                <div className="message">
                  <span>
                    <span>John Smith</span>
                  </span>
                  <p className="typing">•••</p>
                </div>
                <div className="avatar">
                  <img src={SampleAvatar} alt="" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </ScrollContent>

      <div className="chat-footer">
        <button className="btn-add-circle-blue position-relative">
          {BluePlusIcon}
        </button>
        <div className="d-flex justify-content-between  align-items-center ">
          <input type="text" placeholder="Type here..." />
          <button className="send-message"></button>
        </div>
      </div>
      {showShare && (
        <ShareModal
          title="Share this file"
          onClose={() => setshowShare(!showShare)}
          onConfirm={() => setshowShare(!showShare)}
          onExport={() => setshowShare(!showShare)}
        />
      )}
    </div>
  );
};
