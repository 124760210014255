import React, {useState, useEffect} from "react";
import Header from "../../../components/Layout/Header/Header";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import {TabContainer} from "../../../components/Settings/Business/Account/TabContainer";
import BusinessAccountSettingsContext from "../../../providers/Settings/Account/business";
import {getSettings} from "../../../data/Settings/Business/getSettings";
import DataLoading from "../../../components/Layout/Shared/DataLoading/index";
import {GlobalSuccess} from "../../../components/Layout/Shared/Modal/Success";
import {savePlanInformation} from "../../../data/Settings/Business/Account/savePlanInformation";

const AccountSettingsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [isMonthly, setIsMonthly] = useState(true);
  const [planInformation, setPlanInformation] = useState({});
  const [businessInformation, setBusinessInformation] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data: business} = await getSettings();
      if (hasSuccess) {
        setIsMonthly(business.details.planInformation.isMonthly);
        setPlanInformation(business.details.planInformation);
        setBusinessInformation(business.details.businessInformation);
        setPaymentMethods(business.details.paymentMethods);
      }
      setIsLoading(false);
    };
    init();
  }, [refreshFlag]);

  const refresh = () => {
    setRefreshFlag(true);
    setTimeout(() => {
      setRefreshFlag(false);
    }, 10);
  };

  const saveSubscription = async (plan) => {
    setIsLoading(true);
    const {hasSuccess} = await savePlanInformation({...plan, isMonthly});
    if (hasSuccess) {
      setIsLoading(false);
      setShowSuccess(true);
    }
  };

  return (
    <BusinessAccountSettingsContext.Provider
      value={{
        values: {
          isMonthly,
          planInformation,
          businessInformation,
          paymentMethods,
        },
        setters: {
          setIsMonthly,
          setPlanInformation,
          setBusinessInformation,
          setPaymentMethods,
        },
        refresh: refresh,
        saveSubscription: saveSubscription,
      }}
    >
      <section className="dashboard  bg-paleGray pb-5 pr-3">
        <Header />
        <div className="container-fluid mt-5 pt-3">
          <div className="row">
            <GenericPageLeft />
            <PageContainer>
              <PageHeader>
                <PageTitle>Account Settings</PageTitle>
              </PageHeader>
              {isLoading ? <DataLoading height="750px" /> : <TabContainer />}
            </PageContainer>
          </div>
        </div>
      </section>
      {showSuccess && (
        <GlobalSuccess
          heading="Changed!"
          onClose={() => setShowSuccess(false)}
          message="The changes have been successfully saved."
        />
      )}
    </BusinessAccountSettingsContext.Provider>
  );
};
export default AccountSettingsPage;
