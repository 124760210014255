import {FormComponentTypes} from "../constants";
import Checkbox from "./Components/Checkbox";
import ESignature from "./Components/ESignature";
import Heading from "./Components/Heading";
import InputNumber from "./Components/InputNumber";
import InputText from "./Components/InputText";
import Label from "./Components/Label";
import Memo from "./Components/Memo";
import Attachment from "./Components/Attachment";
import RadioButton from "./Components/RadioButton";

const componentMap = {
  [FormComponentTypes.Heading]: Heading,
  [FormComponentTypes.Label]: Label,
  [FormComponentTypes.InputText]: InputText,
  [FormComponentTypes.InputNumber]: InputNumber,
  [FormComponentTypes.RadioButton]: RadioButton,
  [FormComponentTypes.Checkbox]: Checkbox,
  [FormComponentTypes.Memo]: Memo,
  [FormComponentTypes.ESignature]: ESignature,
  [FormComponentTypes.Attachment]: Attachment,
};

const UnknownComponent = () => <div>Unknown Component</div>;

export const resolveComponent = (component) =>
  componentMap[component.type] || UnknownComponent;
