import { caseManagementApiHostName } from "../endpoint";
import { defaultHeaders } from "../headers";

export const getTasksByCaseId = async id => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/cases/${id}/tasks`,
      {
        method: "GET",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const getTasks = async () => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/tasks`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const getTask = async id => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/tasks/${id}`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const createTask = async task => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/tasks`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(task)
    });
    return {
      hasSuccess: result.status === 201 || result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const updateTask = async (id, task) => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/tasks/${id}`, {
      method: "PUT",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(task)
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const deleteTask = async id => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/tasks/${id}`, {
      method: "DELETE",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 204 || result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const cancelTask = async id => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/tasks/${id}/cancel`,
      {
        method: "PATCH",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
