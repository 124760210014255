import React, { useMemo, useState, useEffect, useCallback } from "react";
import ChatIcon from "../../../../assets/icons/chatIcon";
import GroupIcon from "../../../../assets/icons/GroupIcon";
import Search from "../../Shared/Search/Search.component";
import Chats from "./Chats/Chats.component";
import Contacts from "./Contacts/Contacts.component";
import { StyledChatList, StyledChatTab, StyledChatTabs } from "./Tabs.style";
import { useChatContext } from "../../../../providers/Chat";
import { useGlobalContext } from "../../../../providers/global";
import { useLocation, useHistory } from "react-router-dom";

const ChatSidebarTabs = ({ tabs, selectedTab, setSelectedTab }) => {
  const { currentUser } = useGlobalContext();
  const { chats, users, setSelectedChat, createChat, loadData, isReady } =
    useChatContext();
  const location = useLocation();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");

  const TabIcon = ({ tab }) => (tab === "chats" ? <ChatIcon /> : <GroupIcon />);

  const handleOnContactSelected = useCallback(
    async contact => {
      const existingChat = chats.find(
        chat =>
          chat.participants.length === 2 &&
          chat.participants.some(p => p.id === contact.id) &&
          chat.participants.some(p => p.id === currentUser.id)
      );
      if (existingChat) setSelectedChat(existingChat);
      else await createChat([contact.id]);

      setSelectedTab("chats");
    },
    [chats, currentUser.id, createChat, setSelectedChat, setSelectedTab]
  );

  const handleTabChange = tab => {
    setSelectedChat(undefined);
    setSelectedTab(tab.name);
    setSearchTerm("");
    if (tab.name !== "chats") loadData();
  };

  const getChats = useCallback(() => {
    const term = searchTerm.trim().toLowerCase();
    if (term.length === 0) return chats;

    return chats.filter(chat =>
      chat.participants.some(
        participant =>
          participant.name?.toLowerCase().includes(term) ||
          participant.businessName?.toLowerCase().includes(term) ||
          participant.email?.toLowerCase().includes(term)
      )
    );
  }, [searchTerm, chats]);

  const memorizedChats = useMemo(getChats, [getChats]);

  const getContacts = useCallback(() => {
    const term = searchTerm.trim().toLowerCase();
    if (term.length === 0) return users;

    return users.filter(user => user.name.toLowerCase().includes(term));
  }, [searchTerm, users]);

  const memorizedContacts = useMemo(getContacts, [getContacts]);

  useEffect(() => {
    if (!location || !history || !currentUser || !currentUser.id) return;

    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get("clientId");

    if (!clientId || !isReady) return;

    const { pathname, search } = location;
    if (clientId && search) history.replace(pathname);

    if (!users || users.length === 0) return;

    const user = users.find(user => user.id === clientId);
    if (user) handleOnContactSelected(user);
  }, [location, history, currentUser, handleOnContactSelected, users, isReady]);

  return (
    <>
      <StyledChatTabs>
        {tabs.map((tab, index) => (
          <StyledChatTab
            key={index}
            onClick={() => handleTabChange(tab)}
            isActive={selectedTab === tab.name}
          >
            <TabIcon tab={tab.name} />
          </StyledChatTab>
        ))}
      </StyledChatTabs>
      <Search value={searchTerm} onChange={setSearchTerm} />
      <StyledChatList>
        {selectedTab === "chats" && <Chats chats={memorizedChats} />}
        {selectedTab === "invite" && (
          <Contacts
            contacts={memorizedContacts}
            onContactSelected={handleOnContactSelected}
          />
        )}
      </StyledChatList>
    </>
  );
};

export default ChatSidebarTabs;
