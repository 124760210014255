import styled from "styled-components";

export const StyledSidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 375px;
  min-width: 300px;
  border-right: 1px solid #e9ecef;
`;
