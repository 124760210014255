export const compareByField = (
  a,
  b,
  field,
  sortingOptions = {},
  isAscending = true
) => {
  const value1 = a[field];
  const value2 = b[field];
  const {dataType, order} = sortingOptions;
  switch (dataType) {
    case "date":
      return compareDates(value1, value2, isAscending);
    case "number":
      return compareNumber(value1, value2, isAscending);
    case "duration":
      return compareDuration(value1, value2, isAscending);
    default:
      if (order) {
        return compareWithOrder(value1, value2, order, isAscending);
      } else {
        return compare(value1, value2, isAscending);
      }
  }
};

const compareDates = (date1, date2, isAscending) => {
  if (new Date(date1) > new Date(date2)) return isAscending ? 1 : -1;
  if (new Date(date1) < new Date(date2)) return isAscending ? -1 : 1;
  return 0;
};

const compareNumber = (number1, number2, isAscending) => {
  if (Number(number1) > Number(number2)) return isAscending ? 1 : -1;
  if (Number(number1) < Number(number2)) return isAscending ? -1 : 1;
  return 0;
};
//if duration is undefined,it means it's bigger for sorting!
const compareDuration = (number1, number2, isAscending) => {
  const value1 = Number(number1);
  const value2 = Number(number2);
  if (value1 && value2) {
    if (value1 > value2) return isAscending ? 1 : -1;
    if (value1 < value2) return isAscending ? -1 : 1;
  } else {
    if (!value1 && !value2) return 0;
    if (!value1) return isAscending ? 1 : -1;
    if (!value2) return isAscending ? -1 : 1;
  }
  return 0;
};

export const compareWithOrder = (text1, text2, textOrder, isAscending) => {
  if (textOrder.indexOf(text1) > textOrder.indexOf(text2))
    return isAscending ? 1 : -1;
  if (textOrder.indexOf(text1) < textOrder.indexOf(text2))
    return isAscending ? -1 : 1;
  return 0;
};

const compare = (x, y, isAscending) => {
  if (x > y) return isAscending ? 1 : -1;
  if (x < y) return isAscending ? -1 : 1;
  return 0;
};
