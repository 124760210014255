import React, {useState} from "react";
import {Formik, Form, Field} from "formik";
import {registerBusiness} from "../../data/Register/business";
import {useHistory} from "react-router-dom";
import {checkBusinessName} from "../../data/Register/checkBusinessName";
import {RegisterSchema} from "../../schemas/Register/business";
import ErrorField from "../../components/Layout/Shared/ErrorField";
import {isEmptyString, validateEmail} from "../../utils/string";
import {checkExistingEmail} from "../../data/Register/checkExistingEmail";

const BusinessForm = () => {
  const history = useHistory();

  const [nameChecked, setNameChecked] = useState(false);
  const [isBusinessNameValid, setIsBusinessNameValid] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [isEmailInUse, setIsEmailInUse] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const onSubmit = async (values) => {
    if (nameChecked && !isBusinessNameValid) return;
    if (emailChecked && isEmailInUse) return;
    setSubmitting(true);
    delete values.confirmPassword;
    await registerBusiness(values);
    history.push("/verification", {email: values.email});
    setSubmitting(false);
  };

  const onNameBlur = async ({value}) => {
    if (isEmptyString(value)) return;
    const {isValid} = await checkBusinessName({name: value});
    setIsBusinessNameValid(isValid);
    setNameChecked(true);
  };

  const onEmailBlur = async ({value}) => {
    if (!validateEmail(value)) return;
    const {isInUse} = await checkExistingEmail({email: value});
    setIsEmailInUse(isInUse);
    setEmailChecked(true);
  };

  return (
    <div className="col-12">
      <Formik
        initialValues={initialValues}
        validationSchema={RegisterSchema}
        onSubmit={onSubmit}
      >
        {({errors}) => (
          <Form>
            <div className="form-group">
              <Field
                placeholder="Business Name"
                className={
                  nameChecked && isBusinessNameValid && !errors.name
                    ? "custom-input icon-success"
                    : "custom-input"
                }
                name="name"
                type="text"
                onBlur={({target}) => onNameBlur(target)}
              />
              {nameChecked && !isBusinessNameValid && (
                <ErrorField
                  message={"There is another business with the same name."}
                />
              )}
              <ErrorField message={errors.name} />
            </div>
            <div className="form-group">
              <Field
                type="email"
                className={
                  emailChecked && !isEmailInUse && !errors.email
                    ? "custom-input icon-success"
                    : "custom-input"
                }
                placeholder="Email Address"
                name="email"
                onBlur={({target}) => onEmailBlur(target)}
              />
              {emailChecked && isEmailInUse && (
                <ErrorField message={"This email is already registered."} />
              )}
              <ErrorField message={errors.email} />
            </div>
            <div className="form-group">
              <Field
                type="password"
                className="custom-input"
                placeholder="Password"
                name="password"
              />
              <ErrorField message={errors.password} />
            </div>
            <div className="form-group">
              <Field
                type="password"
                className="custom-input"
                placeholder="Confirm Password"
                name="confirmPassword"
              />
              <ErrorField message={errors.confirmPassword} />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-blue-gradient"
            >
              SIGN UP
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BusinessForm;
