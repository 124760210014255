import { chatsApiHostName } from "../endpoint";
import { defaultHeaders } from "../headers";

export const updateSeen = async chatId => {
  try {
    const result = await fetch(`${chatsApiHostName}/chats/${chatId}`, {
      method: "PUT",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
