import XLSX from "xlsx";
import FileSaver from "file-saver";
import { getUniqueString } from "./date";

export const headers = [
  "First Name",
  "Last Name",
  "Designation",
  "Email",
  "Phone",
  "Address",
  "City",
  "State",
  "Country",
  "ZipCode",
  "Status"
];

export const toJaggedArray = source =>
  source.map(item => [
    item.firstName,
    item.lastName,
    item.designation,
    item.email,
    item.phone,
    item.address,
    item.city,
    item.state,
    item.country,
    item.zipCode,
    item.status
  ]);

export const exportToCSV = async (data, filename) => {
  const jaggedContacts = [headers].concat(toJaggedArray(data));
  const workSheet = XLSX.utils.aoa_to_sheet(jaggedContacts);
  const bookOfCSV = XLSX.utils.sheet_to_csv(workSheet);
  const fileData = new Blob([bookOfCSV], {
    type: "text/csv;charset=UTF-8"
  });
  const _filename = filename
    ? `${getUniqueString()}_${filename}.csv`
    : `${getUniqueString()}_contacts.csv`;
  FileSaver.saveAs(fileData, _filename);
};

export const exportToXLSX = async (data, filename) => {
  const jaggedContacts = [headers].concat(toJaggedArray(data));
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const workSheet = XLSX.utils.aoa_to_sheet(jaggedContacts);
  const workBook = {
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ["data"]
  };
  const excelBuffer = XLSX.write(workBook, {
    bookType: "xlsx",
    type: "array"
  });
  const fileData = new Blob([excelBuffer], { type: fileType });
  const _filename = filename
    ? `${getUniqueString()}_${filename}.xlsx`
    : `${getUniqueString()}_contacts.xlsx`;
  FileSaver.saveAs(fileData, _filename);
};
