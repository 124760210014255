/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Formik, Form, Field} from "formik";
import LocationImage from "../../../assets/images/locationImage.png";
import {CountryInput} from "../../../components/Layout/Shared/CountryInput";
import steps from "./steps";
import {StaffRegistrationAddress} from "../../../schemas/Staff/Business/staffRegistrationAddress";
import ErrorField from "../../../components/Layout/Shared/ErrorField";

const StaffAddress = ({goTo, address, setAddress, onSubmit}) => {
  const initAddressValues = {
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  };

  const handleOnClickPrev = () => goTo(steps.INFORMATION);

  return (
    <div className="tab active" id="location">
      <div className="row">
        <div className="col-xl-6  col-lg-6  pl-0">
          <div className="component first">
            <h2>
              <strong>We are connecting you to your team</strong>
            </h2>
            <div>
              <Formik
                initialValues={address ?? initAddressValues}
                validationSchema={StaffRegistrationAddress}
                onSubmit={onSubmit}
              >
                {({errors, values}) => (
                  <>
                    {setAddress(values)}
                    <Form>
                      <div className="form-group">
                        <label>
                          <strong>Staff Address</strong>
                        </label>
                        <Field
                          className="custom-input"
                          placeholder="Street Address"
                          name="address"
                        />
                        <ErrorField message={errors.address} />
                      </div>

                      <div className="form-group">
                        <div className="d-flex justify-content-between">
                          <div>
                            <Field
                              className="custom-input w-100"
                              placeholder="City"
                              name="city"
                            />
                            <ErrorField message={errors.city} />
                          </div>
                          <div>
                            <Field
                              className="custom-input w-100"
                              placeholder="State"
                              name="state"
                            />
                            <ErrorField message={errors.state} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group pb-5">
                        <div className="d-flex justify-content-between mt-5">
                          <div>
                            <Field
                              name="country"
                              className="custom-input ms-2 "
                            >
                              {({field: {value}, form: {setFieldValue}}) => (
                                <CountryInput
                                  name="country"
                                  className="custom-input me-2"
                                  placeholder="Country"
                                  onSelect={({text}) =>
                                    setFieldValue("country", text)
                                  }
                                  defaultValue={value}
                                />
                              )}
                            </Field>
                            <ErrorField message={errors.country} />
                          </div>
                          <div>
                            <Field
                              className="custom-input w-100"
                              placeholder="Zip Code"
                              name="zipCode"
                            />
                            <ErrorField message={errors.zipCode} />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-center mt-5">
                        <a
                          href="javascript:void(0)"
                          className="btn-text-blue me-5 "
                          onClick={handleOnClickPrev}
                        >
                          Previous
                        </a>
                        <button
                          type="submit"
                          className="mt-0 btn btn-blue-gradient ms-5  btn-steps"
                        >
                          Save Details
                        </button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-md-none d-lg-none d-xl-block text-end">
          <div className="component second">
            <img src={LocationImage} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffAddress;
