import queryString from "query-string";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CustomButton } from "../../Layout/Shared/Button";
import Table from "../../Layout/Shared/Table";
import { ViewSwitch } from "../../Layout/Shared/ViewSwitch";

import { FormVisibilityOptions } from "../constants";
import CreateModal from "../Create/CreateModal";
import FormContainerHeader from "../Shared/header.component";
import {
  StyledBody,
  StyledDescription,
  StyledGridCard,
  StyledGridList,
  StyledTemplateCategoriesContainer,
  StyledTitle
} from "../Templates/templates.style";
import FormAction from "./Action/action.component";

const tabs = ["all", "administrative", "external", "survey"];

const Forms = ({ forms = [], refreshPage }) => {
  const { search } = useLocation();
  const { type } = queryString.parse(search);
  const filteredTab = type ? tabs.find(tab => tab === type) : "all";

  const getFormsByType =
    filteredTab === "all"
      ? forms
      : forms.filter(form => form.type === filteredTab);

  const [viewType, setViewType] = useState("list");
  const headers = [
    {
      displayName: "Form Name",
      sortingFieldName: "title"
    },
    {
      displayName: "Last time edited",
      sortingFieldName: "last_time_edited"
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];

  const fields = [
    "title",
    {
      render: ({ row }) => (
        <div className="d-flex justify-content-start align-items-center">
          {row.updated_at}
        </div>
      )
    },
    {
      render: ({ row }) => <FormAction id={row._id} refreshPage={refreshPage} />
    }
  ];

  const [showAllForms, setShowAllForms] = useState(true);

  const filteredForms = showAllForms
    ? getFormsByType
    : getFormsByType?.filter(
        form => form.visibility === FormVisibilityOptions.Me
      );

  const options = {
    data: filteredForms,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: false,
    headerAction: () => (
      <>
        <CustomButton
          title="Create New Form"
          onClick={() => setCreateFormModalVisible(true)}
        />
      </>
    )
  };
  const [createFormModalVisible, setCreateFormModalVisible] = useState(false);
  return (
    <StyledTemplateCategoriesContainer>
      <ul className="generic-page-tab ">
        {tabs.map(tab => (
          <li key={tab} className={filteredTab === tab ? "active" : ""}>
            <Link to={`/business/forms/list?type=${tab}`}>
              {tab[0].toUpperCase() + tab.substring(1)}
            </Link>
          </li>
        ))}
      </ul>
      <FormContainerHeader spaceBetween>
        <div className="period-selector">
          <label>
            <span style={{ paddingLeft: 0 }}>My Forms </span>
            <input
              type="checkbox"
              checked={showAllForms}
              onChange={e => setShowAllForms(e.target.checked)}
            />
            <i></i>
            <span>All</span>
          </label>
        </div>
        <ViewSwitch
          grid={() => setViewType("grid")}
          list={() => setViewType("list")}
          active={viewType}
        />
      </FormContainerHeader>

      {viewType === "list" ? (
        <Table options={options} />
      ) : (
        <StyledGridList>
          {filteredForms.map((form, index) => (
            <StyledGridCard key={index}>
              <StyledBody>
                <StyledTitle>{form.title}</StyledTitle>
                <StyledDescription>{form.updated_at}</StyledDescription>
                <FormAction id={form._id} refreshPage={refreshPage} />
              </StyledBody>
            </StyledGridCard>
          ))}
        </StyledGridList>
      )}
      {createFormModalVisible && (
        <CreateModal close={() => setCreateFormModalVisible(false)} />
      )}
    </StyledTemplateCategoriesContainer>
  );
};

export default Forms;
