/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useBusinessCaseManagementCaseFormContext from "../../../../../../providers/CaseManagement/Cases/form";
import {
  CenteredBox,
  Content,
  ClientInfo,
  PhotoDisplay,
  InfoBlock,
  AddressBlock,
  Text
} from "./index.styled-components";

const LeftSideView = () => {
  const { getters } = useBusinessCaseManagementCaseFormContext();
  const { caseManagementCase } = getters;

  const fullName = `${caseManagementCase?.client?.firstName ?? ""} ${
    caseManagementCase?.client?.lastName ?? ""
  }`.trim();

  const email = caseManagementCase?.client?.email ?? "";

  const phone = caseManagementCase?.client?.phone ?? "";

  const address = caseManagementCase?.client?.address ?? {};
  const {
    address: addressValue = "",
    country = "",
    city = "",
    state = "",
    zipCode = ""
  } = address;

  const addressString = `${addressValue}, ${city}, ${state}, ${country}, ${zipCode}`;

  const photoUrl = caseManagementCase?.client?.profilePictureUrl ?? "";

  return (
    <CenteredBox>
      <Content>
        <ClientInfo>CLINT INFO</ClientInfo>
        <PhotoDisplay url={photoUrl} />
        <InfoBlock width={116}>
          <Text bold>NAME</Text>
          <Text>{fullName}</Text>
        </InfoBlock>
        <InfoBlock width={137}>
          <Text bold>EMAIL</Text>
          <Text>{email}</Text>
        </InfoBlock>
        <InfoBlock width={102}>
          <Text bold>PHONE</Text>
          <Text>{phone}</Text>
        </InfoBlock>
        <AddressBlock width={199}>
          <Text bold>ADDRESS</Text>
          <Text>{addressString}</Text>
        </AddressBlock>
      </Content>
    </CenteredBox>
  );
};

export default LeftSideView;
