import {
  IconTeam,
  StatsGroup,
  StatsInvitations,
  StatsMessage,
  StatsSubmissions,
  StatsSupport,
} from "../assets/icons/icon";

export const statsSettings = [
  {
    title: "CONTACTS",
    icon: StatsGroup,
  },
  {
    title: "SUBSMISSIONS",
    icon: StatsSubmissions,
  },
  {
    title: "SERVICES",
    icon: StatsSupport,
  },
  {
    title: "TEAMS",
    icon: IconTeam,
  },
  {
    title: "MESSAGES",
    icon: StatsMessage,
  },
  {
    title: "INVITATIONS",
    icon: StatsInvitations,
  },
];
