import React from 'react'
import { ProfileCard } from '../ProfileCard'
import { Formik, Form, Field } from 'formik'
import { LabelBlueBold } from '../../../../Layout/Shared/LabelBlueBold'
import { useBusinessProfileSettingsContext } from '../../../../../providers/Settings/Profile/business'
import PhoneInput from 'react-phone-input-2'
import ErrorField from '../../../../Layout/Shared/ErrorField'
import { StaffProfileSchema } from '../../../../../schemas/ProfileSettings/business'

export const MyProfile = () => {
  const {
    values: { staffProfile, staffProfilePictureUrl },
    setters: { setStaffProfile, setStaffProfilePictureUrl },
  } = useBusinessProfileSettingsContext()

  return (
    <div className="row ps-5 pe-5  pb-5">
      <div className="col-xl-3 col-lg-4 col-md-4 border-end pt-5">
        <ProfileCard
          title={`${staffProfile.firstName} ${staffProfile.lastName}`}
          buttonValue="Upload Your Picture"
          imageUrl={staffProfilePictureUrl}
          setImageUrl={setStaffProfilePictureUrl}
          source="owner-profile-picture"
        />
      </div>
      <div className="col-xl-9 col-lg-8 col-md-8 p-5">
        <div className="row">
          <div className="col-xl-5 pe-5 ">
            <Formik
              enableReinitialize={true}
              initialValues={staffProfile}
              validateOnBlur={false}
              validationSchema={StaffProfileSchema}
            >
              {({ errors, values }) => (
                <>
                  {setStaffProfile(values)}
                  <Form>
                    <div className="h2 pb-4">Profile Details</div>
                    <LabelBlueBold content="Your Name" />
                    <div className="d-flex justify-content-between">
                      <Field
                        className="custom-input me-2"
                        placeholder="Enter First Name"
                        name="firstName"
                      />
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Enter Last Name"
                        name="lastName"
                      />
                    </div>
                    <div className="mt-5">
                      <LabelBlueBold content="Email Address" />
                      <Field
                        className="custom-input"
                        placeholder="john@soleenterprises.com"
                        name="email"
                        disabled={true}
                      />
                    </div>
                    <div className="mt-4">
                      <LabelBlueBold content="Address" />
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Enter Your Address"
                        name="address"
                      />
                    </div>
                    <div className="mt-4">
                      <LabelBlueBold content="Zip Code" />
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Enter Your Zip Code"
                        name="zipCode"
                      />
                    </div>
                    <div className="mt-4">
                      <LabelBlueBold content="City" />
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Enter Your City"
                        name="city"
                      />
                    </div>
                    <div className="mt-4">
                      <LabelBlueBold content="Country" />
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Enter Your Country"
                        name="country"
                      />
                    </div>

                    <div className="mt-5 form-group  custom-phone">
                      <LabelBlueBold content="Phone Number" />
                      <Field name="phone">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <PhoneInput
                            enableSearch
                            placeholder="Enter Mobile Number"
                            country={'ca'}
                            value={value}
                            onChange={(number) =>
                              setFieldValue('phone', number)
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <ErrorField message={errors.firstName} />
                    <ErrorField message={errors.lastName} />
                    <ErrorField message={errors.address} />
                    <ErrorField message={errors.phone} />
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
