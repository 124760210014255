export const plans = {
  FREE: "FREE",
  SMART: "SMART",
  PROFESSIONAL: "PROFESSIONAL",
  ENTERPRISE: "ENTERPRISE",
};
export const features = [
  "Dashboard",
  "Client Portal",
  "Messaging",
  "Notifications",
  "Contacts",
  "Language [En & Fr]",
  "From Builder",
  "Forms",
  "From Analysis",
  "Submissions",
  "Services",
  "Sectors",
  "Programs",
  "Contact Management",
  "Advance Analytics",
  "Permissions",
  "Calendars",
  "Mobile App",
  "APIs Integrations",
  "Backend Generic",
  "Workflows",
  "Custom Branding",
];
export const planData = [
  {
    name: plans.FREE,
    monthlyTitle: "Forever",
    yearlyTitle: "Forever",
    monthlyPrice: "$0.00",
    yearlyPrice: "$0.00",
    isMonthly: true,
    active: false,
    featureList: [
      "OK",
      "1 User",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "5 Forms",
      "OK",
      "100 Submissions",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
      "-",
    ],
  },
  {
    name: plans.SMART,
    monthlyTitle: "Billed Monthly",
    yearlyTitle: "Billed Yearly",
    monthlyPrice: "$19.99",
    yearlyPrice: "$99.00",
    isMonthly: true,
    active: false,
    featureList: [
      "OK",
      "Per User",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "50 Forms",
      "OK",
      "1500 Submissions",
      "5 Services",
      "5 Sectors",
      "OK",
      "OK",
      "OK",
      "OK",
      "5 Calendars",
      "OK",
      "-",
      "-",
      "-",
      "-",
    ],
  },
  {
    name: plans.PROFESSIONAL,
    monthlyTitle: "Billed Monthly",
    yearlyTitle: "Billed Yearly",
    monthlyPrice: "$59.99",
    yearlyPrice: "$599.00",
    isMonthly: true,
    active: true,
    featureList: [
      "OK",
      "Unlimited",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "Unlimited",
      "OK",
      "Unlimited",
      "Unlimited",
      "Unlimited",
      "OK",
      "OK",
      "OK",
      "OK",
      "Unlimited",
      "OK",
      "OK",
      "-",
      "-",
      "-",
    ],
  },
  {
    name: plans.ENTERPRISE,
    monthlyTitle: "Billed Monthly",
    yearlyTitle: "Billed Yearly",
    monthlyPrice: "Call Us",
    yearlyPrice: "Call Us",
    isMonthly: true,
    active: false,
    featureList: [
      "OK",
      "Unlimited",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "Unlimited",
      "OK",
      "Unlimited",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
      "OK",
    ],
  },
];
