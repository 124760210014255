import React from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import RequireOption from "../CommonElements/RequireOption";
import PlaceholderOption from "../CommonElements/PlaceholderOption";
import KpiOption from "../CommonElements/KpiOption";
import BaselineOption from "../CommonElements/BaselineOption";
import QualitativeOption from "../CommonElements/QualitativeOption";

export function InputTextOptionsTab({component_key, attributes, dispatch}) {
  return (
    <div>
      <RequireOption
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <PlaceholderOption
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <KpiOption
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      ></KpiOption>
      <BaselineOption
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <QualitativeOption
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
    </div>
  );
}

export default InputTextOptionsTab;
