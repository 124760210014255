import React from "react";
import "./index.scss";
export const RightToggler = ({title, children, size, close, arrow = false}) => (
  <div className={"right-toggler active"} style={{width: size}}>
    <div className="toggler-title">
      <span className={arrow ? "arrow-active" : ""}>{title}</span>
      <button onClick={close} className="toggle-close">
        &times;
      </button>
    </div>
    <div className="body">{children}</div>
  </div>
);
