import React, { useRef, useState } from "react";
import { updateOptions } from "../../../utils";
import Tab from "../Shared/Tab";
import ComponentBox from "../Shared/Box";
import AttachmentUpload from "./Components/AttachmentUpload";
import Attachment from "../../../../Viewer/Components/Attachment";
import { deleteFormAttachment } from "../../../../../../data/Forms/deleteFormAttachment";
import { UploadFormAttachmentRequest } from "../../../../../../data/Forms/uploadAttachment";
import { StyledContainer, StyledTabButton, StyledAttachmentList, StyledAttachmentsContainer, StyledLabelText, StyledImageInput } from "./styles";

const AttachmentComponent = ({
  component,
  isReadOnly,
  onOptionsChange,
  onOrderButtonClick,
  onDeleteClick,
  onDuplicateClick,
  boxVisible = true
}) => {
  const fileInputRef = useRef();
  const [imgInput, setImgInput] = useState([])
  const [isDisplay, setIsDisplay] = useState(component.options?.allOptions?.length > 0 ? "block" : "none");
  const updateValue = (valueType, value, Key, fileName) => {
    if (!isReadOnly) {
      let newOptions = updateOptions(component.options, "valueType", valueType);
      newOptions = updateOptions(newOptions, "fileName", fileName);
      newOptions = updateOptions(newOptions, "value", value);
      newOptions = updateOptions(newOptions, "Key", Key);
      newOptions = updateOptions(newOptions, "allOptions", [...newOptions?.allOptions, {
        valueType,
        fileName,
        value,
        Key
      }]);
      onOptionsChange(newOptions);
    }
  };
  const deleteServiceAttachment = async (Key) => {
    await deleteFormAttachment(Key);
  };

  const handleRemoveAttachment = (value, Key) => {
    deleteServiceAttachment(Key)
    let filterOptions = component.options.allOptions.filter((opt) => opt.value !== value);
    let newOptions = updateOptions(component.option, "allOptions", filterOptions);
    onOptionsChange(newOptions);
  }
  const handleOnChange = async (e) => {
    const file = e.target.files[0]
    setImgInput(file)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    const meta = {
      type: file.type,
      name: file.name
    }
    const uploadedAttachment = await UploadFormAttachmentRequest(file, meta);
    const { hasSuccess } = uploadedAttachment;
    if (hasSuccess) {
      updateValue(`${file.type}`, uploadedAttachment.data.fileDetails.Location, uploadedAttachment.data.fileDetails.Key, `${file.name}`)
      setIsDisplay("block");
    }
  }

  const editComponent = (
    <StyledContainer>
      {isReadOnly === undefined && <>
        <StyledTabButton onClick={() => fileInputRef.current.click()}>Add a File</ StyledTabButton>
        <StyledImageInput
          ref={fileInputRef}
          onChange={handleOnChange}
          imgInput={imgInput}
          type="file"
          accept={["*"].join(",")}
        />
      </>}
      <StyledLabelText display={isDisplay}>Download the File to Get Started</StyledLabelText>
      <StyledAttachmentsContainer>
        <StyledAttachmentList>
          {
            component.options?.allOptions?.map((options) => (
              <AttachmentUpload
                isReadOnly={isReadOnly}
                value={options.value}
                valueType={options.valueType}
                fileName={options.fileName}
                Key={options.Key}
                handleRemoveAttachment={handleRemoveAttachment}
                onChange={(valueType, value) => updateValue(valueType, value)}
              />
            ))
          }
        </StyledAttachmentList>
      </StyledAttachmentsContainer>
    </StyledContainer>
  );

  const previewComponent = (
    <Attachment
      component={component}
      onChange={newOptions => onOptionsChange(newOptions)}

    />
  );

  if (isReadOnly || !boxVisible) {
    return editComponent;
  }
  return (
    <ComponentBox
      title="Add Attachment to This Form"
      column={component.options.column}
      previewMode={component.options.preview}
      onPreviewClick={() => {
        onOptionsChange(
          updateOptions(
            component.options,
            "preview",
            !component.options.preview
          )
        );
      }}
      onDeleteClick={onDeleteClick}
      onDuplicateClick={onDuplicateClick}
      onColumnChanged={column =>
        onOptionsChange(updateOptions(component.options, "column", column))
      }
      onOrderButtonClick={onOrderButtonClick}
    >
      {component.options.preview ? (
        previewComponent
      ) : (
        <Tab
          editComponent={editComponent}
        />
      )}
    </ComponentBox>
  );
};

export default AttachmentComponent;