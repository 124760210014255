import { caseManagementApiHostName } from "../endpoint";
import { defaultHeaders } from "../headers";
import { getBase64 } from "../../utils/base64";

export const getCases = async () => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/cases`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const getCase = async id => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/cases/${id}`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const createCase = async model => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/cases`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify(model)
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const updateCase = async (id, model) => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/cases/${id}`, {
      method: "PUT",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify(model)
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const deleteCase = async id => {
  try {
    const result = await fetch(`${caseManagementApiHostName}/cases/${id}`, {
      method: "DELETE",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const getCaseAttachments = async id => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/cases/${id}/attachments`,
      {
        method: "GET",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const createCaseAttachment = async (id, file, meta) => {
  const body = new FormData();
  const fileData = await getBase64(file);
  body.append("file", fileData);
  return {
    url: `${caseManagementApiHostName}/cases/${id}/attachments`,
    body,
    headers: {
      s3_content_type: meta.type,
      file_name: meta.name,
      Authorization: `Bearer ${localStorage.getItem("access_token")}`
    }
  };
};

export const deleteCaseAttachment = async (id, attachmentId, model) => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/cases/${id}/attachments/${attachmentId}`,
      {
        method: "DELETE",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(model)
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const updateCaseForms = async (id, model) => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/cases/${id}/forms`,
      {
        method: "PATCH",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(model)
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const updateCaseTasks = async (id, model) => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/cases/${id}/tasks`,
      {
        method: "PATCH",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(model)
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const getCaseForms = async id => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/cases/${id}/forms`,
      {
        method: "GET",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const createCaseForm = async (id, model) => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/cases/${id}/forms`,
      {
        method: "POST",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(model)
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const deleteCaseForm = async (id, formId) => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/cases/${id}/forms/${formId}`,
      {
        method: "DELETE",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const getTasksByCaseId = async id => {
  try {
    const result = await fetch(
      `${caseManagementApiHostName}/cases/${id}/tasks`,
      {
        method: "GET",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
