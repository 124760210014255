const STATES = [
  {
    country_code: "CA",
    name: "Alberta",
    state_code: "AB",
  },
  {
    country_code: "CA",
    name: "British Columbia",
    state_code: "BC",
  },
  {
    country_code: "CA",
    name: "Manitoba",
    state_code: "MB",
  },
  {
    country_code: "CA",
    name: "New Brunswick",
    state_code: "NB",
  },
  {
    country_code: "CA",
    name: "Newfoundland and Labrador",
    state_code: "NL",
  },
  {
    country_code: "CA",
    name: "Northwest Territories",
    state_code: "NT",
  },
  {
    country_code: "CA",
    name: "Nova Scotia",
    state_code: "NS",
  },
  {
    country_code: "CA",
    name: "Nunavut",
    state_code: "NU",
  },
  {
    country_code: "CA",
    name: "Ontario",
    state_code: "ON",
  },
  {
    country_code: "CA",
    name: "Prince Edward Island",
    state_code: "PE",
  },
  {
    country_code: "CA",
    name: "Quebec",
    state_code: "QC",
  },
  {
    country_code: "CA",
    name: "Saskatchewan",
    state_code: "SK",
  },
  {
    country_code: "CA",
    name: "Yukon",
    state_code: "YT",
  },
  {
    country_code: "US",
    name: "Alabama",
    state_code: "AL",
  },
  {
    country_code: "US",
    name: "Alaska",
    state_code: "AK",
  },
  {
    country_code: "US",
    name: "American Samoa",
    state_code: "AS",
  },
  {
    country_code: "US",
    name: "Arizona",
    state_code: "AZ",
  },
  {
    country_code: "US",
    name: "Arkansas",
    state_code: "AR",
  },
  {
    country_code: "US",
    name: "Baker Island",
    state_code: "UM-81",
  },
  {
    country_code: "US",
    name: "California",
    state_code: "CA",
  },
  {
    country_code: "US",
    name: "Colorado",
    state_code: "CO",
  },
  {
    country_code: "US",
    name: "Connecticut",
    state_code: "CT",
  },
  {
    country_code: "US",
    name: "Delaware",
    state_code: "DE",
  },
  {
    country_code: "US",
    name: "District of Columbia",
    state_code: "DC",
  },
  {
    country_code: "US",
    name: "Florida",
    state_code: "FL",
  },
  {
    country_code: "US",
    name: "Georgia",
    state_code: "GA",
  },
  {
    country_code: "US",
    name: "Guam",
    state_code: "GU",
  },
  {
    country_code: "US",
    name: "Hawaii",
    state_code: "HI",
  },
  {
    country_code: "US",
    name: "Howland Island",
    state_code: "UM-84",
  },
  {
    country_code: "US",
    name: "Idaho",
    state_code: "ID",
  },
  {
    country_code: "US",
    name: "Illinois",
    state_code: "IL",
  },
  {
    country_code: "US",
    name: "Indiana",
    state_code: "IN",
  },
  {
    country_code: "US",
    name: "Iowa",
    state_code: "IA",
  },
  {
    country_code: "US",
    name: "Jarvis Island",
    state_code: "UM-86",
  },
  {
    country_code: "US",
    name: "Johnston Atoll",
    state_code: "UM-67",
  },
  {
    country_code: "US",
    name: "Kansas",
    state_code: "KS",
  },
  {
    country_code: "US",
    name: "Kentucky",
    state_code: "KY",
  },
  {
    country_code: "US",
    name: "Kingman Reef",
    state_code: "UM-89",
  },
  {
    country_code: "US",
    name: "Louisiana",
    state_code: "LA",
  },
  {
    country_code: "US",
    name: "Maine",
    state_code: "ME",
  },
  {
    country_code: "US",
    name: "Maryland",
    state_code: "MD",
  },
  {
    country_code: "US",
    name: "Massachusetts",
    state_code: "MA",
  },
  {
    country_code: "US",
    name: "Michigan",
    state_code: "MI",
  },
  {
    country_code: "US",
    name: "Midway Atoll",
    state_code: "UM-71",
  },
  {
    country_code: "US",
    name: "Minnesota",
    state_code: "MN",
  },
  {
    country_code: "US",
    name: "Mississippi",
    state_code: "MS",
  },
  {
    country_code: "US",
    name: "Missouri",
    state_code: "MO",
  },
  {
    country_code: "US",
    name: "Montana",
    state_code: "MT",
  },
  {
    country_code: "US",
    name: "Navassa Island",
    state_code: "UM-76",
  },
  {
    country_code: "US",
    name: "Nebraska",
    state_code: "NE",
  },
  {
    country_code: "US",
    name: "Nevada",
    state_code: "NV",
  },
  {
    country_code: "US",
    name: "New Hampshire",
    state_code: "NH",
  },
  {
    country_code: "US",
    name: "New Jersey",
    state_code: "NJ",
  },
  {
    country_code: "US",
    name: "New Mexico",
    state_code: "NM",
  },
  {
    country_code: "US",
    name: "New York",
    state_code: "NY",
  },
  {
    country_code: "US",
    name: "North Carolina",
    state_code: "NC",
  },
  {
    country_code: "US",
    name: "North Dakota",
    state_code: "ND",
  },
  {
    country_code: "US",
    name: "Northern Mariana Islands",
    state_code: "MP",
  },
  {
    country_code: "US",
    name: "Ohio",
    state_code: "OH",
  },
  {
    country_code: "US",
    name: "Oklahoma",
    state_code: "OK",
  },
  {
    country_code: "US",
    name: "Oregon",
    state_code: "OR",
  },
  {
    country_code: "US",
    name: "Palmyra Atoll",
    state_code: "UM-95",
  },
  {
    country_code: "US",
    name: "Pennsylvania",
    state_code: "PA",
  },
  {
    country_code: "US",
    name: "Puerto Rico",
    state_code: "PR",
  },
  {
    country_code: "US",
    name: "Rhode Island",
    state_code: "RI",
  },
  {
    country_code: "US",
    name: "South Carolina",
    state_code: "SC",
  },
  {
    country_code: "US",
    name: "South Dakota",
    state_code: "SD",
  },
  {
    country_code: "US",
    name: "Tennessee",
    state_code: "TN",
  },
  {
    country_code: "US",
    name: "Texas",
    state_code: "TX",
  },
  {
    country_code: "US",
    name: "United States Minor Outlying Islands",
    state_code: "UM",
  },
  {
    country_code: "US",
    name: "United States Virgin Islands",
    state_code: "VI",
  },
  {
    country_code: "US",
    name: "Utah",
    state_code: "UT",
  },
  {
    country_code: "US",
    name: "Vermont",
    state_code: "VT",
  },
  {
    country_code: "US",
    name: "Virginia",
    state_code: "VA",
  },
  {
    country_code: "US",
    name: "Wake Island",
    state_code: "UM-79",
  },
  {
    country_code: "US",
    name: "Washington",
    state_code: "WA",
  },
  {
    country_code: "US",
    name: "West Virginia",
    state_code: "WV",
  },
  {
    country_code: "US",
    name: "Wisconsin",
    state_code: "WI",
  },
  {
    country_code: "US",
    name: "Wyoming",
    state_code: "WY",
  },
];

export default STATES;

export const getStateNameByStateCode = (code) =>
  STATES.find((state) => state.state_code === code)?.name || "";

export const getCountryCodeByStateCode = (code) =>
  STATES.find((state) => state.state_code === code)?.country_code || "";
