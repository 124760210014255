import React, {useState, useEffect} from "react";
import CountrySelect from "../Selects/CountrySelect";
import StateSelect from "../Selects/StateSelect";
import CitySelect from "../Selects/CitySelect";
import {
  getCountryNameByCountryCode,
  FILTERED_COUNTRIES_CODES,
} from "../../.././../static/Shared/countries";
import {
  getStateNameByStateCode,
  getCountryCodeByStateCode,
} from "../../.././../static/Shared/states";
import {isDefaultSelection} from "../../../../utils/string";
import "./index.scss";

const MultipleCitySelection = ({locations, setLocations}) => {
  const [currentCountryCode, setCurrentCountryCode] = useState(null);
  const [currentStateCode, setStateCountryCode] = useState(null);
  const [selectedCities, setSelectedCities] = useState(
    locations.map((location) => ({
      text: location.city,
      id: location.stateCode,
    })) || []
  );

  const handleCountryOnSelect = ({value}) => setCurrentCountryCode(value);
  const handleStateOnSelect = ({value}) => setStateCountryCode(value);
  const handleCityOnSelect = (city) => {
    if (isDefaultSelection(city.id)) return;

    setSelectedCities([
      ...selectedCities.filter(
        (selectedCity) => selectedCity.text !== city.text
      ),
      city,
    ]);
    refreshCitySelection();
  };

  const [showCitySelection, setShowCitySelection] = useState(true);
  const refreshCitySelection = () => {
    setShowCitySelection(false);
    setTimeout(() => {
      setShowCitySelection(true);
    }, 10);
  };

  useEffect(() => {
    const locations = [];
    selectedCities.forEach((city) => {
      locations.push({
        city: city.text,
        stateCode: city.id,
        state: getStateNameByStateCode(city.id),
        countryCode: getCountryCodeByStateCode(city.id),
        country: getCountryNameByCountryCode(
          getCountryCodeByStateCode(city.id)
        ),
      });
    });
    setLocations(locations);
  }, [selectedCities, setLocations]);

  const SelectedCities = () => (
    <ul className="cities-partial">
      {selectedCities.map((city, index) => (
        <li key={index} data-id={`${city.id}`} data-text={city.text}>
          {`${city.text}, ${city.id}, ${getCountryCodeByStateCode(city.id)}`}
          <span onClick={() => handleRemoveSelectedCity(city)}>&times;</span>
        </li>
      ))}
    </ul>
  );

  const handleRemoveSelectedCity = (city) => {
    setSelectedCities([
      ...selectedCities.filter(
        (selectedCity) => selectedCity.text !== city.text
      ),
    ]);
  };

  return (
    <>
      <div className="form">
        <h4 className="pl-4   text-secondary">Add service locations</h4>
        <div className="form-group">
          <CountrySelect
            filteredCodes={FILTERED_COUNTRIES_CODES}
            onChange={({target}) => handleCountryOnSelect(target)}
          />
        </div>

        <div className="form-group">
          <StateSelect
            countryCode={currentCountryCode}
            onChange={({target}) => handleStateOnSelect(target)}
          />
        </div>

        <div className="form-group">
          {showCitySelection && (
            <CitySelect
              className="custom-input ml-0"
              countryCode={currentCountryCode}
              stateCode={currentStateCode}
              filteredCityNames={selectedCities.map(
                (selectedCity) => selectedCity.text
              )}
              onSelect={(city) => handleCityOnSelect(city)}
            />
          )}
          <SelectedCities />
        </div>
      </div>
    </>
  );
};

export default MultipleCitySelection;
