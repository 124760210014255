import React from 'react'
import {
  StyledDesignationInput,
  StyledDesignationWrapper,
  StyledFullNameInput,
} from '../styles'

const Designation = ({
  designation,
  fullName,
  onDesignationChange,
  onFullNameChange,
  isReadOnly,
}) => (
  <StyledDesignationWrapper>
    <StyledDesignationInput
      className="form-control"
      placeholder="Designation"
      readOnly={isReadOnly}
      value={designation}
      onChange={(e) => onDesignationChange(e.currentTarget.value)}
    />
    <StyledFullNameInput
      className="form-control"
      placeholder="Full Name"
      readOnly={isReadOnly}
      value={fullName}
      onChange={(e) => onFullNameChange(e.currentTarget.value)}
    />
  </StyledDesignationWrapper>
)

export default Designation
