import React, { useState, useContext } from "react";
import "../../../../../styles/customComponents/servicePageHeader.scss";
import { CustomButton } from "../../../../Layout/Shared/Button";
import { Options } from "../../../../Layout/Shared/DropdownOptionsMenu";
import Table from "../../../../Layout/Shared/Table";
import { ThreeDotIcon } from "../../../../../assets/icons/icon";
import { AddForms } from "./AddForms";
import { DeleteModal } from "../../../../Layout/Shared/Popups/Delete";
import BusinessServiceContext from "../../../../../providers/Service/business";

const Forms = () => {
  const {
    values: { forms },
    setters: { setForms },
  } = useContext(BusinessServiceContext);

  const [showModal, setShowModal] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const ActionButtons = ({ row }) => (
    <>
      <Options
        arrow={false}
        buttonTitle={ThreeDotIcon}
        menuItems={
          <div>
            <button
              className="text-danger"
              onClick={() => setshowDelete(!showDelete)}
            >
              Delete
            </button>
            <button
              type='button'
              onClick={() => {
                window.open(`/preview/${row.id}`, "_blank")
              }}
            >
              View
            </button>
          </div>
        }
      />
      {showDelete && (
        <DeleteModal
          title="Delete Form"
          text="You are about to delete this form. Deleting this client removes all client’s information and ends all previous communication between you and the client."
          buttonText="Delete"
          onConfirm={() => onDeleteForm(row.name)}
          onClose={() => setshowDelete(!showDelete)}
        />
      )}

    </>
  );
  const ActionsComponent = () => (
    <CustomButton
      color="blue"
      title="Add Form"
      size="140px"
      onClick={() => setShowModal(!showModal)}
    />
  );

  const fields = ["id", "name", "lastModified", { render: ActionButtons }];
  const headers = [
    {
      displayName: "Id",
      sortingFieldName: "id",
      sortingOptions: { dataType: "number" },
    },
    {
      displayName: "Form Name",
      sortingFieldName: "name",
    },
    {
      displayName: "Last Modified",
      sortingFieldName: "lastModified",
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions",
    },
  ];

  const options = {
    data: forms || [],
    headers,
    fields,
    itemsPerPage: 25,
    pagination: true,
    search: true,
    sort: true,
    headerAction: ActionsComponent,
  };

  const onDeleteForm = (name) =>
    setForms(forms.filter((form) => form.name !== name));

  return (
    <section className="p-5 ">
      <div className="service-page-header">
        <Table options={options} />
      </div>
      {showModal && (
        <AddForms
          selectedForms={forms}
          onSave={(newForms) => setForms([...forms,...newForms])}
          onClose={() => setShowModal(!showModal)}
        />
      )}
    </section>
  );
};
export default Forms;
