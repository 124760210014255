import styled, { css } from 'styled-components'
import * as theme from './../../../../../../styles/theme'

export const StyledAttachmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`
export const StyledAttachmentList = styled.div`
  display: flex; 
  flex-wrap: wrap;
`;

export const StyledImageInput = styled.input`
  display: none;
`
export const StyledTabButton = styled.button`
  padding: 1em 1em;
  font-family: ${theme.defaultFont};
  font-size: 12px;
  font-weight: 700;
  border-radius: 5px;
  background-color: white;
  color:${theme.azul};
  max-width: 100px;
`
export const StyledLabelText = styled.label`
  ${({ display }) => css`
    display: ${display};
    font-family: ${theme.defaultFont};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    margin-bottom: 1rem;
    color: ${theme.azul};
  `}
`;
