/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import {
  getTask,
  createTask,
  updateTask,
  deleteTask as _deleteTask,
  cancelTask as _cancelTask
} from "../../../data/CaseManagement/tasks";
import { get as getStaffs } from "../../../data/Settings/Business/Team/getStaffs";
import { fullNameFormatter } from "../../../utils/string";
import CaseManagementTaskCreateSchema from "../../../schemas/CaseManagement/Task/create";
import CaseManagementTaskUpdateSchema from "../../../schemas/CaseManagement/Task/update";
import { getFullNameFromObject } from "../../../utils/string";

export const formatter = (data, currentUser) => {
  const now = new Date();
  const updatedBy = data.updatedBy
    ? getFullNameFromObject(data.updatedBy)
    : getFullNameFromObject(currentUser);
  return {
    _id: data._id ?? undefined,
    title: data.title ?? "",
    description: data.description ?? "",
    status: data.status ?? "Not Started",
    priority: data.priority ?? "Low",
    private: data.private ?? false,
    assignedTo: data.assignedTo ?? "",
    dueDate: data.dueDate ?? now,
    caseId: data.caseId ?? undefined,
    updatedBy
  };
};

export const useTaskFormData = () => {
  const [staffs, setStaffs] = useState([]);
  const [loading, setLoading] = useState(false);

  const load = async (hardLoad = false) => {
    if (!hardLoad && staffs.length) return;
    setLoading(true);
    const [{ data: staffsData, hasSuccess: staffsSuccess }] = await Promise.all(
      [getStaffs()]
    );
    if (staffsSuccess) setStaffs(staffsData.map(fullNameFormatter));
    setLoading(false);
  };

  return {
    staffs,
    loading,
    load
  };
};

export const useTaskModel = currentUser => {
  const [task, setTask] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);

  const load = async id => {
    setLoading(true);
    const { data, hasSuccess } = await getTask(id);
    if (hasSuccess) setTask(formatter(data));
    setLoading(false);
  };

  const reset = () => setTask(formatter({}, currentUser));

  const _delete = async id => {
    const { hasSuccess } = await _deleteTask(id);
    return hasSuccess;
  };

  const cancel = async id => {
    const { hasSuccess } = await _cancelTask(id);
    return hasSuccess;
  };

  const checkForm = async () => {
    const validateForm = async form => {
      try {
        const Schema =
          task && task._id
            ? CaseManagementTaskUpdateSchema
            : CaseManagementTaskCreateSchema;
        await Schema.validate(form, { abortEarly: false });
      } catch {
        return false;
      }
    };
    const model = { ...task };
    delete model.updatedBy;
    delete model._id;
    const validationResponse = await validateForm(model);
    if (validationResponse && validationResponse.errors) {
      setErrors([...new Set(validationResponse.errors)]);
      return false;
    }
    setErrors([]);
    return true;
  };

  const submit = async values => {
    const result = await checkForm();
    if (!result) return false;
    setSubmitting(true);
    const model = { ...task };
    delete model._id;
    delete model.updatedBy;
    const { hasSuccess } = await (task && task._id
      ? updateTask(task._id, model)
      : createTask(model));
    if (hasSuccess) {
      setSubmitting(false);
      return true;
    }
    setSubmitting(false);
    return false;
  };

  return {
    task,
    setTask,
    loading,
    load,
    reset,
    submit,
    delete: _delete,
    cancel,
    submitting,
    errors
  };
};
