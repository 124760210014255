import React from "react";

import "bootstrap/dist/js/bootstrap.min.js";
import {InputGroup, RadiusInput} from "../Base/BaseStyles";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";

export function InputTextEditTab({component_key, attributes, dispatch}) {
  const input_text_question = getAttribute("question", attributes);
  return (
    <InputGroup className="input-group">
      <RadiusInput
        type="text"
        className="form-control"
        aria-label=""
        placeholder="Enter your question"
        value={input_text_question}
        onChange={(event) =>
          dispatch({
            type: "input_text_question_change",
            question: event.target.value,
            key: component_key,
          })
        }
      />
    </InputGroup>
  );
}

export default InputTextEditTab;
