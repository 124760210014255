import {CustomButton} from "../../../Layout/Shared/Button";
import AttachedFile from "../../../../assets/images/attached-icon.svg";
export const AttachmentList = ({attachments, buttonTitle}) => {
  return (
    <section className="p-5">
      <div>
        {attachments.length > 0 ? (
          <div className="col-xl-12 attached-files">
            <label className="h4 font-weight-bold">Attached Files</label>
            <ul className="file-list">
              {attachments.map((file) => (
                <li key={file.name}>
                  <div>
                    <img src={AttachedFile} alt="" />
                  </div>
                  <div className="file-name">
                    {file.name}
                    <small>{file.size}KB</small>
                  </div>
                  <div>
                    <CustomButton
                      title={buttonTitle}
                      size="120px"
                      href={file.location}
                      target="_blank"
                      color="blue"
                      onClick={() => window.open(file.location, "_blank")}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="no-items">You have no items to view</div>
        )}
      </div>
    </section>
  );
};
