/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { CustomButton } from "../../../Layout/Shared/Button";
import { LabelBlueBold } from "../../../Layout/Shared/LabelBlueBold";
import { useHistory, useParams } from "react-router";
import { InnerPageLeft } from "../../../Layout/Shared/InnerPageLeft";
import { ClientCard } from "../../../Clients/Update/ClientCard";
import DataLoading from "../../../Layout/Shared/DataLoading";
import ErrorField from "../../../Layout/Shared/ErrorField";
import PhoneInput from "react-phone-input-2";
import { CountryInput } from "../../../Layout/Shared/CountryInput";
import "../../Create/index.scss";
import { useBusinessClientUpdateContext } from "../../../../providers/Client/update";
import ToggleSwitch from "../../../Layout/Shared/ToggleSwitch";
import { Autocomplete } from "../../../Layout/Shared/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GenderSelect from "../../../Layout/Shared/Selects/GenderSelect";

export const EditClientDetails = () => {
  const { id } = useParams();
  const { getters, setters, load } = useBusinessClientUpdateContext();
  const { client, isLoading, profilePictureUrl } = getters;
  const { setProfilePictureUrl } = setters;

  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    const init = async () => await load(id);
    id && init();
  }, [id]);

  const fullName = `${client.details?.yourInformation?.firstName} ${client.details?.yourInformation?.lastName}`;

  return isLoading ? (
    <DataLoading height="700px" />
  ) : (
    <div className="row m-2  bg-white border rounded-xl">
      <div className="col-md-12 p-5 border-bottom"></div>
      <div className="contact-left border-end">
        <InnerPageLeft>
          <ClientCard
            id={client.id}
            fullName={fullName}
            created_at={client.created_at}
            profilePictureUrl={profilePictureUrl}
            setProfilePictureUrl={setProfilePictureUrl}
            isFavorite={isFavorite}
            setIsFavorite={setIsFavorite}
            disabled={true}
            groups={client.groups}
            isDisabledMessage={
              !client.emailVerified || !client.onboardingCompleted
            }
          />
        </InnerPageLeft>
      </div>
      <div className="contact-right pl-0 pe-5  pt-5">
        <EditClientInformation />
      </div>
    </div>
  );
};

export const EditClientInformation = () => {
  const history = useHistory();
  const goToClientList = () => history.push(`/business/client/list`);
  const { getters, setters, submit } = useBusinessClientUpdateContext();
  const { client, errors } = getters;
  const { setClient } = setters;

  const immigration = client.details?.immigration ?? {};
  const immigrationStatus = immigration?.status ?? {};
  const other = client.details?.other ?? {};
  const showDocumentedDetails = immigrationStatus?.status === "Documented";
  const showOtherEducation = other?.educationStatus === "Other";
  const showImmigrantDetails = client.isImmigrant;

  return (
    <>
      <Formik enableReinitialize initialValues={client} validateOnBlur={false}>
        {({ values }) => (
          <>
            {setClient(values)}
            <Form>
              <div className="row pb-5 add-contact-page">
                <div className="page-left ps-5 mb-5">
                  <div className="pl-4">
                    <h2>Client Details</h2>
                    <div className="mt-5">
                      <LabelBlueBold content="Client Name" />
                      <div className="d-flex justify-content-between">
                        <Field
                          className="custom-input me-2"
                          placeholder="First Name"
                          name="details.yourInformation.firstName"
                          disabled
                        />
                        <Field
                          className="custom-input ms-2 "
                          placeholder="Last Name"
                          name="details.yourInformation.lastName"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <LabelBlueBold content="Email Address" />
                      <Field
                        className="custom-input"
                        placeholder="Enter Email Address"
                        name="email"
                        disabled
                      />
                    </div>
                    <div className="mt-4">
                      <LabelBlueBold content="Type" />
                      <Field>
                        {({ field: { value } }) => {
                          return (
                            <input
                              className="custom-input"
                              name="isImmigrant"
                              type="text"
                              value={
                                value.isImmigrant
                                  ? "This is an Immigrant Profile"
                                  : "Private Profile"
                              }
                              disabled
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="mt-4 custom-phone">
                      <LabelBlueBold content="Mobile Number" />
                      <Field name="details.yourInformation.phone">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <PhoneInput
                            enableSearch
                            placeholder="Enter Mobile Number"
                            country={"ca"}
                            value={value}
                            onChange={number =>
                              setFieldValue(
                                "details.yourInformation.phone",
                                number
                              )
                            }
                            disabled
                          />
                        )}
                      </Field>
                    </div>
                    <div className="mt-4">
                      <LabelBlueBold content="Client Address" />
                      <Field
                        className="custom-input"
                        placeholder="Client Address"
                        name="details.address.address"
                        disabled
                      />
                      <div className="d-flex justify-content-between mt-5">
                        <Field
                          className="custom-input me-2"
                          placeholder="City"
                          name="details.address.city"
                          disabled
                        />
                        <Field
                          className="custom-input ms-2 "
                          placeholder="State"
                          name="details.address.state"
                          disabled
                        />
                      </div>
                      <div className="d-flex justify-content-between mt-5">
                        <Field
                          name="details.address.country"
                          className="custom-input ms-2"
                        >
                          {({ field: { value }, form: { setFieldValue } }) => (
                            <CountryInput
                              name="details.address.country"
                              className="custom-input me-2"
                              placeholder="Country"
                              onSelect={({ text }) =>
                                setFieldValue("details.address.country", text)
                              }
                              defaultValue={value}
                              disabled
                            />
                          )}
                        </Field>
                        <div className="w-100 ms-2 ">
                          <Field
                            className="custom-input ms-2 "
                            placeholder="Zip Code"
                            name="details.address.zipCode"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="page-right pt-5 mt-5 ps-5 mb-5">
                  <div className="pl-4">
                    {/* Group 1: Gender-Age-Country of Origin */}
                    <div className="d-flex justify-content-between mt-5">
                      <Field
                        className="custom-input me-2"
                        name="details.yourInformation.gender"
                      >
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <GenderSelect
                            name="details.yourInformation.gender"
                            className="custom-input me-2"
                            value={value}
                            onSelect={value =>
                              setFieldValue(
                                "details.yourInformation.gender",
                                value
                              )
                            }
                            disabled
                          />
                        )}
                      </Field>
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Birthday"
                        name="details.yourInformation.dateOfBirth"
                      >
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <DatePicker
                            selected={new Date(value ?? Date.now())}
                            onChange={date =>
                              setFieldValue(
                                "details.yourInformation.dateOfBirth",
                                date.getTime()
                              )
                            }
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Birthday"
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            maxDate={new Date()}
                            showMonthDropdown
                            scrollableMonthYearDropdown
                            dropdownMode="select"
                            className="custom-input ms-2 "
                            name="details.yourInformation.dateOfBirth"
                            disabled
                          />
                        )}
                      </Field>
                      <Field
                        className="custom-input ms-2 "
                        name="details.yourInformation.countryOfOrigin"
                      >
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <CountryInput
                            name="details.yourInformation.countryOfOrigin"
                            className="custom-input me-2"
                            placeholder="Country of Origin"
                            onSelect={({ text }) =>
                              setFieldValue(
                                "details.yourInformation.countryOfOrigin",
                                text
                              )
                            }
                            defaultValue={value}
                            disabled
                          />
                        )}
                      </Field>
                    </div>

                    {/* Group 2: Highest Level of Education-Other Education-Employment Status */}
                    <div className="d-flex justify-content-between mt-5">
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Highest Level of Education"
                        name="details.other.educationStatus"
                      >
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <EducationStatus
                            className="custom-input ms-2 "
                            placeholder="Highest Level of Education"
                            name="details.other.educationStatus"
                            educationStatus={value}
                            setEducationStatus={value =>
                              setFieldValue(
                                "details.other.educationStatus",
                                value
                              )
                            }
                            disabled
                          />
                        )}
                      </Field>
                      <Field
                        hidden={!showOtherEducation}
                        className="custom-input ms-2 "
                        placeholder="Education Other"
                        name="details.other.educationStatusOther"
                        disabled
                      />
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Employment Status"
                        name="details.other.employmentStatus"
                      >
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <EmployedStatus
                            className="custom-input ms-2 "
                            placeholder="Employment Status"
                            name="details.other.employmentStatus"
                            employedStatus={value}
                            setEmployedStatus={value =>
                              setFieldValue(
                                "details.other.employmentStatus",
                                value
                              )
                            }
                            disabled
                          />
                        )}
                      </Field>
                    </div>

                    {/* Group 3: Immigration Number-Arrival Status */}
                    <div className="d-flex justify-content-between mt-5">
                      <Field
                        className="custom-input me-2"
                        placeholder="Immigration Number"
                        name="details.immigration.number"
                        disabled
                      />
                      <Field
                        className="custom-input me-2"
                        placeholder="Arrival Status"
                        name="details.immigration.arrivalStatus"
                      >
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <ArrivalStatus
                            className="custom-input me-2"
                            placeHolder="Arrival Status"
                            name="details.immigration.arrivalStatus"
                            arrivalStatus={value}
                            setArrivalStatus={value =>
                              setFieldValue(
                                "details.immigration.arrivalStatus",
                                value
                              )
                            }
                            disabled
                          />
                        )}
                      </Field>
                    </div>

                    {/* Group 4: Immigration Status-Settlement Status-Date of Landing */}
                    {showImmigrantDetails && (
                      <div className="d-flex justify-content-between mt-5">
                        <Field
                          className="custom-input me-2"
                          placeholder="Immigration Status"
                          name="details.immigration.status.status"
                        >
                          {({ field: { value }, form: { setFieldValue } }) => (
                            <ImmigrationStatus
                              status={value}
                              setStatus={value =>
                                setFieldValue(
                                  "details.immigration.status.status",
                                  value
                                )
                              }
                              disabled
                            />
                          )}
                        </Field>
                        <Field
                          hidden={!showDocumentedDetails}
                          disabled={!showDocumentedDetails}
                          className="custom-input ms-2 "
                          placeholder="Settlement Status"
                          name="details.immigration.status.settlementStatus"
                        >
                          {({ field: { value }, form: { setFieldValue } }) => (
                            <SettlementStatus
                              hidden={!showDocumentedDetails}
                              disabled={!showDocumentedDetails}
                              className="custom-input ms-2 "
                              placeholder="Settlement Status"
                              name="details.immigration.status.settlementStatus"
                              settlementStatus={value}
                              setSettlementStatus={value =>
                                setFieldValue(
                                  "details.immigration.status.settlementStatus",
                                  value
                                )
                              }
                            />
                          )}
                        </Field>
                        <Field
                          hidden={!showDocumentedDetails}
                          disabled={!showDocumentedDetails}
                          className="custom-input ms-2 "
                          placeholder="Date of Landing"
                          name="details.immigration.status.dateOfLanding"
                        >
                          {({ field: { value }, form: { setFieldValue } }) =>
                            showDocumentedDetails && (
                              <DatePicker
                                hidden={!showDocumentedDetails}
                                disabled={!showDocumentedDetails}
                                selected={new Date(value ?? Date.now())}
                                onChange={date =>
                                  setFieldValue(
                                    "details.immigration.status.dateOfLanding",
                                    date.getTime()
                                  )
                                }
                                dateFormat="MM/dd/yyyy"
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                maxDate={new Date()}
                                showMonthDropdown
                                scrollableMonthYearDropdown
                                dropdownMode="select"
                                className="custom-input ms-2 "
                                placeholderText="Date of Landing"
                                name="details.immigration.status.dateOfLanding"
                              />
                            )
                          }
                        </Field>
                      </div>
                    )}
                  </div>

                  <div
                    className="col-12 mt-5"
                    style={{
                      bottom: "0",
                      right: "0",
                      display: "flex",
                      justifyContent: "flex-end",
                      textAlign: "right"
                    }}
                  >
                    <button
                      className="btn btn-link font-weight-bold me-5 "
                      onClick={goToClientList}
                    >
                      Cancel
                    </button>
                    <CustomButton
                      type="button"
                      title="Save Changes"
                      size="189px"
                      color="blue"
                      onClick={submit}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
      {errors &&
        errors.length > 0 &&
        errors.map((error, index) => (
          <ErrorField key={index} message={error} />
        ))}
    </>
  );
};

const ImmigrationStatus = ({ status, setStatus }) => {
  const handleChange = ({ target }) => setStatus(target.value);
  return (
    <ToggleSwitch
      selected={status === "Documented"}
      onChange={() =>
        handleChange({
          target: {
            value: status === "Documented" ? "Undocumented" : "Documented"
          }
        })
      }
      selectedLabel={"Documented"}
      unSelectedLabel={"Undocumented"}
    />
  );
};

const SettlementStatus = ({
  settlementStatus,
  setSettlementStatus,
  ...restOfProps
}) => {
  const SETTLEMENT_STATUS = [
    "Permanent Resident",
    "Skilled Worker",
    "Family Class",
    "Refugee Class",
    "Economic Class",
    "Approved in Principle",
    "Live-in Caregiver"
  ];

  return (
    <Autocomplete
      {...restOfProps}
      options={SETTLEMENT_STATUS.map(item => ({ id: item, text: item }))}
      defaultValue={settlementStatus}
      onSelect={({ text }) => setSettlementStatus(text)}
      placeholder="Select Settlement Status"
    />
  );
};

const EducationStatus = ({
  educationStatus,
  setEducationStatus,
  ...restOfProps
}) => {
  const EDUCATION_STATUS = [
    "Certificate",
    "Diploma",
    "College",
    "University (First Degree)",
    "Master’s Degree",
    "PhD",
    "Other"
  ];

  return (
    <Autocomplete
      {...restOfProps}
      options={EDUCATION_STATUS.map(item => ({ id: item, text: item }))}
      defaultValue={educationStatus}
      onSelect={({ text }) => setEducationStatus(text)}
      placeholder="Select Education Status"
    />
  );
};

const EmployedStatus = ({
  employedStatus,
  setEmployedStatus,
  ...restOfProps
}) => {
  const EMPLOYED_STATUS = ["Employed", "Unemployed", "Self-Employed"];
  return (
    <Autocomplete
      {...restOfProps}
      options={EMPLOYED_STATUS.map(item => ({ id: item, text: item }))}
      defaultValue={employedStatus}
      onSelect={({ text }) => setEmployedStatus(text)}
      placeholder="Select Employment Status"
    />
  );
};

const ArrivalStatus = ({ arrivalStatus, setArrivalStatus, ...restOfProps }) => {
  const ARRIVAL_STATUS = ["Pre-arrival", "Just Arrive", "Settling Down"];
  return (
    <Autocomplete
      {...restOfProps}
      options={ARRIVAL_STATUS.map(item => ({ id: item, text: item }))}
      defaultValue={arrivalStatus}
      onSelect={({ text }) => setArrivalStatus(text)}
      placeholder="Select Arrival Status"
    />
  );
};
