import React, {useState, useEffect} from "react";
import Table from "../../../../Layout/Shared/Table";
import {Options} from "../../../../Layout/Shared/DropdownOptionsMenu";
import {ThreeDotIcon} from "../../../../../assets/icons/icon";
import {DeleteModal} from "../../../../Layout/Shared/Popups/Delete";
import {ListLoader} from "../../../../Layout/Shared/DataLoading";
import {FormattedDate} from "../../../../Layout/Shared/FormattedDate";
import {get as getStaff} from "../../../../../data/Settings/Business/Team/getInvitedStaffs";
import {deleteStaff as deleteById} from "../../../../../data/Settings/Business/Team/deleteStaff";

export const InvitedStaff = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deleteStaff, setDeleteStaff] = useState(null);
  const [staffs, setStaffs] = useState([]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await getStaff();
      if (hasSuccess) {
        const dataAsArray = Object.values(data);
        setStaffs(dataAsArray.filter((staff) => staff.status === "Pending"));
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const ActionButtons = ({row}) => (
    <Options
      arrow={false}
      buttonTitle={ThreeDotIcon}
      menuItems={
        <div>
          <button className="text-danger" onClick={() => setDeleteStaff(row)}>
            Delete
          </button>
        </div>
      }
    />
  );
  const Status = ({row}) => (
    <span className={row.status === "Accepted" ? "text-success" : ""}>
      {row.status}
    </span>
  );
  const handleDeleteOnConfirm = async () => {
    setIsLoading(true);
    const {hasSuccess} = await deleteById(deleteStaff._id);
    if (hasSuccess) {
      setStaffs(staffs.filter((staff) => staff._id !== deleteStaff._id));
      setDeleteStaff(null);
      setIsLoading(false);
    }
  };

  const fields = [
    "email",
    "role",
    {
      render: ({row}) => (
        <span>{row.groups.map((group) => group.name).join(", ")}</span>
      ),
    },
    {
      render: ({row}) => (
        <FormattedDate date={row.verified_at || row.created_at} />
      ),
    },
    {render: Status},
    {render: ActionButtons},
  ];
  const headers = [
    {
      displayName: "Email",
      sortingFieldName: "email",
    },
    {
      displayName: "Role",
      sortingFieldName: "role",
    },
    {
      displayName: "Group/Team",
      sortingFieldName: "groups",
    },
    {
      displayName: "Date of Joining",
      sortingFieldName: "created_at",
    },
    {
      displayName: "Status",
      sortingFieldName: "status",
    },
    {
      displayName: "Action",
      sortingFieldName: "action",
    },
  ];

  const options = {
    data: staffs,
    headers,
    fields,
    itemsPerPage: 25,
    pagination: true,
    search: false,
    sort: true,
  };

  return (
    <section className="p-5">
      {isLoading ? <ListLoader height="750px" /> : <Table options={options} />}
      {deleteStaff && (
        <DeleteModal
          height="260px"
          title="Delete Staff"
          text="You are about to delete this staff. Deleting this staff revokes all access to your account and settings and deletes all staff’s information."
          onClose={() => setDeleteStaff(null)}
          buttonText="Delete"
          cancel={true}
          onConfirm={handleDeleteOnConfirm}
        />
      )}
    </section>
  );
};
