import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {CompanyNameWithLogo} from "../../../../Layout/Shared/NameWithLogo";
import Table from "../../../../Layout/Shared/Table";
import {ViewSwitch} from "../../../../Layout/Shared/ViewSwitch";
import {ClientServiceRating} from "../List/Rating";
import {ServiceListGrid} from "../List/gridList";
import "../List/index.scss";
const services = [];
export const AppliedList = () => {
  const [viewType, setViewType] = useState("list");
  const history = useHistory();
  const goToServiceDetails = () =>
    history.push(`/client/service/applied-service`);
  const GoToDetails = () => {
    return (
      <button onClick={goToServiceDetails} className="btn btn-link text-dark">
      </button>
    );
  };
  const fields = [
    {render: () => <GoToDetails />},
    "description",
    "availability",
    {
      render: () => (
        <CompanyNameWithLogo logo={services[0].logo} name={services[0].name} />
      ),
    },
    {render: () => <ClientServiceRating rating={4} count={125} />},
  ];
  const headers = [
    {
      displayName: "Service Name",
      sortingFieldName: "name",
    },
    {
      displayName: "Description",
      sortingFieldName: "description",
    },
    {
      displayName: "Availability",
      sortingFieldName: "availability",
    },
    {
      displayName: "Company",
      sortingFieldName: "company",
    },
    {
      displayName: "Reviews",
      sortingFieldName: "reviews",
    },
  ];
  const options = {
    data: services,
    headers,
    fields,
    pagination: true,
    search: false,
    sort: true,
  };

  return (
    <div className="client-service-list rounded-xl">
      <div className="header">
        <input
          type="text"
          className="custom-input search w-50"
          placeholder="Search here"
        />
        <ViewSwitch
          grid={() => setViewType("grid")}
          list={() => setViewType("list")}
          active={viewType}
        />
      </div>
      <div className="ps-5 pe-5 ">
        {viewType === "list" ? (
          <Table options={options} />
        ) : (
          <ServiceListGrid services={services} />
        )}
      </div>
    </div>
  );
};
