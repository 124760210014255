import React from 'react'
import {
  StyledContainer,
  StyledInput,
  StyledLabel,
  StyledOptionItemsWrapper,
  StyledText,
} from './styles'
import { StyledComponentTitle, StyledRadiusTextarea } from '../../styles'
import ValidationText from '../../../Builder/Body/Components/Shared/ValidationText'

const RadioButton = ({
  onChange,
  component: { options, validations },
  isReadOnly,
}) => {
  const { value, optionItems } = options
  const inputName = new Date().getTime()

  const handleOnChange = (submissionKey, submissionValue) => {
    const submission = options.submission || {}
    onChange({
      ...options,
      submission: {
        ...submission,
        [submissionKey]: submissionValue,
      },
    })
  }

  return (
    <StyledContainer>
      <StyledComponentTitle>{value}</StyledComponentTitle>
      <StyledOptionItemsWrapper>
        {optionItems.map((optionItem, index) => (
          <StyledLabel key={index}>
            <StyledInput
              key={index}
              type="radio"
              disabled={isReadOnly}
              name={inputName}
              checked={options?.submission?.value === optionItem || false}
              onChange={(e) =>
                e.target.checked && handleOnChange('value', e.target.value)
              }
              value={optionItem}
            />
            <StyledText>{optionItem}</StyledText>
          </StyledLabel>
        ))}
      </StyledOptionItemsWrapper>
      {validations?.value && (
        <ValidationText>{validations?.value}</ValidationText>
      )}
      <StyledRadiusTextarea
        className="form-control"
        rows={2}
        placeholder="Enter your comment"
        readOnly={isReadOnly}
        value={options?.submission?.comment || ''}
        onChange={(e) => handleOnChange('comment', e.target.value)}
      />
    </StyledContainer>
  )
}

export default RadioButton
