import React, { useEffect, useState } from "react";
import { InnerPageLeft } from "../../Layout/Shared/InnerPageLeft";
import { PageDetails } from "../../Layout/Shared/PageDetails";
import { PageStatistics } from "../../Layout/Shared/PageStatistics";
import { InlineText } from "../../Layout/Shared/Paragraph";
import { getClientStatistics } from "../../../data/Client/Business/statistics";
import { ListLoader } from "../../Layout/Shared/DataLoading";
import {
  CategoryIcon12x12,
  AlertIcon12x12,
  HeartIcon12x12,
  ChartIcon12x12
} from "../../../assets/icons/icon";
import { useBusinessClientListContext } from "../../../providers/Client/list";

export const Statistics = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [totals, setTotals] = useState([]);
  const { setters, getters } = useBusinessClientListContext();
  const { setFilter } = setters;
  const { filter } = getters;

  const handleOnClick = statistic => setFilter(statistic.title);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const { hasSuccess, data } = await getClientStatistics();
      setTotals({
        clients: data.numberOfClients,
        groups: data.numberOfGroups
      });
      if (hasSuccess) {
        setStatistics([
          {
            title: "All Clients",
            icon: CategoryIcon12x12,
            count: data.numberOfClients,
            isActive: true
          },
          {
            title: "Recently Added",
            icon: ChartIcon12x12,
            count: data.numberOfRecentlyAdded,
            isActive: true
          },
          {
            title: "Favorites",
            icon: HeartIcon12x12,
            count: data.numberOfFavorites,
            isActive: true
          },
          {
            title: "Pending Invitation",
            icon: AlertIcon12x12,
            count: data.numberOfPendingInvitations,
            isActive: true
          },
          {
            title: "Immigrant Profiles",
            icon: ChartIcon12x12,
            count: data.numberOfImmigrantProfiles,
            isActive: true
          },
          {
            title: "Private Profiles",
            icon: ChartIcon12x12,
            count: data.numberOfPrivateProfiles,
            isActive: true
          }
        ]);
      }
      setIsLoading(false);
    };
    init();
  }, []);
  return (
    <InnerPageLeft>
      {isLoading ? (
        <ListLoader height={"500px"} />
      ) : (
        <>
          <PageDetails title="View by Status">
            <ul className="view-by-status ps-5">
              {statistics.map((item, index) => (
                <li
                  key={index}
                  className={filter && item.title === filter ? "active" : ""}
                  onClick={() => handleOnClick(item)}
                >
                  {item.icon} {item.title}
                  <span>{item.count}</span>
                </li>
              ))}
            </ul>
          </PageDetails>
          <PageStatistics title="CLIENT STATISTICS">
            <InlineText text="No. of Clients" rightValue={totals.clients} />
            <InlineText text="No. of Groups" rightValue={totals.groups} />
          </PageStatistics>
        </>
      )}
    </InnerPageLeft>
  );
};
