import React from "react";
import "./progress.scss";
import {Settings, TickGreen} from "../../../../assets/icons/icon";
import FireWorks from "../../../../assets/images/profileHomeImage.png";
import {useHistory} from "react-router-dom";

const Progress = () => {
  const history = useHistory();
  const goToServiceCreate = () => history.push(`/client/service/list`);
  // const goToContactCreate = () => history.push(`/client/contact/list`);

  return (
    <div className="col-xl-6 col-lg-12">
      <div className="profile-card pt-5 pb-0">
        <div className="title">
          GETTING STARTED
          <small>
            Build a streamlined automated system of managing users and clients
          </small>
          <i className="settings">{Settings}</i>
        </div>
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-8 ">
            <div className="tasks mt-5">
              <div className="status">{TickGreen}</div>
              <div className="task">
                <span>
                  <button onClick={goToServiceCreate}>SELECT SERVICES</button>
                </span>
                <p>Use the form building tool to quickly get data. </p>
              </div>
            </div>
            {/* <div className="tasks mt-1">
              <div className="status">{TickGreen}</div>
              <div className="task">
                <span>
                  <button onClick={goToContactCreate}>CONTACT SERVICES</button>
                </span>
                <p>Manage profiles, invite or import your clients </p>
              </div>
            </div> */}
            {/* <div className="tasks mt-1">
              <div className="status">{TickGreen}</div>
              <div className="task">
                <span>FILL/SUBMIT FORMS</span>
                <p>Create services that your business offers</p>
              </div>
            </div>
            <div className="tasks mt-1">
              <div className="status">{TickGreen}</div>
              <div className="task">
                <span>REVIEW FORM</span>
                <p>Bring your team mates and staff on board</p>
              </div>
            </div>
            <div className="tasks mt-1">
              <div className="status"></div>
              <div className="task">
                <span>SEND MESSAGE</span>
                <p>Co-ordinate meetings and book appointments in one place</p>
              </div>
            </div>
           */}
          </div>
        </div>
        <div className="image image-progres">
          <img src={FireWorks} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Progress;
