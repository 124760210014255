import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "../../Layout/Shared/Table";
import { CreateGroup } from "../Group/Create";
import { Options } from "../../Layout/Shared/DropdownOptionsMenu";
import { ThreeDotIcon } from "../../../assets/icons/icon";
import { CustomButton } from "../../Layout/Shared/Button";
import { DeleteModal } from "../../Layout/Shared/Popups/Delete";
import { ShareModal } from "../../Layout/Shared/Popups/Share";
import { list } from "../../../data/Contact/Business/list";
import DataLoading from "../../Layout/Shared/DataLoading";
import { FormattedDate } from "../../Layout/Shared/FormattedDate";
import { deleteById } from "../../../data/Contact/Business/delete";
import { deleteList } from "../../../data/Contact/Business/deleteList";
import DefaultImage from "../../../assets/images/default-profile-picture.png";
import { exportToCSV } from "../../../utils/export";
import { share } from "../../../data/Contact/Business/share";
import { useBusinessContactContext } from "../../../providers/Contact/business";
import { getOneWeekAgoDate } from "../../../utils/date";

const ContactList = () => {
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [selectedContactForDelete, setSelectedContactForDelete] =
    useState(null);
  const [selectedContactForShare, setSelectedContactForShare] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [showBulkDelete, setShowBulkDelete] = useState(false);
  const { refresh, values } = useBusinessContactContext();
  const { filter } = values;

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const { hasSuccess, data } = await list();
      if (hasSuccess) {
        setContacts(data);
        setFilteredContacts(data);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (!filter) return;
    switch (filter) {
      case "All Contacts":
        setFilteredContacts(contacts);
        break;
      case "Recently Added":
        const oneWeekAgoTime = getOneWeekAgoDate().getTime();
        setFilteredContacts(
          contacts.filter(
            contact => new Date(contact.created_at).getTime() > oneWeekAgoTime
          )
        );
        break;
      case "Favorites":
        setFilteredContacts(contacts.filter(contact => contact.isFavorite));
        break;
      case "Pending Invitation":
        setFilteredContacts(
          contacts.filter(contact => contact.status === "Pending")
        );
        break;

      default:
        setFilteredContacts(contacts);
        break;
    }

    return () => {};
  }, [filter, contacts]);

  const onSubmitShareModal = async (values, { setSubmitting }) => {
    values.emails = values.emails.split(",");
    values.emails = values.emails.map(email => email.trim());
    const { hasSuccess } = await share(selectedContactForShare._id, values);
    if (hasSuccess) {
      setShowShare(false);
      setSubmitting(false);
      setSelectedContactForShare(null);
      setInfoMessage("The contact shared successfully.");
    }
  };

  const handleDelete = async () => {
    if (!selectedContactForDelete) return;
    setIsLoading(true);
    const { hasSuccess } = await deleteById(selectedContactForDelete._id);
    hasSuccess && refresh();
  };

  const handleBulkDelete = async () => {
    if (!filteredContacts.some(contact => contact.isSelected)) return;
    setIsLoading(true);
    const { hasSuccess } = await deleteList(
      filteredContacts
        .filter(contact => contact.isSelected)
        .map(contact => contact._id)
    );
    hasSuccess && refresh();
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setInfoMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [infoMessage]);

  const handleExport = async row => exportToCSV([row]);

  const handleBulkExport = async () => {
    if (!filteredContacts.some(contact => contact.isSelected)) return;
    const exportedContacts = filteredContacts.filter(
      contact => contact.isSelected
    );
    exportToCSV(exportedContacts);
  };

  const ActionsComponent = () => (
    <div className="d-flex justify-content-end align-items-center">
      <CustomButton
        color="transparent"
        title="Create Group"
        size="140px"
        onClick={() => setShowCreateGroup(true)}
      />
      <Options
        arrow={true}
        buttonTitle="Options"
        menuItems={
          <div>
            <button
              className="text-danger"
              onClick={() => setShowBulkDelete(true)}
            >
              Delete
            </button>
            <button onClick={handleBulkExport}>Export</button>
          </div>
        }
      />
    </div>
  );

  const Name = ({ row }) => (
    <div className="avatar-in-table">
      <div className="member">
        <div>
          <img src={row.photoUrl ?? DefaultImage} alt="" />
        </div>
      </div>
      <span>{`${row.firstName} ${row.lastName}`}</span>
    </div>
  );

  const CheckBox = ({ row }) => (
    <input
      type="checkbox"
      checked={row.isSelected}
      onChange={() => toggleChecked(row._id)}
    />
  );

  const toggleChecked = id =>
    setFilteredContacts(
      filteredContacts.map(item => {
        return {
          ...item,
          isSelected: item._id === id ? !item.isSelected : item.isSelected
        };
      })
    );

  const ActionButtons = ({ row }) => {
    const history = useHistory();
    const goToContactDetails = () =>
      history.push(`/business/contact/${row._id}`);
    return (
      <Options
        arrow={false}
        buttonTitle={ThreeDotIcon}
        menuItems={
          <div>
            <button onClick={goToContactDetails}>View/Edit</button>
            {row.status === "Accepted" && <button>Add to Group</button>}
            <button
              onClick={() => {
                setSelectedContactForShare(row);
                setShowShare(true);
              }}
            >
              Share
            </button>
            <button onClick={() => handleExport(row)}>Export</button>
            <button
              className="text-danger"
              onClick={() => {
                setSelectedContactForDelete(row);
                setShowDelete(true);
              }}
            >
              Delete
            </button>
          </div>
        }
      />
    );
  };

  const fields = [
    { render: CheckBox },
    { render: Name },
    "city",
    "email",
    "phone",
    { render: ({ row }) => <FormattedDate date={row.created_at} /> },
    "status",
    { render: ActionButtons }
  ];
  const headers = [
    {
      displayName: "",
      sortingFieldName: "isSelected"
    },
    {
      displayName: "Name",
      sortingFieldName: "name"
    },
    {
      displayName: "Location",
      sortingFieldName: "city"
    },
    {
      displayName: "Email",
      sortingFieldName: "email"
    },
    {
      displayName: "Phone",
      sortingFieldName: "phone"
    },
    {
      displayName: "Date Created",
      sortingFieldName: "created_at",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Status",
      sortingFieldName: "status"
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];
  const options = {
    data: filteredContacts,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    headerAction: ActionsComponent
  };

  return (
    <div className="ps-5">
      {isLoading ? <DataLoading height="700px" /> : <Table options={options} />}
      {showCreateGroup && (
        <CreateGroup onClose={() => setShowCreateGroup(false)} />
      )}
      {showDelete && (
        <DeleteModal
          height="260px"
          title="Delete Contact"
          text=" You are about to delete this client from your contact list. Deleting
          this client removes all client’s information and ends all previous
          communication between you and the client. 
          "
          buttonText="Delete"
          onClose={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
      {showBulkDelete && (
        <DeleteModal
          height="260px"
          title="Delete Contact(s)"
          text=" You are about to delete selected client(s) from your contact list. Deleting
          these client(s) removes all client’s information and ends all previous
          communication between you and the client. 
          "
          buttonText="Delete"
          onClose={() => setShowBulkDelete(false)}
          onConfirm={handleBulkDelete}
        />
      )}
      {showShare && (
        <ShareModal
          title="Share Contact"
          buttonText="Share"
          onClose={() => setShowShare(false)}
          onConfirm={onSubmitShareModal}
          onExport={() => {
            selectedContactForShare && handleExport(selectedContactForShare);
          }}
        />
      )}
      {infoMessage && (
        <div className="notifications">
          <div className="info">
            <span>Info</span>
            <p>{infoMessage}</p>
            <button onClick={() => setInfoMessage(null)}>&times;</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactList;
