import React from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import {
  BaseEditFrame,
  BaseEditFrameConfirmed,
  BaseEditTitle,
  DownIcon,
  UpIcon,
} from "./BaseStyles";
import {MoveDirection} from "../../FormEdit/FormEditReducerTypes";

export function calculateColumnWidth(column_size) {
  const numbers = column_size.split("/");
  return 12 / numbers[1];
}

export function Base({
  title,
  component,
  icons,
  confirmed,
  dispatch,
  component_key,
  hover,
  column_size,
}) {
  let bootstrapColumnWidth = calculateColumnWidth(column_size);
  return confirmed === "true" ? (
    <div className={"col-md-" + bootstrapColumnWidth}>
      <BaseEditFrameConfirmed
        onMouseEnter={(event) => {
          event.preventDefault();
          dispatch({
            type: "base_hover",
            key: component_key,
            value: "true",
          });
        }}
        onMouseLeave={(event) => {
          event.preventDefault();
          dispatch({
            type: "base_hover",
            key: component_key,
            value: "false",
          });
        }}
      >
        <div className={"row"}>
          <div className={"col-1"}>
            {hover === "true" ? (
              <div style={{position: "relative"}}>
                <UpIcon
                  onClick={() => {
                    dispatch({
                      type: "move_component",
                      value: MoveDirection.UP,
                      key: component_key,
                    });
                  }}
                  className={"bi bi-chevron-up"}
                ></UpIcon>
                <DownIcon
                  onClick={() => {
                    dispatch({
                      type: "move_component",
                      value: MoveDirection.DOWN,
                      key: component_key,
                    });
                  }}
                  className={"bi bi-chevron-down"}
                ></DownIcon>
              </div>
            ) : null}
          </div>
          {hover === "true" ? (
            <div className={"col-11 justify-content-end d-flex"}>{icons}</div>
          ) : null}
        </div>
        <div className={"row"}>
          <div className={"col-12"}>{component}</div>
        </div>
      </BaseEditFrameConfirmed>
    </div>
  ) : (
    <BaseEditFrame className={"col-md-" + bootstrapColumnWidth}>
      <div className={"row mb-2"}>
        <div className={"col-6 my-auto"}>
          <div style={{position: "relative"}}>
            <UpIcon
              onClick={() => {
                dispatch({
                  type: "move_component",
                  value: MoveDirection.UP,
                  key: component_key,
                });
              }}
              className={"bi bi-chevron-up"}
            ></UpIcon>
            <DownIcon
              onClick={() => {
                dispatch({
                  type: "move_component",
                  value: MoveDirection.DOWN,
                  key: component_key,
                });
              }}
              className={"bi bi-chevron-down"}
            ></DownIcon>
          </div>
          <BaseEditTitle>{title}</BaseEditTitle>
        </div>
        <div className={"col-6 text-end"}>{icons}</div>
      </div>
      <div className={"row"}>
        <div className={"col-md-12"}>{component}</div>
      </div>
    </BaseEditFrame>
  );
}

export default Base;
