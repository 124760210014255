import {hostName} from "../endpoint";
import {defaultHeaders} from "../headers";

export const verifyEmail = async (params) => {
  const result = await fetch(`${hostName}/verify-email`, {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify({...params}),
  });
  return result.json();
};
