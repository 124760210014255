import styled, { css } from 'styled-components'
import * as theme from '../../../../styles/theme'

export const StyledContainer = styled.div`
  padding: 0.5rem;
`
export const StyledList = styled.ul`
  display: flex;
  align-items: center;
  gap: 0.5em;
`
export const StyledListItem = styled.li``
export const StyledListItemButton = styled.button`
  background-color: #fff;
  border: 1px solid #dee2e6 !important;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isCurrent }) =>
    isCurrent &&
    css`
      border: 1px solid ${theme.azul} !important;
      color: ${theme.azul} !important;
    `}
`
