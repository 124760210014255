import * as Yup from "yup";
import {PASSWORD_REGEX} from "../../constants/core.js";

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, "password is too short")
    .max(50, "password is too long")
    .matches(
      PASSWORD_REGEX,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .min(6, "Confirm password is too short!")
    .max(50, "Confirm password is too long!")
    .matches(
      PASSWORD_REGEX,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Confirm password is required"),
});
