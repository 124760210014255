import React, { Fragment } from "react";
import { DEFAULT_SELECTION_VALUE } from "../../../../utils/string";
import { SERVICE_TYPES } from "../../../../constants/services";

export const ServiceTypeSelect = ({ type, onChange }) => {
  return (
    <select
      className="primary-select"
      placeholder="Select Service Type"
      value={type}
      onChange={({ target }) => onChange(target.value)}
    >
      <option value={DEFAULT_SELECTION_VALUE}>Select Service Type</option>
      {SERVICE_TYPES.map((option, index) => (
        <Fragment key={index}>
          <option value={option.type}>{option.name}</option>
        </Fragment>
      ))}
    </select>
  );
};
