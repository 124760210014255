import React, {Fragment} from "react";
import {ComponentUl, SmallNavButton} from "../../FormEdit/FormEditStyles";
import {ComponentTabsHeaderStyle} from "../Base/BaseStyles";

export function ComponentTabsHeader({component_key}) {
  return (
    <Fragment>
      <ComponentTabsHeaderStyle>
        <ComponentUl
          className="nav nav-tabs"
          id={component_key + "Tab"}
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <SmallNavButton
              className="nav-link active"
              id={component_key + "edit-tab"}
              data-bs-toggle="tab"
              data-bs-target={"#" + component_key + "edit"}
              type="button"
              role="tab"
              aria-controls="edit"
              aria-selected="true"
            >
              EDIT
            </SmallNavButton>
          </li>
          <li className="nav-item" role="presentation">
            <SmallNavButton
              className="nav-link"
              id={component_key + "options-tab"}
              data-bs-toggle="tab"
              data-bs-target={"#" + component_key + "options"}
              type="button"
              role="tab"
              aria-controls="options"
              aria-selected="true"
            >
              OPTIONS
            </SmallNavButton>
          </li>
        </ComponentUl>
      </ComponentTabsHeaderStyle>
    </Fragment>
  );
}

export default ComponentTabsHeader;
