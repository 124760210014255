import ESignatureAssigneesOptions from "./ESignatureAssigneesOptions";

export function ESignatureOptionsTab({component_key, attributes, dispatch}) {
  return (
    <ESignatureAssigneesOptions
      dispatch={dispatch}
      attributes={attributes}
      component_key={component_key}
    />
  );
}

export default ESignatureOptionsTab;
