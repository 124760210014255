/* eslint-disable array-callback-return */
import Label from "../Components/Label/Label";
import InputText from "../Components/InputText/InputText";
import InputNumber from "../Components/InputNumber/InputNumber";
import Checkbox from "../Components/Checkbox/Checkbox";
import RadioButton from "../Components/RadioButton/RadioButton";

import Textarea from "../Components/Textarea/Textarea";
import {EditorValue} from "react-rte";
import {getTextAlignStyles} from "react-rte/lib/lib/blockStyleFunctions";
import PageBreak from "../Components/PageBreak/PageBreak";
import ESignature from "../Components/ESignature/ESignature";
import Heading from "../Components/Heading/Heading";
import {initialFormBuilderState} from "./FormEditReducerState";

export function FormBuilderStateFromJSON(json) {
  if (json) {
    let state = null;
    if (typeof json === "string" || json instanceof String) {
      state = JSON.parse(json);
    } else {
      state = json;
    }

    const components = state.components?.map((component) => {
      const attributes = component?.attributes?.map((attribute) => {
        return {
          key: attribute.key,
          value: attribute.value,
          editorValue: attribute.editorValue,
        };
      });
      const newComponent = {
        key: component?.key,
        name: component?.name,
        title: component?.title,
        order: component?.order,
        type: component?.type,
        items: component?.items,
        styles: component?.styles,
        valid: component?.valid,
        attributes: attributes,
      };
      return newComponent;
    });
    const new_state = {
      validation: state.validation,
      formSaved: state.formSaved,
      formValid: state.formValid,
      form_header: {
        title: state.form_header?.title,
        placeholder: state.form_header?.placeholder,
        valid: state.form_header?.valid,
      },
      components: components,
    };
    return new_state;
  }
  return initialFormBuilderState;
}

export const editorValueToString = (state) => {
  state.components.map((component) => {
    if (component.type === ComponentType.TEXTAREA) {
      component.attributes.map((attribute) => {
        if (attribute.key === "title") {
          if (attribute.editorValue instanceof EditorValue) {
            attribute.editorValue = attribute.editorValue.toString("html", {
              blockStyleFn: getTextAlignStyles,
            });
          }
        }
      });
    }
  });
  return state;
};

export const saveState = (state) => {
  let form_states = JSON.parse(localStorage.getItem("form_states"));
  if (form_states == null) form_states = [];
  form_states.push(JSON.stringify(editorValueToString(state)));
  localStorage.setItem("form_states", JSON.stringify(form_states));
};

export const ComponentType = {
  HEADING: "Heading",
  LABEL: "Label",
  INPUT_TEXT: "InputText",
  INPUT_NUMBER: "InputNumber",
  CHECKBOX: "Checkbox",
  RADIOBUTTON: "RadioButton",
  TEXTAREA: "Textarea",
  PAGE_BREAK: "PageBreak",
  ESIGNATURE: "ESignature",
};

export const ToolbarLook = {
  HORIZONTAL: "horizontal",
  TILE: "tile",
};

export const MoveDirection = {
  UP: "up",
  DOWN: "down",
};

export const FormComponentTypes = {
  [ComponentType.HEADING]: Heading,
  [ComponentType.LABEL]: Label,
  [ComponentType.INPUT_TEXT]: InputText,
  [ComponentType.INPUT_NUMBER]: InputNumber,
  [ComponentType.CHECKBOX]: Checkbox,
  [ComponentType.RADIOBUTTON]: RadioButton,
  [ComponentType.TEXTAREA]: Textarea,
  [ComponentType.PAGE_BREAK]: PageBreak,
  [ComponentType.ESIGNATURE]: ESignature,
};
