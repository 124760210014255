import "./index.scss";

export const PaymentCard = ({icon, title, isDefault, subTitle}) => {
  return (
    <div className={isDefault ? "payment-card-box active" : "payment-card-box"}>
      <div>
        <img src={icon} alt={title} />
      </div>
      <div>
        {title} <small>{subTitle}</small>
      </div>
    </div>
  );
};
