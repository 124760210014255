export const isEmptyString = value => value === "";

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const DEFAULT_SELECTION_VALUE = "DEFAULT_SELECTION_VALUE";
export const isDefaultSelection = value => value === DEFAULT_SELECTION_VALUE;

export const getFullNameFromObject = (object = {}, separator = " ") => {
  const { firstName = "", lastName = "" } = object;
  const fullName = [firstName, lastName].filter(Boolean).join(separator);
  return fullName || "N/A";
};

export const fullNameFormatter = model => ({
  ...model,
  fullName: getFullNameFromObject(model)
});
