import "bootstrap/dist/js/bootstrap.min.js";
import React from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {Label} from "../Base/BaseStyles";

export function LabelHover({attributes}) {
  const label = getAttribute("title", attributes);
  return (
    <div className={"row"}>
      <div className={"col-12"}>
        <Label>{label}</Label>
      </div>
    </div>
  );
}

export default LabelHover;
