import Modal from "../index";
import {InfoIcon128} from "../../../../../assets/icons/icon";

export const GlobalInfo = ({heading, message, onClose, showCloseButton}) => {
  return (
    <Modal title={heading} width="610px" height="430px" close={() => onClose()}>
      <div className="d-flex align-items-center flex-column">
        <center>
          <div>{InfoIcon128}</div>
          <span className="h1 mt-5 text-primary-blue"></span>
          <p className="mt-5">{message}</p>
          {showCloseButton}
        </center>
      </div>
    </Modal>
  );
};
