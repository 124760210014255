import {Fragment} from "react";
import ComponentTabsContent from "../ComponentTabs/ComponentTabsContent";
import ComponentTabsHeader from "../ComponentTabs/ComponentTabsHeader";
import RadioButtonEditTab from "./RadioButtonEditTab";
import RadioButtonOptionsTab from "./RadioButtonOptionsTab";

export function RadioButtonEdit({dispatch, attributes, component_key}) {
  return (
    <Fragment>
      <ComponentTabsHeader
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <ComponentTabsContent
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
        EditTab={RadioButtonEditTab}
        OptionTab={RadioButtonOptionsTab}
      />
    </Fragment>
  );
}

export default RadioButtonEdit;
