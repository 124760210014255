import "bootstrap/dist/js/bootstrap.min.js";
import React, {Fragment} from "react";
import {PageBreakLine} from "../Base/BaseStyles";

export function PageBreakEdit({attributes, dispatch, component_key}) {
  return (
    <Fragment>
      <PageBreakLine>
        <hr />
      </PageBreakLine>
    </Fragment>
  );
}

export default PageBreakEdit;
