import * as Yup from "yup";

export const noExtraProperties = (message, validKeys) => {
  return {
    name: "no-extra-properties",
    test: function (value) {
      if (value == null) return true;

      const invalidKeys = Object.keys(value).filter(
        key => !validKeys.includes(key)
      );
      if (invalidKeys.length > 0) {
        return this.createError({
          path: this.path,
          message: `${message}: ${invalidKeys.join(", ")}`
        });
      }
      return true;
    }
  };
};

export const idSchema = Yup.string().required("ID is required");

export const yourInformationSchema = Yup.object()
  .shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phone: Yup.string().required("Phone is required"),
    profilePictureUrl: Yup.string().url("Invalid URL"),
    gender: Yup.string(),
    dateOfBirth: Yup.number().integer("Invalid Date of Birth"),
    countryOfOrigin: Yup.string()
  })
  .test(
    "no-extra-properties",
    "Extra properties are not allowed in your information",
    value => {
      const validKeys = [
        "firstName",
        "lastName",
        "phone",
        "profilePictureUrl",
        "gender",
        "dateOfBirth",
        "countryOfOrigin"
      ];
      return Object.keys(value || {}).every(key => validKeys.includes(key));
    }
  );

export const addressSchema = Yup.object()
  .shape({
    address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Zip Code is required")
  })
  .test(
    "no-extra-properties",
    "Extra properties are not allowed in address",
    value => {
      const validKeys = ["address", "country", "city", "state", "zipCode"];
      return Object.keys(value || {}).every(key => validKeys.includes(key));
    }
  );

export const industriesSchema = Yup.array().of(Yup.string());

export const favoriteServicesSchema = Yup.array().of(Yup.string());

export const immigrationStatusSchema = Yup.object()
  .shape({
    status: Yup.string(),
    settlementStatus: Yup.string(),
    dateOfLanding: Yup.number().integer("Invalid Date of Landing")
  })
  .test(
    "no-extra-properties",
    "Extra properties are not allowed in immigration",
    value => {
      const validKeys = ["status", "settlementStatus", "dateOfLanding"];
      return Object.keys(value || {}).every(key => validKeys.includes(key));
    }
  );

export const immigrationSchema = Yup.object()
  .shape({
    status: immigrationStatusSchema,
    number: Yup.string(),
    arrivalStatus: Yup.string()
  })
  .test(
    "no-extra-properties",
    "Extra properties are not allowed in immigration",
    value => {
      const validKeys = ["status", "number", "arrivalStatus"];
      return Object.keys(value || {}).every(key => validKeys.includes(key));
    }
  );

export const otherSchema = Yup.object()
  .shape({
    educationStatus: Yup.string(),
    educationStatusOther: Yup.string(),
    employmentStatus: Yup.string()
  })
  .test(
    "no-extra-properties",
    "Extra properties are not allowed in other",
    value => {
      const validKeys = [
        "educationStatus",
        "educationStatusOther",
        "employmentStatus"
      ];
      return Object.keys(value || {}).every(key => validKeys.includes(key));
    }
  );

export const detailsSchema = Yup.object()
  .shape({
    yourInformation: yourInformationSchema,
    address: addressSchema,
    industries: industriesSchema,
    favoriteServices: favoriteServicesSchema,
    immigration: immigrationSchema,
    other: otherSchema
  })
  .test(
    "no-extra-properties",
    "Extra properties are not allowed in details",
    value => {
      const validKeys = [
        "yourInformation",
        "address",
        "industries",
        "favoriteServices",
        "immigration",
        "other"
      ];
      return Object.keys(value || {}).every(key => validKeys.includes(key));
    }
  );

export const BusinessClientUpdateSchema = Yup.object()
  .shape({
    id: idSchema,
    details: detailsSchema
  })
  .test(
    noExtraProperties("Extra properties are not allowed in client", [
      "id",
      "details"
    ])
  );
