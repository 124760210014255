import React, {useState} from "react";
import {Formik, Form, Field} from "formik";
import ErrorField from "../../components/Layout/Shared/ErrorField";
import {EmailSchema} from "../../schemas/Shared/email";
import {sendEmail} from "../../data/ForgotPassword/sendBusinessEmail";
import {sendStaffEmail} from "../../data/ForgotPassword/sendStaffEmail";
import {useHistory} from "react-router-dom";

export const BusinessForm = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const initialValues = {email: ""};

  const goToSuccesPage = (email) => {
    history.push("/password-email-sent", {email: email});
  };
  const onSubmit = async ({ email }, _) => {
    const isSuccessful = (await sendStaffEmail({ email })) || (await sendEmail({ email }))
    isSuccessful ? goToSuccesPage(email) : setError('Something went wrong, please check your email and try again')
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EmailSchema}
      onSubmit={onSubmit}
    >
      {({errors}) => (
        <Form>
          <div className="form-group">
            <Field
              type="email"
              className="custom-input"
              placeholder="Business Email Address"
              name="email"
            />
            <ErrorField message={errors.email} />
          </div>
          {error && <ErrorField message={error} />}
          <button type="submit" className="btn btn-blue-gradient">
            Next
          </button>
        </Form>
      )}
    </Formik>
  );
};