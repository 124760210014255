import React from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {InputGroup, RadiusInput} from "../Base/BaseStyles";

export function BaselineOption({component_key, dispatch, attributes}) {
  const baseline = getAttribute("baseline", attributes);
  return (
    <InputGroup className="input-group mt-3">
      <RadiusInput
        type="number"
        className="form-control"
        aria-label=""
        placeholder="Enter baseline"
        value={baseline}
        onChange={(event) =>
          dispatch({
            type: "baseline_change",
            baseline: event.target.value,
            key: component_key,
          })
        }
      />
    </InputGroup>
  );
}

export default BaselineOption;
