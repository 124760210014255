import { appointmentApiHostName } from "../../endpoint";
import { defaultHeaders } from "../../headers";
import moment from "moment";
import { getOnlyTime, isPast, getTime } from "../../../utils/date";

export const getAppointmentList = async () => {
  try {
    const result = await fetch(`${appointmentApiHostName}`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

const getAppointmentStatus = ({ isCancelled, isMissed }, isPastEvent) => {
  if (isCancelled) return "Cancelled";
  if (isMissed) return "Missed";
  if (!isMissed && isPastEvent) return "Completed";
  return "Booked";
};

export const MapToEvents = appointments => {
  return appointments.map(appointment => {
    const isPastEvent = isPast(appointment.date);
    const getTitle = ({ firstName, lastName, date, source }) =>
      source && source === "web"
        ? `${firstName} ${lastName} ${getTime(date)}`
        : `${firstName} ${lastName} ${getOnlyTime(date)}`;
    return {
      ...appointment,
      id: appointment._id,
      start: moment(appointment.date).toDate(),
      end: moment(appointment.date).add(1, "hours").toDate(),
      title: getTitle(appointment),
      isCompleted: !appointment.isMissed && isPastEvent,
      isInProgress:
        !appointment.isMissed && !appointment.isCancelled && !isPastEvent,
      status: getAppointmentStatus(appointment, isPastEvent)
    };
  });
};
