import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getTemplateCategories } from '../../../../data/Forms/getTemplateCategories'
import { LabelBlueBold } from '../../../Layout/Shared/LabelBlueBold'
import Modal from '../../../Layout/Shared/Modal'
import { FormTypes, FormVisibilityOptions } from '../../constants'
import {
  StyledCreateButton,
  StyledFieldWrapper,
  StyledOption,
  StyledSelect,
  StyledLoader,
} from './styles'

const formTypeOptions = Object.keys(FormTypes).map((key) => ({
  label: key,
  value: FormTypes[key],
}))

const visibilityOptions = [
  { label: 'Only me', value: FormVisibilityOptions.Me },
  { label: 'My Organisation', value: FormVisibilityOptions.Organisation },
  { label: 'Everyone (Template)', value: FormVisibilityOptions.Template },
]

const CreateModal = ({ close, extraParameters = [] }) => {
  const history = useHistory()
  const [type, setType] = useState(formTypeOptions[0].value)
  const [visibility, setVisibility] = useState(visibilityOptions[0].value)
  const [categoryId, setCategoryId] = useState('')
  const [categories, setCategories] = useState([])
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false)
  const isTemplate = visibility === FormVisibilityOptions.Template

  const handleOnCreate = () => {
    const parameters = [
      {
        key: 'type',
        value: type,
      },
      {
        key: 'visibility',
        value: visibility,
      },
      {
        key: 'categoryId',
        value: categoryId,
      },
      ...extraParameters,
    ]

    const queryString = parameters
      .filter((param) => param.value.length > 0)
      .map((param) => `${param.key}=${param.value}`)
      .join('&')

    const link = `/business/forms/builder?${queryString}`
    history.push(link)
  }

  useEffect(() => {
    setCategoryId('')
    setCategories([])
    if (visibility === FormVisibilityOptions.Template) {
      setIsCategoriesLoading(true)
      getTemplateCategories().then((response) => {
        setIsCategoriesLoading(false)
        if (response.hasSuccess) {
          const categories = response.data.data
          setCategories(categories)
          setCategoryId(categories[0].id)
        }
      })
    }
  }, [visibility])

  return (
    <Modal title="Create a New Form" close={close}>
      <StyledFieldWrapper>
        <LabelBlueBold content="Please select the form type" />
        <StyledSelect
          value={type}
          onChange={(e) => setType(e.currentTarget.value)}
          className="primary-select bg-transparent"
        >
          {formTypeOptions.map((type, index) => (
            <StyledOption key={index} value={type.value}>
              {type.label}
            </StyledOption>
          ))}
        </StyledSelect>
      </StyledFieldWrapper>
      <StyledFieldWrapper>
        <LabelBlueBold content="Who can see this form?" />
        <StyledSelect
          value={visibility}
          onChange={(e) => setVisibility(e.currentTarget.value)}
          className="primary-select bg-transparent"
        >
          {visibilityOptions.map((type, index) => (
            <StyledOption key={index} value={type.value}>
              {type.label}
            </StyledOption>
          ))}
        </StyledSelect>
      </StyledFieldWrapper>
      {isTemplate && (
        <StyledFieldWrapper>
          <LabelBlueBold content="Template Category" />
          {isCategoriesLoading ? (
            <StyledLoader />
          ) : (
            <StyledSelect
              value={categoryId}
              onChange={(e) => setCategoryId(e.currentTarget.value)}
              className="primary-select bg-transparent"
            >
              {categories.map((category) => (
                <StyledOption key={category.id} value={category.id}>
                  {category.title}
                </StyledOption>
              ))}
            </StyledSelect>
          )}
        </StyledFieldWrapper>
      )}
      <StyledFieldWrapper>
        <StyledCreateButton
          onClick={handleOnCreate}
          className="btn-blue-gradient btn-small"
        >
          Create
        </StyledCreateButton>
      </StyledFieldWrapper>
    </Modal>
  )
}

export default CreateModal
