import styled from "styled-components";

export const azul = "#1b84e7";
export const azul_opacity = "rgba(27,132,231,0.05)";
export const placeholderGray = "#dee2e6";
export const defaultFont = "Open Sans";
export const spacingL = "6rem";
export const spacingXL = "8rem";
export const spacingXS = "2rem";

export const Icon = styled.i`
  font-size: 2em;
`;

export const IradarInput = styled.input`
  padding: 1rem 2rem 1rem 1.75rem;
  width: 100%;
  height: 32px;
  border-radius: 26px;
  box-shadow: 0 5px 15px 0 rgba(167, 190, 255, 0.1),
    0 5px 15px 0 rgba(167, 190, 255, 0.2) inset;
  border: solid 1px ${placeholderGray};
  &.search {
    background-image: url(../../../assets/icons/icon-search-blue-23x23.png);
    background-repeat: no-repeat;
    background-position: ${spacingXS} center;
    padding-left: ${spacingL};
    color: ${azul};
    &::placeholder {
      color: ${azul};
    }
    &::-moz-placeholder {
      color: ${azul};
    }
  }
  &.phone {
    background-image: url(../../../assets/images/flag_CA.png);
    background-repeat: no-repeat;
    background-position: 5% center;
    padding-left: ${spacingXL};
  }
  &.icon-success {
    background-image: url(../../../assets/icons/icon-green-tick-small.png);
    background-repeat: no-repeat;
    background-position: 95% center;
  }
  &.icon-warning {
    background-image: url(../../../assets/icons/icon-warning-small.png);
    background-repeat: no-repeat;
    background-position: 95% center;
  }
  &.icon-danger {
    background-image: url(../../../assets/icons/icon-danger-small.png);
    background-repeat: no-repeat;
    background-position: 95% center;
  }
`;
