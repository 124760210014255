import { FormComponentTypes } from '../constants'
import checkboxValidator from './Components/Checkbox/validator'
import radioButtonValidator from './Components/RadioButton/validator'
import inputNumberValidator from './Components/InputNumber/validator'
import inputTextValidator from './Components/InputText/validator'
import eSignatureValidator from './Components/ESignature/validator'

const componentValidators = {
  [FormComponentTypes.Checkbox]: checkboxValidator,
  [FormComponentTypes.RadioButton]: radioButtonValidator,
  [FormComponentTypes.InputNumber]: inputNumberValidator,
  [FormComponentTypes.InputText]: inputTextValidator,
  [FormComponentTypes.ESignature]: eSignatureValidator,
}

export const validateComponents = (components) => {
  components.forEach((component) => {
    const validator = componentValidators[component.type]
    if (validator) {
      validator(component)
    } else {
      component.isValid = true
    }
  })
  const hasInvalidComponent = components.some((component) => !component.isValid)
  return !hasInvalidComponent
}
