import React, {useState} from "react";
import moment from "moment";
import {Calendar, momentLocalizer} from "react-big-calendar";
import {CustomEvent} from "./Events/CustomEvent";
import {EventWrapper} from "./Events/EventWrapper";
import {CreateAppointment} from "./Appointment/Create";
import {AppointmentDetails} from "./Appointment/Details";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.scss";

const localizer = momentLocalizer(moment);

const BusinessCalendar = ({events, refreshCalendar}) => {
  const [appointmentDetail, setAppointmentDetail] = useState({});
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showAppointmentDetail, setShowAppointmentDetail] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const createNewAppointment = ({start}) => {
    setStartDate(start);
    setShowCreateForm(true);
  };

  const handleEventClick = (event) => {
    setAppointmentDetail(event);
    setShowAppointmentDetail(true);
  };

  return (
    <>
      {showCreateForm && (
        <CreateAppointment
          date={startDate}
          close={() => setShowCreateForm(false)}
          onSuccess={refreshCalendar}
        />
      )}
      {showAppointmentDetail && (
        <AppointmentDetails
          appointment={appointmentDetail}
          close={() => setShowAppointmentDetail(false)}
          onSuccess={refreshCalendar}
        />
      )}
      <Calendar
        selectable
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectSlot={createNewAppointment}
        components={{
          eventWrapper: EventWrapper,
          event: ({event}) => (
            <CustomEvent
              event={event}
              onClick={() => handleEventClick(event)}
            />
          ),
        }}
      />
    </>
  );
};

export default BusinessCalendar;
