import React from 'react'
import { PageContainer } from '../../../components/Layout/Container/PageContainer'
import Header from '../../../components/Layout/Header/Header'
import { GenericPageLeft } from '../../../components/Layout/Shared/GenericPageLeft'
import { PageHeader } from '../../../components/Layout/Shared/PageHeader'
import { PageTitle } from '../../../components/Layout/Shared/PageTitle'
import 'bootstrap-icons/font/bootstrap-icons.css'
import FormBuilder from '../../../components/Form/Builder'
// import FormBuilder from "../../../components/FormBuilder/FormBuilder/FormBuilder";

const FormBuilderPage = () => (
  <section className="dashboard  bg-paleGray pb-5 pe-3">
    <Header />
    <div className="container-fluid mt-5 pt-3">
      <div className="row">
        <GenericPageLeft />
        <PageContainer>
          <PageHeader>
            <PageTitle>FORM BUILDER</PageTitle>
          </PageHeader>
          <FormBuilder />
        </PageContainer>
      </div>
    </div>
  </section>
)

export default FormBuilderPage
