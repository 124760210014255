import * as Yup from "yup";

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

export const validationSchema = Yup.object().shape({
  contacts: Yup.array()
    .of(
      Yup.object().shape(
        {
          firstName: Yup.string()
            .min(3, "First Name is too Short!")
            .max(30, "First Name is too Long!")
            .notOneOf([""], "First Name is required!")
            .required("First Name is required!"),
          lastName: Yup.string()
            .min(3, "Last Name is too Short!")
            .max(30, "Last Name is too Long!")
            .notOneOf([""], "Last Name is required!")
            .required("Last Name is required"),
          designation: Yup.string(),
          email: Yup.string()
            .email("Invalid email!")
            .required("Email is required!"),
          phone: Yup.string()
            .notOneOf([""], "Phone is required!")
            .required("Phone is required!"),
          address: Yup.string(),
          city: Yup.string(),
          state: Yup.string(),
          country: Yup.string(),
          zipCode: Yup.string(),
        },
        []
      )
    )
    .unique("Each user should have a unique email address!", (a) => a.email)
    .required("Must have user!")
    .min(1, "Minimum of 1 user!"),
});
