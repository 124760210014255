import React, {useState, useEffect} from "react";
import {InnerPageLeft} from "../../../../components/Layout/Shared/InnerPageLeft";
import {PageDetails} from "../../../../components/Layout/Shared/PageDetails";
import {PageStatistics} from "../../../../components/Layout/Shared/PageStatistics";
import {InlineText} from "../../../../components/Layout/Shared/Paragraph";
import {LabelBlueBold} from "../../../../components/Layout/Shared/LabelBlueBold";
import {useParams} from "react-router";
import {getById} from "../../../../data/Contact/Business/Group/getById";
import {update} from "../../../../data/Contact/Business/Group/update";
import DataLoading from "../../../Layout/Shared/DataLoading";
import {CustomButton} from "../../../Layout/Shared/Button";
import GroupMemberList from "../MemberList";
import {ExportContact} from "../../../../components/Contacts/ExportContact";
import ErrorField from "../../../Layout/Shared/ErrorField";
import {Formik, Form, Field} from "formik";
import {GroupEditSchema} from "../../../../schemas/Contact/Business/groupUpdate";

export const EditGroup = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {groupId} = useParams();
  const [group, setGroup] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const init = async () => {
      const {data, hasSuccess} = await getById(groupId);
      if (hasSuccess) {
        setGroup(data);
      }
      setIsLoading(false);
    };
    groupId ? init() : setIsLoading(false);
  }, [groupId]);

  const handleOnSubmit = async (values) => {
    setIsLoading(true);
    const {hasSuccess, data} = await update({
      id: groupId,
      name: values.name,
      description: values.description,
    });
    hasSuccess && setMessage("Group saved successfully.");
    setGroup(data);
    setIsLoading(false);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [message]);

  return isLoading ? (
    <DataLoading height="700px" />
  ) : (
    <>
      <div className="row m-2  bg-white border rounded-xl overflow-hidden">
        <div className="col-xl-4 col-lg-12 p-0 border-end">
          <InnerPageLeft>
            <PageDetails title="GROUP DETAILS">
              <Formik
                initialValues={group}
                validationSchema={GroupEditSchema}
                onSubmit={handleOnSubmit}
              >
                {({errors}) => (
                  <Form>
                    <div className="p-5 m-3 form-group">
                      <LabelBlueBold content=" Group Name" />
                      <Field
                        type="text"
                        className="custom-input mb-3"
                        placeholder="Group Name"
                        name="name"
                      />
                      <LabelBlueBold content="Group Description" />
                      <Field
                        width="100%"
                        height="153px"
                        as="textarea"
                        className="custom-textarea"
                        placeholder="Add Description"
                        name="description"
                      />
                      <ErrorField message={errors.name} />
                      <ErrorField message={errors.description} />
                      <div className="d-flex justify-content-end mt-5">
                        <CustomButton title="Save" color="solid-blue" />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </PageDetails>
            <PageStatistics text="GROUP STATISTICS">
              <InlineText
                text="No. Of Contacts"
                rightValue={group.contacts?.length}
              />
            </PageStatistics>
            <div className="mt-2">
              <ExportContact groupId={groupId}></ExportContact>
            </div>
          </InnerPageLeft>
        </div>
        <div className="col-xl-8 col-lg-12  pl-0 pe-5  pt-5">
          <GroupMemberList name={group.name} />
        </div>
      </div>
      {message && (
        <div className="notifications">
          <div className="info">
            <span>Info</span>
            <p>{message}</p>
            <button onClick={() => setMessage(null)}>&times;</button>
          </div>
        </div>
      )}
    </>
  );
};
