import React, {useState} from "react";
import {PaginationBack, PaginationNext} from "../../../assets/icons/icon";

import "../FormView/style.scss";

export function Pagination({totalPages, paginate}) {
  const pageNumbers = [];
  const [activePage, setActivePage] = useState(1);
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const handlePaginate = (number) => {
    paginate(number);
    setActivePage(number);
  };
  return (
    <ul className="custom-pagination">
      {activePage === 1 ? (
        ""
      ) : (
        <li>
          <button onClick={() => handlePaginate(activePage - 1)}>
            {PaginationBack}
          </button>
        </li>
      )}
      {pageNumbers.map((number) => (
        <li key={number}>
          <button
            className={number === activePage ? "active" : ""}
            onClick={() => handlePaginate(number)}
          >
            {number}
          </button>
        </li>
      ))}
      {activePage === pageNumbers.length ? (
        ""
      ) : (
        <li>
          <button onClick={() => handlePaginate(activePage + 1)}>
            {PaginationNext}
          </button>
        </li>
      )}
    </ul>
  );
}

export default Pagination;
