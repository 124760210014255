import styled from 'styled-components'
import * as theme from '../../../../../styles/theme'

export const StyledContainer = styled.div``

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const StyledInput = styled.input`
  width: 24px;
  height: 24px;
  appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background-color: #fff;
  &:checked {
    border: 7px solid ${theme.azul};
  }
  + span {
    font-size: 16px;
  }
`

export const StyledText = styled.span``

export const StyledOptionItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 0.5em;
  grid-gap: 1em;
`
