import React from "react";
import Heading from "../../../../Viewer/Components/Heading";
import { updateOptions } from "../../../utils";
import ComponentBox from "../Shared/Box";
import ValidationText from "../Shared/ValidationText";
import { alignments, styles } from "./options";
import {
  StyledContainer,
  StyledDropdown,
  StyledDropdownContainer,
  StyledDropdownOption,
  StyledInput,
  StyledWrapper
} from "./styles";

const HeadingComponent = ({
  component,
  onOptionsChange,
  onOrderButtonClick,
  onDeleteClick,
  onDuplicateClick
}) => (
  <ComponentBox
    title="Add Heading"
    column={component.options.column}
    previewMode={component.options.preview}
    onPreviewClick={() => {
      onOptionsChange(
        updateOptions(component.options, "preview", !component.options.preview)
      );
    }}
    onDeleteClick={onDeleteClick}
    onDuplicateClick={onDuplicateClick}
    onColumnChanged={column =>
      onOptionsChange(updateOptions(component.options, "column", column))
    }
    onOrderButtonClick={onOrderButtonClick}
  >
    {component.options.preview ? (
      <Heading component={component} />
    ) : (
      <StyledContainer>
        <StyledWrapper>
          <StyledInput
            className="form-control"
            placeholder="Add Heading"
            value={component.options.value}
            onChange={e =>
              onOptionsChange(
                updateOptions(component.options, "value", e.currentTarget.value)
              )
            }
          />
          <StyledDropdownContainer>
            <StyledDropdown
              value={component.options.alignment}
              onChange={e =>
                onOptionsChange(
                  updateOptions(
                    component.options,
                    "alignment",
                    e.currentTarget.value
                  )
                )
              }
            >
              {alignments.map(alignment => (
                <StyledDropdownOption key={alignment} value={alignment}>
                  {alignment}
                </StyledDropdownOption>
              ))}
            </StyledDropdown>
          </StyledDropdownContainer>
          <StyledDropdownContainer>
            <StyledDropdown
              value={component.options.style}
              onChange={e =>
                onOptionsChange(
                  updateOptions(
                    component.options,
                    "style",
                    e.currentTarget.value
                  )
                )
              }
            >
              {styles.map(style => (
                <StyledDropdownOption key={style} value={style}>
                  {style}
                </StyledDropdownOption>
              ))}
            </StyledDropdown>
          </StyledDropdownContainer>
        </StyledWrapper>
        {component.validations?.value && (
          <ValidationText>{component.validations.value}</ValidationText>
        )}
      </StyledContainer>
    )}
  </ComponentBox>
);

export default HeadingComponent;
