import RegisterPage from '../../pages/Register'
import ForgotPasswordPage from '../../pages/ForgotPassword'
import VerificationPage from '../../pages/Verification'
import ForgotPasswordSuccessMessagePage from '../../pages/ForgotPasswordSuccessMessage'
import ResetPasswordSuccessPage from '../../pages/ResetPasswordSuccess'
import NoAccessPage from '../../pages/NoAccessPage'
import AcceptInvitation from '../../pages/Business/Contacts/AcceptInvitation'
import LoginPage from '../../pages/Login'
import FormViewPage from '../../components/FormBuilder/FormView/FormViewPage'
import FormPreviewPage from '../../pages/Business/Forms/Preview'

const previewPrefix = 'preview'

export const defaultRoutes = [
  {
    path: `/${previewPrefix}/:formId`,
    component: FormPreviewPage,
    isPrivate: false,
  },
  {
    path: `/${previewPrefix}/old/:formId`,
    component: FormViewPage,
    isPrivate: false,
  },
  {
    path: '/login',
    component: LoginPage,
    isPrivate: false,
  },
  {
    path: '/register',
    component: RegisterPage,
    isPrivate: false,
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    isPrivate: false,
  },
  {
    path: '/verification',
    component: VerificationPage,
    isPrivate: false,
  },
  {
    path: '/password-email-sent',
    component: ForgotPasswordSuccessMessagePage,
    isPrivate: false,
  },
  {
    path: '/reset-password-success',
    component: ResetPasswordSuccessPage,
    isPrivate: false,
  },
  {
    path: `/contact-acception/:token`,
    component: AcceptInvitation,
    isPrivate: false,
  },
  {
    path: '/no-access',
    component: NoAccessPage,
    isPrivate: false,
  },
  {
    path: '/*',
    component: LoginPage,
    isPrivate: false,
  },
]
