import React, {useState} from "react";
import {useHistory} from "react-router";
import Header from "../../../../components/Layout/Header/Header";
import {PageContainer} from "../../../../components/Layout/Container/PageContainer";
import {PageHeader} from "../../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../../components/Layout/Shared/PageTitle";
import {HeaderButtons} from "../../../../components/Layout/Shared/PageHeader/HeaderButtons";
import {CustomButton} from "../../../../components/Layout/Shared/Button";
import {PieChartIcon} from "../../../../assets/icons/icon";
import {EditContactDetails} from "../../../../components/Contacts/Update/ContactDetails";
import {ImportContactManager} from "../../../../components/Contacts/ImportContactManager";
import {GenericPageLeft} from "../../../../components/Layout/Shared/GenericPageLeft";
import "./index.scss";

const UpdateContactPage = () => {
  const history = useHistory();
  const goToCreateNewContact = () => history.push(`/business/contact/create`);
  const goToContactList = () => history.push(`/business/contact/list`);
  const [showImport, setShowImport] = useState(false);

  return (
    <section className="dashboard  bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3 view-edit-contact">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>CONTACTS</PageTitle>
              <HeaderButtons>
                <CustomButton
                  color="transparent"
                  title="Import Contact"
                  size="200px"
                  onClick={() => setShowImport(true)}
                />
                <CustomButton
                  color="blue"
                  title="Add Contact"
                  size="150px"
                  onClick={goToCreateNewContact}
                />
                <button className="btn btn-icon">
                  {PieChartIcon} Analytics
                </button>
              </HeaderButtons>
            </PageHeader>
            <EditContactDetails />
          </PageContainer>
        </div>
      </div>
      <ImportContactManager
        show={showImport}
        setShow={setShowImport}
        refresh={goToContactList}
      />
    </section>
  );
};

export default UpdateContactPage;
