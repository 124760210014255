import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import Table from "../../../Layout/Shared/Table";
import {Options} from "../../../Layout/Shared/DropdownOptionsMenu";
import {ThreeDotIcon} from "../../../../assets/icons/icon";
import {DeleteModal} from "../../../Layout/Shared/Popups/Delete";
import {list} from "../../../../data/Contact/Business/Group/list";
import {FormattedDate} from "../../../Layout/Shared/FormattedDate";
import DataLoading from "../../../Layout/Shared/DataLoading";
import {deleteById} from "../../../../data/Contact/Business/Group/delete";
import DefaultImage from "../../../../assets/images/default-profile-picture.png";
import {useBusinessContactContext} from "../../../../providers/Contact/business";

const GroupList = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [selectedGroupForDelete, setSelectedGroupForDelete] = useState(null);
  const {refresh} = useBusinessContactContext();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await list();
      if (hasSuccess) {
        setGroups(data);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const handleDelete = async () => {
    if (!selectedGroupForDelete) return;
    setIsLoading(true);
    const {hasSuccess} = await deleteById(selectedGroupForDelete._id);
    hasSuccess && refresh();
  };

  const ActionButtons = ({row}) => {
    const history = useHistory();
    const goToGroupDetails = () =>
      history.push(`/business/contact/group-details/${row._id}`);
    return (
      <Options
        arrow={false}
        buttonTitle={ThreeDotIcon}
        menuItems={
          <div>
            <button onClick={goToGroupDetails}>View/Edit</button>
            <button
              className="text-danger"
              onClick={() => {
                setSelectedGroupForDelete(row);
                setShowDelete(true);
              }}
            >
              Delete
            </button>
          </div>
        }
      />
    );
  };

  const Avatars = ({row}) => (
    <div className="avatar-in-table" key={row._id}>
      <div className="members">
        {row.contacts.slice(0, 5).map((contact) => (
          <div>
            <img src={contact.photoUrl ?? DefaultImage} alt="" />
          </div>
        ))}
      </div>
      <span>{row?.countOfContacts}</span>
    </div>
  );

  const fields = [
    "name",
    {render: Avatars},
    {render: ({row}) => <FormattedDate date={row.created_at} />},
    {render: ActionButtons},
  ];
  const headers = [
    {
      displayName: "Group Name",
      sortingFieldName: "name",
    },
    {
      displayName: "NO. of Contacts",
      sortingFieldName: "contactCount",
    },
    {
      displayName: "Date Created",
      sortingFieldName: "created_at",
      sortingOptions: {dataType: "date"},
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions",
    },
  ];
  const options = {
    data: groups,
    headers,
    fields,
    pagination: true,
    search: false,
    sort: true,
  };
  return (
    <div>
      <div className="ps-5">
        {isLoading ? (
          <DataLoading height="700px" />
        ) : (
          <Table options={options} />
        )}
        {showDelete && (
          <DeleteModal
            title="Delete Group"
            text={`You are about to delete group of ${selectedGroupForDelete.name} from your group list`}
            buttonText="Delete"
            onClose={handleDelete}
          />
        )}
      </div>
    </div>
  );
};

export default GroupList;
