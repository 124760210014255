import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Table from "../../../Layout/Shared/Table";
import { Options } from "../../../Layout/Shared/DropdownOptionsMenu";
import { ThreeDotIcon } from "../../../../assets/icons/icon";
import { DeleteModal } from "../../../Layout/Shared/Popups/Delete";
import {
  getAllGroups,
  deleteGroup
} from "../../../../data/Client/Business/group";
import { FormattedDate } from "../../../Layout/Shared/FormattedDate";
import DataLoading from "../../../Layout/Shared/DataLoading";
import DefaultImage from "../../../../assets/images/default-profile-picture.png";
import { useBusinessClientListContext } from "../../../../providers/Client/list";

const GroupList = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [selectedGroupForDelete, setSelectedGroupForDelete] = useState(null);
  const { refresh } = useBusinessClientListContext();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const { hasSuccess, data } = await getAllGroups();
      if (hasSuccess) setGroups(data);
      setIsLoading(false);
    };
    init();
  }, []);

  const handleDelete = async () => {
    if (!selectedGroupForDelete) return;
    setIsLoading(true);
    const { hasSuccess } = await deleteGroup(selectedGroupForDelete._id);
    hasSuccess && refresh();
  };

  const ActionButtons = ({ row }) => {
    const history = useHistory();
    const goToGroupDetails = () =>
      history.push(`/business/client/group/${row._id}`);
    return (
      <Options
        arrow={false}
        buttonTitle={ThreeDotIcon}
        menuItems={
          <div>
            <button onClick={goToGroupDetails}>View/Edit</button>
            <button
              className="text-danger"
              onClick={() => {
                setSelectedGroupForDelete(row);
                setShowDelete(true);
              }}
            >
              Delete
            </button>
          </div>
        }
      />
    );
  };

  const Avatars = ({ row }) => (
    <div className="avatar-in-table" key={row._id}>
      <div className="members">
        {row.clients.slice(0, 5).map(client => (
          <div>
            <img
              src={
                client.photoUrl && client.photoUrl !== ""
                  ? client.photoUrl
                  : DefaultImage
              }
              alt=""
            />
          </div>
        ))}
      </div>
      <span>{row?.countOfClients}</span>
    </div>
  );

  const fields = [
    "name",
    { render: Avatars },
    { render: ({ row }) => <FormattedDate date={row.created_at} /> },
    { render: ActionButtons }
  ];
  const headers = [
    {
      displayName: "Group Name",
      sortingFieldName: "name"
    },
    {
      displayName: "NO. of Clients",
      sortingFieldName: "clientCount"
    },
    {
      displayName: "Date Created",
      sortingFieldName: "created_at",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];
  const options = {
    data: groups,
    headers,
    fields,
    pagination: true,
    search: false,
    sort: true
  };
  return (
    <div>
      <div className="ps-5">
        {isLoading ? (
          <DataLoading height="700px" />
        ) : (
          <Table options={options} />
        )}
        {showDelete && (
          <DeleteModal
            title="Delete Group"
            text={`You are about to delete group of ${selectedGroupForDelete.name} from your group list`}
            buttonText="Delete"
            onConfirm={handleDelete}
            onClose={() => setShowDelete(false)}
          />
        )}
      </div>
    </div>
  );
};

export default GroupList;
