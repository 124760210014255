import {LabelBlueBold} from "../../../../../Layout/Shared/LabelBlueBold";
import "./index.scss";
export const AppointmentCompanyDetails = ({companyData}) => {
  return (
    <div className="ao-company-details">
      <LabelBlueBold content="Company" />
      <div className="details">
        <figure>
          <img src={companyData.logo} alt="" />
        </figure>
        <div>
          <h3>{companyData.name}</h3>
          <span>
            {companyData.address}
            <br />
            {companyData.city}, {companyData.country}, {companyData.zipCode}
          </span>
        </div>
      </div>
    </div>
  );
};
