import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllForms } from '../../../../data/Forms/getAllForms'
import { TemplateTableLoader } from '../../../Layout/Shared/DataLoading/table-loader.component'
import Table from '../../../Layout/Shared/Table'

const Forms = ({formType = ""}) => {


const [isLoading, setIsLoading] = useState(true)
const [forms, setForms] = useState([])
 
  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    setIsLoading(true)
    const { data: response, hasSuccess } = await getAllForms()

    hasSuccess
      ? setForms(response.data)
      : console.log(JSON.parse(response).description)
    setIsLoading(false)
  }
 

  const filteredForms = formType === ""
  ? forms
  : forms?.filter((form) => form.type === formType)


  const goToSubmissions = (formId) => 
  <Link  to={`/business/forms/${formId}/submissions`}>Show Submissions </Link>

 

  const options = {
    headers: [
      {
        displayName: 'Form Name',
        sortingFieldName: 'title',
      },
      {
        displayName: 'Last time edited',
        sortingFieldName: 'updated_at',
      },
      {
        displayName: 'Submissions',
        sortingFieldName: 'actions',
      },
    ],
    fields: [
      {
        render: ({ row }) => (
          <div className="d-flex justify-content-start align-items-center">
            {row.title}
          </div>
        ),
      },
      {
        render: ({ row }) => (
          <div className="d-flex justify-content-start align-items-center">
            {row.updated_at}
          </div>
        ),
      },
      {
        render: ({ row }) =>   goToSubmissions(row._id)
      },
    ],
    pagination: true,
    search: false,
    sort: false,
  }


  return (

 isLoading ? <TemplateTableLoader showListView={false} />:
    <Table options={{ data: filteredForms, ...options }} />
   
 
  )
}

export default Forms