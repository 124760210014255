import React, { useState, useEffect } from "react";
import { useChatContext } from "../../../providers/Chat";
import Modal from "../../Layout/Shared/Modal";
import Table from "../../Layout/Shared/Table";
import { ScrollContent } from "../../Layout/Shared/ScrollContent";
import {
  StyledSelectedParticipantContainer,
  StyledSelectedParticipantBadge,
  StyledButtonContainer,
  StyledGroupNameContainer,
  StyledGroupNameInput
} from "./NewMessage.style";
import { CustomButton } from "../../Layout/Shared/Button";
import DataLoading from "../../Layout/Shared/DataLoading";

const defaultAvatar = "https://www.w3schools.com/howto/img_avatar.png";

const NewMessage = ({ show, setShow }) => {
  const [contacts, setContacts] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [groupName, setGroupName] = useState("");
  const { users, isReady, createChat, setSelectedChat } = useChatContext();

  const handleOnCreateChat = async () => {
    const selectedUserIds = contacts
      .filter(contact => contact.isSelected)
      .map(contact => contact.id);

    if (selectedUserIds.length === 0 || !isReady) return;

    if (!groupName || groupName.length < 3 || groupName.length > 25) {
      // alert("Group name must be at least 3 characters");
      return;
    }

    setSubmitting(true);
    const chat = await createChat(selectedUserIds, groupName);
    if (chat) {
      setSelectedChat(chat);
      setShow(false);
    }
    setSubmitting(false);
  };
  const handleOnClose = () => setShow(false);

  const SelectedParticipants = () => {
    const selected = contacts.filter(contact => contact.isSelected);
    return (
      <>
        <StyledSelectedParticipantContainer>
          {selected.map((contact, index) => (
            <StyledSelectedParticipantBadge
              key={index}
              type={contact.type}
              name={contact.name}
              businessName={contact.businessName}
            >
              <span>{contact.name}</span>
            </StyledSelectedParticipantBadge>
          ))}
        </StyledSelectedParticipantContainer>
      </>
    );
  };

  const Avatar = ({ row }) => (
    <div className="avatar-in-table" key={row.id}>
      <div
        className={`single-member ${row.type} ${
          !row.onboardingCompleted && "disabled"
        }`}
      >
        <img src={row.profilePicture || row.logo || defaultAvatar} alt="" />
      </div>
    </div>
  );

  const CheckBox = ({ row }) => (
    <input
      type="checkbox"
      disabled={!row.onboardingCompleted}
      checked={row.isSelected}
      onChange={() => toggleChecked(row.id)}
    />
  );

  const toggleChecked = id => {
    setContacts(
      contacts.map(item => {
        return {
          ...item,
          isSelected: item.id === id ? !item.isSelected : item.isSelected
        };
      })
    );
  };

  const fields = [
    {
      render: CheckBox
    },
    {
      render: Avatar
    },
    {
      render: ({ row }) => `${row.name}`
    },
    "email"
  ];
  const headers = [
    {
      displayName: "Selected",
      fieldName: "isSelected"
    },
    {
      displayName: "Profile Picture",
      fieldName: "profilePicture"
    },
    {
      displayName: "Full Name",
      fieldName: "name"
    },
    {
      displayName: "Email",
      fieldName: "email"
    }
  ];
  const options = {
    data: contacts,
    headers,
    fields,
    itemsPerPage: 5,
    pagination: true,
    search: false,
    sort: false,
    headerAction: null
  };

  useEffect(() => {
    const init = async () => {
      if (users && users.length > 0) setContacts(users);
    };
    const dispose = () => {
      setContacts([]);
      setGroupName("");
    };
    init();
    return () => {
      dispose();
    };
  }, [users, show]);

  const createGroupDisabled =
    !isReady ||
    contacts.filter(c => c.isSelected).length < 2 ||
    !groupName ||
    groupName.length < 3 ||
    groupName.length > 25;

  return (
    show && (
      <Modal
        title="New Group Chat"
        width="718px"
        height="700px"
        onClose={handleOnClose}
      >
        {submitting ? (
          <DataLoading height="550px" />
        ) : (
          <>
            <SelectedParticipants />
            <StyledGroupNameContainer>
              <StyledGroupNameInput
                type="text"
                value={groupName}
                onChange={({ target }) => setGroupName(target.value)}
                min={3}
                max={25}
                placeholder="Enter a group name"
              />
            </StyledGroupNameContainer>
            <ScrollContent height={500} style={{ padding: "0 20px" }}>
              <Table options={options} />
            </ScrollContent>
            <StyledButtonContainer>
              <button onClick={handleOnClose} className="btn-text-blue">
                Cancel
              </button>
              <CustomButton
                disabled={createGroupDisabled}
                title="Create Group"
                size="195px"
                color="blue"
                onClick={handleOnCreateChat}
              />
            </StyledButtonContainer>
          </>
        )}
      </Modal>
    )
  );
};

export default NewMessage;
