/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { Formik, Form, Field } from "formik";
import "./createService.scss";
import useBusinessCaseManagementCaseFormContext from "../../../../../../providers/CaseManagement/Cases/form";
import { Autocomplete } from "../../../../../Layout/Shared/Autocomplete";
import { LabelBlueBold } from "../../../../../Layout/Shared/LabelBlueBold";
import {
  CaseManagementStatus,
  CaseManagementPriority
} from "../../../../../CaseManagement/Shared";

const LeftSideForm = () => {
  const { setters, getters, caseFormData } =
    useBusinessCaseManagementCaseFormContext();
  const { caseManagementCase } = getters;
  const { types = [], stages = [], clients = [], staffs = [] } = caseFormData;
  const { setCaseManagementCase } = setters;

  return (
    <Fragment>
      <Formik enableReinitialize initialValues={caseManagementCase}>
        {({ values, errors }) => (
          <>
            {setCaseManagementCase(values)}
            <Form>
              <div className="create-service-form border-bottom">
                <div className="form">
                  <div className="form-group">
                    <LabelBlueBold content="Case Title" />
                    <Field
                      name="title"
                      className="custom-input"
                      placeholder="Case Title"
                    >
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <input
                          value={value}
                          name="title"
                          className="custom-input"
                          placeholder="Case Title"
                          onChange={({ target }) =>
                            setFieldValue("title", target.value)
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <LabelBlueBold content="Case Type" />
                    <Field
                      name="typeId"
                      className="custom-input"
                      placeholder="Case Type"
                    >
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <Autocomplete
                          name="typeId"
                          className="custom-input"
                          placeholder="Case Type"
                          options={types.map(item => ({
                            id: item._id,
                            text: item.title
                          }))}
                          defaultValue={
                            types.find(({ _id }) => _id === value)?.title
                          }
                          onSelect={({ id }) => setFieldValue("typeId", id)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <LabelBlueBold content="Status" />
                    <Field name="status">
                      {({ field: { value }, form: { setFieldValue } }) => {
                        return (
                          <CaseManagementStatus
                            id="case-management-case-status"
                            name="status"
                            selectedValue={value}
                            onChange={value => setFieldValue("status", value)}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <div className="form-group">
                    <LabelBlueBold content="Stage Type" />
                    <Field
                      name="stageId"
                      className="custom-input"
                      placeholder="Stage Type"
                    >
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <Autocomplete
                          name="stageId"
                          className="custom-input"
                          placeholder="Stage Type"
                          options={stages.map(item => ({
                            id: item._id,
                            text: item.title
                          }))}
                          defaultValue={
                            stages.find(({ _id }) => _id === value)?.title
                          }
                          onSelect={({ id }) => setFieldValue("stageId", id)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <LabelBlueBold content="Client" />
                    <Field
                      name="clientId"
                      className="custom-input"
                      placeholder="Client"
                    >
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <Autocomplete
                          name="clientId"
                          className="custom-input"
                          placeholder="Client"
                          options={clients.map(item => ({
                            id: item._id,
                            text: item.fullName
                          }))}
                          defaultValue={
                            clients.find(({ _id }) => _id === value)?.fullName
                          }
                          onSelect={({ id }) => setFieldValue("clientId", id)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <LabelBlueBold content="Priority" />
                    <Field name="priority">
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <CaseManagementPriority
                          id="case-management-case-priority"
                          name="priority"
                          selectedValue={value}
                          onChange={value => setFieldValue("priority", value)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <LabelBlueBold content="Assigned To" />
                    <Field
                      name="assignedTo"
                      className="custom-input"
                      placeholder="Select Staff"
                    >
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <Autocomplete
                          name="assignedTo"
                          className="custom-input"
                          placeholder="Select Staff"
                          options={staffs.map(item => ({
                            id: item._id,
                            text: item.fullName
                          }))}
                          defaultValue={
                            staffs.find(({ _id }) => _id === value)?.fullName
                          }
                          onSelect={({ id }) => {
                            setFieldValue("assignedTo", id);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <LabelBlueBold content="Start Date" />
                    <Field
                      className="custom-input ms-5"
                      placeholder="Start Date"
                      name="startDate"
                    >
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <input
                          type="date"
                          className="custom-input bg-transparent"
                          placeholder="Start Date"
                          max={new Date().toISOString().split("T")[0]}
                          value={
                            new Date(value || Date.now())
                              .toISOString()
                              .split("T")[0]
                          }
                          onChange={({ target }) =>
                            setFieldValue("startDate", target.value)
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <LabelBlueBold content="Due Date" />
                    <Field
                      className="custom-input ms-5"
                      placeholder="Due Date"
                      name="dueDate"
                    >
                      {({ field: { value }, form: { setFieldValue } }) => (
                        <input
                          type="date"
                          className="custom-input bg-transparent"
                          placeholder="Due Date"
                          min={new Date().toISOString().split("T")[0]}
                          value={
                            new Date(value || Date.now())
                              .toISOString()
                              .split("T")[0]
                          }
                          onChange={({ target }) =>
                            setFieldValue("dueDate", target.value)
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Fragment>
  );
};

export default LeftSideForm;
