import React, {useState} from "react";
import Modal from "../../Modal";
import {Formik, Form, Field} from "formik";
import {LabelBlueBold} from "../../LabelBlueBold";
import {CustomButton} from "../../Button";
import ErrorField from "../../ErrorField";
import {BusinessShareModalSchema} from "../../../../../schemas/Shared/shareModal";
import {ListLoader} from "../../DataLoading";
import "./index.scss";
export const ShareModal = ({onClose, onConfirm, onExport, title}) => {
  const [isLoading, setIsLoading] = useState(false);
  const shareInfo = {
    emails: "",
    comment: "",
  };

  const handleOnSubmit = (values, {setSubmitting}) => {
    setIsLoading(true);
    onConfirm(values, {setSubmitting});
  };

  const handleOnClose = () => {
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal title={title} width="757px" height="500px" close={handleOnClose}>
      {isLoading ? (
        <ListLoader height="500px" />
      ) : (
        <div className="share-modal">
          <LabelBlueBold content="Email" />
          <p className="text-muted">
            Enter multiple emails separated by a comma “,”
          </p>
          <Formik
            enableReinitialize
            initialValues={shareInfo}
            validateOnBlur={false}
            validationSchema={BusinessShareModalSchema}
            onSubmit={handleOnSubmit}
          >
            {({errors}) => (
              <>
                <Form>
                  <Field
                    className="custom-input"
                    placeholder="Enter Receiver Email"
                    name="emails"
                  />
                  <div className="mt-5">
                    <Field
                      as="textarea"
                      className="custom-textarea"
                      placeholder="Add Comment"
                      name="comment"
                    />
                  </div>
                  <div className="mt-5 mb-4 d-flex justify-content-end">
                    <CustomButton color="blue" title="Share" size="160px" />
                  </div>
                  <ErrorField message={errors.emails} />
                  <ErrorField message={errors.comment} />
                </Form>
              </>
            )}
          </Formik>
          <button className="btn-text-blue btn-export" onClick={onExport}>
            Export
          </button>
        </div>
      )}
    </Modal>
  );
};
