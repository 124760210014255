import React, { useState } from "react";
import LogoBlueSmall from "../../assets/logo/logoSmall";
import "../../styles/pages/Onboarding.scss";
import YourAddress from "../../components/Onboarding/Client/Address";
import YourInformation from "../../components/Onboarding/Client/Information";
import SelectIndustries from "../../components/Onboarding/Client/Industries";
import steps from "../../components/Onboarding/Client/steps";
import ClientOnboardingContext from "../../providers/Onboarding/client";
import {
  defaultIndustries,
  defaultAddress,
  defaultClientPersonalInformation,
  getSelectedIndustries
} from "../../components/Onboarding/defaultValues.js";
import { updateClient } from "../../data/Onboarding/client";
import { useGlobalContext } from "../../providers/global";

const OnboardingPage = () => {
  const [activeStep, setActiveStep] = useState(steps.INFORMATION);
  const [industries, setIndustries] = useState(defaultIndustries);
  const [address, setAddress] = useState(defaultAddress);
  const [yourInformation, setYourInformation] = useState(
    defaultClientPersonalInformation
  );

  const { updateCurrentUserValue } = useGlobalContext();

  const objectToSave = {
    industries: getSelectedIndustries(industries),
    address,
    yourInformation
  };

  const finish = async () => {
    updateCurrentUserValue({
      firstName: yourInformation.firstName,
      onboardingCompleted: true
    });
    try {
      await updateClient(objectToSave);
    } catch (error) {
      updateCurrentUserValue({
        firstName: "",
        onboardingCompleted: false
      });
      throw error;
    }
  };

  const onbooardingContext = {
    industryInformation: { industries, setIndustries },
    addressInformation: { address, setAddress },
    yourInformations: { yourInformation, setYourInformation },
    finish
  };
  return (
    <ClientOnboardingContext.Provider value={onbooardingContext}>
      <section className="onboarding">
        <div className="vertical-menu">
          <LogoBlueSmall />
          <div className="tabs">
            {
              <ul>
                <li>
                  <span
                    className={activeStep === steps.INFORMATION ? "active" : ""}
                    onClick={() => setActiveStep(steps.INFORMATION)}
                  >
                    Your Information
                  </span>
                </li>
                <li>
                  <span
                    className={activeStep === steps.ADDRESS ? "active" : ""}
                    onClick={() => setActiveStep(steps.ADDRESS)}
                  >
                    Your Address
                  </span>
                </li>
                <li>
                  <span
                    className={activeStep === steps.INDUSTRY ? "active" : ""}
                    onClick={() => setActiveStep(steps.INDUSTRY)}
                  >
                    Select Industries
                  </span>
                </li>
              </ul>
            }
          </div>
        </div>
        <div className="onboarding-section">
          <header>
            Welcome!
            <small>Let’s get started, by setting up your account.</small>
          </header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="tab-content">
                  {activeStep === steps.INFORMATION && (
                    <YourInformation goTo={setActiveStep} />
                  )}
                  {activeStep === steps.ADDRESS && (
                    <YourAddress goTo={setActiveStep} />
                  )}
                  {activeStep === steps.INDUSTRY && (
                    <SelectIndustries goTo={step => setActiveStep(step)} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ClientOnboardingContext.Provider>
  );
};
export default OnboardingPage;
