import Modal from "../../../../../Layout/Shared/Modal";
import {CustomButton} from "../../../../../Layout/Shared/Button";
export const ActivateStaff = ({onClose, username, onConfirm}) => {
  return (
    <Modal title="Activate Staff" width="510px" height="280px" close={onClose}>
      <p>
        You are about to activate {username}’s account. <br />
        <br />
        Are you sure you want to continue?
      </p>
      <div className="mt-4 d-flex justify-content-end">
        <button className="btn-text-blue me-5 " onClick={onClose}>
          Cancel
        </button>
        <CustomButton
          color="blue"
          title="Activate"
          size="160px"
          onClick={onConfirm}
        />
      </div>
    </Modal>
  );
};
