import React, {Fragment} from "react";
import COUNTRIES from "../../../../../static/Shared/countries";
import {DEFAULT_SELECTION_VALUE} from "../../../../../utils/string";

const CountrySelect = ({filteredCodes, ...props}) => {
  const options = filteredCodes
    ? COUNTRIES.filter((country) => filteredCodes.includes(country.code))
    : COUNTRIES;

  return (
    <select
      className="primary-select"
      placeholder="Select Country"
      defaultValue={DEFAULT_SELECTION_VALUE}
      {...props}
    >
      <option value={DEFAULT_SELECTION_VALUE}>Select Country</option>
      {options.map((option, index) => (
        <Fragment key={index}>
          <option value={option.code}>{option.name}</option>
        </Fragment>
      ))}
    </select>
  );
};

export default CountrySelect;
