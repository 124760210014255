import React from 'react'
import { StyledRadiusInput } from '../../../styles'
import Checkbox from '../Checkbox'

const errorMessagePlaceholderDefault = 'This question requires an answer.'
const checkboxLabelDefault = 'Require an answer to this question'

const Required = ({
  isRequired,
  onRequiredChanged,
  errorMessagePlaceholder,
  checkboxLabel,
  errorMessage,
  onErrorMessageChanged,
}) => (
  <>
    <Checkbox
      checked={isRequired}
      onCheckedChanged={onRequiredChanged}
      label={checkboxLabel || checkboxLabelDefault}
    />
    {isRequired && (
      <StyledRadiusInput
        className="form-control"
        placeholder={errorMessagePlaceholder || errorMessagePlaceholderDefault}
        value={errorMessage}
        onChange={(e) => onErrorMessageChanged(e.currentTarget.value)}
      />
    )}
  </>
)

export default Required
