/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { GenericPageLeft } from "../../../../components/Layout/Shared/GenericPageLeft";
import Header from "../../../../components/Layout/Header/Header";
import { PageContainer } from "../../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../../components/Layout/Shared/PageTitle";
import TabContainer from "../../../../components/CaseManagement/Cases/Form/TabContainer";
import DataLoading from "../../../../components/Layout/Shared/DataLoading";
import { CustomButton } from "../../../../components/Layout/Shared/Button";
import { LastUpdate } from "../../../../components/Layout/Shared/LastUpdate";
import ErrorField from "../../../../components/Layout/Shared/ErrorField";
import "./createService.scss";
import useBusinessCaseManagementCaseFormContext, {
  BusinessCaseManagementCaseFormProvider
} from "../../../../providers/CaseManagement/Cases/form";
import LeftSide from "../../../../components/CaseManagement/Cases/Form/LeftSide";
import { useHistory } from "react-router-dom";

const CaseManagementCaseFormPage = () => {
  return (
    <BusinessCaseManagementCaseFormProvider>
      <section className="dashboard  bg-paleGray pb-5 pr-3">
        <Header />
        <CaseManagementCaseFormInnerComponent />
      </section>
    </BusinessCaseManagementCaseFormProvider>
  );
};

export default CaseManagementCaseFormPage;

const CaseManagementCaseFormInnerComponent = () => {
  const { getters, submit } = useBusinessCaseManagementCaseFormContext();
  const {
    caseManagementCase,
    loading,
    submitting,
    formMode,
    errors = [],
    selectedTab
  } = getters;
  const history = useHistory();
  const submitButtonTitle =
    formMode === "new"
      ? "Create"
      : formMode === "edit"
      ? "Update"
      : "Edit Case";
  const cancelButtonTitle =
    formMode === "new" ? "Cancel" : formMode === "edit" ? "Discard" : "Go Back";
  const goBack = () => history.goBack();
  const title =
    formMode === "new" ? "Create A New Case" : caseManagementCase.title;
  const hiddenButton =
    formMode === "edit" && !selectedTab.rights.includes("new");

  const handleEdit = () =>
    history.push(
      `/business/case-management/cases/form/${caseManagementCase._id}/edit`
    );

  return (
    <>
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>{title}</PageTitle>
              <div className="d-flex justify-content-end align-items-center">
                <LastUpdate date={caseManagementCase.updatedAt} />
                <CustomButton
                  hidden={hiddenButton}
                  color="transparent"
                  title={cancelButtonTitle}
                  size="160px"
                  onClick={goBack}
                />
                <CustomButton
                  hidden={hiddenButton}
                  color="blue"
                  title={submitButtonTitle}
                  size="160px"
                  onClick={formMode === "view" ? handleEdit : submit}
                />
              </div>
            </PageHeader>
            <div className="row m-2  bg-white border rounded-xl create-service-main">
              {loading || submitting ? (
                <DataLoading height="500px" />
              ) : (
                <>
                  <div className="col-xl-3 col-lg-12 p-0 border-end">
                    <LeftSide />
                    <ErrorsDisplay errors={errors} />
                  </div>
                  <div className="col-xl-9 col-lg-12 p-0">
                    <TabContainer />
                  </div>
                </>
              )}
            </div>
          </PageContainer>
        </div>
      </div>
    </>
  );
};

const ErrorsDisplay = ({ errors = [] }) => {
  return (
    <>
      {errors.map((error, index) => (
        <ErrorField key={index} message={error} />
      ))}
    </>
  );
};
