import {PaginationBack, PaginationNext} from "../../../../assets/icons/icon";
export const calculatePageCount = (data, itemPerPage) =>
  data.length % itemPerPage === 0
    ? parseInt(data.length / itemPerPage, 10)
    : parseInt(data.length / itemPerPage, 10) + 1;

export const CustomPagination = ({paginationOptions, goToPage}) => {
  const {activePage, totalPages} = paginationOptions;
  const hasPreviousPage = activePage > 1;
  const hasNextPage = totalPages > activePage;
  const goToPreviousPage = () => hasPreviousPage && goToPage(activePage - 1);
  const goToNextPage = () => hasNextPage && goToPage(activePage + 1);
  return (
    <ul className="custom-pagination">
      <li>
        <button
          disabled={hasPreviousPage ? "" : "disabled"}
          onClick={goToPreviousPage}
        >
          {PaginationBack}
        </button>
      </li>
      {Array.from(Array(totalPages).keys()).map((page) => (
        <li key={page} onClick={() => goToPage(page + 1)}>
          <button className={activePage === page + 1 ? "active" : ""}>
            {page + 1}
          </button>
        </li>
      ))}
      <li>
        <button disabled={hasNextPage ? "" : "disabled"} onClick={goToNextPage}>
          {PaginationNext}
        </button>
      </li>
    </ul>
  );
};
