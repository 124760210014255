/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from "react";
import {Formik, Form, Field} from "formik";
import PhoneImage from "../../../../assets/images/phoneImage.png";
import steps from "../steps";
import "react-phone-input-2/lib/style.css";
import BusinessOnboardingContext from "../../../../providers/Onboarding/business";
import {YourInformationSchema} from "../../../../schemas/Onboarding/Business/yourInformation";
import ErrorField from "../../../../components/Layout/Shared/ErrorField";
import {designations} from "../../../../static/Onboarding/business";

const YourInformation = ({goTo}) => {
  const [error, setError] = useState(null);
  const [designation, setDesignation] = useState(null);
  const {yourInformations} = useContext(BusinessOnboardingContext);
  const {yourInformation, setYourInformation} = yourInformations;

  const onSubmit = (values, _) => {
    if (!designation || designation?.value === "-1") {
      setError("Please select designation");
      return;
    }
    setYourInformation({...values, designation: designation.value});
    localStorage.setItem("userFirstName", values.firstName)
    goTo(steps.PLANS);
  };

  const handlePrev = () => goTo(steps.BUSINESSIFORMATION);

  return (
    <div className="tab active" id="yourInformation">
      <div className="row">
        <div className="col-xl-6  col-lg-6  pl-0">
          <div className="component first">
            <h2>
              <strong>Personalize your Business</strong>
            </h2>
            <div>
              <Formik
                initialValues={yourInformation}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onSubmit}
                validationSchema={YourInformationSchema}
              >
                {({errors}) => (
                  <Form>
                    <div className="form-group">
                      <label className="w-100">
                        <strong>Your Name</strong>
                      </label>
                      <div className="d-flex justify-content-between">
                        <Field
                          className="custom-input w-50"
                          placeholder="Enter First Name"
                          name="firstName"
                        />
                        <Field
                          className="custom-input w-50"
                          placeholder="Enter Last Name"
                          name="lastName"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="w-100">
                        <strong>Designation</strong>
                      </label>
                      <select
                        className="primary-select"
                        id="id"
                        onChange={({target}) => setDesignation(target)}
                        defaultValue="-1"
                      >
                        <option value="-1" disabled>
                          Please select designation
                        </option>
                        {designations.map((designation) => (
                          <option
                            key={designation.name}
                            value={designation.name}
                          >
                            {designation.name}
                          </option>
                        ))}
                      </select>
                      <ErrorField message={errors.firstName} />
                      <ErrorField message={errors.lastName} />
                      {error && <ErrorField message={error} />}
                    </div>
                    <div className="form-group d-flex justify-content-end align-items-center">
                      <a
                        href="javascript:void(0)"
                        className="btn-text-blue me-5 "
                        onClick={handlePrev}
                      >
                        Previous
                      </a>
                      <a>
                        <button
                          type="submit"
                          className="mt-0 ms-5  btn btn-blue-gradient btn-steps"
                        >
                          Next
                        </button>
                      </a>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-md-none d-lg-none d-xl-block text-end">
          <div className="component second ">
            <img src={PhoneImage} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourInformation;
