import {React, useState, useEffect} from "react";
import {getReviewsStatistics} from "../../../../../data/Service/Business/getReviewsStatistics";
import {ListLoader} from "../../../../Layout/Shared/DataLoading";

export const ReviewStatistics = ({renderCount}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    const init = async () => {
      const {data, hasSuccess} = await getReviewsStatistics();
      hasSuccess
        ? setStatistics(data)
        : console.log(JSON.parse(data).description);
      setIsLoading(false);
    };
    init();
  }, [renderCount]);
  return (
    <>
      {isLoading ? (
        <ListLoader height="300px" />
      ) : (
        <ul className="review-statics ps-5 pe-5   px-5">
          {statistics.map((service, index) => (
            <li key={index}>
              {service.serviceName} <span>{service.numberOfReviews}</span>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
