import React from "react";
import ContactList from "./ContactList";
import GroupList from "./Group/List";

export const tabs = [
  {
    name: "Contacts",
    component: <ContactList />,
    isDefault: true,
  },
  {
    name: "Groups",
    component: <GroupList />,
  },
];
export const defaultContactTab = tabs.find((tab) => tab.isDefault);
