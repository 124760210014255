import {React, useState, useEffect} from "react";
import ReactStars from "react-rating-stars-component";
import {ThreeDotIcon} from "../../../../../assets/icons/icon";
import {getReviews} from "../../../../../data/Service/Business/getReviews";
import {FormattedDate} from "../../../../Layout/Shared/FormattedDate";
import Table from "../../../../Layout/Shared/Table";
import {Options} from "../../../../Layout/Shared/DropdownOptionsMenu";
import DataLoading from "../../../../Layout/Shared/DataLoading";
import {ReviewDetails} from "../ReviewDetails";

export const ReviewList = ({renderCount}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showReviewDetail, setShowReviewDetail] = useState(false);
  const [selectedReview, setSelectedReview] = useState({});
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const init = async () => {
      const {data: response, hasSuccess} = await getReviews();
      hasSuccess
        ? setReviews(response)
        : console.log(JSON.parse(response).description);
      setIsLoading(false);
    };
    init();
  }, [renderCount]);

  const Stars = ({row}) => <ReactStars value={row} />;
  const ActionButtons = ({row}) => (
    <Options
      arrow={false}
      buttonTitle={ThreeDotIcon}
      menuItems={
        <div>
          <button
            onClick={() => {
              setSelectedReview(row);
              setShowReviewDetail(true);
            }}
          >
            View
          </button>
        </div>
      }
    />
  );
  const fields = [
    {render: ({row}) => <span>{row.client.name}</span>},
    "review",
    {render: ({row}) => <Stars row={row.rating} />},
    {render: ({row}) => <span>{row.serviceName}</span>},
    {render: ({row}) => <FormattedDate date={row.created_at} />},
    {render: ActionButtons},
  ];
  const headers = [
    {
      displayName: "Client Name",
      sortingFieldName: "name",
    },
    {
      displayName: "Comment",
      sortingFieldName: "review",
    },
    {
      displayName: "Star Rating",
      sortingFieldName: "rating",
    },
    {
      displayName: "Service",
      sortingFieldName: "service",
    },
    {
      displayName: "Date",
      sortingFieldName: "date",
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions",
    },
  ];
  const options = {
    data: reviews,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
  };
  return (
    <>
      {isLoading ? (
        <DataLoading height="500px" />
      ) : (
        <>
          <Table options={options} />
          {showReviewDetail && (
            <ReviewDetails
              review={selectedReview}
              onClose={() => setShowReviewDetail(false)}
            />
          )}
        </>
      )}
    </>
  );
};
