import * as Yup from "yup";

export const YourInformationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Firstname is too short")
    .max(50, "Firstname is too long")
    .required("Firstname is required"),
  lastName: Yup.string()
    .min(3, "Lastname is too short")
    .max(50, "Lastname is too long")
    .required("Lastname is required"),
  phone: Yup.string()
    .min(3, "Phone number is too short")
    .max(15, "Phone number is too long")
    .required("Phone number is required"),
});
