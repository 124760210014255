import { clientApiHostName } from "../../endpoint";
import { defaultHeaders } from "../../headers";

export const getClientStatistics = async () => {
  try {
    const result = await fetch(`${clientApiHostName}/statistics`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
