import React, { useState } from 'react'
import {
  StyledContainer,
  StyledTabButton,
  StyledTabButtonsContainer,
  StyledTabContent,
} from './styles'

const Tab = ({ editComponent, optionsComponent }) => {
  const [activeTab, setActiveTab] = useState('edit')

  const hasOptionsTab = !!optionsComponent

  return (
    <StyledContainer>
      <StyledTabButtonsContainer>
        {hasOptionsTab && (
          <>
            <StyledTabButton
              isActive={activeTab === 'edit'}
              onClick={() => setActiveTab('edit')}
            >
              EDIT
            </StyledTabButton>
            <StyledTabButton
              isActive={activeTab === 'options'}
              onClick={() => setActiveTab('options')}
            >
              OPTIONS
            </StyledTabButton>
          </>
        )}
      </StyledTabButtonsContainer>
      {activeTab === 'edit' && (
        <StyledTabContent>{editComponent}</StyledTabContent>
      )}
      {activeTab === 'options' && (
        <StyledTabContent>{optionsComponent}</StyledTabContent>
      )}
    </StyledContainer>
  )
}

export default Tab