import styled, { css } from "styled-components";
import * as theme from "../../../../../../../styles/theme";


export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap:0.5em;
`

export const StyledTabButtonsContainer = styled.div`
    display: flex;
    align-items:center;
    gap:0.5em;

`

export const StyledTabButton = styled.button`
    padding: 0.2em 1em;
    font-family: ${theme.defaultFont};
    font-size: 12px;
    font-weight: 600;
    ${({ isActive }) => isActive && css`
        background-color: white;
        color:${theme.azul};
    `}
`

export const StyledTabContent = styled.div``

