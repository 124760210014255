import React, {useState, useEffect} from "react";
import Header from "../../../components/Layout/Header/Header";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import ServiceList from "../../../components/Services/Business/List";
import {InnerPageLeft} from "../../../components/Layout/Shared/InnerPageLeft";
import {PageStatistics} from "../../../components/Layout/Shared/PageStatistics";
import {InlineText} from "../../../components/Layout/Shared/Paragraph";
import {getServiceList} from "../../../data/Service/Business/getServiceList";
import DataLoading from "../../../components/Layout/Shared/DataLoading";
import {RecentServices} from "../../../components/Services/Business/List/recentServices";

const ServiceListPage = () => {
  const [renderCount, setRenderCount] = useState(0);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getInitialData();
  }, [renderCount]);

  const getInitialData = async () => {
    setIsLoading(true);
    const {data: response, hasSuccess} = await getServiceList();
    hasSuccess
      ? setServices(response)
      : console.log(JSON.parse(response).description);
    setIsLoading(false);
  };

  return (
    <section className="dashboard bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Current Services</PageTitle>
            </PageHeader>
            <div className="row m-2  bg-white border rounded-xl overflow-hidden">
              <div className="page-left order-xl-1 order-lg-2 order-md-2 order-sm-2 p-0 border-end">
                <InnerPageLeft>
                  <RecentServices reRender={renderCount} />
                  <PageStatistics title="SERVICE STATISTICS">
                    <InlineText
                      text="No. of Services"
                      rightValue={services.length}
                    />
                  </PageStatistics>
                </InnerPageLeft>
              </div>
              <div className="page-right order-xl-2 order-lg-1 order-md-1 ps-5 pe-5  pt-3">
                {isLoading ? (
                  <DataLoading />
                ) : (
                  <ServiceList
                    services={services}
                    refreshPage={() => setRenderCount(renderCount + 1)}
                  />
                )}
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
    </section>
  );
};
export default ServiceListPage;
