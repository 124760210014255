import {ServiceDetails} from "./Details";
import {Attachments} from "../Applied/Attachments";
import {ServiceForms} from "../Applied/Forms";
import {Reviews} from "./Reviews";
export const tabs = [
  {
    name: "Service Details",
    component: ServiceDetails,
    isDefault: true,
  },
  {
    name: "Attachments",
    component: Attachments,
    isDefault: true,
    disabled: true,
  },
  {
    name: "Forms",
    component: ServiceForms,
    isDefault: true,
    disabled: true,
  },
  {
    name: "Reviews",
    component: Reviews,
  },
];
export const defaultTab = tabs.find((tab) => tab.isDefault);
