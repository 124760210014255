import React, {useContext} from "react";
import BusinessServiceContext from "../../../../../providers/Service/business";
export const ShortDescription = () => {
  const {service} = useContext(BusinessServiceContext);
  return (
    <div className="mt-5">
      <h4>Description</h4>
      <p>{service.shortDescription}</p>
    </div>
  );
};
