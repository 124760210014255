import React, { useState } from "react";
import ChatSidebarHeader from "./Header/Header.component";
import { StyledSidebarContainer } from "./Sidebar.style";
import ChatSidebarTabs from "./Tabs/Tabs.component";
import NewMessage from "./../NewMessage/NewMessage.component";

const ChatSidebar = ({ tabs, selectedTab, setSelectedTab }) => {
  const [showNewMessage, setShowNewMessage] = useState(false);
  return (
    <StyledSidebarContainer>
      <ChatSidebarHeader
        showNewMessage={showNewMessage}
        setShowNewMessage={setShowNewMessage}
      />
      <ChatSidebarTabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <NewMessage show={showNewMessage} setShow={setShowNewMessage} />
    </StyledSidebarContainer>
  );
};

export default ChatSidebar;
