import styled, { css } from 'styled-components'

export const StyledH1 = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
  ${({ alignment }) => css`
    text-align: ${alignment};
  `}
`

export const StyledH2 = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
  ${({ alignment }) => css`
    text-align: ${alignment};
  `}
`

export const StyledH3 = styled.h3`
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
  ${({ alignment }) => css`
    text-align: ${alignment};
  `}
`
