import React, {useEffect, useState} from "react";
import {InnerPageLeft} from "../../Layout/Shared/InnerPageLeft";
import {PageDetails} from "../../Layout/Shared/PageDetails";
import {PageStatistics} from "../../Layout/Shared/PageStatistics";
import {InlineText} from "../../Layout/Shared/Paragraph";
import {getStatistics} from "../../../data/Contact/Business/statistics";
import {ListLoader} from "../../Layout/Shared/DataLoading";
import {
  CategoryIcon12x12,
  AlertIcon12x12,
  HeartIcon12x12,
  ChartIcon12x12,
} from "../../../assets/icons/icon";
import {useBusinessContactContext} from "../../../providers/Contact/business";

export const Statistics = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [totals, setTotals] = useState([]);
  const {setters, values} = useBusinessContactContext();
  const {setFilter} = setters;
  const {filter} = values;

  const handleOnClick = (statistic) => setFilter(statistic.title);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await getStatistics();
      setTotals({
        contact: data.numberOfContacts,
        groups: data.numberOfGroups,
      });
      if (hasSuccess) {
        setStatistics([
          {
            title: "All Contacts",
            icon: CategoryIcon12x12,
            count: data.numberOfContacts,
            isActive: true,
          },
          {
            title: "Recently Added",
            icon: ChartIcon12x12,
            count: data.numberOfRecentlyAdded,
            isActive: true,
          },
          {
            title: "Favorites",
            icon: HeartIcon12x12,
            count: data.numberOfFavorites,
            isActive: true,
          },
          {
            title: "Pending Invitation",
            icon: AlertIcon12x12,
            count: data.numberOfPendingInvitations,
            isActive: true,
          },
        ]);
      }
      setIsLoading(false);
    };
    init();
  }, []);
  return (
    <InnerPageLeft>
      {isLoading ? (
        <ListLoader height={"500px"} />
      ) : (
        <>
          <PageDetails title="View by Status">
            <ul className="view-by-status ps-5">
              {statistics.map((item, index) => (
                <li
                  key={index}
                  className={filter && item.title === filter ? "active" : ""}
                  onClick={() => handleOnClick(item)}
                >
                  {item.icon} {item.title}
                  <span>{item.count}</span>
                </li>
              ))}
            </ul>
          </PageDetails>
          <PageStatistics title="CONTACT STATISTICS">
            <InlineText text="No. of Contacts" rightValue={totals.contact} />
            <InlineText text="No. of Groups" rightValue={totals.groups} />
          </PageStatistics>
        </>
      )}
    </InnerPageLeft>
  );
};
