import {FormList} from "../../../../Shared/Forms";
const ActionButton = () => {
  return <button className="p-3 ps-5 pe-5  btn-blue">Fill Form</button>;
};
const forms = [
  {
    id: "1",
    name: "Sample form",
    requested: "Mar 15, 2021",
    submitted: <span className="text-danger">Pending</span>,
  },
  {
    id: "2",
    name: "Sample form 2",
    requested: "Mar 15, 2021",
    submitted: "Mar 12, 2021",
  },
  {
    id: "3",
    name: "Post- training /workshop assessment",
    requested: "Mar 15, 2021",
    submitted: <span className="text-danger">Pending</span>,
  },
];
const fields = ["id", "name", "requested", "submitted", {render: ActionButton}];
const headers = [
  {
    displayName: "Id",
    sortingFieldName: "id",
    sortingOptions: {dataType: "number"},
  },
  {
    displayName: "Form Name",
    sortingFieldName: "name",
  },
  {
    displayName: "Requested",
    sortingFieldName: "requested",
  },
  {
    displayName: "Submitted",
    sortingFieldName: "submitted",
  },
  {
    displayName: "",
    sortingFieldName: "action",
  },
];

const options = {
  data: forms || [],
  headers,
  fields,
  itemsPerPage: 25,
  pagination: true,
  search: false,
  sort: true,
};
export const ServiceForms = () => {
  return (
    <div className="p-5 ms-3 ">
      <h4 className="font-weight-bold">Connected Forms</h4>
      <FormList fields={fields} headers={headers} options={options} />
    </div>
  );
};
