/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Modal from "../../../Layout/Shared/Modal";
import "../../../../styles/customComponents/shared.scss";
import "../index.scss";
import { list } from "../../../../data/Contact/Business/list";
import { ListLoader } from "../../../Layout/Shared/DataLoading";
import Table from "../../../Layout/Shared/Table";
import { ScrollContent } from "../../../Layout/Shared/ScrollContent";
import { CustomButton } from "../../../Layout/Shared/Button";

export const ImportClient = ({ onClose, onConfirm, contacts, setContacts }) => {
  const [loading, setLoading] = useState(false);

  const limit = 5;

  const disabled =
    contacts.filter(c => c.isSelected).length === 0 ||
    contacts.filter(c => c.isSelected).length > limit;

  const CheckBox = ({ row }) => (
    <input
      type="checkbox"
      checked={row.isSelected}
      onChange={() => toggleChecked(row._id)}
    />
  );

  const toggleChecked = id => {
    setContacts(
      contacts.map(item => {
        return {
          ...item,
          isSelected: item._id === id ? !item.isSelected : item.isSelected
        };
      })
    );
  };

  const FullName = ({ row }) => `${row.firstName} ${row.lastName}`;

  const fields = [{ render: CheckBox }, { render: FullName }, "email", "phone"];

  const headers = [
    {
      displayName: "",
      sortingFieldName: "isSelected"
    },
    {
      displayName: "Full Name"
    },
    {
      displayName: "Email",
      sortingFieldName: "email"
    },
    {
      displayName: "Phone",
      sortingFieldName: "phone"
    }
  ];

  const options = {
    data: contacts,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    itemsPerPage: 10
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const { hasSuccess, data } = await list();
      if (hasSuccess) setContacts(data);
      setLoading(false);
    };
    init();

    return () => setContacts([]);
  }, []);

  return (
    <Modal
      title="Migrate Client from Contacts"
      width="718px"
      height="700px"
      close={() => onClose()}
    >
      {loading ? (
        <ListLoader height="550px" />
      ) : (
        <div>
          {contacts.filter(c => c.isSelected).length === 0 && (
            <p className="mt-3 mb-5">
              To migrate clients, select a client from your contacts.
            </p>
          )}
          {contacts.filter(c => c.isSelected).length > limit && (
            <p className="mt-3 mb-5">
              You can only migrate a maximum of {limit} clients at a time.
            </p>
          )}
          <ScrollContent height="400px">
            <Table options={options} />
          </ScrollContent>
          <div className="d-flex  align-items-center pt-5 mt-5">
            <button
              onClick={() => onClose()}
              className="btn btn-text-blue me-4"
            >
              Cancel
            </button>
            <CustomButton
              title="Proceed"
              onClick={() => {
                onClose();
                onConfirm();
              }}
              size="160px"
              color="blue"
              disabled={disabled}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
