import * as Yup from "yup";
import {PASSWORD_REGEX} from "../../constants/core.js";

export const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "password is too short")
    .max(50, "password is too long")
    .matches(
      PASSWORD_REGEX,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});
