import React from 'react'
import { FormComponentTypes } from '../../../constants'
import {
  StyledContainer,
  StyledItemContainer,
  StyledItemButton,
  StyledItemIcon,
  StyledItemLabel,
  StyledItemWrapper,
} from './styles'

const TOOLBOX_ITEMS = [
  {
    componentType: FormComponentTypes.Heading,
    label: 'Heading',
    icon: 'bi bi-card-heading',
  },
  {
    componentType: FormComponentTypes.Label,
    label: 'Label',
    icon: 'bi bi-bookmark',
  },
  {
    componentType: FormComponentTypes.InputText,
    label: 'Input Text',
    icon: 'bi bi-file-text',
  },
  {
    componentType: FormComponentTypes.InputNumber,
    label: 'Input Number',
    icon: 'bi bi-123',
  },
  {
    componentType: FormComponentTypes.RadioButton,
    label: 'Radio Button',
    icon: 'bi bi-record-circle',
  },
  {
    componentType: FormComponentTypes.Checkbox,
    label: 'Checkbox',
    icon: 'bi bi-check',
  },
  {
    componentType: FormComponentTypes.Memo,
    label: 'Memo',
    icon: 'bi bi-arrow-down-circle',
  },
  {
    componentType: FormComponentTypes.Attachment,
    label: 'Attachment',
    icon: 'bi bi-paperclip',
  },
  {
    componentType: FormComponentTypes.PageBreak,
    label: 'Page Break',
    icon: 'bi bi-file-break',
  },
  {
    componentType: FormComponentTypes.ESignature,
    label: 'E-Signature',
    icon: 'bi bi-pen',
  },
]

const ToolboxItems = ({ isGrid, onItemClick }) => (
  <StyledContainer className="list-group" isGrid={isGrid}>
    {TOOLBOX_ITEMS.map(({ componentType, icon, label }, index) => (
      <StyledItemContainer key={index} isGrid={isGrid}>
        <StyledItemWrapper
          isGrid={isGrid}
          className="list-group-item list-group-item-action"
        >
          <StyledItemButton
            isGrid={isGrid}
            onClick={() => onItemClick(componentType)}
          >
            <StyledItemIcon className={icon} />
            <StyledItemLabel>{label}</StyledItemLabel>
          </StyledItemButton>
        </StyledItemWrapper>
      </StyledItemContainer>
    ))}
  </StyledContainer>
)

export default ToolboxItems
