import React, {useContext} from "react";
import BusinessServiceContext from "../../../../../providers/Service/business";
import {OwnerDetails} from "../Details/OwnerDetails";
import {Summary} from "../Details/Summary";
import {CommunicationChannels} from "../Details/CommunicationChannels";
import {ServiceSchedules} from "../Details/ServiceSchedules";
import {AvailableLocations} from "../Details/AvailableLocations";
import {Chat} from "./Chat";
import {ShortDescription} from "../Details/Description";
import {Industry} from "../Details/Industry";
const DetailCollaborate = () => {
  const {service} = useContext(BusinessServiceContext);
  const {_id} = service;
  return (
    <div className="row ">
      <div className="col-xl-7 col-lg-7 service-details">
        <div className="row  pt-5 ps-5 pe-5 ">
          <div className="col-xl-12">
            <h2 className="service-id">Service ID: [{_id}]</h2>
          </div>
          <div className="col-xl-6">
            <OwnerDetails />
          </div>
          <div className="col-xl-6">
            <ShortDescription />
          </div>
        </div>
        <div className="row  ps-5 pe-5 ">
          <div className="col-xl-6 col-md-6 col-6">
            <CommunicationChannels />
          </div>
          <div className="col-xl-6 col-lg-12">
            <Summary />
          </div>
        </div>
        <div className="row ps-5 pe-5 ">
          <div className="col-xl-6 col-md-6 col-6">
            <AvailableLocations />
            <Industry />
          </div>
          <div className="col-xl-6 col-lg-12">
            <ServiceSchedules />
          </div>
        </div>
      </div>
      <div className="col-xl-5 col-lg-5 border-left pl-0">
        <Chat />
      </div>
    </div>
  );
};
export default DetailCollaborate;
