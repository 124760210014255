import { chatsApiHostName } from "../endpoint";
import { defaultHeaders } from "../headers";

export const createChat = async (participants, groupName) => {
  try {
    const result = await fetch(`${chatsApiHostName}/chats`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify({ participants, groupName })
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
