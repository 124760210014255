import React from 'react'
import {
  StyledContainer,
  StyledInput,
  StyledLabel,
  StyledOptionItemsWrapper,
  StyledText,
} from './styles'
import { StyledComponentTitle, StyledRadiusTextarea } from '../../styles'
import ValidationText from '../../../Builder/Body/Components/Shared/ValidationText'

const Checkbox = ({
  onChange,
  component: { options, validations },
  isReadOnly,
}) => {
  const { value, optionItems } = options
  const inputName = new Date().getTime()

  const handleOnOptionChange = (e) => {
    let values = options?.submission?.values || []
    if (e.target.checked && !values.includes(e.target.value)) {
      values.push(e.target.value)
    } else {
      values = values.filter((value) => value !== e.target.value)
    }
    onChange({
      ...options,
      submission: {
        ...options?.submission,
        values,
      },
    })
  }

  const handleOnCommentChange = (e) => {
    onChange({
      ...options,
      submission: {
        ...options?.submission,
        comment: e.target.value,
      },
    })
  }

  return (
    <StyledContainer>
      <StyledComponentTitle>{value}</StyledComponentTitle>
      <StyledOptionItemsWrapper>
        {optionItems.map((optionItem, index) => (
          <StyledLabel key={index}>
            <StyledInput
              key={index}
              type="checkbox"
              disabled={isReadOnly}
              name={inputName}
              checked={
                options?.submission?.values?.includes(optionItem) || false
              }
              onChange={handleOnOptionChange}
              value={optionItem}
            />
            <StyledText>{optionItem}</StyledText>
          </StyledLabel>
        ))}
      </StyledOptionItemsWrapper>
      {validations?.values && (
        <ValidationText>{validations?.values}</ValidationText>
      )}
      <StyledRadiusTextarea
        className="form-control"
        rows={2}
        readOnly={isReadOnly}
        placeholder="Enter your comment"
        value={options?.submission?.comment || ''}
        onChange={handleOnCommentChange}
      />
    </StyledContainer>
  )
}

export default Checkbox
