import styled from "styled-components";
import { PRIORITY, STATUS, PRIVATE } from "../../../constants/case-management";

const StyledBadgeBase = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  width: 92px;
  height: 32px;
  line-height: 32px;
  &:hover {
    opacity: 0.8;
  }
`;

const getStyles = (items, value) => {
  const item = items.find(item => item.value === value);
  return item ? item.styleProps : {};
};

export const StyledStatusBadge = styled(StyledBadgeBase)`
  background-color: ${({ status }) =>
    getStyles(STATUS, status).checkedBackgroundColor};
  color: ${({ status }) => getStyles(STATUS, status).checkedColor};
  border: ${({ status }) => getStyles(STATUS, status).checkedBorder};
`;

export const StyledPriorityBadge = styled(StyledBadgeBase)`
  background-color: ${({ priority }) =>
    getStyles(PRIORITY, priority).checkedBackgroundColor};
  color: ${({ priority }) => getStyles(PRIORITY, priority).checkedColor};
  border: ${({ priority }) => getStyles(PRIORITY, priority).checkedBorder};
`;

const getCancelledStyles = cancelled => {
  const styles = getStyles(PRIVATE, cancelled ? "No" : "Yes");
  return {
    color: styles.checkedColor,
    backgroundColor: styles.checkedBackgroundColor,
    border: styles.checkedBorder
  };
};

export const StyledCancelledBadge = styled(StyledBadgeBase)`
  background-color: ${({ cancelled }) =>
    getCancelledStyles(cancelled).backgroundColor};
  color: ${({ cancelled }) => getCancelledStyles(cancelled).color};
  border: ${({ cancelled }) => getCancelledStyles(cancelled).border};
`;
