import styled from 'styled-components'
import * as theme from '../../../styles/theme'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
`

export const StyledTitle = styled.h1`
  font-size: 23px;
  font-weight: 600;
  margin: 0;
`

export const StyledRadiusInput = styled.input`
  border-radius: 25px 25px 25px 25px;
  padding: 1rem 1.75rem 1rem 1.75rem;
  height: 32px !important;
  font-size: 15px;
  box-shadow: 0 4px 6px 0 rgb(167 190 255 / 37%);

  &::placeholder {
    color: #b7bdc3;
    font-family: ${theme.defaultFont};
  }
  &:read-only {
    background-color: #f5f5f5;
  }
`

export const StyledRadiusTextarea = styled.textarea`
  border-radius: 25px 25px 25px 25px;
  padding: 1rem 1.75rem 1rem 1.75rem;
  font-size: 15px;
  box-shadow: 0 4px 6px 0 rgb(167 190 255 / 37%);

  &::placeholder {
    color: #b7bdc3;
    font-family: ${theme.defaultFont};
  }

  &:read-only {
    background-color: #f5f5f5;
  }
`

export const StyledComponentTitle = styled.span`
  display: block;
  color: ${theme.azul};
  font-size: 13px;
  font-weight: bold;
  font-family: ${theme.defaultFont};
  margin-bottom: 0.5em;
`

export const StyledSubmitButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 2em;
`

export const StyledSubmitButton = styled.button`
  color: white;
  background-image: linear-gradient(to right, ${theme.azul}, #1515af 100%);
  border: solid 1px #dee2e6;
  border-radius: 26px;
  width: 120px;
  height: 47px;
  font-size: 13px;
  font-weight: 700;
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`
