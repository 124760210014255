import Summary from "./Summary";
import Notes from "./Notes";
import Attachments from "./Attachments";
import Forms from "./Forms";
import Tasks from "./Tasks";

export const tabs = [
  {
    name: "Summary",
    component: Summary,
    default: true,
    rights: ["new", "edit", "view"]
  },
  {
    name: "Attachments",
    component: Attachments,
    rights: ["edit", "view"]
  },
  {
    name: "Forms",
    component: Forms,
    rights: ["edit", "view"]
  },
  {
    name: "Tasks",
    component: Tasks,
    rights: ["edit", "view"]
  },
  {
    name: "Notes",
    component: Notes,
    rights: ["new", "edit", "view"]
  }
];

export const defaultTab = tabs.find(({ default: isDefault }) => isDefault);
