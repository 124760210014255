import {formsApiHostName} from "../endpoint";
import {defaultHeaders} from "../headers";

export const createNewForm = async (model) => {
  try {
    const result = await fetch(`${formsApiHostName}/forms`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(model),
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    };
  }
};
