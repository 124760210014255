import * as Yup from "yup";

export const StaffRegistrationAddress = Yup.object().shape({
  address: Yup.string()
    .min(5, "Address is too Short!")
    .max(50, "Address is too Long!")
    .required("Address is required"),
  city: Yup.string()
    .min(3, "City is too Short!")
    .max(15, "City is too Long!")
    .required("City is required"),
  state: Yup.string()
    .min(3, "State is too Short!")
    .max(15, "State is too Long!")
    .required("State is required"),
  country: Yup.string()
    .min(2, "Country is too Short!")
    .max(60, "Country is too Long!")
    .required("Country is required"),
});
