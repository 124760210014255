import {hostName} from "../endpoint";
import {defaultHeaders} from "../headers";

export const sendStaffEmail = async (email) => {
  try {
    const result = await fetch(`${hostName}/business/reset-staff-password`, {
      method: "POST",
      headers: defaultHeaders,
      body: JSON.stringify(email),
    });
    const response = await result.json();
    return response.isSuccessful;
  } catch (err) {
    return false;
  }
};