import React, { useState } from "react";
import { useChatContext } from "../../providers/Chat";
import Chat from "./Chat/Chat.component";
import { StyledChatContainer } from "./Messenger.style";
import ChatSidebar from "./Sidebar/Sidebar.component";

export const tabs = [
  {
    name: "chats",
    icon: "StatsMessage"
  },
  {
    name: "invite",
    icon: "StatsGroup"
  }
];

const Messenger = () => {
  const [selectedTab, setSelectedTab] = useState("chats"); // "chats" or "invite
  const { selectedChat } = useChatContext();

  return (
    <>
      <StyledChatContainer>
        <ChatSidebar
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {selectedChat && <Chat chat={selectedChat} />}
      </StyledChatContainer>
    </>
  );
};

export default Messenger;
