import React from "react";
import "../styles/pages/login.scss";
import {ResetPassword} from "../components/ForgotPassword/ResetPassword";
import LearnMore from "../components/Login/LearnMore";

const ForgotPasswordPage = () => (
  <div>
    <div className="container-fluid sign-in">
      <div className="row d-md-flex">
        <LearnMore />

        <div className="col-lg-6 right order-1 order-md-2 order-lg-2 d-flex justify-content-center align-items-center">
          <div className="box">
            <h2>
              Forgot your password? <small>Just reset it</small>
            </h2>
            <div className="row">
              <div className="col-sm-12">
                <ResetPassword />
              </div>
              <div className="col-sm-12 pt-5 mt-5">
                Remember it now? <a href="/login"> Sign In </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ForgotPasswordPage;
