import {v4 as uuidv4} from "uuid";

import {MoveDirection} from "./FormEditReducerTypes";
import {
  changeComponentAttribute,
  clone,
  getAttribute,
  getComponent,
  sortComponents,
} from "./FormEditReducerHelpers";
import {
  dummyValidator,
  setFormValid,
  validateESignaturePolicyCheck,
  validateHeading,
  validateTitle,
} from "./FormEditValidation";
import {createItem} from "../Data/Data";

function getWithout(items, index) {
  return [...items.slice(0, index), ...items.slice(index + 1)];
}

export function formEditReducer(state, action) {
  switch (action.type) {
    case "title_change":
      return setFormValid({
        ...state,
        form_header: {
          ...state.form_header,
          title: action.title,
          valid: validateTitle(action.title),
        },
        formSaved: false,
      });
    case "heading_title_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "title",
        action.title,
        validateHeading
      );
    }
    case "heading_dropdown_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "selected_type",
        action.value
      );
    }
    case "heading_dropdown_alignment_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "selected_alignment",
        action.value
      );
    }
    case "label_title_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "title",
        action.title,
        dummyValidator
      );
    }
    case "input_text_question_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "question",
        action.question,
        dummyValidator
      );
    }
    case "input_text_title_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "title",
        action.title,
        dummyValidator
      );
    }
    case "placeholder_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "placeholder",
        action.placeholder
      );
    }
    case "input_number_question_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "question",
        action.question,
        dummyValidator
      );
    }
    case "input_number_title_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "title",
        action.title,
        dummyValidator
      );
    }
    case "require_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "require",
        action.require
      );
    }
    case "require_text_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "require_text",
        action.require_text,
        dummyValidator
      );
    }
    case "checkbox_question_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "question",
        action.question,
        dummyValidator
      );
    }
    case "radio_button_question_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "question",
        action.question,
        dummyValidator
      );
    }
    case "base_confirm": {
      return changeComponentAttribute(
        state,
        action.key,
        "confirmed",
        action.value
      );
    }
    case "kpi_text_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "kpi",
        action.kpi,
        dummyValidator
      );
    }
    case "baseline_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "baseline",
        action.baseline
      );
    }
    case "qualitative_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "qualitative",
        action.qualitative
      );
    }
    case "set_state": {
      return action.state;
    }
    case "base_hover": {
      return changeComponentAttribute(state, action.key, "hover", action.value);
    }
    case "column_size_dropdown_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "selected_column_size",
        action.value
      );
    }
    case "radio_button_add_option":
    case "checkbox_add_option": {
      const options = getAttribute(
        "options",
        getComponent(action.key, state.components).component.attributes
      );
      if (
        action.option.length <= 0 ||
        options.filter((option) => option === action.option).length > 0
      ) {
        return state;
      }
      return changeComponentAttribute(
        changeComponentAttribute(
          state,
          action.key,
          "options",
          options.concat(action.option)
        ),
        action.key,
        "option_entry_title",
        ""
      );
    }
    case "radio_button_change_option":
    case "checkbox_change_option": {
      const options = getAttribute(
        "options",
        getComponent(action.key, state.components).component.attributes
      );
      return changeComponentAttribute(
        state,
        action.key,
        "options",
        options.map((option, i) =>
          option === action.option && i === action.index
            ? action.new_option
            : option
        )
      );
    }
    case "radio_button_delete_option":
    case "checkbox_delete_option": {
      const options = getAttribute(
        "options",
        getComponent(action.key, state.components).component.attributes
      );
      return changeComponentAttribute(
        state,
        action.key,
        "options",
        options.filter((option) => option !== action.option)
      );
    }
    case "radio_button_change_option_entry_title":
    case "checkbox_change_option_entry_title": {
      return changeComponentAttribute(
        state,
        action.key,
        "option_entry_title",
        action.title,
        dummyValidator
      );
    }
    case "radio_button_checked_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "checked",
        action.checked ? action.option : ""
      );
    }
    case "checkbox_checked_change": {
      const checked = getAttribute(
        "checked",
        getComponent(action.key, state.components).component.attributes
      );
      return changeComponentAttribute(
        state,
        action.key,
        "checked",
        action.checked
          ? checked.concat(action.option)
          : checked.filter((o) => o !== action.option)
      );
    }
    case "radio_button_change_comment":
    case "checkbox_change_comment": {
      return changeComponentAttribute(
        state,
        action.key,
        "comment",
        action.comment,
        dummyValidator
      );
    }
    case "delete_component": {
      const foundComponent = getComponent(action.key, state.components);
      const componentToDelete = foundComponent.component;
      if (state.components.length > foundComponent.index + 1) {
        const belowComponent = state.components[foundComponent.index + 1];
        state = changeComponentAttribute(
          state,
          belowComponent.key,
          "hover",
          "true"
        );
      }
      return {
        ...state,
        components: state.components.filter((obj) => obj !== componentToDelete),
        formSaved: false,
      };
    }
    case "clone_component": {
      const componentToClone = getComponent(action.key, state.components);
      const clonedComponent = clone(componentToClone.component);
      clonedComponent.key += "_" + uuidv4();
      const new_components = state.components.slice();
      new_components.push(clonedComponent);
      return {...state, components: new_components, formSaved: false};
    }
    case "form_saved": {
      return {...state, formSaved: action.value};
    }
    case "move_component": {
      const sortedComponents = sortComponents(state.components);
      const componentToMove = getComponent(action.key, sortedComponents);
      for (let i = 0; i < sortedComponents.length; i++) {
        sortedComponents[i].order = i + 1;
      }
      const index = componentToMove.index;
      if (action.value === MoveDirection.UP) {
        if (index > 0) {
          const tmp = sortedComponents[index].order;
          sortedComponents[index].order = sortedComponents[index - 1].order;
          sortedComponents[index - 1].order = tmp;
        }
      } else {
        if (index < sortedComponents.length - 1) {
          const tmp = sortedComponents[index].order;
          sortedComponents[index].order = sortedComponents[index + 1].order;
          sortedComponents[index + 1].order = tmp;
        }
      }
      const new_components = sortedComponents.slice();
      return {...state, components: new_components, formSaved: false};
    }
    case "toolbox_item_click": {
      const new_components = state.components.slice();
      const newComponent = createItem(action.value, new_components.length);
      new_components.push(newComponent);
      return {...state, components: new_components, formValid: false};
    }
    case "textarea_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "title",
        "",
        dummyValidator,
        action.title
      );
    }
    case "esig_designation_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "designation",
        action.value,
        dummyValidator
      );
    }
    case "esig_name_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "name",
        action.value,
        dummyValidator
      );
    }
    case "esig_policy_checked_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "policy_checked",
        action.value,
        validateESignaturePolicyCheck
      );
    }
    case "esig_input_mode_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "input_mode",
        action.value,
        dummyValidator
      );
    }
    case "esig_base64_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "base64",
        action.value,
        dummyValidator
      );
    }
    case "esig_text_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "text",
        action.value,
        dummyValidator
      );
    }
    case "esig_me_checked_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "me_checked",
        action.value,
        dummyValidator
      );
    }
    case "esig_others_checked_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "others_checked",
        action.value,
        dummyValidator
      );
    }
    case "esig_add_assignee": {
      const attributes = getComponent(action.key, state.components).component
        .attributes;
      const assignees_ids = getAttribute("assignees_ids", attributes);
      if (
        assignees_ids.filter((assignee_id) => assignee_id === action.id)
          .length > 0
      ) {
        return state;
      }
      const assignees_emails = getAttribute("assignees_emails", attributes);
      return changeComponentAttribute(
        changeComponentAttribute(
          state,
          action.key,
          "assignees_ids",
          assignees_ids.concat(action.id)
        ),
        action.key,
        "assignees_emails",
        assignees_emails.concat(action.email),
        dummyValidator
      );
    }
    case "esig_remove_assignee": {
      const attributes = getComponent(action.key, state.components).component
        .attributes;
      const assignees_ids = getAttribute("assignees_ids", attributes);
      const assignees_emails = getAttribute("assignees_emails", attributes);
      const index = assignees_ids.indexOf(action.id);
      if (index === -1) {
        return state;
      }
      return changeComponentAttribute(
        changeComponentAttribute(
          state,
          action.key,
          "assignees_ids",
          getWithout(assignees_ids, index),
          dummyValidator
        ),
        action.key,
        "assignees_emails",
        getWithout(assignees_emails, index),
        dummyValidator
      );
    }
    case "esig_clear_assignees": {
      return changeComponentAttribute(
        changeComponentAttribute(state, action.key, "assignees_ids", []),
        action.key,
        "assignees_emails",
        [],
        dummyValidator
      );
    }
    case "esig_show_assignees_checked_change": {
      return changeComponentAttribute(
        state,
        action.key,
        "show_assignees_checked",
        action.value,
        dummyValidator
      );
    }
    default:
      throw new Error();
  }
}
