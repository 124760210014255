import React from "react";
import {Link} from "react-router-dom";
import {StyledSearchItem, StyledSearchList} from "./search.style";

const Search = ({search}) => {
  return (
    <StyledSearchList>
      {search
        .filter((item) => item.link !== undefined)
        .map((item) => (
          <StyledSearchItem>
            <Link to={item.link}>{item.name}</Link>
          </StyledSearchItem>
        ))}
    </StyledSearchList>
  );
};

export default Search;
