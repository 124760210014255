import React, {useState} from "react";
import { useParams, useHistory } from "react-router";
import Header from "../../../components/Layout/Header/Header";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {InnerPageLeft} from "../../../components/Layout/Shared/InnerPageLeft";
import {TabContainer} from "../../../components/Services/Client/Service/Detail/TabContainer";
import {CompanyProfile} from "../../../components/Services/Client/FindService/CompanyProfile";
import DefaultBusinessLogo from "../../../assets/images/default-business-image.png";
import {ServiceList} from "../../../components/Layout/Shared/ServiceList";
import {ButtonBack} from "../../../components/Layout/Shared/Button/ButtonBack";
import {CongratulationsModal} from "../../../components/Services/Client/Service/Dialogs/Congratulations";
import {ErrorModal} from "../../../components/Services/Client/Service/Dialogs/Error";

const companyData = {
  logo: DefaultBusinessLogo,
  companyName: "Webron Software",
  rating: "4",
  ratingCount: "215",
  city: "Ottawa",
  country: "Canada",
  companyStatus: true,
  distance: "250",
  timeOpen: "Mon-Fri, 9:00 AM",
  timeClose: "6:00 PM",
  languages: ["English", "German"],
};

const ServiceDetails = () => {
  const { id } = useParams();
  const { forms } = useHistory().location?.state?.relevant;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  
  return (
    <section className="dashboard bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>FIND SERVICE</PageTitle>
            </PageHeader>
            <div className="row m-2  bg-white border rounded-xl overflow-hidden">
              <div
                className="page-left order-xl-1 order-lg-2 order-md-2 order-sm-2 p-0 border-end"
                style={{width: "32%"}}
              >
                <InnerPageLeft>
                  <div className="ps-5 pt-4 pb-3">
                    <ButtonBack
                      title="Back"
                      size="112px"
                      onClick={() => console.log("back button clicked")}
                    />
                  </div>
                  <div className="ps-5 mt-5 mb-5">
                    <CompanyProfile data={companyData} />
                  </div>
                  <hr />
                  <div className="ps-5 pe-5  mt-5 mb-5">
                    <div className="title-lg font-weight-bold mb-4">
                      Our Services
                    </div>
                    <ServiceList />
                  </div>
                </InnerPageLeft>
              </div>
              <div
                className="page-right order-xl-2 order-lg-1 order-md-1 pt-3"
                style={{width: "65%"}}
              >
                <TabContainer forms={forms} id={id} />
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
      <div className="d-flex justify-content-end pe-5 ">
        <button
          className="btn-blue btn-x-small me-5 "
          onClick={() => setShowSuccess(true)}
        >
          Success
        </button>
        <button
          className="btn-blue btn-x-small"
          onClick={() => setShowError(true)}
        >
          Error
        </button>

        {showSuccess && (
          <CongratulationsModal
            onClose={() => setShowSuccess(false)}
            heading="Congratulations!"
            email="jane.smith@gmail.com"
          />
        )}
        {showError && (
          <ErrorModal
            heading="Sorry! Something got wrong."
            onClose={() => setShowError(false)}
          />
        )}
      </div>
    </section>
  );
};

export default ServiceDetails;
