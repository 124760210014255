import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../styles/customComponents/genericTabs.scss";
import { StyledTemplateCategoriesContainer } from "../../Form/Templates/templates.style";
import { defaultFormTab, tabs } from "../Tabs";

export const TabContainer = () => {
const {pathname} = useLocation();
const getFormTypeFromUrl = pathname.split("/").at(-1)
const filteredTab =  getFormTypeFromUrl  ? tabs.find(tab => tab.key === getFormTypeFromUrl) : defaultFormTab;

return (
    <StyledTemplateCategoriesContainer>
      <ul className="generic-page-tab ">
        {tabs.map((tab) => (
          <li
            key={tab.name}
            className={filteredTab.key === tab.key ? "active" : ""}
          >
            <Link to={`/business/forms/submissions${tab.key === "submissions" ? '' : `/${tab.key}`}`}>{tab.name}</Link>
          </li>
        ))}
      </ul>
    
      <section>{filteredTab.component}</section>
      </StyledTemplateCategoriesContainer>
  );
};
