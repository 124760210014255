import styled, {css} from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  padding: 3em 2em 1em;
`;

export const StyledWrapper = styled.div`
  width: 100%;
  border-radius: 6px;
  border: solid 1px #dee2e6;
`;

export const StyledHeader = styled.header`
  height: 40px;
  border-bottom: solid 1px #dee2e6;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
`;
export const StyledHeaderDotContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7em;
  padding-left: 2em;
`;

export const StyledHeaderDot = styled.span`
  color: #dee2e6;
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
`;

export const StyledContent = styled.div``;

export const StyledComponentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
`;
export const StyledSubmittedText = styled.p`
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

export const StyledComponentWrapper = styled.div`
  margin: 1rem 0;
  ${({isVisible}) =>
    !isVisible &&
    css`
      visibility: hidden;
      opacity: 0;
      position: absolute;
      zindex: -1;
    `}
`;
