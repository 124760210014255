import React, {Fragment} from "react";
import RichTextEditor from "react-rte";
import {getTextAlignStyles} from "react-rte/lib/lib/blockStyleFunctions";
import {getEditorAttribute} from "../../FormEdit/FormEditReducerHelpers";

export function Textarea({dispatch, attributes, component_key}) {
  let title = getEditorAttribute("title", attributes);
  if (typeof title == "string") {
    title = RichTextEditor.createValueFromString(title, "html");
  }
  return (
    <Fragment>
      <div
        dangerouslySetInnerHTML={{
          __html: title.toString("html", {blockStyleFn: getTextAlignStyles}),
        }}
      />
    </Fragment>
  );
}

export default Textarea;
