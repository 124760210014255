import ReactStars from "react-rating-stars-component";

export const ClientServiceRating = ({rating, count}) => {
  return (
    <div className="d-flex justify-content-start align-items-center">
      <ReactStars value={rating} size={18} />
      <span className="ms-4 ">{count}</span>
    </div>
  );
};
