import {hostName} from "../../endpoint";
import {defaultHeaders} from "../../headers";

export const uploadImage = async (file) => {
  try {
    const result = await fetch(`${hostName}/file`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(file),
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    };
  }
};
