import React from "react";
import Table from "../../../Layout/Shared/Table";
import { FormattedDateTime } from "../../../Layout/Shared/FormattedDate";
import { ActionButtons } from "./actions";
import "./index.scss";

const TypeList = ({ types, setType, setShowFormModal, refresh }) => {
  const fields = [
    "title",
    "description",
    { render: ({ row }) => <FormattedDateTime date={row.createdAt} /> },
    { render: ({ row }) => <FormattedDateTime date={row.updatedAt} /> },
    {
      render: ({ row }) => (
        <ActionButtons
          type={row}
          setType={setType}
          setShowFormModal={setShowFormModal}
          refresh={refresh}
        />
      )
    }
  ];

  const headers = [
    {
      displayName: "Case Type Title",
      sortingFieldName: "title"
    },
    {
      displayName: "Case Type Description",
      sortingFieldName: "description"
    },
    {
      displayName: "Date Created",
      sortingFieldName: "createdAt",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Last Modified",
      sortingFieldName: "updatedAt",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];

  const options = {
    data: types,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    headerAction: () => <p></p>
  };

  return (
    <div className="service-list">
      <Table options={options} />
    </div>
  );
};

export default TypeList;
