import React, {useState, useEffect} from "react";
import Modal from "../../../../../Layout/Shared/Modal";
import {CustomButton} from "../../../../../Layout/Shared/Button";
import ErrorField from "../../../../../Layout/Shared/ErrorField";
import "./index.scss";
import {ScrollContent} from "../../../../../Layout/Shared/ScrollContent";
import {ListLoader} from "../../../../../Layout/Shared/DataLoading";
import {get as getRoles} from ".././../../../../../data/Settings/Business/Team/getRoles";
import {get as getGroups} from ".././../../../../../data/Settings/Business/Team/getGroups";
import {createStaffList} from ".././../../../../../data/Settings/Business/Team/createStaffList";
import {BusinessTeamInviteSchema} from "../../../.././../../schemas/TeamSettings/business";
import {NewStaff} from "./newStaff";

const initialStaffs = [
  {
    _id: 1,
    email: "",
    role: "-1",
    group: "-1",
  },
  {
    _id: 2,
    email: "",
    role: "-1",
    group: "-1",
  },
  {
    _id: 3,
    email: "",
    role: "-1",
    group: "-1",
  },
];

export const AddStaff = ({onClose, refresh}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [staffs, setStaffs] = useState(initialStaffs);
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);
  const [isEmailInUse, setIsEmailInUse] = useState(false);

  const emailRegistered = (data) => {
    setIsEmailInUse(data)
  }
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess: hasSuccessRoles, data: _roles} = await getRoles();
      if (hasSuccessRoles) {
        const rolesAsArray = Object.values(_roles);
        setRoles(rolesAsArray);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess: hasSuccessGroups, data: _groups} = await getGroups();
      if (hasSuccessGroups) {
        const groupsAsArray = Object.values(_groups);
        setGroups(groupsAsArray);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const getNextId = () => staffs[staffs.length - 1]._id + 1;
  const isFirstItem = (id) => id === 1;
  const setValue = (id, key, value) =>
    setStaffs(
      staffs.map((staff) =>
        id === staff._id
          ? {
              ...staff,
              [key]: value,
            }
          : staff
      )
    );

  useEffect(() => {
    const hasError = staffs.some(
      (staff) =>
        staffs.filter(
          (filterStaff) =>
            filterStaff.email !== "" && filterStaff.email === staff.email
        ).length > 1
    );
    if (hasError) {
      setError("Each staff should have a unique email address.");
    } else {
      setError(null);
    }
    return () => {};
  }, [staffs]);

  const validateForm = async (form) => {
    try {
      await BusinessTeamInviteSchema.validate(form);
    } catch (error) {
      return error;
    }
  };

  const handleSubmit = async () => {
    const createList = staffs
      .filter((staff) => staff.email !== "")
      .map((staff) => ({
        ...staff,
        _id: null,
        group: staff.group !== "-1" ? staff.group : null,
      }));

    const validationResponse = await validateForm({staffs: createList});
    if (validationResponse && validationResponse.errors) {
      setError(validationResponse.errors.shift());
      return;
    } else {
      setError(null);
    }
    if (isEmailInUse) {
      setError('Email is already in use')
    } else {
      setIsLoading(true);
      const {hasSuccess} = await createStaffList(createList);
      if (hasSuccess) {
        onClose();
        setIsLoading(false);
        refresh && refresh();
     }
    }
  };

  return (
    <>
      <Modal
        title="Add Staff"
        width="920px"
        height="460px"
        close={onClose}
      >
        {isLoading ? (
          <ListLoader height="275px" />
        ) : (
          <>
            <ScrollContent height="calc(100vh - 300px)">
              <>
                {staffs.map(({_id}) => (
                  <NewStaff
                    id={_id}
                    key={_id}
                    showHeaders={isFirstItem(_id)}
                    setValue={setValue}
                    roles={roles}
                    groups={groups}
                    isInUse={emailRegistered}
                  />
                ))}
                {error && <ErrorField message={error} />}
              </>
            </ScrollContent>

            <div className="d-flex pt-5 pb-3 align-items-center">
              <div className="flex-grow-1">
                <button
                  onClick={() =>
                    setStaffs([
                      ...staffs,
                      {_id: getNextId(), email: "", role: "-1", group: "-1"},
                    ])
                  }
                  className="btn btn-text-blue me-5 "
                >
                  Add More Staff
                </button>
              </div>
              <button className="btn btn-text-blue me-5 " onClick={onClose}>
                Cancel
              </button>
              <CustomButton
                color="blue"
                title="Invite Staff"
                size="160px"
                type="submit"
                onClick={handleSubmit}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
