/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import { GenericPageLeft } from "../../../components/Layout/Shared/GenericPageLeft";
import Header from "../../../components/Layout/Header/Header";
import { PageContainer } from "../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../components/Layout/Shared/PageTitle";
import DataLoading from "../../../components/Layout/Shared/DataLoading";
import { CustomButton } from "../../../components/Layout/Shared/Button";
import { createService } from "../../../data/Service/Business/createService";
import CreateBusinessServiceSchema from "../../../schemas/Services/Business/create";
import ErrorField from "../../../components/Layout/Shared/ErrorField";
import MultipleCitySelection from "../../../components/Layout/Shared/MultipleCitySelection";
import { GlobalContext } from "../../../providers/global";
import { getProfileInfo } from "../../../data/ProfileSettings/Business/getProfileInfo";
import { GlobalInfo } from "../../../components/Layout/Shared/Modal/Info";
import { DEFAULT_SELECTION_VALUE } from "../../../utils/string";
import "./createService.scss";
import IconInfoBlue from "../../../assets/images/icon-info-72.png";
import { ServiceTypeSelect } from "../../../components/Services/Business/Shared";

const CreateService = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [serviceDetails, setServiceDetails] = useState({
    name: "",
    owner: "",
    email: "",
    phone: "",
    industry: DEFAULT_SELECTION_VALUE
  });
  const [isSaving, setIsSaving] = useState(false);
  const [availableLocations, setAvailableLocations] = useState([]);
  const { industries, setIndustries } = useContext(GlobalContext);
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const goToProfileSettings = () => history.push("/business/settings/profile");
  const goToServiceUpdate = id =>
    history.push(`/business/service/update/${id}`);
  const isIndustriesEmpty = () => industries && industries.length === 0;

  const constructor = async () => {
    if (constructorHasRun) return;
    if (!industries) await loadIndustries();
    if (isIndustriesEmpty()) setShowInfo(true);
    setConstructorHasRun(true);
  };

  const loadIndustries = async () => {
    const { hasSuccess, data } = await getProfileInfo();
    if (!hasSuccess) return;
    const { details } = data;
    setIndustries(details.industries);
  };

  const validateForm = async form => {
    try {
      await CreateBusinessServiceSchema.validate(form);
    } catch (error) {
      return error;
    }
  };

  const save = async () => {
    const validationResponse = await validateForm(serviceDetails);
    if (validationResponse && validationResponse.errors) {
      setError(validationResponse.errors.shift());
      return;
    }
    setIsSaving(true);
    const { hasSuccess, data } = await createService({
      ...serviceDetails,
      availableLocations
    });
    if (hasSuccess) {
      goToServiceUpdate(data._id);
    } else {
      console.log(JSON.parse(data).description);
    }
    setIsSaving(false);
  };

  constructor();
  return (
    <>
      <section className="dashboard  bg-paleGray pb-5 pr-3">
        <Header />
        <div className="container-fluid mt-5 pt-3">
          <div className="row">
            <GenericPageLeft />
            <PageContainer>
              <PageHeader>
                <PageTitle>Create Service</PageTitle>
                <div className="d-flex justify-content-end align-items-center"></div>
              </PageHeader>
              <div className="row m-2  bg-white border rounded-xl create-service-main">
                {isSaving ? (
                  <DataLoading height="500px" />
                ) : (
                  <>
                    <div className="col-xl-3 col-lg-12 p-0 border-end">
                      <Formik initialValues={serviceDetails}>
                        {({ values }) => (
                          <Form>
                            {setServiceDetails(values)}
                            <div className="create-service-form border-bottom">
                              <div className="form">
                                <div className="form-group">
                                  <Field
                                    className="custom-input"
                                    placeholder="Service Name"
                                    name="name"
                                  />
                                </div>
                                <div className="form-group">
                                  <Field
                                    className="custom-input"
                                    placeholder="Service Owner"
                                    name="owner"
                                  />
                                </div>
                                <div className="form-group">
                                  <Field
                                    className="custom-input"
                                    placeholder="Email Address"
                                    name="email"
                                  />
                                </div>

                                <div className="form-group  custom-phone">
                                  <Field name="phone">
                                    {({
                                      field: { value },
                                      form: { setFieldValue }
                                    }) => (
                                      <PhoneInput
                                        enableSearch
                                        placeholder="Phone Number"
                                        country={"ca"}
                                        value={value}
                                        onChange={number =>
                                          setFieldValue("phone", number)
                                        }
                                      />
                                    )}
                                  </Field>
                                </div>

                                <div className="form-group">
                                  <Field name="industry">
                                    {({
                                      field: { value },
                                      form: { setFieldValue }
                                    }) => (
                                      <select
                                        className="primary-select"
                                        placeholder="Select Services Category"
                                        value={value}
                                        onChange={({ target }) =>
                                          setFieldValue(
                                            "industry",
                                            target.value
                                          )
                                        }
                                      >
                                        <option value={DEFAULT_SELECTION_VALUE}>
                                          Select Services Category
                                        </option>
                                        {!industries
                                          ? []
                                          : industries.map((option, index) => (
                                              <Fragment key={index}>
                                                <option value={option}>
                                                  {option}
                                                </option>
                                              </Fragment>
                                            ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>

                                <div className="form-group">
                                  <Field name="type">
                                    {({
                                      field: { value },
                                      form: { setFieldValue }
                                    }) => (
                                      <ServiceTypeSelect
                                        type={value}
                                        onChange={value =>
                                          setFieldValue("type", value)
                                        }
                                      />
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                      {error && <ErrorField message={JSON.stringify(error)} />}
                      <div className="create-service-form">
                        <MultipleCitySelection
                          locations={availableLocations}
                          setLocations={setAvailableLocations}
                        />
                      </div>
                      <div className="ps-5 ms-4  pb-5">
                        <CustomButton
                          color="blue"
                          title="Save"
                          size="160px"
                          onClick={() => save()}
                        />
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-12 p-0 img-bg">
                      <div className="info-box">
                        <img src={IconInfoBlue} alt="" />
                        <span>
                          CREATE YOUR SERVICES HERE, AND TELL US MORE ON THE
                          NEXT PAGE
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </PageContainer>
          </div>
        </div>
      </section>
      {showInfo && (
        <GlobalInfo
          heading="Info"
          message="The business has not any Services Category so select at least one in order to create service. You are redirecting to Profile Settings."
          onClose={goToProfileSettings}
          showCloseButton={
            <button
              className="btn-blue btn-small mt-2"
              onClick={goToProfileSettings}
            >
              Go to profile settings
            </button>
          }
        />
      )}
    </>
  );
};
export default CreateService;
