import BusinessDashboard from "../../pages/Business/Dashboard";
import BusinessOnboardingPage from "../../pages/Business/Onboarding";
import ResetPasswordPage from "../../pages/ResetPassword";
import CreateService from "../../pages/Business/Service/Create";
import UpdateService from "../../pages/Business/Service/Update";
import ViewServicePage from "../../pages/Business/Service/View";
import ServiceListPage from "../../pages/Business/Service/List";
import ServiceReviewsPage from "../../pages/Business/Service/Reviews";
import AccountSettingsPage from "../../pages/Business/Settings/Account";
import TeamSettingsPage from "../../pages/Business/Settings/Team";
import ProfileSettingsPage from "../../pages/Business/Settings/Profile";
import CalendarPage from "../../pages/Business/Calendar";
import ContactsPage from "../../pages/Business/Contacts/List";
import CreateContact from "../../pages/Business/Contacts/Create";
import UpdateContactPage from "../../pages/Business/Contacts/Edit";
import GroupDetailsPage from "../../pages/Business/Contacts/Group/Edit";

import { modules } from "../../constants/modules";
import CreateFormPage from "../../pages/Business/Forms/Create";
import ListFormsPage from "../../pages/Business/Forms/List";
import FormTemplatesPage from "../../pages/Business/Forms/Templates";
import FormTemplateCategoryPage from "../../pages/Business/Forms/[slug]";
import FormBuilderPage from "../../pages/Business/Forms/Builder";
import AllSubmissions from "../../pages/Business/Forms/Submissions";
import VerifyEmailPage from "../../pages/VerifyEmail";
import FormSubmissionPage from "../../pages/Business/Forms/Submission";
import MessagesPage from "../../pages/Messages/Messages";
import FormSubmissions from "../../pages/Business/Forms/SubmissionsList";

import ClientsPage from "../../pages/Business/Clients/List";
import UpdateClientPage from "../../pages/Business/Clients/Edit";
import CreateClientPage from "../../pages/Business/Clients/Create";
import ClientGroupDetailsPage from "../../pages/Business/Clients/Group/Edit";

import CaseManagementTypesListPage from "../../pages/Business/CaseManagement/Types/List";
import CaseManagementStagesListPage from "../../pages/Business/CaseManagement/Stages/List";
import CaseManagementCasesListPage from "../../pages/Business/CaseManagement/Cases/List";
import CaseManagementCaseFormPage from "../../pages/Business/CaseManagement/Cases/Form";
import CaseManagementTasksListPage from "../../pages/Business/CaseManagement/Tasks/List";

const businessPrefix = "business";
const servicePrefix = "service";
const formPrefix = "forms";
const settingsPrefix = "settings";
const contactPrefix = "contact";
const submissionsPrefix = "submissions";
const clientPrefix = "client";
const caseManagementPrefix = "case-management";

export const businessRoutes = [
  {
    path: `/${businessPrefix}/dashboard`,
    component: BusinessDashboard,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/onboarding`,
    component: BusinessOnboardingPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/messages`,
    component: MessagesPage,
    module: modules.MESSAGES,
    isPrivate: true
  },
  {
    path: `/${businessPrefix}/reset-password/:token`,
    component: ResetPasswordPage,
    isPrivate: false,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/calendar`,
    component: CalendarPage,
    isPrivate: true,
    module: modules.CALENDAR
  },
  {
    path: `/${businessPrefix}/${servicePrefix}/create`,
    component: CreateService,
    isPrivate: true,
    module: modules.SERVICES
  },

  {
    path: `/${businessPrefix}/${servicePrefix}/list`,
    component: ServiceListPage,
    isPrivate: true,
    module: modules.SERVICES
  },
  {
    path: `/${businessPrefix}/${formPrefix}/create`,
    component: CreateFormPage,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/${formPrefix}/list`,
    component: ListFormsPage,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/forms/:formId/${submissionsPrefix}/:submissionId`,
    component: FormSubmissionPage,
    isPrivate: false,
    module: modules.SUBMISSIONS,
    disabledFor: ["Viewer"]
  },
  {
    path: `/${businessPrefix}/${formPrefix}/:formId/submissions`,
    component: AllSubmissions,
    isPrivate: false,
    module: modules.FORMS,
    disabledFor: ["Viewer"]
  },
  {
    path: `/${businessPrefix}/${formPrefix}/submissions`,
    component: FormSubmissions,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/${formPrefix}/submissions/administrative`,
    component: FormSubmissions,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/${formPrefix}/submissions/external`,
    component: FormSubmissions,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/${formPrefix}/submissions/survey`,
    component: FormSubmissions,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/${formPrefix}/templates/:slug`,
    component: FormTemplateCategoryPage,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/${formPrefix}/templates`,
    component: FormTemplatesPage,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/${formPrefix}/builder/:formId`,
    component: FormBuilderPage,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/${formPrefix}/builder`,
    component: FormBuilderPage,
    isPrivate: true,
    module: modules.FORMS
  },
  {
    path: `/${businessPrefix}/${servicePrefix}/reviews`,
    component: ServiceReviewsPage,
    isPrivate: true,
    module: modules.SERVICES
  },
  {
    path: `/${businessPrefix}/${servicePrefix}/view/:serviceId`,
    component: ViewServicePage,
    isPrivate: true,
    module: modules.SERVICES
  },
  {
    path: `/${businessPrefix}/${servicePrefix}/update/:serviceId`,
    component: UpdateService,
    isPrivate: true,
    module: modules.SERVICES
  },
  {
    path: `/${businessPrefix}/${settingsPrefix}/profile`,
    component: ProfileSettingsPage,
    isPrivate: true,
    module: modules.SETTINGS
  },
  {
    path: `/${businessPrefix}/${settingsPrefix}/account`,
    component: AccountSettingsPage,
    isPrivate: true,
    module: modules.SETTINGS
  },
  {
    path: `/${businessPrefix}/${settingsPrefix}/team`,
    component: TeamSettingsPage,
    isPrivate: true,
    module: modules.SETTINGS
  },
  {
    path: `/${businessPrefix}/${contactPrefix}/create`,
    component: CreateContact,
    isPrivate: true,
    module: modules.CONTACTS
  },
  {
    path: `/${businessPrefix}/${contactPrefix}/list`,
    component: ContactsPage,
    isPrivate: true,
    module: modules.CONTACTS
  },
  {
    path: `/${businessPrefix}/${contactPrefix}/group-details/:groupId`,
    component: GroupDetailsPage,
    isPrivate: true,
    module: modules.CONTACTS
  },
  {
    path: `/${businessPrefix}/${contactPrefix}/:contactId`,
    component: UpdateContactPage,
    isPrivate: true,
    module: modules.CONTACTS
  },
  {
    path: `/${businessPrefix}/verify-email/:token`,
    component: VerifyEmailPage,
    isPrivate: false
  },
  {
    path: `/${businessPrefix}/${clientPrefix}/list`,
    component: ClientsPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${clientPrefix}/create`,
    component: CreateClientPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${clientPrefix}/group/:groupId`,
    component: ClientGroupDetailsPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${clientPrefix}/:id`,
    component: UpdateClientPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${caseManagementPrefix}/types`,
    component: CaseManagementTypesListPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${caseManagementPrefix}/stages`,
    component: CaseManagementStagesListPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${caseManagementPrefix}/cases/form/:id/view`,
    component: CaseManagementCaseFormPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${caseManagementPrefix}/cases/form/:id/edit`,
    component: CaseManagementCaseFormPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${caseManagementPrefix}/cases/form`,
    component: CaseManagementCaseFormPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${caseManagementPrefix}/cases`,
    component: CaseManagementCasesListPage,
    isPrivate: true,
    module: modules.GLOBAL
  },
  {
    path: `/${businessPrefix}/${caseManagementPrefix}/tasks`,
    component: CaseManagementTasksListPage,
    isPrivate: true,
    module: modules.GLOBAL
  }
];
