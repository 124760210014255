import styled, { css } from "styled-components";

export const StyledMessageCard = styled.div`
  ${({ isItMe }) => css`
    justify-content: ${isItMe ? "flex-start" : "flex-end"};
    align-items: center;
    display: inline-flex;
    padding: 0 1rem;
    margin-bottom: 2rem;
  `}
`;

export const StyledMessageLine = styled.div`
  max-width: 70%;
`;

export const StyledUserInfo = styled.div`
  ${({ isItMe }) => css`
    display: flex;
    align-items: center;
    justify-content: ${isItMe ? "flex-start" : "flex-end"};
    margin-bottom: 0.5rem;
  `}
`;

export const StyledMessage = styled.div`
  ${({ isItMe }) => css`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: ${isItMe ? "#1b84e7" : "#dee2e6"};
    color: ${isItMe ? "#fff" : "#495057"};
    border-radius: ${isItMe ? "0 8px 8px 8px" : "8px 0 8px 8px"};
    a {
      text-decoration: underline !important;
      color: inherit;
    }
  `}
`;

export const StyledAvatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 1rem;
`;

export const StyledUserNameAndDate = styled.div`
  display: grid;
  grid-template-columns: auto 10px auto;
  grid-gap: 5px;
  font-size: 11px;
  color: #495057;
`;

export const StyledActionsWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: -80px;
  gap: 0.5rem;
  display: flex;
  span {
    cursor: pointer;
    color: #495057;
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
  }
`;

export const StyledMessageEditInput = styled.textarea`
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 12px;
`;

export const StyledAttachmentContainer = styled.div`
  ${({ isItMe }) => css`
    position: relative;
    display: flex;
    align-items: center;
    width: 220px;
    height: 120px;
    padding: 16px 0 0;
    background-color: ${isItMe ? "#1b84e7" : "#dee2e6"};
    border-radius: ${isItMe ? "0 8px 8px 8px" : "8px 0 8px 8px"};
    flex-direction: column;
    justify-content: center;
  `}
`;

export const StyledAttachmentFileName = styled.span`
  ${({ isItMe }) => css`
    font-size: 13px;
    text-align: center;
    color: ${isItMe ? "#ffffff" : "#495057"};
    &:hover {
      text-decoration: underline;
    }
    &:hover ${StyledAttachmentTooltip} {
      visibility: visible;
      opacity: 1;
    }
  `}
`;

export const StyledAttachmentDownloadButton = styled.a`
  ${({ isItMe }) => css`
    display: block;
    width: 220px;
    height: 36px;
    margin: 16px 0 0;
    padding: 9px 78px 9px 79px;
    box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.2);
    background-color: ${isItMe ? "#1b84e7" : "#dee2e6"};
    text-align: center;
    color: ${isItMe ? "#ffffff" : "#495057"};
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    cursor: pointer;
  `}
`;

export const StyledAttachmentActionsWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: -45px;
  display: flex;
  gap: 0.5rem;

  span {
    cursor: pointer;
    color: #495057;
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
  }
`;

export const StyledAttachmentTooltip = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  ${({ isItMe }) =>
    isItMe
      ? css`
          left: 105%;
          top: 50%;
          transform: translateY(-50%);
        `
      : css`
          right: 105%;
          top: 50%;
          transform: translateY(-50%);
        `}
  opacity: 0;
  transition: opacity 0.3s;
`;

export const StyledAttachmentTopSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 2px;
`;
