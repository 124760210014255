import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isClient } from "../constants/roles";
import { goToAppForBusiness, goToAppForClient } from "../helpers/history";
export const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [industries, setIndustries] = useState(null);

  const history = useHistory();

  const setUserImage = user => ({
    ...user,
    image:
      user.image ||
      "/static/media/default-business-image.745579a85c217c20ae80.jpg"
  });

  const login = ({ auth, user }) => {
    localStorage.setItem("access_token", auth.access_token);
    localStorage.setItem("currentUser", JSON.stringify(user));
    setCurrentUser(setUserImage(user));
  };

  const logout = () => {
    localStorage.clear();
    setCurrentUser(null);
  };

  useEffect(() => {
    const userJson = localStorage.getItem("currentUser");
    if (userJson) {
      const user = JSON.parse(userJson);
      setCurrentUser(setUserImage(user));
      if (
        history.location.pathname === "/" ||
        history.location.pathname === "/login"
      ) {
        if (isClient(user.role)) {
          goToAppForClient(history, user);
        } else {
          goToAppForBusiness(history, user);
        }
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <>Loading...</>;
  }

  const accessToken = localStorage.getItem("access_token");

  const updateCurrentUserValue = newUserValue => {
    const newUser = { ...currentUser, ...newUserValue };
    localStorage.setItem("currentUser", JSON.stringify(newUser));
    setCurrentUser(setUserImage(newUser));
  };

  return (
    <GlobalContext.Provider
      value={{
        login,
        logout,
        currentUser,
        updateCurrentUserValue,
        industries,
        setIndustries,
        accessToken
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
