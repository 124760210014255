import {CompanyReviews} from "../../../Review/Reviews";
import {CompanyRating} from "../../../Review/Rating";
import "./index.scss";
import {AddReviewToService} from "../../../Review/AddReview";
const average = {
  average: "4.2",
  stars: 4,
  total: 1253,
};
const votes = [
  {
    percent: "60%",
    star: 5,
  },
  {
    percent: "20%",
    star: 4,
  },
  {
    percent: "5%",
    star: 3,
  },
  {
    percent: "10%",
    star: 2,
  },
  {
    percent: "5%",
    star: 1,
  },
];
const services = [
  {
    id: 1,
    serviceName: "How well satisfied you’re with this service?",
  },
  {
    id: 2,
    serviceName: "How you rate the service owner?",
  },
  {
    id: 3,
    serviceName: "How you rate the communication?",
  },
  {
    id: 4,
    serviceName: "How you rate the time frame for this service?",
  },
];
const reviews = [];
export const Reviews = () => {
  return (
    <div className="applied-reviews">
      <div className="reviews-left">
        <div className="pl-3">
          <CompanyRating average={average} votes={votes} />
        </div>
        <div className="mt-5 pt-3 pl-3">
          <h3 className="font-weight-bold mb-5">Reviews</h3>
          <CompanyReviews reviews={reviews} />
        </div>
      </div>
      <div className="reviews-right">
        <AddReviewToService services={services} />
      </div>
    </div>
  );
};
