import React, { useEffect, useState } from "react";
import { StyledContainer, StyledWrapper } from "./styles";
import Header from "./Header";
import Toolbox from "./Toolbox";
import Body from "./Body";
import { FormBuilderContextProvider, useFormBuilderContext } from "./context";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { FormTypes, FormVisibilityOptions } from "../constants";
import { checkObjectValue } from "../../../utils/object";
import { validateComponents } from "./validator";
import { showErrorMessage, showSuccessMessage } from "../../../helpers/dialog";
import { updateForm } from "../../../data/Forms/updateForm";
import { createNewForm } from "../../../data/Forms/createForm";
import { getComponentsForSave } from "./utils";
import { getFormById } from "../../../data/Forms/getFormById";
import Loader from "./Loader";

const Builder = () => {
  const { search } = useLocation();
  const { formId } = useParams();
  const history = useHistory();
  const { type, visibility, templateId, categoryId } =
    queryString.parse(search);

  const {
    components,
    setComponents,
    formTitle,
    setFormTitleValidated,
    isFormTitleValid,
    isEditing,
    editingForm,
    initializeNewForm,
    initializeEditForm,
    initializeNewFormByTemplate
  } = useFormBuilderContext();

  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadForm = () => {
    if (formId) {
      getFormById(formId).then(({ data: response }) => {
        initializeEditForm(response.data);
        setLoading(false);
      });
    } else if (templateId) {
      getFormById(templateId).then(({ data: response }) => {
        initializeNewFormByTemplate(response.data);
        setLoading(false);
      });
    } else {
      initializeNewForm();
      setLoading(false);
    }
  };

  useEffect(() => {
    const isFormTypeValid = checkObjectValue(FormTypes, type);
    const isFormVisibilityValid = checkObjectValue(
      FormVisibilityOptions,
      visibility
    );
    if (formId || (isFormTypeValid && isFormVisibilityValid)) {
      loadForm();
    } else {
      history.push("create");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSaveClick = async () => {
    try {
      setFormTitleValidated(true);
      const isComponentsValid = validateComponents(components);

      const isValid = isFormTitleValid && isComponentsValid;

      if (!isValid) {
        setComponents([...components]);
        showErrorMessage("Form can't be saved. Please check the errors");
        return;
      }

      setIsSaving(true);
      const createModel = () => ({
        type,
        visibility,
        categoryId,
        title: formTitle
      });

      const updateModel = () => ({
        title: formTitle
      });

      const model = isEditing ? updateModel() : createModel();

      if (!categoryId) {
        delete model.categoryId;
      }

      model.components = getComponentsForSave(components);

      const { hasSuccess, data } = isEditing
        ? await updateForm(editingForm.id, model)
        : await createNewForm(model);

      setIsSaving(false);

      if (hasSuccess) {
        showSuccessMessage("Form saved successfully");
        history.push("/business/forms/list");
      } else {
        console.error("API error => ", data);
        showSuccessMessage("Form saved failed");
      }
    } catch (error) {
      setIsSaving(false);
      console.error("Error => ", error);
      showSuccessMessage("Form saved failed - Error");
    }
  };

  return (
    <StyledContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header isSaving={isSaving} onSaveClick={handleOnSaveClick} />
          <StyledWrapper>
            <Toolbox />
            <Body />
          </StyledWrapper>
        </>
      )}
    </StyledContainer>
  );
};

const FormBuilder = () => (
  <FormBuilderContextProvider>
    <Builder />
  </FormBuilderContextProvider>
);

export default FormBuilder;
