export function setFormValid(state) {
  return {...state, formValid: validateForm(state)};
}

export function validateForm(state) {
  const validComponent = {};
  validComponent.valid = true;
  const componentsValid = state.components.reduce((prev, next) => {
    const c = {};
    c.valid = prev.valid && next.valid;
    return c;
  }, validComponent);
  return componentsValid.valid && state.form_header.valid;
}

export function validateTitle(title) {
  return title.length > 2;
}

export function validateHeading(attribute) {
  return attribute.value.length > 0;
}

export function validateInputTextName(attribute) {
  return attribute.value.length > 2;
}

export function dummyValidator(attribute) {
  console.log("Dummy validator", attribute);
  return true;
}

export function validateESignaturePolicyCheck(attribute) {
  return attribute.value === "true";
}
