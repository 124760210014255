import { roles } from "../constants/roles";
import { parseJwt } from "./function";

export const getCurrentUser = () =>
  JSON.parse(localStorage.getItem("currentUser"));

export const getUserEmail = () => getCurrentUser().email;
export const getUserRole = () => getCurrentUser().role;
export const getToken = () => localStorage.getItem("access_token");
export const getFeatures = () => getCurrentUser().features;
export const isBusiness = () =>
  getUserRole().toLowerCase() === roles.BUSINESS.toLowerCase();
export const isStaff = () =>
  getUserRole().toLowerCase() === roles.STAFF.toLowerCase();
export const isClient = () =>
  getUserRole().toLowerCase() === roles.CLIENT.toLowerCase();
export const getTokenInfo = () => parseJwt(getToken());

export const updateUserInfo = userInfo => {
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
};
