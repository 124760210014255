import React from "react";
import ReactDOM from "react-dom/client";
import { GlobalProvider } from "./providers/global";
import App from "./components/App/App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { ChatProvider } from "./providers/Chat";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <GlobalProvider>
      <ChatProvider>
        <App />
      </ChatProvider>
    </GlobalProvider>
  </BrowserRouter>
);
