import "./PageContainer.scss";

export const PageContainer = ({children}) => (
  <div className="right p-3 generic-page-container">
    <section>
      <div className="row">
        <div className="col-lg-12">{children}</div>
      </div>
    </section>
  </div>
);
