import "bootstrap/dist/js/bootstrap.min.js";
import React from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {LabelInputGroup, RadiusInput} from "../Base/BaseStyles";

export function LabelEdit({attributes, dispatch, component_key}) {
  const label = getAttribute("title", attributes);
  return (
    <LabelInputGroup className="input-group">
      <RadiusInput
        type="text"
        className="form-control"
        aria-label=""
        placeholder="Add Label"
        value={label}
        onChange={(event) =>
          dispatch({
            type: "label_title_change",
            title: event.target.value,
            key: component_key,
          })
        }
      />
    </LabelInputGroup>
  );
}

export default LabelEdit;
