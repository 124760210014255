import styled from "styled-components";
import * as theme from "../Styles/Theme";
import {Icon} from "../Styles/Theme";

export const StyledFormEdit = styled.div`
  color: black;
  background-color: white;
`;
export const FormTitleBackground = styled.div`
  background: ${theme.azul_opacity};
  height: ${(props) => props.height};
`;
export const FormTitleText = styled.div`
  font-family: ${theme.defaultFont};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: ${theme.azul};
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
`;
export const HeaderInput = styled(theme.IradarInput)`
  border: solid 1px #1b84e7;
  background: transparent;
`;
export const EmptyComponentsTitle = styled.div`
  font-family: ${theme.defaultFont};
  font-size: 20px;
  color: #495057;
  margin-top: 8em;
  margin-bottom: 1.6em;
`;
export const DashedRectangle = styled.div`
  border-radius: 8px;
  border: dashed 1px ${theme.azul};
  background-color: #fafdff;
`;
export const AddElementTitle = styled.div`
  font-family: ${theme.defaultFont};
  font-weight: bold;
  font-size: 16px;
  color: #495057;
`;
export const AddElementDescription = styled.div`
  font-family: ${theme.defaultFont};
  color: #79818b;
  opacity: 0.9;
  font-size: 16px;
  margin-top: 0.8em;
`;

export const NavButton = styled.button`
  font-family: ${theme.defaultFont} !important;
  color: #495057 !important;
  font-size: 17px !important;
  padding: 1em 2em 1em 2em;

  &.active {
    border-color: white white ${theme.azul} white !important;
    font-weight: bold !important;
    color: black !important;
  }
`;

export const SmallNavButton = styled(NavButton)`
  padding: 0.2em 0.8em 0.2em 0.8em !important;
  font-size: 12px !important;

  &.active {
    border-color: white white ${theme.azul} white !important;
    border-bottom-width: 0.2em !important;
    font-weight: bold !important;
    color: ${theme.azul} !important;
  }
`;
export const ComponentUl = styled.ul`
  border-bottom: 0;
`;
export const CurrentTabTitle = styled.div`
  font-family: ${theme.defaultFont} !important;
  color: #495057 !important;
  padding: 0.4em 2em 0 2em;

  font-size: 25px;
  font-weight: bold;
`;
export const SaveButton = styled.button`
  background-image: linear-gradient(to right, ${theme.azul}, #1515af 100%);
  padding: 10px 22px;
  margin-top: 0.9em;
  border: solid 1px #dee2e6;
  border-radius: 26px;
  font-size: 13px;
  color: white;
`;
export const WhiteButton = styled(SaveButton)`
  background-image: unset;
  background-color: white;
  color: ${theme.azul};
  font-size: 13px;
  font-weight: 700;
`;

export const DefaultIcon = styled(Icon)`
  margin-top: 0.7em;
  cursor: pointer;
`;
export const EditIcon = styled(Icon)`
  font-size: 1em;
  margin-top: 0.7em;
  cursor: pointer;
  color: ${theme.azul};
`;
export const MagnifierIcon = styled(Icon)`
  font-size: 1em;
  margin-top: 0.7em;
  cursor: pointer;
  color: ${theme.azul};
`;
