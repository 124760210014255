import React, {Fragment, useEffect, useRef, useState, useCallback} from "react";
import SignatureCanvas from "react-signature-canvas";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import {
  BaseEditIcon,
  ESignatureFunctionButton,
  InputGroup,
  RadiusInput,
  SignatureWrapper,
} from "../Base/BaseStyles";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";

export function ESignatureCanvas({dispatch, attributes, component_key}) {
  const sigRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [dimensions, setDimensions] = useState({});

  const inputMode = getAttribute("input_mode", attributes);
  const setInputMode = (val) =>
    dispatch({type: "esig_input_mode_change", key: component_key, value: val});
  const text = getAttribute("text", attributes);
  const setText = (str) =>
    dispatch({type: "esig_text_change", key: component_key, value: str});
  const base64 = getAttribute("base64", attributes);
  const setBase64 = (str) =>
    dispatch({type: "esig_base64_change", key: component_key, value: str});
  const designation = getAttribute("designation", attributes);
  const setDesignation = (str) =>
    dispatch({type: "esig_designation_change", key: component_key, value: str});
  const name = getAttribute("name", attributes);
  const setName = (str) =>
    dispatch({type: "esig_name_change", key: component_key, value: str});
  const policyChecked = getAttribute("policy_checked", attributes) === "true";
  const setPolicyChecked = (val) =>
    dispatch({
      type: "esig_policy_checked_change",
      key: component_key,
      value: val ? "true" : "false",
    });

  const uploadImage = (e) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      const fileReader = new FileReader();
      try {
        fileReader.readAsDataURL(file);
      } catch (e) {
        return false;
      }
      sigRef.current.clear();
      fileReader.onload = (event) => {
        setBase64(fileReader.result);
        event.target.files = [];
      };
      fileReader.onerror = (err) => {
        console.log(err);
      };
    }
    e.target.value = "";
    return true;
  };

  const canvasResize = useCallback(() => {
    if (sigRef && sigRef.current) {
      const {width, height} = sigRef.current.getSignaturePad()._canvas;
      setDimensions({width, height});
    }
  }, [sigRef]);

  useEffect(() => {
    window.addEventListener("resize", canvasResize);
    canvasResize();
  }, [canvasResize]);

  useEffect(() => {
    if (
      sigRef.current &&
      base64 &&
      base64 !== sigRef.current.toDataURL() &&
      dimensions.width &&
      dimensions.height
    ) {
      sigRef.current.fromDataURL(base64, dimensions);
    }
  }, [base64, dimensions]);

  const signatureContent = (
    <SignatureWrapper>
      <SignatureCanvas
        penColor={"#1b84e7"}
        ref={sigRef}
        onEnd={() => setBase64(sigRef.current.toDataURL())}
        canvasProps={{className: "eSignRect eSignWidth"}}
      />
    </SignatureWrapper>
  );

  const textContent = (
    <InputGroup className="input-group" style={{marginTop: "30px"}}>
      <RadiusInput
        style={{marginLeft: "1.8em", maxWidth: "91%"}}
        type="text"
        className="form-control"
        aria-label=""
        placeholder="Signature"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
    </InputGroup>
  );

  const signatureModeButton = (
    <ESignatureFunctionButton
      clickable={true}
      className={"bi bi-pencil"}
      data-toggle="tooltip"
      data-placement="top"
      title={"Handwriting"}
      onClick={() => setInputMode("signature")}
    />
  );

  const textModeButton = (
    <ESignatureFunctionButton
      clickable={true}
      className={"bi bi-fonts"}
      data-toggle="tooltip"
      data-placement="top"
      title={"Text Signature"}
      onClick={() => setInputMode("text")}
    />
  );

  const uploadModeButton = (
    <label>
      <ESignatureFunctionButton
        clickable={true}
        className={"bi bi-upload"}
        data-toggle="tooltip"
        data-placement="top"
        title="Upload Image"
      />
      <input
        style={{display: "none"}}
        type={"file"}
        className={"bi bi-upload"}
        data-toggle="tooltip"
        data-placement="top"
        title="Upload Image"
        onChange={uploadImage}
      />
    </label>
  );

  const clearBase64Button = (
    <ESignatureFunctionButton
      clickable={true}
      className={"bi bi-eraser"}
      data-toggle="tooltip"
      data-placement="top"
      title="Clear"
      onClick={() => {
        sigRef.current.clear();
        setBase64("");
      }}
    />
  );

  let content = null;
  if (inputMode === "signature") {
    content = signatureContent;
  } else if (inputMode === "text") {
    content = textContent;
  }

  return (
    <Fragment>
      {content}
      <div style={{marginTop: "5px", marginRight: "2.6em", float: "right"}}>
        {clearBase64Button}
        {signatureModeButton}
        {textModeButton}
        {uploadModeButton}
      </div>

      <InputGroup className="input-group" style={{marginTop: "30px"}}>
        <div className="row">
          <div className="col-md-4">
            <RadiusInput
              style={{marginTop: "15px"}}
              type="text"
              className="form-control"
              aria-label=""
              placeholder={"Designation"}
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
          </div>
          <div className="col-md-8">
            <RadiusInput
              style={{marginTop: "15px"}}
              type="text"
              className="form-control"
              aria-label=""
              placeholder={"Full Name"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
      </InputGroup>
      <InputGroup className="input-group" style={{marginTop: "30px"}}>
        <Form.Check
          className={"esignature-policy-checkbox"}
          style={{marginTop: "15px", width: "50%", marginLeft: "1.6em"}}
          type={"checkbox"}
          onChange={(e) => setPolicyChecked(e.target.checked)}
          checked={policyChecked}
          label="I have read and I agree to the e-signature
            policy"
        />
      </InputGroup>
      <div style={{width: "90%", marginLeft: "1.6em"}}>
        <label>
          e-signature policy
          <BaseEditIcon
            clickable={true}
            className={open ? "bi bi-caret-down" : "bi bi-caret-right"}
            data-toggle="tooltip"
            data-placement="top"
            title="Upload Image"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          />
        </label>
        <Collapse in={open}>
          <div style={{marginTop: "15px", color: "#1b84e7"}}>
            You must first confirm that you agree to sign the documents by means
            of electronic signature. By clicking on the [“Accept”] button at the
            bottom of this page, you agree to sign the documents by means of
            electronic signature and understand that affixing your electronic
            signature creates a legal obligation in the same way as a signature
            on a paper document. If you do not want to proceed using an
            electronic signature, you can click on the [Decline to sign] A
            representative of the organization requesting signature may then
            send you a paper version to sign. If several people’s signatures are
            required and one of them refuses to sign the documents by means of
            electronic signature, the business must obtain printed signatures
            from all of the individuals involved.
          </div>
        </Collapse>
      </div>
    </Fragment>
  );
}

export default ESignatureCanvas;
