import React from 'react'
import { StyledRadiusInput } from '../../../styles'
import Required from '../../Shared/Required'
import { StyledContainer } from './styles'

const Options = ({ options, onChanged }) => {
  const {
    isRequired,
    requiredErrorMessage,
    placeholderText,
    kpiText,
    baseLineText,
  } = options

  const updateValue = (key, value) => {
    onChanged(key, value)
  }

  return (
    <StyledContainer>
      <Required
        isRequired={isRequired}
        onRequiredChanged={(checked) => updateValue('isRequired', checked)}
        errorMessage={requiredErrorMessage}
        onErrorMessageChanged={(value) =>
          updateValue('requiredErrorMessage', value)
        }
      />

      <StyledRadiusInput
        className="form-control"
        placeholder="Enter placeholder"
        value={placeholderText}
        onChange={(e) => updateValue('placeholderText', e.currentTarget.value)}
      />
      <StyledRadiusInput
        className="form-control"
        placeholder="Enter KPI"
        value={kpiText}
        onChange={(e) => updateValue('kpiText', e.currentTarget.value)}
      />
      <StyledRadiusInput
        className="form-control"
        placeholder="Enter baseline"
        value={baseLineText}
        onChange={(e) => updateValue('baseLineText', e.currentTarget.value)}
      />
    </StyledContainer>
  )
}

export default Options
