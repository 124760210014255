import React from "react";
import {calculateColumnWidth} from "../Components/Base/Base";
import {checkSelectedColumnSize} from "../FormEdit/Components";
import {sortComponents} from "../FormEdit/FormEditReducerHelpers";
import {FormPreviewComponentTypes} from "./FormViewReducerTypes";
import {BaseViewStyle} from "./FormViewStyles";

function getComponents(components, dispatch, currentView) {
  const parsedComponents = [];
  const sortedComponents = sortComponents(components);
  for (const component of sortedComponents) {
    let bootstrapColumnWidth = calculateColumnWidth(
      checkSelectedColumnSize(component.attributes)
    );
    if (currentView === "Phone") {
      bootstrapColumnWidth = 12;
    }
    const ParsedComponentType = FormPreviewComponentTypes[component.type];
    const component_props = {
      attributes: component.attributes,
      component_key: component.key,
      dispatch: dispatch,
    };
    parsedComponents.push(
      <div
        className={"col-sm-12 col-md-" + bootstrapColumnWidth}
        key={"preview " + component.key}
      >
        <BaseViewStyle>
          <ParsedComponentType {...component_props} />
        </BaseViewStyle>
      </div>
    );
  }
  return parsedComponents;
}

export function Components({components, dispatch, currentView = ""}) {
  const components_length = components.length;
  return (
    <div>
      {components_length ? (
        <ul>
          <div className={"row"}>
            {getComponents(components, dispatch, currentView)}
          </div>
        </ul>
      ) : (
        <ul></ul>
      )}
      {!components_length && (
        <div className="row">
          <div className="col-12">
            The submission is loading, please wait...
          </div>
        </div>
      )}
    </div>
  );
}

export default Components;
