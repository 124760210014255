import * as Yup from "yup";

export const EmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required")
});

export const BusinessClientInviteSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  isImmigrant: Yup.boolean(),
  businessId: Yup.string().required("Business ID is required")
});
