import { chatsWebsocketEndpoint } from "../../data/endpoint";

export const createWebSocket = ({
  onOpen,
  onMessage,
  onError,
  onClose,
  token
}) => {
  try {
    const endpoint = `${chatsWebsocketEndpoint}?token=${token}`;
    const socket = new WebSocket(endpoint);

    socket.addEventListener("open", () => {
      console.log("Socket opened");
      if (typeof onOpen === "function") onOpen();
    });

    socket.addEventListener("message", ({ data }) => {
      if (typeof onMessage === "function") onMessage(JSON.parse(data));
    });

    socket.addEventListener("error", e => {
      console.error("@WebSocket [ERROR] => ", e);
      if (typeof onError === "function") onError(e);
    });

    socket.addEventListener("close", e => {
      console.error("@WebSocket [CLOSE] => ", e);
      if (typeof onClose === "function") onClose(e);
    });

    return socket;
  } catch (error) {
    console.error("Error creating WebSocket:", error);
    return null;
  }
};

export const sendWebSocketMessage = ({ socket, message, action, token }) => {
  const json = JSON.stringify({
    action,
    data: {
      ...message,
      token
    }
  });
  socket.send(json);
};
