import React from "react";
import useBusinessCaseManagementCaseFormContext from "../../../../../providers/CaseManagement/Cases/form";
import LeftSideForm from "./LeftSideForm";
import LeftSideView from "./LeftSideView";

const LeftSide = () => {
  const { getters } = useBusinessCaseManagementCaseFormContext();
  const { formMode } = getters;
  return formMode === "view" ? <LeftSideView /> : <LeftSideForm />;
};

export default LeftSide;
