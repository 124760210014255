import {hostName} from "../endpoint";
import {defaultHeaders} from "../headers";

export const updateClient = async (details) => {
  const token = localStorage.getItem("access_token");
  setTimeout(async () => {
    const result = await fetch(`${hostName}/client`, {
      method: "PUT",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(details),
    });
    return result.json();
  }, 400);
};
