import * as Yup from "yup";
import { STATUS, PRIORITY } from "../../../constants/case-management";

const noExtraProperties = (message, validKeys) => {
  return {
    name: "no-extra-properties",
    test: function (value) {
      if (value == null) return true;

      const invalidKeys = Object.keys(value).filter(
        key => !validKeys.includes(key)
      );
      if (invalidKeys.length > 0) {
        return this.createError({
          path: this.path,
          message: `${message}: ${invalidKeys.join(", ")}`
        });
      }
      return true;
    }
  };
};

const statusValues = STATUS.map(status => status.value);
const priorityValues = PRIORITY.map(priority => priority.value);

const CaseManagementTaskCreateSchema = Yup.object()
  .shape({
    title: Yup.string()
      .required("Title is required")
      .min(3, "Title is too Short!")
      .max(50, "Title is too Long!"),
    description: Yup.string()
      .required("Description is required")
      .min(3, "Description is too Short!")
      .max(50, "Description is too Long!"),
    status: Yup.string().oneOf(statusValues).required("Status is required"),
    priority: Yup.string()
      .oneOf(priorityValues)
      .required("Priority is required"),
    private: Yup.boolean().default(false),
    assignedTo: Yup.string()
      .notOneOf(["-1"], "Please select a assignee!")
      .required("Assignee is required"),
    dueDate: Yup.string().required("Due Date is required"),
    caseId: Yup.string()
  })
  .test(
    noExtraProperties("Extra properties are not allowed in case", [
      "title",
      "description",
      "status",
      "priority",
      "private",
      "assignedTo",
      "dueDate",
      "caseId"
    ])
  );

export default CaseManagementTaskCreateSchema;
