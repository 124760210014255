import React from 'react'
import ESignatureComponent from '../../../Builder/Body/Components/ESignature'
import ValidationText from '../../../Builder/Body/Components/Shared/ValidationText'
import { FormComponentTypes } from '../../../constants'

const ESignature = ({
  component: { options, validations },
  onChange,
  isReadOnly,
  isVisible,
}) => {
  const handleOnChange = (newOptions) => {
    options.submission = newOptions
    onChange(options)
  }
  const component = {
    type: FormComponentTypes.ESignature,
    options: options.submission || options,
  }
  return (
    <>
      <ESignatureComponent
        key={isVisible}
        isReadOnly={isReadOnly}
        boxVisible={false}
        component={component}
        onOptionsChange={handleOnChange}
      />
      {validations?.value && (
        <ValidationText>{validations?.value}</ValidationText>
      )}
      {validations?.policy && (
        <ValidationText>{validations?.policy}</ValidationText>
      )}
    </>
  )
}

export default ESignature
