import React from "react";

const ErrorField = ({message}) => (
  <div
    id="badge-danger"
    className="invalid-feedback form-text mt-2 ms-3 d-block"
  >
    {message}
  </div>
);

export default ErrorField;
