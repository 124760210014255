/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, Fragment } from "react";
import { ArrowDownSilver } from "../../../assets/icons/icon";
import { Link } from "react-router-dom";
import {
  businessMenuItems,
  clientMenuItems,
  getBusinessMenuItemsByFeatures
} from "./menuItems";
import { useLocation } from "react-router-dom";
import { isBusiness, isStaff, getFeatures } from "../../../utils/account";
import "./Sidebar.scss";
import { useChatContext } from "../../../providers/Chat";

const hasSubItems = item => item.subItems;

const SubItems = ({ item, pathname }) => {
  const isSelected = item.subItems.some(i => i.link === pathname);
  const [isOpen, setIsOpen] = useState(isSelected);
  const Item = item.link ? Link : "a";
  const extraProps = item.link ? { to: item.link } : {};
  return (
    <li>
      <Item
        {...extraProps}
        className={`menu-label ${item.link === pathname ? "active-menu" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {item.icon}
        {item.name} <span>{ArrowDownSilver}</span>
      </Item>
      {isOpen && (
        <ul className="sub-menu">
          {item.subItems.map((subItem, index) => (
            <li key={index}>
              <Link
                className={subItem.link === pathname ? "active-menu" : ""}
                to={subItem.link}
              >
                {subItem.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const Sidebar = () => {
  const { chats = [] } = useChatContext();
  const { pathname } = useLocation();
  const menuItems = isBusiness()
    ? businessMenuItems
    : isStaff()
    ? getBusinessMenuItemsByFeatures(getFeatures())
    : clientMenuItems;

  const totalUnSeenCount = chats.reduce(
    (acc, chat) => (acc += chat.unSeenCount),
    0
  );

  return (
    <div className="sidebar">
      <div className="mb-auto w-100">
        <ul className="mainmenu">
          {menuItems
            .filter(menu => menu.isVisible)
            .map((item, index) => (
              <Fragment key={index}>
                {hasSubItems(item) ? (
                  <SubItems item={item} pathname={pathname} />
                ) : (
                  <li>
                    <Link
                      className={[
                        item.link === pathname ? "active-menu" : "",
                        item.hasChatBadge ? "chat-badge" : ""
                      ].join(" ")}
                      to={item.link}
                    >
                      {item.icon}
                      {item.name}
                      {item.hasChatBadge && totalUnSeenCount > 0 && (
                        <span>{totalUnSeenCount}</span>
                      )}
                    </Link>
                  </li>
                )}
              </Fragment>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
