import React from "react";
import { updateOptions } from "../../../utils";
import ComponentBox from "../Shared/Box";
import { StyledRadiusInput } from "../../styles";
import Tab from "../Shared/Tab";
import OptionsComponent from "./OptionsComponent";
import ValidationText from "../Shared/ValidationText";
import Checkbox from "../../../../Viewer/Components/Checkbox";

const CheckboxComponent = ({
  component,
  onOptionsChange,
  onOrderButtonClick,
  onDeleteClick,
  onDuplicateClick
}) => {
  const optionsComponent = (
    <OptionsComponent
      options={component.options}
      onChanged={(key, value) =>
        onOptionsChange(updateOptions(component.options, key, value))
      }
    />
  );

  const validationKeys = Object.keys(component.validations || {});

  const inputComponent = (
    <>
      <StyledRadiusInput
        className="form-control"
        placeholder="Enter your question"
        value={component.options.value}
        onChange={e => {
          onOptionsChange(
            updateOptions(component.options, "value", e.currentTarget.value)
          );
        }}
      />

      {validationKeys.map(key => (
        <ValidationText key={key}>{component.validations[key]}</ValidationText>
      ))}
    </>
  );

  const previewComponent = (
    <Checkbox
      component={component}
      onChange={newOptions => onOptionsChange(newOptions)}
    />
  );

  return (
    <ComponentBox
      title="Add Checkbox Field"
      column={component.options.column}
      previewMode={component.options.preview}
      onPreviewClick={() => {
        onOptionsChange(
          updateOptions(
            component.options,
            "preview",
            !component.options.preview
          )
        );
      }}
      onDeleteClick={onDeleteClick}
      onDuplicateClick={onDuplicateClick}
      onColumnChanged={column =>
        onOptionsChange(updateOptions(component.options, "column", column))
      }
      onOrderButtonClick={onOrderButtonClick}
    >
      {component.options.preview ? (
        previewComponent
      ) : (
        <Tab
          editComponent={inputComponent}
          optionsComponent={optionsComponent}
        />
      )}
    </ComponentBox>
  );
};

export default CheckboxComponent;
