import {Fragment} from "react";
import ComponentTabsContent from "../ComponentTabs/ComponentTabsContent";
import ComponentTabsHeader from "../ComponentTabs/ComponentTabsHeader";
import ESignatureEditTab from "./ESignatureEditTab";
import ESignatureOptionsTab from "./ESignatureOptionsTab";

export function ESignatureEdit({dispatch, attributes, component_key}) {
  return (
    <Fragment>
      <ComponentTabsHeader
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <ComponentTabsContent
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
        EditTab={ESignatureEditTab}
        OptionTab={ESignatureOptionsTab}
      />
    </Fragment>
  );
}

export default ESignatureEdit;
