import React from 'react'
import { StyledContainer, StyledDescriptionWrapper, StyledDescriptionText, StyledViewSwitchContainer, StyledViewSwitchWrapper, StyledViewSwitchItem, StyledViewSwitchItemIcon } from "./styles"



const ToolboxHeader = ({ onViewChanged }) => {

    const handleOnViewChanged = (isGrid) => onViewChanged(isGrid);

    const viewSwitchItems = [
        { icon: 'bi bi-list-ul', onClick: () => handleOnViewChanged(false) },
        { icon: 'bi bi-grid', onClick: () => handleOnViewChanged(true) },
    ]


    return (
        <StyledContainer>
            <StyledDescriptionWrapper>
                <StyledDescriptionText>Form elements</StyledDescriptionText>
            </StyledDescriptionWrapper>
            <StyledViewSwitchContainer>
                <StyledViewSwitchWrapper>
                    {viewSwitchItems.map((item, index) => (
                        <StyledViewSwitchItem key={index} onClick={item.onClick}>
                            <StyledViewSwitchItemIcon className={item.icon} />
                        </StyledViewSwitchItem>
                    ))}
                </StyledViewSwitchWrapper>
            </StyledViewSwitchContainer>
        </StyledContainer>
    )
}

export default ToolboxHeader