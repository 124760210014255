import React from "react";
import { StyledH1, StyledH2, StyledH3 } from "./styles";

const components = {
  H1: StyledH1,
  H2: StyledH2,
  H3: StyledH3
};

const Heading = ({
  component: {
    options: { alignment, style, value }
  }
}) => {
  const Component = components[style];
  return Component ? (
    <Component alignment={alignment}>{value}</Component>
  ) : null;
};

export default Heading;
