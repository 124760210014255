import React, { useState } from "react";
import { Options } from "../../../Layout/Shared/DropdownOptionsMenu";
import { ThreeDotIcon } from "../../../../assets/icons/icon";
import { DeleteModal } from "../../../Layout/Shared/Popups/Delete";
import { deleteType } from "../../../../data/CaseManagement/types";

export const ActionButtons = ({ type, setType, setShowFormModal, refresh }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleDelete = async () => {
    setSubmitting(true);
    const { hasSuccess } = await deleteType(type._id);
    if (hasSuccess) {
      await refresh();
      setShowDelete(false);
    }
    setSubmitting(false);
  };

  return (
    <>
      {submitting ? (
        <span className="dots-loading">{ThreeDotIcon}</span>
      ) : (
        <Options
          arrow={false}
          buttonTitle={ThreeDotIcon}
          menuItems={
            <div>
              <button
                onClick={() => {
                  setShowDelete(false);
                  setType(type);
                  setShowFormModal(true);
                }}
              >
                Edit
              </button>

              <button
                className="text-danger"
                onClick={() => {
                  // setShowFormModal(false);
                  // setType({});
                  setShowDelete(true);
                }}
              >
                Delete
              </button>
            </div>
          }
        />
      )}

      {showDelete && (
        <DeleteModal
          title="Delete Type"
          text="You are about to delete this type from your type list. Deleting
          this type removes all type’s information and ends all previous
          communication between you and the type."
          buttonText="Delete"
          onConfirm={handleDelete}
          onClose={() => setShowDelete(false)}
        />
      )}
    </>
  );
};
