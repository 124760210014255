import styled, { css } from "styled-components";

const getMessageBorderColor = type => {
  switch (type) {
    case "business":
      return "#1b84e7";
    case "staff":
      return "#28a745";
    case "client":
      return "#ffc107";
    case "group":
      return "#6c757d";
    default:
      return "transparent";
  }
};

export const StyledMessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const StyledMessageCenter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  height: calc(100vh - 300px);
  overflow-y: auto;
  padding-right: 1rem;
  background: #f5f6f7;
`;

export const StyledMessageMessageCenterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 4px solid ${({ type }) => getMessageBorderColor(type)};
  height: 84px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RightContainer = styled.div`
  margin-left: auto;
`;

export const ContactAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
`;

export const StyledDetailRightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  background: #fff;
  border-left: 1px solid #e0e0e0;
  height: 100%;
`;

export const StyledTabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

export const StyledTab = styled.div`
  ${({ active }) => css`
    padding: 1rem;
    cursor: pointer;
    font-weight: bold;
    &:hover {
      color: #007bff;
    }
    color: ${active ? "#007bff" : "#000"};
  `}
`;

export const StyledParticipantsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledStyledParticipantContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const AttachmentContainer = styled.div`
  width: 100%;
  margin-bottom: 2px;
  padding: 0 0 0 16px;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

export const FileDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFileNameTooltip = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
`;

export const FileName = styled.div`
  height: 18px;
  margin: 12px 12px 12px 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #495057;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
  &:hover ${StyledFileNameTooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

export const DownloadBox = styled.a`
  width: 42px;
  height: 42px;
  background-color: #fafafa;
  padding: 14px 14px 14px 14px;
  box-shadow: -1px 0 0 0 var(--pale-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const svgDownloadIconData = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.234"
    height="14"
    viewBox="0 0 12.234 14"
  >
    <g>
      <g>
        <path
          fill="#1b84e7"
          d="M44.516 7.761a.292.292 0 0 0-.269-.18h-3.196V.292A.292.292 0 0 0 40.76 0h-4.666a.292.292 0 0 0-.294.292v7.289h-3.2a.291.291 0 0 0-.206.5l5.817 5.836a.292.292 0 0 0 .413 0l5.834-5.836a.291.291 0 0 0 .058-.32z"
          transform="translate(-32.304) translate(32.304) translate(-32.304)"
        />
      </g>
    </g>
  </svg>
`;

export const DownloadIcon = styled.img.attrs({
  src: `data:image/svg+xml;utf8,${encodeURIComponent(svgDownloadIconData)}`
})`
  width: 16.47px;
  height: 18.9px;
  object-fit: contain;
`;

export const SenderMiniInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  white-space: nowrap;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 30px;
  background-color: #707070;
  margin: 0 2px;
`;

export const RightAlignedContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
`;

export const SmallAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  border: 2px solid ${({ type }) => getMessageBorderColor(type)};
`;

export const Tooltip = styled.div`
  visibility: hidden;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;
