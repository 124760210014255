import * as Yup from "yup";
import { STATUS, PRIORITY } from "../../../constants/case-management";

const noExtraProperties = (message, validKeys) => {
  return {
    name: "no-extra-properties",
    test: function (value) {
      if (value == null) return true;

      const invalidKeys = Object.keys(value).filter(
        key => !validKeys.includes(key)
      );
      if (invalidKeys.length > 0) {
        return this.createError({
          path: this.path,
          message: `${message}: ${invalidKeys.join(", ")}`
        });
      }
      return true;
    }
  };
};

const statusValues = STATUS.map(status => status.value);
const priorityValues = PRIORITY.map(priority => priority.value);

const CaseManagementCaseUpdateSchema = Yup.object()
  .shape({
    title: Yup.string()
      .min(3, "Case Title is too Short!")
      .max(30, "Case Title is too Long!")
      .notOneOf([""], "Case Title is required!")
      .required("Case Title is required!"),
    typeId: Yup.string()
      .notOneOf(["-1"], "Please select a case type!")
      .required("Case Type is required"),
    status: Yup.string()
      .oneOf(statusValues)
      .required("Case Status is required"),
    stageId: Yup.string()
      .notOneOf(["-1"], "Please select a case stage!")
      .required("Case Stage is required"),
    clientId: Yup.string()
      .notOneOf(["-1"], "Please select a client!")
      .required("Client is required"),
    priority: Yup.string()
      .oneOf(priorityValues)
      .required("Case Priority is required"),
    assignedTo: Yup.string()
      .notOneOf(["-1"], "Please select a assignee!")
      .required("Assignee is required"),
    startDate: Yup.date().required("Start Date is required"),
    dueDate: Yup.date().required("End Date is required"),
    summary: Yup.string()
      .min(5, "Summary is too Short!")
      .max(100, "Summary is too Long!")
      .required("Summary is required"),
    note: Yup.string().default("")
  })
  .test(
    noExtraProperties("Extra properties are not allowed in case", [
      "title",
      "typeId",
      "status",
      "stageId",
      "clientId",
      "priority",
      "assignedTo",
      "startDate",
      "dueDate",
      "summary",
      "note"
    ])
  );

export default CaseManagementCaseUpdateSchema;
