import Modal from "../../Modal";

export const DeleteModal = ({
  onClose,
  title,
  text,
  buttonText,
  cancel = false,
  onConfirm,
  height,
}) => {
  const confirm = () => {
    onConfirm && onConfirm();
    close();
  };
  const close = () => onClose();
  return (
    <Modal
      title={title}
      width="510px"
      height={height}
      close={close}
      backdrop={false}
    >
      <div>
        <p>
          {text}
          <br />
          <br />
          Are you sure you want to continue?
        </p>
        <div className="mt-4 d-flex justify-content-end">
          {cancel && (
            <button onClick={close} className="btn-text-blue me-5 ">
              Cancel
            </button>
          )}
          <button
            className="btn-small btn-red float-end mt-0"
            onClick={confirm}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};
