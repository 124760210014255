import React from "react";
import Dropzone from "react-dropzone-uploader";
import useBusinessCaseManagementCaseFormContext from "../../../../../providers/CaseManagement/Cases/form";
import { AttachmentsList } from "./list";
import "react-dropzone-uploader/dist/styles.css";
import "../../../../../styles/customComponents/customUploader.scss";

const Attachments = () => {
  const { attachments: _ } = useBusinessCaseManagementCaseFormContext();
  const { createAttachment, getAttachments } = _;

  const getUploadParams = async ({ meta, file }) =>
    await createAttachment(file, meta);

  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === "headers_received") {
      console.log(`${JSON.stringify(meta)} uploaded!`);
      remove();
      getAttachments();
    } else if (status === "aborted") {
      console.log(`${meta.name}, upload failed...`);
    } else {
      console.log(`status: ${status}, name: ${meta.name}`);
    }
  };

  return (
    <section className="p-5">
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={true}
        inputContent={
          <span className="upload-files">
            Drop your file here or <span className="text-primary">browse</span>
          </span>
        }
        accept="image/*,.mp4,.pdf,.docx,.doc"
      />
      <AttachmentsList />
    </section>
  );
};

export default Attachments;
