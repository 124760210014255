import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import queryString from "query-string";
import LearnMore from "../../components/Login/LearnMore";
import { Formik, Form, Field } from "formik";
import { getInvitedStaff } from "../../data/Settings/Business/Team/getInvitedStaff";
import { ListLoader } from "../../components/Layout/Shared/DataLoading";
import { StaffVerificationSchema } from "../../schemas/TeamSettings/staffVerification";
import ErrorField from "../../components/Layout/Shared/ErrorField";
import "../../styles/pages/login.scss";

const Registration = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [invitedStaff, setInvitedStaff] = useState(null);
  const [isInvalidOrUsedToken, setIsInvalidOrUsedToken] = useState(false);
  const history = useHistory();
  const goToStaffOnboarding = state => history.push(`/staff/onboarding`, state);
  const { search } = location;

  useEffect(() => {
    const init = async () => {
      let { token } = queryString.parse(search);
      const { data, hasSuccess } = await getInvitedStaff(token);
      hasSuccess ? setInvitedStaff(data) : setIsInvalidOrUsedToken(true);
      setIsLoading(false);
    };
    search ? init() : setIsLoading(false);
  }, [search]);

  const handleOnSubmit = async values => {
    const staff = {
      id: invitedStaff._id,
      token: invitedStaff.verifyToken,
      email: values.email,
      password: values.password,
      designation: invitedStaff.roleName || "Default Role Name"
    };
    goToStaffOnboarding(staff);
  };

  return (
    <>
      <div>
        <div className="container-fluid sign-in">
          <div className="row d-md-flex">
            <LearnMore />
            <div className="col-xl-6 col-lg-6 col-md-6 right order-1 order-md-2 order-lg-2 d-flex justify-content-center align-items-center">
              {isLoading ? (
                <ListLoader height="calc(95vh / 1.5)" />
              ) : isInvalidOrUsedToken ? (
                <InvalidOrUsedToken />
              ) : (
                <div className="box">
                  <h2>
                    Verification
                    <small>
                      Email verification successful! Please complete
                      registration to continue.
                    </small>
                  </h2>
                  <div className="row">
                    <div className="col-12">
                      <h2 className="mb-3">
                        <small>
                          Welcome to iRadar Immigration,
                          <span className="text-primary-blue">
                            {invitedStaff.businessName}
                          </span>
                          <br /> invited you to join.
                        </small>
                      </h2>
                    </div>
                    <div className="col-12">
                      <Formik
                        initialValues={{
                          email: invitedStaff.email,
                          password: "",
                          confirmPassword: ""
                        }}
                        validationSchema={StaffVerificationSchema}
                        onSubmit={handleOnSubmit}
                      >
                        {({ errors }) => (
                          <Form>
                            <div className="form-group">
                              <Field
                                type="email"
                                className="custom-input"
                                placeholder="Email Address"
                                name="email"
                                disabled={true}
                              />
                            </div>
                            <div className="form-group">
                              <Field
                                type="password"
                                className="custom-input"
                                placeholder="Password"
                                name="password"
                              />
                              <ErrorField message={errors.password} />
                            </div>
                            <div className="form-group">
                              <Field
                                type="password"
                                className="custom-input"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                              />
                              <ErrorField message={errors.confirmPassword} />
                            </div>
                            <button
                              type="submit"
                              disabled={isLoading}
                              className="btn btn-blue-gradient"
                            >
                              SIGN IN
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;

const InvalidOrUsedToken = () => {
  const history = useHistory();
  const goToHome = () => history.push("/");
  return (
    <>
      <div className="d-flex align-items-center flex-column">
        <center>
          <div></div>
          <span className="h1 mt-5 text-primary-blue"></span>
          <p className="mt-5">
            Invalid token or token has been already registered!
          </p>
          <button className="btn-blue btn-small mt-2" onClick={goToHome}>
            Home
          </button>
        </center>
      </div>
    </>
  );
};
