import React from "react";
import {
  RadioButtonInput,
  RadioButtonLabel,
  RadioButtonsContainer
} from "./index.styled-components";

export const RadioButton = ({
  id,
  label,
  name,
  checked,
  value,
  onChange,
  styleProps = {}
}) => (
  <>
    <RadioButtonInput
      type="radio"
      id={id ? id : value}
      name={name}
      checked={checked}
      value={value}
      onChange={({ target }) => onChange(target.value)}
      styleProps={styleProps}
    />
    <RadioButtonLabel htmlFor={id} styleProps={styleProps} checked={checked}>
      {label}
    </RadioButtonLabel>
  </>
);

export const RadioButtonGroup = ({
  options,
  name,
  selectedValue,
  onChange
}) => (
  <RadioButtonsContainer>
    {options.map(option => (
      <RadioButton
        key={option.value}
        id={option.id ? option.id : option.value}
        label={option.label}
        name={name}
        checked={selectedValue === option.value}
        value={option.value}
        onChange={onChange}
        styleProps={option.styleProps}
      />
    ))}
  </RadioButtonsContainer>
);
