import React, {useState, useEffect} from "react";
import Table from "../../../../../Layout/Shared/Table";
import {Options} from "../../../../../Layout/Shared/DropdownOptionsMenu";
import {ListLoader} from "../../../../../Layout/Shared/DataLoading";
import {ThreeDotIcon} from "../../../../../../assets/icons/icon";
import {DeleteModal} from "../../../../../Layout/Shared/Popups/Delete";
import {get as getRoles} from "../../../../../../data/Settings/Business/Team/getRoles";
import {deleteRole as deleteRoleById} from "../../../../../../data/Settings/Business/Team/deleteRole";
import {UpdateRole} from "../UpdateRole";

export const UserDefinedRoles = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [deleteRole, setDeleteRol] = useState(null);
  const [editRole, setEditRole] = useState(null);

  useEffect(() => {
    const init = () => fetchRoles();
    init();
  }, []);

  const fetchRoles = async () => {
    setIsLoading(true);
    const {hasSuccess, data} = await getRoles();
    if (hasSuccess) {
      const dataAsArray = Object.values(data);
      setRoles(dataAsArray);
      setIsLoading(false);
    }
  };

  const ActionButtons = ({row}) => (
    <Options
      arrow={false}
      buttonTitle={ThreeDotIcon}
      menuItems={
        <div>
          <button onClick={() => setEditRole(row)}>Edit</button>
          <button className="text-danger" onClick={() => setDeleteRol(row)}>
            Delete
          </button>
        </div>
      }
    />
  );

  const AccessLevelsActions = ({row}) => (
    <span className={row.globalAccessLevel === "Manager" ? "manager" : ""}>
      {row.globalAccessLevel}
    </span>
  );
  const handleDelete = async () => {
    const {hasSuccess} = await deleteRoleById(deleteRole._id);
    if (hasSuccess) {
      setRoles(roles.filter((role) => role._id !== deleteRole._id));
      setDeleteRol(null);
    }
  };

  const fields = [
    "name",
    {
      render: ({row}) => (
        <button class="btn btn-link" onClick={() => setEditRole(row)}>
          Click to view all on right column
        </button>
      ),
    },
    {render: AccessLevelsActions},
    {render: ActionButtons},
  ];
  const headers = [
    {
      displayName: "Staff Defined Roles",
      sortingFieldName: "role",
    },
    {
      displayName: "Access to Features",
      sortingFieldName: "features",
    },
    {
      displayName: "Access Level",
      sortingFieldName: "globalAccessLevel",
    },
    {
      displayName: "Actions",
      sortingFieldName: "action",
    },
  ];

  const options = {
    data: roles,
    headers,
    fields,
    itemsPerPage: 25,
    pagination: true,
    search: false,
    sort: true,
  };

  return (
    <>
      {isLoading ? (
        <ListLoader height="275px" />
      ) : (
        <section className="p-5 roles-list-table">
          <Table options={options} />
          {deleteRole && (
            <DeleteModal
              height="260px"
              title="Delete Role"
              text="You are about to delete Role. Deleting this user revokes all access to your account and settings and deletes All user’s information."
              onClose={() => setDeleteRol(null)}
              buttonText="Delete"
              cancel={false}
              onConfirm={handleDelete}
            />
          )}
        </section>
      )}
      {editRole && (
        <UpdateRole
          onClose={() => setEditRole(null)}
          role={editRole}
          refresh={fetchRoles}
        />
      )}
    </>
  );
};
