import React, {useState} from "react";
import {weekDays, defaultStart, defaultEnd} from "../../../../../../utils/date";
import {Day} from "./daySelector";
import "./index.scss";

export const WeekDays = ({days, setDaysToSave}) => {
  const [updatedSchedules, setUpdatedSchedules] = useState(days || []);

  const getSchedule = (name) => updatedSchedules.find(({day}) => day === name);
  const hasSchedule = (name) => updatedSchedules.some(({day}) => day === name);

  const setSchedule = (schedule) => {
    const index = updatedSchedules.findIndex(({day}) => day === schedule.day);
    if (index !== -1) {
      const newSchedules = [...updatedSchedules];
      if (schedule.isSelected) {
        newSchedules[index] = schedule;
      } else {
        newSchedules.splice(index, 1);
      }
      setUpdatedSchedules(newSchedules);
      setDaysToSave(newSchedules.filter((day) => day.isSelected));
      return;
    }

    const newSchedules = [...updatedSchedules];
    newSchedules.push(schedule);
    setUpdatedSchedules(newSchedules);
    setDaysToSave(newSchedules.filter((day) => day.isSelected));
  };

  return (
    <>
      {weekDays.map((day, index) => (
        <Day
          key={index}
          schedule={{
            day,
            isSelected: hasSchedule(day),
            start: getSchedule(day)?.start || defaultStart,
            end: getSchedule(day)?.end || defaultEnd,
          }}
          onChange={setSchedule}
        ></Day>
      ))}
    </>
  );
};
