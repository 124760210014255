import {AttachmentList} from "../../../../Shared/AttachmentList";
const attachments = [
  {
    name: "Test File",
    size: "520",
    location: "",
  },
];
export const Attachments = () => {
  return <AttachmentList attachments={attachments} buttonTitle="Download" />;
};
