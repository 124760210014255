import React, {useState, useEffect} from "react";
import Modal from "../../../../../Layout/Shared/Modal";
import {CustomButton} from "../../../../../Layout/Shared/Button";
import ErrorField from "../../../../../Layout/Shared/ErrorField";
import {Number, Cvc, Expiration} from "react-credit-card-primitives";
import {order} from "../../../../../../utils/array";
// import {paymentOptions as options} from "../../../../../../static/Settings/Account/business";
import {paymentOptions} from "../../../../../../static/Settings/Account/business";
import {string} from "yup";
import {useBusinessAccountSettingsContext} from "../../../../../../providers/Settings/Account/business";
import {addPaymentMethod} from "../../../../../../data/Settings/Business/Account/addPaymentMethod";
import DataLoading from "../../../../../Layout/Shared/DataLoading/index";
import "./index.scss";

export const AddPaymentMethod = ({onClose}) => {
  const {refresh} = useBusinessAccountSettingsContext();
  // const [paymentOptions, setPaymentOptions] = useState(options);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvvCode, setCVVCode] = useState("");
  const [isDefault, setIsDefault] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [firstNameValidationError, setFirstNameValidationError] = useState(
    "First Name is required!"
  );
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [lastNameValidationError, setLastNameValidationError] = useState(
    "Last Name is required!"
  );
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [isExpiryValid, setIsExpiryValid] = useState(false);
  const [isCVVCodeValid, setIsCVVCodeValid] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);

  const validateFirstName = async (value) => {
    try {
      await string()
        .required("First Name is required!")
        .min(3, "First Name is too Short!")
        .max(50, "First Name is too Long!")
        .validate(value);
    } catch (error) {
      return error;
    }
  };

  const validateLastName = async (value) => {
    try {
      await string()
        .required("Last Name is required")
        .min(3, "Last Name is too Short!")
        .max(50, "Last Name is too Long!")
        .validate(value);
    } catch (error) {
      return error;
    }
  };

  const handleFirstName = async (value) => {
    setFirstName(value);
    const validationResponse = await validateFirstName(value);
    if (validationResponse && validationResponse.errors) {
      setFirstNameValidationError(validationResponse.errors.shift());
      setIsFirstNameValid(false);
    } else {
      setFirstNameValidationError(null);
      setIsFirstNameValid(true);
    }
  };

  const handleLastName = async (value) => {
    setLastName(value);
    const validationResponse = await validateLastName(value);
    if (validationResponse && validationResponse.errors) {
      setLastNameValidationError(validationResponse.errors.shift());
      setIsLastNameValid(false);
    } else {
      setLastNameValidationError(null);
      setIsLastNameValid(true);
    }
  };

  const handleCardNumber = (value, valid, type) => {
    setCardNumber(value);
    setIsCardNumberValid(valid);
    setCardType(type);
  };

  const handleExpiry = (month, year, valid) => {
    setExpiry({year, month});
    setIsExpiryValid(valid);
  };

  const handleCVVCode = (value, valid) => {
    setCVVCode(value);
    setIsCVVCodeValid(valid);
  };

  const handleOnSubmit = async () => {
    setSubmitted(true);
    if (
      !firstName ||
      !lastName ||
      !isCardNumberValid ||
      !isExpiryValid ||
      !isCVVCodeValid
    )
      return;

    setIsSaving(true);
    const paymentMethod = {
      paymentOption: "Chargebee",
      nameOnCard: `${firstName} ${lastName}`,
      firstName,
      lastName,
      cardNumber,
      cardType,
      expiry,
      cvvCode,
      isDefault,
    };
    const {hasSuccess, data} = await addPaymentMethod(paymentMethod);
    if (hasSuccess) {
      setIsSaving(false);
      refresh();
    } else {
      console.log(data);
      setWarningMessage(
        "Error on adding payment method. Please check information you added"
      );
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setWarningMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [warningMessage]);

  /*
  const handlePaymentOptionOnChange = (paymentOption) =>
    setPaymentOptions([
      ...paymentOptions
        .filter((po) => po.name !== paymentOption.name)
        .map((po) => ({...po, selected: false})),
      {...paymentOption, selected: true},
    ]);
  */
  return (
    <Modal title="Add New Payment Method" close={onClose}>
      {isSaving ? (
        <DataLoading height="400px" />
      ) : (
        <>
          <div className="add-payment-window">
            <div className="add-payment-method">
              <div className="wrapper">
                <div className="border-end-0">
                  <div className="d-block title-md pb-2">
                    Select Payment Option
                  </div>
                  <ul>
                    {paymentOptions.sort(order).map((item, index) => (
                      <li key={index}>
                        <button
                          disabled={item.selected ? "disabled" : null}
                          className={item.className}
                          // onClick={() => handlePaymentOptionOnChange(item)}
                        >
                          {item.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="ps-5">
                  <div className="d-block title-md pb-2">Add Details</div>
                  <div className="form-element">
                    <label>
                      <strong>First Name</strong>
                    </label>
                    <input
                      name="firstName"
                      type="text"
                      className="custom-input"
                      placeholder="John"
                      defaultValue={firstName}
                      onChange={({target}) => handleFirstName(target.value)}
                    />
                    {!isFirstNameValid && submitted && (
                      <ErrorField message={firstNameValidationError} />
                    )}
                  </div>
                  <div className="form-element">
                    <label>
                      <strong>Last Name</strong>
                    </label>
                    <input
                      name="lastName"
                      type="text"
                      className="custom-input"
                      placeholder="Doe"
                      defaultValue={lastName}
                      onChange={({target}) => handleLastName(target.value)}
                    />
                    {!isLastNameValid && submitted && (
                      <ErrorField message={lastNameValidationError} />
                    )}
                  </div>
                  <div className="form-element">
                    <label>
                      <strong>Card Number</strong>
                    </label>
                    <Number
                      onChange={({value, valid, type}) =>
                        handleCardNumber(value, valid, type)
                      }
                      render={({getInputProps, valid}) => (
                        <input
                          type="card"
                          name="cardNumber"
                          placeholder="0000 0000 0000 0000"
                          id="cardnumber"
                          {...getInputProps()}
                          className={
                            valid ? "custom-input" : "custom-input error"
                          }
                        />
                      )}
                    />
                    {!isCardNumberValid && submitted && (
                      <ErrorField message={"Invalid credit card number"} />
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-element me-2  ">
                      <label>
                        <strong>Expiry</strong>
                      </label>
                      <Expiration
                        onChange={({month, year, valid}) =>
                          handleExpiry(month, year, valid)
                        }
                        render={({getInputProps, valid, error}) => (
                          <div>
                            <input
                              placeholder="00 / 00"
                              {...getInputProps()}
                              className={
                                valid ? "custom-input" : "custom-input error"
                              }
                            />
                          </div>
                        )}
                      />
                      {!isExpiryValid && submitted && (
                        <ErrorField message={"Invalid expiry date"} />
                      )}
                    </div>
                    <div className="form-element ms-2 ">
                      <label>
                        <strong>CVV Code</strong>
                      </label>
                      <Cvc
                        onChange={({value, valid}) =>
                          handleCVVCode(value, valid)
                        }
                        render={({getInputProps, valid}) => (
                          <input
                            {...getInputProps()}
                            className={
                              valid ? "custom-input" : "custom-input error"
                            }
                          />
                        )}
                      />
                      {!isCVVCodeValid && submitted && (
                        <ErrorField message={"Invalid expiry date"} />
                      )}
                    </div>
                  </div>
                  <div className="form-element">
                    <input
                      name="default"
                      type="checkbox"
                      id="default"
                      defaultChecked={isDefault}
                      onChange={({target}) => setIsDefault(target.checked)}
                    />
                    <label htmlFor="default">
                      Make it default payment method
                    </label>
                  </div>
                  <div className="bottom mt-5 pt-5 w-100 d-flex justify-content-end">
                    <button
                      className="btn btn-link font-weight-bold me-5 "
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <CustomButton
                      color="blue"
                      title="Add Method"
                      size="220px"
                      onClick={handleOnSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              className="external-link btn-text-blue text-left"
              onClick="return false;"
            >
              Would like to talk to our support agent?
            </button>
          </div>
          {warningMessage && (
            <div className="notifications">
              <div className="warning">
                <span>Warning</span>
                <p>{warningMessage}</p>
                <button onClick={() => setWarningMessage(null)}>&times;</button>
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};
