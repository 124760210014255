import {RenderHtml} from "../../../Layout/Shared/RenderHtml";
import * as Showdown from "showdown";
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});
export const ServiceSummary = ({summary}) => (
  <div className="summary">
    <h4>Summary</h4>
    <RenderHtml renderAs={"div"} html={converter.makeHtml(summary)} />
  </div>
);
