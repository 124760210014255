import React from "react";
import COUNTRIES from "../../.././../static/Shared/countries";
import {Autocomplete} from "../Autocomplete";

export const CountryInput = (props) => {
  return (
    <Autocomplete
      options={COUNTRIES.map((country) => ({
        id: country.code,
        text: country.name,
      }))}
      {...props}
    />
  );
};
