import React from "react";
import {
  StyledStatusBadge,
  StyledPriorityBadge,
  StyledCancelledBadge
} from "./index.styled-components";
import { RadioButtonGroup } from "../../Layout/Shared/RadioButtonBadge";
import { PRIORITY, STATUS, PRIVATE } from "../../../constants/case-management";

export const CaseManagementStatus = ({
  id = "",
  name,
  selectedValue,
  onChange
}) => {
  const OPTIONS = STATUS.map(option => ({
    ...option,
    id: `${id}-${option.value}`
  }));
  return (
    <RadioButtonGroup
      options={OPTIONS}
      name={name}
      selectedValue={selectedValue}
      onChange={onChange}
    />
  );
};

export const CaseManagementPriority = ({
  id = "",
  name,
  selectedValue,
  onChange
}) => {
  const OPTIONS = PRIORITY.map(option => ({
    ...option,
    id: `${id}-${option.value}`
  }));
  return (
    <RadioButtonGroup
      options={OPTIONS}
      name={name}
      selectedValue={selectedValue}
      onChange={onChange}
    />
  );
};

export const CaseManagementPrivate = ({
  id = "",
  name,
  selectedValue,
  onChange
}) => {
  const options = PRIVATE.map(option => ({
    ...option,
    id: `${id}-${option.value}`
  }));
  return (
    <RadioButtonGroup
      options={options}
      name={name}
      selectedValue={selectedValue}
      onChange={onChange}
    />
  );
};

export const StatusBadge = ({ status }) => (
  <StyledStatusBadge status={status}>{status}</StyledStatusBadge>
);

export const PriorityBadge = ({ priority }) => (
  <StyledPriorityBadge priority={priority}>{priority}</StyledPriorityBadge>
);

export const CancelledBadge = ({ cancelled }) => (
  <StyledCancelledBadge cancelled={cancelled}>
    {cancelled ? "Cancelled" : "Active"}
  </StyledCancelledBadge>
);

export const DisplayNo = ({ no = "" }) => {
  const displayText = no ? `#${no}` : "N/A";
  return <div style={{ color: "#2E8DE5" }}>{displayText}</div>;
};
