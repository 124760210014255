import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledTemplateCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dee2e0;
  background: #fff;
  border-radius: 0.8rem !important;
`

export const StyledLink = styled(Link)`
  color: #495057;
  display: flex;
  * {
    margin-left: 6px;
  }
`

export const StyledGridList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  grid-gap: 2rem;
  padding: 1rem 2rem;
`

export const StyledGridCard = styled.a`
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  color: #495057;
  &:hover {
    background-color: #f8f8f8;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }
`

export const StyledImageContainer = styled.div`
  display: flex;
  max-height: 300px;
  overflow: hidden;
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
`

export const StyledBody = styled.div`
  padding: 2rem 1rem;
`

export const StyledTitle = styled.h5`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
`

export const StyledDescription = styled.p`
  font-size: 14px;
`

export const StyledTemplateCard = styled(Link)`
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #495057;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    text-decoration: none;
  }
`
