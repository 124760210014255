import moment from "moment";
import "./index.scss";
const slots = [];
export const SlotList = () => {
  return (
    <ul className="select-slot-list">
      {slots.map((slot, index) => (
        <li className="slot">
          <input type="checkbox" name="slot" id={slot.time} />
          <label htmlFor={slot.time}>
            <span> {moment(slot.time).format("hh:mm A")}</span>
            <span>Available</span>
          </label>
        </li>
      ))}
    </ul>
  );
};
