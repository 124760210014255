import React, {useContext} from "react";
import {Formik, Form, Field} from "formik";
import LocationImage from "../../../assets/images/locationImage.png";
import ClientOnboardingContext from "../../../providers/Onboarding/client";
import {YourAddressSchema} from "../../../schemas/Onboarding/Client/yourAddress";
import ErrorField from "../../../components/Layout/Shared/ErrorField";

import steps from "./steps";

const YourAddress = ({goTo}) => {
  const {addressInformation} = useContext(ClientOnboardingContext);
  const {address, setAddress} = addressInformation;

  const onSubmit = (values) => {
    setAddress(values);
    goTo(steps.INDUSTRY);
  };
  return (
    <div className="tab active" id="location">
      <div className="row">
        <div className="col-xl-6  col-lg-6  pl-0">
          <div className="component first">
            <h2>
              <strong>Speak with service providers</strong>
            </h2>
            <div>
              <Formik
                validationSchema={YourAddressSchema}
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={address}
                onSubmit={onSubmit}
              >
                {({errors}) => (
                  <Form>
                    <div className="form-group">
                      <label>
                        <strong>Address</strong>
                      </label>
                      <Field
                        className="custom-input"
                        placeholder="Street Address"
                        name="address"
                      />
                    </div>

                    <div className="form-group">
                      <div className="d-flex justify-content-between">
                        <Field
                          className="custom-input w-50"
                          placeholder="City"
                          name="city"
                        />
                        <Field
                          className="custom-input w-50"
                          placeholder="State"
                          name="state"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="d-flex justify-content-between">
                        <Field
                          className="custom-input w-50"
                          placeholder="Country"
                          name="country"
                        />
                        <Field
                          className="custom-input w-50"
                          placeholder="Zip Code"
                          name="zipCode"
                        />
                      </div>
                      <ErrorField message={errors.address} />
                      <ErrorField message={errors.city} />
                      <ErrorField message={errors.state} />
                      <ErrorField message={errors.country} />
                      <ErrorField message={errors.zipCode} />
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="submit"
                        className="btn btn-blue-gradient btn-steps"
                      >
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-md-none d-lg-none d-xl-block text-end">
          <div className="component second">
            <img src={LocationImage} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourAddress;
