import React from "react";
import "./index.scss";

export const SwitchButton = ({
  status,
  onChange,
  small,
  label,
  disabled,
  ...props
}) => (
  <div
    className={
      disabled
        ? "switch-button disabled"
        : small
        ? "switch-button small"
        : "switch-button"
    }
  >
    <label>
      <input
        {...props}
        type="checkbox"
        name="name"
        checked={status ? "checked" : ""}
        onChange={({ target }) => onChange(target.checked)}
        disabled={disabled}
      />
      <i></i>
      {label && <span>{label}</span>}
    </label>
  </div>
);
