import * as Yup from "yup";

export const YourInformationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "FirstName is too short")
    .max(100, "FirstName is too long")
    .required("FirstName is required"),
  lastName: Yup.string()
    .min(2, "LastName is too short")
    .max(50, "LastName is too long")
    .required("LastName is required"),
});
