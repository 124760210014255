import styled, { css } from "styled-components";

export const StyledSendMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  padding: 0 2rem;
`;
export const StyledSendMessageFileInputContainer = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  position: relative;
`;
export const StyledSendMessageFileInput = styled.input`
  display: none;
  position: relative;

  & + label {
    content: "";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #dee2e6;
    cursor: pointer;
  }
`;
export const StyledSendMessageTextInputContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto 36px;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  align-items: center;
  margin-left: 2rem;
  height: 40px;
  border: 1px solid #dee2e6;
  border-radius: 20px;
  padding-left: 1.5rem;
`;
export const StyledSendMessageTextInput = styled.input`
  border: none;
`;
export const StyledSendMessageButton = styled.button`
  ${({ disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ${disabled ? "#EEE": "#0d6efd"};
    border: none; 
    svg {
      path {
        fill:  ${disabled ? "#BBB": "#fff"};;
      }
    }
  `}
`;
