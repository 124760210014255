import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "../../../../Layout/Shared/Table";
import "./index.scss";
import { ViewSwitch } from "../../../../Layout/Shared/ViewSwitch";
import { CompanyNameWithLogo } from "../../../../Layout/Shared/NameWithLogo";
import { ServiceListGrid } from "./gridList";
import { ClientServiceRating } from "./Rating";
import CountrySelect from "../../../../Layout/Shared/Selects/CountrySelect";
import StateSelect from "../../../../Layout/Shared/Selects/StateSelect";
import CitySelect from "../../../../Layout/Shared/Selects/CitySelect";
import { getCountryNameByCountryCode } from "../../../../../static/Shared/countries";
import { getStateNameByStateCode } from "../../../../../static/Shared/states";
import { servicesSearch } from "../../../../../data/Service/Client/servicesSearch";
import DataLoading from "../../../../Layout/Shared/DataLoading";
import { RenderHtml } from "../../../../Layout/Shared/RenderHtml";
import { defaultIndustries } from "../../../../../components/Onboarding/defaultValues";
import { FILTERED_COUNTRIES_CODES } from "../../../../../static/Shared/countries";
import {
  DEFAULT_SELECTION_VALUE,
  isDefaultSelection
} from "../../../../../utils/string";
import * as Showdown from "showdown";
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

const ClientServices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);

  const [viewType, setViewType] = useState("list");

  const [currentIndustry, setCurrentIndustry] = useState(null);
  const [currentCountryCode, setCurrentCountryCode] = useState(null);
  const [currentStateCode, setCurrentStateCode] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const GoToService = ({ service }) => <Link
    to={{
      pathname: `/client/service/detail/${service._id}`,
      state: {
        relevant: service
      }
    }
    }>{service.name}</Link>;

  const handleIndustryOnSelect = ({ value }) =>
    setCurrentIndustry(!isDefaultSelection(value) ? value : null);
  const handleCountryOnSelect = ({ value }) => {
    setCurrentCountryCode(value);
    if (isDefaultSelection(value)) setCurrentStateCode(value);
  };
  const handleStateOnSelect = ({ value }) => setCurrentStateCode(value);
  const handleCityOnSelect = ({ text }) => setCurrentCity(text);

  useEffect(() => {
    getData();
  }, []);

  const getData = async filter => {
    setIsLoading(true);
    const { data: response, hasSuccess } = await servicesSearch(filter);
    hasSuccess
      ? setServices(response)
      : console.log(JSON.parse(response).description);
    setIsLoading(false);
  };

  const handleOnSubmit = async () => {
    const country = getCountryNameByCountryCode(currentCountryCode);
    const state = getStateNameByStateCode(currentStateCode);

    const filter = {};

    if (currentIndustry && currentIndustry !== "")
      filter.industry = currentIndustry;
    if (country && country !== "") filter.country = country;
    if (state && state !== "") filter.state = state;
    if (currentCity && currentCity !== "") filter.city = currentCity;
    if (searchTerm && searchTerm !== "") filter.searchTerm = searchTerm;

    await getData(filter);
  };

  const fields = [
    {
      render: ({ row }) => <GoToService service={row} />
    },
    {
      render: ({ row }) => (
        <RenderHtml
          renderAs={"div"}
          html={converter.makeHtml(row.shortDescription)}
        />
      )
    },
    {
      render: ({ row }) =>
        row.schedule?.days?.length > 0 ? (
          <span>{row.schedule?.days?.length} Day(s) a Week</span>
        ) : (
          <span>N/A</span>
        )
    },
    {
      render: ({ row }) => (
        <CompanyNameWithLogo logo={row.businessLogo} name={row.businessName} />
      )
    },
    { render: () => <ClientServiceRating rating={4} count={125} /> }
  ];
  const headers = [
    {
      displayName: "Service Name",
      sortingFieldName: "name"
    },
    {
      displayName: "Description",
      sortingFieldName: "description"
    },
    {
      displayName: "Availability",
      sortingFieldName: "availability"
    },
    {
      displayName: "Company",
      sortingFieldName: "company"
    },
    {
      displayName: "Reviews",
      sortingFieldName: "reviews"
    }
  ];
  const options = {
    data: services,
    headers,
    fields,
    pagination: true,
    search: false,
    sort: true
  };

  return (
    <div className="client-service-list rounded-xl">
      <div className="row ps-5 pe-5  pt-5 pb-4 border-bottom">
        <div className="col-xl-7 mb-4 d-flex">
          <select
            className="primary-select me-2  "
            onChange={({ target }) => handleIndustryOnSelect(target)}
          >
            <option value={DEFAULT_SELECTION_VALUE}>Select Services Category</option>
            {defaultIndustries
              .filter(industry => industry.isIndustry)
              .map(industry => (
                <option value={industry.name}>{industry.name}</option>
              ))}
          </select>
          <CountrySelect
            filteredCodes={FILTERED_COUNTRIES_CODES}
            onChange={({ target }) => handleCountryOnSelect(target)}
          />
          <StateSelect
            countryCode={currentCountryCode}
            onChange={({ target }) => handleStateOnSelect(target)}
          />
          <CitySelect
            className="custom-input ms-2 "
            countryCode={currentCountryCode}
            stateCode={currentStateCode}
            onSelect={city => handleCityOnSelect(city)}
          />
        </div>
        <div className="col-xl-5 d-flex justify-content-between">
          <div className="d-flex w-75">
            <input
              className="custom-input w-100"
              placeholder="Search here"
              value={searchTerm}
              onChange={({ target }) => setSearchTerm(target.value)}
            />
            <button
              className="btn btn-blue btn-small ms-3 "
              onClick={handleOnSubmit}
            >
              Search
            </button>
          </div>
          <div>
            <ViewSwitch
              grid={() => setViewType("grid")}
              list={() => setViewType("list")}
              active={viewType}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <DataLoading height="calc(90vh)" />
      ) : (
        <div className="ps-5 pe-5 ">
          {viewType === "list" ? (
            <Table options={options} />
          ) : (
            <ServiceListGrid services={services} />
          )}
        </div>
      )}
    </div>
  );
};
export default ClientServices;
