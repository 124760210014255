import React from "react";
import {DropdownText, RightRadiusDropdown} from "../Base/BaseStyles";
import styled from "styled-components";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";

const DropdownLi = styled.li`
  cursor: pointer;
`;

export function getLis(lis, selected, component_key, dispatch) {
  const liElements = [];
  lis = lis.filter((li) => li !== selected);
  for (const li of lis) {
    liElements.push(
      <DropdownLi
        key={li}
        onClick={(event) => {
          event.preventDefault();
          dispatch({
            type: "heading_dropdown_change",
            value: event.target.lastChild.nodeValue,
            key: component_key,
          });
        }}
      >
        <DropdownText className="dropdown-item">{li}</DropdownText>
      </DropdownLi>
    );
  }
  return liElements;
}

export function Dropdown({attributes, dispatch, component_key}) {
  return (
    <div>
      <div className="dropdown">
        <RightRadiusDropdown
          className="btn dropdown-toggle formBuilder"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {getAttribute("selected_type", attributes)}
        </RightRadiusDropdown>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {getLis(
            getAttribute("types", attributes),
            getAttribute("selected_type", attributes),
            component_key,
            dispatch
          )}
        </ul>
      </div>
    </div>
  );
}

export default Dropdown;
