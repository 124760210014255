import React, { useState } from 'react'
import { CustomSelectFile } from '../../../../Layout/Shared/SelectFile'
import { getBase64 } from '../../../../../utils/base64'
import { uploadImage } from '../../../../../data/Settings/Business/uploadImage'
import { ListLoader } from '../../../../Layout/Shared/DataLoading'

export const ProfileCard = ({
  title,
  buttonValue,
  imageUrl,
  setImageUrl,
  source,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const handleFile = async ({ files }) => {
    setIsLoading(true)
    const file = files[0]
    const payload = {
      name: file.name,
      type: file.type,
      file: await getBase64(file),
      source: source,
    }
    const {
      hasSuccess,
      data,
    } = await uploadImage(payload)
    hasSuccess && setImageUrl(data.Location)
    setIsLoading(false)
  }

  return (
    <div className="card border-0 w-75">
      <div className="h2  pb-5">{title}</div>
      <figure className="border rounded-xxl overflow-hidden">
        <img
          src={imageUrl}
          onLoad={() =>
            setTimeout(() => {
              setIsLoading(false)
            }, 500)
          }
          alt={title}
          className="w-100"
          style={{ display: isLoading ? 'none' : 'block' }}
        />
        {isLoading && <ListLoader height={'213px'} />}
      </figure>

      {!disabled && (
        <div className="card-body p-0 mt-5">
          <CustomSelectFile
            label={buttonValue}
            size="100%"
            onChange={(e) => handleFile(e.target)}
            accept="image/*"
          />
        </div>
      )}
    </div>
  )
}
