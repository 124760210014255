import React from "react";

const AttachmentDownloadIcon = ({ file, valType, val}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.34492 1.64572C5.24984 1.83239 5.24984 2.07739 5.24984 2.56797V6.41797H4.55159C4.0365 6.41797 3.77867 6.41797 3.65675 6.52064C3.60444 6.56461 3.56315 6.62023 3.53621 6.68304C3.50926 6.74584 3.49741 6.81409 3.50159 6.8823C3.5115 7.04213 3.68942 7.22822 4.04467 7.60097L6.4935 10.1682C6.66967 10.3537 6.75775 10.4459 6.861 10.4803C6.9511 10.5105 7.04858 10.5105 7.13867 10.4803C7.24192 10.4459 7.33 10.3537 7.50617 10.1682L9.955 7.60155C10.3108 7.22822 10.4882 7.04155 10.4975 6.8823C10.5018 6.81414 10.49 6.74592 10.4631 6.68312C10.4363 6.62031 10.3951 6.56467 10.3429 6.52064C10.221 6.41797 9.96375 6.41797 9.44809 6.41797H8.74984V2.56797C8.74984 2.07797 8.74984 1.83297 8.65417 1.64572C8.57041 1.48102 8.43666 1.34707 8.27209 1.26305C8.08542 1.16797 7.84042 1.16797 7.34984 1.16797H6.64984C6.15984 1.16797 5.91484 1.16797 5.72759 1.26305C5.5628 1.34697 5.42883 1.48093 5.34492 1.64572ZM2.9165 12.2513C2.9165 12.406 2.97796 12.5544 3.08736 12.6638C3.19675 12.7732 3.34513 12.8346 3.49984 12.8346H10.4998C10.6545 12.8346 10.8029 12.7732 10.9123 12.6638C11.0217 12.5544 11.0832 12.406 11.0832 12.2513C11.0832 12.0966 11.0217 11.9482 10.9123 11.8388C10.8029 11.7294 10.6545 11.668 10.4998 11.668H3.49984C3.34513 11.668 3.19675 11.7294 3.08736 11.8388C2.97796 11.9482 2.9165 12.0966 2.9165 12.2513Z"
        fill="#006096"
      />
    </svg>
  );
};

export default AttachmentDownloadIcon;
