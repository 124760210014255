/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { PageInnerTitle } from "../../../../Layout/Shared/PageInnerTitle";
import { WeekDays } from "./WeekDays";
import { Holidays } from "./Holiday";
import BusinessServiceContext from "../../../../../providers/Service/business";
import TimezoneSelect from "../../../../Layout/Shared/Selects/TimezoneSelect";

const Schedule = () => {
  const {
    values: { schedule },
    setters: { setSchedule }
  } = useContext(BusinessServiceContext);

  const holidays = schedule && schedule.holidays ? schedule.holidays : [];

  const days = schedule && schedule.days ? schedule.days : [];

  const timeZone =
    schedule && schedule.timeZone
      ? schedule.timeZone
      : {
          timeZone: "EST",
          timeZoneName: "America/Toronto"
        };

  const [holidaysTosave, setHolidaysTosave] = useState(holidays);
  const [daysToSave, setDaysToSave] = useState(days);
  const [timezoneToSave, setTimezoneToSave] = useState(timeZone);

  useEffect(() => {
    setSchedule({
      days: daysToSave,
      holidays: holidaysTosave,
      timeZone: timezoneToSave
    });
  }, [holidaysTosave, daysToSave, timezoneToSave]);

  return (
    <section>
      <div className="d-flex justify-content-between   pt-0">
        <div className="w-50 ps-5 pt-5 pe-5 ">
          <div className="mb-5">
            <PageInnerTitle>Timezone</PageInnerTitle>
            <TimezoneSelect
              className="custom-input ml-0"
              value={timezoneToSave.timeZoneName}
              onSelect={setTimezoneToSave}
            />
          </div>
          <div className="mb-5">
            <PageInnerTitle>Set Weekly Schedule </PageInnerTitle>
            <WeekDays days={days} setDaysToSave={setDaysToSave} />
          </div>
        </div>
        <div className="w-50 ps-5 pt-5 pe-5  border-left">
          <Holidays
            currentHolidays={holidays}
            setHolidaysTosave={setHolidaysTosave}
          />
        </div>
      </div>
    </section>
  );
};
export default Schedule;
