import * as Icon from "../../../assets/icons/icon";

export const businessMenuItems = [
  {
    name: "Dashboard",
    icon: Icon.SpeedometerIcon,
    link: "/business/dashboard",
    isVisible: true,
    default: true
  },
  {
    name: "Services",
    icon: Icon.SupportIcon,
    subItems: [
      { name: "Create Service", link: "/business/service/create" },
      { name: "Current Services", link: "/business/service/list" },
      { name: "Reviews", link: "/business/service/reviews" }
    ],
    isVisible: true
  },
  {
    name: "Forms",
    icon: Icon.AdaptionFormIcon,
    subItems: [
      { name: "New Form", link: "/business/forms/create" },
      { name: "Use Template", link: "/business/forms/templates" },
      { name: "All Forms", link: "/business/forms/list" }
    ],
    isVisible: true
  },
  {
    name: "Submissions",
    icon: Icon.ShoppingListIcon,
    subItems: [
      { name: "All Submissions", link: "/business/forms/submissions" },
      {
        name: "Administrative",
        link: "/business/forms/submissions/administrative"
      },
      { name: "External", link: "/business/forms/submissions/external" },
      { name: "Survey", link: "/business/forms/submissions/survey" }
    ],
    isVisible: true
  },
  {
    name: "Programs",
    icon: Icon.PuzzleIcon,
    link: "/business/programs",
    isVisible: false
  },
  {
    name: "Sector",
    icon: Icon.PieGraphIcon,
    link: "/business/sector",
    isVisible: false
  },
  {
    name: "Analytics",
    icon: Icon.PieChartIcon,
    link: "/business/analytics",
    isVisible: false
  },
  {
    name: "Calendar",
    icon: Icon.CalendarIcon,
    link: "/business/calendar",
    isVisible: true
  },
  {
    name: "Clients",
    icon: Icon.GroupIcon,
    subItems: [
      { name: "Invite Client", link: "/business/client/create" },
      { name: "Client List", link: "/business/client/list" }
    ],
    isVisible: true
  },
  {
    name: "Contacts",
    icon: Icon.GroupIcon,
    subItems: [
      { name: "Create new contact", link: "/business/contact/create" },
      { name: "Contact List", link: "/business/contact/list" }
    ],
    isVisible: true
  },
  {
    name: "Case Management",
    icon: Icon.CaseIcon,
    subItems: [
      { name: "Cases", link: "/business/case-management/cases"},
      { name: "Create Case", link: "/business/case-management/cases/form" },
      { name: "Tasks", link: "/business/case-management/tasks" },
      { name: "Types", link: "/business/case-management/types" },
      { name: "Stages", link: "/business/case-management/stages" }
    ],
    isVisible: true
  },
  {
    name: "Messages",
    icon: Icon.MessageIcon,
    link: "/business/messages",
    isVisible: true,
    hasChatBadge: true
  },
  {
    name: "Notifications",
    icon: Icon.Bell,
    link: "/business/notifications",
    isVisible: false
  },
  {
    name: "Settings",
    icon: Icon.GearIcon,
    subItems: [
      { name: "Profile Settings", link: "/business/settings/profile" },
      { name: "Account Settings", link: "/business/settings/account" },
      { name: "Team Settings", link: "/business/settings/team" }
    ],
    isVisible: true
  }
];

export const clientMenuItems = [
  {
    name: "Dashboard",
    icon: Icon.SpeedometerIcon,
    link: "/",
    isVisible: true
  },
  {
    name: "Services",
    icon: Icon.SupportIcon,
    subItems: [
      { name: "Search Service", link: "/client/service/list" },
      { name: "Applied", link: "/client/service/applied" },
      { name: "Reviewed", link: "/client/service/reviewed" }
    ],
    isVisible: true
  },
  {
    name: "Invitation",
    icon: Icon.Bell,
    link: "/",
    isVisible: true
  },
  {
    name: "Settings",
    icon: Icon.GearIcon,
    subItems: [
      { name: "Sublink", link: "/client/dashboard" },
      { name: "Sublink", link: "/client/dashboard" }
    ],
    isVisible: true
  }
];

export const getBusinessMenuItemsByFeatures = features => {
  features = features
    .filter(
      feature => feature.level === "Manager" || feature.level === "manager"
    )
    .map(feature => feature.name);

  const defaultItemNames = businessMenuItems
    .filter(businessMenuItem => businessMenuItem.default)
    .map(businessMenuItem => businessMenuItem.name);

  features = [...features, ...defaultItemNames];

  return businessMenuItems.filter(businessMenuItem =>
    features.includes(businessMenuItem.name)
  );
};
