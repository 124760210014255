import React, { useEffect, useRef, useState, Fragment } from "react";
import { useGlobalContext } from "../../../providers/global";
import {
  ContactAvatar,
  StyledMessageCenter,
  StyledMessageContainer,
  StyledMessageMessageCenterHeader,
  LeftContainer,
  RightContainer,
  StyledDetailRightSideContainer,
  StyledTabContainer,
  StyledTab,
  StyledParticipantsListContainer,
  StyledStyledParticipantContainer,
  AttachmentsContainer,
  AttachmentContainer,
  FileDetails,
  FileName,
  DownloadBox,
  DownloadIcon,
  StyledFileNameTooltip,
  SenderMiniInfoContainer,
  VerticalLine,
  RightAlignedContainer,
  SmallAvatar,
  Tooltip,
  AvatarContainer
} from "./Chat.style";
import {
  StyledAvatar,
  StyledChatCard,
  StyledUserInfo,
  StyledUserName
} from "../Sidebar/Tabs/Chats/ChatCard/ChatCard.style";
import MessageCard from "./MessageCard/MessageCard.component";
import SendMessage from "./SendMessage/SendMessage.component";
import ThreeDotsIcon from "../../../assets/icons/three-dots-icon";
import GenericFileIcon from "../../../assets/icons/generic-file-icon";

const defaultAvatar = "https://www.w3schools.com/howto/img_avatar.png";

const truncateFileName = (name, maxLength = 16) => {
  if (name.length > maxLength) {
    const extension = name.split(".").pop();
    return `${name.substring(
      0,
      maxLength - extension.length - 1
    )}...${extension}`;
  }
  return name;
};

const Chat = ({ chat }) => {
  const { currentUser } = useGlobalContext();
  const {
    participants = [],
    messages = [],
    groupName,
    groupProfilePicture
  } = chat;
  const [showDetailRightSide, setShowDetailRightSide] = useState(false);
  const [detailRightSideTab, setDetailRightSideTab] = useState("attachments");

  const isGroupChat = participants.length > 2;

  const otherParticipant =
    !isGroupChat && participants.find(p => p.id !== currentUser.id);
  const otherParticipants =
    isGroupChat && participants.filter(p => p.id !== currentUser.id);

  const name = !isGroupChat
    ? otherParticipant?.name ||
      otherParticipant?.businessName ||
      otherParticipant?.email
    : groupName ||
      otherParticipants
        .map(p => p?.name || p?.businessName || p?.email)
        .join(", ");

  const profileImage = !isGroupChat
    ? otherParticipant?.image || defaultAvatar
    : groupProfilePicture || defaultAvatar;

  const type = !isGroupChat ? otherParticipant?.type : "group";

  const messageContainerRef = useRef();

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      setTimeout(() => {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      });
    }
  };

  scrollToBottom();

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <StyledMessageContainer>
      <div style={{ flex: 1 }}>
        <StyledMessageMessageCenterHeader type={type}>
          <LeftContainer>
            <ContactAvatar src={profileImage} alt={name} />
            {name}
          </LeftContainer>
          <RightContainer>
            <ThreeDotsIcon
              onClick={() => setShowDetailRightSide(!showDetailRightSide)}
            />
          </RightContainer>
        </StyledMessageMessageCenterHeader>
        <StyledMessageCenter ref={messageContainerRef}>
          <MessageCard
            containerRef={messageContainerRef}
            participants={participants}
            messages={messages}
          />
        </StyledMessageCenter>
        <div>
          <SendMessage />
        </div>
      </div>
      {showDetailRightSide && (
        <StyledDetailRightSideContainer>
          <StyledMessageMessageCenterHeader type={type}>
            <StyledTabContainer>
              <StyledTab
                active={detailRightSideTab === "participants"}
                onClick={() => setDetailRightSideTab("participants")}
              >
                Participants
              </StyledTab>
              <StyledTab
                active={detailRightSideTab === "attachments"}
                onClick={() => setDetailRightSideTab("attachments")}
              >
                Attachments
              </StyledTab>
            </StyledTabContainer>
          </StyledMessageMessageCenterHeader>
          {detailRightSideTab === "participants" && (
            <ParticipantsList participants={participants} />
          )}
          {detailRightSideTab === "attachments" && (
            <AttachmentsList
              attachments={messages.filter(
                m => m?.message?.type === "attachment"
              )}
              participants={participants}
            />
          )}
        </StyledDetailRightSideContainer>
      )}
    </StyledMessageContainer>
  );
};

export default Chat;

const ParticipantsList = ({ participants = [] }) => {
  return (
    <StyledParticipantsListContainer>
      {participants.map((participant, index) => (
        <Fragment key={index}>
          <StyledStyledParticipantContainer>
            <StyledChatCard
              disabled={true}
              name={participant.name}
              type={participant.type}
            >
              <StyledAvatar src={participant.image || defaultAvatar} />
              <StyledUserInfo>
                <StyledUserName>
                  {participant.name ||
                    participant.businessName ||
                    participant.email}
                </StyledUserName>
              </StyledUserInfo>
            </StyledChatCard>
          </StyledStyledParticipantContainer>
        </Fragment>
      ))}
    </StyledParticipantsListContainer>
  );
};

const AttachmentsList = ({ attachments = [], participants = [] }) => {
  const getSender = senderId => participants.find(({ id }) => id === senderId);
  const SenderInfo = ({ sender }) => (
    <AvatarContainer>
      <SmallAvatar src={sender.image || defaultAvatar} type={sender.type}/>
      <Tooltip>{sender.name}</Tooltip>
    </AvatarContainer>
  );

  return (
    <AttachmentsContainer>
      {attachments.map((attachment, index) => (
        <Fragment key={index}>
          <AttachmentContainer>
            <FileDetails>
              <GenericFileIcon
                fill="gray"
                width="24px"
                height="36px"
                margin="0 3px"
                border="solid 1px #707070"
              />
              <FileName>
                {truncateFileName(attachment.message.content.name)}
                <StyledFileNameTooltip>
                  {attachment.message.content.name}
                </StyledFileNameTooltip>
              </FileName>
            </FileDetails>
            <RightAlignedContainer>
              <SenderMiniInfoContainer>
                <SenderInfo sender={getSender(attachment.senderId)} />
              </SenderMiniInfoContainer>
              <VerticalLine />
              <DownloadBox
                href={attachment.message.content.url}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadIcon alt="Download Icon" />
              </DownloadBox>
            </RightAlignedContainer>
          </AttachmentContainer>
        </Fragment>
      ))}
    </AttachmentsContainer>
  );
};
