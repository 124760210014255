import { formsApiHostName } from "../endpoint";
import { defaultHeaders } from "../headers";

export const getTemplateCategories = async () => {
    try {
        const result = await fetch(`${formsApiHostName}/categories`, {
            method: "GET",
            headers: {
                ...defaultHeaders,
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        return {
            hasSuccess: result.status === 200,
            data: await result.json(),
        };
    } catch (error) {
        return {
            hasSuccess: false,
            data: error.message,
        };
    }
};
