import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const MySwal = withReactContent(Swal)

export const showSuccessMessage = (message) => {
  MySwal.fire({
    title: message,
    icon: 'success',
    showConfirmButton: false,
    timer: 2500,
  })
}

export const showErrorMessage = (message) => {
  MySwal.fire({
    title: message,
    icon: 'error',
    showConfirmButton: false,
    timer: 2500,
  })
}
