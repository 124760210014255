import React from "react";
import { SelectBoxBlue } from "../../Layout/Shared/SelectBoxBlue";
import {
  getAllGroups,
  getClientsInGroup
} from "../../../data/Client/Business/group";
import { exportToCSV, exportToXLSX } from "../../../utils/export";

export const ExportClient = ({ groupId }) => {
  const getContacts = async () => {
    const { data, hasSuccess } = groupId
      ? await getClientsInGroup(groupId)
      : await getAllGroups();
    return hasSuccess ? data : [];
  };

  const handleChange = async ({ target }) => {
    const contacts = await getContacts();
    if (target.value === "csv") {
      exportToCSV(contacts, "clients");
    } else if (target.value === "xlsx") {
      exportToXLSX(contacts, "clients");
    }
  };
  return (
    <>
      <SelectBoxBlue width="220px" height="44px" onChange={handleChange}>
        <option value="-1">Export Client List</option>
        <option value="csv">Export Client List .csv</option>
        <option value="xlsx">Export Client List .xlsx</option>
        ))
      </SelectBoxBlue>
    </>
  );
};
