import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router";
import Table from "../../../Layout/Shared/Table";
import {Options} from "../../../Layout/Shared/DropdownOptionsMenu";
import {ThreeDotIcon} from "../../../../assets/icons/icon";
import {DeleteModal} from "../../../Layout/Shared/Popups/Delete";
import {FormattedDate} from "../../../Layout/Shared/FormattedDate";
import DataLoading from "../../../Layout/Shared/DataLoading";
import {getMembers} from "../../../../data/Contact/Business/Group/getMembers";
import {removeContact} from "../../../../data/Contact/Business/Group/removeContact";
import DefaultImage from "../../../../assets/images/default-profile-picture.png";
import {CustomButton} from "../../../Layout/Shared/Button";
import {exportToCSV} from "../../../../utils/export";

const GroupMemberList = ({name}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showBulkDelete, setShowBulkDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const {groupId} = useParams();
  const [selectedContactForDelete, setSelectedContactForDelete] =
    useState(null);
  const history = useHistory();
  const goToCreateNewContact = () => {
    // history.push(`/business/contact/create`);
    history.push({
      pathname: `/business/contact/create`,
      state: groupId,
    });
  };

  useEffect(() => {
    const init = async () => {
      const {data, hasSuccess} = await getMembers(groupId);
      if (hasSuccess) {
        setContacts(data);
      }
      setIsLoading(false);
    };
    groupId ? init() : setIsLoading(false);
  }, [groupId]);

  const handleRemove = async () => {
    if (!selectedContactForDelete) return;
    setIsLoading(true);
    const {hasSuccess} = await removeContact(groupId, [
      selectedContactForDelete._id,
    ]);
    if (hasSuccess) {
      setContacts(
        contacts.filter(
          (contact) => contact._id !== selectedContactForDelete._id
        )
      );
      setShowDelete(false);
      setSelectedContactForDelete(null);
      setIsLoading(false);
    }
  };

  const handleBulkRemove = async () => {
    if (!contacts.some((contact) => contact.isSelected)) return;
    setIsLoading(true);
    const removedContactIds = contacts
      .filter((contact) => contact.isSelected)
      .map((contact) => contact._id);
    const {hasSuccess} = await removeContact(groupId, removedContactIds);
    if (hasSuccess) {
      setContacts(
        contacts.filter((contact) => !removedContactIds.includes(contact._id))
      );
      setShowDelete(false);
      setSelectedContactForDelete(null);
      setIsLoading(false);
    }
  };

  const handleExport = async (row) => exportToCSV([row]);

  const handleBulkExport = async () => {
    if (!contacts.some((contact) => contact.isSelected)) return;
    const exportedContacts = contacts.filter((contact) => contact.isSelected);
    exportToCSV(exportedContacts);
  };

  const Name = ({row}) => (
    <div className="avatar-in-table">
      <div className="member">
        <div>
          <img src={row.photoUrl ?? DefaultImage} alt="" />
        </div>
      </div>
      <span>{`${row.firstName} ${row.lastName}`}</span>
    </div>
  );

  const CheckBox = ({row}) => (
    <input
      type="checkbox"
      checked={row.isSelected}
      onChange={() => toggleChecked(row._id)}
    />
  );

  const toggleChecked = (id) =>
    setContacts(
      contacts.map((item) => {
        return {
          ...item,
          isSelected: item._id === id ? !item.isSelected : item.isSelected,
        };
      })
    );
  const ActionsComponent = () => (
    <div className="d-flex justify-content-end align-items-center">
      <CustomButton
        color="transparent"
        title="Add Contact"
        width="200px"
        onClick={() => goToCreateNewContact()}
      />
      <Options
        arrow={true}
        buttonTitle="Options"
        menuItems={
          <div>
            <button onClick={handleBulkExport}>Export</button>
            <button
              className="text-danger"
              onClick={() => setShowBulkDelete(true)}
            >
              Delete
            </button>
          </div>
        }
      />
    </div>
  );

  const ActionButtons = ({row}) => {
    const history = useHistory();
    const goToContactDetails = () =>
      history.push(`/business/contact/${row._id}`);
    return (
      <Options
        arrow={false}
        buttonTitle={ThreeDotIcon}
        menuItems={
          <div>
            <button onClick={goToContactDetails}>View/Edit</button>
            <button
              onClick={() => {
                handleExport(row);
              }}
            >
              Export
            </button>
            <button
              className="text-danger"
              onClick={() => {
                setSelectedContactForDelete(row);
                setShowDelete(true);
              }}
            >
              Delete
            </button>
          </div>
        }
      />
    );
  };

  const fields = [
    {render: CheckBox},
    {render: Name},
    "city",
    "email",
    "phone",
    {render: ({row}) => <FormattedDate date={row.created_at} />},
    "status",
    {render: ActionButtons},
  ];
  const headers = [
    {
      displayName: "",
      sortingFieldName: "isSelected",
    },
    {
      displayName: "Name",
      sortingFieldName: "name",
    },
    {
      displayName: "Location",
      sortingFieldName: "city",
    },
    {
      displayName: "Email",
      sortingFieldName: "email",
    },
    {
      displayName: "Phone",
      sortingFieldName: "phone",
    },
    {
      displayName: "Date Created",
      sortingFieldName: "created_at",
      sortingOptions: {dataType: "date"},
    },
    {
      displayName: "Status",
      sortingFieldName: "status",
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions",
    },
  ];
  const options = {
    data: contacts,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    headerAction: ActionsComponent,
  };
  return (
    <div>
      <div className="ps-5">
        {isLoading ? (
          <DataLoading height="700px" />
        ) : (
          <Table options={options} />
        )}
        {showDelete && (
          <DeleteModal
            title="Remove Contact"
            text={`${selectedContactForDelete.firstName} ${selectedContactForDelete.lastName} will be removed from Group of ${name}`}
            buttonText="Remove"
            onClose={() => setShowDelete(false)}
            onConfirm={handleRemove}
          />
        )}
        {showBulkDelete && (
          <DeleteModal
            title="Remove Contact(s)"
            text={`Selected contacts will be removed from Group of ${name}`}
            buttonText="Remove"
            onClose={() => setShowBulkDelete(false)}
            onConfirm={handleBulkRemove}
          />
        )}
      </div>
    </div>
  );
};

export default GroupMemberList;
