export const Headers = ({isMonthly, plan}) => (
  <>
    <span>{plan.name}</span>
    {isMonthly ? (
      <span>{plan.monthlyTitle}</span>
    ) : (
      <span>{plan.yearlyTitle}</span>
    )}
    <span>
      {isMonthly ? (
        <p className="price">{plan.monthlyPrice}</p>
      ) : (
        <p className="price">{plan.yearlyPrice}</p>
      )}
    </span>
  </>
);
