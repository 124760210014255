import {Link} from "react-router-dom";
import styled from "styled-components";

export const CreateFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dee2e0;
  background: #fff;
  border-radius: 0.8rem !important;
`;

export const StyledTitle = styled.h2`
  font-size: 20px;
  color: #495057;
  font-family: "Open Sans", sans-serif;
  padding: 1.5rem;
  font-weight: 700;
  margin-left: 1.5rem;
`;

export const StyledMessage = styled.span`
  font-size: 1.8rem;
  padding: 6rem 0;
  text-align: center;
  font-weight: 500;
  width: 100%;
`;

export const StyledOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0rem 0 4rem 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledCard = styled.div`
  display: flex;
  padding: 1.5rem;
  width: 240px;
  height: 278px;
  box-sizing: border-box;
`;

export const StyledCardTitle = styled.h3`
  margin-bottom: 18px;
  color: #495057;
  font-size: 16px;
  font-weight: bold;
`;

export const StyledCardDescription = styled.p`
  opacity: 0.9;
  color: #79818b;
  font-size: 14px;
  margin-bottom: 4rem;
`;

export const StyledCardIcon = styled.div`
  width: 48px;
  height: 48px;
  margin: 50px auto;
`;

export const StyledCardBody = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid #dee2e0;
  background: #fff;
  border-radius: 4px;
  color: #495057;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  text-align: center;
  &:hover {
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
    color: #495057;
    text-decoration: none;
  }
`;
