import React from "react";
import "./index.scss";

const DataLoading = ({height, tiles = true}) => {
  return (
    <div className="data-loader" style={{height: height}}>
      {tiles && (
        <div className="titles">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
      <div className="box"></div>
    </div>
  );
};

export const StatLoading = ({height}) => {
  return (
    <div className="data-loader" style={{height: height}}>
      <div className="titles-lg">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export const ListLoader = ({height}) => {
  return (
    <div className="pl-4 pr-4 pt-4">
      <div className="data-loader" style={{height: height}}>
        <div className="box"></div>
      </div>
    </div>
  );
};

export const FullPageLoader = () => {
  return (
    <div class="page-loader">
      <div class="sidebar-left">
        <div class="profile">
          <div class="shade">
            <div class="name-1 shade"></div>
          </div>
        </div>
        <div class="nav-group">
          <div class="label shade"></div>
          <div class="group-container">
            <div class="link shade"></div>
            <div class="link shade"></div>
            <div class="link shade"></div>
          </div>
        </div>
        <div class="nav-group">
          <div class="label shade"></div>
          <div class="group-container">
            <div class="link shade"></div>
            <div class="link shade"></div>
            <div class="link shade"> </div>
          </div>
        </div>
      </div>
      <div class="dashboard-container">
        <div class="cards">
          <div class="card-xl shade"></div>
          <div class="card shade"></div>
          <div class="card shade"></div>
        </div>
        <div class="cards">
          <div class="card-m shade"></div>
          <div class="card-m shade"></div>
        </div>
      </div>
    </div>
  );
};

export default DataLoading;
