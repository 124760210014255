import React, { useState } from "react";
import { useHistory } from "react-router";
import Header from "../../../../components/Layout/Header/Header";
import { PageContainer } from "../../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../../components/Layout/Shared/PageTitle";
import { HeaderButtons } from "../../../../components/Layout/Shared/PageHeader/HeaderButtons";
import { CustomButton } from "../../../../components/Layout/Shared/Button";
import { PieChartIcon } from "../../../../assets/icons/icon";
import { EditClientDetails } from "../../../../components/Clients/Update/ClientDetails";
import { GenericPageLeft } from "../../../../components/Layout/Shared/GenericPageLeft";
import "./index.scss";
import { BusinessClientUpdateProvider } from "../../../../providers/Client/update";
import { ImportClientManager } from "../../../../components/Clients/ImportClientManager";

const UpdateClientPage = () => {
  const history = useHistory();
  const goToCreateNewClient = () => history.push(`/business/client/create`);
  const goToContactList = () => history.push(`/business/client/list`);
  const [showImport, setShowImport] = useState(false);
  return (
    <BusinessClientUpdateProvider>
      <section className="dashboard  bg-paleGray pb-5 pr-3">
        <Header />
        <div className="container-fluid mt-5 pt-3 view-edit-contact">
          <div className="row">
            <GenericPageLeft />
            <PageContainer>
              <PageHeader>
                <PageTitle>CLIENTS</PageTitle>
                <HeaderButtons>
                  <CustomButton
                    color="transparent"
                    title="Migrate Client from Contacts"
                    size="200px"
                    onClick={() => setShowImport(true)}
                  />
                  <CustomButton
                    color="blue"
                    title="Invite Client"
                    size="150px"
                    onClick={goToCreateNewClient}
                  />
                  <button className="btn btn-icon">
                    {PieChartIcon} Analytics
                  </button>
                </HeaderButtons>
              </PageHeader>
              <EditClientDetails />
            </PageContainer>
          </div>
        </div>
        <ImportClientManager
          show={showImport}
          setShow={setShowImport}
          refresh={goToContactList}
        />
      </section>
    </BusinessClientUpdateProvider>
  );
};

export default UpdateClientPage;
