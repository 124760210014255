import "./index.scss";
export const CalendarDaily = () => {
  return (
    <div className="daily-view">
      <button className="prev"></button>
      <span>Mar 12, 2021</span>
      <button className="next"></button>
    </div>
  );
};
