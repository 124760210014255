import React, {useState} from "react";
import {CustomButton} from "../../../../../Layout/Shared/Button";
import {ButtonBack} from "../../../../../Layout/Shared/Button/ButtonBack";
import {CancelAppointmentModal} from "../../Dialogs/CancelAppointment";
import {tabs, defaultTab} from "../tabs";
import "./index.scss";
export const TabContainer = () => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [cancelAppointment, setCancelAppointment] = useState(false);
  const Component = selectedTab.component;
  return (
    <div className="bg-white  applied-service">
      <ul className="generic-page-tab">
        <li className="me-5  back-section">
          <ButtonBack title="Back" size="112px" />
        </li>
        {tabs.map((tab) => (
          <li
            key={tab.name}
            className={selectedTab.name === tab.name ? "active" : null}
          >
            <button onClick={() => setSelectedTab(tab)}>{tab.name}</button>
          </li>
        ))}
      </ul>
      <div className="right-side pt-3 pe-5 ">
        {selectedTab.name === "Reviews" ? (
          <span className="add-review-title">Write a Review</span>
        ) : (
          <CustomButton color="blue" title="Write Review" size="144px" />
        )}
      </div>
      <section>
        <Component setSelectedTab={(param) => setSelectedTab(param)} />
      </section>
      {cancelAppointment && (
        <CancelAppointmentModal
          heading="You’re about to cancel the appointment?"
          onClose={() => setCancelAppointment(false)}
        />
      )}
    </div>
  );
};
