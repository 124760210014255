import "bootstrap/dist/js/bootstrap.min.js";
import React from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {Heading} from "../Base/BaseStyles";

export function HeadingHover({attributes}) {
  let level = getAttribute("selected_type", attributes);
  level = level.substr(level.length - 1);
  const title = getAttribute("title", attributes);
  let alignment = "";
  try {
    alignment = getAttribute("selected_alignment", attributes);
  } catch (e) {
    alignment = "left";
  }

  const HeadingTag = `h${level}`;
  return (
    <div className={"row"}>
      <div className={"col-12"}>
        <Heading textAlignment={alignment}>
          <HeadingTag>{title}</HeadingTag>
        </Heading>
      </div>
    </div>
  );
}

export default HeadingHover;
