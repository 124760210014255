import React, {useState, useEffect} from "react";
import Table from "../../../../Layout/Shared/Table";
import {Options} from "../../../../Layout/Shared/DropdownOptionsMenu";
import {ListLoader} from "../../../../Layout/Shared/DataLoading";
import {DeactivateStaff} from "./DeactivateStaff";
import {ActivateStaff} from "./ActivateStaff";
import {DeleteModal} from "../../../../Layout/Shared/Popups/Delete";
import {ThreeDotIcon} from "../../../../../assets/icons/icon";
import {get as getStaffs} from "../../../../../data/Settings/Business/Team/getStaffs";
import {FormattedDate} from "../../../../Layout/Shared/FormattedDate";
import {deleteStaff as deleteById} from "../../../../../data/Settings/Business/Team/deleteStaff";
import {update} from "../../../../../data/Settings/Business/Team/updateStaff";

export const CurrentTeam = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deleteStaff, setDeleteStaff] = useState(null);
  const [staffs, setStaff] = useState([]);
  const [deactivateStaff, setDeactivateStaff] = useState(null);
  const [activateStaff, setActivateStaff] = useState(null);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await getStaffs();
      if (hasSuccess) {
        const dataAsArray = Object.values(data);
        setStaff(dataAsArray);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const ActionButtons = ({row}) => (
    <Options
      arrow={false}
      buttonTitle={ThreeDotIcon}
      menuItems={
        <div>
          <button>Edit</button>
          {row.status === "Active" ? (
            <button onClick={() => setDeactivateStaff(row)}>Deactivate</button>
          ) : (
            <button onClick={() => setActivateStaff(row)}>Activate</button>
          )}
          <button className="text-danger" onClick={() => setDeleteStaff(row)}>
            Delete
          </button>
        </div>
      }
    />
  );
  const Status = ({row}) => (
    <span className={row.status === "Active" ? "text-success" : "text-danger"}>
      {row.status}
    </span>
  );
  const handleDeleteOnConfirm = async () => {
    setIsLoading(true);
    const {hasSuccess} = await deleteById(deleteStaff._id);
    if (hasSuccess) {
      setStaff(staffs.filter((staff) => staff._id !== deleteStaff._id));
      setDeleteStaff(null);
      setIsLoading(false);
    }
  };

  const handleDeactivateStaff = async () => {
    setIsLoading(true);
    const {hasSuccess} = await update({
      _id: deactivateStaff._id,
      status: "Deactivate",
    });
    if (hasSuccess) {
      setStaff([
        ...staffs.filter((staff) => staff._id !== deactivateStaff._id),
        {...deactivateStaff, status: "Deactivate"},
      ]);
      setDeactivateStaff(null);
      setIsLoading(false);
    }
  };

  const handleActivateStaff = async () => {
    setIsLoading(true);
    const {hasSuccess} = await update({
      _id: activateStaff._id,
      status: "Active",
    });
    if (hasSuccess) {
      setStaff([
        ...staffs.filter((staff) => staff._id !== activateStaff._id),
        {...activateStaff, status: "Active"},
      ]);
      setActivateStaff(null);
      setIsLoading(false);
    }
  };

  const fields = [
    {
      render: ({row}) => (
        <span>
          {row.firstName} {row.lastName}
        </span>
      ),
    },
    "role",
    {render: ({row}) => <FormattedDate date={row.verified_at} />},
    "email",
    {
      render: ({row}) => (
        <span>{row.groups.map((group) => group.name).join(", ")}</span>
      ),
    },
    {render: Status},
    {render: ActionButtons},
  ];
  const headers = [
    {
      displayName: "Name",
      sortingFieldName: "name",
    },
    {
      displayName: "Role",
      sortingFieldName: "role",
    },
    {
      displayName: "Date of Joining",
      sortingFieldName: "verified_at",
    },
    {
      displayName: "Email",
      sortingFieldName: "email",
    },
    {
      displayName: "Group/Team",
      sortingFieldName: "groupTeam",
    },
    {
      displayName: "Status",
      sortingFieldName: "status",
    },
    {
      displayName: "Action",
      sortingFieldName: "action",
    },
  ];

  const options = {
    data: staffs,
    headers,
    fields,
    itemsPerPage: 25,
    pagination: true,
    search: false,
    sort: true,
  };

  return (
    <section className="p-5">
      {isLoading ? (
        <ListLoader height="calc(95vh - 200px)" />
      ) : (
        <Table options={options} />
      )}
      {deactivateStaff && (
        <DeactivateStaff
          onClose={() => setDeactivateStaff(null)}
          username={deactivateStaff.username}
          onConfirm={handleDeactivateStaff}
        />
      )}
      {activateStaff && (
        <ActivateStaff
          onClose={() => setActivateStaff(null)}
          username={activateStaff.username}
          onConfirm={handleActivateStaff}
        />
      )}
      {deleteStaff && (
        <DeleteModal
          height="260px"
          title="Delete Staff"
          text="You are about to delate {user_name}'s account. Deleting this staff revokes all access to your account and settings and deletes All staff’s information."
          onClose={() => setDeleteStaff(false)}
          buttonText="Delete"
          cancel={true}
          onConfirm={handleDeleteOnConfirm}
        />
      )}
    </section>
  );
};
