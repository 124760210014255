import {LabelBlueBold} from "../../../../../../Layout/Shared/LabelBlueBold";
import "./index.scss";
export const CompanyDetails = ({company}) => {
  return (
    <div className="feedback-company-details">
      <h4 className="mb-4">Company Details</h4>
      <div className="pt-1">
        <figure>
          <img src={company.logo} alt={company.name} />
        </figure>
      </div>

      <div>
        <LabelBlueBold content="Company Name" />
        <span>{company.name}</span>
      </div>
      <div>
        <LabelBlueBold content="Service Name" />
        <span>{company.service}</span>
      </div>
      <div>
        <LabelBlueBold content="Consultant Name" />
        <span>{company.consultant}</span>
      </div>
    </div>
  );
};
