export const PageDetails = ({title, children}) => {
  return (
    <div>
      <div className="d-block h4 fw-bold mt-5 ms-5 text-primary-blue pb-2">
        {title}
      </div>
      {children}
    </div>
  );
};
