import React, {useState, useEffect, useContext} from "react";
import LogoBlueSmall from "../../assets/logo/logoSmall";
import "../../styles/pages/Onboarding.scss";
import StaffInfo from "../../components/Onboarding/Staff/Information";
import StaffAddress from "../../components/Onboarding/Staff/Address";
import steps from "../../components/Onboarding/Staff/steps";
import {useHistory} from "react-router";
import {registrationStaff} from "../../data/Settings/Business/Team/registrationStaff";
import {FullPageLoader} from "../../components/Layout/Shared/DataLoading";
import {GlobalContext} from "../../providers/global";

const OnboardingPage = ({location}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [activeStep, setActiveStep] = useState(steps.INFORMATION);
  const [staff, setStaff] = useState(null);
  const [address, setAddress] = useState(null);
  const history = useHistory();
  const goToStaffDashboard = (state) => history.push(`/staff/dashboard`, state);
  const globalContext = useContext(GlobalContext);

  const {state} = location;
  useEffect(() => {
    if (!state) return;
    setStaff(state);
  }, [state]);

  const handleOnSubmit = async (values) => {
    setIsSaving(true);
    const invitedStaff = {...staff, ...values};
    const {hasSuccess, data} = await registrationStaff(invitedStaff);
    if (hasSuccess) {
      globalContext.login(data);
      goToStaffDashboard(data);
    }
  };

  return (
    <>
      {isSaving ? (
        <FullPageLoader height="100vh" />
      ) : (
        <section className="onboarding">
          <div className="vertical-menu">
            <LogoBlueSmall />
            <div className="tabs">
              {
                <ul>
                  <li>
                    <span
                      className={
                        activeStep === steps.INFORMATION ? "active" : ""
                      }
                      onClick={() => setActiveStep(steps.INFORMATION)}
                    >
                      Your Information
                    </span>
                  </li>
                  <li>
                    <span
                      className={activeStep === steps.ADDRESS ? "active" : ""}
                      onClick={() => setActiveStep(steps.ADDRESS)}
                    >
                      Your Address
                    </span>
                  </li>
                </ul>
              }
            </div>
          </div>
          <div className="onboarding-section">
            <header>
              Welcome!
              {activeStep === steps.ADDRESS && (
                <small>Let’s get started, by setting up your account.</small>
              )}
            </header>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="tab-content">
                    {staff && activeStep === steps.INFORMATION && (
                      <StaffInfo
                        goTo={setActiveStep}
                        staff={staff}
                        setStaff={setStaff}
                      />
                    )}
                    {activeStep === steps.ADDRESS && (
                      <StaffAddress
                        goTo={setActiveStep}
                        address={address}
                        setAddress={setAddress}
                        onSubmit={handleOnSubmit}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default OnboardingPage;
