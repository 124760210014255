import { clientApiHostName } from "../../endpoint";
import { defaultHeaders } from "../../headers";

export const create = async group => {
  try {
    const result = await fetch(`${clientApiHostName}/group`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify(group)
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const getAllGroups = async () => {
  try {
    const result = await fetch(`${clientApiHostName}/group`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const getGroupById = async id => {
  try {
    const result = await fetch(`${clientApiHostName}/group/${id}`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const updateGroup = async (id, group) => {
  try {
    const result = await fetch(`${clientApiHostName}/group/${id}`, {
      method: "PUT",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify(group)
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const deleteGroup = async id => {
  try {
    const result = await fetch(`${clientApiHostName}/group/${id}`, {
      method: "DELETE",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const addClientToGroup = async (id, clientId) => {
  try {
    const result = await fetch(
      `${clientApiHostName}/group/${id}/client/${clientId}`,
      {
        method: "PUT",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const removeClientFromGroup = async (id, clientId) => {
  try {
    const result = await fetch(
      `${clientApiHostName}/group/${id}/client/${clientId}`,
      {
        method: "DELETE",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const getClientsInGroup = async id => {
  try {
    const result = await fetch(`${clientApiHostName}/group/${id}/client`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
