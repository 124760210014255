import React, {useState} from "react";
import * as Yup from "yup";
import ErrorField from "../../../../../Layout/Shared/ErrorField";

export const NewUser = ({id, setValue, showHeaders}) => {
  const [isValid, setIsValid] = useState(true);

  const emailSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleEmailChange = ({value}) => {
    emailSchema.isValid({email: value}).then((valid) => {
      setIsValid(valid);
      setValue(id, "email", valid ? value : "");
    });
  };

  return (
    <div className="row pr-3">
      <div className="col-7">
        {showHeaders && (
          <div className="d-block font-weight-bold text-primary-blue pb-3 pl-0">
            Email Address
          </div>
        )}
        <div className="form-group mt-1 mb-4">
          <input
            type="text"
            className="custom-input"
            placeholder="email address"
            onChange={({target}) => handleEmailChange(target)}
          />
          {!isValid && <ErrorField message={"Invalid email address"} />}
        </div>
      </div>
      <div className="col-5">
        {showHeaders && (
          <div className="d-block font-weight-bold text-primary-blue pb-3 pl-0">
            Can do
          </div>
        )}
        <div className="form-group mt-1 mb-4">
          <select
            className="primary-select"
            onChange={({target}) => setValue(id, "permission", target.value)}
          >
            <option>View</option>
            <option>Comment</option>
            <option>Edit</option>
          </select>
        </div>
      </div>
    </div>
  );
};
