import styled from "styled-components";

export const StyledLoaderContainer = styled.div`
  border-radius: 8px;
  position: relative;
  width: 100%;
  * {
    animation: loading 1s linear infinite;
  }
  @keyframes loading {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

export const StyledLoaderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e1e1e1;
`;

export const BackButtonLoader = styled.div`
  max-width: 112px;
  height: 44px;
  background: #e1e1e1;
  border-radius: 6px;
  flex-grow: 1;
`;

export const ButtonGroupLoader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ButtonLoader = styled.div`
  width: 90px;
  height: 44px;
  background: #e1e1e1;
  border-radius: 30px;
  margin-left: 1rem;
`;

export const StyledLoaderBody = styled.div`
  display: grid;
  grid-template-columns: 25% auto;
`;

export const StyledLoaderLeft = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  padding: 1rem 0;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  margin-right: 1rem;
`;

export const StyledToolbarTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e1e1e1;
  div {
    width: 70%;
    height: 20px;
    background: #e1e1e1;
    border-radius: 4px;
  }
  span {
    width: 20px;
    height: 20px;
    background: #e1e1e1;
    margin-left: 1rem;
    border-radius: 4px;
  }
`;

export const StyledMockToolbar = styled.div``;

export const StyledToolbarItem = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 2rem;
  div {
    width: 80%;
    height: 20px;
    background: #e1e1e1;
    border-radius: 4px;
  }
  span {
    width: 30px;
    height: 30px;
    background: #e1e1e1;
    margin-right: 1rem;
    border-radius: 4px;
  }
`;

export const StyledLoaderRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLoaderRightHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem 20%;
  border: 1px solid #e1e1e1;
  background: #fff;
  border-radius: 4px;

  span {
    width: 320px;
    height: 20px;
    background: #e1e1e1;
    margin-right: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  div {
    width: 100%;
    height: 30px;
    background: #e1e1e1;
    border-radius: 4px;
  }
`;

export const StyledLoaderRightBody = styled.div`
  padding: 1rem;
  margin-bottom: 5%;
  width: 70%;
  border-radius: 4px;
  p {
    display: block;
    width: 60%;
    margin: 0 auto;
    height: 20px;
    background: #e1e1e1;
    margin-bottom: 3rem;
    border-radius: 4px;
  }
`;

export const StyledLoaderRightBodyHeader = styled.div`
  background: #fff;
  border: 1px solid #e1e1e1;
  padding: 4rem;
  span {
    display: block;
    width: 100%;
    height: 20px;
    background: #e1e1e1;
    margin-right: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  div {
    display: block;
    width: 100%;
    height: 20px;
    background: #e1e1e1;
    margin-top: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
`;
