import {clientRoutes} from "./client";
import {businessRoutes} from "./business";
import {staffRoutes} from "./staff";
import {defaultRoutes} from "./default";

const routes = [
  ...clientRoutes,
  ...businessRoutes,
  ...staffRoutes,
  ...defaultRoutes,
];

export default routes;
