import styled from "styled-components";
import * as theme from "../../../../../styles/theme";

export const StyledTitle = styled.div`
  font-family: ${theme.defaultFont};
  font-size: 20px;
  color: #495057;
  margin-top: 8em;
  margin-bottom: 1.6em;
  display:flex;
  justify-content:center;
`;

export const StyledRectangle = styled.div`
  border-radius: 8px;
  border: dashed 1px ${theme.azul};
  background-color: #fafdff;
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em 0;

`;

export const StyledAddElementTitle = styled.div`
  font-family: ${theme.defaultFont};
  font-weight: bold;
  font-size: 16px;
  color: #495057;
`;

export const StyledAddElementDescription = styled.div`
  font-family: ${theme.defaultFont};
  color: #79818b;
  opacity: 0.9;
  font-size: 16px;
  margin-top: 0.8em;
`;