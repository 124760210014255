import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import Table from "../../../Layout/Shared/Table";
import { Options } from "../../../Layout/Shared/DropdownOptionsMenu";
import { ThreeDotIcon } from "../../../../assets/icons/icon";
import { DeleteModal } from "../../../Layout/Shared/Popups/Delete";
import { FormattedDate } from "../../../Layout/Shared/FormattedDate";
import DataLoading from "../../../Layout/Shared/DataLoading";
import {
  getClientsInGroup,
  removeClientFromGroup
} from "../../../../data/Client/Business/group";
import DefaultImage from "../../../../assets/images/default-profile-picture.png";
import { CustomButton } from "../../../Layout/Shared/Button";
import { exportToCSV } from "../../../../utils/export";

const ClientGroupMemberList = ({ name }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showBulkDelete, setShowBulkDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const { groupId } = useParams();
  const [selectedClientForDelete, setSelectedClientForDelete] = useState(null);
  const history = useHistory();
  const goToInviteNewClient = () => {
    history.push({
      pathname: `/business/client/create`,
      state: groupId
    });
  };

  useEffect(() => {
    const init = async () => {
      const { data, hasSuccess } = await getClientsInGroup(groupId);
      if (hasSuccess) {
        setClients(data);
      }
      setIsLoading(false);
    };
    groupId ? init() : setIsLoading(false);
  }, [groupId]);

  const handleRemove = async () => {
    if (!selectedClientForDelete) return;
    setIsLoading(true);
    const { hasSuccess } = await removeClientFromGroup(groupId, [
      selectedClientForDelete._id
    ]);
    if (hasSuccess) {
      setClients(
        clients.filter(client => client._id !== selectedClientForDelete._id)
      );
      setShowDelete(false);
      setSelectedClientForDelete(null);
      setIsLoading(false);
    }
  };

  const handleBulkRemove = async () => {
    if (!clients.some(client => client.isSelected)) return;
    setIsLoading(true);
    const removedClientIds = clients
      .filter(client => client.isSelected)
      .map(client => client._id);
    const { hasSuccess } = await removeClientFromGroup(
      groupId,
      removedClientIds
    );
    if (hasSuccess) {
      setClients(
        clients.filter(client => !removedClientIds.includes(client._id))
      );
      setShowDelete(false);
      setSelectedClientForDelete(null);
      setIsLoading(false);
    }
  };

  const handleExport = async row => exportToCSV([row], "client");

  const handleBulkExport = async () => {
    if (!clients.some(client => client.isSelected)) return;
    const exportedClients = clients.filter(client => client.isSelected);
    exportToCSV(exportedClients, "clients");
  };

  const Name = ({ row }) => (
    <div className="avatar-in-table">
      <div className="member">
        <div>
          <img src={row.photoUrl ?? DefaultImage} alt="" />
        </div>
      </div>
      <span>{`${row.firstName} ${row.lastName}`}</span>
    </div>
  );

  const CheckBox = ({ row }) => (
    <input
      type="checkbox"
      checked={row.isSelected}
      onChange={() => toggleChecked(row._id)}
    />
  );

  const toggleChecked = id =>
    setClients(
      clients.map(item => {
        return {
          ...item,
          isSelected: item._id === id ? !item.isSelected : item.isSelected
        };
      })
    );
  const ActionsComponent = () => (
    <div className="d-flex justify-content-end align-items-center">
      <CustomButton
        color="transparent"
        title="Invite Client"
        width="200px"
        onClick={() => goToInviteNewClient()}
      />
      <Options
        arrow={true}
        buttonTitle="Options"
        menuItems={
          <div>
            <button onClick={handleBulkExport}>Export</button>
            <button
              className="text-danger"
              onClick={() => setShowBulkDelete(true)}
            >
              Delete
            </button>
          </div>
        }
      />
    </div>
  );

  const ActionButtons = ({ row }) => {
    const history = useHistory();
    const goToClientDetails = () => history.push(`/business/client/${row._id}`);
    return (
      <Options
        arrow={false}
        buttonTitle={ThreeDotIcon}
        menuItems={
          <div>
            <button onClick={goToClientDetails}>View/Edit</button>
            <button
              onClick={() => {
                handleExport(row);
              }}
            >
              Export
            </button>
            <button
              className="text-danger"
              onClick={() => {
                setSelectedClientForDelete(row);
                setShowDelete(true);
              }}
            >
              Delete
            </button>
          </div>
        }
      />
    );
  };

  const fields = [
    { render: CheckBox },
    { render: Name },
    "city",
    "email",
    "phone",
    { render: ({ row }) => <FormattedDate date={row.created_at} /> },
    "status",
    { render: ActionButtons }
  ];
  const headers = [
    {
      displayName: "",
      sortingFieldName: "isSelected"
    },
    {
      displayName: "Name",
      sortingFieldName: "name"
    },
    {
      displayName: "Location",
      sortingFieldName: "city"
    },
    {
      displayName: "Email",
      sortingFieldName: "email"
    },
    {
      displayName: "Phone",
      sortingFieldName: "phone"
    },
    {
      displayName: "Date Created",
      sortingFieldName: "created_at",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Status",
      sortingFieldName: "status"
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];
  const options = {
    data: clients,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    headerAction: ActionsComponent
  };
  return (
    <div>
      <div className="ps-5">
        {isLoading ? (
          <DataLoading height="700px" />
        ) : (
          <Table options={options} />
        )}
        {showDelete && (
          <DeleteModal
            title="Remove Client"
            text={`${selectedClientForDelete.firstName} ${selectedClientForDelete.lastName} will be removed from Group of ${name}`}
            buttonText="Remove"
            onClose={() => setShowDelete(false)}
            onConfirm={handleRemove}
          />
        )}
        {showBulkDelete && (
          <DeleteModal
            title="Remove Clients(s)"
            text={`Selected clients will be removed from Group of ${name}`}
            buttonText="Remove"
            onClose={() => setShowBulkDelete(false)}
            onConfirm={handleBulkRemove}
          />
        )}
      </div>
    </div>
  );
};

export default ClientGroupMemberList;
