import {hostName} from "../endpoint";
import {defaultHeaders} from "../headers";

export const resetStaffPassword = async (password, token) => {
  try {
    const result = await fetch(`${hostName}/business/update-staff-password`, {
      method: "POST",
      headers: defaultHeaders,
      body: JSON.stringify({password, token}),
    });
    const response = await result.json();
    return response.isSuccessful;
  } catch (err) {
    return false;
  }
};