import styled, { css } from "styled-components";
import * as theme from "../../../../../styles/theme";

export const StyledContainer = styled.ul`
  ${({ isGrid }) => isGrid && css`
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     gap: 1rem;
     margin-top:0.5em !important;
  `}
`

export const StyledItemContainer = styled.li``

export const StyledItemWrapper = styled.div`
${({ isGrid }) => isGrid && css`
    border: solid 1px #dee2e6;
    border-radius:6px !important;
`}
  
`

export const StyledItemButton = styled.a`
  text-decoration: none;
  width: 100%;
  padding: 0em;
  cursor: pointer;
  display:flex;
  align-items:center;
  ${({ isGrid }) => isGrid && css`
    flex-direction: column;
    padding:0.5rem;
    i  {
      padding-left: 0;
      font-size:2em;
    }
    span {
      padding-left: 0;
      font-size:15px;
      line-height:1.43;
    }
`}
`

export const StyledItemIcon = styled.i`
    font-size: 1.8em;
    padding-left: 1em;
    color: #495057;
`;

export const StyledItemLabel = styled.span`
  font-family: ${theme.defaultFont};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 270%;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 25px;
`;