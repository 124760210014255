import { validateComponents } from './validator'

const { createContext, useContext, useState } = require('react')

const FormBuilderContext = createContext(null)

export const useFormBuilderContext = () => useContext(FormBuilderContext)

export const FormBuilderContextProvider = ({ children }) => {
  const [editingForm, setEditingForm] = useState()
  const [formTitle, setFormTitle] = useState('')
  const [formTitleValidated, setFormTitleValidated] = useState(false)
  const [components, setComponents] = useState([])

  const addNewComponent = (component) => {
    setComponents([...components, component])
  }

  const updateComponent = (index, component) => {
    const clone = [...components]
    clone[index] = component

    const validatingComponents = clone.filter((c) => !!c.validations)

    if (validatingComponents.length > 0) {
      validateComponents(validatingComponents)
    }

    setComponents(clone)
  }

  const changeComponentOrder = (component, direction) => {
    const index = components.indexOf(component)
    const clone = [...components]

    if (direction === 'up' && index > 0) {
      const temp = clone[index - 1]
      clone[index - 1] = component
      clone[index] = temp
      setComponents(clone)
    } else if (direction === 'down' && index < clone.length - 1) {
      const temp = clone[index + 1]
      clone[index + 1] = component
      clone[index] = temp
      setComponents(clone)
    }
  }

  const deleteComponent = (index) => {
    const clone = [...components]
    clone.splice(index, 1)
    setComponents(clone)
  }
  const duplicateComponent = (index) => {
    const clone = [...components]
    const component = { ...clone[index] }
    clone.splice(index, 0, component)
    setComponents(clone)
  }

  const initializeNewForm = () => {
    setEditingForm(undefined)
    setFormTitle('')
    setComponents([])
  }

  const initializeEditForm = (form) => {
    form.id = form._id
    delete form._id
    setEditingForm(form)
    setFormTitle(form.title)
    setComponents(form.components)
  }

  const initializeNewFormByTemplate = (template) => {
    setEditingForm(undefined)
    setFormTitle(template.title)
    setComponents(template.components)
  }

  const hasComponent = components.length > 0
  const isEditing = !!editingForm
  const isFormTitleValid = formTitle.trim().length > 0

  const value = {
    formTitle,
    formTitleValidated,
    setFormTitleValidated,
    isFormTitleValid,
    deleteComponent,
    duplicateComponent,
    isEditing,
    editingForm,
    setFormTitle,
    initializeNewForm,
    initializeEditForm,
    initializeNewFormByTemplate,
    components,
    hasComponent,
    addNewComponent,
    updateComponent,
    changeComponentOrder,
    setComponents,
  }

  return (
    <FormBuilderContext.Provider value={value}>
      {children}
    </FormBuilderContext.Provider>
  )
}
