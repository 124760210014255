export const designations = [
  {name: "Applicants", isContact: true},
  {name: "Assistance Director", isContact: false},
  {name: "Business Owner", isContact: false},
  {name: "Client", isContact: true},
  {name: "Coordinator/ Supervisor", isContact: false},
  {name: "Deputy Director", isContact: false},
  {name: "Director", isContact: false},
  {name: "Funder", isContact: true},
  {name: "Junior Manager", isContact: false},
  {name: "Managing Director", isContact: false},
  {name: "Operatives", isContact: false},
  {name: "Partner", isContact: true},
  {name: "President", isContact: false},
  {name: "Project Lead", isContact: false},
  {name: "Senior Manager", isContact: false},
  {name: "Supervisory Management", isContact: false},
  {name: "Team Lead", isContact: false},
  {name: "Vice President", isContact: false},
  {name: "Volunteer", isContact: true},
  {name: "Others", isContact: true},
];
