import React, {useState, useContext} from "react";
import PaymentImage from "../../../../assets/images/paymentImage.png";
import {features} from "../paymentPlanDetails";
import steps from "../steps";
import "./plans.scss";
import BusinessOnboardingContext from "../../../../providers/Onboarding/business";
import {Feature} from "../../../../components/Layout/Shared/PaymentPlans/features";
import {Headers} from "../../../../components/Layout/Shared/PaymentPlans/header";

const enterpriseMessage =
  "We have got your enterprise request. \n Our team will get in touch with you shortly.";

const Plans = ({goTo}) => {
  const {planInformation, setInfoNotificationMessage, finishOnPlanInformation} =
    useContext(BusinessOnboardingContext);
  const {planDetails, setPlanDetails} = planInformation;
  const [isMonthly, setIsMonthly] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectPlan = async (selectedPlan) => {
    const updatedPlanDetails = planDetails.map((detail) => {
      return {
        ...detail,
        isMonthly,
        active: selectedPlan.name === detail.name,
      };
    });
    setPlanDetails(updatedPlanDetails);

    if (selectedPlan.name === "SMART" || selectedPlan.name === "PROFESSIONAL") {
      goTo(steps.PAYMENT);
      return;
    }

    setIsSubmitting(true);
    const response = await finishOnPlanInformation(updatedPlanDetails);
    if (response) {
      selectedPlan.name === "ENTERPRISE" &&
        setInfoNotificationMessage(enterpriseMessage);
      goTo(steps.SUCCESS);
      setIsSubmitting(false);
    }
  };
  return (
    <div className="tab active plans" id="payment">
      <div className="payment-bg d-hide">
        <img src={PaymentImage} alt="" />
      </div>
      <div className="row text-center">
        <div className="col-lg-12">
          <div className="plan-title w-100 text-center">
            <h3>Select a Plan</h3>
            <div className="period-selector">
              <label>
                <span>Monthly</span>
                <input
                  type="checkbox"
                  name="selectperiod"
                  value={isMonthly}
                  onChange={(e) => setIsMonthly(!e.target.checked)}
                />
                <i></i>
                <span>Yearly</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="payment-plans position-relative">
        <div className="plan-type">
          <div className="">
            <h3>
              <strong>FEATURES</strong>
            </h3>
            <ul className="features mt-4">
              {features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>

          {planDetails.map((plan, index) => (
            <div key={index} className={plan.active ? "active" : ""}>
              <section>
                <Headers isMonthly={isMonthly} plan={plan} />
                <Feature plan={plan} />
                <button
                  disabled={isSubmitting}
                  onClick={() => selectPlan(plan)}
                  className="mx-auto btn btn-blue-gradient btn-steps w-75"
                >
                  Select
                </button>
              </section>
            </div>
          ))}
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

export default Plans;
