import React from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {InputGroup, RadiusInput} from "../Base/BaseStyles";

export function KpiOption({component_key, dispatch, attributes}) {
  const kpi_text = getAttribute("kpi", attributes);
  return (
    <InputGroup className="input-group mt-3">
      <RadiusInput
        type="text"
        className="form-control"
        aria-label=""
        placeholder="Enter KPI"
        value={kpi_text}
        onChange={(event) =>
          dispatch({
            type: "kpi_text_change",
            kpi: event.target.value,
            key: component_key,
          })
        }
      />
    </InputGroup>
  );
}

export default KpiOption;
