import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CustomButton } from '../../../Layout/Shared/Button'
import { ButtonBack } from '../../../Layout/Shared/Button/ButtonBack'
import {
  StyledButtonWrapper,
  StyledHeader,
  StyledLeftSide,
  StyledRightSide,
} from './styles'
import { useFormBuilderContext } from '../context'

const Header = ({ isSaving, onSaveClick }) => {
  const { isEditing, editingForm } = useFormBuilderContext()
  const history = useHistory()

  const viewButton = <CustomButton color={'transparent'} title="View" />

  const buttonText = isEditing
    ? isSaving
      ? 'Updating...'
      : 'Update'
    : isSaving
    ? 'Saving...'
    : 'Save'

  return (
    <StyledHeader>
      <StyledButtonWrapper>
        <StyledLeftSide>
          <ButtonBack title="Back" onClick={() => history.goBack()} />
        </StyledLeftSide>
        <StyledRightSide>
          {isEditing && (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/preview/${editingForm.id}`}
            >
              {viewButton}
            </Link>
          )}
          <Link to={`/business/forms/list`}>
            <CustomButton color="transparent" title="Share" />
          </Link>
          <CustomButton
            disabled={isSaving}
            className="btn-blue-gradient btn-small mt-0"
            title={buttonText}
            onClick={onSaveClick}
          />
        </StyledRightSide>
      </StyledButtonWrapper>
    </StyledHeader>
  )
}

export default Header
