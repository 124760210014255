import React, { forwardRef, useImperativeHandle, useState } from 'react'
import DropdownBoundary from '../../../../utils/dropdown-boundary'

import {
  StyledActionButton,
  StyledDropdownAction,
  StyledLink,
  StyledList,
  StyledListItem,
} from './action.style'

const DropdownAction = ({ ctaText, items }, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  useImperativeHandle(
    ref,
    () => ({
      toggle: () => setIsOpen((prev) => !prev),
    }),
    [],
  )
  return (
    <StyledDropdownAction isOpen={isOpen}>
      <StyledActionButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        {ctaText}
      </StyledActionButton>
      {isOpen && (
        <DropdownBoundary callback={() => setIsOpen(false)}>
          <StyledList>
            {items.map((item) => (
              <StyledListItem key={item.slug}>
                {item.onClick ? (
                  <StyledLink as={'span'} onClick={item.onClick}>
                    {item.title}
                  </StyledLink>
                ) : (
                  <StyledLink
                    target={item.isExternal ? '_blank' : '_self'}
                    to={item.slug}
                  >
                    {item.title}
                  </StyledLink>
                )}
              </StyledListItem>
            ))}
          </StyledList>
        </DropdownBoundary>
      )}
    </StyledDropdownAction>
  )
}

export default forwardRef(DropdownAction)
