/* eslint-disable import/no-anonymous-default-export */
import { createContext, useContext, useState } from "react";

const BusinessClientListContext = createContext(null);

const useBusinessClientListContext = () => {
  const context = useContext(BusinessClientListContext);
  if (!context)
    throw new Error(
      `Can't use "useBusinessClientContext" without an BusinessClientProvider!`
    );
  return context;
};

const BusinessClientListProvider = props => {
  const [clients, setClients] = useState([]);
  const [filter, setFilter] = useState(null);
  const [showMainSection, setShowMainSection] = useState(true);
  const [showImport, setShowImport] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);

  const handleRefresh = () => {
    setShowMainSection(false);
    setTimeout(() => {
      setShowMainSection(true);
    }, 10);
  };

  const setters = { setClients, setFilter, setShowMainSection, setShowImport, setShowCreateGroup };
  const getters = { clients, filter, showMainSection, showImport, showCreateGroup };
  const values = { setters, getters, refresh: handleRefresh };

  return <BusinessClientListContext.Provider {...props} value={values} />;
};

export { BusinessClientListProvider, useBusinessClientListContext };
