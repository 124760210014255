import React from "react";
import { Autocomplete } from "../../Autocomplete";

const GenderSelect = ({ value, onSelect, ...restOfProps }) => {
  const GENDERS = ["Male", "Female", "Other", "Prefer not to say"];
  const genders = GENDERS.map(name => ({
    id: name,
    text: name
  }));
  return (
    <Autocomplete
      {...restOfProps}
      options={genders}
      placeHolder={"Select..."}
      onSelect={({ text }) => onSelect(text)}
      defaultValue={value}
    />
  );
};

export default GenderSelect;
