/* eslint-disable import/no-anonymous-default-export */
import { createContext, useContext, useState } from "react";
import {
  BusinessClientInviteSchema,
  EmailSchema
} from "../../schemas/Client/Business/invite";
import { create } from "../../data/Client/Business/create";
import { invite } from "../../data/Client/Business/invite";
import { checkClient as check } from "../../data/Register/check-client";
import { generateRandomPassword } from "../../utils/security";
import { useGlobalContext } from "../global";

const BusinessClientCreateContext = createContext(null);

const useBusinessClientCreateContext = () => {
  const context = useContext(BusinessClientCreateContext);
  if (!context)
    throw new Error(
      "BusinessClientCreateContext must be used within a BusinessClientCreateProvider"
    );
  return context;
};

const BusinessClientCreateProvider = props => {
  const { currentUser } = useGlobalContext();
  const [client, setClient] = useState({
    email: "",
    password: "",
    isImmigrant: true,
    businessId: currentUser.id
  });
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkResult, setCheckResult] = useState({
    isInUse: false,
    isInvitedByBusiness: false,
    isInvitedByAnotherBusiness: false
  });
  const [checkedClient, setCheckedClient] = useState(false);

  const validateEmailForm = async email => {
    try {
      await EmailSchema.validate({ email });
    } catch (error) {
      return error;
    }
  };

  const checkClient = async email => {
    const validationResponse = await validateEmailForm(email);
    if (validationResponse && validationResponse.errors) return false;
    const result = await check(email, currentUser.id);
    setCheckResult(result);
    setCheckedClient(true);
  };

  const validateForm = async form => {
    try {
      await BusinessClientInviteSchema.validate(form);
    } catch (error) {
      return error;
    }
  };

  const submit = async () => {
    const validationResponse = await validateForm(client);
    if (validationResponse && validationResponse.errors) {
      setErrors(validationResponse.errors);
      return false;
    }
    if (!checkedClient) return false;
    setIsSubmitting(true);
    if (!checkResult.isInUse) {
      const { hasSuccess } = await create({
        ...client,
        password: generateRandomPassword()
      });
      if (hasSuccess) {
        setIsSubmitting(false);
        return true;
      }
    } else if (checkResult.isInUse && !checkResult.isInvitedByBusiness) {
      const { hasSuccess } = await invite(client);
      if (hasSuccess) {
        setIsSubmitting(false);
        return true;
      }
    }
    return false;
  };

  const setters = {
    setClient,
    setErrors,
    setIsLoading,
    setIsSubmitting,
    setCheckedClient
  };
  const getters = {
    client,
    errors,
    isLoading,
    isSubmitting,
    checkResult,
    checkedClient
  };
  const values = { setters, getters, submit, checkClient };
  return <BusinessClientCreateContext.Provider {...props} value={values} />;
};

export { BusinessClientCreateProvider, useBusinessClientCreateContext };
