/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";

import {GenericPageLeft} from "../../../../components/Layout/Shared/GenericPageLeft";
import {PageContainer} from "../../../../components/Layout/Container/PageContainer";
import {PageTitle} from "../../../../components/Layout/Shared/PageTitle";
import {PageHeader} from "../../../../components/Layout/Shared/PageHeader";
import {getAllSubmissions} from "../../../../data/Submissions/getAllSubmissions";
import Table from "../../../../components/Layout/Shared/Table";

import {Spinner} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {Link} from "react-router-dom";

import Header from "../../../../components/Layout/Header/Header";
import {StyledTemplateCategoriesContainer} from "../../../../components/Form/Templates/templates.style";
import {getFormById} from "../../../../data/Forms/getFormById";
import {disabledStyle} from "../../../../styles/commonStyles";

const headers = [
  {
    displayName: "User",
    sortingFieldName: "businessEmail",
  },
  {
    displayName: "Last time edited",
    sortingFieldName: "updated_at",
  },
];

const AllSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const params = useParams();
  const {formId} = params;
  const [loading, setLoading] = useState(false);
  const [formName, setFormName] = useState("");
 
  const fields = [
    {
      render: ({row}) => (
        <div className="d-flex justify-content-start align-items-center">
          {row.businessEmail || "external"}
        </div>
      ),
    },
    {
      render: ({row}) => (
        <div className="d-flex justify-content-start align-items-center">
          {row.updated_at}
        </div>
      ),
    },
    {
      render: ({row}) => (
        <Link
          className="dropdown-item"
          target="_blank"
          rel="noopener noreferrer"
          to={`/business/forms/${formId}/submissions/${row._id}`}
        >
          Show
        </Link>
      ),
    },
  ];

  useEffect(() => {
    getInitialData()
    loadByFormId(formId)
  }, [])

  const getInitialData = async () => {
    setLoading(true)
    const { data: response, hasSuccess } = await getAllSubmissions(formId)
    hasSuccess
      ? setSubmissions(response?.data)
      : console.log(JSON.parse(response).description)
      setLoading(false)
  }

  
  const loadByFormId = async formId => {
    const { data: response } = await getFormById(formId);
    setFormName(response.data?.title);
  };

  return (
    <section className="dashboard  bg-paleGray pb-5 pe-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>
                All Submissions - {formName} ({submissions.length})
              </PageTitle>
            </PageHeader>
            <div
              className="form-list rounded-xl"
              style={loading ? disabledStyle : {}}
            >
              {loading && (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
              <StyledTemplateCategoriesContainer>
                <div className="p-5">
                  <Table
                    options={{
                      data: submissions,
                      headers,
                      fields,
                      pagination: true,
                      search: false,
                      sort: true,
                    }}
                  />
                </div>
              </StyledTemplateCategoriesContainer>
            </div>
          </PageContainer>
        </div>
      </div>
    </section>
  );
};

export default AllSubmissions;
