import React, {useState} from "react";
import {Schedule} from "../../../../Shared/Schedule";
import {ServiceSummary} from "../../../../Shared/Summary";
import "../../../../Business/View/Details/index.scss";
import {AppointmentConsultantDetails} from "../../AppointmentOverview/Consultant";
import {Channels} from "../../../../Shared/CommunicationChannels";
import {CustomButton} from "../../../../../Layout/Shared/Button";
import {BookAppointment} from "../../BookAppointment";
import {AppointmentOverview} from "../../AppointmentOverview";
import "./index.scss";
export const ClientServiceDetails = () => {
  const [bookAppointment, setBookAppointment] = useState(false);
  const [bookOverview, setBookOverview] = useState(false);
  const schedule = {
    days: [
      {
        day: "Sunday",
        isSelected: true,
        start: "09:00 AM",
        end: "06:00 PM",
      },
      {
        day: "Monday",
        isSelected: false,
        start: "09:00 AM",
        end: "06:00 PM",
      },
    ],
    holidays: [
      {
        name: "Public Holiday",
        date: "2021-03-16T13:44:43.594+00:00",
        recurrence: "One Time",
      },
    ],
  };
  const consultant = {
    avatar: "",
    name: "Michel Smith",
    email: "michel.smith@gmail.com",
    phone: "+1 975 5245 554",
  };
  const channels = {
    textAndMessages: "Message Centre",
    calls: "+1 975 5245 554",
    appointments: (
      <CustomButton
        color="transparent"
        title="Book An Appointment"
        siz="150px"
        onClick={() => setBookAppointment(true)}
      />
    ),
  };
  const goToOverview = () => {
    setBookAppointment(false);
    setBookOverview(true);
  };
  const goBack = () => {
    setBookAppointment(true);
    setBookOverview(false);
  };

  return (
    <div className="service-details client-service-details pt-2 ps-5">
      <ServiceSummary
        summary={
          "General Service Information saepe quis tempora voluptatum molestiae soluta vel. Aut quo facere quisquam harum. General Service Information saepe quis "
        }
      />
      <div className="details-bottom">
        <div className="detail-item">
          <Schedule schedule={schedule} />
        </div>
        <div className="detail-item">
          <AppointmentConsultantDetails consultantData={consultant} />
        </div>
        <div className="detail-item">
          <Channels userType={"client"} channels={channels} />
        </div>
      </div>
      {bookAppointment && (
        <BookAppointment
          onClose={() => setBookAppointment(false)}
          goToOverview={goToOverview}
        />
      )}
      {bookOverview && (
        <AppointmentOverview
          goBack={goBack}
          onClose={() => setBookOverview(false)}
        />
      )}
    </div>
  );
};
