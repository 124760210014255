import React, { useContext } from "react";
import BusinessServiceContext from "../../../../../providers/Service/business";
import "../../../../../styles/customComponents/servicePageHeader.scss";
import { FormList } from "../../../Shared/Forms";
import { CustomButton } from "../../../../Layout/Shared/Button";

const Forms = () => {
  const ViewButton = ({ row }) => {
    return (
      <CustomButton
        title='View'
        size="90px"
        target="_blank"
        color="blue"
        onClick={() => window.open(`/preview/${row.id}`, "_blank")}
      />
    )
  };
  const {
    service: { forms },
  } = useContext(BusinessServiceContext);
  const fields = ["id", "name", "lastModified", { render: ViewButton }];
  const headers = [
    {
      displayName: "Id",
      sortingFieldName: "id",
      sortingOptions: { dataType: "number" },
    },
    {
      displayName: "Form Name",
      sortingFieldName: "name",
    },
    {
      displayName: "Last Modified",
      sortingFieldName: "lastModified",
    },
  ];

  const options = {
    data: forms || [],
    headers,
    fields,
    itemsPerPage: 25,
    pagination: true,
    search: true,
    sort: true,
  };
  return (
    <div className="p-5">
      <FormList
        fields={fields}
        headers={headers}
        options={options}
        forms={forms}
      />
    </div>
  );
};

export default Forms;
