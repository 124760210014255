import ClientDashboard from "../../pages/Client/Dashboard";
import InvitationAfterLogin from "../../pages/Client/InvitationsAfterLogin";
import Invitation from "../../pages/Client/Invitations";
import ClientOnboardingPage from "../../pages/Client/Onboarding";
import ResetPasswordPage from "../../pages/ResetPassword";
import ClientServiceList from "../../pages/Client/Service/List";
import ServiceDetails from "../../pages/Client/Service/Detail";
import AppliedList from "../../pages/Client/Service/Applied";
import AppliedService from "../../pages/Client/Service/AppliedService";
import AppliedServiceDisabled from "../../pages/Client/Service/AppliedServiceDisabled";
import ServiceReviewed from "../../pages/Client/Service/Reviewed";
import {modules} from "../../constants/modules";
import VerifyEmailPage from "../../pages/VerifyEmail";

const clientPrefix = "client";
const servicePrefix = "service";

export const clientRoutes = [
  {
    path: `/${clientPrefix}/dashboard`,
    component: ClientDashboard,
    isPrivate: false,
    module: modules.GLOBAL,
  },
  {
    path: `/${clientPrefix}/onboarding`,
    component: ClientOnboardingPage,
    isPrivate: false,
    module: modules.GLOBAL,
  },
  {
    path: `/${clientPrefix}/${servicePrefix}/list`,
    component: ClientServiceList,
    isPrivate: true,
    module: modules.SERVICES,
  },
  {
    path: `/${clientPrefix}/${servicePrefix}/detail/:id`,
    component: ServiceDetails,
    isPrivate: true,
    module: modules.SERVICES,
  },
  {
    path: `/${clientPrefix}/${servicePrefix}/applied`,
    component: AppliedList,
    isPrivate: true,
    module: modules.SERVICES,
  },
  {
    path: `/${clientPrefix}/${servicePrefix}/applied-service`,
    component: AppliedService,
    isPrivate: true,
    module: modules.SERVICES,
  },
  {
    path: `/${clientPrefix}/${servicePrefix}/applied-service-disabled`,
    component: AppliedServiceDisabled,
    isPrivate: true,
    module: modules.SERVICES,
  },
  {
    path: `/${clientPrefix}/${servicePrefix}/reviewed`,
    component: ServiceReviewed,
    isPrivate: true,
    module: modules.SERVICES,
  },
  {
    path: `/${clientPrefix}/invitationsAfterLogin`,
    component: InvitationAfterLogin,
    isPrivate: false,
    module: modules.GLOBAL,
  },
  {
    path: `/${clientPrefix}/reset-password/:token`,
    component: ResetPasswordPage,
    isPrivate: false,
  },
  {
    path: `/${clientPrefix}/invitations`,
    component: Invitation,
    isPrivate: false,
  },
  {
    path: `/${clientPrefix}/verify-email/:token`,
    component: VerifyEmailPage,
    isPrivate: false,
  },
];
