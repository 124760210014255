import React from "react";
import "./index.scss";

const CalendarLoading = ({height}) => {
  return (
    <div className="calendar-loader">
      <div className="box" style={{height: height}}></div>
    </div>
  );
};

export default CalendarLoading;
