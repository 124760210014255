import React, {useState} from "react";
import Modal from "../../../../../Layout/Shared/Modal";
import {CustomButton} from "../../../../../Layout/Shared/Button";
import {NewUser} from "./newUser";
import {ScrollContent} from "../../../../../Layout/Shared/ScrollContent";
import ErrorField from "../../../../../Layout/Shared/ErrorField";

export const AddUserModal = ({existingUsers, onAddUser, onClose}) => {
  const initialObject = [
    {id: 1, email: "", permission: "View"},
    {id: 2, email: "", permission: "View"},
    {id: 3, email: "", permission: "View"},
  ];

  const [users, setUsers] = useState(initialObject);
  const [error, setError] = useState(null);
  const getNextId = () => users[users.length - 1].id + 1;

  const isFirstItem = (id) => id === 1;

  const setValue = (id, key, value) =>
    setUsers(
      users.map((user) =>
        id === user.id
          ? {
              ...user,
              [key]: value,
            }
          : user
      )
    );

  const addUsersToService = () => {
    const combinedUserList = [
      ...existingUsers,
      ...users.filter((user) => user.email !== ""),
    ];
    const hasError = combinedUserList.some(
      (user) =>
        combinedUserList.filter((filterUser) => filterUser.email === user.email)
          .length > 1
    );
    if (hasError) {
      setError("Each user should have a unique email address.");
      return;
    }
    onAddUser(combinedUserList);
  };

  return (
    <Modal
      title="Add Users to Collaborate"
      width="670px"
      height="auto"
      close={onClose}
    >
      <ScrollContent height="calc(100vh - 300px)">
        <>
          {users.map(({id}) => (
            <NewUser
              id={id}
              key={id}
              showHeaders={isFirstItem(id)}
              setValue={setValue}
            />
          ))}
          {error && <ErrorField message={error} />}
        </>
      </ScrollContent>

      <div className="d-flex pt-5 pb-3 align-items-center">
        <div className="flex-grow-1">
          <button
            onClick={() =>
              setUsers([
                ...users,
                {id: getNextId(), email: "", permission: "View"},
              ])
            }
            className="btn btn-text-blue me-5 "
          >
            Add More User
          </button>
        </div>
        <button className="btn btn-text-blue me-5 " onClick={onClose}>
          Cancel
        </button>
        <CustomButton
          color="blue"
          title="Add Users"
          onClick={addUsersToService}
        />
      </div>
    </Modal>
  );
};
