import * as Yup from "yup";

const FormCaseManagementStageSchema = Yup.object().shape({
  title: Yup.string()
    .required("Type title is required")
    .min(3, "Type title is too Short!")
    .max(50, "Type title is too Long!"),
  description: Yup.string()
    .required("Type description is required")
    .min(3, "Type description is too Short!")
    .max(50, "Type description is too Long!")
});

export default FormCaseManagementStageSchema;
