import moment from "moment";

export const FormattedDate = ({date}) => {
  return date !== undefined && date !== null ? (
    <>{moment(date).format("ddd MMM DD YYYY")}</>
  ) : null;
};

export const FormattedDateTime = ({date}) => {
  return date !== undefined && date !== null ? (
    <>{moment(date).format("ddd MMM DD YYYY hh:mm A")}</>
  ) : null;
}