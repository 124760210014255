import React, { useState, useEffect } from "react";
import Modal from "../../../Layout/Shared/Modal";
import { ScrollContent } from "../../../Layout/Shared/ScrollContent";
import { CustomButton } from "../../../Layout/Shared/Button";
import "../../../../styles/customComponents/shared.scss";
import "../index.scss";
import Table from "../../../Layout/Shared/Table";
import { ListLoader } from "../../../Layout/Shared/DataLoading";
import { checkClient } from "../../../../data/Register/check-client";
import { useGlobalContext } from "../../../../providers/global";
import { migrateClientsFromContacts } from "../../../../data/Client/Business/shared";

export const ImportSelectedClient = ({
  onClose,
  onConfirm,
  contacts,
  setContacts
}) => {
  const { currentUser } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [checkedContacts, setCheckedContacts] = useState([]);

  const handleSubmit = async () => {
    const contactsToMigrate = checkedContacts
      .filter(({ isSelected }) => isSelected)
      .map(({ _id, email, isImmigrant }) => ({
        id: _id,
        email,
        isImmigrant
      }));
    setLoading(true);
    const { hasSuccess, data } = await migrateClientsFromContacts({
      businessId: currentUser.id,
      contacts: contactsToMigrate
    });
    if (hasSuccess) {
      setContacts([]);
      setCheckedContacts([]);
      onConfirm();
    } else console.log(`Error: ${data}`);
    setLoading(false);
  };

  const FullName = ({ row }) => `${row.firstName} ${row.lastName}`;
  const InvitedByAnotherBusiness = ({ row }) =>
    row.isInvitedByAnotherBusiness ? "Yes" : "No";
  const InvitedByBusiness = ({ row }) =>
    row.isInvitedByBusiness ? "Yes" : "No";

  const CheckBox = ({ row }) => (
    <input
      disabled={!row.isSelected}
      type="checkbox"
      checked={row.isImmigrant}
      onChange={() => toggleChecked(row._id)}
    />
  );

  const toggleChecked = id => {
    setCheckedContacts(
      checkedContacts.map(item => {
        return {
          ...item,
          isImmigrant: item._id === id ? !item.isImmigrant : item.isImmigrant
        };
      })
    );
  };

  const fields = [
    { render: FullName },
    "email",
    {
      render: InvitedByAnotherBusiness
    },
    {
      render: InvitedByBusiness
    },
    {
      render: CheckBox
    }
  ];

  const headers = [
    {
      displayName: "Full Name"
    },
    {
      displayName: "Email",
      sortingFieldName: "email"
    },
    {
      displayName: "Invited By Another Business",
      sortingFieldName: "isInvitedByAnotherBusiness"
    },
    {
      displayName: "Invited By Business",
      sortingFieldName: "isInvitedByBusiness"
    },
    {
      displayName: "Is Immigrant"
    }
  ];

  const options = {
    data: checkedContacts,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    itemsPerPage: 10
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await Promise.all(
        contacts
          .filter(({ isSelected }) => isSelected)
          .map(async contact => {
            const result = await checkClient(contact.email, currentUser.id);
            const isSelected = !result.isInvitedByBusiness;
            const isImmigrant = isSelected ?? undefined;
            return { ...contact, ...result, isSelected, isImmigrant };
          })
      );
      setCheckedContacts(results);
      setLoading(false);
    })();
    return () => setCheckedContacts([]);
  }, [contacts, currentUser]);

  return (
    <Modal
      title="Migrate Client from Contacts"
      width="718px"
      height="700px"
      close={() => onClose()}
    >
      {loading ? (
        <ListLoader height="550px" />
      ) : (
        <div>
          {checkedContacts.length > 0 && (
            <p className="mt-3 mb-5">
              {checkedContacts.filter(c => c.isSelected).length} of contacts are
              suitable for migration
            </p>
          )}
          <ScrollContent height="400px">
            <Table options={options} />
          </ScrollContent>
          <div className="d-flex  align-items-center pt-5 mt-5">
            <button
              onClick={() => onClose()}
              className="btn btn-text-blue me-4"
            >
              Cancel
            </button>
            <CustomButton
              disabled={checkedContacts.filter(c => c.isSelected).length === 0}
              title="Migrate"
              size="160px"
              color="blue"
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
