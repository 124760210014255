import React from "react";
import {
  StyledToggleSwitchLabel,
  StyledToggleSwitchInput,
  StyledToggleSwitchSlider,
  StyledToggleSwitchRightLabel
} from "./index.styled-components";

const ToggleSwitch = ({
  selected,
  onChange,
  selectedLabel,
  unSelectedLabel
}) => {
  return (
    <>
      <StyledToggleSwitchLabel>
        <StyledToggleSwitchInput
          type="checkbox"
          checked={selected}
          onChange={onChange}
        />
        <StyledToggleSwitchSlider checked={selected} />
      </StyledToggleSwitchLabel>
      <StyledToggleSwitchRightLabel>
        {selected ? selectedLabel ?? "" : unSelectedLabel ?? ""}
      </StyledToggleSwitchRightLabel>
    </>
  );
};

export default ToggleSwitch;
