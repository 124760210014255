import React, { useEffect, useState } from "react";
import Modal from "../../../Layout/Shared/Modal";
import { LabelBlueBold } from "../../../Layout/Shared/LabelBlueBold";
import { CustomButton } from "../../../Layout/Shared/Button";
import { CustomTextArea } from "../../../Layout/Shared/TextArea";
import { ScrollContent } from "../../../Layout/Shared/ScrollContent";
import { list } from "../../../../data/Client/Business/get-list";
import DataLoading, { ListLoader } from "../../../Layout/Shared/DataLoading";
import { attempSearch } from "../../../Layout/Shared/Table/search";
import { string } from "yup";
import ErrorField from "../../../Layout/Shared/ErrorField";
import { create } from "../../../../data/Client/Business/group";
import "./index.scss";
import "../../../../styles/customComponents/shared.scss";
import useRefresh from "../../../../providers/route";
import { useHistory } from "react-router-dom";

export const CreateClientGroup = ({ onClose }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const redirectPath = "/business/client/list";
  const history = useHistory();
  const refresh = useRefresh(history, redirectPath);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const { hasSuccess, data } = await list();
      if (hasSuccess) {
        setClients(data);
        setFilteredClients(data);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const refreshPage = () =>
    history.location.pathname === redirectPath
      ? refresh()
      : history.push(redirectPath);

  const handleSearch = ({ value }) => {
    setFilteredClients(
      value !== ""
        ? attempSearch(clients, ["firstName", "lastName", "email"], value)
        : clients
    );
  };

  const toggleChecked = id => {
    setClients(
      clients.map(item => {
        return {
          ...item,
          isSelected: item.id === id ? !item.isSelected : item.isSelected
        };
      })
    );
    setFilteredClients(
      filteredClients.map(item => {
        return {
          ...item,
          isSelected: item.id === id ? !item.isSelected : item.isSelected
        };
      })
    );
  };

  const validateName = async value => {
    try {
      await string()
        .required("Group name is required")
        .min(3, "Group name is too Short!")
        .max(50, "Group name is too Long!")
        .validate(value);
    } catch (error) {
      return error;
    }
  };

  const handleSubmit = async () => {
    const validationResponse = await validateName(name);
    if (validationResponse && validationResponse.errors) {
      setError(validationResponse.errors.shift());
      return;
    } else {
      setError(null);
    }
    setSubmitting(true);
    const createGroup = {
      name,
      description,
      clients: clients
        .filter(client => client.isSelected)
        .map(client => client.id)
    };
    const { hasSuccess } = await create(createGroup);
    if (hasSuccess) {
      setSubmitting(false);
      onClose();
      refreshPage();
    }
  };

  return (
    <Modal title="Create Group" width="920px" height="538px" close={onClose}>
      {submitting ? (
        <DataLoading height="250px" />
      ) : (
        <div className="create-group-wrapper">
          <div className="create-group">
            <div>
              <LabelBlueBold content="Group Name" />
              <input
                name="name"
                type="text"
                className="custom-input mb-3"
                placeholder="Enter group name"
                value={name}
                onChange={({ target }) => setName(target.value)}
              />
              <LabelBlueBold content="Description" />
              <CustomTextArea
                name="description"
                placeholder="Enter description"
                value={description}
                onChange={({ target }) => setDescription(target.value)}
              />
              {error && <ErrorField message={JSON.stringify(error)} />}
            </div>
            <div className="second-column">
              <div className="titles">
                <LabelBlueBold content="All Contacts" />
                <LabelBlueBold content="Email" />
                <LabelBlueBold content="Selected" />
              </div>
              <div>
                <input
                  type="text"
                  className="custom-input search"
                  placeholder="Search"
                  onChange={({ target }) => handleSearch(target)}
                />
              </div>
              {isLoading ? (
                <ListLoader height={"200px"} />
              ) : (
                <ScrollContent height="220px">
                  <section className="selectable-contact-list">
                    <ul>
                      {filteredClients.map((contact, index) => (
                        <li key={index}>
                          <input
                            type="checkbox"
                            id={contact.id}
                            checked={contact.isSelected}
                            onChange={() => toggleChecked(contact.id)}
                          />
                          <label htmlFor={contact.id}>
                            <span>
                              {contact.firstName} {contact.lastName}
                            </span>
                            {contact.email}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </section>
                </ScrollContent>
              )}
            </div>
          </div>
          <div className="bottom">
            <button className="btn btn-link font-weight-bold" onClick={onClose}>
              Cancel
            </button>
            <CustomButton
              color="blue"
              title="Create Group"
              size="160px"
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
