import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../../../components/Layout/Header/Header";
import { PageContainer } from "../../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../../components/Layout/Shared/PageTitle";
import { HeaderButtons } from "../../../../components/Layout/Shared/PageHeader/HeaderButtons";
import { TabContainer } from "../../../../components/Clients/TabContainer";
import { CustomButton } from "../../../../components/Layout/Shared/Button";
import { PieChartIcon } from "../../../../assets/icons/icon";
import { GenericPageLeft } from "../../../../components/Layout/Shared/GenericPageLeft";
import {
  BusinessClientListProvider,
  useBusinessClientListContext
} from "../../../../providers/Client/list";
import { Statistics } from "../../../../components/Clients/List/statistics";
import { ExportClient } from "../../../../components/Clients/List/export-client";
import { ImportClientManager } from "../../../../components/Clients/ImportClientManager";

const ClientsPage = () => {
  const history = useHistory();
  const goToCreateNewClient = () => history.push(`/business/client/create`);
  const [showImport, setShowImport] = useState(false);
  const goToContactList = () => history.push(`/business/client/list`);

  const ClientsPageMainSection = () => {
    const { getters } = useBusinessClientListContext();
    const { showMainSection } = getters;
    if (!showMainSection) return null;
    return (
      <div className="row bg-white border rounded-xl m-2">
        <div className="page-left p-0 border-end">
          <Statistics />
          <ExportClient />
        </div>
        <div className="page-right  pl-0 pe-5  pt-3">
          <TabContainer />
        </div>
      </div>
    );
  };

  return (
    <BusinessClientListProvider>
      <section className="dashboard  bg-paleGray pb-5 pr-3">
        <Header />
        <div className="container-fluid mt-5 pt-3">
          <div className="row">
            <GenericPageLeft />
            <PageContainer>
              <PageHeader>
                <PageTitle>CLIENTS</PageTitle>
                <HeaderButtons>
                  <CustomButton
                    color="transparent"
                    title="Migrate Client from Contacts"
                    size="200px"
                    onClick={() => setShowImport(true)}
                  />
                  <CustomButton
                    color="blue"
                    title="Invite Client"
                    size="150px"
                    onClick={goToCreateNewClient}
                  />
                  <button className="btn btn-icon">
                    {PieChartIcon} Analytics
                  </button>
                </HeaderButtons>
              </PageHeader>
              <ClientsPageMainSection />
              <ImportClientManager
                show={showImport}
                setShow={setShowImport}
                refresh={goToContactList}
              />
            </PageContainer>
          </div>
        </div>
      </section>
    </BusinessClientListProvider>
  );
};

export default ClientsPage;
