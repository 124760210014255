import * as Yup from "yup";

Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

export const BusinessTeamInviteSchema = Yup.object().shape({
  staffs: Yup.array()
    .of(
      Yup.object().shape(
        {
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
          role: Yup.string()
            .notOneOf(["-1"], "Please select a role!")
            .required("Role is required!"),
          group: Yup.string().nullable(),
        },
        []
      )
    )
    .unique("Each user should have a unique email address.", (a) => a.email)
    .required("Must have user!")
    .min(1, "Minimum of 1 user!"),
});
