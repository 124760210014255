import React, {Fragment} from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import CheckboxEdit from "./CheckboxEdit";
import CheckboxHover from "./CheckboxHover";

export function Checkbox({dispatch, attributes, component_key}) {
  const confirmed = getAttribute("confirmed", attributes);
  return (
    <Fragment>
      <Fragment>
        {confirmed === "true" ? (
          <CheckboxHover
            component_key={component_key}
            attributes={attributes}
            dispatch={dispatch}
          />
        ) : (
          <CheckboxEdit
            component_key={component_key}
            attributes={attributes}
            dispatch={dispatch}
          />
        )}
      </Fragment>
    </Fragment>
  );
}

export default Checkbox;
