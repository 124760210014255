import React, { useEffect, useRef, useState } from "react";
import Container from "./Container";
import {
  StyledContainer,
  StyledSubmitButton,
  StyledSubmitButtonContainer,
  StyledTitle
} from "./styles";
import { getFormById } from "../../../data/Forms/getFormById";
import { getSubmission } from "../../../data/Forms/getSubmission";
import { createSubmission } from "../../../data/Submissions/createSubmission";
import { showErrorMessage, showSuccessMessage } from "../../../helpers/dialog";
import { validateComponents } from "./validator";

const FormViewer = ({ formId, submissionId }) => {
  const [form, setForm] = useState();
  const [isValid] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const loadedRef = useRef(false);

  const isSubmissionViewMode = !!submissionId;

  const isLoading = !form;

  const loadByFormId = async formId => {
    const { data: response } = await getFormById(formId);
    setForm(response.data);
  };
  const loadBySubmissionId = async (formId, submissionId) => {
    const { success, data: response } = await getSubmission(
      formId,
      submissionId
    );
    if (success) {
      setForm(response.data.form);
    }
  };

  useEffect(() => {
    if (!loadedRef.current) {
      if (formId) {
        loadedRef.current = true;
        if (isSubmissionViewMode) {
          loadBySubmissionId(formId, submissionId);
        } else {
          loadByFormId(formId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (index, options) => {
    const newComponents = [...form.components];
    newComponents[index].options = options;

    const validatingComponents = newComponents.filter(c => !!c.validations);

    if (validatingComponents.length > 0) {
      validateComponents(validatingComponents);
    }

    setForm({ ...form, components: newComponents });
  };

  const handleSave = async () => {
    const isComponentsValid = validateComponents(form.components);

    if (!isComponentsValid) {
      setForm({ ...form });
      showErrorMessage("Please fill in all required fields!");
      return;
    }
    setIsSaving(true);
    const submissionsData = {
      submissions: form.components.map(component =>
        component.options?.submission ? component.options.submission : null
      )
    };
    const { isSuccess, data } = await createSubmission(formId, submissionsData);
    setIsSaving(false);
    setIsSubmitted(true);
    if (isSuccess) {
      showSuccessMessage("Submission added successfully!");
    } else {
      console.error(data);
      showErrorMessage("Failed to add submission!");
    }
  };

  const isButtonVisible = !isLoading && !isSubmitted && !isSubmissionViewMode;
  const isButtonDisabled = isSaving || !isValid;
  const buttonText = isSaving ? "Saving..." : "Submit";

  return (
    <StyledContainer>
      {!isLoading && !isSubmitted && <StyledTitle>{form.title}</StyledTitle>}
      <Container
        components={form?.components}
        isReadOnly={isSubmissionViewMode}
        isLoading={isLoading}
        isSubmitted={isSubmitted}
        onChange={(index, options) => handleOnChange(index, options)}
      />
      {isButtonVisible && (
        <StyledSubmitButtonContainer>
          <StyledSubmitButton disabled={isButtonDisabled} onClick={handleSave}>
            {buttonText}
          </StyledSubmitButton>
        </StyledSubmitButtonContainer>
      )}
    </StyledContainer>
  );
};

export default FormViewer;
