import React, {useState, useEffect} from "react";
import {PageDetails} from "../../../../components/Layout/Shared/PageDetails";
import {getRecentServices} from "../../../../data/Service/Business/getRecentServices";
import {ListLoader} from "../../../Layout/Shared/DataLoading";

export const RecentServices = ({reRender}) => {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const init = async () => {
    setIsLoading(true);
    const {data, hasSuccess} = await getRecentServices(10);
    if (hasSuccess) {
      setServices(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, [reRender]);

  return (
    <PageDetails title="RECENTLY CREATED">
      {isLoading ? (
        <ListLoader height="450px" />
      ) : (
        <ul className="review-statics ps-5 pe-5 ">
          {services.map(({_id, name}) => (
            <li key={_id}>{name}</li>
          ))}
        </ul>
      )}
    </PageDetails>
  );
};
