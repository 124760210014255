import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import Header from "../../../components/Layout/Header/Header";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import React from "react";
import CreateForm from "../../../components/Form/Create/create.component";

const CreateFormPage = () => {
  return (
    <section className="dashboard  bg-paleGray pb-5 pe-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Form builder</PageTitle>
            </PageHeader>
            <CreateForm />
          </PageContainer>
        </div>
      </div>
    </section>
  );
};

export default CreateFormPage;
