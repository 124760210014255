export const active = "Active";
export const inActive = "Inactive";

export const isActive = (status) => status === active;
export const isInactive = (status) => status === inActive;

export const SERVICE_TYPES = [
    {
      type: "immigration",
      name: "Immigration"
    },
    {
      type: "private",
      name: "Private"
    },
    {
      type: "both",
      name: "Both"
    }
  ];