import React, {useState} from "react";
import {SystemDefaultAccessLevels} from "./AccessLevels";
import {UserDefinedRoles} from "./Roles";
import "./index.scss";
export const RolesAndPermissions = () => {
  const [showAccessLevels, setShowAccessLevels] = useState(true);
  const handleRefreshAccessLevels = () => {
    setShowAccessLevels(false);
    setTimeout(() => {
      setShowAccessLevels(true);
    }, 1);
  };

  return (
    <>
      <section>
        {showAccessLevels && (
          <SystemDefaultAccessLevels refresh={handleRefreshAccessLevels} />
        )}
        <UserDefinedRoles />
      </section>
    </>
  );
};
