import React, {useState} from "react";
import {profileTabs, defaultProfileTab} from "../../tabs";
import {CustomButton} from "../../../../Layout/Shared/Button";
import "./index.scss";
import {useBusinessProfileSettingsContext} from "../../../../../providers/Settings/Profile/business";

export const TabContainer = () => {
  const [selectedTab, setSelectedTab] = useState(defaultProfileTab);
  const {
    handleSubmit,
    values: {isStaff},
  } = useBusinessProfileSettingsContext();

  const tabs = isStaff
    ? profileTabs.filter((tab) => tab.isStaff || tab.isDefault)
    : profileTabs.filter((tab) => !tab.isStaff || tab.isDefault);

  return (
    <div className="bg-white rounded-xl border pb-5">
      <ul className="generic-page-tab">
        {tabs.map((tab) => (
          <li
            key={tab.name}
            className={selectedTab.name === tab.name ? "active" : null}
          >
            <button onClick={() => setSelectedTab(tab)}>{tab.name}</button>
          </li>
        ))}
        <div className="float-end mt-3 me-5 ">
          <CustomButton
            color="blue"
            onClick={handleSubmit}
            title="Save Changes"
          />
        </div>
      </ul>
      <section>{selectedTab.component}</section>
    </div>
  );
};
