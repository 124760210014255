import {CompanyRating} from "../../../Review/Rating";
import {CompanyReviews} from "../../../Review/Reviews";
const average = {
  average: "4.2",
  stars: 4,
  total: 1253,
};
const votes = [
  {
    percent: "60%",
    star: 5,
  },
  {
    percent: "20%",
    star: 4,
  },
  {
    percent: "5%",
    star: 3,
  },
  {
    percent: "10%",
    star: 2,
  },
  {
    percent: "5%",
    star: 1,
  },
];
const reviews = [];
export const ClientServiceReviews = () => {
  return (
    <div className="p-5">
      <div>
        <CompanyRating average={average} votes={votes} />
      </div>
      <div className="mt-5 pt-3">
        <h3 className="font-weight-bold mb-5">Reviews</h3>
        <CompanyReviews reviews={reviews} />
      </div>
    </div>
  );
};
