/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import LearnMore from "../components/Login/LearnMore";
import {verifyEmail} from "../data/Register/verifyEmail";
import "../styles/pages/login.scss";

const businessPrefix = "/business";

const VerifyEmailPage = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(
    "Please click below button to login page to continue."
  );

  const location = useLocation();
  const isBusiness = location.pathname.startsWith(businessPrefix);
  const token = location.pathname
    .substring(location.pathname.length - 60)
    ?.replace(/\//g, "-");
  const type = isBusiness ? "business" : "client";

  useEffect(() => {
    verifyEmail({token, type}).then((res) => {
      if (res.message) {
        setMessage(res.message);
      } else {
        const response = JSON.parse(res);
        setMessage(response.description);
      }
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <div className="container-fluid sign-in">
        <div className="row d-md-flex">
          <LearnMore />
          <div className="col-lg-6 right order-1 order-md-2 order-lg-2 d-flex justify-content-center align-items-center">
            {loading ? (
              <div className="verify-loader">
                <div className="card-loader">
                  <div className="card-ani title"></div>
                  <div className="card-ani description "></div>
                  <div className="card-ani button "></div>
                </div>
              </div>
            ) : (
              <div className="box">
                <h2>Your Email is Verified</h2>
                <div className="row">
                  <div className="col-12">
                    <h2>
                      <small>{message}</small>
                    </h2>
                  </div>
                  <div className="col-sm-12">
                    <Link to="/" className="btn btn-blue-gradient">
                      SIGN IN
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
