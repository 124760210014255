import ReactStars from "react-rating-stars-component";
import moment from "moment";
import "./index.scss";
export const CompanyReviews = ({reviews}) => {
  return (
    <div className="company-reviews">
      {reviews.map((review, index) => (
        <div className="review" key={index}>
          <figure>
            <img src={review.avatar} alt="" />
          </figure>
          <div className="contact-info">
            <div>
              <strong>{review.name}</strong>
            </div>
            <div className="date-time">
              {moment(review.dateTime).format("MMM d, YYYY | hh:mm A")}
            </div>
            <div>
              <ReactStars value={review.rating} size={16} />
            </div>
          </div>
          <div className="review">{review.review}</div>
        </div>
      ))}
    </div>
  );
};
