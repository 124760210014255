/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {getAttachments} from "../../../../../data/Service/Business/getAttachments";
import {deleteAttachment} from "../../../../../data/Service/Business/deleteAttachment";
import {ListLoader} from "../../../../Layout/Shared/DataLoading";
import {CustomButton} from "../../../../Layout/Shared/Button";
import AttachedFile from "../../../../../assets/images/attached-icon.svg";
import "../../View/AttachmentsList/index.scss";
import {DeleteIconRed} from "../../../../../assets/icons/icon";
import {DeleteModal} from "../../../../Layout/Shared/Popups/Delete";

export const AttachmentsList = ({refresh, serviceId}) => {
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ShowDelete, setShowDelete] = useState(false);
  const getAttachmentList = async () => {
    setIsLoading(true);
    const {data, hasSuccess} = await getAttachments(serviceId);
    if (hasSuccess) {
      setAttachments(data.attachments);
    }
    setIsLoading(false);
  };

  const deleteServiceAttachment = async (key) => {
    const {hasSuccess} = await deleteAttachment(key);
    if (hasSuccess) getAttachmentList();
  };

  useEffect(() => {
    getAttachmentList();
  }, [refresh]);

  return (
    <>
      {isLoading ? (
        <ListLoader height="450px" />
      ) : (
        <section>
          <div>
            {attachments.length > 0 && (
              <div className="col-xl-12 attached-files">
                <label className="h4 font-weight-bold">Attached Files</label>
                <ul className="file-list">
                  {attachments.map((file) => (
                    <li key={file.name}>
                      <div>
                        <img src={AttachedFile} alt="" />
                      </div>
                      <div className="file-name">
                        {file.name}
                        <small>{file.size}KB</small>
                      </div>
                      <div>
                        <CustomButton
                          title="View"
                          size="98px"
                          href={file.location}
                          target="_blank"
                          color="blue"
                          onClick={() => window.open(file.location, "_blank")}
                        />
                        <button
                          className="btn btn-circle-red"
                          onClick={() => setShowDelete(!ShowDelete)}
                        >
                          {DeleteIconRed}
                        </button>
                        {ShowDelete && (
                          <DeleteModal
                            title="Delete file"
                            buttonText="Delete"
                            cancel={true}
                            text="This file will be permanently deleted."
                            onClose={() => setShowDelete(!ShowDelete)}
                            onConfirm={() => deleteServiceAttachment(file.key)}
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};
