import ReactStars from "react-rating-stars-component";
import {CustomButton} from "../../../../Layout/Shared/Button";
import "./index.scss";
export const AddReviewToService = ({services}) => {
  return (
    <div className="add-review-form">
      <div className="services">
        <ul>
          {services.map((service, index) => (
            <li key={index}>
              <span className="title-inline-form">{service.serviceName}</span>
              <p>
                Rate us in between 1-5 stars where 1 represent bad and 5
                represent awesome experience.
              </p>
              <div className="rating-area">
                <ReactStars value={4} size={32} /> <div>Please rate</div>
              </div>
            </li>
          ))}
        </ul>
        <div>
          <span className="title-inline-form">
            Please leave your comments bellow
          </span>
          <textarea
            className="custom-textarea"
            placeholder="Type here..."
            style={{resize: "none"}}
          ></textarea>
        </div>
      </div>
      <div className="button-wrapper">
        <button className="btn-text-blue">Cancel</button>
        <CustomButton title="Submit Review" size="190px" color="blue" />
      </div>
    </div>
  );
};
