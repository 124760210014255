import React from "react";
import Invitation from "../../components/Invitation/Client/index";
import Header from "../../components/Layout/Header/Header";
import {GenericPageLeft} from "../../components/Layout/Shared/GenericPageLeft";
import "../../styles/pages/invitations.scss";

const Invitations = () => {
  return (
    <section className="invitations  pb-5 pr-3 bg-paleGray height-auto">
      <Header />
      <div className="container-fluid  mt-5 pt-3">
        <div className="row d-flex">
          <GenericPageLeft />

          <div className="content ">
            <section>
              <div className="row mt-3 p-5">
                <div className="col-xl-12 mt-5">
                  <span className="title h1 font-weight-bold">INVITATIONS</span>
                </div>
                <div className="col-lg-12 invitations mt-5 rounded-xl  bg-white p-5">
                  <Invitation />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Invitations;
