import styled from "styled-components";
import * as theme from "../Styles/Theme";

export const StyledFormView = styled.div.attrs((props) => props)`
  color: black;
  padding: 20px 30px;
  width: ${(props) => {
    if (props.viewType === "Desktop") {
      return "100%";
    }
    if (props.viewType === "Tablet") {
      return "800px";
    }
    if (props.viewType === "Phone") {
      return "500px";
    }
    return "500px";
  }};
`;
export const FormViewPageHeader = styled.div`
  padding: 30px 30px;
  font-weight: 600;
  text-align: center;
  font-size: 23px;
`;
export const PreviewHeader = styled.div`
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 0px;
`;

export const PreviewHeaderTitle = styled.h1`
  font-weight: 700;
`;
export const PreviewFormCard = styled.div`
  border-radius: 6px;
  border: solid 1px #dee2e6;
`;
export const FormViewButtonStyle = styled.button`
  color: white;
  background-image: linear-gradient(to right, ${theme.azul}, #1515af 100%);
  border: solid 1px #dee2e6;
  border-radius: 26px;
  width: 95px;
  height: 47px;
  font-size: 13px;
  font-weight: 700;
  margin-top: 20px !important;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const PreviewFormCardHeader = styled.div`
  padding: 8px 15px;
  background-color: #f9f9f9;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-color: #dee2e6;
`;
export const PreviewFormCardBody = styled.div`
  padding-top: 30px;
`;
export const Dot = styled.span`
  color: ${theme.placeholderGray};
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
`;
export const BaseViewStyle = styled.div`
  margin-bottom: 24px;
`;

export const ViewOptionButtons = styled.div`
  border-radius: 26px !important;
  border: 1.3px solid ${theme.placeholderGray};
`;

export const ViewOptionButton = styled.button`
  padding: 11px 20px;
  border: 0px;
  color: #495057;

  :focus {
    color: ${theme.azul};
    background-color: #ffffff9e;
    border-radius: 26px !important;
    border: 2px solid ${theme.azul};
    box-shadow: unset !important;
  }

  :hover {
    border-radius: 26px !important;
    background-color: unset;
    color: ${theme.azul};
  }

  &.active {
    color: ${theme.azul};
    background-color: #ffffff9e;
    border-radius: 26px !important;
    border: 2px solid ${theme.azul};
    box-shadow: unset;
  }
`;
export const ViewIconOption = styled.i`
  font-size: 1.4em;
`;

export const ViewOptionTitle = styled.div`
  font-stretch: normal;
  letter-spacing: 0.24px;
  color: #79818b;
  font-size: 12px;
  font-weight: 800;
  margin-right: 10px;
`;
