import React from "react";
import {ProfileCard} from "../ProfileCard";
import {Formik, Form, Field} from "formik";
import {LabelBlueBold} from "../../../../Layout/Shared/LabelBlueBold";
import {useBusinessProfileSettingsContext} from "../../../../../providers/Settings/Profile/business";
import PhoneInput from "react-phone-input-2";
import ErrorField from "../../../../Layout/Shared/ErrorField";
import {designations} from "../../../../../static/Onboarding/business";
import {OwnerProfileSchema} from "../../../../../schemas/ProfileSettings/business";

export const OwnerProfile = () => {
  const {
    values: {ownerProfile, isStaff, profilePictureUrl},
    setters: {setOwnerProfile, setProfilePictureUrl},
  } = useBusinessProfileSettingsContext();

  return (
    <div className="row ps-5 pe-5  pb-5">
      <div className="col-xl-3 col-lg-4 col-md-4 border-end pt-5">
        <ProfileCard
          title={`${ownerProfile.firstName} ${ownerProfile.lastName}`}
          buttonValue="Upload Your Picture"
          imageUrl={profilePictureUrl}
          setImageUrl={setProfilePictureUrl}
          source="owner-profile-picture"
          disabled={isStaff}
        />
      </div>
      <div className="col-xl-9 col-lg-8 col-md-8 p-5">
        <div className="row">
          <div className="col-xl-5 pe-5 ">
            <Formik
              enableReinitialize={true}
              initialValues={ownerProfile}
              validateOnBlur={false}
              validationSchema={OwnerProfileSchema}
            >
              {({errors, values}) => (
                <>
                  {setOwnerProfile(values)}
                  <Form>
                    <div className="h2 pb-4">Contact Details</div>
                    <LabelBlueBold content="Your Name" />
                    <div className="d-flex justify-content-between">
                      <Field
                        className="custom-input me-2"
                        placeholder="Enter First Name"
                        name="firstName"
                        disabled={isStaff}
                      />
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Enter Last Name"
                        name="lastName"
                        disabled={isStaff}
                      />
                    </div>
                    <div className="mt-4">
                      <LabelBlueBold content="Designation" />
                      <Field name="designation">
                        {({field: {value}, form: {setFieldValue}}) => (
                          <select
                            className="primary-select"
                            placeholder="Please select designation"
                            defaultValue={value}
                            disabled={isStaff}
                            onChange={({target}) =>
                              setFieldValue("designation", target.value)
                            }
                          >
                            <option value="-1" disabled>
                              Please select designation
                            </option>
                            {designations.map((designation) => (
                              <option
                                key={designation.name}
                                value={designation.name}
                              >
                                {designation.name}
                              </option>
                            ))}
                          </select>
                        )}
                      </Field>
                    </div>
                    <div className="mt-5">
                      <LabelBlueBold content="Email Address" />
                      <Field
                        className="custom-input"
                        placeholder="john@soleenterprises.com"
                        name="email"
                        disabled={true}
                      />
                    </div>
                    <div className="mt-5 form-group  custom-phone">
                      <LabelBlueBold content="Phone Number" />
                      <Field name="phone">
                        {({field: {value}, form: {setFieldValue}}) => (
                          <PhoneInput
                            enableSearch
                            placeholder="Enter Mobile Number"
                            country={"ca"}
                            value={value}
                            onChange={(number) =>
                              setFieldValue("phone", number)
                            }
                            disabled={isStaff}
                          />
                        )}
                      </Field>
                    </div>
                    <ErrorField message={errors.firstName} />
                    <ErrorField message={errors.lastName} />
                    <ErrorField message={errors.designation} />
                    <ErrorField message={errors.phone} />
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
