import React from 'react'
import AttachmentComponent from '../../../Builder/Body/Components/Attachment'
import { FormComponentTypes } from '../../../constants'

const Attachment = ({
  component:  options ,
  isReadOnly,
  onChange,
  isVisible,
}) => {
  const handleOnChange = (newOptions) => {
    options.submission = newOptions
    onChange(options)
  }
  const component = {
    type: FormComponentTypes.Attachment,
    options: options.submission || options.options,
  }
  return (
    <>     
      <AttachmentComponent
        key={isVisible}
        isReadOnly={isReadOnly}
        boxVisible={false}
        component={component}
        onOptionsChange={handleOnChange}
      />
    </>
  )
}

export default Attachment
