import React, { useState, useEffect } from "react";
import {
  HeartIconOrange,
  HeartIconBlueBorder
} from "../../../../assets/icons/icon";
import "./index.scss";
import { getDate, getOnlyTime } from "../../../../utils/date";
import { getBase64 } from "../../../../utils/base64";
import { uploadFile } from "../../../../data/Shared/upload-file";
import { ListLoader } from "../../../Layout/Shared/DataLoading";
import { CustomSelectFile } from "../../../Layout/Shared/SelectFile";
import DefaultImage from "../../../../assets/images/default-profile-picture.png";
import { ShareModal } from "../../../Layout/Shared/Popups/Share";
import { exportToCSV } from "../../../../utils/export";
import { get } from "../../../../data/Client/Business/get";
import { shareClient } from "../../../../data/Client/Business/shared";
import { useHistory } from "react-router";

export const ClientCard = ({
  id,
  fullName,
  created_at,
  profilePictureUrl,
  setProfilePictureUrl,
  isFavorite,
  setIsFavorite,
  disabled,
  groups = [],
  isDisabledMessage
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [infoMessage, setInfoMessage] = useState(null);
  const [showShare, setShowShare] = useState(false);
  const history = useHistory();

  const handleFile = async ({ files }) => {
    setIsLoading(true);
    const file = files[0];
    const body = {
      name: file.name,
      type: file.type,
      file: await getBase64(file),
      source: "profile-picture",
      rootPath: `clients/${id}`
    };
    const { hasSuccess, data } = await uploadFile(body);
    hasSuccess && setProfilePictureUrl(data.Location);
    setIsLoading(false);
  };

  const onSubmitShareModal = async (values, { setSubmitting }) => {
    values.emails = values.emails.split(",");
    values.emails = values.emails.map(email => email.trim());
    const { hasSuccess } = await shareClient(id, values);
    if (hasSuccess) {
      setShowShare(false);
      setSubmitting(false);
      setInfoMessage("The contact shared successfully.");
    }
  };

  const handleExport = async () => {
    const { data, hasSuccess } = await get(id);
    hasSuccess && exportToCSV([data], "client");
  };

  const goToMessages = id => history.push(`/business/messages?clientId=${id}`);

  useEffect(() => {
    const timeId = setTimeout(() => setInfoMessage(null), 4000);
    return () => clearTimeout(timeId);
  }, [infoMessage]);

  return (
    <div className="contact-profile-card">
      <div className="row">
        <div className="col-12 text-center p-5 contact-profile-picture">
          <h2>
            {fullName}
            <button
              className="btn-icon"
              onClick={() => setIsFavorite(!isFavorite)}
              disabled={disabled}
            >
              {isFavorite ? HeartIconOrange : HeartIconBlueBorder}
            </button>
          </h2>
          <figure className="border rounded-xxl overflow-hidden">
            <img
              src={profilePictureUrl ?? DefaultImage}
              onLoad={() =>
                setTimeout(() => {
                  setIsLoading(false);
                }, 500)
              }
              alt=""
              style={{
                display: isLoading ? "none" : "flex"
              }}
            />
            {isLoading && <ListLoader height={"213px"} />}
          </figure>
          <CustomSelectFile
            color="solid-blue"
            label="Upload Your Picture"
            size="210px"
            onChange={e => handleFile(e.target)}
            accept="image/*"
            disabled={disabled}
          />
        </div>
        <div className="border-top w-100">
          <div className="group-section">
            <h4>
              Group(s) <span>{groups.length}</span>
            </h4>
            <ul className="groups">
              {groups.map((group, index) => (
                <li key={index}>
                  {group.name}
                  <button onClick={() => {}} disabled={true}></button>
                </li>
              ))}
            </ul>
            <button
              className="btn btn-small btn-text-blue mt-4 w-100"
              hidden={true}
            >
              Add to Group
            </button>
            <div className="action-buttons">
              <div className="d-flex justify-content-between">
                <button
                  disabled={isDisabledMessage}
                  className={`btn-small btn-blue me-3 ${
                    isDisabledMessage && "disabled"
                  }`}
                  onClick={() => goToMessages(id)}
                >
                  Message
                </button>
                <button
                  className="btn-small btn-white-border-black text-primary"
                  onClick={() => setShowShare(true)}
                >
                  Share
                </button>
              </div>
              <button
                className="btn-small btn-white-border-black danger w-100 mt-5"
                hidden={true}
              >
                Delete
              </button>
            </div>
            <div className="created mt-5">
              <span>Date Created</span>
              <span>
                {getDate(created_at)} | {getOnlyTime(created_at)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {showShare && (
        <ShareModal
          title="Share Client"
          onConfirm={onSubmitShareModal}
          onExport={handleExport}
          onClose={() => setShowShare(false)}
        />
      )}
      {infoMessage && (
        <div className="notifications">
          <div className="info">
            <span>Info</span>
            <p>{infoMessage}</p>
            <button onClick={() => setInfoMessage(null)}>&times;</button>
          </div>
        </div>
      )}
    </div>
  );
};
