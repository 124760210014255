import React from "react";
import { Autocomplete } from "../../Autocomplete";

const getAllTimeZones = () => {
  const timeZones = Intl.supportedValuesOf("timeZone");
  return timeZones
    .map(timeZone => {
      const now = new Date();
      const options = { timeZone, timeZoneName: "short" };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const parts = formatter.formatToParts(now);
      const timeZoneName = timeZone;
      const match = parts.find(part => part.type === "timeZoneName").value;
      const timeZoneGMT = match ? match : "GMT";

      const dateString = `Thu, 01 Jan 1970 00:00:00 ${timeZoneGMT}`;
      const testDate = new Date(dateString);

      if (!isNaN(testDate.getTime())) {
        return { timeZoneName: timeZoneName, timeZone: timeZoneGMT };
      } else {
        return null;
      }
    })
    .filter(zone => zone !== null);
};

const TIME_ZONES = getAllTimeZones();

const TimezoneSelect = ({ value, onSelect, ...props }) => {
  const TIME_ZONES_OPTIONS = TIME_ZONES.map(({ timeZoneName }) => ({
    id: timeZoneName,
    text: timeZoneName
  }));

  const getTimeZone = _timeZoneName =>
    TIME_ZONES.find(({ timeZoneName }) => timeZoneName === _timeZoneName);
  return (
    <Autocomplete
      {...props}
      options={TIME_ZONES_OPTIONS}
      placeHolder={"Select..."}
      onSelect={({ id }) => {
        const timeZone = getTimeZone(id) || {
          timeZone: "",
          timeZoneName: ""
        };
        onSelect(timeZone);
      }}
      defaultValue={value}
    />
  );
};

export default TimezoneSelect;
