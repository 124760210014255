import React, {useContext} from "react";
import BusinessServiceContext from "../../../../../providers/Service/business";
import {SwitchButton} from "../../../../Layout/Shared/SwitchButtonBlue";
export const CommunicationChannels = () => {
  const {service} = useContext(BusinessServiceContext);
  const {communicationChannel = {}} = service;
  const {
    textAndMessages = false,
    calls = false,
    appointments = false,
  } = communicationChannel;
  return (
    <div className="communication-channels">
      <h4>Communication Channels</h4>
      <span>
        Text & Message
        <span>
          <SwitchButton small status={textAndMessages} disabled={true} />
        </span>
      </span>
      <span>
        Calls
        <span>
          <SwitchButton small status={calls} disabled={true} />
        </span>
      </span>
      <span>
        Appointment
        <span>
          <SwitchButton small status={appointments} disabled={true} />
        </span>
      </span>
    </div>
  );
};
