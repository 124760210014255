import React, {useState, useEffect, useContext} from "react";
import Header from "../../../components/Layout/Header/Header";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import {TabContainer} from "../../../components/Settings/Business/Profile/TabContainer";
import BusinessProfileSettingsContext from "../../../providers/Settings/Profile/business";
import {getSettings} from "../../../data/Settings/Business/getSettings";
import DataLoading from "../../../components/Layout/Shared/DataLoading/index";
import {GlobalSuccess} from "../../../components/Layout/Shared/Modal/Success";
import {updateSettingsDetails} from "../../../data/Settings/Business/updateSettingsDetails";
import {updateStaffDetails} from "../../../data/Settings/Business/updateStaffDetails";
import BusinessLogo from "../../../assets/images/default-business-image.jpg";
import OwnerImage from "../../../assets/images/default-owner-image.png";
import {GlobalContext} from "../../../providers/global";

const ProfileSettingsPage = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [businessProfile, setBusinessProfile] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [ownerProfile, setOwnerProfile] = useState(null);
  const [staffProfile, setStaffProfile] = useState(null);
  const [logoUrl, setLogoUrl] = useState(BusinessLogo);
  const [profilePictureUrl, setProfilePictureUrl] = useState(OwnerImage);
  const [staffProfilePictureUrl, setStaffProfilePictureUrl] = useState(BusinessLogo);
  const {setIndustries: setGlobalIndustries} = useContext(GlobalContext);

  const isStaff = staffProfile !== null;

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await getSettings();
      if (hasSuccess) {
        setProfileSettingDetails(data);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const setProfileSettingDetails = ({name, details, email, staff}) => {
    setStaffProfile(staff || null);
    const {businessInformation, industries, yourInformation} = details;
    setBusinessProfile({
      name,
      email: email,
      ...businessInformation,
    });
    setIndustries(industries);
    setOwnerProfile({
      email: email,
      phone: "",
      ...yourInformation,
    });
    setLogoUrl(businessInformation.logo || BusinessLogo);
    setProfilePictureUrl(yourInformation.profilePicture || OwnerImage);
    setStaffProfilePictureUrl(staff?.profilePicture || BusinessLogo);
  };

  const saveStaffProfile = async (staffData) => {
    setIsLoading(true);
    const {hasSuccess, data} = await updateStaffDetails(staffData);
    if (hasSuccess) {
      setStaffProfile(data);
      setIsLoading(false);
      setShowSuccess(true);
    }
  };

  const handleSubmit = async () => {
    if (isStaff) {
      await saveStaffProfile({...staffProfile, profilePicture :staffProfilePictureUrl});
    } else {
      setIsLoading(true);
      const profileSettingDetails = {
        businessInformation: {
          ...businessProfile,
          logo: logoUrl,
        },
        industries,
        yourInformation: {
          ...ownerProfile,
          profilePicture: profilePictureUrl,
        },
      };
      delete profileSettingDetails.businessInformation.name;
      delete profileSettingDetails.businessInformation.email;
      delete profileSettingDetails.yourInformation.email;
      const {hasSuccess, data} = await updateSettingsDetails(
        profileSettingDetails
      );
      if (hasSuccess) {
        setProfileSettingDetails(data);
        const {details} = data;
        setGlobalIndustries(details.industries);
        setIsLoading(false);
        setShowSuccess(true);
      }
    }
  };

  return (
    <BusinessProfileSettingsContext.Provider
      value={{
        handleSubmit,
        values: {
          businessProfile,
          industries,
          ownerProfile,
          logoUrl,
          profilePictureUrl,
          staffProfile,
          staffProfilePictureUrl,
          isStaff,
        },
        setters: {
          setStaffProfile,
          setBusinessProfile,
          setIndustries,
          setOwnerProfile,
          setLogoUrl,
          setProfilePictureUrl,
          setStaffProfilePictureUrl
        },
      }}
    >
      <section className="dashboard  bg-paleGray pb-5 pr-3">
        <Header />
        <div className="container-fluid mt-5 pt-3">
          <div className="row">
            <GenericPageLeft />
            <PageContainer>
              <PageHeader>
                <PageTitle>Profile Settings</PageTitle>
              </PageHeader>
              {isLoading ? <DataLoading height="750px" /> : <TabContainer />}
            </PageContainer>
          </div>
        </div>
      </section>
      {showSuccess && (
        <GlobalSuccess
          heading="Changed!"
          onClose={() => setShowSuccess(false)}
          message="The changes have been successfully saved"
        />
      )}
    </BusinessProfileSettingsContext.Provider>
  );
};
export default ProfileSettingsPage;
