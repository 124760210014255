import React from 'react'
import {
  StyledCheckboxContainer,
  StyledCheckboxInput,
  StyledCheckboxText,
} from './styles'

const Checkbox = ({
  checked,
  onCheckedChanged,
  children,
  label,
  isReadOnly,
}) => (
  <StyledCheckboxContainer>
    <StyledCheckboxInput
      type="checkbox"
      checked={checked}
      onChange={(e) => onCheckedChanged(e.currentTarget.checked)}
      disabled={isReadOnly}
    />
    {label ? (
      <StyledCheckboxText>{label}</StyledCheckboxText>
    ) : (
      <StyledCheckboxText>{children}</StyledCheckboxText>
    )}
  </StyledCheckboxContainer>
)

export default Checkbox
