import "./index.scss";
import moment from "moment";
import IconClock from "../../../../../assets/icons/icon-clock-32x32.png";
export const Consultant = ({data, isOnline}) => {
  return (
    <div className="client-service-consultant">
      <div className="consultant-box">
        {data.avatar && (
          <div className="avatar">
            <img src={data.avatar} alt={data.consultantName} />
          </div>
        )}

        <div className="box-top">
          <div>
            <span className="consultant">{data.consultantName}</span>
            <span className="text-primary-blue">{data.job}</span>
          </div>
          <div>
            <span>{data.email} </span>
            <span>{data.phone}</span>
          </div>
        </div>
        {isOnline ? (
          <div className="box-body">
            <div>
              <p>
                Welcome onboard, I’m available to assist you as per there
                service schedule. Please contact me through following channels.
              </p>
            </div>
          </div>
        ) : (
          <div className="box-body">
            <div>
              <img src={IconClock} alt="" />
            </div>
            <div className="date-time">
              Your appointment is scheduled with me <br /> <strong>at</strong>
              <span>{moment(data.time).format("hh:mm A")}</span>{" "}
              <strong>on</strong>
              <span>{moment(data.time).format("MMM d, YYYY")}</span>
            </div>
          </div>
        )}
        <div className="box-bottom">
          <div className="button-wrapper">
            <button>Chat Now</button>
            <button>Call Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};
