import styled from "styled-components";

export const TemplateTableLoader = ({height, showListView = true}) => {
  return (
    <StyledTemplateLoaderContainer>
      {showListView && (  
         <StyledHeader>
        <StyledListView />
      </StyledHeader>)}
      <StyledLoaderItems>
        {Array(10)
          .fill(0)
          .map((_, i) => (
            <StyledLoaderItem key={i}></StyledLoaderItem>
          ))}
      </StyledLoaderItems>
      <StyledBottom>
        <StyledShowingSection>
          <span></span>
        </StyledShowingSection>

        <StyledPaginationSection>
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <span key={i}></span>
            ))}
        </StyledPaginationSection>
      </StyledBottom>
    </StyledTemplateLoaderContainer>
  );
};

export const StyledTemplateLoaderContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 2rem;
  position: relative;
  * {
    animation: loading 1s linear infinite;
  }
  @keyframes loading {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e1e1e1;
`;

export const StyledListView = styled.span`
  width: 80px;
  height: 40px;
  border-radius: 4px;
  background: #f3f3f3;
`;

export const StyledLoaderItems = styled.ul`
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  overflow: hidden;
`;

export const StyledLoaderItem = styled.li`
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: calc(50% - 8px);
    left: 4px;
    width: calc(100% - 8px);
    height: 16px;
    background: #e8e8e8;
    z-index: 1;
    border-radius: 6px;
  }
`;

export const StyledBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

export const StyledShowingSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  span {
    display: block;
    width: 55px;
    height: 32px;
    background: #e8e8e8;
    margin-left: 65px;
    border-radius: 6px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 55px;
    height: 16px;
    left: 0;
    top: 8px;
    background: #e8e8e8;
    border-radius: 6px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100px;
    height: 16px;
    left: 130px;
    top: 8px;
    background: #e8e8e8;
    border-radius: 6px;
  }
`;

export const StyledPaginationSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 300px;
  height: 40px;
  * {
    width: 30px;
    height: 52px;
    margin-left: 8px;
    border-radius: 4px;
    background: #e8e8e8;
  }
`;
