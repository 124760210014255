import React, { useState } from 'react'
import Checkbox from '../../Shared/Checkbox'
import {
  StyledPolicyButton,
  StyledPolicyContent,
  StyledPolicyIcon,
  StyledPolicyText,
  StyledPolicyWrapper,
} from '../styles'

const PolicyCheckbox = ({ policyAccepted, onChange, isReadOnly }) => {
  const [policyVisible, setPolicyVisible] = useState(false)
  return (
    <>
      <Checkbox
        checked={policyAccepted}
        isReadOnly={isReadOnly}
        onCheckedChanged={onChange}
      >
        <StyledPolicyWrapper>
          <StyledPolicyText>
            I have read and I agree to the e-signature policy e-signature policy
          </StyledPolicyText>
          <StyledPolicyButton
            policyVisible={policyVisible}
            onClick={() => setPolicyVisible((prev) => !prev)}
          >
            <StyledPolicyIcon className="bi bi-caret-right" />
          </StyledPolicyButton>
        </StyledPolicyWrapper>
      </Checkbox>
      {policyVisible && (
        <StyledPolicyContent>
          You must first confirm that you agree to sign the documents by means
          of electronic signature. By clicking on the [“Accept”] button at the
          bottom of this page, you agree to sign the documents by means of
          electronic signature and understand that affixing your electronic
          signature creates a legal obligation in the same way as a signature on
          a paper document. If you do not want to proceed using an electronic
          signature, you can click on the [Decline to sign] A representative of
          the organization requesting signature may then send you a paper
          version to sign. If several people’s signatures are required and one
          of them refuses to sign the documents by means of electronic
          signature, the business must obtain printed signatures from all of the
          individuals involved.
        </StyledPolicyContent>
      )}
    </>
  )
}

export default PolicyCheckbox
