import styled from "styled-components";

export const StyledChatTabs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1px;
  border-bottom: 1px solid #e9ecef;
  padding: 0 0;
`;

export const StyledChatTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 2px solid transparent;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? " #1b84e7" : "transparent")};
  svg {
    fill: ${({ isActive }) => (isActive ? " #1b84e7" : "#b7bdc3")};
    path {
      fill: ${({ isActive }) => (isActive ? " #1b84e7" : "#b7bdc3")};
    }
  }
`;

export const StyledChatList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  height: calc(100vh - 340px);
  overflow-y: auto;
`;
