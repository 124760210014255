/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "../../Modal";
import ListLoader from "../../DataLoading";
import "./index.scss";

const ConfirmDialog = ({ title, description, onConfirm, onClose }) => {
  const confirm = () => {
    onConfirm && onConfirm();
    close();
  };
  const close = () => onClose();
  return (
    <>
      <Modal
        title={title}
        width="510px"
        height="255px"
        close={close}
        backdrop={false}
      >
        <div>
          <p>
            <h4>{description}</h4>
            <br />
            <br />
          </p>
          <div className="mt-5 d-flex justify-content-end">
            <button onClick={close} className="btn-text-blue me-5 ">
              Cancel
            </button>
            <button
              className="btn-small btn-red float-end mt-0"
              onClick={confirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmDialog;

export const ConfirmDialogV2 = ({
  title = "Confirm",
  body = "Are you sure you want to continue?",
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  onClose = () => {},
  onConfirm = () => {},
  submitting = false,
  width = "510px",
  height = "255px"
}) => {
  return (
    <>
      <Modal
        title={title}
        width={width}
        height={height}
        close={onClose}
        backdrop={false}
      >
        {submitting ? (
          <ListLoader height={height} />
        ) : (
          <div>
            <p>
              <h4>{body}</h4>
              <br />
              <br />
            </p>
            <div className="mt-5 d-flex justify-content-end">
              <button onClick={onClose} className="btn-text-blue me-5 ">
                {cancelButtonText}
              </button>
              <button
                className="btn-small btn-red float-end mt-0"
                onClick={onConfirm}
              >
                {confirmButtonText}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
