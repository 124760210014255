import React, {useState, useEffect, useCallback} from "react";
import "../../../styles/customComponents/shared.scss";
import "./index.scss";
import Papa from "papaparse";
import vcf from "vcf";
import XLSX from "xlsx";
import {getExtension} from "../../../utils/file";
import {ImportContact} from "./ImportContact";
import {ImportSelectedContact} from "./ImportSelectedContact";
import {ImportSuccess} from "./ImportSuccess";

export const ImportContactManager = ({show, setShow, refresh}) => {
  const [showImportSelectedContact, setShowImportSelectedContact] = useState(
    false
  );
  const [showImportSuccess, setShowImportSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const [contacts, setContacts] = useState([]);

  let id = 0;
  const toObject = useCallback(
    (src) => ({
      id: id++,
      firstName:
        src["FirstName"] ||
        src["First Name"] ||
        src["Given Name"] ||
        src["Name"] ||
        "",
      lastName:
        src["LastName"] ||
        src["Last Name"] ||
        src["Family Name"] ||
        src["Name"] ||
        "",
      designation:
        src["Designation"] ||
        src["designation"] ||
        src["Title"] ||
        src["title"] ||
        src["Job Title"] ||
        "",
      email:
        src["Email"] ||
        src["email"] ||
        src["Mail"] ||
        src["mail"] ||
        src["E-mail Address"] ||
        src["E-mail 1 - Value"] ||
        "",
      phone:
        src["Phone"] || src["Mobile Phone"] || src["Phone 1 - Value"] || "",
      address:
        src["Address"] || src["address"] || src["Address 1 - Value"] || "",
      city:
        src["City"] ||
        src["city"] ||
        src["Location"] ||
        src["location"] ||
        src["Address"] ||
        src["Address 1 - Value"] ||
        "",
      state: src["State"] || src["state"] || "",
      country:
        src["Country"] ||
        src["country"] ||
        src["Address 1 - Country"] ||
        src["Home Country"] ||
        "",
      zipCode:
        src["ZipCode"] ||
        src["zipCode"] ||
        src["Zip Code"] ||
        src["zip code"] ||
        src["Home Postal Code"] ||
        src["Address 1 - Postal Code"] ||
        "",
      isSelected: true,
    }),
    [id]
  );

  const vCardToObject = useCallback(
    (vCard) => ({
      id: id++,
      firstName:
        vCard.get("n")?._data.split(";").slice(1).join(" ").trim() || "",
      lastName: vCard.get("n")?._data.split(";")?.shift()?.trim() || "",
      designation:
        vCard.get("title")?._data || vCard.get("title")?.shift()?._data || "",
      email:
        vCard.get("email")?._data || vCard.get("email")?.shift()?._data || "",
      phone: vCard.get("tel")?._data || vCard.get("tel")?.shift()?._data || "",
      address:
        vCard.get("adr")?._data || vCard.get("adr")?.shift()?._data || "",
      city:
        vCard.get("locality")?._data ||
        vCard.get("locality")?.shift()?._data ||
        "",
      state:
        vCard.get("region")?._data || vCard.get("region")?.shift()?._data || "",
      country:
        vCard.get("country-name")?._data ||
        vCard.get("country-name")?.shift()?._data ||
        "",
      zipCode:
        vCard.get("postal-code")?._data ||
        vCard.get("postal-code")?.shift()?._data ||
        "",
      isSelected: true,
    }),
    [id]
  );

  useEffect(() => {
    if (!file) return;
    if (getExtension(file.name) === "csv") {
      Papa.parse(file, {
        header: true,
        complete: function (results) {
          const list = results.data || [];
          const contacts = list.map(toObject);
          setContacts(contacts);
        },
      });
    } else if (getExtension(file.name) === "vcf") {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const list = vcf.parse(fileReader.result);
        const contacts = list.map(vCardToObject);
        setContacts(contacts);
      };
      fileReader.readAsText(file);
    } else if (
      getExtension(file.name) === "xls" ||
      getExtension(file.name) === "xlsx"
    ) {
      const fileReader = new FileReader();
      fileReader.onload = ({target}) => {
        const {result: bufferArray} = target;
        const wb = XLSX.read(bufferArray, {type: "buffer"});
        const ws = wb.Sheets[wb.SheetNames.shift()];
        const list = XLSX.utils.sheet_to_json(ws);
        const contacts = list.map(toObject);
        setContacts(contacts);
      };
      fileReader.readAsArrayBuffer(file);
    }
    return () => {};
  }, [file, toObject, vCardToObject]);

  return (
    <>
      {show && (
        <ImportContact
          onClose={() => setShow(false)}
          onConfirm={() => {
            setShowImportSelectedContact(true);
            setShow(false);
          }}
          file={file}
          setFile={setFile}
        />
      )}
      {showImportSelectedContact && (
        <ImportSelectedContact
          onClose={() => setShowImportSelectedContact(false)}
          onConfirm={() => {
            setShowImportSuccess(true);
            setShowImportSelectedContact(false);
          }}
          contacts={contacts}
          setContacts={setContacts}
        />
      )}
      {showImportSuccess && (
        <ImportSuccess
          onClose={() => {
            setFile(null);
            setContacts([]);
            setShowImportSuccess(false);
          }}
          onConfirm={() => {
            setFile(null);
            setContacts([]);
            setShowImportSuccess(false);
          }}
          refresh={refresh}
        />
      )}
    </>
  );
};
