import React from "react";
import {
  calculateColumnWidth,
  columnDropdownValues
} from "../../../../../Shared/util";
import {
  StyledArrowButton,
  StyledArrowIcon,
  StyledArrowsContainer,
  StyledColumnDropdown,
  StyledColumnDropdownOption,
  StyledComponentContainer,
  StyledHeader,
  StyledMain,
  StyledSection,
  StyledTitle,
  StyledToolButton,
  StyledToolButtonIcon,
  StyledToolsWrapper,
  StyledWrapper
} from "./styles";

const ComponentBox = ({
  children,
  title,
  onPreviewClick,
  onColumnChanged,
  onOrderButtonClick,
  onDeleteClick,
  onDuplicateClick,
  column = columnDropdownValues[0],
  columnEditable = true,
  previewMode = false
}) => {
  const toolButtons = [
    {
      icon: previewMode ? "bi bi-pen-fill" : "bi bi-search",
      onClick: onPreviewClick
    },
    { icon: "bi bi-union", onClick: onDuplicateClick },
    { icon: "bi bi-trash-fill", onClick: onDeleteClick }
  ];

  return (
    <StyledSection
      className={`col-${column ? calculateColumnWidth(column) : 12}`}
    >
      <StyledComponentContainer>
        {!previewMode && (
          <StyledArrowsContainer>
            <StyledArrowButton onClick={() => onOrderButtonClick("up")}>
              <StyledArrowIcon className="bi bi-chevron-up" />
            </StyledArrowButton>
            <StyledArrowButton onClick={() => onOrderButtonClick("down")}>
              <StyledArrowIcon className="bi bi-chevron-down" />
            </StyledArrowButton>
          </StyledArrowsContainer>
        )}
        <StyledWrapper>
          <StyledHeader>
            <StyledTitle>{previewMode ? "" : title}</StyledTitle>
            <StyledToolsWrapper>
              {columnEditable && (
                <StyledColumnDropdown
                  value={column}
                  onChange={e => onColumnChanged(e.currentTarget.value)}
                >
                  {columnDropdownValues.map(value => (
                    <StyledColumnDropdownOption key={value} value={value}>
                      {value}
                    </StyledColumnDropdownOption>
                  ))}
                </StyledColumnDropdown>
              )}
              {toolButtons.map(({ icon, onClick }, index) => (
                <StyledToolButton key={index} onClick={onClick}>
                  <StyledToolButtonIcon className={icon} />
                </StyledToolButton>
              ))}
            </StyledToolsWrapper>
          </StyledHeader>
          <StyledMain previewMode={previewMode}>{children}</StyledMain>
        </StyledWrapper>
      </StyledComponentContainer>
    </StyledSection>
  );
};

export default ComponentBox;
