import React, {useState, useEffect} from "react";
import {ServiceList} from "../../Layout/Shared/ServiceList";
import {CustomButton} from "../../Layout/Shared/Button";
import {ContactInformation} from "./Information";
import {LabelBlueBold} from "../../Layout/Shared/LabelBlueBold";
import {useHistory} from "react-router-dom";
import ProfilePicture from "../../../assets/images/default-profile-picture.png";
import {create} from "../../../data/Contact/Business/create";
import {InnerPageLeft} from "../../Layout/Shared/InnerPageLeft";
import {ContactProfilePicture} from "./ProfilePicture";
import DataLoading from "../../Layout/Shared/DataLoading";
import {BusinessContactCreateSchema} from "../../../schemas/Contact/Business/create";
import "./index.scss";
import {useLocation} from "react-router-dom";

export const AddNewContact = ({setShowImport, ...props}) => {
  const history = useHistory();
  const goToContactList = () => history.push(`/business/contact/list`);
  const [isLoading, setIsLoading] = useState(false);
  const [contact, setContact] = useState({
    firstName: "",
    lastName: "",
    designation: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });
  const [industries, setIndustries] = useState([]);
  const [photoUrl, setPhotoUrl] = useState(ProfilePicture);
  const [isFavorite, setIsFavorite] = useState(false);
  const [error, setError] = useState(null);
  const [groupId, setGroupId] = useState();
  const location = useLocation();

  useEffect(() => {
    if (!location || !location.state) return;
    setGroupId(location.state);
    return () => {};
  }, [location]);

  const validateForm = async (form) => {
    try {
      await BusinessContactCreateSchema.validate(form);
    } catch (error) {
      return error;
    }
  };

  const handleSubmit = async () => {
    const validationResponse = await validateForm(contact);
    if (validationResponse && validationResponse.errors) {
      setError(validationResponse.errors.shift());
      return;
    }
    setIsLoading(true);
    const createContact = {
      ...contact,
      industries,
      photoUrl,
      isFavorite,
      groups: groupId !== undefined && groupId ? [groupId] : [],
    };
    const {hasSuccess} = await create(createContact);
    if (hasSuccess) {
      setIsLoading(false);
      goToContactList();
    }
  };
  return (
    <div className="row m-2  bg-white border rounded-xl">
      <div className="col-md-12 p-5 border-bottom">
        <h4 className="ms-5  ps-5">
          Have a CSV or vCard file?
          <button
            className="btn-icon text-primary-blue"
            onClick={() => setShowImport(true)}
          >
            Import contact instead?
          </button>
        </h4>
      </div>
      {isLoading ? (
        <DataLoading />
      ) : (
        <>
          <div className="contact-left border-end">
            <InnerPageLeft>
              <div className="row">
                <ContactProfilePicture
                  profilePictureUrl={photoUrl}
                  setProfilePictureUrl={setPhotoUrl}
                  isFavorite={isFavorite}
                  setIsFavorite={setIsFavorite}
                  groupId={groupId}
                  setGroupId={setGroupId}
                />
              </div>
            </InnerPageLeft>
          </div>
          <div className="contact-right  pl-0 pe-5  pt-5">
            <div className="row pb-5 add-contact-page">
              <div className="page-left ps-5 mb-5">
                <ContactInformation
                  contact={contact}
                  setContact={setContact}
                  error={error}
                />
              </div>
              <div className="page-right pt-5 mt-5 ps-5 mb-5">
                <LabelBlueBold content="Select Services Category" />
                <ServiceList
                  selectedIndustries={industries}
                  setSelectedIndustries={setIndustries}
                  isService={true}
                />
                <div className="col-12 button-wrapper">
                  <button
                    className="btn btn-link font-weight-bold me-5 "
                    onClick={goToContactList}
                  >
                    Cancel
                  </button>
                  <CustomButton
                    title="Create Contact"
                    size="189px"
                    color="blue"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
