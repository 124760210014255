import React, {Fragment} from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {Question, RadiusInputHover, RequireText} from "../Base/BaseStyles";

export function InputTextHover({component_key, attributes, dispatch}) {
  const title = getAttribute("title", attributes);
  const question = getAttribute("question", attributes);
  const placeholder_text = getAttribute("placeholder", attributes);
  const require = getAttribute("require", attributes);
  const require_text = getAttribute("require_text", attributes);
  return (
    <Fragment>
      <div className={"row"}>
        <div className={"col-12"}>
          <Question>{question}</Question>
        </div>
        <div className={"col-12 mt-2"}>
          <RadiusInputHover
            type="text"
            className="form-control"
            aria-label=""
            placeholder={placeholder_text}
            value={title}
            onChange={(event) => {
              dispatch({
                type: "input_text_title_change",
                title: event.target.value,
                key: component_key,
              });
            }}
          />
        </div>
        {require === "true" && !title ? (
          <RequireText>{require_text}</RequireText>
        ) : (
          <div></div>
        )}
      </div>
    </Fragment>
  );
}

export default InputTextHover;
