import {hostName} from "../../../endpoint";
import {defaultHeaders} from "../../../headers";

export const registrationStaff = async (staff) => {
  try {
    const result = await fetch(`${hostName}/business/team-registration-staff`, {
      method: "PUT",
      headers: {
        ...defaultHeaders,
      },
      body: JSON.stringify(staff),
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    };
  }
};
