import React, { useState } from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import { RenderHtml } from "../../../../../components/Layout/Shared/RenderHtml";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./index.scss";
import useBusinessCaseManagementCaseFormContext from "../../../../../providers/CaseManagement/Cases/form";
import ReadOnlySummary from "./index.readonly";

const Summary = () => {
  const { setters, getters } = useBusinessCaseManagementCaseFormContext();
  const { formMode, summary, caseManagementCase } = getters;
  const { setSummary } = setters;
  const [selectedTab, setSelectedTab] = useState("write");

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });

  return formMode === "view" ? (
    <ReadOnlySummary
      caseManagementCase={caseManagementCase}
      summary={summary}
    />
  ) : (
    <section className="p-5">
      <div>
        <span className="d-flex pl-3 mt-5 pb-2">Case Summary</span>
        <ReactMde
          disabled={formMode === "view"}
          value={summary}
          onChange={summary => {
            formMode !== "view" && setSummary(summary);
          }}
          selectedTab={selectedTab}
          onTabChange={tab => formMode !== "view" && setSelectedTab(tab)}
          generateMarkdownPreview={markdown =>
            Promise.resolve(converter.makeHtml(markdown))
          }
          childProps={{
            writeButton: {
              tabIndex: -1
            }
          }}
        />
        <RenderHtml renderAs={"div"} html={converter.makeHtml(summary)} />
      </div>
    </section>
  );
};

export default Summary;
