import React from 'react'
import { useParams } from 'react-router-dom'
import FormViewer from '../../../components/Form/Viewer'

const FormSubmissionPage = () => {
  const { formId, submissionId } = useParams()
  return <FormViewer formId={formId} submissionId={submissionId} />
}

export default FormSubmissionPage
