import {CustomButton} from "../../../../../Layout/Shared/Button";
import Modal from "../../../../../Layout/Shared/Modal";
import ErrorImage from "../../../../../../assets/images/smile-sad-red.png";
import {Formik, Form, Field} from "formik";
import "./index.scss";
export const CancelAppointmentModal = ({onClose, heading}) => {
  return (
    <Modal title="&nbsp;" width="630px" height="380px" close={onClose}>
      <div className="cancel-modal-content">
        <div className="head">
          <img src={ErrorImage} alt="" />
          <h3 className="heading pt-4">{heading}</h3>
        </div>

        <div className="content">
          <span>Please explain why you want to cancel the appointment?</span>
          <Formik>
            <Form>
              <Field
                as="textarea"
                className="custom-textarea"
                placeholder="Type here..."
              />
            </Form>
          </Formik>
        </div>
        <div className="footer">
          <button onClick={onClose} className="btn-text-blue">
            Cancel
          </button>
          <CustomButton
            onClick={onClose}
            title="Cancel Appointment"
            size="176px"
            color="red"
          />
        </div>
      </div>
    </Modal>
  );
};
