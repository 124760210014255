import {LabelBlueBold} from "../../../../../../Layout/Shared/LabelBlueBold";
import ReactStars from "react-rating-stars-component";
export const Ratings = ({ratings}) => {
  return (
    <div className="mt-5">
      <LabelBlueBold content="Your Ratings" />
      {ratings.map((rating, index) => (
        <div
          key={index}
          className="w-75 d-flex justify-content-between align-items-center"
        >
          <span>{rating.name}</span>
          <div>
            <ReactStars value={rating.rating} size={22} />
          </div>
        </div>
      ))}
    </div>
  );
};
