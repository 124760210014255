/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  getCaseAttachments,
  createCaseAttachment,
  deleteCaseAttachment,
  getCaseForms,
  createCaseForm,
  deleteCaseForm,
  getCase
} from "../../../data/CaseManagement/cases";
import { getTasksByCaseId } from "../../../data/CaseManagement/tasks";
import { getTypes } from "../../../data/CaseManagement/types";
import { getStages } from "../../../data/CaseManagement/stages";
import { list as getClients } from "../../../data/Client/Business/get-list";
import { get as getStaffs } from "../../../data/Settings/Business/Team/getStaffs";
import { fullNameFormatter } from "../../../utils/string";
import {
  useTaskModel,
  formatter as taskFormatter
} from "../../CaseManagement/Tasks/list.custom-hooks";

export const useCaseModel = () => {
  const now = new Date();
  const [loading, setLoading] = useState(false);
  const [caseManagementCase, setCaseManagementCase] = useState({
    title: "",
    typeId: "",
    status: "Not Started",
    stageId: "",
    clientId: "",
    priority: "Low",
    assignedTo: "",
    startDate: now,
    dueDate: now,
    updatedAt: now
  });
  const [summary, setSummary] = useState("");
  const [note, setNote] = useState("");

  const load = async (id, forUpdate = false) => {
    setLoading(true);
    const { data, hasSuccess } = await getCase(id);
    if (hasSuccess) {
      const { summary, note, ...rest } = data;
      const formattedRest = forUpdate ? updateFormatter(rest) : rest;
      setCaseManagementCase(formattedRest);
      setSummary(summary);
      setNote(note);
    }
    setLoading(false);
  };

  const getModel = () => {
    const model = { ...caseManagementCase, summary, note };
    delete model.updatedAt;
    return model;
  };

  return {
    caseManagementCase,
    summary,
    note,
    setCaseManagementCase,
    setSummary,
    setNote,
    loading,
    load,
    getModel
  };
};

export const useCaseFormData = () => {
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [stages, setStages] = useState([]);
  const [clients, setClients] = useState([]);
  const [staffs, setStaffs] = useState([]);

  const load = async () => {
    setLoading(true);
    const [
      { data: typesData, hasSuccess: typesSuccess },
      { data: stagesData, hasSuccess: stagesSuccess },
      { data: clientsData, hasSuccess: clientsSuccess },
      { data: staffsData, hasSuccess: staffsSuccess }
    ] = await Promise.all([getTypes(), getStages(), getClients(), getStaffs()]);

    if (typesSuccess) setTypes(typesData);
    if (stagesSuccess) setStages(stagesData);
    if (clientsSuccess) setClients(clientsData.map(fullNameFormatter));
    if (staffsSuccess) setStaffs(staffsData.map(fullNameFormatter));
    setLoading(false);
  };

  const loadStaffs = async () => {
    setLoading(true);
    const { data, hasSuccess } = await getStaffs();
    if (hasSuccess) setStaffs(data.map(fullNameFormatter));
    setLoading(false);
  };

  return {
    types,
    stages,
    clients,
    staffs,
    loading,
    load,
    loadStaffs
  };
};

export const updateFormatter = data => {
  const now = new Date();
  return {
    title: data.title ?? "",
    typeId: data.typeId ?? "",
    status: data.status ?? "Not Started",
    stageId: data.stageId ?? "",
    clientId: data.clientId ?? "",
    priority: data.priority ?? "Low",
    assignedTo: data.assignedTo?._id ?? "",
    startDate: data.startDate ?? now,
    dueDate: data.dueDate ?? now,
    updatedAt: data.updatedAt ?? now
  };
};

export const useAttachments = id => {
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAttachments = async () => {
    setLoading(true);
    const { data, hasSuccess } = await getCaseAttachments(id);
    if (hasSuccess) setAttachments(data);
    setLoading(false);
  };

  const createAttachment = async (file, meta) =>
    createCaseAttachment(id, file, meta);

  const removeAttachment = async key => {
    setLoading(true);
    const { hasSuccess } = await deleteCaseAttachment(id, 0, {
      key
    });
    setLoading(false);
    return hasSuccess;
  };

  useEffect(() => {
    if (id) getAttachments();
  }, [id]);

  return {
    attachments,
    loading,
    getAttachments,
    createAttachment,
    removeAttachment
  };
};

export const useForms = id => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);

  const getForms = async () => {
    setLoading(true);
    const { data, hasSuccess } = await getCaseForms(id);
    if (hasSuccess) setForms(data);
    setLoading(false);
  };

  const createForm = async form => {
    setLoading(true);
    const { hasSuccess } = await createCaseForm(id, form);
    if (hasSuccess) await getForms();
    setLoading(false);
  };

  const removeForm = async formId => {
    setLoading(true);
    const { hasSuccess } = await deleteCaseForm(id, formId);
    if (hasSuccess) await getForms();
    setLoading(false);
  };

  useEffect(() => {
    if (id) getForms();
  }, [id]);

  return {
    forms,
    loading,
    getForms,
    createForm,
    removeForm
  };
};

export const useTasks = (currentUser, id) => {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const taskModel = useTaskModel(currentUser);

  taskModel.reset = () =>
    taskModel.setTask(
      taskFormatter(
        {
          caseId: id
        },
        currentUser
      )
    );

  const load = async () => {
    setLoading(true);
    const { data, hasSuccess } = await getTasksByCaseId(id);
    if (hasSuccess) setTasks(data);
    setLoading(false);
  };

  useEffect(() => {
    if (id) load();
  }, [id]);

  return {
    loading,
    tasks,
    load,
    taskModel
  };
};
