import * as Yup from "yup";

export const GroupEditSchema = Yup.object().shape({
  name: Yup.string()
    .required("Group Name is required!")
    .min(3, "Group Name is too Short!")
    .max(50, "Group Name is too Long!"),
  description: Yup.string()
    .min(3, "Description is too Short!")
    .max(500, "Description is too Long!"),
});
