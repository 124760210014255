import styled from "styled-components";
import * as theme from "../../../../../../../styles/theme";


export const StyledCheckboxContainer = styled.label`
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
`

export const StyledCheckboxInput = styled.input`
    width:16px;
    height:16px;
`

export const StyledCheckboxText = styled.span`
    font-family: ${theme.defaultFont};
    font-size:16px;
    color: #495057;
    user-select: none;
`
