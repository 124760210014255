import { chatsApiHostName } from "../endpoint";
import { defaultHeaders } from "../headers";

export const getChats = async () => {
  try {
    const response = await fetch(`${chatsApiHostName}/chats`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }
    });
    if (response.status !== 200) {
      throw new Error("Error fetching chats");
    }
    const { data } = await response.json();
    return data;
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
