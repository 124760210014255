import React from "react";
import { updateOptions } from "../../../utils";
import { SignatureTypes } from "./types";
import { StyledContainer } from "./styles";
import { StyledRadiusInput } from "../../styles";
import Tab from "../Shared/Tab";
import ComponentBox from "../Shared/Box";
import DrawingSignature from "./Components/DrawingSignature";
import ImageSignature from "./Components/ImageSignature";
import Tools from "./Components/Tools";
import Designation from "./Components/Designation";
import PolicyCheckbox from "./Components/PolicyCheckbox";
import Options from "./OptionsComponent";
import ESignature from "../../../../Viewer/Components/ESignature";

const ESignatureComponent = ({
  component,
  isReadOnly,
  onOptionsChange,
  onOrderButtonClick,
  onDeleteClick,
  onDuplicateClick,
  boxVisible = true
}) => {
  const updateValue = (valueType, value) => {
    if (!isReadOnly) {
      let newOptions = updateOptions(component.options, "valueType", valueType);
      newOptions = updateOptions(newOptions, "value", value);
      onOptionsChange(newOptions);
    }
  };
  const isDrawingSignature =
    component.options.valueType === SignatureTypes.Drawing;
  const isTextSignature = component.options.valueType === SignatureTypes.Text;
  const isImageSignature = component.options.valueType === SignatureTypes.Image;

  const editComponent = (
    <StyledContainer>
      {isDrawingSignature && (
        <DrawingSignature
          isReadOnly={isReadOnly}
          value={component.options.value}
          onChange={value => updateValue("drawing", value)}
        />
      )}

      {isTextSignature && (
        <StyledRadiusInput
          className="form-control"
          placeholder="Signature"
          readOnly={isReadOnly}
          value={component.options.value}
          onChange={e => updateValue("text", e.currentTarget.value)}
        />
      )}

      {isImageSignature && (
        <ImageSignature
          isReadOnly={isReadOnly}
          value={component.options.value}
          onChange={value => updateValue("image", value)}
        />
      )}

      {!isReadOnly && (
        <Tools
          onClearClick={() => updateValue("drawing", "")}
          onTypeChanged={valueType => updateValue(valueType, "")}
        />
      )}

      <Designation
        isReadOnly={isReadOnly}
        designation={component.options.designation}
        fullName={component.options.fullName}
        onDesignationChange={value =>
          onOptionsChange(
            updateOptions(component.options, "designation", value)
          )
        }
        onFullNameChange={value =>
          onOptionsChange(updateOptions(component.options, "fullName", value))
        }
      />

      <PolicyCheckbox
        isReadOnly={isReadOnly}
        policyAccepted={component.options.policyAccepted}
        onChange={value =>
          onOptionsChange(
            updateOptions(component.options, "policyAccepted", value)
          )
        }
      />
    </StyledContainer>
  );

  const optionsComponent = (
    <Options
      options={component.options}
      onChanged={(key, value) =>
        onOptionsChange(updateOptions(component.options, key, value))
      }
    />
  );

  const previewComponent = (
    <ESignature
      component={component}
      onChange={newOptions => onOptionsChange(newOptions)}
    />
  );

  if (isReadOnly || !boxVisible) {
    return editComponent;
  }

  return (
    <ComponentBox
      title="Add E-Signature Field"
      column={component.options.column}
      previewMode={component.options.preview}
      onPreviewClick={() => {
        onOptionsChange(
          updateOptions(
            component.options,
            "preview",
            !component.options.preview
          )
        );
      }}
      onDeleteClick={onDeleteClick}
      onDuplicateClick={onDuplicateClick}
      onColumnChanged={column =>
        onOptionsChange(updateOptions(component.options, "column", column))
      }
      onOrderButtonClick={onOrderButtonClick}
    >
      {component.options.preview ? (
        previewComponent
      ) : (
        <Tab
          editComponent={editComponent}
          optionsComponent={optionsComponent}
        />
      )}
    </ComponentBox>
  );
};

export default ESignatureComponent;
