import React, {Fragment} from "react";

import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {
  PreviewDefaultMargin,
  PreviewInputOption,
  PreviewQuestion,
  PreviewLabel,
  TextAreaPreview,
} from "../../Styles/PreviewStyles";
import {FormCheck, RequireText} from "../Base/BaseStyles";

export function RadioButtonPreview({dispatch, attributes, component_key}) {
  const question = getAttribute("question", attributes);
  const options = getAttribute("options", attributes);
  const comment = getAttribute("comment", attributes);
  const checked = getAttribute("checked", attributes);
  const require = getAttribute("require", attributes);
  const require_text = getAttribute("require_text", attributes);
  const option_groups = [];
  const row_count = Math.ceil(options.length / 2);
  for (let i = 0; i < row_count; i++) {
    option_groups[i] = options.slice(i * 2, i * 2 + 2);
  }
  return (
    <Fragment>
      <div className={"row"}>
        <div className={"col-12"}>
          <PreviewQuestion>{question}</PreviewQuestion>
        </div>
        {option_groups.map((option_group, i) => (
          <PreviewDefaultMargin className={"row"} key={i}>
            {option_group.map((option, j) => (
              <div className={"col-6"} key={j}>
                <FormCheck className="form-check">
                  <PreviewInputOption
                    className="form-check-input"
                    type="radio"
                    checked={checked === option}
                    onChange={(e) => {
                      dispatch({
                        type: "radio_button_checked_change",
                        key: component_key,
                        checked: e.target.checked,
                        option: option,
                      });
                    }}
                  />
                  <PreviewLabel
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {option}
                  </PreviewLabel>
                </FormCheck>
              </div>
            ))}
          </PreviewDefaultMargin>
        ))}
      </div>

      <div className={"row"}>
        <div className={"col-12"}>
          <div className="input-group">
            <TextAreaPreview
              className="form-control"
              aria-label=""
              placeholder="Enter your comment"
              value={comment}
              onChange={(e) =>
                dispatch({
                  type: "radio_button_change_comment",
                  key: component_key,
                  comment: e.target.value,
                })
              }
            />
          </div>
          {require === "true" && !comment ? (
            <RequireText>{require_text}</RequireText>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default RadioButtonPreview;
