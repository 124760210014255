/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { getChats } from "../../data/Chat/getChats";
import { updateSeen } from "../../data/Chat/updateSeen";
import { createWebSocket, sendWebSocketMessage } from "./utils";
import { useGlobalContext } from "../global";
import { getContacts } from "../../data/Chat/getContacts";
import { createChat as createChatService } from "../../data/Chat/createChat";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const { currentUser, accessToken: token } = useGlobalContext();

  const [chats, setChats] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedChat, setSelectedChat] = useState();
  const [receivedMessage, setReceivedMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const socket = useRef(null);

  const ACTIONS = {
    NEW_MESSAGE: "newMessage",
    UPDATE_TEXT_MESSAGE: "updateTextMessage",
    DELETE_MESSAGE: "deleteMessage",
    SEND_MESSAGE: "sendMessage"
  };

  const initializeWebSocket = token => {
    if (socket.current) return;

    let reconnectTimeout;

    const reconnectAfterDelay = () => {
      reconnectTimeout = setTimeout(() => {
        console.log("Reconnecting in 3 seconds...");
        initializeWebSocket();
      }, 3000);
    };

    socket.current = createWebSocket({
      onOpen: () => {
        setIsConnected(true);
        console.log("WebSocket connection opened");
        clearTimeout(reconnectTimeout);
      },
      onMessage: setReceivedMessage, // handleOnMessage,
      onError: error => {
        setIsConnected(false);
        console.error("WebSocket error:", error);
      },
      onClose: async () => {
        setIsConnected(false);
        setIsReady(false);
        socket.current = null;
        console.log("WebSocket connection closed");
        reconnectAfterDelay();
      },
      token
    });
  };

  const reconnect = () => {
    if (socket.current) {
      socket.current.close();
      socket.current = null;
    }

    initializeWebSocket(token);
  };

  const loadData = async () => {
    try {
      setIsLoading(true);

      const [chats = [], users = []] = await Promise.all([
        getChats(),
        getContacts()
      ]);

      setUsers(users);
      setChats(chats);
      setIsDataLoaded(true);

      return { chats, users };
    } catch (error) {
      console.error("Error loading data:", error);
      setIsDataLoaded(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnMessage = data => {
    if (!data || !data.action || !data.data) {
      console.error("Invalid message format");
      return;
    }

    const ACTION_HANDLERS = {
      [ACTIONS.NEW_MESSAGE]: handleOnNewMessage,
      [ACTIONS.UPDATE_TEXT_MESSAGE]: handleOnUpdateTextMessage,
      [ACTIONS.DELETE_MESSAGE]: handleOnDeleteMessage
    };

    const actionHandler = ACTION_HANDLERS[data.action];

    if (!actionHandler) {
      console.error(`Unknown action: ${data.action}`);
      return;
    }

    try {
      actionHandler(data.data);
    } catch (error) {
      console.error(`Error handling action ${data.action}:`, error);
    }
  };

  const handleOnNewMessage = async data => {
    if (!data || !data.chatId || !data.id) {
      console.error("Invalid data object");
      return;
    }

    const update = (_chats, isReload = false) => {
      const chat = _chats.find(chat => chat.id === data.chatId);

      if (!chat) {
        console.error(`Chat with ID ${data.chatId} not found`);
        return;
      }

      if (!chat.messages.some(m => m.id === data.id)) chat.messages.push(data);

      if (selectedChat?.id !== chat.id) chat.unSeenCount++;
      else updateSeen(chat.id);

      if (isReload) setChats([..._chats]);
      else
        setChats(prev => {
          return prev.map(c => {
            if (c.id === chat.id) return { ...chat };
            return c;
          });
        });

      if (selectedChat?.id === chat.id) setSelectedChat({ ...chat });
    };

    const chatExists = chats.some(chat => chat.id === data.chatId);
    if (chatExists) update(chats);
    else {
      try {
        const { chats: newChats = [] } = await loadData();
        update(newChats, true);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    }
  };

  const handleOnUpdateTextMessage = data => {
    if (!data || !data.chatId || !data.messageId || !data.text) {
      console.error("Invalid data object");
      return;
    }

    const { chatId, messageId, text } = data;

    const chat = chats.find(chat => chat.id === chatId);

    if (!chat) {
      console.error(`Chat with ID ${chatId} not found`);
      return;
    }

    const message = chat.messages.find(m => m.id === messageId);

    if (!message) {
      console.error(`Message with ID ${messageId} not found in chat ${chatId}`);
      return;
    }

    setChats(prev => {
      return prev.map(chat => {
        if (chat.id === chatId) {
          chat.messages = chat.messages.map(m => {
            if (m.id === messageId) m.message.content = text;
            return m;
          });
        }
        return chat;
      });
    });

    if (selectedChat?.id === chatId)
      setSelectedChat(prev => {
        return {
          ...prev,
          messages: prev.messages.map(m => {
            if (m.id === messageId) m.message.content = text;
            return m;
          })
        };
      });
  };

  const handleOnDeleteMessage = data => {
    if (!data || !data.chatId || !data.messageId) {
      console.error("Invalid data object");
      return;
    }

    const { chatId, messageId } = data;

    const chat = chats.find(chat => chat.id === chatId);
    if (!chat) {
      console.error(`Chat with ID ${chatId} not found`);
      return;
    }

    const messageExists = chat.messages.some(m => m.id === messageId);
    if (!messageExists) {
      console.error(`Message with ID ${messageId} not found in chat ${chatId}`);
      return;
    }

    setChats(prev => {
      return prev.map(chat => {
        if (chat.id === chatId)
          chat.messages = chat.messages.filter(m => m.id !== messageId);
        return chat;
      });
    });

    if (selectedChat?.id === chatId)
      setSelectedChat(prev => {
        return {
          ...prev,
          messages: prev.messages.filter(m => m.id !== messageId)
        };
      });
  };

  const sendMessage = (content, type) => {
    if (!content) {
      console.error(`${type === "text" ? "Message" : "Attachment"} is missing`);
      return;
    }

    if (!token) {
      console.error("Access token is missing");
      return;
    }

    if (!selectedChat || !selectedChat.id) {
      console.error("No selected chat or chat ID is missing");
      return;
    }

    if (
      !socket.current ||
      (socket.current && socket.current.readyState !== WebSocket.OPEN)
    ) {
      console.error("WebSocket is not connected");
      return;
    }

    const data = {
      token: token,
      chatId: selectedChat.id,
      message: {
        type,
        content
      }
    };

    try {
      sendWebSocketMessage({
        socket: socket.current,
        message: data,
        action: ACTIONS.SEND_MESSAGE,
        token
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const updateTextMessage = (message, newContent) => {
    if (!message || !message.chatId || !message.id) {
      console.error("Invalid message object");
      return;
    }

    if (!newContent) {
      console.error("New content is missing");
      return;
    }

    if (!token) {
      console.error("Access token is missing");
      return;
    }

    if (
      !socket.current ||
      (socket.current && socket.current.readyState !== WebSocket.OPEN)
    ) {
      console.error("WebSocket is not connected");
      return;
    }

    const data = {
      token: token,
      chatId: message.chatId,
      messageId: message.id,
      text: newContent
    };

    try {
      sendWebSocketMessage({
        socket: socket.current,
        message: data,
        action: ACTIONS.UPDATE_TEXT_MESSAGE,
        token
      });

      const chat = chats.find(p => p.id === message.chatId);
      if (!chat) {
        console.error(`Chat with ID ${message.chatId} not found`);
        return;
      }

      const existingMessage = chat.messages.find(p => p.id === message.id);
      if (!existingMessage) {
        console.error(
          `Message with ID ${message.id} not found in chat ${message.chatId}`
        );
        return;
      }

      setChats(prev => {
        return prev.map(chat => {
          if (chat.id === message.chatId) {
            chat.messages = chat.messages.map(m => {
              if (m.id === message.id) m.message.content = newContent;
              return m;
            });
          }
          return chat;
        });
      });

      if (selectedChat?.id === message.chatId)
        setSelectedChat(prev => {
          return {
            ...prev,
            messages: prev.messages.map(m => {
              if (m.id === message.id) m.message.content = newContent;
              return m;
            })
          };
        });
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };

  const deleteMessage = message => {
    if (!message || !message.chatId || !message.id) {
      console.error("Invalid message object");
      return;
    }

    if (!token) {
      console.error("Access token is missing");
      return;
    }

    if (
      !socket.current ||
      (socket.current && socket.current.readyState !== WebSocket.OPEN)
    ) {
      console.error("WebSocket is not connected");
      return;
    }

    const data = {
      token: token,
      chatId: message.chatId,
      messageId: message.id
    };

    try {
      sendWebSocketMessage({
        socket: socket.current,
        message: data,
        action: ACTIONS.DELETE_MESSAGE,
        token
      });

      const chat = chats.find(p => p.id === message.chatId);
      if (!chat) {
        console.error(`Chat with ID ${message.chatId} not found`);
        return;
      }

      const existingMessage = chat.messages.find(p => p.id === message.id);
      if (!existingMessage) {
        console.error(
          `Message with ID ${message.id} not found in chat ${message.chatId}`
        );
        return;
      }

      setChats(prev => {
        return prev.map(chat => {
          if (chat.id === message.chatId)
            chat.messages = chat.messages.filter(m => m.id !== message.id);
          return chat;
        });
      });

      if (selectedChat?.id === message.chatId)
        setSelectedChat(prev => {
          return {
            ...prev,
            messages: prev.messages.filter(m => m.id !== message.id)
          };
        });
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const createChat = async (userIds, groupName) => {
    try {
      setUsers(prev => {
        return prev.map(u => {
          if (userIds.includes(u.id)) u.isLoading = true;
          return u;
        });
      });

      const {
        data: { chatId }
      } = await createChatService(userIds, groupName);

      const { chats: _chats = [] } = await loadData();

      const chat = _chats.find(chat => chat.id === chatId);
      if (!chat) {
        console.error(`Chat with ID ${chatId} not found`);
        return null;
      }

      setSelectedChat(chat);
      return chat;
    } catch (error) {
      console.error("Error creating chat:", error);
      return null;
    } finally {
      setUsers(prev => {
        return prev.map(u => {
          if (userIds.includes(u.id)) u.isLoading = false;
          return u;
        });
      });
    }
  };

  useEffect(() => {
    const _isReady =
      socket.current &&
      socket.current.readyState === WebSocket.OPEN &&
      isConnected &&
      isDataLoaded;
    setIsReady(_isReady);
  }, [isConnected, isDataLoaded]);

  useEffect(() => {
    if (receivedMessage) handleOnMessage(receivedMessage);
  }, [receivedMessage]);

  useEffect(() => {
    if (!selectedChat || selectedChat.unSeenCount <= 0) return;
    updateSeen(selectedChat.id);
    selectedChat.unSeenCount = 0;
    setChats([...chats]);
  }, [selectedChat]);

  useEffect(() => {
    const init = async () => {
      if (!currentUser || !token) {
        dispose();
        return;
      }

      await loadData();
      initializeWebSocket(token);
    };

    const dispose = () => {
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
      setIsConnected(false);
      setChats([]);
      setUsers([]);
      setIsReady(false);
    };

    init();
    return () => {
      dispose();
    };
  }, [currentUser, token]);

  const value = {
    chats,
    users,
    selectedChat,
    setSelectedChat,
    createChat,
    sendTextMessage: message => sendMessage(message, "text"),
    updateTextMessage,
    deleteMessage,
    loadData,
    isReady,
    reconnect,
    sendAttachmentMessage: attachment => sendMessage(attachment, "attachment")
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => useContext(ChatContext);
