import React, { useState } from 'react'
import {
  CreateFormContainer,
  StyledCard,
  StyledCardBody,
  StyledCardDescription,
  StyledCardIcon,
  StyledCardTitle,
  StyledMessage,
  StyledOptions,
} from './create.style'
import * as Icon from '../../../assets/icons/icon'
import FormContainerHeader from '../Shared/header.component'
import Heading from '../../Layout/Shared/Heading/heading.component'
import CreateModal from './CreateModal'

const CreateForm = () => {
  const createFormOptions = [
    {
      title: 'Form Builder',
      slug: '',
      click: (e) => {
        e.preventDefault()
        setCreateFormModalVisible(true)
      },
      description: 'Build From Scratch',
      icon: Icon.IconWrench,
    },
    {
      title: 'Use Template',
      slug: 'templates',
      description: 'From Library',
      icon: Icon.IconMetro,
    },
    {
      title: 'All Forms',
      slug: 'list',
      description: 'From Your Previous Forms',
      icon: Icon.IconHistory,
    },
  ]
  const [createFormModalVisible, setCreateFormModalVisible] = useState(false)
  return (
    <>
      {createFormModalVisible && (
        <CreateModal close={() => setCreateFormModalVisible(false)} />
      )}
      <CreateFormContainer>
        <FormContainerHeader>
          <Heading as="h2">New Form</Heading>
        </FormContainerHeader>
        <StyledMessage>How would you like to get started?</StyledMessage>
        <StyledOptions>
          {createFormOptions.map((option, index) => (
            <StyledCard key={index}>
              <StyledCardBody onClick={option.click} to={option.slug}>
                <StyledCardIcon> {option.icon}</StyledCardIcon>
                <StyledCardTitle>{option.title}</StyledCardTitle>
                <StyledCardDescription>
                  {option.description}
                </StyledCardDescription>
              </StyledCardBody>
            </StyledCard>
          ))}
        </StyledOptions>
      </CreateFormContainer>
    </>
  )
}

export default CreateForm
