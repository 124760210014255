import {hostName} from "../endpoint";
import {defaultHeaders} from "../headers";

export const checkExistingEmail = async (email) => {
  const result = await fetch(`${hostName}/business/is-email-in-use`, {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify(email),
  });
  return result.json();
};
