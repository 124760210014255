import styled from "styled-components";
import * as theme from "../../Styles/Theme.js";

export const DescriptionTitle = styled.div`
  font-family: ${theme.defaultFont};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: left;
  color: #495057;
`;
export const Wrapper = styled.div`
  position: relative;
`;
export const FormStateButton = styled.button`
  margin-right: 1em;
  margin-top: 1em;
`;
export const BaseEditTitle = styled(DescriptionTitle)`
  margin-left: 2em;
`;
export const CheckboxOptionTitle = styled(DescriptionTitle)`
  margin-left: 0.2em;
  padding-left: 0.5em;
  margin-bottom: 0.5em;
`;
export const BaseIcon = styled.i`
  font-size: 0.9em;
  padding: 0.3em 0.4em 0.3em 0.4em;
  cursor: pointer;
  color: ${theme.azul};
`;
export const BaseEditIcon = styled(BaseIcon)`
  color: ${(props) => (props.clickable ? theme.azul : "#b2b5b8")};
`;

export const ESignatureFunctionButton = styled(BaseIcon)`
  color: ${(props) => (props.clickable ? theme.azul : "#b2b5b8")};
  -webkit-text-stroke: ${(props) =>
    props.clickable ? "0.4px #1b84e7" : "0.4px #dee2e6"};
`;

export const AssigneeRemoveIconButton = styled.i`
  font-size: 0.9em;
  padding: 0.3em 0em 0.3em 0.5em;
  cursor: pointer;
  color: "#ffffff";
  -webkit-text-stroke: "0.4px #1b84e7";
`;

export const RadiusInput = styled.input`
  border-radius: 25px 25px 25px 25px;
  /*border-color: ${theme.azul};*/
  margin-left: 1.6em;
  padding: 1rem 1.75rem 1rem 1.75rem;
  height: 32px !important;
  font-size: 13px;
  box-shadow: 0 4px 6px 0 rgb(167 190 255 / 37%);

  &::placeholder {
    color: #b7bdc3;
    font-family: ${theme.defaultFont};
    font-size: 13px;
  }
`;

export const CheckboxOptionRadiusInput = styled(RadiusInput)`
  margin-bottom: 0.4em;
  margin-left: 0;
  border-radius: 26px;
  border: solid 1px #dee2e6;
  box-shadow: 0 0px 0px 0 white;
`;

export const CheckboxHoverOptionInput = styled.input`
  margin-bottom: 0.8em;
`;
export const RadioButtonOptionRadiusInput = styled(RadiusInput)`
  margin-bottom: 0.4em;
  margin-left: 0;
  border: solid 1px #dee2e6;
  box-shadow: 0 0px 0px 0 white;
`;

export const RadioButtonHoverOptionInput = styled.input`
  margin-bottom: 0.8em;
`;
export const RadioButtonOptionTitle = styled(DescriptionTitle)`
  margin-left: 0.2em;
  padding-left: 0.5em;
  margin-bottom: 0.5em;
`;

export const LeftRadiusInput = styled(RadiusInput)`
  border-radius: 25px 0 0 25px;
`;
export const RadiusInputHover = styled(RadiusInput)`
  margin-left: 0;
`;

export const DropdownText = styled.a`
  font-family: ${theme.defaultFont};
  letter-spacing: normal;
  text-align: left;
  color: #495057;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-size: 16px;
`;
export const Dropdown = styled.button`
  font-family: ${theme.defaultFont};
  letter-spacing: normal;
  text-align: left;
  color: #495057;
  box-shadow: 0 4px 6px 0 rgb(167 190 255 / 37%);
  border: 1px solid #ced4da;
  background-color: white;
  height: 32px !important;
  padding-left: 1.5em;

  &:after {
    margin-left: 0.8em;
    margin-right: 0.8em;
  }

  &:hover {
    border: 1px solid #ced4da;
  }

  &:active,
  &:focus,
  &.show:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
`;
export const RightRadiusDropdown = styled(Dropdown)`
  border-radius: 0 25px 25px 0;
`;

export const AlignmentDropdown = styled(Dropdown)`
  border-radius: 0 0 0 0 !important;
`;
export const ColumnDropdown = styled(Dropdown)`
  padding: 0px 3px;
  font-size: 12px !important;
  height: 18px !important;
  box-shadow: unset;
  margin-right: 2px;
`;
export const EditSection = styled.div`
  align-items: unset !important;
`;
export const ColumnDropdownMenu = styled.ul`
  .dropdown-item {
    font-size: 12px !important;
  }
`;
export const ToolboxDropdownStyle = styled.div`
  margin-left: auto;

  :hover {
    background: white;
  }
`;
export const ToolboxButtonDropdown = styled(Dropdown)`
  padding: 5px 5px 5px 5px;
  box-shadow: unset;

  &:after {
    margin-left: 0.4em;
    margin-right: 0.1em;
  }
`;
export const BaseEditFrame = styled.div`
  background-color: #fafafa;
  padding: 0.75em 1.25em 1.25em 1.25em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
`;
export const BaseEditFrameConfirmed = styled.div`
  background-color: #fafafa;
  padding: 0.75em 1.25em 0.75em 1.25em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
`;
export const InputGroup = styled.div`
  /* padding-right: 2em;*/
`;
export const SignatureWrapper = styled.div`
  width: 90%;
  margin-right: 50px;
`;
export const SignatureStyle = styled.div`
  width: 80%;
  margin: 20px;
  height: 200px;
`;
export const OptionsInputGroup = styled.div`
  padding-right: 2em;
  padding-left: 2em;
`;
export const FormCheck = styled.div`
  display: block;
`;
export const HeadingInputGroup = styled(InputGroup)`
  padding-right: 2em;
`;
export const LabelInputGroup = styled(InputGroup)`
  padding-right: 2em;
`;
export const UpIcon = styled.i`
  position: absolute;
  top: -0.6em;
  color: ${theme.azul};
  height: 1.2em;

  &:hover {
    text-shadow: 1px 2px 3px #004eff;
  }
`;
export const DownIcon = styled.i`
  position: absolute;
  top: 0.2em;
  color: ${theme.azul};

  &:hover {
    text-shadow: 1px 2px 3px #004eff;
  }
`;
export const Heading = styled.div`
  padding-left: 15px;
  text-align: ${(props) => props.textAlignment};
`;

export const HeadingName = styled.div`
  color: #eb0056;
  font-size: 16px;
  font-family: ${theme.defaultFont};
  margin-left: 0.7em;
`;
export const Label = styled.div`
  color: ${theme.azul};
  font-size: 13px;
  font-weight: bold;
  font-family: ${theme.defaultFont};
`;
export const Question = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${theme.azul};
  font-family: ${theme.defaultFont};
  margin-bottom: 0.3em;
`;
export const ComponentTabsHeaderStyle = styled.div`
  margin-bottom: 1em;
  padding-left: 27px;
`;
export const CheckboxLabel = styled.div`
  font-size: 16px;
  color: #495057;
  margin-bottom: 0.5em;
`;
export const TextArea = styled.textarea`
  border-radius: 16px;
  padding: 1rem 1.75rem 1rem 1.75rem;
  font-size: 13px;
  box-shadow: 0 4px 6px 0 rgb(167 190 255 / 37%);

  &::placeholder {
    color: #b7bdc3;
    font-family: ${theme.defaultFont};
    font-size: 13px;
  }
`;

export const RequireQuestionTextArea = styled(TextArea)`
  margin-left: 1.3em;
`;
export const RequireText = styled.div`
  color: #ee3d3d;
  padding-left: 1em;
  padding-top: 0.3em;
`;
export const OptionDiv = styled.div`
  margin-left: 2em;
  margin-right: 2em;
  border-radius: 8px;
  border: solid 1px #dee2e6;
  margin-top: 0.5em;
  padding: 0.7em 1em;
`;
export const CheckboxHover = styled.div`
  display: block;
`;
export const PageBreakLine = styled.div``;
