import React from "react";
import { FormTypes } from "../Form/constants";
import Forms from "./TabContainer/Forms/forms.component";
 
export const tabs = [
  {
    name: "All",
    key: "submissions",
    component: <Forms />,
    isDefault: true,
  },
  {
    name: "Administrative",
    key: FormTypes.Administrative.toLowerCase(),
    component: <Forms formType={FormTypes.Administrative} />,
  },
  {
    name: "External",
    key: FormTypes.External.toLowerCase(),
    component:  <Forms formType={FormTypes.External} />,
  },
  {
    name: "Survey",
    key: FormTypes.Survey.toLowerCase(),
    component: <Forms formType={FormTypes.Survey} />,
  },
];
export const defaultFormTab = tabs.find((tab) => tab.isDefault);
