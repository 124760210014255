export const CASE_MANAGEMENT_COLORS = {
  NONE: {
    LIGHT: {
      backgroundColor: "#dce3e8",
      color: "#3f5464"
    },
    DARK: {
      backgroundColor: "#5c7282",
      color: "#ffffff"
    }
  },
  INFORMATION: {
    LIGHT: {
      backgroundColor: "#d4e5fa",
      color: "#0d4ea6"
    },
    DARK: {
      backgroundColor: "#186ade",
      color: "#ffffff"
    }
  },
  SUCCESS: {
    LIGHT: {
      backgroundColor: "#c7ebd1",
      color: "#045e45"
    },
    DARK: {
      backgroundColor: "#077d55",
      color: "#ffffff"
    }
  },
  WARNING: {
    LIGHT: {
      backgroundColor: "#faf6cf",
      color: "#e86426"
    },
    DARK: {
      backgroundColor: "#f5c519",
      color: "#090f15"
    }
  },
  ERROR: {
    LIGHT: {
      backgroundColor: "#fadcd9",
      color: "#a1150b"
    },
    DARK: {
      backgroundColor: "#d92010",
      color: "#ffffff"
    }
  }
};

export const STATUS = [
  {
    value: "Not Started",
    label: "Not Started",
    styleProps: {
      checkedBackgroundColor: CASE_MANAGEMENT_COLORS.NONE.DARK.backgroundColor,
      checkedColor: CASE_MANAGEMENT_COLORS.NONE.DARK.color,
      checkedBorder: `1px solid ${CASE_MANAGEMENT_COLORS.NONE.DARK.color}`
    }
  },
  {
    value: "In Progress",
    label: "In Progress",
    styleProps: {
      checkedBackgroundColor:
        CASE_MANAGEMENT_COLORS.WARNING.DARK.backgroundColor,
      checkedColor: CASE_MANAGEMENT_COLORS.WARNING.DARK.color,
      checkedBorder: `1px solid ${CASE_MANAGEMENT_COLORS.WARNING.DARK.backgroundColor}`
    }
  },
  {
    value: "Completed",
    label: "Completed",
    styleProps: {
      checkedBackgroundColor:
        CASE_MANAGEMENT_COLORS.SUCCESS.DARK.backgroundColor,
      checkedColor: CASE_MANAGEMENT_COLORS.SUCCESS.DARK.color,
      checkedBorder: `1px solid ${CASE_MANAGEMENT_COLORS.SUCCESS.DARK.backgroundColor}`
    }
  }
];

export const PRIORITY = [
  {
    value: "Low",
    label: "Low",
    styleProps: {
      checkedBackgroundColor:
        CASE_MANAGEMENT_COLORS.INFORMATION.DARK.backgroundColor,
      checkedColor: CASE_MANAGEMENT_COLORS.INFORMATION.DARK.color,
      checkedBorder: `1px solid ${CASE_MANAGEMENT_COLORS.INFORMATION.DARK.backgroundColor}`
    }
  },
  {
    value: "Medium",
    label: "Medium",
    styleProps: {
      checkedBackgroundColor:
        CASE_MANAGEMENT_COLORS.WARNING.DARK.backgroundColor,
      checkedColor: CASE_MANAGEMENT_COLORS.WARNING.DARK.color,
      checkedBorder: `1px solid ${CASE_MANAGEMENT_COLORS.WARNING.DARK.backgroundColor}`
    }
  },
  {
    value: "High",
    label: "High",
    styleProps: {
      checkedBackgroundColor: CASE_MANAGEMENT_COLORS.ERROR.DARK.backgroundColor,
      checkedColor: CASE_MANAGEMENT_COLORS.ERROR.DARK.color,
      checkedBorder: `1px solid ${CASE_MANAGEMENT_COLORS.ERROR.DARK.backgroundColor}`
    }
  }
];

export const PRIVATE = [
  {
    value: "Yes",
    label: "Yes",
    styleProps: {
      checkedBackgroundColor:
        CASE_MANAGEMENT_COLORS.SUCCESS.DARK.backgroundColor,
      checkedColor: CASE_MANAGEMENT_COLORS.SUCCESS.DARK.color,
      checkedBorder: `1px solid ${CASE_MANAGEMENT_COLORS.SUCCESS.DARK.backgroundColor}`
    }
  },
  {
    value: "No",
    label: "No",
    styleProps: {
      checkedBackgroundColor: CASE_MANAGEMENT_COLORS.ERROR.DARK.backgroundColor,
      checkedColor: CASE_MANAGEMENT_COLORS.ERROR.DARK.color,
      checkedBorder: `1px solid ${CASE_MANAGEMENT_COLORS.ERROR.DARK.backgroundColor}`
    }
  }
];
