import React, { useContext } from "react";
import BusinessServiceContext from "../../../../../providers/Service/business";
import PhoneInput from "react-phone-input-2";
export const OwnerDetails = () => {
  const { service } = useContext(BusinessServiceContext);
  const { name, email, phone } = service;
  return (
    <div className="owner-details">
      <h4>Owner Details</h4>
      <span>{name}</span>
      <span>{email}</span>
      <span>
        <PhoneInput
          value={phone}
          disabled={true}
          inputClass={"readOnlyPhoneInput"}
          buttonClass={"readOnlyPhoneInputButton"}
        />
      </span>
    </div>
  );
};
