import React, { useState } from "react";
import { tabs, defaultContactTab } from "../tabs";
import { CustomButton } from "../../Layout/Shared/Button";
import "../../../styles/customComponents/genericTabs.scss";
import "./index.scss";
import { useBusinessClientListContext } from "../../../providers/Client/list";
import { CreateClientGroup } from "../Group/Create";

export const TabContainer = () => {
  const [selectedTab, setSelectedTab] = useState(defaultContactTab);
  const { setters, getters } = useBusinessClientListContext();
  const { setShowCreateGroup } = setters;
  const { showCreateGroup } = getters;
  return (
    <div className="pb-5">
      <ul className="generic-page-tab ">
        {tabs.map(tab => (
          <li
            key={tab.name}
            className={selectedTab.name === tab.name ? "active" : ""}
          >
            <button onClick={() => setSelectedTab(tab)}>{tab.name}</button>
          </li>
        ))}
      </ul>
      {selectedTab.name === defaultContactTab.name ? (
        <div className="contacts-button-wrapper pt-5"></div>
      ) : (
        <div className="contacts-button-wrapper pt-4">
          <CustomButton
            color="solid-blue"
            title="Create Group"
            size="160px"
            onClick={() => setShowCreateGroup(true)}
          />
        </div>
      )}
      <section>{selectedTab.component}</section>
      {showCreateGroup && (
        <CreateClientGroup onClose={() => setShowCreateGroup(false)} />
      )}
    </div>
  );
};
