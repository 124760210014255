import React, { useEffect, useRef } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import * as theme from '../../../../../../../styles/theme'
import { StyledSignatureImagePreview } from '../styles'

const DrawingSignature = ({ value, onChange, isReadOnly }) => {
  const signatureRef = useRef()

  useEffect(() => {
    if (!isReadOnly) {
      const hasValue = !!value
      if (hasValue) {
        const currentSignature = signatureRef.current.toDataURL()
        if (currentSignature !== value) {
          signatureRef.current.fromDataURL(value)
        }
      } else {
        signatureRef.current.clear()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  if (isReadOnly) {
    return (
      <StyledSignatureImagePreview className="e-signature-image" src={value} />
    )
  }

  return (
    <ReactSignatureCanvas
      penColor={theme.azul}
      ref={signatureRef}
      onEnd={() => onChange(signatureRef.current.toDataURL())}
      canvasProps={{ className: 'e-signature-canvas' }}
    />
  )
}

export default DrawingSignature
