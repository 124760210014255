import React from "react";
import { updateOptions } from "../../../utils";
import ComponentBox from "../Shared/Box";
import { getTextAlignClassName } from "react-rte/lib/lib/blockStyleFunctions";
import { StyledRichTextEditor } from "./styles";
import RichTextEditor from "react-rte";
import Memo from "../../../../Viewer/Components/Memo";

const MemoComponent = ({
  component,
  onOptionsChange,
  onOrderButtonClick,
  onDeleteClick,
  onDuplicateClick
}) => {
  if (typeof component.options.value === "string") {
    component.options.value = RichTextEditor.createValueFromString(
      component.options.value,
      "html"
    );
  }
  return (
    <ComponentBox
      title="Add Memo"
      column={component.options.column}
      previewMode={component.options.preview}
      onPreviewClick={() => {
        onOptionsChange(
          updateOptions(
            component.options,
            "preview",
            !component.options.preview
          )
        );
      }}
      onDeleteClick={onDeleteClick}
      onDuplicateClick={onDuplicateClick}
      onColumnChanged={column =>
        onOptionsChange(updateOptions(component.options, "column", column))
      }
      onOrderButtonClick={onOrderButtonClick}
    >
      {component.options.preview ? (
        <Memo
          component={{
            options: { value: component.options.value.toString("html") }
          }}
        />
      ) : (
        <StyledRichTextEditor
          value={component.options.value}
          blockStyleFn={getTextAlignClassName}
          onChange={value =>
            onOptionsChange(updateOptions(component.options, "value", value))
          }
        />
      )}
    </ComponentBox>
  );
};

export default MemoComponent;
