import React from "react";
import { tabs } from "../tabs";
import useBusinessCaseManagementCaseFormContext from "../../../../../providers/CaseManagement/Cases/form";
import "./index.scss";

const TabContainer = () => {
  const { getters, setters } = useBusinessCaseManagementCaseFormContext();
  const { formMode, selectedTab } = getters;
  const { setSelectedTab } = setters;
  const hasRight = tab =>
    tabs.find(({ name }) => name === tab.name).rights.includes(formMode);
  const Component = selectedTab.component;
  return (
    <div className="bg-white  pb-5">
      <ul className="generic-page-tab">
        {tabs.filter(hasRight).map(tab => (
          <li
            key={tab.name}
            className={tab.name === selectedTab.name ? "active" : null}
          >
            <button onClick={() => setSelectedTab(tab)}>{tab.name}</button>
          </li>
        ))}
      </ul>
      <section>
        <Component setSelectedTab={tab => setSelectedTab(tab)} />
      </section>
    </div>
  );
};

export default TabContainer;
