import React from "react";
import IRadarLogo from "../../assets/icons/iRadar-logo";

const handleRedirectToIRadar = () =>
  window.open("https://link2settle.com/", "_blank");

const LearnMore = () => (
  <div className="col-xl-6 col-lg-6 col-md-6 left order-2 order-md-1 order-lg-1 order-xl-1 d-flex justify-content-center align-items-center">
    <div className="box">
      <span className="brand">
        <IRadarLogo />
      </span>
      <article>
        <h2>
          Connect Immigrants to everything they need to settle easily and
          quickly
        </h2>
        <br />
        <h3>One Stop Shop for Immigration Services</h3>
      </article>
      <button
        className="btn btn-blue btn-small"
        onClick={handleRedirectToIRadar}
      >
        LEARN MORE
      </button>
      <p>iRadar © Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
    </div>
  </div>
);

export default LearnMore;
