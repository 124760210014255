import "bootstrap/dist/js/bootstrap.min.js";
import React from "react";
import {PageBreakLine} from "../Base/BaseStyles";

export function PageBreakHover({attributes}) {
  return (
    <div className={"row"}>
      <div className={"col-12"}>
        <PageBreakLine>
          <hr />
        </PageBreakLine>
      </div>
    </div>
  );
}

export default PageBreakHover;
