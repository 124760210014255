import styled, { css } from "styled-components";

export const defaultStyleProps = {
  backgroundColor: "#FFF",
  color: "#B8B8B8",
  border: "1px solid #B8B8B8",
  padding: "0px",
  fontSize: "14px",
  width: "92px",
  height: "32px",
  lineHeight: "32px",
  borderRadius: "19px",
  hoverOpacity: "0.8",
  checkedBackgroundColor: "#DDD",
  checkedColor: "#000",
  checkedBorder: "1px solid #000"
};

export const RadioButtonLabel = styled.label`
  ${({ styleProps, checked }) => {
    const backgroundColor = checked
      ? styleProps.checkedBackgroundColor ||
        defaultStyleProps.checkedBackgroundColor
      : styleProps.backgroundColor || defaultStyleProps.backgroundColor;
    const color = checked
      ? styleProps.checkedColor || defaultStyleProps.checkedColor
      : styleProps.color || defaultStyleProps.color;
    const border = checked
      ? styleProps.checkedBorder || defaultStyleProps.checkedBorder
      : styleProps.border || defaultStyleProps.border;
    return css`
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: ${backgroundColor};
      color: ${color};
      border: ${border};
      padding: ${styleProps.padding || defaultStyleProps.padding};
      font-size: ${styleProps.fontSize || defaultStyleProps.fontSize};
      width: ${styleProps.width || defaultStyleProps.width};
      height: ${styleProps.height || defaultStyleProps.height};
      line-height: ${styleProps.lineHeight || defaultStyleProps.lineHeight};
      border-radius: ${styleProps.borderRadius ||
      defaultStyleProps.borderRadius};
      cursor: pointer;
      margin-right: 8px;

      &:hover {
        opacity: ${styleProps.hoverOpacity || defaultStyleProps.hoverOpacity};
      }
    `;
  }}
`;

export const RadioButtonInput = styled.input`
  display: none;

  &:checked + ${RadioButtonLabel} {
    background-color: ${({ styleProps }) =>
      styleProps.checkedBackgroundColor ||
      defaultStyleProps.checkedBackgroundColor};
    color: ${({ styleProps }) =>
      styleProps.checkedColor || defaultStyleProps.checkedColor};
  }
`;

export const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
