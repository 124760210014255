import {Fragment} from "react";

import ComponentTabsContent from "../ComponentTabs/ComponentTabsContent";
import ComponentTabsHeader from "../ComponentTabs/ComponentTabsHeader";
import CheckboxEditTab from "./CheckboxEditTab";
import CheckboxOptionsTab from "./CheckboxOptionsTab";

export function CheckboxEdit({dispatch, attributes, component_key}) {
  return (
    <Fragment>
      <ComponentTabsHeader
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      />
      <ComponentTabsContent
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
        EditTab={CheckboxEditTab}
        OptionTab={CheckboxOptionsTab}
      />
    </Fragment>
  );
}

export default CheckboxEdit;
