import * as Yup from "yup";

export const StaffRegistrationInfo = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "First Name is too Short!")
    .max(30, "First Name is too Long!")
    .notOneOf([""], "First Name is required!")
    .required("First Name is required!"),
  lastName: Yup.string()
    .min(3, "Last Name is too Short!")
    .max(30, "Last Name is too Long!")
    .notOneOf([""], "Last Name is required!")
    .required("Last Name is required"),
  // designation: Yup.string()
  //   .notOneOf(["-1"], "Please select a designation!")
  //   .required("Designation is required"),
  phone: Yup.string().required("Phone is required"),
});
