import React from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./index.scss";
import useBusinessCaseManagementCaseFormContext from "../../../../../providers/CaseManagement/Cases/form";
import { CustomTextArea } from "../../../../Layout/Shared/TextArea";

const Notes = () => {
  const { setters, getters } = useBusinessCaseManagementCaseFormContext();
  const { formMode, note } = getters;
  const { setNote } = setters;
  return (
    <section className="p-5">
      <div>
        <span className="d-flex pl-3 mt-5 pb-2">Case Notes</span>
        <CustomTextArea
          disabled={formMode === "view"}
          height="458px"
          width="849px"
          value={note}
          onChange={({ target }) => {
            formMode !== "view" && setNote(target.value);
          }}
        />
      </div>
    </section>
  );
};

export default Notes;
