import styled from "styled-components";

export const StyledToggleSwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25.5px;
`;

export const StyledToggleSwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const StyledToggleSwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => (props.checked ? "#2196F3" : "#ccc")};
  transition: 0.4s;
  border-radius: 25.5px;

  &:before {
    position: absolute;
    content: "";
    height: 19.5px;
    width: 19.5px;
    left: ${props => (props.checked ? "21.5px" : "4px")};
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const StyledToggleSwitchRightLabel = styled.span`
  padding-left: 20px;
  vertical-align: top;
`;
