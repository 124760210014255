import "./index.scss";
import moment from "moment";
export const LastUpdate = ({date}) => (
  <span className="last-update">
    Last Updated
    <small>
      <span>{moment(date).format("LL")}</span>
      <span>{moment(date).format("hh:mm A")}</span>
    </small>
  </span>
);
