const CITIES = [
  {
    name: "Airdrie",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Athabasca",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Banff",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Barrhead",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Bassano",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Beaumont",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Beaverlodge",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Black Diamond",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Blackfalds",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Bon Accord",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Bonnyville",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Bow Island",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Brooks",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Calgary",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Calmar",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Camrose",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Canmore",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Cardston",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Carstairs",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Chestermere",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Claresholm",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Coaldale",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Coalhurst",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Cochrane",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Cold Lake",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Crossfield",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Devon",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Didsbury",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Drayton Valley",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Edmonton",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Edson",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Elk Point",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Fairview",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Falher",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Fort Macleod",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Fort McMurray",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Fort Saskatchewan",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Fox Creek",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Gibbons",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Grand Centre",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Grande Cache",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Grande Prairie",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Grimshaw",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Hanna",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Heritage Pointe",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "High Level",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "High Prairie",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "High River",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Hinton",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Irricana",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Jasper Park Lodge",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Killam",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Lac La Biche",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Lacombe",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Lamont",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Larkspur",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Laurel",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Leduc",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Lethbridge",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Lloydminster",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Magrath",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Manning",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Mannville",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Maple Ridge",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Mayerthorpe",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Medicine Hat",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Mill Woods Town Centre",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Millet",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Morinville",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Nanton",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Okotoks",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Olds",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Peace River",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Penhold",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Picture Butte",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Pincher Creek",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Ponoka",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Provost",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Raymond",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Red Deer",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Rideau Park",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Rimbey",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Rocky Mountain House",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Sexsmith",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Sherwood Park",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Silver Berry",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Slave Lake",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Smoky Lake",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Spirit River",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Springbrook",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Spruce Grove",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "St. Albert",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Stettler",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Stony Plain",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Strathmore",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Sundre",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Swan Hills",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Sylvan Lake",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Taber",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Tamarack",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Three Hills",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Tofield",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Two Hills",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Valleyview",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Vegreville",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Vermilion",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Viking",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Vulcan",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Wainwright",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Wembley",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Westlake",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Westlock",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Wetaskiwin",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Whitecourt",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Wild Rose",
    country_code: "CA",
    state_code: "AB",
  },
  {
    name: "Abbotsford",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Agassiz",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Aldergrove",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Aldergrove East",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Anmore",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Arbutus Ridge",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Armstrong",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Ashcroft",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Barrière",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Bowen Island",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Burnaby",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Burns Lake",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Cache Creek",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Campbell River",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Castlegar",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Cedar",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Central Coast Regional District",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Chase",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Chemainus",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Chetwynd",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Chilliwack",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Colwood",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Coombs",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Coquitlam",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Courtenay",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Cowichan Bay",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Cranbrook",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Creston",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Cumberland",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Dawson Creek",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Delta",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Denman Island",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Denman Island Trust Area",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Duck Lake",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Duncan",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "East Wellington",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Elkford",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Ellison",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Enderby",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Fairwinds",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Fernie",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Fort Nelson",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Fort St. John",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Fraser Valley Regional District",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "French Creek",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Fruitvale",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Gibsons",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Golden",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Grand Forks",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Hanceville",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Hope",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Hornby Island",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Houston",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Invermere",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Kamloops",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Kelowna",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Kimberley",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Kitimat",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Ladner",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Ladysmith",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Lake Cowichan",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Langford",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Langley",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Lillooet",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Lions Bay",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Logan Lake",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Lumby",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Mackenzie",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Maple Ridge",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Merritt",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Metchosin",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Metro Vancouver Regional District",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Mission",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Nakusp",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Nanaimo",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Nelson",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "New Westminster",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "North Cowichan",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "North Oyster/Yellow Point",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "North Saanich",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "North Vancouver",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Oak Bay",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Okanagan",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Okanagan Falls",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Oliver",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Osoyoos",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Parksville",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Peace River Regional District",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Peachland",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Pemberton",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Penticton",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Pitt Meadows",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Port Alberni",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Port Coquitlam",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Port McNeill",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Port Moody",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Powell River",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Prince George",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Prince Rupert",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Princeton",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Puntledge",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Quesnel",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Regional District of Alberni-Clayoquot",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Regional District of Central Okanagan",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Revelstoke",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Richmond",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Rossland",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Royston",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Salmo",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Salmon Arm",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Salt Spring Island",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Saltair",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Sechelt",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Sicamous",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Six Mile",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Smithers",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Sooke",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "South Pender Harbour",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Sparwood",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Summerland",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Surrey",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Terrace",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Tofino",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Trail",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Tsawwassen",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Tumbler Ridge",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Ucluelet",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Vancouver",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Vanderhoof",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Vernon",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Victoria",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Walnut Grove",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Welcome Beach",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "West End",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "West Kelowna",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "West Vancouver",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Whistler",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "White Rock",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Williams Lake",
    country_code: "CA",
    state_code: "BC",
  },
  {
    name: "Altona",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Beausejour",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Boissevain",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Brandon",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Carberry",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Carman",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Cross Lake 19A",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Dauphin",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "De Salaberry",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Deloraine",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Flin Flon",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Gimli",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Grunthal",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Headingley",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Ile des Chênes",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Killarney",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "La Broquerie",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Lac du Bonnet",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Landmark",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Lorette",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Melita",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Minnedosa",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Moose Lake",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Morden",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Morris",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Neepawa",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Niverville",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Portage la Prairie",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Rivers",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Roblin",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Selkirk",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Shilo",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Souris",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "St. Adolphe",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Steinbach",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Stonewall",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Swan River",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "The Pas",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Thompson",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Virden",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "West St. Paul",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Winkler",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Winnipeg",
    country_code: "CA",
    state_code: "MB",
  },
  {
    name: "Baie Ste. Anne",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Bathurst",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Bouctouche",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Campbellton",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Dieppe",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Edmundston",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Florenceville-Bristol",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Fredericton",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Fundy Bay",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Grande-Digue",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Greater Lakeburn",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Hampton",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Harrison Brook",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Keswick Ridge",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Lincoln",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Lutes Mountain",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "McEwen",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Miramichi",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Moncton",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Nackawic",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "New Maryland",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Noonan",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Oromocto",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Richibucto",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Sackville",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Saint Andrews",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Saint John",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Saint-Antoine",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Saint-Léonard",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Salisbury",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Shediac",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Shediac Bridge-Shediac River",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Shippagan",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Starlight Village",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Sussex",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Tracadie-Sheila",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Wells",
    country_code: "CA",
    state_code: "NB",
  },
  {
    name: "Bay Roberts",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Bay St. George South",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Bonavista",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Botwood",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Burgeo",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Carbonear",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Catalina",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Channel-Port aux Basques",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Clarenville-Shoal Harbour",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Conception Bay South",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Corner Brook",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Deer Lake",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Fogo Island",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Gambo",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Goulds",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Grand Bank",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Grand Falls-Windsor",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Happy Valley-Goose Bay",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Harbour Breton",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Labrador City",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Lewisporte",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Marystown",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Mount Pearl",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Pasadena",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Springdale",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "St. Anthony",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "St. John's",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Stephenville",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Stephenville Crossing",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Torbay",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Upper Island Cove",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Wabana",
    country_code: "CA",
    state_code: "NL",
  },
  {
    name: "Behchokǫ̀",
    country_code: "CA",
    state_code: "NT",
  },
  {
    name: "Fort McPherson",
    country_code: "CA",
    state_code: "NT",
  },
  {
    name: "Fort Smith",
    country_code: "CA",
    state_code: "NT",
  },
  {
    name: "Hay River",
    country_code: "CA",
    state_code: "NT",
  },
  {
    name: "Inuvik",
    country_code: "CA",
    state_code: "NT",
  },
  {
    name: "Norman Wells",
    country_code: "CA",
    state_code: "NT",
  },
  {
    name: "Yellowknife",
    country_code: "CA",
    state_code: "NT",
  },
  {
    name: "Amherst",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Annapolis County",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Antigonish",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Berwick",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Bridgewater",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Cape Breton County",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Chester",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Colchester",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Cole Harbour",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Cow Bay",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Dartmouth",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Digby",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Digby County",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "English Corner",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Eskasoni 3",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Fall River",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Glace Bay",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Greenwood",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Halifax",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Hantsport",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Hayes Subdivision",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Kentville",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Lake Echo",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Lantz",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Lower Sackville",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Lunenburg",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Middleton",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "New Glasgow",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Oxford",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Parrsboro",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Pictou",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Pictou County",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Port Hawkesbury",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Port Williams",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Princeville",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Shelburne",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Springhill",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Sydney",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Sydney Mines",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Truro",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Windsor",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Wolfville",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Yarmouth",
    country_code: "CA",
    state_code: "NS",
  },
  {
    name: "Clyde River",
    country_code: "CA",
    state_code: "NU",
  },
  {
    name: "Gjoa Haven",
    country_code: "CA",
    state_code: "NU",
  },
  {
    name: "Iqaluit",
    country_code: "CA",
    state_code: "NU",
  },
  {
    name: "Kugluktuk",
    country_code: "CA",
    state_code: "NU",
  },
  {
    name: "Pangnirtung",
    country_code: "CA",
    state_code: "NU",
  },
  {
    name: "Rankin Inlet",
    country_code: "CA",
    state_code: "NU",
  },
  {
    name: "Ajax",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Algoma",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Alliston",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Amherstburg",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Amigo Beach",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Ancaster",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Angus",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Arnprior",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Atikokan",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Attawapiskat",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Aurora",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Aylmer",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Azilda",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Ballantrae",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Bancroft",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Barrie",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Bath",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Belleville",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Bells Corners",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Belmont",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Binbrook",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Bluewater",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Bourget",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Bracebridge",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Brampton",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Brant",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Brantford",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Brockville",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Brussels",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Burford",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Burlington",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Cambridge",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Camlachie",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Capreol",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Carleton Place",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Casselman",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Chatham",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Chatham-Kent",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Clarence-Rockland",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Cobourg",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Cochrane District",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Collingwood",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Concord",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Constance Bay",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Cookstown",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Cornwall",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Corunna",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Deep River",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Delaware",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Deseronto",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Dorchester",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Dowling",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Dryden",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Durham",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Ear Falls",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "East Gwillimbury",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "East York",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Elliot Lake",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Elmvale",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Englehart",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Espanola",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Essex",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Etobicoke",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Fort Erie",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Fort Frances",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Gananoque",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Glencoe",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Goderich",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Golden",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Gravenhurst",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Greater Napanee",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Greater Sudbury",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Greenstone",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Guelph",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Haldimand County",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Haliburton Village",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Halton",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Hamilton",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Hanover",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Harriston",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Hawkesbury",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Hearst",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Hornepayne",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Huntsville",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Huron East",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Ingersoll",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Innisfil",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Iroquois Falls",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Jarvis",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Kanata",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Kapuskasing",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Kawartha Lakes",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Kenora",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Keswick",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Kincardine",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "King",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Kingston",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Kirkland Lake",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Kitchener",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "L'Orignal",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Lakefield",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Lambton Shores",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Lappe",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Leamington",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Limoges",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Lindsay",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Listowel",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Little Current",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Lively",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "London",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Lucan",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Madoc",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Manitoulin District",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Manitouwadge",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Marathon",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Markdale",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Markham",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Mattawa",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Meaford",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Metcalfe",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Midland",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Mildmay",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Millbrook",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Milton",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Mississauga",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Mississauga Beach",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Moose Factory",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Moosonee",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Morrisburg",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Mount Albert",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Mount Brydges",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Napanee",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Napanee Downtown",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Neebing",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Nepean",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "New Hamburg",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Newmarket",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Niagara Falls",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Nipissing District",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Norfolk County",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "North Bay",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "North Perth",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "North York",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Norwood",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Oakville",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Omemee",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Orangeville",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Orillia",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Osgoode",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Oshawa",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Ottawa",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Owen Sound",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Paisley",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Paris",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Parkhill",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Parry Sound",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Parry Sound District",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Peel",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Pembroke",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Perth",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Petawawa",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Peterborough",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Petrolia",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Pickering",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Picton",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Plantagenet",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Plattsville",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Port Colborne",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Port Hope",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Port Rowan",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Port Stanley",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Powassan",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Prescott",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Prince Edward",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Queenswood Heights",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Quinte West",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Rainy River District",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Rayside-Balfour",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Red Lake",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Regional Municipality of Waterloo",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Renfrew",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Richmond",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Richmond Hill",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Ridgetown",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Rockwood",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Russell",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Sarnia",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Sault Ste. Marie",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Scarborough",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Seaforth",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Shelburne",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Simcoe",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Sioux Lookout",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Skatepark",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Smiths Falls",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "South Huron",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "South River",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "St. Catharines",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "St. George",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "St. Thomas",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Stirling",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Stoney Point",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Stratford",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Sudbury",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Tavistock",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Temiskaming Shores",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Thessalon",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Thorold",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Thunder Bay",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Thunder Bay District",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Timiskaming District",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Timmins",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Tobermory",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Toronto",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Toronto county",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Tottenham",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Tweed",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Uxbridge",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Valley East",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Vanier",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Vaughan",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Vineland",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Virgil",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Walpole Island",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Wasaga Beach",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Waterford",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Waterloo",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Watford",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Wawa",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Welland",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Wellesley",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Wendover",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "West Lorne",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Willowdale",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Winchester",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Windsor",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Wingham",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Woodstock",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "York",
    country_code: "CA",
    state_code: "ON",
  },
  {
    name: "Alberton",
    country_code: "CA",
    state_code: "PE",
  },
  {
    name: "Belfast",
    country_code: "CA",
    state_code: "PE",
  },
  {
    name: "Charlottetown",
    country_code: "CA",
    state_code: "PE",
  },
  {
    name: "Cornwall",
    country_code: "CA",
    state_code: "PE",
  },
  {
    name: "Fallingbrook",
    country_code: "CA",
    state_code: "PE",
  },
  {
    name: "Kensington",
    country_code: "CA",
    state_code: "PE",
  },
  {
    name: "Montague",
    country_code: "CA",
    state_code: "PE",
  },
  {
    name: "Souris",
    country_code: "CA",
    state_code: "PE",
  },
  {
    name: "Summerside",
    country_code: "CA",
    state_code: "PE",
  },
  {
    name: "Abitibi-Témiscamingue",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Acton Vale",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Adstock",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Albanel",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Alma",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Amos",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Amqui",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Ange-Gardien",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Asbestos",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Baie-Comeau",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Baie-D'Urfé",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Baie-Saint-Paul",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Barraute",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Bas-Saint-Laurent",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Beaconsfield",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Beauceville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Beauharnois",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Beaupré",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Bécancour",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Bedford",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Beloeil",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Berthierville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Blainville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Bois-des-Filion",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Boisbriand",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Bonaventure",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Boucherville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Breakeyville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Bromont",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Brossard",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Brownsburg-Chatham",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Buckingham",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Cabano",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Cacouna",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Candiac",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Cantley",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Cap-Chat",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Cap-Santé",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Capitale-Nationale",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Carignan",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Carleton",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Carleton-sur-Mer",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Centre-du-Québec",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Chambly",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Chambord",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Chandler",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Chapais",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Charlemagne",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Château-Richer",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Châteauguay",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Chaudière-Appalaches",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Chertsey",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Chibougamau",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Chute-aux-Outardes",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Coaticook",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Contrecoeur",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Cookshire",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Cookshire-Eaton",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Côte-Nord",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Côte-Saint-Luc",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Coteau-du-Lac",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Cowansville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Crabtree",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Danville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Daveluyville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Delson",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Deux-Montagnes",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Disraeli",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Dolbeau-Mistassini",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Dollard-Des Ormeaux",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Donnacona",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Dorval",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Drummondville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Dunham",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "East Angus",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "East Broughton",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Farnham",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Ferme-Neuve",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Fermont",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Forestville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Fort-Coulonge",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Fossambault-sur-le-Lac",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Franklin",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Gaspé",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Gaspésie-Îles-de-la-Madeleine",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Gatineau",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Godefroy",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Granby",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Hampstead",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Hauterive",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Havre-Saint-Pierre",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Hérouxville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Hudson",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Huntingdon",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Joliette",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Jonquière",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Kingsey Falls",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Kirkland",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "L'Ancienne-Lorette",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "L'Ange-Gardien",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "L'Ascension-de-Notre-Seigneur",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "L'Assomption",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "L'Épiphanie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "L'Île-Perrot",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "La Conception",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "La Haute-Saint-Charles",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "La Malbaie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "La Minerve",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "La Pocatière",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "La Prairie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "La Sarre",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "La Tuque",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Labelle",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lac-Alouette",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lac-Brome",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lac-Connelly",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lac-Lapierre",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lac-Mégantic",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lac-Simon",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lachute",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lacolle",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lanoraie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Laval",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lavaltrie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Le Bic",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "le Plateau",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lebel-sur-Quévillon",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Leblanc",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Les Cèdres",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Les Coteaux",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Les Escoumins",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lévis",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Linière",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Longueuil",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Lorraine",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Louiseville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Luceville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Macamic",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Magog",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Malartic",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Maliotenam",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Manawan",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mandeville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Maniwaki",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Maria",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Marieville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mascouche",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Maskinongé",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Matagami",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Matane",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mauricie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Melocheville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mercier",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Métabetchouan",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Metabetchouan-Lac-a-la-Croix",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mirabel",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mistissini",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mont-Joli",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mont-Laurier",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mont-Royal",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mont-Saint-Grégoire",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mont-Saint-Hilaire",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Mont-Tremblant",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Montmagny",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Montréal",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Montréal-Est",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Montréal-Ouest",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Morin-Heights",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Napierville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Neuville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "New Carlisle",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "New-Richmond",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Nicolet",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Nord-du-Québec",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Normandin",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Notre-Dame-de-Grâce",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Notre-Dame-de-l'Île-Perrot",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Notre-Dame-des-Prairies",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Notre-Dame-du-Lac",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Notre-Dame-du-Mont-Carmel",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Oka",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Ormstown",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Otterburn Park",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Outaouais",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Papineauville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Parc-Boutin",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Piedmont",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Pierreville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Pincourt",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Plessisville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Pohénégamook",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Pointe-Calumet",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Pointe-Claire",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Pointe-du-Lac",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Pont Rouge",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Pont-Rouge",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Port-Cartier",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Portneuf",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Prévost",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Princeville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Québec",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Rawdon",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Repentigny",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Richelieu",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Richmond",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Rigaud",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Rimouski",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Rivière-du-Loup",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Rivière-Rouge",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Roberval",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Rock Forest",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Rosemère",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Rougemont",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Rouyn-Noranda",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sacré-Coeur",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saguenay",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Adolphe-d'Howard",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Alexandre",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Amable",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Ambroise",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-André-Avellin",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Anselme",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Antoine-de-Tilly",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Augustin",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Augustin-de-Desmaures",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Barnabé-Sud",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Basile-le-Grand",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Boniface",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Bruno",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Bruno-de-Guigues",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Bruno-de-Montarville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Canut",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Césaire",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Charles",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Côme--Linière",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Constant",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Cyrille-de-Wendover",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Damase",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Denis-sur-Richelieu",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Donat-de-Montcalm",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Édouard",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Elzéar",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Éphrem-de-Beauce",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Eustache",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Félicien",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Félix-de-Valois",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Gabriel",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Gédéon",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Georges",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Germain-de-Grantham",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Henri",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Hippolyte",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Honoré",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Hyacinthe",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Isidore",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Jacques-le-Mineur",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Jean-Baptiste",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Jean-sur-Richelieu",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Jérôme",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Joseph",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Joseph-de-Beauce",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Joseph-de-Coleraine",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Joseph-du-Lac",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Lambert-de-Lauzon",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Laurent",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Lazare",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Léonard",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Léonard-d'Aston",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Liboire",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Lin-Laurentides",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Marc-des-Carrières",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Mathieu",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Michel",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Michel-des-Saints",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Nazaire",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Norbert",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Pacôme",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Pascal",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Philippe-de-La Prairie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Pie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Pierre-les-Becquets",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Prime",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Raphaël",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Raymond",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Rémi",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Rémi-de-Tingwick",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Sauveur",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Sauveur-des-Monts",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Siméon",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Thomas",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Tite",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Victor",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Saint-Zotique",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte Catherine de la Jacques Cartier",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Adèle",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Agathe-des-Monts",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Anne-de-Bellevue",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Anne-des-Monts",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Anne-des-Plaines",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Béatrix",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Catherine",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Croix",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Élisabeth",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Julie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Julienne",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Madeleine",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Marie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Marthe-sur-le-Lac",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Martine",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Sophie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Thècle",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sainte-Thérèse",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Salaberry-de-Valleyfield",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Salluit",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Senneterre",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sept-Îles",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Shannon",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Shawinigan",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Shawville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sherbrooke",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sorel-Tracy",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "St-Jean-Port-Joli",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Sutton",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Témiscaming",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Terrasse-des-Pins",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Terrebonne",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Thetford-Mines",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Thurso",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Trois-Rivières",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Val-d'Or",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Val-David",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Val-des-Monts",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Val-Morin",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Valcourt",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Vallée-Jonction",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Varennes",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Vaudreuil-Dorion",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Venise-en-Québec",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Verchères",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Victoriaville",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Ville-Marie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Wakefield",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Warwick",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Waskaganish",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Waswanipi",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Waterloo",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Weedon Centre",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Westmount",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Weymontachie",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Windsor",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Yamachiche",
    country_code: "CA",
    state_code: "QC",
  },
  {
    name: "Assiniboia",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Biggar",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Canora",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Carlyle",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Dalmeny",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Esterhazy",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Estevan",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Foam Lake",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Gravelbourg",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Hudson Bay",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Humboldt",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Indian Head",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Kamsack",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Kerrobert",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Kindersley",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "La Ronge",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Langenburg",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Langham",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Lanigan",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Lumsden",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Macklin",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Maple Creek",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Martensville",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Meadow Lake",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Melfort",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Melville",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Moose Jaw",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Moosomin",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Nipawin",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "North Battleford",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Outlook",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Oxbow",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Pelican Narrows",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Pilot Butte",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Preeceville",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Prince Albert",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Regina",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Regina Beach",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Rosetown",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Rosthern",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Saskatoon",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Shaunavon",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Shellbrook",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Swift Current",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Tisdale",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Unity",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Wadena",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Warman",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Watrous",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Weyburn",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "White City",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Wilkie",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Wynyard",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Yorkton",
    country_code: "CA",
    state_code: "SK",
  },
  {
    name: "Dawson City",
    country_code: "CA",
    state_code: "YT",
  },
  {
    name: "Haines Junction",
    country_code: "CA",
    state_code: "YT",
  },
  {
    name: "Watson Lake",
    country_code: "CA",
    state_code: "YT",
  },
  {
    name: "Whitehorse",
    country_code: "CA",
    state_code: "YT",
  },
  {
    name: "Abbeville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Adamsville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Alabaster",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Albertville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Alexander City",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Alexandria",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Aliceville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Andalusia",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Anniston",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Arab",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Argo",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Ashford",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Ashville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Atmore",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Attalla",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Autauga County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Baldwin County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Ballplay",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Barbour County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Bay Minette",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Bayou La Batre",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Bear Creek",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Berry",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Bessemer",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Bibb County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Birmingham",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Blount County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Blountsville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Blue Ridge",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Boaz",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Brent",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Brewton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Brighton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Brook Highland",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Brookside",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Brookwood",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Brundidge",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Bullock County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Butler",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Butler County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Bynum",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cahaba Heights",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Calera",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Carbon Hill",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Carlisle-Rockledge",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cedar Bluff",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Center Point",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Centre",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Centreville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Chalkville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Chambers County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Chatom",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Chelsea",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cherokee",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cherokee County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Chickasaw",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Childersburg",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Chilton County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Choccolocco",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Choctaw County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Citronelle",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Clanton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Clarke County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Clay",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cleburne County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cleveland",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Clio",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Coaling",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Coffee County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Colbert County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Collinsville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Columbiana",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Conecuh County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Coosa County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Coosada",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cordova",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cottonwood",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Covington County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cowarts",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Crenshaw County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Creola",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Crossville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cullman",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Cullman County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Dadeville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Dale County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Daleville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Dallas County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Daphne",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Dauphin Island",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Deatsville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "DeKalb County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Demopolis",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Dixiana",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Dora",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Dothan",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Double Springs",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "East Brewton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "East Florence",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Eclectic",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Elba",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Elberta",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Elmore",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Elmore County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Emerald Mountain",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Enterprise",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Escambia County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Etowah County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Eufaula",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Eutaw",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Evergreen",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fairhope",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Falkville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fayette",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fayetteville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Flint City",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Flomaton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Florala",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Foley",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Forestdale",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fort Deposit",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fort Payne",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fort Rucker",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Frisco City",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fultondale",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Fyffe",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Gadsden",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Gardendale",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Geneva",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Geneva County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Georgiana",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Glencoe",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Good Hope",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Goodwater",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Gordo",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Grand Bay",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Grayson Valley",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Graysville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Greensboro",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Grove Hill",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Guin",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Gulf Shores",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Guntersville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hackleburg",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hale County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Haleyville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hamilton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hanceville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Harpersville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hartselle",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Harvest",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hayden",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hayneville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hazel Green",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Headland",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Heflin",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Helena",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Henagar",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Highland Lakes",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hokes Bluff",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Holt",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Holtville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Homewood",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hoover",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Houston County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Hueytown",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Huguley",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Huntsville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Indian Springs Village",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Inverness",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Irondale",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Jacksonville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Jasper",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Jemison",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Kimberly",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Kinsey",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Ladonia",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lafayette",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lake Purdy",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lake View",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lamar County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lanett",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lauderdale County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Leeds",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Leesburg",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Level Plains",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Limestone County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Linden",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lineville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lipscomb",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Livingston",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Locust Fork",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Lowndes County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Loxley",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Luverne",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Macon County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Malvern",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Marbury",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Marengo County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Margaret",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Meadowbrook",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Meridianville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Midfield",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Midland City",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Mignon",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Millbrook",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Minor",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Mobile",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Mobile County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Monroeville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Montevallo",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Montgomery",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Moody",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Moores Mill",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Morris",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Moulton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Moundville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Mount Olive",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Mountain Brook",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Munford",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Muscle Shoals",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "New Brockton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "New Hope",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "New Market",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "North Bibb",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Northport",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Odenville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Ohatchee",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Oneonta",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Opelika",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Opp",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Orange Beach",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Owens Cross Roads",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Ozark",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Pelham",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Pell City",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Phenix City",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Phil Campbell",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Pickens County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Piedmont",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Pike Road",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Pine Level",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Pinson",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Pleasant Grove",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Point Clear",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Prattville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Priceville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Prichard",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Ragland",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Rainbow City",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Rainsville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Randolph County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Red Bay",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Redstone Arsenal",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Reform",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Rehobeth",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Roanoke",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Robertsdale",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Rock Creek",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Rockford",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Rogersville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Russell County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Russellville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Saint Clair County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Saks",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Samson",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Saraland",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Sardis City",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Satsuma",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Scottsboro",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Selma",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Selmont-West Selmont",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Semmes",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Sheffield",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Shelby",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Shelby County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Shoal Creek",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Slocomb",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Smiths Station",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Smoke Rise",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Southside",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Spanish Fort",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Springville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Steele",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Stevenson",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Stewartville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Sulligent",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Sumiton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Summerdale",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Sumter County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Sylacauga",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Sylvan Springs",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Sylvania",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Talladega",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Talladega County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Tallapoosa County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Tallassee",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Tarrant",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Taylor",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Theodore",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Thomasville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Thorsby",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Tillmans Corner",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Town Creek",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Trinity",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Trussville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Tuscaloosa",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Tuscaloosa County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Tuscumbia",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Tuskegee",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Underwood-Petersville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Union Springs",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Uniontown",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Valley",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Valley Grande",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Vance",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Vandiver",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Vernon",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Vestavia Hills",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Vincent",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Walker County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Warrior",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Weaver",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Webb",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Wedowee",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "West Blocton",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "West End-Cobb Town",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Westover",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Wetumpka",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Whitesboro",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Wilcox County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Wilsonville",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Winfield",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Winston County",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Woodstock",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "York",
    country_code: "US",
    state_code: "AL",
  },
  {
    name: "Akutan",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Aleutians East Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Aleutians West Census Area",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Anchor Point",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Anchorage",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Anchorage Municipality",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Badger",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Barrow",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Bear Creek",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Bethel",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Bethel Census Area",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Big Lake",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Bristol Bay Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Butte",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Chevak",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "City and Borough of Wrangell",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Cohoe",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "College",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Cordova",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Craig",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Deltana",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Denali Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Diamond Ridge",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Dillingham",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Dillingham Census Area",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Dutch Harbor",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Eagle River",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Eielson Air Force Base",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Elmendorf Air Force Base",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Ester",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Fairbanks",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Fairbanks North Star Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Farm Loop",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Farmers Loop",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Fishhook",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Fritz Creek",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Gateway",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Girdwood",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Haines",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Haines Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Healy",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Homer",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Hoonah-Angoon Census Area",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Hooper Bay",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Houston",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Juneau",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Kalifornsky",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Kenai",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Kenai Peninsula Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Ketchikan",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Ketchikan Gateway Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "King Cove",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Knik-Fairview",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Kodiak",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Kodiak Island Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Kodiak Station",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Kotzebue",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Lake and Peninsula Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Lakes",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Lazy Mountain",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Matanuska-Susitna Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Meadow Lakes",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Metlakatla",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Nikiski",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Nome",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Nome Census Area",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "North Pole",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "North Slope Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Northwest Arctic Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Palmer",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Petersburg",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Petersburg Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Prudhoe Bay",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Ridgeway",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Salcha",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Sand Point",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Seward",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Sitka",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Sitka City and Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Skagway Municipality",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Soldotna",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Southeast Fairbanks Census Area",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Sterling",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Sutton-Alpine",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Tanaina",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Tok",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Unalaska",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Valdez",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Valdez-Cordova Census Area",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Wasilla",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Willow",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Wrangell",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Y",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Yakutat City and Borough",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Yukon-Koyukuk Census Area",
    country_code: "US",
    state_code: "AK",
  },
  {
    name: "Ahwatukee Foothills",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Ajo",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Alhambra",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Anthem",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Apache County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Apache Junction",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Arivaca Junction",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Arizona City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Avenue B and C",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Avondale",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Avra Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Bagdad",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Beaver Dam",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Benson",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Big Park",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Bisbee",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Black Canyon City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Blackwater",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Buckeye",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Bullhead City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Bylas",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Cactus Flat",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Camp Verde",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Canyon Day",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Carefree",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Casa Blanca",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Casa Grande",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Casas Adobes",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Catalina",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Catalina Foothills",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Cave Creek",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Centennial Park",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Central City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Central Heights-Midland City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Chandler",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Chinle",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Chino Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Cibecue",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Cienega Springs",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Citrus Park",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Clarkdale",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Claypool",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Clifton",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Cochise County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Coconino County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Colorado City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Congress",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Coolidge",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Cordes Lakes",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Cornville",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Corona de Tucson",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Cottonwood",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Deer Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Desert Hills",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Dewey-Humboldt",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Dilkon",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Dolan Springs",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Donovan Estates",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Douglas",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Drexel Heights",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Eagar",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "East Sahuarita",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Ehrenberg",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "El Mirage",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Eloy",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Encanto",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "First Mesa",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Flagstaff",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Flowing Wells",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Fort Defiance",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Fortuna Foothills",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Fountain Hills",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Fredonia",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Ganado",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Gila Bend",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Gila County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Gilbert",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Glendale",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Globe",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Gold Camp",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Gold Canyon",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Golden Shores",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Golden Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Goodyear",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Graham County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Grand Canyon",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Grand Canyon Village",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Green Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Greenlee County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Guadalupe",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Heber-Overgaard",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Holbrook",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Houck",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Huachuca City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Joseph City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Kachina Village",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Kaibito",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Kayenta",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Kearny",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Kingman",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "La Paz County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Lake Havasu City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Lake Montezuma",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Lake of the Woods",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Laveen",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "LeChee",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Linden",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Litchfield Park",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Lukachukai",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Mammoth",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Many Farms",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Marana",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Maricopa",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Maricopa County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Maryvale",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Mayer",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Meadview",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Mesa",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Mescal",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Miami",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Mohave County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Mohave Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Morenci",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Mountainaire",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Naco",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Navajo County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "New Kingman-Butler",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "New River",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Nogales",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "North Fork",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Oracle",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Oro Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Page",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Paradise Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Parker",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Parks",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Paulden",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Payson",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Peach Springs",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Peoria",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Peridot",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Phoenix",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Picture Rocks",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Pima",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Pima County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Pinal County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Pine",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Pinetop-Lakeside",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Pirtleville",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Prescott",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Prescott Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Quartzsite",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Queen Creek",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Rio Rico",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Rio Verde",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sacaton",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Saddle Brooke",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Safford",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sahuarita",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Saint David",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Saint Johns",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Saint Michaels",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Salome",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "San Carlos",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "San Luis",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "San Manuel",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "San Tan Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Santa Cruz County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Scenic",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Scottsdale",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sedona",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sells",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Show Low",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sierra Vista",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sierra Vista Southeast",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Six Shooter Canyon",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Snowflake",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Somerton",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "South Tucson",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Spring Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Springerville",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Star Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Summit",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sun City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sun City West",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sun Lakes",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Sun Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Superior",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Surprise",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Swift Trail Junction",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tanque Verde",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Taylor",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tempe",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tempe Junction",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Thatcher",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Three Points",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tolleson",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tombstone",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tonto Basin",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tortolita",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tsaile",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tuba City",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tubac",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tucson",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Tucson Estates",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Vail",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Valencia West",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Valle Vista",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Verde Village",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Village of Oak Creek (Big Park)",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Wellton",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "West Sedona",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Whetstone",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "White Mountain Lake",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Whiteriver",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Wickenburg",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Willcox",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Williams",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Williamson",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Willow Valley",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Window Rock",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Winslow",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Yavapai County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Youngtown",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Yuma",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Yuma County",
    country_code: "US",
    state_code: "AZ",
  },
  {
    name: "Alexander",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Alma",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Arkadelphia",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Arkansas City",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Arkansas County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Ash Flat",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Ashdown",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Ashley County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Atkins",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Augusta",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Austin",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Bald Knob",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Barling",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Batesville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Baxter County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Bay",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Beebe",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Bella Vista",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Benton",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Benton County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Bentonville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Berryville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Bethel Heights",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Blytheville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Bono",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Boone County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Booneville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Bradley County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Brinkley",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Brookland",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Bryant",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Bull Shoals",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Cabot",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Calico Rock",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Caraway",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Carlisle",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Cave City",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Cave Springs",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Cedarville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Centerton",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Charleston",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Cherokee Village",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Chicot County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Clarendon",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Clarksville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Cleburne County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Cleveland County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Coal Hill",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Columbia County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Conway",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Conway County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Corning",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Craighead County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Crittenden County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Cross County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Crossett",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Dallas County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Dardanelle",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "De Queen",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "De Witt",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Dermott",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Des Arc",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Desha County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Diaz",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Dierks",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Drew County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Dumas",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Earle",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "East End",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "El Dorado",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Elkins",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Elm Springs",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "England",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Eudora",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Eureka Springs",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Fairfield Bay",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Faulkner County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Fayetteville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Flippin",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Fordyce",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Forrest City",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Fort Smith",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Fulton County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Garland County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Gassville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Gentry",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Gibson",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Glenwood",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Goshen",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Gosnell",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Gravel Ridge",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Gravette",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Green Forest",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Greenbrier",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Greenland",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Greenwood",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Gurdon",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hamburg",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hampton",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Harrisburg",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Harrison",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Haskell",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hazen",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Heber Springs",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Helena",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Helena-West Helena",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hempstead County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Highland",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Holiday Island",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hope",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Horatio",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Horseshoe Bend",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hot Spring County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hot Springs",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hot Springs National Park",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hot Springs Village",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Howard County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hoxie",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Hughes",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Huntsville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Independence County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Izard County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Jacksonville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Jasper",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Johnson",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Jonesboro",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Judsonia",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Kensett",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lafayette County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lake City",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lake Hamilton",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lake Village",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lamar",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Landmark",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lavaca",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Leachville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lepanto",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lewisville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Little Flock",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Little River County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Little Rock",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Little Rock Air Force Base",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "London",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lonoke",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lonoke County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Lowell",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Luxora",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Magnolia",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Malvern",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Manila",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Mansfield",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Marianna",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Marked Tree",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Marmaduke",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Marshall",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Marvell",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Maumelle",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Mayflower",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "McAlmont",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "McCrory",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "McGehee",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Melbourne",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Mena",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Midway",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Miller County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Mineral Springs",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Mississippi County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Monette",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Morrilton",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Mount Ida",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Mountain Home",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Mountain View",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Mulberry",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Murfreesboro",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Nashville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Nevada County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Newark",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Newton County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "North Crossett",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "North Little Rock",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Ola",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Osceola",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Ouachita County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Ozark",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Paragould",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Paris",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Parkin",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Pea Ridge",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Perryville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Phillips County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Piggott",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Pine Bluff",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Piney",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Pocahontas",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Poinsett County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Pope County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Pottsville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Prairie County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Prairie Creek",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Prairie Grove",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Prescott",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Pulaski County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Randolph County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Rector",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Redfield",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Rison",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Rockwell",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Rogers",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Russellville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Saint Francis County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Saline County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Searcy",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Searcy County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Sebastian County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Sevier County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Shannon Hills",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Sharp County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Sheridan",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Sherwood",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Siloam Springs",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Smackover",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Southside",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Springdale",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Stamps",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Star City",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Stone County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Stuttgart",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Sulphur Springs",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Texarkana",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Tontitown",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Trumann",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Tuckerman",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Van Buren",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Van Buren County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Vilonia",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Waldo",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Waldron",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Walnut Ridge",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Ward",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "West Crossett",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "West Fork",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "West Helena",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "West Memphis",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "White County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "White Hall",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Woodruff County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Wrightsville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Wynne",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Yell County",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Yellville",
    country_code: "US",
    state_code: "AR",
  },
  {
    name: "Acalanes Ridge",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Acton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Adelanto",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Agoura",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Agoura Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Agua Dulce",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Aguanga",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ahwahnee",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alameda",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alameda County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alamo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alhambra",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Aliso Viejo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Allendale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alondra Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alpaugh",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alpine",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alpine County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alta Sierra",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Altadena",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alturas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Alum Rock",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Amador County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "American Canyon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Amesti",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Anaheim",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Anderson",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Angels Camp",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Angwin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Antelope",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Antioch",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Anza",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Apple Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Aptos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Aptos Hills-Larkin Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Arbuckle",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Arcadia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Arcata",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Arden-Arcade",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Armona",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Arnold",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Aromas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Arroyo Grande",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Artesia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Arvin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Atascadero",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Atherton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Atwater",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Auberry",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Auburn Lake Trails",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "August",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Avalon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Avenal",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Avila Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Avocado Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Azusa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bakersfield",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Baldwin Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Banning",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Barstow",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Barstow Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bay Point",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bayside",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bayview",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Beale Air Force Base",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bear Valley Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Beaumont",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bell",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bell Gardens",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bella Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bellflower",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Belmont",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Belvedere",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ben Lomond",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Benicia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Berkeley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bermuda Dunes",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Berry Creek",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bertsch-Oceanview",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bethel Island",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Beverly Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Big Bear City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Big Bear Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Big Pine",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Big River",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Biggs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Biola",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bishop",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Black Point-Green Point",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Blackhawk",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bloomington",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Blue Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Blythe",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bodega Bay",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bodfish",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bolinas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bonadelle Ranchos-Madera Ranchos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bonita",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bonny Doon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bonsall",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Boonville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Boron",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Boronda",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Borrego Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bostonia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Boulder Creek",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Boyes Hot Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Boyle Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bradbury",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Brawley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Brea",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Brentwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bret Harte",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Brisbane",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Broadmoor",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Brookdale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Brooktrails",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Buckhorn",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Buellton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Buena Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Buena Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Burbank",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Burlingame",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Burney",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Butte County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Buttonwillow",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Byron",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Bystrom",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cabazon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Calabasas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Calaveras County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Calexico",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "California City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Calimesa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Calipatria",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Calistoga",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Callender",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Camarillo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cambria",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cambrian Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cameron Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Camino",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Camp Meeker",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Camp Pendleton North",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Camp Pendleton South",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Campbell",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Campo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Canoga Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Canyon Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Capitola",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Carlsbad",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Carmel Valley Village",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Carmel-by-the-Sea",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Carmichael",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Carpinteria",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Carson",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Caruthers",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Casa Conejo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Casa de Oro-Mount Helix",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Castaic",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Castro Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Castroville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cathedral City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cayucos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cedar Ridge",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Central Valley (historical)",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Century City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ceres",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cerritos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Challenge-Brownsville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Channel Islands Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Charter Oak",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Chatsworth",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cherry Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cherryland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Chico",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "China Lake Acres",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Chinatown",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Chino",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Chino Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Chowchilla",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Chualar",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Chula Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Citrus",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Citrus Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "City and County of San Francisco",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Claremont",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Clay",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Clear Lake Riviera",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Clearlake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Clearlake Oaks",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cloverdale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Clovis",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Coachella",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Coalinga",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Coarsegold",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cobb",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Colfax",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Collierville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Colma",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Colton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Colusa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Colusa County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Commerce",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Compton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Contra Costa Centre",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Contra Costa County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cool",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Copperopolis",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Corcoran",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Corning",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Corona",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Coronado",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Corralitos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Corte Madera",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Costa Mesa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cotati",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Coto De Caza",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cottonwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Country Club",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Covelo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Covina",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Crescent City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Crest",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Crestline",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Crockett",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cudahy",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Culver City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cupertino",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cutler",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cutten",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Cypress",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Daly City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dana Point",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Davis",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Day Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Deer Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Del Aire",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Del Mar",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Del Monte Forest",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Del Norte County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Del Rey",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Del Rey Oaks",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Del Rio",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Delano",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Delhi",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Denair",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Descanso",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Desert Edge",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Desert Hot Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Desert Shores",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Desert View Highlands",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Diablo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Diamond Bar",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Diamond Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dinuba",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Discovery Bay",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dixon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dixon Lane-Meadow Creek",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dogtown",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dollar Point",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dos Palos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Downey",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Downieville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Duarte",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dublin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dunnigan",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Dunsmuir",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Durham",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Earlimart",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Foothills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Hemet",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East La Mirada",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Los Angeles",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Oakdale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Palo Alto",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Pasadena",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Porterville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Quincy",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Rancho Dominguez",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Richmond Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East San Gabriel",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "East Sonora",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Easton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Eastvale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Echo Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Edwards Air Force Base",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Cajon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Centro",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Cerrito",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Cerrito Corona",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Dorado County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Dorado Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Granada",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Monte",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Rio",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Segundo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Sobrante",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "El Verano",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Eldridge",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Elk Grove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Elkhorn",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Elverta",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Emerald Lake Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Emeryville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Empire",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Encinitas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Encino",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Escalon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Escondido",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Esparto",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Eucalyptus Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Eureka",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Exeter",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fair Oaks",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fairbanks Ranch",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fairfax",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fairmead",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fallbrook",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Farmersville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Felton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ferndale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fetters Hot Springs-Agua Caliente",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fillmore",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Firebaugh",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Florence-Graham",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Florin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Folsom",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fontana",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Foothill Farms",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Foothill Ranch",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ford City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Forest Meadows",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Forest Ranch",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Foresthill",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Forestville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fort Bragg",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fort Irwin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fortuna",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Foster City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fountain Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fowler",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Frazier Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Freedom",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fremont",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "French Camp",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fresno",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fresno County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fruitridge Pocket",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Fullerton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Galt",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Garden Acres",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Garden Grove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Gardena",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Garnet",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Gerber",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Gilroy",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Glen Avon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Glendale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Glendora",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Glenn County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Gold River",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Golden Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Goleta",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Gonzales",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Good Hope",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Goshen",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Grand Terrace",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Granite Bay",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Granite Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Grass Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Graton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Green Acres",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Green Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Greenacres",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Gridley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Grover Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Guadalupe",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Guerneville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Gustine",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hacienda Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Half Moon Bay",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hamilton City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hanford",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Happy Camp",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Harbison Canyon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hartley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hawaiian Gardens",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hawthorne",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hayfork",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hayward",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Healdsburg",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Heber",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hemet",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Herald",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hercules",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hermosa Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hesperia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hidden Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hidden Meadows",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hidden Valley Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Highgrove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Highland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Highlands-Baywood Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hillsborough",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hilmar-Irwin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hollister",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hollywood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Holtville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Home Garden",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Home Gardens",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Homeland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hughson",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Humboldt County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Humboldt Hill",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Huntington Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Huntington Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Huron",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Hydesville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Idyllwild",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Idyllwild-Pine Cove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Imperial",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Imperial Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Imperial County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Indian Wells",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Indio",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Inglewood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Interlaken",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Inverness",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Inyo County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Inyokern",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ione",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Irvine",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Irwindale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Isla Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ivanhoe",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Jamestown",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Jamul",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Janesville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Johnstonville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Joshua Tree",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Julian",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Jurupa Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kelseyville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kennedy",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kensington",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kentfield",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kenwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kerman",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kern County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kernville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kettleman City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Keyes",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "King City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kings Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kings County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Kingsburg",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Knightsen",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Koreatown",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Cañada Flintridge",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Crescenta-Montrose",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Habra",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Habra Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Jolla",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Mesa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Mirada",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Palma",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Presa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Puente",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Quinta",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Riviera",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Selva Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "La Verne",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ladera",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ladera Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ladera Ranch",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lafayette",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Laguna",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Laguna Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Laguna Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Laguna Niguel",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Laguna Woods",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lagunitas-Forest Knolls",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake Arrowhead",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake Elsinore",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake Forest",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake Isabella",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake Los Angeles",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake Nacimiento",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake of the Pines",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake San Marcos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lake Wildwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lakeland Village",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lakeport",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lakeside",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lakeview",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lakewood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lamont",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Larkfield-Wikiup",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Larkspur",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Las Flores",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Las Lomas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lassen County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lathrop",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Laton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lawndale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Laytonville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Le Grand",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lebec",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lemon Grove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lemoore",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lemoore Station",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lennox",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lenwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Leona Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lewiston",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lexington Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lincoln Village",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Linda",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Linden",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lindsay",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Littlerock",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Live Oak",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Livermore",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Livingston",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lockeford",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lodi",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Loma Linda",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Loma Rica",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lomita",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lompico",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lompoc",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "London",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lone Pine",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Long Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Loomis",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Alamitos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Alamos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Altos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Altos Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Angeles",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Angeles County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Banos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Gatos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Molinos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Olivos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Osos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Los Serranos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lost Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lower Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Loyola",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lucas Valley-Marinwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lucerne",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lucerne Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Lynwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Madera",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Madera Acres",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Madera County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Magalia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Malibu",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mammoth Lakes",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Manhattan Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Manteca",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "March Air Force Base",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Maricopa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Marin City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Marin County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Marina",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Marina del Rey",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mariposa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mariposa County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Martinez",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Marysville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Matheny",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Maxwell",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mayflower Village",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Maywood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "McCloud",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "McFarland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "McKinleyville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mead Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Meadow Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Meadowbrook",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mecca",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Meiners Oaks",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mendocino County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mendota",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Menifee",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Menlo Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mentone",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Merced",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Merced County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mesa Verde",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Midpines",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Midway City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mill Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Millbrae",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Milpitas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Minkler",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mira Mesa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mira Monte",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mission Canyon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mission District",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mission Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mission Viejo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Modesto",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Modoc County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mojave",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mono County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mono Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Monrovia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Montague",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Montalvin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Montara",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Montclair",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Monte Rio",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Monte Sereno",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Montebello",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Montecito",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Monterey",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Monterey County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Monterey Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Monument Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Moorpark",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Morada",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Moraga",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Moreno Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Morgan Hill",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Morongo Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Morro Bay",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Moss Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mount Hermon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mount Shasta",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mountain House",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mountain Ranch",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mountain View",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Mountain View Acres",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Murphys",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Murrieta",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Murrieta Hot Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Muscoy",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Myrtletown",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Napa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Napa County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "National City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Needles",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Nevada City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Nevada County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Newark",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Newcastle",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Newman",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Newport Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Nice",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Niland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Nipomo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Noe Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Norco",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North Auburn",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North Edwards",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North El Monte",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North Fair Oaks",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North Highlands",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North Hollywood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North Lakeport",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North Richmond",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "North Tustin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Northridge",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Norwalk",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Novato",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Nuevo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oak Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oak Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oak View",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oakdale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oakhurst",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oakley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oasis",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Occidental",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oceano",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oceanside",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oildale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ojai",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Old Fig Garden",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Olivehurst",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ontario",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Opal Cliffs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Orange",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Orange County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Orange Cove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Orangevale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Orcutt",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Orinda",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Orland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Orosi",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oroville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oroville East",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Oxnard",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pacheco",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pacific Grove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pacifica",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pajaro",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Palermo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Palm Desert",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Palm Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Palmdale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Palo Alto",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Palo Cedro",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Palos Verdes Estates",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Paradise",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Paramount",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Parksdale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Parkway",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Parkwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Parlier",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pasadena",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pasatiempo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Paso Robles",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Patterson",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Patterson Tract",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pedley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Penn Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Penngrove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Perris",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Petaluma",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Phelan",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Phoenix Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pico Rivera",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Piedmont",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pine Grove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pine Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pine Mountain Club",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pine Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pinole",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Piñon Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pioneer",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Piru",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pismo Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pittsburg",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pixley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Placentia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Placer County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Placerville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Planada",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pleasant Hill",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pleasanton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Plumas County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Plumas Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pollock Pines",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Pomona",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Poplar-Cotton Center",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Port Hueneme",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Porterville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Portola",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Portola Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Portola Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Poway",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Prunedale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Quail Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Quartz Hill",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Quincy",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rainbow",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ramona",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Calaveras",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Cordova",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Cucamonga",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Mirage",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Murieta",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Palos Verdes",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Penasquitos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho San Diego",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Santa Fe",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Santa Margarita",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rancho Tehama Reserve",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Red Bluff",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Red Corral",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Redding",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Redlands",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Redondo Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Redway",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Redwood City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Redwood Shores",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Redwood Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Reedley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rialto",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Richgrove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ridgecrest",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ridgemark",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rio Del Mar",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rio Dell",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rio Linda",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rio Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ripon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Riverbank",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Riverdale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Riverdale Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Riverside County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rocklin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rodeo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rohnert Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rolling Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rolling Hills Estates",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rollingwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Romoland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rosamond",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rosedale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Roseland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rosemead",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rosemont",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Roseville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ross",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rossmoor",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rowland Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Rubidoux",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Running Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sacramento",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sacramento County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Saint Helena",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Salida",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Salinas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Salton City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Andreas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Anselmo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Antonio Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Benito County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Bernardino",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Bernardino County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Bruno",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Carlos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Clemente",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Diego",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Diego Country Estates",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Diego County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Dimas",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Fernando",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Francisco",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Gabriel",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Jacinto",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Joaquin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Joaquin County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Joaquin Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Jose",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Juan Bautista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Juan Capistrano",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Leandro",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Lorenzo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Luis Obispo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Luis Obispo County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Marcos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Marino",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Martin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Mateo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Mateo County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Miguel",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Pablo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Pasqual",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Pedro",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Rafael",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "San Ramon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sanger",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Ana",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Barbara",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Barbara County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Clara",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Clara County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Clarita",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Cruz",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Cruz County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Fe Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Margarita",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Maria",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Monica",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Paula",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Rosa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Susana",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Venetia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santa Ynez",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Santee",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Saranap",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Saratoga",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Saticoy",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sausalito",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Scotts Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sea Ranch",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Seacliff",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Seal Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Searles Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Seaside",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sebastopol",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sedco Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Seeley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Selma",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Seven Trees",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Shackelford",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Shadow Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Shafter",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Shandon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Shasta",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Shasta County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Shasta Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sheridan",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sherman Oaks",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Shingle Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Shingletown",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sierra County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sierra Madre",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Signal Hill",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Silver Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Silver Lakes",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Simi Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Siskiyou County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sky Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sleepy Hollow",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Soda Bay",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Solana Beach",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Solano County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Soledad",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Solvang",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sonoma",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sonoma County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sonora",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Soquel",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sorrento Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Soulsbyville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South Dos Palos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South El Monte",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South Gate",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South Lake Tahoe",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South Oroville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South Pasadena",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South San Francisco",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South San Gabriel",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South San Jose Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South Taft",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South Whittier",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "South Yuba City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Spring Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Spring Valley Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Squaw Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Stallion Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Stanford",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Stanislaus County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Stanton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Stevenson Ranch",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Stockton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Stratford",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Strathmore",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Strawberry",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Studio City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Suisun",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Summerland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sun City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sun Village",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sunland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sunnyside",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sunnyside-Tahoe City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sunnyslope",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sunnyvale",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Susanville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sutter",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sutter County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Sutter Creek",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Taft",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Taft Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Taft Mosswood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tahoe Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tahoma",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Talmage",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tamalpais Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tamalpais-Homestead Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tara Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tarpey Village",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tehachapi",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tehama County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Temecula",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Temelec",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Temple City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Templeton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Terra Bella",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Teviston",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Thermal",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Thermalito",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Thornton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Thousand Oaks",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Thousand Palms",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Three Rivers",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tiburon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tierra Buena",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tipton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Topanga",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Toro Canyon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Torrance",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Trabuco Canyon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tracy",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Trinity County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Truckee",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tujunga",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tulare",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tulare County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tuolumne City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tuolumne County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Turlock",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Tustin",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Twain Harte",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Twentynine Palms",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Twin Lakes",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ukiah",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Union City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Universal City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Upland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Upper Lake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Vacaville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Val Verde",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Valencia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Valinda",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Valle Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Vallejo",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Valley Center",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Valley Glen",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Valley Springs",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Van Nuys",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Vandenberg Air Force Base",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Vandenberg Village",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Venice",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ventura",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Ventura County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Victorville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "View Park-Windsor Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Villa Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Vincent",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Vine Hill",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Vineyard",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Visalia",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Visitacion Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Vista",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Vista Santa Rosa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Waldon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Walnut",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Walnut Creek",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Walnut Grove",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Walnut Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Wasco",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Waterford",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Watsonville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Weaverville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Weed",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Weedpatch",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Weldon",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Athens",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Bishop",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Carson",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Covina",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Hollywood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Menlo Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Modesto",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Park",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Puente Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Rancho Dominguez",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Sacramento",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "West Whittier-Los Nietos",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Westhaven-Moonstone",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Westlake Village",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Westminster",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Westmont",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Westmorland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Westwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Wheatland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Whittier",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Wildomar",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Williams",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Willits",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Willow Creek",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Willowbrook",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Willows",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Wilton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Winter Gardens",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Winters",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Winton",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Wofford Heights",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Woodacre",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Woodbridge",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Woodcrest",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Woodlake",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Woodland",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Woodland Hills",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Woodside",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Woodville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Wrightwood",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yolo County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yorba Linda",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yosemite Lakes",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yosemite Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yountville",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yreka",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yuba City",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yuba County",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yucaipa",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Yucca Valley",
    country_code: "US",
    state_code: "CA",
  },
  {
    name: "Acres Green",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Air Force Academy",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Akron",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Alamosa",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Alamosa County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Alamosa East",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Applewood",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Arapahoe County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Archuleta County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Aristocrat Ranchettes",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Arvada",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Aspen",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Ault",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Aurora",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Avon",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Baca County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Basalt",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Battlement Mesa",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Bayfield",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Bennett",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Bent County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Berkley",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Berthoud",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Black Forest",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Boulder",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Boulder County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Breckenridge",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Brighton",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Broomfield",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Broomfield County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Brush",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Buena Vista",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Byers",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Campion",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cañon City",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Carbondale",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Carriage Club",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cascade-Chipita Park",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Castle Pines",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Castle Pines North",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Castle Rock",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Castlewood",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cedaredge",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Centennial",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Center",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Central City",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Chaffee County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cherry Creek",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cherry Hills Village",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cheyenne County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cheyenne Wells",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cimarron Hills",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Clear Creek County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Clifton",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Coal Creek",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Colorado City",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Colorado Springs",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Columbine",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Columbine Valley",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Commerce City",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Conejos",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Conejos County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cortez",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Costilla County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Craig",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Creede",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Crested Butte",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Cripple Creek",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Crowley County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Custer County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Dacono",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Del Norte",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Delta",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Delta County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Denver",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Denver County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Derby",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Dolores County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Dove Creek",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Dove Valley",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Durango",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Eads",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Eagle",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Eagle County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Eaton",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Edgewater",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Edwards",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "El Jebel",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "El Paso County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Elbert County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Elizabeth",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Ellicott",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Englewood",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Erie",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Estes Park",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Evans",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Evergreen",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fairplay",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Federal Heights",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Firestone",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fort Carson",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fort Collins",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fort Lupton",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fort Morgan",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fountain",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fowler",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fraser",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Frederick",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fremont County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Frisco",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fruita",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Fruitvale",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Garfield County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Genesee",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Gilcrest",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Gilpin County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Glendale",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Gleneagle",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Glenwood Springs",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Golden",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Granby",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Grand County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Grand Junction",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Greeley",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Greenwood Village",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Gunbarrel",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Gunnison",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Gunnison County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Gypsum",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Hayden",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Highlands Ranch",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Hinsdale County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Holly Hills",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Holyoke",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Hot Sulphur Springs",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Huerfano County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Hugo",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Idaho Springs",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Indian Hills",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Inverness",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Johnstown",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Julesburg",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Keenesburg",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Ken Caryl",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Kersey",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Keystone",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Kiowa",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Kiowa County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Kit Carson County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Kittredge",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Kremmling",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "La Junta",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "La Plata County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "La Salle",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lafayette",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lake City",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lakewood",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lamar",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Laporte",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Larimer County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Las Animas",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Las Animas County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Leadville",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Leadville North",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Limon",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lincoln Park",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Littleton",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lochbuie",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Loma",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lone Tree",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Longmont",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Louisville",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Loveland",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Lyons",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Mancos",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Manitou Springs",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Mead",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Meeker",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Meridian",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Mesa County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Milliken",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Mineral County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Minturn",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Moffat County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Monte Vista",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Montezuma County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Montrose",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Montrose County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Monument",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Mountain Village",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Nederland",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "New Castle",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Niwot",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Northglenn",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Olathe",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Orchard City",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Orchard Mesa",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Ordway",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Otero County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Ouray",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Ouray County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Pagosa Springs",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Palisade",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Palmer Lake",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Paonia",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Parachute",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Park County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Parker",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Penrose",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Perry Park",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Phillips County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Pitkin County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Platteville",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Ponderosa Park",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Prowers County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Pueblo",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Pueblo County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Pueblo West",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Rangely",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Redlands",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Rifle",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Rio Blanco County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Rio Grande County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Rocky Ford",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Routt County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Roxborough Park",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Saguache",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Saguache County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Salida",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "San Juan County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "San Luis",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "San Miguel County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Security-Widefield",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Sedgwick County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Severance",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Shaw Heights",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Sheridan",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Sherrelwood",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Silt",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Silverthorne",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Silverton",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Snowmass Village",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Southglenn",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Steamboat Springs",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Sterling",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Stonegate",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Strasburg",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Stratmoor",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Summit County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Superior",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Teller County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Telluride",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "The Pinery",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Thornton",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Todd Creek",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Towaoc",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Trinidad",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Twin Lakes",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Upper Bear Creek",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Vail",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Walden",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Walsenburg",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Welby",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Weld County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Wellington",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "West Pleasant View",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Westcliffe",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Westminster",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Wheat Ridge",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Woodland Park",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Woodmoor",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Wray",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Yuma",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Yuma County",
    country_code: "US",
    state_code: "CO",
  },
  {
    name: "Ansonia",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Baltic",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Bethel",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Bethlehem Village",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Blue Hills",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Branford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Branford Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Byram",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Canaan",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Canton Valley",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Central Waterford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Cheshire",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Cheshire Village",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Chester Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "City of Milford (balance)",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Colchester",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Collinsville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Conning Towers-Nautilus Park",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Cos Cob",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Coventry Lake",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Cromwell",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Crystal Lake",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Danbury",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Danielson",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Darien",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Deep River Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Derby",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Durham",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "East Brooklyn",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "East Haddam",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "East Hampton",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "East Hartford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "East Haven",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "East Norwalk",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "East Windsor",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Easton",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Ellington",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Enfield",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Essex Village",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Fairfield County",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Gales Ferry",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Glastonbury",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Glastonbury Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Glenville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Greenwich",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Groton",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Guilford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Guilford Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Hamden",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Hartford County",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Hazardville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Hebron",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Heritage Village",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Higganum",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Jewett City",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Kensington",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Kent",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Killingly Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Lake Pocotopaug",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Ledyard",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Lisbon",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Litchfield",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Litchfield County",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Long Hill",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Madison Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Mansfield City",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Meriden",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Middlebury",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Middlesex County",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Montville Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Moodus",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Moosup",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Mystic",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Naugatuck",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New Britain",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New Canaan",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New Fairfield",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New Hartford Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New Haven",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New Haven County",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New London",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New London County",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New Milford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "New Preston",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Newington",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Newtown",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Niantic",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Noank",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "North Branford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "North Granby",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "North Grosvenor Dale",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "North Haven",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "North Stamford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Northwest Harwinton",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Norwalk",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Norwich",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Oakville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Old Greenwich",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Old Mystic",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Old Saybrook",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Old Saybrook Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Orange",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Oxoboxo River",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Pawcatuck",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Pemberwick",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Plainfield",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Plainfield Village",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Plainville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Poquonock Bridge",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Portland",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Preston City",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Prospect",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Putnam",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Quinebaug",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Ridgefield",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Rockville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Salmon Brook",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Saybrook Manor",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Seymour",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Shelton",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Sherman",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Sherwood Manor",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Simsbury Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Somers",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "South Coventry",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "South Windham",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "South Windsor",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "South Woodstock",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Southbury",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Southport",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Southwood Acres",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Stafford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Stafford Springs",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Stamford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Storrs",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Stratford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Suffield Depot",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Tariffville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Terramuggus",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Terryville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Thomaston",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Thompson",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Thompsonville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Tolland",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Tolland County",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Torrington",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Trumbull",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Uncasville",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Wallingford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Wallingford Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Waterbury",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Waterford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Watertown",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Wauregan",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Weatogue",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "West Hartford",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "West Haven",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "West Simsbury",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "West Torrington",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Westbrook Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Westport",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Wethersfield",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Willimantic",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Wilton",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Winchester Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Windham",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Windham County",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Windsor Locks",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Winsted",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Wolcott",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Woodbridge",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Woodbury",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Woodbury Center",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Woodmont",
    country_code: "US",
    state_code: "CT",
  },
  {
    name: "Bear",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Bellefonte",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Bethany Beach",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Blades",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Bridgeville",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Brookside",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Cheswold",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Claymont",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Delaware City",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Delmar",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Dover Base Housing",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Edgemoor",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Elsmere",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Felton",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Glasgow",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Greenwood",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Harrington",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Highland Acres",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Hockessin",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Kent Acres",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Kent County",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Laurel",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Lewes",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Long Neck",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Millsboro",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "New Castle",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "New Castle County",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Newark",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "North Star",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Ocean View",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Pike Creek",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Pike Creek Valley",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Rehoboth Beach",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Rising Sun-Lebanon",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Riverview",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Rodney Village",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Seaford",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Selbyville",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Smyrna",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Sussex County",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Townsend",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Wilmington",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Wilmington Manor",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Woodside East",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Wyoming",
    country_code: "US",
    state_code: "DE",
  },
  {
    name: "Adams Morgan",
    country_code: "US",
    state_code: "DC",
  },
  {
    name: "Bloomingdale",
    country_code: "US",
    state_code: "DC",
  },
  {
    name: "Chevy Chase",
    country_code: "US",
    state_code: "DC",
  },
  {
    name: "Shaw",
    country_code: "US",
    state_code: "DC",
  },
  {
    name: "Washington, D.C.",
    country_code: "US",
    state_code: "DC",
  },
  {
    name: "Aberdeen",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Alachua",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Alachua County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Alafaya",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Allapattah",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Altamonte Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Alturas",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Alva",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Andover",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Anna Maria",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Apalachicola",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Apollo Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Apopka",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Arcadia",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Archer",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Asbury Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Astatula",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Astor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Atlantic Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Atlantis",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Auburndale",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Aventura",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Avon Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Azalea Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Babson Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bagdad",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Baker County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bal Harbour",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Baldwin",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Balm",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bartow",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bay County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bay Harbor Islands",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bay Hill",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bay Pines",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bayonet Point",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bayshore Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Beacon Square",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bee Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bellair-Meadowbrook Terrace",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Belle Glade",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Belle Glade Camp",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Belle Isle",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Belleair",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Belleair Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Belleair Bluffs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Belleview",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bellview",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Beverly Hills",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Big Coppitt Key",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Big Pine Key",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Biscayne Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bithlo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Black Diamond",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bloomingdale",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Blountstown",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Boca Del Mar",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Boca Pointe",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Boca Raton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bokeelia",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bonifay",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bonita Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Boulevard Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bowling Green",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Boyette",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Boynton Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bradenton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bradenton Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bradford County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Brandon",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Brent",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Brevard County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Broadview Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bronson",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Brookridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Brooksville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Broward County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Broward Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Brownsville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Buckhead Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Buckingham",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Buenaventura Lakes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bunche Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bunnell",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Burnt Store Marina",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Bushnell",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Butler Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Callahan",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Callaway",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Campbell",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cantonment",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cape Canaveral",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cape Coral",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Carol City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Carrabelle",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Carrollwood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Carrollwood Village",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Carver Ranches",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Casselberry",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cedar Grove",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Celebration",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Center Hill",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Century",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Charlotte County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Charlotte Harbor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Charlotte Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Chattahoochee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cheval",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Chiefland",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Chipley",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Christmas",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Chuluota",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Citra",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Citrus County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Citrus Hills",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Citrus Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Citrus Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Citrus Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Clarcona",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Clearwater",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Clermont",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cleveland",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Clewiston",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cocoa",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cocoa Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cocoa West",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Coconut Creek",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Coconut Grove",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Collier County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Columbia County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Combee Settlement",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Connerton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Conway",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cooper City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Coral Gables",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Coral Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Coral Terrace",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cortez",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Country Club",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Country Walk",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Crawfordville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Crescent City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Crestview",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Crooked Lake Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cross City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Crystal Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Crystal River",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Crystal Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cudjoe Key",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cutler",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cutler Bay",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cutler Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cypress Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cypress Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Cypress Quarters",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Dade City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Dade City North",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Dania Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Davenport",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Davie",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Daytona Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Daytona Beach Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "De Land Southwest",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "De Leon Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "DeBary",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Deerfield Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "DeFuniak Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "DeLand",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Delray Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Deltona",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "DeSoto County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Desoto Lakes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Destin",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Dixie County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Doctor Phillips",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Doral",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Dundee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Dunedin",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Dunnellon",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Duval County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Eagle Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "East Bronson",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "East Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "East Lake-Orient Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "East Milton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "East Naples",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "East Palatka",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "East Pensacola Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "East Perrine",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Eastpoint",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Eatonville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Edgewater",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Edgewood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Eglin Air Force Base",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Eglin Village",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Egypt Lake-Leto",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "El Portal",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Elfers",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ellenton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Englewood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ensley",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Escambia County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Estero",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Eustis",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fairview Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Feather Sound",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fellsmere",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fern Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fernandina Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ferry Pass",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fish Hawk",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Five Points",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Flagami",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Flagler Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Flagler County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Flagler Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fleming Island",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Floral City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Florida City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Florida Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Forest City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fort Lauderdale",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fort Meade",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fort Myers",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fort Myers Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fort Myers Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fort Pierce",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fort Pierce North",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fort Pierce South",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fort Walton Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fountainebleau",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Four Corners",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Freeport",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Frostproof",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fruit Cove",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fruitland Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fruitville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fuller Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Fussels Corner",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gadsden County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gainesville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gandy",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gateway",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Geneva",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gibsonia",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gibsonton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gifford",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gilchrist County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Glades County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gladeview",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Glencoe",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Glenvar Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Golden Gate",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Golden Glades",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Goldenrod",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gonzalez",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gotha",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Goulding",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Goulds",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Graceville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Grant-Valkaria",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Greater Northdale",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Green Cove Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Greenacres City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Greenbriar",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gretna",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Grove City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Groveland",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gulf Breeze",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gulf County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gulf Gate Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Gulfport",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Haines City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hallandale Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Harbor Bluffs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Harbour Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hardee County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Harlem",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Harlem Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Havana",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Haverhill",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hawthorne",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Heathrow",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hendry County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Heritage Pines",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hernando",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hernando Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hernando County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hialeah",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hialeah Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "High Point",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "High Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Highland Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Highland City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Highlands County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hiland Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hill 'n Dale",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hilliard",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hillsboro Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hillsborough County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hobe Sound",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Holden Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Holiday",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Holley",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Holly Hill",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hollywood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Holmes Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Holmes County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Homestead",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Homosassa",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Homosassa Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Horizon West",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Howey-in-the-Hills",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hunters Creek",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hutchinson Island South",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Hypoluxo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Immokalee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Indialantic",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Indian Harbour Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Indian River County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Indian River Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Indian River Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Indian Rocks Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Indian Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Indiantown",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Inglis",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Interlachen",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Inverness",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Inverness Highlands North",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Inverness Highlands South",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Inwood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Iona",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Islamorada",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Island Walk",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Isle of Normandy",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ives Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Jacksonville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Jacksonville Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Jan-Phyl Village",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Jasmine Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Jasper",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Jensen Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "June Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Juno Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Jupiter",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Kathleen",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Kendale Lakes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Kendall",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Kendall Green",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Kendall West",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Kenneth City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Kensington Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Key Biscayne",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Key Largo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Key Vista",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Key West",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Keystone",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Keystone Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Kings Point",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Kissimmee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "LaBelle",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lacoochee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lady Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lafayette County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Laguna Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Alfred",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Belvedere Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Butler",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Clarke Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Forest",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Hamilton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Helen",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Lorraine",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Lucerne",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Mack-Forest Hills",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Magdalene",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Mary",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Panasoffkee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Placid",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Sarasota",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Wales",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Worth",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lake Worth Corridor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lakeland",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lakeland Highlands",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lakes by the Bay",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lakeside",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lakewood Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Land O' Lakes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lantana",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Largo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lauderdale Lakes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lauderdale-by-the-Sea",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lauderhill",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Laurel",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lealman",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lecanto",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Leesburg",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lehigh Acres",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Leisure City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lely",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lely Resort",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Leon County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Levy County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Liberty County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lighthouse Point",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Limestone Creek",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Live Oak",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lochmoor Waterway Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lockhart",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Longboat Key",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Longwood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Loughman",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lower Grand Lagoon",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Loxahatchee Groves",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lutz",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Lynn Haven",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Macclenny",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Madeira Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Maitland",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Malabar",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Malone",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Manasota Key",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Manatee County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Manatee Road",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mango",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mangonia Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Marathon",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Marco",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Marco Island",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Margate",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Marianna",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Martin County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mary Esther",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Masaryktown",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mascotte",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mayo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "McGregor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Meadow Oaks",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Meadow Woods",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Medulla",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Melbourne",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Melbourne Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Melrose Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Memphis",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Merritt Island",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mexico Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Miami",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Miami Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Miami Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Miami Lakes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Miami Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Miami Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Miami-Dade County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Micco",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Middleburg",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Midway",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mims",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Minneola",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Miramar",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Miramar Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Molino",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Montverde",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Moore Haven",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mount Dora",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mount Plymouth",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Mulberry",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Myrtle Grove",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Naples",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Naples Manor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Naples Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Naranja",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Nassau County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Nassau Village-Ratliff",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Navarre",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Neptune Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "New Port Richey",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "New Port Richey East",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "New Smyrna Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Newberry",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Niceville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Nocatee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Nokomis",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Norland",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Andrews Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Bay Village",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Brooksville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North DeLand",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Fort Myers",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Key Largo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Lauderdale",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Miami",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Miami Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Palm Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Port",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Redington Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North River Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Sarasota",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "North Weeki Wachee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Northdale",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Oak Hill",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Oak Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Oakland Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Oakleaf Plantation",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ocala",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ocean City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ocean Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ocoee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Odessa",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ojus",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Okaloosa County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Okeechobee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Okeechobee County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Oldsmar",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Olga",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Olympia Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Opa-locka",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Orange City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Orange County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Orange Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Orangetree",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Oriole Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Orlando",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Orlovista",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ormond Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ormond-by-the-Sea",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Osceola County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Osprey",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Oviedo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pace",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pahokee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palatka",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Aire",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Bay",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Beach County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Beach Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Beach Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Coast",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Harbor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm River-Clair Mel",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Springs North",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palm Valley",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palmetto",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palmetto Bay",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palmetto Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Palmona Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Panama City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Panama City Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Paradise Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Parker",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Parkland",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pasadena Hills",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pasco County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pebble Creek",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pelican Bay",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pembroke Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pembroke Pines",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pensacola",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Perry",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pierson",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pine Castle",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pine Hills",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pine Island Center",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pine Island Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pine Manor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pine Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pinecrest",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pinellas County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pinellas Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pinewood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Placid Lakes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Plant City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Plantation",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Plantation Mobile Home Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Poinciana",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Point Baker",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Polk City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pompano Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pompano Beach Highlands",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ponce Inlet",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ponte Vedra Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Port Charlotte",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Port LaBelle",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Port Orange",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Port Richey",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Port Saint Joe",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Port Saint John",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Port Saint Lucie",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Port Salerno",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Pretty Bayou",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Progress Village",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Punta Gorda",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Punta Gorda Isles",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Punta Rassa",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Putnam County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Quail Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Quincy",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Redington Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Redington Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Richmond Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Richmond West",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ridge Manor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ridge Wood Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ridgecrest",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "River Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Riverview",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Riviera Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Rock Island",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Rockledge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Roosevelt Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Roseland",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Rotonda West",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Royal Palm Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Royal Palm Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Ruskin",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Safety Harbor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint Augustine",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint Augustine Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint Augustine Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint Augustine South",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint Cloud",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint George",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint James City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint Johns County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint Leo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint Lucie County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Saint Pete Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Samoset",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Samsula-Spruce Creek",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "San Antonio",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "San Carlos Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sandalfoot Cove",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sanford",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sanibel",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Santa Rosa County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sarasota",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sarasota County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sarasota Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Satellite Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sawgrass",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Schall Circle",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Scott Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Seaside",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sebastian",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sebring",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Seffner",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Seminole",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Seminole County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Seminole Manor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sewall's Point",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Shady Hills",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sharpes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Siesta Key",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Silver Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Silver Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Silver Springs Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sky Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sneads",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Apopka",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Bay",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Bradenton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Brooksville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Daytona",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Gate Ridge",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Highpoint",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Miami",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Miami Heights",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Palm Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Pasadena",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Patrick Shores",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Sarasota",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "South Venice",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Southchase",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Southeast Arcadia",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Southgate",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Southwest Ranches",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Spring Hill",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "St. Johns",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "St. Petersburg",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Starke",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Steinhatchee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Stock Island",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Stuart",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sugarmill Woods",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sumter County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sun City Center",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Suncoast Estates",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sunny Isles Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sunrise",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sunset",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sunshine Ranches",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Surfside",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Suwannee County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Sweetwater",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Taft",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tallahassee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tamarac",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tamiami",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tampa",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tangelo Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tangerine",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tarpon Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tavares",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tavernier",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Taylor County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Taylor Creek",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tedder",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Temple Terrace",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tequesta",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Terra Mar",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "The Acreage",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "The Crossings",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "The Hammocks",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "The Meadows",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "The Villages",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Thonotosassa",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Three Lakes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Three Oaks",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tice",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tierra Verde",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tiger Point",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Timber Pines",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Titusville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Town 'n' Country",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Treasure Island",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Trinity",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Twin Lakes",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Tyndall Air Force Base",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Umatilla",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Union Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "University",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "University Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Upper Grand Lagoon",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Valparaiso",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Valrico",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Vamo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Venice",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Venice Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Vero Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Vero Beach South",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Verona Walk",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Viera East",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Viera West",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Villages of Oriole",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Villano Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Villas",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Vineyards",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Virginia Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Volusia County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wabasso Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wahneta",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wakulla County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Waldo",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wallace",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Walton County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Warm Mineral Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Warrington",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Washington Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Watertown",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wauchula",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wedgefield",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Weeki Wachee Gardens",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wekiwa Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wellborn",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wellington",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wesley Chapel",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West and East Lealman",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Bradenton",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West DeLand",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Gate",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Hollywood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Little River",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Melbourne",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Miami",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Palm Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Pensacola",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Perrine",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Samoset",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "West Vero Corridor",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Westchase",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Westchester",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Weston",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Westview",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Westwood Lake",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wewahitchka",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Whiskey Creek",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "White City",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Whitfield",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wildwood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Williamsburg",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Williston",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Williston Highlands",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Willow Oak",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wilton Manors",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wimauma",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Windermere",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Winston",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Winter Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Winter Garden",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Winter Haven",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Winter Park",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Winter Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Woodlawn Beach",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Woodville",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Wright",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Yalaha",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Youngstown",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Yulee",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Zellwood",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Zephyrhills",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Zephyrhills North",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Zephyrhills South",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Zephyrhills West",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Zolfo Springs",
    country_code: "US",
    state_code: "FL",
  },
  {
    name: "Abbeville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Acworth",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Adairsville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Adel",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Alamo",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Alma",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Alpharetta",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Alto",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Americus",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Appling County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Aragon",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Arcade",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ashburn",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Atkinson County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Atlanta",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Augusta",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Austell",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Avondale Estates",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bacon County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bainbridge",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Baker County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Baldwin",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Baldwin County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ball Ground",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Banks County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Barnesville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Barrow County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bartow County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Baxley",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Belvedere Park",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ben Hill County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Berkeley Lake",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Berrien County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bibb County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Blackshear",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Blairsville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Blakely",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bleckley County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bloomingdale",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Blue Ridge",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bogart",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bonanza",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Boston",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bowdon",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Brantley County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Braselton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bremen",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Brookhaven",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Brooklet",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Brooks County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Broxton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Brunswick",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bryan County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Buchanan",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Buena Vista",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Buford",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Bulloch County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Burke County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Butler",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Butts County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Byron",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cairo",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Calhoun",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Camden County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Camilla",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Candler County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Candler-McAfee",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Carnesville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cartersville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Catoosa County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cave Spring",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cedartown",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Chamblee",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Charlton County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Chatham County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Chatsworth",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Chattahoochee County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Chattahoochee Hills",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Chattanooga Valley",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Chattooga County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cherokee County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Chickamauga",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Clarke County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Clarkesville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Clarkston",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Claxton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Clayton County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cleveland",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Clinch County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cobb County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cochran",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Coffee County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "College Park",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Colquitt",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Colquitt County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Columbia County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Comer",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Commerce",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Conley",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Conyers",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cook County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cordele",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cornelia",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Country Club Estates",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Covington",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Coweta County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Crawfordville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Crisp County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cumming",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cusseta",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Cuthbert",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dacula",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dade County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dahlonega",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dallas",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dalton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Danielsville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Darien",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Davisboro",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dawson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dawson County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dawsonville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Decatur County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Deenwood",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "DeKalb County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Demorest",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dock Junction",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dodge County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Donalsonville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dooly County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Doraville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dougherty County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Douglas",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Douglasville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Druid Hills",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dublin",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Duluth",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Dunwoody",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Early County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "East Dublin",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "East Griffin",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "East Newnan",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "East Point",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Eastman",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Eatonton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Echols County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Edison",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Effingham County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Elbert County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Elberton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ellaville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ellijay",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Emanuel County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Emerson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Enigma",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Euharlee",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Evans",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Evans County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Experiment",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fair Oaks",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fairburn",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fannin County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fayetteville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Firing Range",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fitzgerald",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Flowery Branch",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Floyd County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Folkston",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Forest Park",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Forsyth",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Forsyth County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fort Gaines",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fort Oglethorpe",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fort Stewart",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fort Valley",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Franklin Springs",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Fulton County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Gainesville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Garden City",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Gibson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Gilmer County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Glascock County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Glennville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Glynn County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Gordon",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Gordon County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Grady County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Grantville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Gray",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Grayson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Greensboro",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Gresham Park",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Griffin",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Grovetown",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Gumlog",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Guyton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Gwinnett County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Habersham County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hahira",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hall County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hamilton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hampton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hannahs Mill",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hapeville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Haralson County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hardwick",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Harlem",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Harris County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hart County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hartwell",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hawkinsville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hazlehurst",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Heard County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Helena",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Henderson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hephzibah",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hiawassee",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hinesville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hiram",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hogansville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Holly Springs",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Homer",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Homerville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Hoschton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Houston County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Indian Springs",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Irondale",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Irwin County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Irwinton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Isle of Hope",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jasper",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jasper County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jeff Davis County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jeffersonville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jenkins County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jesup",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Johns Creek",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jones County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Jonesboro",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Kennesaw",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Kings Bay Base",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Kingsland",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Knoxville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "LaFayette",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "LaGrange",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lake City",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lakeland",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lakeview",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lakeview Estates",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lamar County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lanier County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Laurens County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lavonia",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lawrenceville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Leesburg",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Liberty County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lilburn",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lincolnton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lindale",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lithia Springs",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lithonia",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Locust Grove",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Loganville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Long County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lookout Mountain",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Louisville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lovejoy",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lowndes County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ludowici",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lula",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lumber City",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lumpkin",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lumpkin County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Lyons",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Mableton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Macon",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Macon County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Marietta",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Marshallville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Martinez",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Maysville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "McCaysville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "McDonough",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "McDuffie County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "McIntosh County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "McRae",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Meigs",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Meriwether County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Metter",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Midway",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Milledgeville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Millen",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Miller County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Mitchell County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Montezuma",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Montgomery",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Morgan",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Morrow",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Moultrie",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Mount Airy",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Mount Zion",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Mountain City",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Mountain Park",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Murray County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Muscogee County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Nahunta",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Nashville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Nelson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Newnan",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Newton County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Nicholls",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Nicholson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Norcross",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "North Atlanta",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "North Decatur",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "North Druid Hills",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Oakwood",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ocilla",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Oconee County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Oglethorpe",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Oglethorpe County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Omega",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Palmetto",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Panthersville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Paulding County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Peach County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Peachtree City",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Peachtree Corners",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Pearson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Pelham",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Pembroke",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Perry",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Pickens County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Pierce County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Pine Mountain",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Pooler",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Port Wentworth",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Porterdale",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Powder Springs",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Preston",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Pulaski County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Putnam County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Putney",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Quitman",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Quitman County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Rabun County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Randolph County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Raoul",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ray City",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Redan",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Reed Creek",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Reidsville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Remerton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Reynolds",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Richland",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Richmond County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Richmond Hill",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Rincon",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ringgold",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Riverdale",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Robins Air Force Base",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Rochelle",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Rockdale County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Rockmart",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Rome",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Rossville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Roswell",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Royston",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Russell",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Rydal",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Saint Simon Mills",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Saint Simons Island",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sandersville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sandy Springs",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sardis",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Savannah",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Schley County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Scottdale",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Screven County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Seminole County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Senoia",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Shannon",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Skidaway Island",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Smyrna",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Snellville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Social Circle",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Soperton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Spalding County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sparks",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sparta",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "St. Marys",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Statenville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Statesboro",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Statham",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Stephens County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Stewart County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Stockbridge",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Stone Mountain",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Stonecrest",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sugar Hill",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Summerville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sumter County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sunnyside",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Suwanee",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Swainsboro",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sylvania",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Sylvester",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Talbot County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Talbotton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Taliaferro County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Tallapoosa",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Tattnall County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Taylor County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Telfair County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Temple",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Tennille",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Terrell County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Thomas County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Thomaston",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Thomasville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Thomson",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Thunderbolt",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Tift County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Tifton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Toccoa",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Toombs County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Towns County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Treutlen County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Trion",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Troup County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Tucker",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Turner County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Twiggs County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Twin City",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Tybee Island",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Tyrone",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Unadilla",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Union City",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Union Point",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Unionville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Upson County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Valdosta",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Varnell",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Vidalia",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Vienna",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Villa Rica",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Vinings",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Wadley",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Walker County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Walnut Grove",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Walthourville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Walton County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Ware County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Warner Robins",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Warrenton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Watkinsville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Waycross",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Waynesboro",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Webster County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "West Point",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "West Warrenton",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Wheeler County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "White County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Whitemarsh Island",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Whitfield County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Wilcox County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Wilkes County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Wilkinson County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Willacoochee",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Wilmington Island",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Winder",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Winterville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Woodbine",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Woodstock",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Worth County",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Wrens",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Wrightsville",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Young Harris",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "Zebulon",
    country_code: "US",
    state_code: "GA",
  },
  {
    name: "‘Āhuimanu",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "‘Aiea",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "‘Ele‘ele",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "‘Ewa Beach",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "‘Ewa Gentry",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "‘Ewa Villages",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "‘Ōma‘o",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Ainaloa",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Anahola",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Captain Cook",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "East Honolulu",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Fern Acres",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Haiku-Pauwela",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hālawa",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hālawa Heights",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hale‘iwa",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hana",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hanamā‘ulu",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hanapēpē",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hanapēpē Heights",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hau‘ula",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hawaii County",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hawaiian Acres",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hawaiian Beaches",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hawaiian Ocean View",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hawaiian Paradise Park",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hāwī",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "He‘eia",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hickam Field",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hilo",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Hōlualoa",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Honalo",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Honaunau-Napoopoo",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Honoka‘a",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Honolulu",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Honolulu County",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Iroquois Point",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Ka‘a‘awa",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kā‘anapali",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kaanapali Landing",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kahalu‘u",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kahaluu-Keauhou",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kahuku",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kahului",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kailua",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kailua-Kona",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kalāheo",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kalaoa",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kalawao County",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kaneohe",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kapa‘a",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kapaau",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kapolei",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kauai County",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kaunakakai",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kea‘au",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kealakekua",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kekaha",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kēōkea",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kīhei",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kīlauea",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Ko Olina",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Koloa",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kualapu‘u",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kula",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Kurtistown",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Lā‘ie",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Lahaina",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Lanai City",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Lawai",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Leilani Estates",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Lihue",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Mā‘ili",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Mākaha",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Mākaha Valley",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Makakilo City",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Makawao",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Marine Corps Base Hawaii - MCBH",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Maui County",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Maunawili",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Mililani Town",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Mokulēia",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Mountain View",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Nānākuli",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Nanawale Estates",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Napili-Honokowai",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Ocean Pointe",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Orchidlands Estates",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Pāhala",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Paia",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Pāpa‘ikou",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Pearl City",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Pepeekeo",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Princeville",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Puhi",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Pukalani",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Punalu‘u",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Pupukea",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Royal Kunia",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Schofield Barracks",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Village Park",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Volcano",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Wahiawā",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waialua",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waianae",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waihee-Waiehu",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waikapū",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waikoloa",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Wailea",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Wailua",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Wailua Homesteads",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Wailuku",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waimalu",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waimanalo",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waimānalo Beach",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waimea",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Wainaku",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waipahu",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waipi‘o Acres",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Waipio",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Whitmore Village",
    country_code: "US",
    state_code: "HI",
  },
  {
    name: "Aberdeen",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Ada County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "American Falls",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Ammon",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Arco",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Ashton",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Bannock County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Bear Lake County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Benewah County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Bingham County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Blackfoot",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Blaine County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Boise",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Boise County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Bonner County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Bonners Ferry",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Bonneville County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Boundary County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Buhl",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Burley",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Butte County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Caldwell",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Camas County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Canyon County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Caribou County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Cascade",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Cassia County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Challis",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Chubbuck",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Clearwater County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Coeur d'Alene",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Council",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Custer County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Dalton Gardens",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Driggs",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Dubois",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Eagle",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Elmore County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Emmett",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Filer",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Fort Hall",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Fremont County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Fruitland",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Garden City",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Gem County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Glenns Ferry",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Gooding",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Gooding County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Grangeville",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Hailey",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Hansen",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Hayden",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Heyburn",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Hidden Spring",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Homedale",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Idaho City",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Idaho County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Idaho Falls",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Iona",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Jerome",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Jerome County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Kamiah",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Kellogg",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Ketchum",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Kimberly",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Kootenai County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Kuna",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Lapwai",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Latah County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Lemhi County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Lewis County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Lewiston",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Lewiston Orchards",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Malad City",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Marsing",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "McCall",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Meridian",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Middleton",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Minidoka County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Montpelier",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Moreland",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Moscow",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Mountain Home",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Murphy",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Nampa",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "New Plymouth",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Nez Perce County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Nezperce",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Oneida County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Orofino",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Osburn",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Owyhee County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Paris",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Parma",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Paul",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Payette",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Payette County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Pinehurst",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Plummer",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Pocatello",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Ponderay",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Post Falls",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Power County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Preston",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Priest River",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Rathdrum",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Rexburg",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Rigby",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Rupert",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Saint Anthony",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Saint Maries",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Salmon",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Sandpoint",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Shelley",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Shoshone",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Shoshone County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Soda Springs",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Spirit Lake",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Star",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Sugar City",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Sun Valley",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Teton County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Twin Falls",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Twin Falls County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Tyhee",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Ucon",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Valley County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Victor",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Wallace",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Weiser",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Wendell",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Wilder",
    country_code: "US",
    state_code: "ID",
  },
  {
    name: "Abingdon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Addison",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Albany Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Albers",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Albion",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Aledo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Alexander County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Algonquin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Alorton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Alsip",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Altamont",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Alton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Amboy",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Andalusia",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Anna",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Antioch",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Arcola",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Arlington Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Arthur",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ashburn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Assumption",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Astoria",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Atlanta",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Atwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Auburn Gresham",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Aurora",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Aviston",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Avondale",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bannockburn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Barrington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Barrington Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Barry",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bartlett",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bartonville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Batavia",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Beach Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Beardstown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Beckemeyer",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Beecher",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Belleville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bellwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Belmont Cragin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Belvidere",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bement",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Benld",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bensenville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Benton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Berkeley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Berwyn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bethalto",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bethany",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Big Rock",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bloomingdale",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bloomington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Blue Island",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Blue Mound",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bolingbrook",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bond County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Boone County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Boulder Hill",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bourbonnais",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bradley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Braidwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Breese",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bridgeview",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Brighton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Brighton Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Broadview",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Brookfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Brown County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Buffalo Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bull Valley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bunker Hill",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Burbank",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bureau County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Burnham",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Burr Ridge",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Bushnell",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Byron",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cahokia",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cairo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Calumet City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Calumet Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cambria",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cambridge",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Camp Point",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Capron",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carbon Cliff",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carbondale",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carlinville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carlyle",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carmi",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carol Stream",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carpentersville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carrier Mills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carterville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Carthage",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cary",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Casey",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Caseyville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cass County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Catlin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Central City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Centralia",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Centreville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cerro Gordo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Champaign",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Champaign County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Channahon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Channel Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Charleston",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chatham",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chatsworth",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chebanse",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chenoa",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cherry Valley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chicago",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chicago Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chicago Lawn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chicago Loop",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chicago Ridge",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chillicothe",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Chrisman",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Christian County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Christopher",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cicero",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Clarendon Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Clifton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Clinton County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Coal City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Coal Valley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cobden",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Colchester",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Coles County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Colfax",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Collinsville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Colona",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cook County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cortland",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Country Club Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Countryside",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Crainville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Crest Hill",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Crestwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Crete",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Creve Coeur",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Crystal Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Crystal Lawns",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cuba",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Cumberland County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Danvers",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Darien",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Davis Junction",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "De Soto",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "De Witt County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Deer Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Deerfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "DeKalb",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "DeKalb County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Delavan",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Depue",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Des Plaines",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Diamond",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Divernon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Dixmoor",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Dixon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Dolton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Douglas",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Downers Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Du Quoin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Dunlap",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "DuPage County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Dupo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Durand",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Dwight",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Earlville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "East Alton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "East Dubuque",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "East Dundee",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "East Garfield Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "East Hazel Crest",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "East Moline",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "East Peoria",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "East Saint Louis",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Edgar County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Edgewater",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Edinburg",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Edwards County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Edwardsville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Effingham",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Effingham County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "El Paso",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Elburn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Eldorado",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Elgin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Elizabethtown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Elk Grove Village",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Elmhurst",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Elmwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Elmwood Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Elwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Energy",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Englewood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Erie",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Eureka",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Evanston",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Evergreen Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fairbury",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fairmont",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fairmont City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fairview Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Farmer City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fisher",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Flanagan",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Flora",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Flossmoor",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ford County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ford Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Forest Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Forest Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Forrest",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Forreston",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Forsyth",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fox Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fox Lake Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fox River Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Frankfort",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Frankfort Square",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Franklin Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Freeburg",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Freeport",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fulton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Fulton County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gage Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gages Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Galena",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Galesburg",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gallatin County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Galva",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gardner",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Geneseo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Geneva",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Genoa",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Germantown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Germantown Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gibson City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gifford",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gilberts",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gillespie",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gilman",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Girard",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Glasford",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Glen Carbon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Glen Ellyn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Glencoe",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Glendale Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Glenview",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Glenwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Godfrey",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Golconda",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Goodings Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Goreville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Grand Boulevard",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Grandview",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Grandwood Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Granite City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Grant Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Granville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Grayslake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Grayville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Greater Grand Crossing",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Green Oaks",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Green Rock",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Greenup",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gridley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Griggsville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Grundy County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Gurnee",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hainesville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hamilton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hampshire",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hampton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hanna City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hanover Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hardin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hardin County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Harrisburg",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Harristown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Harvard",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Harvey",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Harwood Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Havana",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hawthorn Woods",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hazel Crest",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hebron",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Henderson County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hennepin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Henry",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Heritage Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Herrin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Herscher",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Heyworth",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hickory Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Highland",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Highland Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Highwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hillcrest",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hillsboro",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hillside",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hinckley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hinsdale",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hodgkins",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hoffman Estates",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Holiday Shores",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Homer",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Homer Glen",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hometown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Homewood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hoopeston",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Huntley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Hyde Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ina",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Indian Head Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ingalls Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Inverness",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Iroquois County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Irving Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Island Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Itasca",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Jacksonville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Jasper County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Jerome",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Jersey County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Jerseyville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Jo Daviess County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Johnsburg",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Johnston City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Joliet",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Jonesboro",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Justice",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kane County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kankakee",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kankakee County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kendall County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kenilworth",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kenwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kewanee",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kildeer",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kincaid",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Kirkland",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Knollwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Knox County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Knoxville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "La Grange",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "La Grange Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "La Harpe",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "La Salle",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lacon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ladd",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake Barrington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake Bluff",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake Camelot",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake Catherine",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake Forest",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake Holiday",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake in the Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake of the Woods",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake Summerset",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake Villa",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lake Zurich",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lakemoor",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lakewood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lakewood Shores",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lanark",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lansing",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "LaSalle County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lawrenceville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Le Roy",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Leland Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lemont",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lena",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lewistown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Libertyville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lily Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Limestone",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lincoln Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lincoln Square",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lincolnshire",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lincolnwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lindenhurst",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lisle",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Litchfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Livingston County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lockport",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Logan Square",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lombard",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Long Creek",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Long Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Long Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Louisville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Loves Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lovington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lower West Side",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lynwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Lyons",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Machesney Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mackinaw",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Macomb",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Macon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Macon County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Macoupin County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mahomet",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Malta",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Manhattan",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Manito",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Manteno",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Maple Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Marengo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Marissa",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Markham",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Maroa",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Marquette Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Marseilles",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Marshall",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Martinsville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Maryville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mascoutah",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mason City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mason County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Massac County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Matteson",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mattoon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Maywood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "McCullom Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "McDonough County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "McHenry",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "McHenry County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "McKinley Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "McLean County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "McLeansboro",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Melrose Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Menard County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mendota",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mercer County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Meredosia",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Merrionette Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Metamora",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Metropolis",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Midlothian",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Milan",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Millstadt",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Minier",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Minonk",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Minooka",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mitchell",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mokena",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Moline",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Momence",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Monee",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Monmouth",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Montgomery",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Morgan Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Morris",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Morrison",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Morrisonville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Morton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Morton Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Moultrie County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mound City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Carmel",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Carroll",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Greenwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Morris",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Olive",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Prospect",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Pulaski",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Sterling",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mount Zion",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Moweaqua",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Mundelein",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Murphysboro",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Naperville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Nashville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Nauvoo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Near North Side",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Near South Side",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Neoga",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "New Athens",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "New Baden",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "New Berlin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "New City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "New Lenox",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Newark",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Niles",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Nokomis",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Normal",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Norridge",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Norris City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "North Aurora",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "North Barrington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "North Center",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "North Chicago",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "North Lawndale",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "North Pekin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "North Peoria",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "North Riverside",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Northbrook",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Northfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Northlake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "O'Fallon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oak Brook",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oak Forest",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oak Lawn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oak Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oakbrook Terrace",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oakwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oakwood Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oblong",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Odin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ogle County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oglesby",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Okawville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Olney",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Olympia Fields",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Onarga",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oquawka",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oregon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Orion",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Orland Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Orland Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Oswego",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ottawa",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Palatine",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Palestine",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Palos Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Palos Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Palos Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pana",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Paris",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Park City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Park Forest",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Park Ridge",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pawnee",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Paxton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Payson",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pecatonica",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pekin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Peoria",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Peoria County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Peoria Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Peotone",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Peru",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Petersburg",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Philo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Phoenix",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Piatt County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pinckneyville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pingree Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pistakee Highlands",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pittsfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Plainfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Plano",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Polo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pontiac",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pontoon Beach",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pope County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Poplar Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Port Barrington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Port Byron",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Portage Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Posen",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Prairie Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Prestbury",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Preston Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Princeville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Prophetstown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Prospect Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Pulaski County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Putnam County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Quincy",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Ramsey",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Randolph County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rantoul",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Red Bud",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Richland County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Richton Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "River Forest",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "River Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Riverdale",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Riverton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Riverwoods",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Roanoke",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Robbins",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Robinson",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rochelle",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rock Falls",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rock Island",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rock Island County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rockdale",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rockford",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rockton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rogers Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rolling Meadows",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rome",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Romeoville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Roodhouse",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Roscoe",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Roselle",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rosemont",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rosewood Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rosiclare",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rossville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Round Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Round Lake Beach",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Round Lake Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Round Lake Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Roxana",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Royalton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Rushville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Saint Anne",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Saint Charles",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Saint Clair County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Saint Elmo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Saint Jacob",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Saint Joseph",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Saline County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sandoval",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sandwich",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sangamon County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sauk Village",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Savanna",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Savoy",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Schaumburg",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Schiller Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Schuyler County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Scott Air Force Base",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Seneca",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sesser",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Shawneetown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Shelby County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Shelbyville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sheldon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sheridan",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sherman",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Shiloh",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Shorewood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sidney",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Silvis",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Skokie",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sleepy Hollow",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Smithton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Somonauk",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Barrington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Beloit",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Chicago",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Chicago Heights",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Elgin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Holland",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Jacksonville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Lawndale",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Pekin",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Roxana",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "South Shore",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Southern View",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sparta",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Spring Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Spring Valley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Stark County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Staunton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Steeleville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Steger",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Stephenson County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sterling",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Stickney",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Stillman Valley",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Stockton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Stone Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Streamwood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Streator",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sugar Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sullivan",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Summit",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sumner",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Swansea",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Sycamore",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Taylorville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Tazewell County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Teutopolis",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "The Galena Territory",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Third Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Thomasboro",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Thornton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Tilton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Tinley Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Toledo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Tolono",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Toluca",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Toulon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Tower Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Tremont",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Tuscola",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Twin Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "University Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Upper Alton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Uptown",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Urbana",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Valmeyer",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Vandalia",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Venetian Village",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Venice",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Vermilion County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Vernon Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Vienna",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Villa Grove",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Villa Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Village of Campton Hills",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Virden",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Virginia",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Volo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wabash County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wadsworth",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Walnut",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wamac",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Warrensburg",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Warrenville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Warsaw",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wasco",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Washburn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Washington Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Waterloo",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Waterman",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Watseka",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wauconda",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Waukegan",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Waverly",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wayne",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wayne City",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Chicago",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Dundee",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Elsdon",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Englewood",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Frankfort",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Garfield Park",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Lawn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Peoria",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Ridge",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "West Town",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Westchester",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Western Springs",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Westmont",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Westville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wheaton",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wheeling",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "White County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "White Hall",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Whiteside County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Will County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Williamson County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Williamsville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Willow Springs",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Willowbrook",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wilmette",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wilmington",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Winfield",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Winnebago",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Winnebago County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Winnetka",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Winthrop Harbor",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wonder Lake",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wood Dale",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wood River",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Woodford County",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Woodlawn",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Woodridge",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Woodstock",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Worden",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Worth",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Wyoming",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Yorkville",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Zeigler",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Zion",
    country_code: "US",
    state_code: "IL",
  },
  {
    name: "Aberdeen",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Akron",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Albion",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Alexandria",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Allen County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Anderson",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Andrews",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Angola",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Arcadia",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Argos",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Attica",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Aurora",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Austin",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Avilla",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Avon",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bargersville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bartholomew County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bass Lake",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Batesville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Battle Ground",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Beech Grove",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Benton County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Berne",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bicknell",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Blackford County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bloomfield",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bloomington",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bluffton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Boone County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Boonville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bourbon",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Brazil",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bremen",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bright",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Broad Ripple",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Brooklyn",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Brookston",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Brookville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Brown County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Brownsburg",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Brownstown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Burns Harbor",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Butler",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Cambridge City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Cannelton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Carmel",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Cass County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Cayuga",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Cedar Lake",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Chandler",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Charlestown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Chesterfield",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Chesterton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Churubusco",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Cicero",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Clarksville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Clermont",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Clinton County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Cloverdale",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Columbia City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Connersville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Converse",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Cordry Sweetwater Lakes",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Corydon",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Country Squire Lakes",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Covington",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Crawfordsville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Crothersville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Crown Point",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Culver",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Cumberland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Dale",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Daleville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Darmstadt",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Daviess County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Dearborn County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Decatur County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "DeKalb County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Delaware County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Delphi",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "DeMotte",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Dillsboro",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Dubois County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Dunkirk",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Dunlap",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Dyer",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "East Chicago",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Eaton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Edgewood",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Edinburgh",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Elkhart",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Elkhart County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Ellettsville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Elwood",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "English",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Evansville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fairfield Heights",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fairmount",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fairview Park",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Farmersburg",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Farmland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Ferdinand",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fish Lake",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fishers",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Flora",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Floyd County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fort Branch",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fort Wayne",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fortville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fountain County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fowler",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Frankfort",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Frankton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fremont",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "French Lick",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Fulton County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Galena",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Galveston",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Garrett",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Gary",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Gas City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Geneva",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Gibson County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Goodland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Goshen",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Grabill",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Granger",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Greencastle",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Greendale",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Greensburg",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Greentown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Greenwood",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Griffith",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Grissom Air Force Base",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Gulivoire Park",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hagerstown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hamilton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hammond",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hanover",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Harlan",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Harrison County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hartford City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Haubstadt",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hebron",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hendricks County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Henryville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Heritage Lake",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hidden Valley",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Highland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hobart",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hope",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Howard County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Hudson Lake",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Huntertown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Huntingburg",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Huntington",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Huntington County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Indian Heights",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Indianapolis",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Ingalls",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Jasonville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Jasper",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Jasper County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Jay County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Jeffersonville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Jennings County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Jonesboro",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Kendallville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Kentland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Kingsford Heights",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Knightstown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Knox",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Knox County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Kokomo",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Koontz Lake",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Kosciusko County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Kouts",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "La Porte",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lafayette",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lagrange",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "LaGrange County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lake Dalecarlia",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lake Station",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lakes of the Four Seasons",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lapel",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "LaPorte",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "LaPorte County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lawrence",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lawrenceburg",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Leo-Cedarville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Ligonier",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Linton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Logansport",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Long Beach",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Loogootee",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lowell",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Lynn",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Markle",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Martin County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Martinsville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "McCordsville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Melody Hill",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Meridian Hills",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Merrillville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Miami County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Michigan City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Middlebury",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Milan",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Mishawaka",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Mitchell",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Monon",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Monroeville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Monrovia",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Montpelier",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Mooresville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Morocco",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Morristown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Mulberry",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Muncie",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Munster",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Nappanee",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Nashville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "New Albany",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "New Carlisle",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "New Castle",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "New Chicago",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "New Haven",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "New Palestine",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "New Paris",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "New Pekin",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "New Whiteland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Newburgh",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Newton County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Noble County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Noblesville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "North Judson",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "North Liberty",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "North Madison",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "North Manchester",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "North Terre Haute",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "North Vernon",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "North Webster",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Notre Dame",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Oak Park",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Oakland City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Odon",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Ogden Dunes",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Ohio County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Oolitic",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Orange County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Orleans",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Osceola",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Osgood",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Ossian",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Otterbein",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Owen County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Owensville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Paoli",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Parke County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Parker City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Pendleton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Peru",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Petersburg",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Pierceton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Pittsboro",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Plainfield",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Portage",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Porter",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Porter County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Portland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Posey County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Poseyville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Princes Lakes",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Pulaski County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Putnam County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Randolph County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Redkey",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Remington",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Rensselaer",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Ripley County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Rising Sun",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Roanoke",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Rockport",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Rockville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Rome City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Roselawn",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Rossville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Rush County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Rushville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Russiaville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Saint John",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Saint Joseph County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Saint Paul",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Santa Claus",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Schererville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Scottsburg",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Seelyville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Sellersburg",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Seymour",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Shadeland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Shelburn",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Shelby County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Shelbyville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Sheridan",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Shoals",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Shorewood Forest",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Simonton Lake",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Smithville-Sanders",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "South Bend",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "South Haven",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "South Whitley",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Southport",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Speedway",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Spencer",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Spencer County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Starke County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Steuben County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Sullivan",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Sullivan County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Sunman",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Sweetser",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Switzerland County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Syracuse",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Tell City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Terre Haute",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Thorntown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Tippecanoe County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Tipton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Tipton County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Topeka",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Trafalgar",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Trail Creek",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Tri-Lakes",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Union City",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Upland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Valparaiso",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Vanderburgh County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Veedersburg",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Vermillion County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Versailles",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Vevay",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Vigo County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Vincennes",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Wabash",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Wabash County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Wakarusa",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Walkerton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Walton",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Wanatah",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Warren Park",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Warrick County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Warsaw",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Waterloo",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Wells County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "West Lafayette",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "West Terre Haute",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Westfield",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Westport",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Westville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "White County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Whiteland",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Whitestown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Whiting",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Whitley County",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Williamsport",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Winamac",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Winfield",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Winona Lake",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Wolcottville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Woodburn",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Worthington",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Yorktown",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Zionsville",
    country_code: "US",
    state_code: "IN",
  },
  {
    name: "Ackley",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Adair County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Adel",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Akron",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Albia",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Algona",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Allamakee County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Allison",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Alta",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Alton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Altoona",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Ames",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Anamosa",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Ankeny",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Aplington",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Appanoose County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Arnolds Park",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Asbury",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Atkins",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Atlantic",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Audubon",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Audubon County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Avoca",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Baxter",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Belle Plaine",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Belmond",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Benton County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Bettendorf",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Black Hawk County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Bloomfield",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Blue Grass",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Bondurant",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Boone",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Boone County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Bremer County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Britt",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Brooklyn",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Buchanan County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Buena Vista County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Buffalo (historical)",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Butler County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Camanche",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Carlisle",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Carroll",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Carter Lake",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Cascade",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Cass County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Cedar County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Cedar Falls",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Cedar Rapids",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Center Point",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Central City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Cerro Gordo County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Chariton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Charles City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Cherokee",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Cherokee County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Chickasaw County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clarinda",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clarion",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clarke County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clarksville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clayton County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clear Lake",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clinton County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Clive",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Colfax",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Columbus Junction",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Conrad",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Coon Rapids",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Coralville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Corning",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Corydon",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Council Bluffs",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Cresco",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Creston",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Dakota City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Dallas Center",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Dallas County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Davenport",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Davis County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "De Soto",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "De Witt",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Decatur County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Decorah",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Delaware County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Denison",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Denver",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Des Moines",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Des Moines County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Dickinson County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Dike",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Dubuque",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Dubuque County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Durant",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Dyersville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Dysart",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Eagle Grove",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Earlham",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Eddyville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Eldora",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Eldridge",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Elk Run Heights",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Elkader",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Ely",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Emmet County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Emmetsburg",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Epworth",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Estherville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Evansdale",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Fairbank",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Fairfax",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Farley",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Fayette",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Floyd County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Forest City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Fort Dodge",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Fort Madison",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Fremont County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Garner",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "George",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Gilbert",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Glenwood",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Glidden",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Granger",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Greene",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Grimes",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Grinnell",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Grundy Center",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Grundy County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Guthrie Center",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Guthrie County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Guttenberg",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hamburg",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hampton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hardin County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Harlan",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Harrison County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hartley",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hawarden",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hiawatha",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Holstein",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Howard County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Hull",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Humboldt",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Humboldt County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Huxley",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Ida County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Ida Grove",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Indianola",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Iowa City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Iowa County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Iowa Falls",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Jasper County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Jesup",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Jewell",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Johnston",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Jones County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Kalona",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Keokuk",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Keokuk County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Keosauqua",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Kingsley",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Knoxville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Kossuth County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "La Porte City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lake City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lake Mills",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lake Panorama",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lake Park",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lake View",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lamoni",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Laurens",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Le Claire",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Le Mars",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lenox",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Leon",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Linn County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lisbon",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Logan",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lone Tree",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Louisa County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lucas County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Lyon County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Madrid",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Maharishi Vedic City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mahaska County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Malvern",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Manly",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Manning",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Manson",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mapleton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Maquoketa",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Marcus",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Marengo",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Marshalltown",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mason City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mechanicsville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mediapolis",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Melcher-Dallas",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mills County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Missouri Valley",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mitchell County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mitchellville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Monona",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Monona County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Montezuma",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mount Ayr",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mount Pleasant",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Moville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Muscatine",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Muscatine County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Nashua",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Nevada",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "New Hampton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "New London",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "New Sharon",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Nora Springs",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "North English",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "North Liberty",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Northwood",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Norwalk",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "O'Brien County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Oelwein",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Ogden",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Onawa",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Orange City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Osage",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Osceola",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Osceola County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Oskaloosa",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Ottumwa",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Page County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Palo",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Palo Alto County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Panora",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Park View",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Parkersburg",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Paullina",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Pella",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Peosta",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Perry",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Pleasant Hill",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Pleasantville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Plymouth County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Pocahontas",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Pocahontas County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Polk City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Postville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Pottawattamie County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Poweshiek County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Prairie City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Preston",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Primghar",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Red Oak",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Reinbeck",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Remsen",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Ringgold County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Robins",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Rock Rapids",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Rock Valley",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Rockwell",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Rockwell City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Roland",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sac City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sac County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Saint Ansgar",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sanborn",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Saylorville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sergeant Bluff",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sheffield",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Shelby County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sheldon",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Shell Rock",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Shenandoah",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sibley",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sidney",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sigourney",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sioux Center",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sioux City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sioux County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Slater",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Solon",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Spencer",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Spirit Lake",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Springville",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "State Center",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Storm Lake",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Story City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Story County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Strawberry Point",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Stuart",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Sumner",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Tama",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Tama County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Taylor County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Tiffin",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Tipton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Toledo",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Traer",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Tripoli",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "University Heights",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Urbana",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Urbandale",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Van Buren County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Van Meter",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Villisca",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Vinton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Walcott",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Walford",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Wapello",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Wapello County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Waterloo",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Waukee",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Waukon",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Waverly",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Webster City",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Webster County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Wellman",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "West Branch",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "West Burlington",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "West Des Moines",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "West Liberty",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "West Union",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Williamsburg",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Wilton",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Windsor Heights",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Winfield",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Winnebago County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Winneshiek County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Winterset",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Woodbine",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Woodbury County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Woodward",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Worth County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Wright County",
    country_code: "US",
    state_code: "IA",
  },
  {
    name: "Abilene",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Allen County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Alma",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Altamont",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Anderson County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Andover",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Anthony",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Arkansas City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Arma",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Atchison",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Atchison County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Atwood",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Augusta",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Baldwin City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Barber County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Barton County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Basehor",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Baxter Springs",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Bellaire",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Belle Plaine",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Belleville",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Beloit",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Bonner Springs",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Bourbon County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Brown County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Buhler",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Butler County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Caldwell",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Caney",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Carbondale",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Chanute",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Chapman",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Chase County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Chautauqua County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Cheney",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Cherokee County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Cherryvale",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Chetopa",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Cheyenne County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Cimarron",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Clay Center",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Clearwater",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Cloud County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Coffey County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Coffeyville",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Colby",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Coldwater",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Colwich",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Comanche County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Concordia",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Conway Springs",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Cottonwood Falls",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Council Grove",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Cowley County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "De Soto",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Decatur County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Derby",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Dickinson County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Dighton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Dodge City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Doniphan County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Douglass",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Edgerton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Edwards County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Edwardsville",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "El Dorado",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Elk County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Elkhart",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ellinwood",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ellis",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ellis County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ellsworth",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ellsworth County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Elwood",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Emporia",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Erie",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Eudora",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Eureka",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Fairway",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Finney County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ford County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Fort Riley North",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Fort Scott",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Fredonia",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Frontenac",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Galena",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Garden City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Gardner",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Garnett",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Geary County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Girard",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Goddard",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Goodland",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Gove",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Gove County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Graham County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Grandview Plaza",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Gray County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Great Bend",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Greeley County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Greensburg",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Greenwood County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Halstead",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Harper",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Harper County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Harvey County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Haskell County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Haven",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hays",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Haysville",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Herington",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hesston",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hiawatha",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Highland",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hill City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hillsboro",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hodgeman County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hoisington",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Holcomb",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Holton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Horton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Howard",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hoxie",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hugoton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Humboldt",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Hutchinson",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Inman",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Iola",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Jetmore",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Jewell County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Johnson",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Junction City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Kansas City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Kearny County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Kechi",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Kingman",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Kingman County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Kinsley",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Kiowa",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Kiowa County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "La Crosse",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "La Cygne",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Labette County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lakin",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lane County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lansing",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Larned",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lawrence",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Leavenworth",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Leavenworth County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Leawood",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lenexa",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Leoti",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Liberal",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lindsborg",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Linn County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Louisburg",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lyndon",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lyon County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Lyons",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Maize",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Manhattan",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Mankato",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Marysville",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "McConnell AFB",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "McPherson",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "McPherson County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Meade",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Meade County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Medicine Lodge",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Merriam",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Miami County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Minneapolis",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Mission",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Mission Hills",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Mitchell County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Morris County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Morton County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Mound City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Moundridge",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Mulvane",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Nemaha County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Neodesha",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Neosho County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ness City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ness County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "New Century, KS",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Nickerson",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "North Newton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Norton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Norton County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Oakley",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Oberlin",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ogden",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Olathe",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Osage City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Osage County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Osawatomie",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Osborne",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Osborne County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Oskaloosa",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Oswego",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ottawa",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ottawa County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Overbrook",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Overland Park",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Paola",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Park City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Parsons",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Pawnee County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Peabody",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Phillips County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Phillipsburg",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Pittsburg",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Plains",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Plainville",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Pleasanton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Pottawatomie County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Prairie Village",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Pratt",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Pratt County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Rawlins County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Reno County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Republic County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Rice County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Riley County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Roeland Park",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Rooks County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Rose Hill",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Rossville",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Rush County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Russell",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Russell County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sabetha",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Saint Francis",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Saint John",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Saint Marys",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Salina",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Saline County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Satanta",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Scott City",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sedan",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sedgwick",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sedgwick County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Seneca",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Seward County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sharon Springs",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Shawnee",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Shawnee County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sheridan County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sherman County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Silver Lake",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Smith Center",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Smith County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Solomon",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "South Hutchinson",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Spring Hill",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Stafford County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Stanton County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sterling",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Stevens County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Stockton",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sublette",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Sumner County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Syracuse",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Thomas County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Tonganoxie",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Topeka",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Towanda",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Trego County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Tribune",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Ulysses",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Valley Center",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Valley Falls",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Victoria",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wabaunsee County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "WaKeeney",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wallace County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wamego",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wathena",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wellington",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wellsville",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Westmoreland",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Westwood",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wichita",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wichita County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wilson County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Winfield",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Woodson County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Wyandotte County",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Yates Center",
    country_code: "US",
    state_code: "KS",
  },
  {
    name: "Adair County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Alexandria",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Allen County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Anchorage",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Anderson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Annville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Audubon Park",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Augusta",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Ballard County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Barbourmeade",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Barbourville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bardstown",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bardwell",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Barren County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bath County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Beattyville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Beaver Dam",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Beechwood Village",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bell County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Benton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Berea",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bloomfield",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Boone County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Booneville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bourbon County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bowling Green",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Boyd County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Boyle County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bracken County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Brandenburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Breathitt County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Breckinridge Center",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Breckinridge County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Brodhead",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Brooks",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Brooksville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Brownsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Buckner",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Buechel",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Bullitt County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Burkesville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Butler County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Cadiz",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Caldwell County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Calhoun",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Calloway County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Calvert City",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Camargo",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Campbell County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Campbellsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Campton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Carlisle",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Carlisle County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Carter County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Casey County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Catlettsburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Cave City",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Central City",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Christian County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Claryville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Clay",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Clay City",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Clinton County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Cloverport",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Coal Run Village",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Cold Spring",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Coldstream",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Corbin",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Covington",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Crescent Springs",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Crestview Hills",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Crestwood",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Crittenden",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Crittenden County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Cumberland",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Cumberland County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Cynthiana",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Daviess County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Dawson Springs",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Dixon",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Doe Valley",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Douglass Hills",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Dry Ridge",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Earlington",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Eddyville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Edgewood",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Edmonson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Edmonton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Elizabethtown",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Elk Creek",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Elkfork",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Elkton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Elliott County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Elsmere",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Eminence",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Erlanger",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Estill County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fairdale",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Falmouth",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Farley",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fern Creek",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Flatwoods",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fleming County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Flemingsburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Floyd County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fort Campbell North",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fort Knox",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fort Mitchell",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fort Thomas",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fort Wright",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Francisville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Frankfort",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Frenchburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fulton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Fulton County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Gallatin County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Garrard County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Glasgow",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Graves County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Graymoor-Devondale",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Grayson",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Grayson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Green County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Greensburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Greenup",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Greenup County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Guthrie",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hardin County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hardinsburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Harlan",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Harlan County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Harrison County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Harrodsburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hart County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hawesville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hazard",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hebron",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hebron Estates",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Henderson",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Henderson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hendron",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Heritage Creek",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hickman",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hickman County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Highland Heights",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Highview",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hillview",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hindman",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hodgenville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hopkins County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hopkinsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Horse Cave",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hurstbourne",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hurstbourne Acres",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Hyden",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Indian Hills",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Indian Hills Cherokee Section",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Inez",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Ironville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Irvine",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Irvington",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Jamestown",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Jeffersontown",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Jeffersonville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Jenkins",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Jessamine County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Junction City",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Kenton County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Knott County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Knottsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Knox County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "La Center",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "La Grange",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lakeside Park",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Larue County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Laurel County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lawrenceburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lebanon Junction",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Ledbetter",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Leitchfield",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Leslie County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Letcher County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lewis County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lewisport",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lexington-Fayette",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Livermore",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Livingston County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "London",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Louisa",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Louisville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Ludlow",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lyndon",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Lyon County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Madisonville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Magoffin County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Martin County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Mason County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Masonville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Massac",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Mayfield",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Maysville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "McCracken County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "McCreary County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "McKee",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "McLean County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Meade County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Meads",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Menifee County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Mercer County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Metcalfe County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Middlesboro",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Midway",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Morehead",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Morganfield",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Morgantown",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Mount Olivet",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Mount Sterling",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Mount Washington",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Muhlenberg County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Munfordville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Murray",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Nelson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "New Castle",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Newburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Nicholas County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Nicholasville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "North Corbin",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Northfield",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Nortonville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Oak Grove",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Oakbrook",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Ohio County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Okolona",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Oldham County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Olive Hill",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Orchard Grass Hills",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Owen County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Owensboro",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Owenton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Owingsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Owsley County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Paducah",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Paintsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Paris",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Park Hills",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Pendleton County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Pewee Valley",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Pikeville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Pine Knot",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Pineville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Pioneer Village",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Plano",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Pleasure Ridge Park",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Powell County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Prestonsburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Prospect",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Providence",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Pulaski County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Raceland",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Radcliff",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Reidland",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Robertson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Rockcastle County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Rowan County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Russell",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Russell County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Russell Springs",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Russellville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Ryland Heights",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Saint Dennis",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Saint Matthews",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Saint Regis Park",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Salyersville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Sandy Hook",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Scottsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Sebree",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Shelby County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Shelbyville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Shepherdsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Shively",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Silver Grove",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Simpson County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Simpsonville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Smithland",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Somerset",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "South Shore",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Southgate",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Spencer County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Stanford",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Stanton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Stearns",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Sturgis",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Taylor County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Taylor Mill",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Taylorsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Todd County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Tompkinsville",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Trigg County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Trimble County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Union",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Valley Station",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Van Lear",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Vanceburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Verona",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Versailles",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Villa Hills",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Vine Grove",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Walton",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Warsaw",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Watterson Park",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Webster County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "West Buechel",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "West Liberty",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Westwood",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Whitesburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Whitley City",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Whitley County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Wickliffe",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Wilder",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Williamsburg",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Williamstown",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Wilmore",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Windy Hills",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Wolfe County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Woodford County",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Worthington",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Worthington Hills",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Wurtland",
    country_code: "US",
    state_code: "KY",
  },
  {
    name: "Abbeville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Abita Springs",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Acadia Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Addis",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Alexandria",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Allen Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ama",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Amelia",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Amite",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Arabi",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Arcadia",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Arnaudville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ascension Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Assumption Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Avondale",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Avoyelles Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Baker",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Baldwin",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ball",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Banks Springs",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Barataria",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Basile",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bastrop",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Baton Rouge",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bawcomville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bayou Cane",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bayou Gauche",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bayou Vista",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Beauregard Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Belle Chasse",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Belle Rose",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Benton",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bernice",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Berwick",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bienville Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Blanchard",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bogalusa",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bossier City",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bossier Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bourg",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Boutte",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Breaux Bridge",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bridge City",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Broussard",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Brownsfield",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Brownsville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Brusly",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Bunkie",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Caddo Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Cade",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Calcasieu Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Caldwell Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Cameron",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Cameron Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Campti",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Carencro",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Carlyss",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Carville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Catahoula",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Catahoula Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Cecilia",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Central",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Chackbay",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Chalmette",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Charenton",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Chauvin",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Church Point",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Claiborne",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Claiborne Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Clarks",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Colfax",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Concordia Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Convent",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Cottonport",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Coushatta",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Covington",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Crowley",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Cullen",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Cut Off",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "De Soto Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Delcambre",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Delhi",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Denham Springs",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "DeQuincy",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "DeRidder",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Des Allemands",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Destrehan",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Deville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Donaldsonville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Dulac",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Duson",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "East Baton Rouge Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "East Carroll Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "East Feliciana Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Eastwood",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Eden Isle",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Edgard",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Elmwood",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Elton",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Erath",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Erwinville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Estelle",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Eunice",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Evangeline Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Farmerville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ferriday",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Fort Polk North",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Fort Polk South",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Franklin Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Franklinton",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "French Settlement",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Galliano",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Gardere",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Garyville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Glenmora",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Golden Meadow",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Gonzales",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Grambling",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Gramercy",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Grand Isle",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Grand Point",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Grant Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Gray",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Greensburg",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Greenwood",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Gretna",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Gueydan",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Hackberry",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Hahnville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Hammond",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Harahan",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Harrisonburg",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Harvey",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Haughton",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Haynesville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Henderson",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Homer",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Houma",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Iberia Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Iberville Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Inniswold",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Iota",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Iowa",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jackson Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jean Lafitte",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jeanerette",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jefferson Davis Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jefferson Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jena",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jennings",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jonesboro",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Jonesville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Kaplan",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Kenner",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Kentwood",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Killian",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Kinder",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Krotz Springs",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "La Salle Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Labadieville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lacombe",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lafayette",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lafayette Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lafourche Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lake Arthur",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lake Charles",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lake Providence",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lakeshore",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Laplace",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Larose",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lawtell",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lecompte",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Leesville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Leonville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lincoln Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Livingston",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Livingston Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Livonia",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lockport",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lockport Heights",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Logansport",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Luling",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Lutcher",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Madison Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Mamou",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Mandeville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Mansfield",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Mansura",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Many",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Maringouin",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Marksville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Marrero",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Mathews",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Maurice",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Melville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Meraux",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Merrydale",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Merryville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Metairie",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Metairie Terrace",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Midway",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Minden",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Minorca",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Montegut",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Montz",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Morehouse Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Morgan City",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Moss Bluff",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Napoleonville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Natalbany",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Natchitoches",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Natchitoches Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "New Iberia",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "New Llano",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "New Orleans",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "New Roads",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "New Sarpy",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Newellton",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Norco",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "North Vacherie",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Oak Grove",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Oak Hills Place",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Oakdale",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Oberlin",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Old Jefferson",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Olla",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Opelousas",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Orleans Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ossun",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ouachita Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Paradis",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Patterson",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Paulina",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Pearl River",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Pierre Part",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Pine Prairie",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Pineville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Plaquemine",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Plaquemines Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Pointe Coupee Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ponchatoula",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Port Allen",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Port Barre",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Port Sulphur",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Poydras",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Prairieville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Presquille",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Prien",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Raceland",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Rapides Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Rayne",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Rayville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Red Chute",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Red River Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Reserve",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Richland Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Richwood",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ringgold",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "River Ridge",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Roseland",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Rosepine",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ruston",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Sabine Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Bernard Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Charles Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Francisville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Gabriel",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Helena Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint James Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint John the Baptist Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Joseph",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Landry Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Martin Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Martinville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Mary Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Rose",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Saint Tammany Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Schriever",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Scott",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Shenandoah",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Shreveport",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Sibley",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Simmesport",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Slidell",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Sorrento",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "South Vacherie",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Springhill",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Sterlington",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Stonewall",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Sulphur",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Sunset",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Supreme",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Swartz",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Tallulah",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Tangipahoa Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Tensas Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Terrebonne Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Terrytown",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Thibodaux",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Timberlane",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Union Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Urania",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Vermilion Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Vernon Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Vidalia",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Vienna Bend",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Village Saint George",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Ville Platte",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Vinton",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Violet",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Vivian",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Waggaman",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Walker",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Washington Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Watson",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Webster Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Welsh",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "West Baton Rouge Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "West Carroll Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "West Feliciana Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "West Ferriday",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "West Monroe",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Westlake",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Westminster",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Westwego",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "White Castle",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Winn Parish",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Winnfield",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Winnsboro",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Woodmere",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Woodworth",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Youngsville",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Zachary",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Zwolle",
    country_code: "US",
    state_code: "LA",
  },
  {
    name: "Acton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Addison",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Albion",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Alfred",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Androscoggin County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Appleton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Aroostook County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Arundel",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Augusta",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Bangor",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Bar Harbor",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Bath",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Belfast",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Belgrade",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Benton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Berwick",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Bethel",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Biddeford",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Boothbay",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Boothbay Harbor",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Bradford",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Bradley",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Brewer",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Bridgton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Brooks",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Brownfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Brownville",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Brunswick",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Buckfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Bucksport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Burnham",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Buxton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Calais",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Canaan",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Cape Neddick",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Caribou",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Carmel",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Castine",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Charleston",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Chelsea",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Cherryfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Chesterville",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "China",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Chisholm",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Corinna",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Cornish",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Cornville",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Cumberland Center",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Cumberland County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Cushing",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Damariscotta",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Dedham",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Deer Isle",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Denmark",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Dexter",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Dixfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Dixmont",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Dover-Foxcroft",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "East Machias",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "East Millinocket",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Easton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Eastport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Eddington",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Edgecomb",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Eliot",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Ellsworth",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Enfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Etna",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Falmouth",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Falmouth Foreside",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Farmingdale",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Fayette",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Fort Fairfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Fort Kent",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Frankfort",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Freeport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Frenchville",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Friendship",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Fryeburg",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Gardiner",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Garland",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Gorham",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Gouldsboro",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Greenbush",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Greene",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hallowell",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hampden",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hancock",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Harpswell Center",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Harrison",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hebron",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hermon",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hiram",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hodgdon",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Holden",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hollis Center",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hope",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Houlton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Howland",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Jay",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Jonesport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Kenduskeag",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Kennebec County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Kennebunk",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Kennebunkport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Kingfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Kittery",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Kittery Point",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Knox County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Lake Arrowhead",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Leeds",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Levant",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Lewiston",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Limerick",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Limestone",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Limington",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Lincolnville",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Lisbon",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Lisbon Falls",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Livermore",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Livermore Falls",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Lovell",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Machias",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Machiasport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Madawaska",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Mechanic Falls",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Medway",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Mexico",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Milbridge",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Millinocket",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Milo",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Minot",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Monmouth",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "New Gloucester",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "New Sharon",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Newfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Nobleboro",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Norridgewock",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "North Bath",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "North Berwick",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "North Windham",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Northport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Norway",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Ogunquit",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Old Orchard Beach",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Old Town",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Orland",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Orono",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Orrington",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Owls Head",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Oxford County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Palermo",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Palmyra",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Paris",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Parsonsfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Patten",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Penobscot",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Penobscot County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Peru",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Phillips",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Phippsburg",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Piscataquis County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Pittsfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Pittston",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Poland",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Porter",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Portland",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Presque Isle",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Randolph",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Raymond",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Readfield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Rockland",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Rockport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Rome",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Rumford",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Sabattus",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Saco",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Sagadahoc County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Saint Albans",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Saint George",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Sanford",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Sangerville",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Scarborough",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Searsmont",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Sedgwick",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Shapleigh",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Sidney",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Skowhegan",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Somerset County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "South Berwick",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "South Eliot",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "South Paris",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "South Portland",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "South Portland Gardens",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "South Sanford",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "South Thomaston",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "South Windham",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Springvale",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Steep Falls",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Stetson",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Steuben",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Stockton Springs",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Stonington",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Strong",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Sullivan",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Surry",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Swanville",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Thomaston",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Topsham",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Tremont",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Turner",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Union",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Van Buren",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Vassalboro",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Veazie",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Vinalhaven",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Waldo County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Waldoboro",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Waterboro",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Waterville",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Wayne",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Wells Beach Station",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "West Kennebunk",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "West Paris",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "West Scarborough",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Westbrook",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Whitefield",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Wilton",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Winslow",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Winterport",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Winthrop",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Wiscasset",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Woodstock",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Woolwich",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Yarmouth",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "York Beach",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "York County",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "York Harbor",
    country_code: "US",
    state_code: "ME",
  },
  {
    name: "Aberdeen",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Aberdeen Proving Ground",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Accokeek",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Adamstown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Adelphi",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Algonquin",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Allegany County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Andrews AFB",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Annapolis",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Anne Arundel County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Arbutus",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Arden on the Severn",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Arnold",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Ashton-Sandy Spring",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Aspen Hill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Baden",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Ballenger Creek",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Baltimore",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Baltimore County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Baltimore Highlands",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bartonsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bel Air",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bel Air North",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bel Air South",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Beltsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bennsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Berlin",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Berwyn Heights",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bethesda",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bladensburg",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Boonsboro",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bowie",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bowleys Quarters",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bowling Green",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Braddock Heights",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Brandywine",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Brentwood",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Brock Hall",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Brooklyn Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Brookmont",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Brunswick",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Bryans Road",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Buckeystown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Burtonsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Butcher's Hill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cabin John",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "California",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Calvert County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Calverton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cambridge",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Camp Springs",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cape Saint Claire",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Capitol Heights",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Carney",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Caroline County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Catonsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cavetown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cecil County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Centreville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Charles County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Charles Village",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Charlestown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Charlotte Hall",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Chesapeake Beach",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Chesapeake Ranch Estates",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Chestertown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cheverly",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Chevy Chase",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Chevy Chase Village",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Chillum",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "City of Baltimore",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Clarksburg",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Clover Hill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cloverly",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cobb Island",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cockeysville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Colesville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "College Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Colmar Manor",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Coral Hills",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cottage City",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cresaptown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Crisfield",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Crofton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Croom",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Crownsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Cumberland",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Damascus",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Darnestown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Davidsonville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Deale",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Denton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Derwood",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "District Heights",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Dorchester County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Drum Point",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Dundalk",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Dunkirk",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Dunkirk Town Center",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "East Riverdale",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Easton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Edgemere",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Edgewater",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Edgewood",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Edmonston",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Eldersburg",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Elkridge",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Elkton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Ellicott City",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Emmitsburg",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Essex",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fairland",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fairmount Heights",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fairwood",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fallston",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Federalsburg",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Ferndale",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Forest Glen",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Forest Heights",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Forestville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fort George G Mead Junction",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fort Meade",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fort Washington",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fountainhead-Orchard Hills",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Four Corners",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Frederick",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Frederick County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Friendly",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Friendship Village",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Frostburg",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fruitland",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Fulton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Gaithersburg",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Gambrills",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Garrett County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Garrett Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Garrison",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Germantown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Glassmanor",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Glen Burnie",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Glenarden",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Glenmont",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Glenn Dale",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Goddard",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Golden Beach",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Grasonville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Greater Upper Marlboro",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Green Haven",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Green Valley",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Greenbelt",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Greensboro",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hagerstown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Halfway",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hampstead",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hampton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hancock",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hanover",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Harford County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Havre de Grace",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hebron",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Herald Harbor",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Highfield-Cascade",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Highland",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hillandale",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hillcrest Heights",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hillsmere Shores",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Howard County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hughesville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hunt Valley",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Huntingtown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Huntingtown Town Center",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hurlock",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Hyattsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Ilchester",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Indian Head",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Irvington",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Jarrettsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Jessup",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Joppatowne",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Keedysville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Kemp Mill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Kensington",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Kent County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Kettering",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Kingstown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Kingsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "La Plata",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "La Vale",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lake Arbor",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lake Shore",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Landover",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Landover Hills",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Langley Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lanham",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lanham-Seabrook",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lansdowne",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Largo",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Laurel",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Layhill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Leisure World",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Leonardtown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lexington Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Linganore",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Linthicum",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lochearn",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lonaconing",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Londontowne",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Long Beach",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lusby",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lutherville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Lutherville-Timonium",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Marlboro Meadows",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Marlboro Village",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Marlow Heights",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Marlton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Maryland City",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Maugansville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Mayo",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Mays Chapel",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Mechanicsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Mellwood",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Middle River",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Milford Mill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Mitchellville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Montgomery Village",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Morningside",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Mount Airy",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Mount Rainier",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Mountain Lake Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Myersville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "National Harbor",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Naval Academy",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "New Carrollton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "New Windsor",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "North Beach",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "North Bel Air",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "North Bethesda",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "North East",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "North Kensington",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "North Laurel",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "North Potomac",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Ocean City",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Ocean Pines",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Odenton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Olney",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Overlea",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Owings",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Owings Mills",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Oxon Hill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Oxon Hill-Glassmanor",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Paramount-Long Meadow",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Parkville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Parole",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Pasadena",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Peppermill Village",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Perry Hall",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Perryman",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Perryville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Pikesville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Pittsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Pleasant Hills",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Pocomoke City",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Point of Rocks",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Poolesville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Potomac",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Potomac Heights",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Potomac Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Prince Frederick",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Prince George's County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Princess Anne",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Pumphrey",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Queen Anne",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Queen Anne's County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Queenland",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Randallstown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Redland",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Reisterstown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Ridgely",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Rising Sun",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Riva",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Riverdale Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Riviera Beach",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Robinwood",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Rock Hall",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Rockville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Rosaryville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Rosedale",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Rossmoor",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Rossville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Saint Charles",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Saint James",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Saint Mary's County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Saint Michaels",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Salisbury",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Savage",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Scaggsville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Seabrook",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Seat Pleasant",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Selby-on-the-Bay",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Severn",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Severna Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Shady Side",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Silver Hill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Silver Spring",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Smithsburg",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Snow Hill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Solomons",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Somerset",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Somerset County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "South Bel Air",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "South Gate",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "South Kensington",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "South Laurel",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Spencerville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Spring Ridge",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Springdale",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Stevensville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Suitland",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Suitland-Silver Hill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Summerfield",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Sykesville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Takoma Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Talbot County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Taneytown",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Temple Hills",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Thurmont",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Timonium",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Towson",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Trappe",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Travilah",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "University Park",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Upper Marlboro",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Urbana",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Waldorf",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Walker Mill",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Walkersville",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "West Elkridge",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "West Laurel",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "West Ocean City",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Westernport",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Westminster",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Westphalia",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Wheaton",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "White Marsh",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "White Oak",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Wicomico County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Williamsport",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Wilson-Conococheague",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Woodlawn",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Woodmore",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Woodsboro",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Worcester County",
    country_code: "US",
    state_code: "MD",
  },
  {
    name: "Abington",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Acton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Acushnet",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Acushnet Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Adams",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Agawam",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Amesbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Amherst",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Amherst Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Andover",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ashburnham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ashby",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ashfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Assonet",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Athol",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Attleboro",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Avon",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ayer",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Baldwinville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Barnstable",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Barnstable County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Barre",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Becket",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Belchertown",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Bellingham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Belmont",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Berkley",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Berkshire County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Berlin",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Bernardston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Beverly",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Beverly Cove",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Billerica",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Blackstone",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Bliss Corner",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Bolton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Bondsville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Boston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Bourne",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Boxborough",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Boxford",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Boylston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Braintree",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Brewster",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Bridgewater",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Brimfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Bristol County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Brockton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Brookline",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Buckland",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Buzzards Bay",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Cambridge",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Carlisle",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Carver",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Charlemont",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Charlton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Chatham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Chelmsford",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Chelsea",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Chesterfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Chicopee",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Cochituate",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Cohasset",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Colrain",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Conway",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Cordaville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Cotuit",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dalton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Danvers",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dedham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dennis",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dennis Port",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Devens",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dighton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Douglas",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dracut",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dudley",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dukes County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Dunstable",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Duxbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "East Bridgewater",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "East Brookfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "East Dennis",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "East Douglas",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "East Falmouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "East Harwich",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "East Longmeadow",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "East Pepperell",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "East Sandwich",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Eastham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Easthampton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Easton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Edgartown",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Erving",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Essex",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Essex County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Everett",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Fairhaven",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Fall River",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Falmouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Fiskdale",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Fitchburg",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Forestdale",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Foxborough",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Framingham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Framingham Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Freetown",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Gardner",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Gill",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Gloucester",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Grafton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Granby",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Granville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Great Barrington",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Green Harbor-Cedar Crest",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Groton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Groveland",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hadley",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Halifax",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hamilton Worcester",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hampden",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hampden County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hampshire County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hanover",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hanson",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hardwick",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Harvard",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Harwich",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Harwich Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Harwich Port",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hatfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Haverhill",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Head of Westport",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hingham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hinsdale",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Holbrook",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Holden",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Holland",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Holliston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Holyoke",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hopedale",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hopkinton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Housatonic",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hubbardston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hull",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Hyannis",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ipswich",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Jamaica Plain",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lanesborough",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lawrence",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lee",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Leicester",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lenox",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Leominster",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Leverett",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Littleton Common",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Longmeadow",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lowell",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ludlow",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lunenburg",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lynn",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Lynnfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Malden",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Manchester-by-the-Sea",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Mansfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Mansfield Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Marblehead",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Marion Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Marlborough",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Marshfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Marshfield Hills",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Marstons Mills",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Mashpee",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Mattapoisett",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Mattapoisett Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Maynard",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Medfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Medford",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Medway",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Melrose",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Mendon",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Merrimac",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Methuen",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Middleborough",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Middleborough Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Middlesex County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Middleton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Millbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Millers Falls",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Millis",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Millis-Clicquot",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Millville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Monson",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Monson Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Montague",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Monument Beach",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Nahant",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Nantucket",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Nantucket County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Natick",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Needham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "New Bedford",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "New Marlborough",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Newburyport",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Norfolk",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Norfolk County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Adams",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Amherst",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Andover",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Attleborough Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Brookfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Chicopee",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Eastham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Falmouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Lakeville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Pembroke",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Plymouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Reading",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Scituate",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Seekonk",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "North Westport",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Northampton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Northborough",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Northbridge",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Northfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Northwest Harwich",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Norton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Norton Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Norwell",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Norwood",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Oak Bluffs",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Oakham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ocean Bluff-Brant Rock",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ocean Grove",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Onset",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Orange",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Orleans",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Osterville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Otis",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Palmer",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Paxton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Peabody",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Pelham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Pepperell",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Phillipston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Pinehurst",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Pittsfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Plainville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Plymouth County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Plympton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Pocasset",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Provincetown",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Quincy",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Randolph",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Raynham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Raynham Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Reading",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Rehoboth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Revere",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Rockland",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Rockport",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Rowley",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Royalston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Rutland",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sagamore",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Salisbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sandwich",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Saugus",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Scituate",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Seekonk",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sharon",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sheffield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Shelburne",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Shelburne Falls",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sherborn",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Shirley",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Shrewsbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Shutesbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Smith Mills",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Somerset",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Somerville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Amherst",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Ashburnham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Boston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Deerfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Dennis",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Duxbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Hadley",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Lancaster",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Peabody",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "South Yarmouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Southampton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Southborough",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Southbridge",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Southwick",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Spencer",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sterling",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Stockbridge",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Stoneham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Stoughton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Stow",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sturbridge",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sudbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Suffolk County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sunderland",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Sutton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Swampscott",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Swansea",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Taunton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Teaticket",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Templeton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Tewksbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Three Rivers",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Topsfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Townsend",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Truro",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Turners Falls",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Tyngsboro",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Upton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Uxbridge",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Vineyard Haven",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wakefield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wales",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Walpole",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Waltham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Ware",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wareham Center",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Watertown",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wayland",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Webster",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wellesley",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wellfleet",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wendell",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wenham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Barnstable",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Boylston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Bridgewater",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Brookfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Chatham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Concord",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Dennis",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Falmouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Newbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Springfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Stockbridge",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Tisbury",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Wareham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "West Yarmouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Westborough",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Westfield",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Westford",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Westhampton",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Westminster",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Weston",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Westwood",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Weweantic",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Weymouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Whately",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "White Island Shores",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Whitinsville",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Whitman",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wilbraham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Williamsburg",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Williamstown",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wilmington",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Winchendon",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Winthrop",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Woburn",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Worcester",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Worcester County",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Wrentham",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Yarmouth",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Yarmouth Port",
    country_code: "US",
    state_code: "MA",
  },
  {
    name: "Adrian",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Albion",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Alcona County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Alger County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Algonac",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Allegan",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Allegan County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Allen Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Allendale",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Alma",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Almont",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Alpena",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Alpena County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ann Arbor",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Antrim County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Arenac County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Argentine",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Armada",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Atlanta",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Au Sable",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Auburn Hills",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bad Axe",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Baldwin",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bangor",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Baraga",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Baraga County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Barnes Lake-Millers Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Barry County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bath",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Battle Creek",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bay City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bay County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bay Harbor",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Beaverton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Beecher",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Beechwood",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Belding",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bellaire",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Belleville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Benton Harbor",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Benton Heights",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Benzie County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Berkley",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Berrien County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Berrien Springs",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bessemer",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Beulah",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Beverly Hills",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Big Rapids",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bingham Farms",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Birch Run",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Birmingham",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Blissfield",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bloomfield Hills",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Boyne City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Branch County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Breckenridge",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bridgman",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Brighton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Bronson",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Brooklyn",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Brown City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Brownlee Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Buchanan",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Buena Vista",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Burt",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Burton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Byron Center",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Cadillac",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Caledonia",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Canadian Lakes",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Capac",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Carleton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Caro",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Carson City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Cass City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Cass County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Cassopolis",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Cedar Springs",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Center Line",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Centreville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Charlevoix",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Charlevoix County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Charlotte",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Cheboygan",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Cheboygan County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Chelsea",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Chesaning",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Chippewa County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Clare",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Clare County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Clarkston",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Clawson",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Clinton County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Clinton Township",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Clio",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Coldwater",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Coleman",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Coloma",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Colon",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Comstock Northwest",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Comstock Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Constantine",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Coopersville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Corunna",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Croswell",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Crystal Falls",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Cutlerville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Davison",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Dearborn",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Dearborn Heights",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Delta County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Detroit",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Detroit Beach",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "DeWitt",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Dexter",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Dickinson County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Dimondale",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Dollar Bay",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Douglas",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Dowagiac",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Dundee",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Durand",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Eagle River",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "East Grand Rapids",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "East Jordan",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "East Lansing",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "East Tawas",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Eastpointe",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Eastwood",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Eaton County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Eaton Rapids",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ecorse",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Edgemont Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Edmore",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Edwardsburg",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Elk Rapids",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Emmet County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Escanaba",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Essexville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Evart",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Fair Plain",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Farmington Hills",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Fennville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Fenton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ferndale",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ferrysburg",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Flat Rock",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Flint",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Flushing",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Forest Hills",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Fowler",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Fowlerville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Frankenmuth",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Frankfort",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Fraser",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Freeland",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Fremont",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Fruitport",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Galesburg",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Garden City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Gaylord",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Genesee County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Gibraltar",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Gladstone",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Gladwin",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Gladwin County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Gogebic County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Goodrich",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grand Blanc",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grand Haven",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grand Ledge",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grand Rapids",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grand Traverse County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grandville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grass Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Gratiot County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grayling",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Greilickville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grosse Ile",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grosse Pointe",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grosse Pointe Farms",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grosse Pointe Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grosse Pointe Shores",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Grosse Pointe Woods",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Gwinn",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hamtramck",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hancock",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Harbor Beach",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Harbor Springs",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Harper Woods",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Harrison",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Harrisville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hart",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Harvey",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Haslett",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hastings",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hazel Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hemlock",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Highland Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hillsdale",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hillsdale County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Holland",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Holly",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Holt",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Homer",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Houghton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Houghton County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Houghton Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Howard City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Howell",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hubbard Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Hudsonville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Huntington Woods",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Huron County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Imlay City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Indian River",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ingham County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Inkster",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ionia",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ionia County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Iosco County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Iron County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Iron Mountain",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Iron River",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ironwood",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Isabella County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ishpeming",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ithaca",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Jenison",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Jonesville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "K. I. Sawyer Air Force Base",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kalamazoo",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kalamazoo County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kalkaska",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kalkaska County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Keego Harbor",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kent City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kent County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kentwood",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Keweenaw County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kilmanagh",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kingsford",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Kingsley",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "L'Anse",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Laingsburg",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lake City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lake Fenton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lake Isabella",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lake Michigan Beach",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lake Odessa",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lake Orion",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lakeview",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lakewood Club",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lambertville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lansing",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lapeer",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lapeer County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lathrup Village",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Laurium",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lawton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Leelanau County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Leland",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lenawee County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Leslie",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Level Park-Oak Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lewiston",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lincoln Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Linden",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Litchfield",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Livingston County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Livonia",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Lowell",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Luce County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ludington",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Luna Pier",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mackinac County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Macomb County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Madison Heights",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mancelona",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Manistee",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Manistee County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Manistique",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Manitou Beach-Devils Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Manton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Marcellus",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Marine City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Marlette",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Marquette",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Marquette County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Marshall",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Marysville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mason",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mason County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mattawan",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mecosta County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Melvindale",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Memphis",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Menominee",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Menominee County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Michigan Center",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Middleville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Midland",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Midland County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Milan",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Millington",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mio",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Missaukee County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Montague",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Montcalm County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Montmorency County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Montrose",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Morenci",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mount Clemens",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mount Morris",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Mount Pleasant",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Munising",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Muskegon",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Muskegon County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Muskegon Heights",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Napoleon",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Nashville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Negaunee",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "New Baltimore",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "New Buffalo",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "New Haven",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Newaygo",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Newaygo County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Newberry",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Niles",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "North Branch",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "North Muskegon",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Northview",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Northville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Norton Shores",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Norway",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Novi",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Oak Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Oakland County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Oceana County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ogemaw County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Okemos",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Olivet",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ontonagon",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ontonagon County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Orchard Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ortonville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Osceola County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Oscoda County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Otsego",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Otsego County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ottawa County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ovid",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Owosso",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Parchment",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Paw Paw",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Paw Paw Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Pearl Beach",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Perry",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Petersburg",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Petoskey",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Pigeon",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Pinckney",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Pinconning",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Plainwell",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Pleasant Ridge",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Pontiac",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Port Huron",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Portage",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Portland",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Potterville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Presque Isle County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Prudenville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Quincy",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Quinnesec",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Rapid City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ravenna",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Reading",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Redford",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Reed City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Reese",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "River Rouge",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Riverview",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Rochester Hills",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Rockford",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Rockwood",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Rogers City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Romeo",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Romulus",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Roosevelt Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Roscommon",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Roscommon County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Roseville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Royal Oak",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saginaw",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saginaw County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saginaw Township North",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Charles",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Clair",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Clair County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Clair Shores",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Helen",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Ignace",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Johns",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Joseph",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Joseph County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saint Louis",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saline",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Sand Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Sandusky",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Sanilac County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Saranac",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Sault Ste. Marie",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Schoolcraft",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Schoolcraft County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Scottville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Sebewaing",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Shelby",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Shepherd",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Shiawassee County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Shields",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Shorewood-Tower Hills-Harbert",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Skidway Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "South Gull Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "South Haven",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "South Lyon",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "South Monroe",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "South Rockwood",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Southfield",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Southgate",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Sparta",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Spring Arbor",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Spring Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Stambaugh, Iron River",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Standish",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Stanton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Sterling Heights",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Stevensville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Stockbridge",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Stony Point",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Sturgis",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Swartz Creek",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Sylvan Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Tawas City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Taylor",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Tecumseh",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Temperance",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Three Oaks",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Three Rivers",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Traverse City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Trowbridge Park",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Tuscola County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Twin Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Union City",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Utica",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Van Buren County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Vandercook Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Vassar",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Vicksburg",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wacousta",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wakefield",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Walker",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Walled Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Washtenaw County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Waterford",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Watervliet",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Waverly",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wayland",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wayne",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Webberville",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "West Bloomfield Township",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "West Branch",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "West Ishpeming",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "West Monroe",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Westland",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Westwood",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wexford County",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "White Cloud",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "White Pigeon",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Whitehall",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Whitmore Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Williamston",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wixom",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wolf Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wolverine Lake",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Woodhaven",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Woodland Beach",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wyandotte",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Wyoming",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Yale",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ypsilanti",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Zeeland",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Zilwaukee",
    country_code: "US",
    state_code: "MI",
  },
  {
    name: "Ada",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Adrian",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Afton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Aitkin",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Aitkin County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Albert Lea",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Albertville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Alexandria",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Andover",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Annandale",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Anoka",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Anoka County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Apple Valley",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Appleton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Arden Hills",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Arnold",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Atwater",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Aurora",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Austin",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Avon",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Babbitt",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Bagley",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Barnesville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Baudette",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Baxter",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Bayport",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Becker",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Becker County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Belle Plaine",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Beltrami County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Bemidji",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Benson",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Benton County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Big Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Big Stone County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Birchwood",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Blaine",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Blooming Prairie",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Bloomington",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Blue Earth",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Blue Earth County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Braham",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Brainerd",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Branch",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Breckenridge",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Breezy Point",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Brooklyn Center",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Brooklyn Park",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Brown County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Burnsville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Byron",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Caledonia",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cambridge",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Canby",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cannon Falls",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Carlton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Carlton County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Carver",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Carver County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cass County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Center City",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Champlin",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Chanhassen",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Chaska",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Chatfield",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Chippewa County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Chisago City",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Chisago County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Chisholm",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Circle Pines",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Clara City",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Clearwater",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Clearwater County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cloquet",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cohasset",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cokato",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cold Spring",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Coleraine",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Collegeville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cologne",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Columbia Heights",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cook County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Coon Rapids",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Corcoran",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cottage Grove",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cottonwood",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cottonwood County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Crookston",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Crosby",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Cross Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Crow Wing County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Crystal",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Dakota County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Dassel",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Dawson",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Deephaven",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Delano",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Dellwood",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Detroit Lakes",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Dilworth",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Dodge Center",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Dodge County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Duluth",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Dundas",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Eagan",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Eagle Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "East Bethel",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "East Grand Forks",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "East Gull Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Eden Prairie",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Eden Valley",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Edgerton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Edina",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Elbow Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Elgin",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Elk River",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Elko New Market",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Ely",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Esko",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Eveleth",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Excelsior",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Eyota",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Fairfax",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Fairmont",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Falcon Heights",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Faribault",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Faribault County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Fergus Falls",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Fillmore County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Foley",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Forest Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Fosston",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Frazee",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Freeborn County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Fridley",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Fulda",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Gaylord",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Gilbert",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Glencoe",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Glenwood",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Glyndon",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Golden Valley",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Goodhue",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Goodhue County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Goodview",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Grand Marais",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Grand Meadow",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Grand Rapids",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Granite Falls",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Grant",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hallock",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Ham Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hanover",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Harris",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hastings",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hawley",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hayfield",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hector",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hennepin County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hermantown",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hibbing",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hinckley",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hopkins",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Houston County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Howard Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hoyt Lakes",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hubbard County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hugo",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Hutchinson",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "International Falls",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Inver Grove Heights",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Isanti",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Isanti County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Itasca County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Ivanhoe",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Janesville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Jordan",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Kanabec County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Kandiyohi County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Kasson",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Keewatin",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Kenyon",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Kittson County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Koochiching County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "La Crescent",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lac qui Parle County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lake City",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lake Crystal",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lake Elmo",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lake of the Woods County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lake Saint Croix Beach",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lake Shore",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lakefield",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lakeland",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lakeville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lauderdale",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Le Center",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Le Sueur",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Le Sueur County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lester Prairie",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lewiston",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lindstrom",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lino Lakes",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Litchfield",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Little Canada",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Little Falls",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Little Rock",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Long Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Long Prairie",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lonsdale",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Luverne",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Lyon County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Madelia",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Madison Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mahnomen",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mahnomen County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mahtomedi",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mankato",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mantorville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Maple Grove",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Maple Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Maple Plain",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mapleton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Maplewood",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Marshall",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Martin County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mayer",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "McLeod County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Medford",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Medina",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Meeker County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Melrose",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Menahga",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mendota Heights",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Milaca",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mille Lacs County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Minneapolis",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Minneota",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Minnetonka",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Minnetonka Mills",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Minnetrista",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Montevideo",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Montgomery",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Montrose",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Moorhead",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Moose Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mora",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Morris",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Morrison County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mound",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mounds View",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mountain Iron",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mountain Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Mower County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Murray County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "New Brighton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "New Hope",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "New London",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "New Prague",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "New Richland",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "New Ulm",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "New York Mills",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Nicollet",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Nicollet County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Nisswa",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Nobles County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Norman County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "North Branch",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "North Mankato",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "North Oaks",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "North Saint Paul",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Northfield",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Norwood (historical)",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Norwood Young America",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Nowthen",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Oak Grove",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Oak Park Heights",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Oakdale",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Oakport",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Olivia",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Olmsted County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Orono",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Oronoco",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Ortonville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Osakis",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Osseo",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Otsego",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Otter Tail County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Owatonna",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Park Rapids",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Parkers Prairie",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Parkville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Paynesville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pelican Rapids",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pennington County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pequot Lakes",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Perham",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pierz",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pine City",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pine County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pine Island",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pipestone",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pipestone County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Plainview",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Pope County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Preston",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Prior Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Proctor",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Ramsey",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Ramsey County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Red Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Red Lake County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Red Lake Falls",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Red Wing",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Redby",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Redwood County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Redwood Falls",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Renville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Renville County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rice",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rice County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Richfield",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Robbinsdale",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rock County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rock Creek",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rockford",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rockville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rogers",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Roseau",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Roseau County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rosemount",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Roseville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Royalton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rush City",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Rushford",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Anthony",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Augusta",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Bonifacius",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Charles",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Cloud",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Francis",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint James",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Joseph",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Louis County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Louis Park",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Michael",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Paul",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Paul Park",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Saint Peter",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Sandstone",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Sartell",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Sauk Centre",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Sauk Rapids",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Savage",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Scandia",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Shafer",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Shakopee",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Sherburn",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Sherburne County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Shoreview",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Shorewood",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Sibley County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Silver Bay",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Slayton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Sleepy Eye",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "South Saint Paul",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Spicer",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Spring Grove",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Spring Lake Park",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Spring Park",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Spring Valley",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Stacy",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Staples",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Starbuck",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Stearns County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Steele County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Stevens County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Stewartville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Stillwater",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Swift County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Taylors Falls",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Thief River Falls",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Todd County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Tonka Bay",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Tracy",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Traverse County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Truman",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Two Harbors",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Tyler",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Vadnais Heights",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Victoria",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Vineland",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Virginia",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wabasha",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wabasha County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Waconia",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wadena",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wadena County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Waite Park",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Walker",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wanamingo",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Warroad",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Waseca",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Waseca County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Watertown",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Waterville",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Watonwan County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Waverly",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wayzata",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wells",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "West Coon Rapids",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "West Saint Paul",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wheaton",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "White Bear Lake",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wilkin County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Willmar",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Windom",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Winnebago",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Winona",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Winona County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Winsted",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Winthrop",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Woodbury",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Worthington",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wright County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Wyoming",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Yellow Medicine County",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Young America (historical)",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Zimmerman",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Zumbrota",
    country_code: "US",
    state_code: "MN",
  },
  {
    name: "Aberdeen",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Ackerman",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Alcorn County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Amite County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Amory",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Arnold Line",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Attala County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Baldwyn",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Batesville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Bay Saint Louis",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Bay Springs",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Beechwood",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Belmont",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Belzoni",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Benton County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Biloxi",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Bolivar County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Booneville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Brandon",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Brookhaven",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Brooksville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Bruce",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Bude",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Byhalia",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Byram",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Caledonia",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Calhoun City",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Carriere",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Carthage",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Centreville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Charleston",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Chickasaw County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Choctaw County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Claiborne County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Clarke County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Clarksdale",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Cleary",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Cleveland",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Coahoma County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Coldwater",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Collins",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Collinsville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Columbus Air Force Base",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Como",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Conehatta",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Copiah County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Corinth",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Covington County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Crystal Springs",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "D'Iberville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "De Kalb",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "De Lisle",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "De Soto County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Derma",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Diamondhead",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Drew",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Duck Hill",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Durant",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Edwards",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Ellisville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Escatawpa",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Eupora",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Fayette",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Flora",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Flowood",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Forest",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Forrest County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Friars Point",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Fulton",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Gautier",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "George County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Glendale",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Goodman",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Greenwood",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Grenada",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Grenada County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Gulf Hills",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Gulf Park Estates",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Gulfport",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Guntown",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Harrison County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hattiesburg",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hazlehurst",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Helena",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hernando",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hickory Hills",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hide-A-Way Lake",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hillsboro",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hinds County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hollandale",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Holly Springs",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Holmes County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Horn Lake",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Houston",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Humphreys County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Hurley",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Indianola",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Issaquena County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Itawamba County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Itta Bena",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Iuka",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Jasper County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Jefferson Davis County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Jones County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Jonestown",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Kearney Park",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Kemper County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Kiln",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Kosciusko",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lafayette County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lamar County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lambert",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Latimer",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lauderdale County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Laurel",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Leake County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Leakesville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Leflore County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Leland",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Long Beach",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Louisville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lowndes County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lucedale",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lumberton",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lyman",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Lynchburg",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Macon",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Magee",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Magnolia",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Mantachie",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Marks",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Mayersville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "McComb",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Meadville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Mendenhall",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Meridian",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Meridian Station",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Metcalfe",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Mikoma",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Moorhead",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Morgantown",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Morton",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Moss Point",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Mound Bayou",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Natchez",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Nellieburg",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Neshoba County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Nettleton",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "New Albany",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "New Augusta",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "New Hope",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Newton County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Nicholson",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "North Tunica",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Noxubee County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Ocean Springs",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Okolona",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Oktibbeha County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Olive Branch",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Panola County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pascagoula",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pass Christian",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pearl",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pearl River",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pearl River County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pearlington",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pelahatchie",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Petal",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Philadelphia",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Picayune",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pickens",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pittsboro",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Plantersville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pontotoc",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Pontotoc County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Poplarville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Port Gibson",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Prentiss",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Prentiss County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Purvis",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Quitman",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Quitman County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Raleigh",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Rankin County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Rawls Springs",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Raymond",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Richland",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Richton",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Ridgeland",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Ripley",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Rolling Fork",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Rosedale",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Ruleville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Saint Martin",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Saltillo",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Sardis",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Saucier",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Senatobia",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Shannon",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Sharkey County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Sharon",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Shaw",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Shelby",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Simpson County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Smith County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Southaven",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Starkville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Stone County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Stonewall",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Summit",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Sumrall",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Sunflower",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Sunflower County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tallahatchie County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tate County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Taylorsville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tchula",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Terry",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tippah County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tishomingo County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tunica",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tunica County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tunica Resorts",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tupelo",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tutwiler",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Tylertown",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Union",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "University",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Vancleave",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Vardaman",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Verona",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Vicksburg",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Wade",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Walls",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Walnut Grove",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Walthall",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Walthall County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Water Valley",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Waveland",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Waynesboro",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Webster County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Wesson",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "West Gulfport",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "West Hattiesburg",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "West Point",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Wiggins",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Wilkinson County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Winona",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Winston County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Woodville",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Yalobusha County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Yazoo City",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Yazoo County",
    country_code: "US",
    state_code: "MS",
  },
  {
    name: "Adair County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Adrian",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Advance",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Affton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Alton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Anderson",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Andrew County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Appleton City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Archie",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Arnold",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ash Grove",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Atchison County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Audrain County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Aurora",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ava",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ballwin",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Barnhart",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Barry County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Barton County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bates County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Battlefield",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bel-Nor",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bel-Ridge",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Belle",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bellefontaine Neighbors",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Belton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Benton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Benton County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Berkeley",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bernie",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bethany",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Billings",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bismarck",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Black Jack",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bloomfield",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Blue Springs",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bolivar",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bollinger County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bonne Terre",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Boone County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Boonville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bourbon",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bowling Green",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Branson",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Breckenridge Hills",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Brentwood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Bridgeton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Brookfield",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Buchanan County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Buckner",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Butler",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Butler County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Byrnes Mill",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cabool",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Caldwell County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "California",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Callaway County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Calverton Park",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Camden County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Camdenton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cameron",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Campbell",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cape Girardeau",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cape Girardeau County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Carl Junction",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Carter County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Carterville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Carthage",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Caruthersville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cass County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cassville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Castle Point",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cedar County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cedar Hill",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Centralia",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Chaffee",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Chariton County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Charlack",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Charleston",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Chesterfield",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Chillicothe",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Christian County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "City of Saint Louis",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Clarkson Valley",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Clarkton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Claycomo",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Clever",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Clinton County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cole Camp",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cole County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Concordia",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cool Valley",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cooper County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cottleville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Country Club Hills",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Country Club Village",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Crane",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Crestwood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Creve Coeur",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Crocker",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Crystal City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Cuba",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Dade County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Dallas County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Dardenne Prairie",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Daviess County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "De Soto",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "DeKalb County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Dellwood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Dent County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Des Peres",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Desloge",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Dexter",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Dixon",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Doniphan",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Duenweg",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Dunklin County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Duquesne",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "East Independence",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "East Prairie",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Edina",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "El Dorado Springs",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Eldon",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ellisville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Elsberry",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Elvins",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Eminence",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Esther",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Eureka",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Excelsior Springs",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Fair Grove",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Fayette",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Fenton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ferguson",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Festus",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Flat River",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Florissant",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Forsyth",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Fort Leonard Wood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Four Seasons",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Fredericktown",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Frontenac",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Fulton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Gainesville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Galena",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Gallatin",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Garden City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Gasconade County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Gentry County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Gerald",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Gideon",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Gladstone",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Glasgow",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Glasgow Village",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Glendale",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Goodman",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Gower",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Grain Valley",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Granby",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Grandview",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Grant City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Gray Summit",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Green Park",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Greenwood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Grundy County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hallsville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hamilton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hanley Hills",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hannibal",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Harrison County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Harrisonville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hartville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hayti",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hazelwood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Herculaneum",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hermann",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hermitage",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hickory County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Higginsville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "High Ridge",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hillsboro",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hillsdale",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Holden",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Hollister",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Holt County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Holts Summit",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Houston",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Howard County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Howell County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Humansville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Huntsville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Imperial",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Iron County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ironton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Jasper County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Jefferson City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Jennings",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Joplin",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Kahoka",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Kansas City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Kearney",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Kennett",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Keytesville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Kimberling City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "King City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Kirksville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Kirkwood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Kissee Mills",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Knob Noster",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Knox County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "La Monte",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "La Plata",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "LaBarque Creek",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Laclede County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ladue",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lafayette County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lake Lotawana",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lake Ozark",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lake Saint Louis",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lake Winnebago",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lakeshire",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lamar",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lathrop",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lawson",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Leadwood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lee's Summit",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lemay",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lewis County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Licking",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lilbourn",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Linn",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Linn County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Linneus",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Livingston County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Lone Jack",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Louisiana",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Macon",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Macon County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Malden",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Mansfield",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Maplewood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Marble Hill",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Marceline",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Maries County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Marionville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Marlborough",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Marshall",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Marshfield",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Marthasville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Maryland Heights",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Maryville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Maysville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "McDonald County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Mehlville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Memphis",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Mercer County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Merriam Woods",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Mexico",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Milan",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Miller County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Mississippi County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Moberly",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Moline Acres",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Monett",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Moniteau County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Monroe City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Montgomery City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Moscow Mills",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Mound City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Mountain Grove",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Mountain View",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Murphy",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Neosho",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Nevada",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "New Franklin",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "New Haven",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "New London",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "New Madrid",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "New Madrid County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Newton County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Nixa",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Nodaway County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Noel",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Normandy",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "North Kansas City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Northwoods",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "O'Fallon",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Oak Grove",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Oakville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Odessa",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Old Jamestown",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Olivette",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Oran",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Oregon",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Oregon County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Oronogo",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Osage Beach",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Osage County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Osceola",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Overland",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Owensville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ozark",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ozark County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pacific",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pagedale",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Palmyra",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Paris",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Park Hills",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Parkville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Peculiar",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pemiscot County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Perryville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pettis County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pevely",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Phelps County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Piedmont",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pierce City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pine Lawn",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pineville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Platte City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Platte County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Plattsburg",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pleasant Hill",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pleasant Valley",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Poplar Bluff",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Portageville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Potosi",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Pulaski County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Purdy",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Putnam County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ralls County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Randolph County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ray County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Raymore",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Raytown",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Republic",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Reynolds County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Rich Hill",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Richland",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Richmond Heights",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Ripley County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Riverview",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Rock Hill",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Rock Port",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Rogersville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Rolla",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Ann",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Charles",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Charles County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Clair",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Clair County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Francois County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint George",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint James",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint John",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Johns",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Joseph",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Louis County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Martins",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Paul",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Peters",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saint Robert",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sainte Genevieve",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sainte Genevieve County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Saline County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Salisbury",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sappington",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sarcoxie",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Savannah",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Schuyler County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Scotland County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Scott City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sedalia",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Senath",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Seneca",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Seymour",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Shannon County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Shelbina",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Shelby County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Shelbyville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Shell Knob",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Shrewsbury",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sikeston",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Slater",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Smithville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Spanish Lake",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sparta",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "St. Louis",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Stanberry",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Steele",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Steelville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Stockton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Stoddard County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Stone County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Stover",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Strafford",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sugar Creek",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sullivan",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sullivan County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sunset Hills",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Sweet Springs",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Taney County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Taos",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Tarkio",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Terre du Lac",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Terre Haute",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Texas County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Thayer",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Tipton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Town and Country",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Tuscumbia",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Union",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Unionville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "University City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Valley Park",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Van Buren",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Vandalia",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Velda Village",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Velda Village Hills",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Vernon County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Versailles",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Vienna",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Villa Ridge",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Vinita Park",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Wardsville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Warrensburg",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Warrenton",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Warsaw",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Warson Woods",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Waynesville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Weatherby Lake",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Webb City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Webster County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Webster Groves",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Weldon Spring",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Wellston",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Wellsville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Wentzville",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "West Plains",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Weston",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Whiteman Air Force Base",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Wildwood",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Willard",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Willow Springs",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Winfield",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Winona",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Woodson Terrace",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Worth County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Wright City",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Wright County",
    country_code: "US",
    state_code: "MO",
  },
  {
    name: "Absarokee",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Anaconda",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Baker",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Beaverhead County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Belgrade",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Big Horn County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Big Sky",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Big Timber",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Bigfork",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Billings",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Blaine County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Bonner-West Riverside",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Boulder",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Bozeman",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Broadus",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Broadwater County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Browning",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Butte",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Butte-Silver Bow (Balance)",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Carbon County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Carter County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Cascade County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Chinook",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Choteau",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Chouteau County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Circle",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Clancy",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Colstrip",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Columbia Falls",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Conrad",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Crow Agency",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Custer County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Cut Bank",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Daniels County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Dawson County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Deer Lodge",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Deer Lodge County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Dillon",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "East Helena",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "East Missoula",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Ekalaka",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Eureka",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Evergreen",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Fallon County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Fergus County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Flathead County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Forsyth",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Fort Belknap Agency",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Fort Benton",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Four Corners",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Frenchtown",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Gallatin County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Garfield County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Glacier County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Glasgow",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Glendive",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Golden Valley County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Granite County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Great Falls",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Hamilton",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Hardin",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Harlowton",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Havre",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Helena",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Helena Valley Northeast",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Helena Valley Northwest",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Helena Valley Southeast",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Helena Valley West Central",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Helena West Side",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Hill County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Hysham",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Jordan",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Judith Basin County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Kalispell",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Lakeside",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Lame Deer",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Laurel",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Lewis and Clark County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Lewistown",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Libby",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Liberty County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Livingston",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Lockwood",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Lolo",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Malmstrom Air Force Base",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Malta",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Manhattan",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "McCone County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Meagher County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Miles City",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Mineral County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Missoula",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Missoula County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Montana City",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Musselshell County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "North Browning",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Orchard Homes",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Pablo",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Park County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Petroleum County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Philipsburg",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Phillips County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Plains",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Plentywood",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Polson",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Pondera County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Powder River County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Powell County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Prairie County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Ravalli County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Red Lodge",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Richland County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Ronan",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Roosevelt County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Rosebud County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Roundup",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Ryegate",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Sanders County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Scobey",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Seeley Lake",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Shelby",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Sheridan County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Sidney",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Silver Bow County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Somers",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "South Browning",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Stanford",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Stevensville",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Stillwater County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Sun Prairie",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Superior",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Sweet Grass County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Terry",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Teton County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Thompson Falls",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Three Forks",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Toole County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Townsend",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Treasure County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Valley County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Virginia City",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Warm Springs",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "West Glendive",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "West Yellowstone",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Wheatland County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "White Sulphur Springs",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Whitefish",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Whitehall",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Wibaux",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Wibaux County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Winnett",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Wolf Point",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Yellowstone County",
    country_code: "US",
    state_code: "MT",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Ainsworth",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Albion",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Alliance",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Alma",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Antelope County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Arapahoe",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Arthur",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Arthur County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Atkinson",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Aurora",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Banner County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Bartlett",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Bassett",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Battle Creek",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Bayard",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Beatrice",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Beaver City",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Benkelman",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Bennington",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Blaine County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Blair",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Boone County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Box Butte County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Boyd County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Brewster",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Broken Bow",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Brown County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Buffalo County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Burt County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Burwell",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Butler County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Butte",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Cambridge",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Cass County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Cedar County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Center",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Central City",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Chadron",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Chalco",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Chappell",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Chase County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Cherry County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Cheyenne County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Clay Center",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Colfax County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Cozad",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Creighton",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Crete",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Cuming County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Custer County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Dakota City",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Dakota County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "David City",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Dawes County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Dawson County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Deuel County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Dixon County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Dodge County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Dundy County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Eagle",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Elkhorn",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Elwood",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Fairbury",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Falls City",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Fillmore County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Fremont",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Friend",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Frontier County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Fullerton",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Furnas County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Gage County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Garden County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Garfield County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Geneva",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Gering",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Gibbon",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Gordon",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Gosper County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Gothenburg",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Grand Island",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Grant",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Greeley",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Greeley County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Gretna",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hall County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Harlan County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Harrisburg",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Harrison",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hartington",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hastings",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hayes Center",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hayes County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hebron",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hickman",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hitchcock County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Holdrege",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Holt County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hooker County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Howard County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Hyannis",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Imperial",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Kearney",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Kearney County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Keith County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Keya Paha County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Kimball",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Kimball County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Knox County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "La Vista",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Lancaster County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Louisville",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Loup City",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Loup County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Macy",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "McCook",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "McPherson County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Merrick County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Minden",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Mitchell",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Morrill County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Mullen",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Nance County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Nebraska City",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Neligh",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Nelson",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Nemaha County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Norfolk",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "North Bend",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "North Platte",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Nuckolls County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "O'Neill",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Offutt Air Force Base",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Ogallala",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Omaha",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Ord",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Osceola",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Oshkosh",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Otoe County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Papillion",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Pawnee City",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Pawnee County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Pender",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Perkins County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Phelps County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Pierce",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Pierce County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Plainview",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Platte County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Plattsmouth",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Ponca",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Ralston",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Ravenna",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Red Cloud",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Red Willow County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Richardson County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Rock County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Rushville",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Saint Paul",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Saline County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Sarpy County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Saunders County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Schuyler",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Scotts Bluff County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Scottsbluff",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Seward",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Seward County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Shelton",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Sheridan County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Sherman County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Sidney",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Sioux County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "South Sioux City",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Springview",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Stanton",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Stanton County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Stapleton",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Stockville",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Stromsburg",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Superior",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Sutherland",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Sutton",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Syracuse",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Taylor",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Tecumseh",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Tekamah",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Terrytown",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Thayer County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Thedford",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Thomas County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Thurston County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Tryon",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Valentine",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Valley",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Valley County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Wahoo",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Wakefield",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Waterloo",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Waverly",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Wayne",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Webster County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Weeping Water",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "West Point",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Wheeler County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Wilber",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Wisner",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Wood River",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Wymore",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "York",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "York County",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Yutan",
    country_code: "US",
    state_code: "NE",
  },
  {
    name: "Alamo",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Battle Mountain",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Beatty",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Boulder City",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Bunkerville",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Caliente",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Carlin",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Carson City",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Churchill County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Cold Springs",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "East Valley",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Elko",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Elko County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Ely",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Enterprise",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Esmeralda County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Eureka",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Eureka County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Fallon",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Fernley",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Gardnerville",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Gardnerville Ranchos",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Golden Valley",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Goldfield",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Hawthorne",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Henderson",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Humboldt County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Incline Village",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Indian Hills",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Jackpot",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Johnson Lane",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Kingsbury",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Lander County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Las Vegas",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Laughlin",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Lemmon Valley",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Lovelock",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Lyon County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "McGill",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Mesquite",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Minden",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Mineral County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Moapa Town",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Moapa Valley",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Mogul",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Nellis Air Force Base",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "North Las Vegas",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Nye County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Pahrump",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Paradise",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Pershing County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Pioche",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Reno",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Sandy Valley",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Silver Springs",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Smith",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Smith Valley",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Spanish Springs",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Sparks",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Spring Creek",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Spring Valley",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Stagecoach",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Storey County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Summerlin South",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Sun Valley",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Sunrise Manor",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Tonopah",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Topaz Ranch Estates",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Verdi",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Virginia City",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Washoe County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Wells",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "West Wendover",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "White Pine County",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Whitney",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Winnemucca",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Yerington",
    country_code: "US",
    state_code: "NV",
  },
  {
    name: "Alexandria",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Alstead",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Andover",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Antrim",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Atkinson",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Barnstead",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Barrington",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Belknap County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Belmont",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Berlin",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Boscawen",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Bow Bog",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Brentwood",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Bridgewater",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Brookline",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Candia",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Canterbury",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Center Harbor",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Charlestown",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Cheshire County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Chesterfield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Chichester",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Claremont",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Colebrook",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Contoocook",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Conway",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Coos County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Danbury",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Deerfield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Deering",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Derry",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Derry Village",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Dublin",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Durham",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "East Concord",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "East Kingston",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "East Merrimack",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Effingham",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Enfield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Epping",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Epsom",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Exeter",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Fitzwilliam",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Francestown",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Freedom",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Fremont",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Gilford",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Gilmanton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Goffstown",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Gorham",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Grafton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Grafton County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Grantham",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Greenland",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Groveton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hampstead",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hampton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hampton Beach",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hampton Falls",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hanover",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Harrisville",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Haverhill",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Henniker",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hill",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hillsborough",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hillsborough County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hinsdale",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Holderness",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hollis",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hooksett",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hopkinton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Jaffrey",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Keene",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Kensington",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Laconia",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Lee",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Lempster",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Litchfield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Littleton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Londonderry",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Lyme",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Lyndeborough",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Madbury",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Marlborough",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Mason",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Meredith",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Merrimack",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Merrimack County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Milan",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Mont Vernon",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Moultonborough",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Nashua",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "New Boston",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "New Castle",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "New Durham",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "New Ipswich",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "New London",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Newbury",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Newmarket",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "North Conway",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "North Hampton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Northfield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Northumberland",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Northwood",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Nottingham",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Orford",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Ossipee",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Pelham",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Pembroke",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Peterborough",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Pinardville",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Pittsfield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Plaistow",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Portsmouth",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Raymond",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Rindge",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Rockingham County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Rollinsford",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Rumney",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Rye",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Salisbury",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Sanbornton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Sanbornville",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Sandown",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Sandwich",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Seabrook",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Somersworth",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "South Hooksett",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Strafford",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Strafford County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Stratford",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Stratham Station",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Sullivan County",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Sunapee",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Suncook",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Sutton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Swanzey",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Tamworth",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Temple",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Thornton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Tilton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Tilton-Northfield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Tuftonboro",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Unity",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Wakefield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Weare",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Webster",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "West Swanzey",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Westmoreland",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Whitefield",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Wilmot",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Wilton",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Windham",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Wolfeboro",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Woodstock",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Woodsville",
    country_code: "US",
    state_code: "NH",
  },
  {
    name: "Absecon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Allendale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Allentown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Alloway",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Alpha",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Alpine",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Annandale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Asbury Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Atco",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Atlantic City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Atlantic County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Atlantic Highlands",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Audubon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Audubon Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Avalon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Avenel",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Avon-by-the-Sea",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Barnegat",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Barrington",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bayonne",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bayville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Beach Haven",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Beach Haven West",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Beachwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Beattystown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Beckett",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bedminster",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Belford",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Belleville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bellmawr",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Belmar",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Belvidere",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bergen County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bergenfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Berkeley Heights",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Berlin",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bernardsville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Beverly",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Blackwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bloomfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bloomingdale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bogota",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Boonton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bordentown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bound Brook",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bradley Beach",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bradley Gardens",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Brass Castle",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bridgeton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Bridgewater",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Brielle",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Brigantine",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Brookdale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Brooklawn",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Browns Mills",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Brownville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Budd Lake",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Buena",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Burlington County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Butler",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Caldwell",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Califon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Camden County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cape May",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cape May County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cape May Court House",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Carlstadt",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Carneys Point",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Carteret",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cedar Glen Lakes",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cedar Glen West",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cedar Grove",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Chatham",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cherry Hill",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cherry Hill Mall",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Chesilhurst",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cinnaminson",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Clark",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Clearbrook Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Clementon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cliffside Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cliffwood Beach",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Clifton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Closter",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Collings Lakes",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Collingswood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Colonia",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Concordia",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Country Lake Estates",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cranbury",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Crandon Lakes",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cranford",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cresskill",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Crestwood Village",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Cumberland County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Delanco",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Demarest",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Dover Beaches North",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Dover Beaches South",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Dumont",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Dunellen",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "East Brunswick",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "East Franklin",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "East Freehold",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "East Hanover",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "East Newark",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "East Orange",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "East Rutherford",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Eatontown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Echelon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Edgewater",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Edgewater Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Edison",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Egg Harbor City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Elizabeth",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ellisburg",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Elmer",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Elmwood Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Elwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Emerson",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Englewood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Englewood Cliffs",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Englishtown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Erma",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Essex County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Essex Fells",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Estell Manor",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ewing",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Fair Haven",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Fair Lawn",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Fairton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Fanwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Farmingdale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Finderne",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Flemington",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Florham Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Folsom",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Fords",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Forked River",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Fort Dix",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Fort Lee",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Franklin Center",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Franklin Lakes",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Franklin Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Freehold",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Frenchtown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Garfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Garwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Gibbsboro",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Gibbstown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Gladstone",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Glassboro",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Glen Gardner",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Glen Ridge",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Glen Rock",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Glendora",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Gloucester City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Gloucester County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Golden Triangle",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Green Knoll",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Greentree",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Groveville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Guttenberg",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hackensack",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hackettstown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Haddon Heights",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Haddonfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Haledon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hamburg",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hamilton Square",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hammonton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hampton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hanover",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hardwick",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Harrington Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Harrison",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hasbrouck Heights",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Haworth",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hawthorne",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Heathcote",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Helmetta",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "High Bridge",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Highland Lake",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Highland Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Highlands",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hightstown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hillsdale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hillside",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ho-Ho-Kus",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hoboken",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Holiday City South",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Holiday City-Berkeley",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Holiday Heights",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hopatcong",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hopatcong Hills",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hopewell",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hudson County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Hunterdon County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Irvington",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Iselin",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Island Heights",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Jamesburg",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Jersey City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Keansburg",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Kearny",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Kendall Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Kenilworth",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Kenvil",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Keyport",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Kingston Estates",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Kinnelon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lake Como",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lake Mohawk",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lake Telemark",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lakehurst",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lakewood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lambertville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Landing",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Laurel Lake",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Laurel Springs",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Laurence Harbor",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lavallette",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lawnside",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lawrenceville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Leisure Knoll",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Leisure Village",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Leisure Village East",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Leisure Village West-Pine Lake Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Leisuretowne",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Leonardo",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Leonia",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lincoln Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lincroft",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Linden",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lindenwold",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Linwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Little Falls",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Little Ferry",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Little Silver",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Livingston",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lodi",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Long Branch",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Long Valley",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Lyndhurst",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Madison Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Magnolia",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mahwah",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Manahawkin",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Manasquan",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Manville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Maple Shade",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Maplewood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Margate City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Marlboro",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Marlton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Martinsville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Matawan",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mays Landing",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Maywood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "McGuire AFB",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Medford Lakes",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mendham",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mercer County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mercerville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mercerville-Hamilton Square",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Merchantville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Metuchen",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Middlebush",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Middlesex",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Middlesex County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Midland Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Milltown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Millville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Monmouth Beach",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Monmouth County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Monmouth Junction",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Montclair",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Montvale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Moonachie",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Moorestown-Lenola",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Morganville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Morris County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Morris Plains",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Morristown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mount Arlington",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mount Ephraim",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mount Holly",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mount Laurel",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mountain Lakes",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mountainside",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mullica Hill",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Mystic Island",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "National Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Navesink",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Neptune City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Netcong",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "New Brunswick",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "New Egypt",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "New Milford",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "New Providence",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Newark",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Newfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "North Arlington",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "North Beach Haven",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "North Bergen",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "North Caldwell",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "North Cape May",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "North Haledon",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "North Middletown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "North Plainfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "North Wildwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Northfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Northvale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Norwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Nutley",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Oak Valley",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Oakhurst",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Oaklyn",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ocean Acres",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ocean City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ocean County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ocean Gate",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ocean Grove",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Oceanport",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ogdensburg",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Old Bridge",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Old Tappan",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Olivet",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Oradell",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Orange",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Palisades Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Palmyra",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Paramus",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Park Ridge",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Parsippany",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Passaic",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Passaic County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Paterson",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Paulsboro",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Peapack",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pemberton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pemberton Heights",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pennington",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Penns Grove",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pennsauken",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pennsville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Perth Amboy",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Phillipsburg",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pine Beach",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pine Hill",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pine Lake Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pine Ridge at Crestwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Piscataway",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pitman",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Plainfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Plainsboro Center",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pleasantville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Point Pleasant",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Point Pleasant Beach",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pomona",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Pompton Lakes",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Port Monmouth",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Port Norris",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Port Reading",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Port Republic",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Presidential Lakes Estates",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Princeton Junction",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Princeton Meadows",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Prospect Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Rahway",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ramblewood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ramsey",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ramtown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Randolph",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Raritan",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Red Bank",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Richwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ridgefield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ridgefield Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ridgewood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ringwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Rio Grande",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "River Edge",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "River Vale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Riverdale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Riverton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Robbinsville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Robertsville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Rochelle Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Rockaway",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Roebling",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Roseland",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Roselle",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Roselle Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Rosenhayn",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Rossmoor",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Rumson",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Runnemede",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Rutherford",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Saddle Brook",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Saddle River",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Salem County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sayreville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sayreville Junction",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Scotch Plains",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sea Bright",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sea Girt",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sea Isle City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Seabrook Farms",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Seaside Heights",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Seaside Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Secaucus",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sewaren",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Shark River Hills",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ship Bottom",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Short Hills",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Shrewsbury",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sicklerville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Silver Ridge",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Singac",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sixmile Run",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Smithville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Society Hill",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Somerdale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Somers Point",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Somerset",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Somerset County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Somerville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "South Amboy",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "South Belmar",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "South Bound Brook",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "South Old Bridge",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "South Orange",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "South Plainfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "South River",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "South Toms River",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "South Vineland",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sparta",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Spotswood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Spring Lake",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Spring Lake Heights",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Springdale",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Stanhope",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Stratford",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Strathmore",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Succasunna",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Summit",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Surf City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sussex",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Sussex County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Swedesboro",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Teaneck",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ten Mile Run",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Tenafly",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Tinton Falls",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Toms River",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Totowa",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Tuckerton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Turnersville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Twin Rivers",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Union",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Union Beach",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Union City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Upper Montclair",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Upper Pohatcong",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Upper Saddle River",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Ventnor City",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Vernon Center",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Vernon Valley",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Verona",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Victory Gardens",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Victory Lakes",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Villas",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Vincentown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Vineland",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Vista Center",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Voorhees",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Waldwick",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wallington",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wanamassa",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wanaque",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Waretown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Warren Township",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Watchung",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wayne",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Weehawken",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wenonah",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "West Belmar",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "West Cape May",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "West Freehold",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "West Long Branch",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "West Milford",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "West New York",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "West Orange",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Westfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Weston",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Westville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Westwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wharton",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "White Horse",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "White Meadow Lake",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Whitehouse Station",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Whitesboro",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Whitesboro-Burleigh",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Whittingham",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wildwood",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wildwood Crest",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Williamstown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Willingboro",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Winfield",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wood-Lynne",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wood-Ridge",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Woodbine",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Woodbridge",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Woodbury",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Woodbury Heights",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Woodcliff Lake",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Woodland Park",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Woodstown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Wyckoff",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Yardville",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Yorketown",
    country_code: "US",
    state_code: "NJ",
  },
  {
    name: "Agua Fria",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Alamo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Alamogordo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Albuquerque",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Angel Fire",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Anthony",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Arenas Valley",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Arroyo Seco",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Artesia",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Atoka",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Aztec",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Bayard",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Belen",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Berino",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Bernalillo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Bernalillo County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Black Rock",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Bloomfield",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Boles Acres",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Bosque Farms",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Cannon Air Force Base",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Capitan",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Carlsbad",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Carnuel",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Carrizozo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Catron County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Chama",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Chaparral",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Chaves County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Chimayo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Church Rock",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Cibola County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Clovis",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Colfax County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Corrales",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Crownpoint",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Curry County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "De Baca County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Deming",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Dexter",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Doña Ana",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Doña Ana County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Dulce",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Eddy County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Edgewood",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "El Cerro",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "El Cerro Mission",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "El Rancho",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "El Valle de Arroyo Seco",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Eldorado at Santa Fe",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Elephant Butte",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Enchanted Hills",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Española",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Estancia",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Eunice",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Flora Vista",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Fort Sumner",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Gallup",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Grants",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Guadalupe County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Hagerman",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Harding County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Hatch",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Hidalgo County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Hobbs",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Holloman Air Force Base",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Hurley",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Jal",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Jarales",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Jemez Pueblo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Keeler Farm",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Kirtland",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "La Cienega",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "La Huerta",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "La Luz",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "La Mesilla",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "La Puebla",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "La Union",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Laguna",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Las Cruces",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Las Maravillas",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Las Vegas",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Lea County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Lee Acres",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Lordsburg",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Los Alamos",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Los Alamos County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Los Chavez",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Los Lunas",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Los Ranchos de Albuquerque",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Loving",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Lovington",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Luna County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "McIntosh",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "McKinley County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Meadow Lake",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Mescalero",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Mesilla",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Mesquite",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Milan",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Monterey Park",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Mora",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Mora County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Moriarty",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Mosquero",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Nambe",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Navajo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "North Valley",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Ohkay Owingeh",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Otero County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Paradise Hills",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Pecos",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Peralta",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Placitas",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Pojoaque",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Ponderosa Pine",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Portales",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Quay County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Questa",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Radium Springs",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Ranchos de Taos",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Raton",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Reserve",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Rio Arriba County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Rio Communities",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Rio Rancho",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Roosevelt County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Roswell",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Ruidoso",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Ruidoso Downs",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "San Felipe Pueblo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "San Juan County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "San Miguel",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "San Miguel County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "San Ysidro",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Sandia Heights",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Sandia Knolls",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Sandoval County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Santa Clara",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Santa Clara Pueblo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Santa Fe",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Santa Fe County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Santa Rosa",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Santa Teresa",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Santo Domingo Pueblo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Shiprock",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Sierra County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Silver City",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Skyline-Ganipa",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Socorro",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Socorro County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "South Valley",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Spencerville",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Sunland Park",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Taos",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Taos County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Taos Pueblo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Texico",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Thoreau",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Tierra Amarilla",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Tome",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Torrance County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Truth or Consequences",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Tucumcari",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Tularosa",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Twin Lakes",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "University Park",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Upper Fruitland",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Vado",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Valencia",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Valencia County",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Waterflow",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "West Hammond",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "White Rock",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "White Sands",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Zuni Pueblo",
    country_code: "US",
    state_code: "NM",
  },
  {
    name: "Adams",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Adams Center",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Addison",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Airmont",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Akron",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Alabama",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Albany County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Albertson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Albion",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Alden",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Alexandria Bay",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Alfred",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Allegany",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Allegany County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Altamont",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Amagansett",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Amherst",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Amityville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Amsterdam",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Andover",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Angola",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Angola on the Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Apalachin",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Aquebogue",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Arcade",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ardsley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Armonk",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Arrochar",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Arverne",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Astoria",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Atlantic Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Attica",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Augusta",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Averill Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Avon",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Babylon",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bainbridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Baiting Hollow",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Baldwin",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Baldwin Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Baldwinsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ballston Spa",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Balmville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bardonia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Barnum Island",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Batavia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bath",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bath Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Baxter Estates",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bay Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bay Shore",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bay Wood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Baychester",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bayport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bayside",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bayville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Beacon",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Beaver Dam Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Beaverdam Lake-Salisbury Mills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bedford Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bellaire",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Belle Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bellerose",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bellerose Terrace",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bellmore",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bellport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Belmont",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bensonhurst",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bergen",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bergen Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bethpage",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Big Flats",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Billington Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Binghamton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Black River",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Blasdell",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Blauvelt",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bloomfield",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Blue Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bohemia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bolivar",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Boonville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Borough Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Boston",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brentwood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brewerton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brewster",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brewster Hill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Briarcliff Manor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Briarwood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bridgehampton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brighton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brighton Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brightwaters",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brinckerhoff",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Broad Channel",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Broadalbin",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brockport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brocton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bronx",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bronxville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brookhaven",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brooklyn",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brooklyn Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brookville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Broome County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brownsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Brownville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Buchanan",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Bushwick",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cairo",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Calcium",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Caledonia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Calverton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cambria Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cambridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Camillus",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Canajoharie",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Canandaigua",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Canarsie",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Canastota",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Canisteo",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Carle Place",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Carmel",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Carmel Hamlet",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Carthage",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Castleton-on-Hudson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Catskill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cattaraugus County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cayuga County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cayuga Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cazenovia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cedarhurst",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Celoron",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Center Moriches",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Centereach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Centerport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Central Islip",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Central Square",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Central Valley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chadwicks",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Champlain",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chappaqua",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Charleston",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chatham",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chautauqua County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cheektowaga",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chelsea",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chemung County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chenango Bridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chenango County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chestnut Ridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Chittenango",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Churchville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "City Island",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clarence",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clarence Center",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clark Mills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clarkson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clifton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clifton Springs",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clinton County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clintondale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clyde",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Clymer",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cobleskill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cohoes",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cold Spring",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cold Spring Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "College Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Colonie",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Columbia County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Commack",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Coney Island",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Congers",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Constantia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cooperstown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Copiague",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Coram",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Corinth",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Corning",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cornwall",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Corona",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cortland",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cortland County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cortland West",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Country Knolls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Coxsackie",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Crompond",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Croton-on-Hudson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Crown Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Crugers",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cuba",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cumberland Head",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cutchogue",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Cypress Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dannemora",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dansville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Deer Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Delaware County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Delevan",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Delhi",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Delmar",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Depew",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Deposit",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dexter",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dix Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dobbs Ferry",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dolgeville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dongan Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Douglaston",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dover Plains",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dryden",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dundee",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dunkirk",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Durham",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dutchess County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Dyker Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Atlantic Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Aurora",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Elmhurst",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Farmingdale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Flatbush",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Garden City",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Glenville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Greenbush",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Hampton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Hampton North",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Harlem",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Islip",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Ithaca",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Massapequa",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Meadow",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Moriches",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East New York",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Northport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Norwich",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Patchogue",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Quogue",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Rochester",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Rockaway",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Setauket",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Shoreham",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Syracuse",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Tremont",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Village",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "East Williston",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Eastchester",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Eastport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Eatons Neck",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Eden",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Edgemere",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Edinburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Eggertsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Elbridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Elizabethtown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ellenville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Elma Center",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Elmhurst",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Elmira",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Elmira Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Elmont",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Elmsford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Eltingville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Elwood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Emerson Hill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Endicott",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Endwell",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Erie County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Essex County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fairmount",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fairport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Falconer",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fallsburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Far Rockaway",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Farmingdale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Farmingville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fayetteville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Financial District",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Firthcliffe",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fishkill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Flanders",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Flatbush",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Flatlands",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Floral Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Florida",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Flower Hill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fonda",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fordham",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Forest Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fort Covington Hamlet",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fort Drum",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fort Edward",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fort Hamilton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fort Montgomery",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fort Plain",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fort Salonga",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fort Wadsworth",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Frankfort",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Franklin Square",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Franklinville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fredonia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Freeport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fresh Meadows",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Frewsburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Friendship",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fulton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Fulton County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Galeville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gang Mills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Garden City",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Garden City Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Garden City South",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gardnertown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gasport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gates-North Gates",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Genesee County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Geneseo",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Geneva",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Glasco",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Glen Cove",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Glen Head",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Glen Oaks",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Glendale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Glens Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Glens Falls North",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Glenwood Landing",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gloversville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Goldens Bridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gordon Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Goshen",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gouverneur",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gowanda",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gramercy Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Grand Island",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Grandyle Village",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Graniteville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Grant City",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Granville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Gravesend",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Great Kills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Great Neck",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Great Neck Estates",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Great Neck Gardens",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Great Neck Plaza",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Great River",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greece",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Green Island",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greenburgh",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greene",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greenlawn",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greenpoint",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greenport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greenport West",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greenvale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greenwich",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Greenwood Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Groton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Grymes Hill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hadley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hagaman",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Halesite",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hamburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hamilton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hamlin",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hampton Bays",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hampton Manor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hannawa Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Harbor Isle",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Harlem",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Harriman",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Harris Hill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Harrison",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hartsdale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hastings-on-Hudson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hauppauge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Haverstraw",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Haviland",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hawthorne",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Head of the Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hell's Kitchen",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hempstead",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Heritage Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Herkimer",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Herkimer County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Herricks",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hewlett",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hewlett Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hicksville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Highland",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Highland Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Highland Mills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hillcrest",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hillside",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hillside Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hilton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Holbrook",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Holcomb",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Holland",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Holley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hollis",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Holtsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Homer",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Honeoye Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hoosick Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hornell",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Horseheads",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Horseheads North",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Houghton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Howard Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hudson Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Huguenot",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Huntington",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Huntington Bay",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Huntington Station",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hunts Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hurley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Hyde Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ilion",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Inwood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Irondequoit",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Irvington",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Island Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Islandia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Islip",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Islip Terrace",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ithaca",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jackson Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jamaica",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jamesport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jamestown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jamestown West",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jefferson Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jefferson Valley-Yorktown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jericho",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Johnson City",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Johnstown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Jordan",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kaser",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Katonah",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Keeseville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kenmore",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kensington",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kerhonkson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Keuka Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kew Gardens",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kew Gardens Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kiantone",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kinderhook",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kings Bridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kings County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kings Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kings Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Kiryas Joel",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lackawanna",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Carmel",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Erie Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Grove",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Katrine",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Luzerne",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Mohegan",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Placid",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Pleasant",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Ronkonkoma",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lake Success",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lakeland",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lakeview",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lakewood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lansing",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Larchmont",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Latham",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lattingtown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Laurel",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Laurel Hollow",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Laurelton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lawrence",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Le Roy",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Levittown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lewis County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lewiston",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lido Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lima",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lincoln Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lincolndale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lindenhurst",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lindley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Little Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Little Neck",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Little Valley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Liverpool",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Livingston County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Livingston Manor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Livonia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lloyd Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lockport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Locust Valley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Long Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Long Island City",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lorenz Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lowville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lynbrook",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lyncourt",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Lyons",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Macedon",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mahopac",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Malone",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Malverne",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mamaroneck",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Manhasset",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Manhasset Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Manhattan",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Manlius",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Manorhaven",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Manorville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Marbletown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Marcellus",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mariners Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Marlboro",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Maspeth",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Massapequa",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Massapequa Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Massena",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mastic",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mastic Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mattituck",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mattydale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Maybrook",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mayville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "McGraw",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "McKownville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mechanicstown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mechanicville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Medford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Medina",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Melrose",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Melrose Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Melville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Menands",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Merrick",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Merritt Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mexico",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Middle Island",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Middle Village",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Middleburgh",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Middleport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Midland Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mill Neck",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Millbrook",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Miller Place",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mineola",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Minetto",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mineville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Minoa",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mohawk",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Monsey",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Montauk",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Montebello",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Montgomery",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Montour Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Montrose",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Moravia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Moriches",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Morningside Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Morris Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Morris Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Morrisania",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Morrisonville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Morrisville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mott Haven",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mount Ivy",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mount Kisco",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mount Morris",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mount Sinai",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Mountain Lodge Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Munsey Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Munsons Corners",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Muttontown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Myers Corner",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Nanuet",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Napanoch",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Naples",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Nassau",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Nassau County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Nedrow",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Neponsit",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Nesconset",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Brighton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Cassel",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New City",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Dorp",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Dorp Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Hartford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Hempstead",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Hyde Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Paltz",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Rochelle",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Springville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Square",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New Windsor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New York City",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New York County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "New York Mills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Newark",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Newburgh",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Newfane",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Niagara County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Niagara Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Niskayuna",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Nissequogue",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Niverville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Norfolk",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Amityville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Babylon",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Ballston Spa",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Bay Shore",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Bellmore",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Bellport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Boston",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Castle",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Collins",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Elba",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Gates",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Great River",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Lindenhurst",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Massapequa",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Merrick",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North New Hyde Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Patchogue",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Sea",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Syracuse",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Tonawanda",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Valley Stream",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "North Wantagh",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Northeast Ithaca",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Northport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Northumberland",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Northville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Northwest Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Northwest Ithaca",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Norwich",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Norwood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Noyack",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Nunda",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Nyack",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oakdale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oakfield",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oakwood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oceanside",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ogdensburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Olcott",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Old Bethpage",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Old Brookville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Old Westbury",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Olean",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oneida",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oneida County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oneonta",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Onondaga County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ontario",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ontario County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Orange County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Orange Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Orangeburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Orchard Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oriskany",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Orleans County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ossining",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oswego",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oswego County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Otisville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Otsego County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Owego",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oyster Bay",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Oyster Bay Cove",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ozone Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Painted Post",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Palenville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Palmyra",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Park Slope",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Parkchester",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Patchogue",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pawling",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Peach Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pearl River",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Peekskill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pelham",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pelham Manor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Penn Yan",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Perry",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Perth",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Peru",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Phelps",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Philadelphia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Philmont",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Phoenix",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Piermont",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pine Bush",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pine Plains",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pittsford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Plainedge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Plainview",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Plandome",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Plandome Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Plattekill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Plattsburgh",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Plattsburgh West",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pleasant Valley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pleasantville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Poestenkill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Point Lookout",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pomona",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Byron",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Chester",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Dickinson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Ewen",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Henry",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Jefferson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Jefferson Station",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Jervis",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Morris",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Richmond",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Washington",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Port Washington North",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Portland",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Potsdam",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Poughkeepsie",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pound Ridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Pulaski",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Purchase",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Putnam County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Putnam Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Queens",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Queens County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Queens Village",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Queensbury",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Randolph",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ransomville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rapids",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ravena",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Red Hook",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Red Oaks Mill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rego Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Remsenburg-Speonk",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rensselaer",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rensselaer County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rhinebeck",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Richfield Springs",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Richland",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Richmond County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Richmond Hill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ridgewood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Riverdale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Riverhead",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rock Hill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rockaway Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rockland County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rockville Centre",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rocky Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Roessleville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rome",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ronkonkoma",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Roosevelt",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rosebank",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rosedale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rosendale Village",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Roslyn",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Roslyn Estates",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Roslyn Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Roslyn Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rossville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rotterdam",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rouses Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rye",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Rye Brook",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sackets Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sag Harbor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Saint Bonaventure",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Saint James",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Saint Johnsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Salamanca",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Salisbury",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sanborn",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sands Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Saranac Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Saratoga County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Saratoga Springs",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Saugerties",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Saugerties South",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sayville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Scarsdale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Schenectady",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Schenectady County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Schoharie",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Schoharie County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Schuyler County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Schuylerville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Scotchtown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Scotia",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Scottsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sea Cliff",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Seaford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Searingtown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Seaside",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Selden",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Seneca County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Seneca Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Seneca Knolls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Setauket-East Setauket",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sheepshead Bay",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Shelter Island",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Shelter Island Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Shenorock",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sherburne",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sherrill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Shinnecock Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Shirley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Shokan",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Shortsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Shrub Oak",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sidney",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Silver Creek",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Skaneateles",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sleepy Hollow",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sloan",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sloatsburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Smithtown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sodus",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Solvay",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sound Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Blooming Grove",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Corning",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Fallsburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Farmingdale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Floral Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Glens Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Hempstead",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Hill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Huntington",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Lockport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Nyack",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "South Valley Stream",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Southampton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Southold",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Southport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Spackenkill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sparkill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Spencerport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Spring Valley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Springfield Gardens",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Springs",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Springville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Spuyten Duyvil",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "St. Lawrence County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Stamford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Stapleton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Staten Island",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Steinway",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Steuben County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Stewart Manor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Stillwater",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Stone Ridge",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Stony Brook",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Stony Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Stottville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Suffern",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Suffolk County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sullivan County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sunnyside",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sunset Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Sylvan Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Syosset",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Syracuse",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tappan",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tarrytown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Terrace Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Terryville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "The Bronx",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Thiells",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Thomaston",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Thornwood",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Throgs Neck",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ticonderoga",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tillson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tioga County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tivoli",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tompkins County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tompkinsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tonawanda",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Town Line",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tremont",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tribes Hill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Trumansburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tuckahoe",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Tupper Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Ulster County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Unadilla",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Union Springs",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Uniondale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Unionport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "University Gardens",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "University Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Upper Brookville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Upper Nyack",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Utica",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Vails Gate",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Valatie",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Valhalla",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Valley Cottage",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Valley Stream",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Van Nest",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Vernon",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Verplanck",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Victor",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Village Green",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Village of the Branch",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Viola",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Volney",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Voorheesville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wading River",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wakefield",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Walden",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wallkill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Walton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Walton Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wampsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wanakah",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wantagh",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wappingers Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Warrensburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Warsaw",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Warwick",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Washington Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Washington Mills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Washingtonville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Watchtower",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Water Mill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Waterford",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Waterloo",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Watertown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Waterville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Watervliet",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Watkins Glen",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Waverly",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wawarsing",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wayland",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Webster",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Weedsport",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wellsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wesley Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Albany",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Babylon",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Bay Shore",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Carthage",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Elmira",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West End",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Glens Falls",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Haverstraw",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Hempstead",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Hills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Hurley",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Islip",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Nyack",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Point",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Sand Lake",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Sayville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "West Seneca",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Westbury",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Westchester County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Westerleigh",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Westfield",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Westhampton",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Westhampton Beach",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Westmere",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Weston Mills",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Westvale",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wheatley Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "White Plains",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Whitehall",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Whitesboro",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Whitestone",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Williamsburg",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Williamson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Williamsville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Williston Park",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Willowbrook",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wilson",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wolcott",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Woodbury",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Woodhaven",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Woodlawn",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Woodmere",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Woodrow",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Woodside",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Woodstock",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Worcester",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wurtsboro",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wyandanch",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wykagyl",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wynantskill",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Wyoming County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Yaphank",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Yates County",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Yonkers",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Yorkshire",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Yorktown Heights",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Yorkville",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Youngstown",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Zena",
    country_code: "US",
    state_code: "NY",
  },
  {
    name: "Aberdeen",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Advance",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Ahoskie",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Alamance County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Albemarle",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Alexander County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Alleghany County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Andrews",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Angier",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Anson County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Apex",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Archdale",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Archer Lodge",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Ashe County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Asheboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Asheville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Atlantic Beach",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Avery County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Avery Creek",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Ayden",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Badin",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bakersville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Balfour",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Banner Elk",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Barker Heights",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bayboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bayshore",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Beaufort",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Beaufort County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Belhaven",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Belmont",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Belville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Benson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bent Creek",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bermuda Run",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bertie County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bessemer City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bethel",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bethlehem",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Beulaville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Biltmore Forest",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Biscoe",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Black Mountain",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bladen County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bladenboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Blowing Rock",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Boiling Spring Lakes",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Boiling Springs",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bolivia",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Boone",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Boonville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Brevard",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Brices Creek",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Broad Creek",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Broadway",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Brogden",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Brunswick",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Brunswick County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Bryson City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Buies Creek",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Buncombe County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Burgaw",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Burke County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Burnsville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Butner",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Buxton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cabarrus County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cajahs Mountain",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Calabash",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Caldwell County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Camden County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cape Carteret",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Carolina Beach",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Carolina Shores",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Carrboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Carteret County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Carthage",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cary",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Castle Hayne",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Caswell County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Catawba County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cedar Point",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Chadbourn",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Chapel Hill",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Charlotte",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Chatham County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cherokee",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cherokee County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cherryville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "China Grove",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Chowan County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Claremont",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Clemmons",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cleveland County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Clyde",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Coats",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Columbus County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Connelly Springs",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Conover",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cordova",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cornelius",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cove Creek",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cramerton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Craven County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Creedmoor",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cricket",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cullowhee",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Cumberland County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Currituck",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Currituck County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Dallas",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Dana",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Danbury",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Dare County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Davidson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Davidson County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Davie County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Denton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Denver",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Dobson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Drexel",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Dunn",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Duplin County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Durham",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Durham County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "East Flat Rock",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "East Rockingham",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "East Spencer",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Eastover",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Eden",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Edenton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Edgecombe County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Edneyville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Elizabeth City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Elizabethtown",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Elkin",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Ellerbe",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Elm City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Elon",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Elroy",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Emerald Isle",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Enfield",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Enochville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Erwin",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Etowah",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fairfield Harbour",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fairmont",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fairplains",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Farmville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fayetteville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fearrington Village",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Flat Rock",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fletcher",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Forest City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Forest Oaks",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Forsyth County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fort Bragg",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Foscoe",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Four Oaks",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Franklinton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Franklinville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fremont",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fruitland",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Fuquay-Varina",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Gamewell",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Garner",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Gaston",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Gaston County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Gastonia",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Gates County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Gatesville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Gibsonville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Glen Alpine",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Glen Raven",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Goldsboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Gorman",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Graham",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Graham County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Granite Falls",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Granite Quarry",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Granville County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Green Level",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Greensboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Grifton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Guilford County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Half Moon",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Halifax",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Halifax County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hamlet",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hampstead",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Harkers Island",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Harnett County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Harrisburg",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Havelock",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Haw River",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hayesville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hays",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Haywood County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hazelwood",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hemby Bridge",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Henderson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Henderson County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hendersonville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hertford",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hertford County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hickory",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "High Point",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hildebran",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hillsborough",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hoke County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Holly Ridge",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Holly Springs",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hoopers Creek",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hope Mills",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Horse Shoe",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Huntersville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Hyde County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Icard",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Indian Trail",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Iredell County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Jacksonville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "James City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Jamestown",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Johnston County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Jones County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Jonesville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kannapolis",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kenansville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kenly",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kernersville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kill Devil Hills",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "King",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kings Grant",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kings Mountain",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kinston",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kitty Hawk",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Knightdale",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Kure Beach",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "La Grange",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lake Junaluska",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lake Lure",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lake Norman of Catawba",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lake Park",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lake Waccamaw",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Landis",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Laurel Hill",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Laurel Park",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Laurinburg",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Leland",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lenoir",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lenoir County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lewisville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lillington",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lincolnton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Locust",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Long Beach",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Longview",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Louisburg",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lowell",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lowesville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lucama",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Lumberton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Macon County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Maggie Valley",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Maiden",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Manteo",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mar-Mac",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mars Hill",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Marshall",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Marshville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Martin County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Marvin",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Masonboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Matthews",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Maury",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Maxton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mayodan",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Maysville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "McDowell County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "McLeansville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mebane",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mecklenburg County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Midland",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Midway",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Millers Creek",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mills River",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mineral Springs",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mint Hill",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mitchell County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mocksville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Moore County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mooresville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Moravian Falls",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Morehead City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Morganton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Morrisville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mount Airy",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mount Gilead",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mount Holly",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mount Olive",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mount Pleasant",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mountain Home",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mountain View",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Moyock",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Mulberry",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Murfreesboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Murphy",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Murraysville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Myrtle Grove",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Nags Head",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Nash County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Nashville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Navassa",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Neuse Forest",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "New Bern",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "New Hanover County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Newland",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Norlina",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "North Wilkesboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Northampton County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Northchase",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Northlakes",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Norwood",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Oak Island",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Oak Ridge",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Oakboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Ogden",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Onslow County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Orange County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pamlico County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pasquotank County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pembroke",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pender County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Perquimans County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Person County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pilot Mountain",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pine Knoll Shores",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pine Level",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pinebluff",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pinehurst",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pinetops",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pineville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Piney Green",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pitt County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pittsboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Plain View",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pleasant Garden",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pleasant Hill",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Polkton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Princeville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Pumpkin Center",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Raeford",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Raleigh",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Ramseur",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Randleman",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Randolph County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Ranlo",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Red Oak",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Red Springs",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Reidsville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rhodhiss",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Richlands",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Richmond County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "River Bend",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "River Road",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Roanoke Rapids",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Robbins",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Robbinsville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Robersonville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Robeson County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rockfish",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rockingham",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rockingham County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rockwell",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rocky Mount",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rocky Point",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rolesville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rose Hill",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Roseboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rowan County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rowland",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Roxboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Royal Pines",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rural Hall",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rutherford College",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rutherford County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Rutherfordton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Saint James",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Saint Pauls",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Saint Stephens",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Salisbury",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sampson County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sanford",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sawmills",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Saxapahaw",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Scotland County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Scotland Neck",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Scotts Mill",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sea Breeze",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Seagate",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Selma",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Seven Lakes",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Shallotte",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sharpsburg",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Shelby",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sherrills Ford",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Siler City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Silver Lake",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Skippers Corner",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Smithfield",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sneads Ferry",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Snow Hill",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "South Gastonia",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "South Henderson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "South Rosemary",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Southern Pines",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Southern Shores",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Southmont",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Southport",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sparta",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Spencer",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Spindale",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Spring Hope",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Spring Lake",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Spruce Pine",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Stallings",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Stanfield",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Stanley",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Stanly County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Statesville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Stedman",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Stokes County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Stokesdale",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Stoneville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Stony Point",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Summerfield",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sunset Beach",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Surf City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Surry County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Swain County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Swannanoa",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Swanquarter",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Swansboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Swepsonville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Sylva",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Tabor City",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Tarboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Taylorsville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Thomasville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Thurmond",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Toast",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Tobaccoville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Transylvania County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Trent Woods",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Trinity",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Troutman",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Tryon",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Tyro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Tyrrell County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Unionville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Valdese",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Valley Hill",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Vance County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Vander",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wadesboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wake County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wake Forest",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Walkertown",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wallace",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wallburg",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Walnut Cove",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wanchese",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Warrenton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Warsaw",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Watauga County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Waxhaw",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Waynesville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Weaverville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Weddington",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Welcome",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Weldon",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wendell",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wentworth",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wesley Chapel",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "West Canton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "West Jefferson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "West Marion",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "West Raleigh",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Westport",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Whispering Pines",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "White Plains",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Whiteville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wilkes County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wilkesboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Williamston",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wilmington",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wilson",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wilson County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wilsons Mills",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wingate",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Winston-Salem",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Winterville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Winton",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Woodfin",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wrightsboro",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Wrightsville Beach",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Yadkin County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Yadkinville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Yancey County",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Yanceyville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Youngsville",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Zebulon",
    country_code: "US",
    state_code: "NC",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Amidon",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Ashley",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Barnes County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Beach",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Belcourt",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Belfield",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Benson County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Beulah",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Billings County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Bismarck",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Bottineau",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Bottineau County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Bowbells",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Bowman",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Bowman County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Burke County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Burleigh County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Cando",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Carrington",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Carson",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Cass County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Casselton",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Cavalier",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Cavalier County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Center",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Cooperstown",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Crosby",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Devils Lake",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Dickey County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Dickinson",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Divide County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Dunn County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Eddy County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Ellendale",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Emmons County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Fargo",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Fessenden",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Finley",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Forman",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Fort Totten",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Fort Yates",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Foster County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Garrison",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Golden Valley County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Grafton",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Grand Forks",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Grand Forks Air Force Base",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Grand Forks County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Griggs County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Harvey",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Hazen",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Hettinger",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Hettinger County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Hillsboro",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Horace",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Jamestown",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Kenmare",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Kidder County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Killdeer",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Lakota",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "LaMoure County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Langdon",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Larimore",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Linton",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Lisbon",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Mandan",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Manning",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Mayville",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "McClusky",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "McHenry County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "McIntosh County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "McKenzie County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "McLean County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Medora",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Mercer County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Minnewaukan",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Minot",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Minot Air Force Base",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Mohall",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Morton County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Mott",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Mountrail County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Napoleon",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Nelson County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "New Rockford",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "New Town",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Oakes",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Oliver County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Park River",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Parshall",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Pembina County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Pierce County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Ramsey County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Ransom County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Renville County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Richland County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Rolette County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Rolla",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Rugby",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Sargent County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Sheldon",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Shell Valley",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Sheridan County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Sioux County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Slope County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Stanley",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Stanton",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Stark County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Steele",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Steele County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Stutsman County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Surrey",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Thompson",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Tioga",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Towner",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Towner County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Traill County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Valley City",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Velva",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Wahpeton",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Walsh County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Ward County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Washburn",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Watford City",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Wells County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "West Fargo",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Williams County",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Williston",
    country_code: "US",
    state_code: "ND",
  },
  {
    name: "Ada",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Akron",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Allen County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Alliance",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Amberley",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Amelia",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Amherst",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Andover",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Anna",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ansonia",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Antwerp",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Apple Creek",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Apple Valley",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Arcanum",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Archbold",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ashland County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ashley",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ashtabula",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ashtabula County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ashville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Athens County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Auglaize County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Aurora",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Austintown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Avon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Avon Center",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Avon Lake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bainbridge",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ballville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Baltimore",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Barberton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Barnesville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Batavia",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bay Village",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Beach City",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Beachwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Beavercreek",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Beckett Ridge",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bedford Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Beechwood Trails",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bellaire",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bellbrook",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bellefontaine",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bellville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Belmont County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Belpre",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Berea",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bethel",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bethesda",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Beverly",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bexley",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Blacklick Estates",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Blanchester",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Blue Ash",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bluffton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Boardman",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bolindale",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Boston Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Botkins",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bowling Green",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bradford",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bradner",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bratenahl",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brecksville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bremen",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brewster",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bridgetown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brilliant",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brimfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Broadview Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brook Park",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brookfield Center",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brooklyn",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brooklyn Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brookville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brown County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Brunswick",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bryan",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Buckeye Lake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Bucyrus",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Burton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Butler County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Byesville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cadiz",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Calcutta",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Caldwell",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cambridge",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Campbell",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Canal Fulton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Canal Winchester",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Canfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cardington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Carey",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Carlisle",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cedarville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Celina",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Centerburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Chagrin Falls",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Champaign County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Champion Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Chardon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Chauncey",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cherry Grove",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Chesterland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cheviot",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Chillicothe",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Choctaw Lake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Churchill",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cincinnati",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Circleville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Clark-Fulton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Clayton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Clermont County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cleveland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cleveland Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cleves",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Clinton County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Clyde",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Coal Grove",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Coldwater",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Collinwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Columbiana",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Columbiana County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Columbus Grove",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Commercial Point",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Conneaut",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Continental",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Convoy",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Copley",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cortland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Coshocton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Coshocton County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Covedale",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Covington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Craig Beach",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Crestline",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Creston",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cridersville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Crooksville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Crystal Lakes",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Curtice",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cuyahoga County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Cuyahoga Falls",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dalton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Darke County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Day Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "De Graff",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Deer Park",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Defiance",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Defiance County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Delaware",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Delaware County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Delhi Hills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Delphos",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Delta",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dennison",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dent",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Deshler",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Detroit-Shoreway",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Devola",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dillonvale",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Doylestown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dresden",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Drexel",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dry Ridge",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dry Run",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dublin",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Dunlap",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "East Canton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "East Cleveland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "East Liverpool",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "East Palestine",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Eastlake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Eaton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Eaton Estates",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Edgerton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Edgewood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Elida",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Elmore",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Elmwood Place",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Elyria",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Englewood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Enon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Erie County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Etna",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Euclid",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Evendale",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fairborn",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fairfax",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fairfield Beach",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fairfield County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fairlawn",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fairport Harbor",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fairview Park",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Farmersville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fayette",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Findlay",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Finneytown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Five Points",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Forest",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Forest Park",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Forestville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fort Loramie",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fort McKinley",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fort Recovery",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fort Shawnee",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fostoria",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Frankfort",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Franklin Furnace",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Frazeysburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fredericktown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fremont",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fruit Hill",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Fulton County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Gahanna",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Galion",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Gallia County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Gallipolis",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Gambier",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Garfield Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Garrettsville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Gates Mills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Geauga County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Geneva",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Geneva-on-the-Lake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Genoa",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Germantown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Gibsonburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Girard",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Glandorf",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Glendale",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Glenmoor",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Glenville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Glouster",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Gnadenhutten",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Golf Manor",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Goshen",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Grafton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Grandview",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Grandview Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Granville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Granville South",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Green",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Green Meadows",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Green Springs",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Greenhills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Greensburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Greentown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Greenwich",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Groesbeck",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Grove City",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Groveport",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Guernsey County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hamilton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hanover",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Harbor Hills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hardin County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Harrison",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Harrison County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hartville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Haskins",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Heath",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hebron",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hicksville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Highland County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Highland Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Highpoint",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hilliard",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hillsboro",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hiram",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hocking County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Holgate",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Holiday Valley",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Holland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Holmes County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hough",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Howland Center",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hubbard",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Huber Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Huber Ridge",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Hunter",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Huron",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Huron County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ironton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Jackson Center",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Jamestown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Jeffersonville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Johnstown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Kalida",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Kent",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Kenton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Kenwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Kettering",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Kings Mills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Kirtland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Knox County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "La Croft",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lagrange",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lake Darby",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lake Lakengren",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lake Mohawk",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lakemore",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lakeview",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lakewood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Landen",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Leavittsburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Leesburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Leetonia",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Leipsic",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lewis Center",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lewisburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Liberty Center",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Licking County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lima",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lincoln Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lincoln Village",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lisbon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lithopolis",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lockland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lodi",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Logan",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Logan Elm Village",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "London",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lorain",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lorain County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lordstown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Loudonville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Louisville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Loveland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Loveland Park",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lowellville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lucas County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lucasville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Luckey",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lynchburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Lyndhurst",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Macedonia",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mack",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Madeira",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mahoning County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Malvern",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mansfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mantua",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Maple Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mariemont",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Marietta",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Martins Ferry",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Marysville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mason",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Massillon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Masury",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Maumee",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mayfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mayfield Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "McArthur",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "McComb",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "McConnelsville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "McDonald",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "McKinley Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mechanicsburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Medina",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Medina County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Meigs County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mentor",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mentor-on-the-Lake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mercer County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Miami County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Miami Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Miamisburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Miamitown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Middleburg Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Middlefield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Middleport",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Milan",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Millbury",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Millersburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Millersport",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mineral Ridge",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Minerva",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Minerva Park",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mingo Junction",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Minster",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mogadore",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Monfort Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Monroeville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Montgomery",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Montpelier",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Montrose-Ghent",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Moraine",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Moreland Hills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Morgandale",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Morrow",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Morrow County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mount Carmel",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mount Gilead",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mount Healthy",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mount Healthy Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mount Orab",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mount Repose",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mount Sterling",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Mulberry",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Munroe Falls",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Muskingum County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Napoleon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Navarre",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Nelsonville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Albany",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Boston",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Bremen",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Burlington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New California",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Carlisle",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Concord",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Franklin",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Lebanon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Lexington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New London",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Matamoras",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Miami",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Middletown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Paris",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Philadelphia",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Richmond",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Vienna",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "New Waterford",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Newark",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Newburgh Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Newcomerstown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Newton Falls",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Newtown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Niles",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Noble County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Baltimore",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Canton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North College Hill",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Fork Village",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Kingsville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Lewisburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Madison",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Olmsted",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Randall",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Ridgeville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Royalton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "North Zanesville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Northbrook",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Northfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Northgate",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Northridge",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Northwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Norton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Norwalk",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Norwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Oak Harbor",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Oak Hill",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Oakwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Oberlin",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Obetz",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Olmsted Falls",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ontario",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Orange",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Oregon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Orrville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Orwell",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ottawa",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ottawa County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ottawa Hills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Painesville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pandora",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Park Layne",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Parma",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Parma Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pataskala",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Paulding",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Paulding County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Payne",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Peebles",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pemberville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pepper Pike",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Perry",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Perry Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Perrysburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pickaway County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pickerington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Piketon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pioneer",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Piqua",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Plain City",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pleasant Grove",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pleasant Hill",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pleasant Run",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pleasant Run Farm",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Poland",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Pomeroy",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Port Clinton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Portage County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Portage Lakes",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Portsmouth",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Powell",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Powhatan Point",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Preble County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Prospect",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Putnam County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ravenna",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Reading",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Reminderville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Reno",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Reynoldsburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Richfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Richland County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Richmond Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Richville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Richwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ripley",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Rittman",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Roaming Shores",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Rockford",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Rocky River",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Rosemount",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Roseville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ross",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ross County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Rossford",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Rossmoyne",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Russells Point",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sabina",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Saint Bernard",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Saint Clairsville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Saint Henry",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Saint Marys",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Saint Paris",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Salem Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Salineville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sandusky",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sandusky County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sawyerwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Scioto County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sciotodale",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sebring",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Seneca County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Seven Hills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Seville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Shadyside",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Shaker Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sharonville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Shawnee Hills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sheffield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sheffield Lake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Shelby",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Shelby County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sherwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Shiloh",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Shreve",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sidney",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Silver Lake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Silverton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sixteen Mile Stand",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Skyline Acres",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Smithville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Solon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Somerset",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "South Amherst",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "South Bloomfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "South Canal",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "South Charleston",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "South Euclid",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "South Lebanon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "South Point",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "South Russell",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "South Zanesville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Spencerville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Springboro",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Springdale",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Stark County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Steubenville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Stony Prairie",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Stow",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Strasburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Streetsboro",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Strongsville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Struthers",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Stryker",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sugarcreek",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sugarcreek Police Dept",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Summerside",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Summit County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sunbury",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Swanton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Sylvania",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Tallmadge",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Terrace Park",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "The Plains",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "The Village of Indian Hill",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Thornport",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Tiffin",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Tiltonsville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Tipp City",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Toledo",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Toronto",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Trotwood",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Trumbull County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Turpin Hills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Tuscarawas",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Tuscarawas County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Twinsburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Uhrichsville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Union",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Union City",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Uniontown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "University Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Upper Arlington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Upper Sandusky",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Urbana",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Urbancrest",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Utica",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Valley View",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Van Wert",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Van Wert County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Vandalia",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Vermilion",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Vermilion-on-the-Lake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Versailles",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Vinton County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wadsworth",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wakeman",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Walbridge",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Walton Hills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wapakoneta",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Warrensville Heights",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Washington Court House",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Waterville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wauseon",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Waverly",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Waynesville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wellington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wellston",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wellsville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Alexandria",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Carrollton City",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Hill",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Jefferson",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Lafayette",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Liberty",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Milton",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Portsmouth",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Salem",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Union",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "West Unity",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Westerville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Westfield Center",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Westlake",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Weston",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wetherington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wheelersburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "White Oak",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Whitehall",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Whitehouse",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wickliffe",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wilberforce",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Willard",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Williams County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Williamsburg",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Williamsport",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Willoughby",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Willoughby Hills",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Willowick",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wilmington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Windham",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wintersville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Withamsville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wolfhurst",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wood County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Woodlawn",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Woodsfield",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Woodville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wooster",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Worthington",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wright-Patterson AFB",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wyandot County",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Wyoming",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Xenia",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Yellow Springs",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Yorkville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Youngstown",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Zanesville",
    country_code: "US",
    state_code: "OH",
  },
  {
    name: "Ada",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Adair County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Afton",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Alfalfa County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Altus",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Alva",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Anadarko",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Antlers",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Apache",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Arapaho",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Ardmore",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Arkoma",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Arnett",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Atoka",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Atoka County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Barnsdall",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Bartlesville",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Beaver",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Beaver County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Beckham County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Beggs",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Bethany",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Bethel Acres",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Bixby",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Blackwell",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Blaine County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Blanchard",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Boise City",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Boley",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Bray",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Bristow",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Broken Arrow",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Broken Bow",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Bryan County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Burns Flat",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Bushyhead",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Byng",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cache",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Caddo",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Caddo County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Calera",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Canadian County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Carnegie",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Carter County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Catoosa",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Central High",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Chandler",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Checotah",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Chelsea",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cherokee",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cherokee County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cheyenne",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Chickasha",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Choctaw",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Choctaw County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Chouteau",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cimarron County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Claremore",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cleora",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cleveland",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cleveland County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Coal County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Coalgate",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Colbert",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Collinsville",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Comanche",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Comanche County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Commerce",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Copeland",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cordell",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cotton County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Coweta",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Craig County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Creek County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Crescent",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cushing",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Custer County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Cyril",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Davis",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Del City",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Delaware County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Dewey",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Dewey County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Dickson",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Drumright",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Duncan",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Durant",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Edmond",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "El Reno",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Elgin",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Elk City",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Ellis County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Enid",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Erick",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Eufaula",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Fairfax",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Fairland",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Fletcher",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Forest Park",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Fort Gibson",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Frederick",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Garfield County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Garvin County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Geary",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Geronimo",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Glenpool",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Goldsby",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Goodwell",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Grady County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Granite",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Greer County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Grove",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Guthrie",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Guymon",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hall Park",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Harmon County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Harper County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Harrah",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hartshorne",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Haskell",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Haskell County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Healdton",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Heavener",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Helena",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hennessey",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Henryetta",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hinton",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hobart",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Holdenville",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hollis",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hominy",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hooker",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hughes County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Hugo",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Idabel",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Inola",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Jay",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Jenks",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Johnston County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Jones",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Justice",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Kay County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Kellyville",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Kenwood",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Kiefer",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Kingfisher",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Kingfisher County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Kiowa County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Konawa",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Krebs",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Langston",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Latimer County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Laverne",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Lawton",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Le Flore County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Lindsay",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Locust Grove",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Lone Grove",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Longtown",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Love County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Luther",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Madill",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Major County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Mangum",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Mannford",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Marietta",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Marlow",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Maud",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Mayes County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Maysville",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "McAlester",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "McClain County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "McCord",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "McCurtain County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "McIntosh County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "McLoud",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Medford",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Meeker",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Meridian",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Miami",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Midwest City",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Minco",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Moore",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Mooreland",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Morris",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Mounds",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Muldrow",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Murray County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Muskogee",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Muskogee County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Mustang",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Newcastle",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Newkirk",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Nichols Hills",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Nicoma Park",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Ninnekah",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Noble",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Noble County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Norman",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Nowata",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Nowata County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Oakhurst",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Oilton",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Okarche",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Okeene",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Okemah",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Okfuskee County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Oklahoma City",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Oklahoma County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Okmulgee",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Okmulgee County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Oologah",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Osage County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Ottawa County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Owasso",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Panama",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Park Hill",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pauls Valley",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pawhuska",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pawnee",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pawnee County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Payne County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Perkins",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Perry",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Piedmont",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pink",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pittsburg County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pocola",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Ponca City",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pontotoc County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Poteau",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pottawatomie County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Prague",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pryor",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pryor Creek",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Purcell",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Pushmataha County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Quinton",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Ringling",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Roger Mills County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Rogers County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Roland",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Rush Springs",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Salina",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Sallisaw",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Sand Springs",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Sapulpa",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Sayre",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Seminole",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Seminole County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Sequoyah County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Shattuck",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Shawnee",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Skiatook",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Slaughterville",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Snyder",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Spencer",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Sperry",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Spiro",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Stephens County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Stigler",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Stillwater",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Stilwell",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Stratford",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Stroud",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Sulphur",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Tahlequah",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Talihina",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Taloga",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Tecumseh",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Texanna",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Texas County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "The Village",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Thomas",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Tillman County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Tishomingo",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Tonkawa",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Tulsa",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Tulsa County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Turley",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Tuttle",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Union City",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Verdigris",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Vian",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Vinita",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Wagoner",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Wagoner County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Walters",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Warner",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Warr Acres",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Washita County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Watonga",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Waukomis",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Waurika",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Weatherford",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Westville",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Wetumka",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Wewoka",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Wilburton",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Wilson",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Wister",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Woods County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Woodward",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Woodward County",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Wynnewood",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Yale",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Yukon",
    country_code: "US",
    state_code: "OK",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Aloha",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Altamont",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Amity",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Astoria",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Athena",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Aumsville",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Baker City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Baker County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Bandon",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Banks",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Barview",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Bay City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Beavercreek",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Beaverton",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Bend",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Benton County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Bethany",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Boardman",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Brookings",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Brownsville",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Bunker Hill",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Burns",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Canby",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Cannon Beach",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Canyon City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Canyonville",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Carlton",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Cascade Locks",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Cave Junction",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Cedar Hills",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Cedar Mill",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Central Point",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Chenoweth",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Clackamas",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Clackamas County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Clatskanie",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Clatsop County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Coburg",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Columbia City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Columbia County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Condon",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Coos Bay",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Coos County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Coquille",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Cornelius",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Corvallis",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Cottage Grove",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Creswell",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Crook County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Culp Creek",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Culver",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Curry County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Dallas",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Damascus",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Depoe Bay",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Deschutes County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Deschutes River Woods",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Donald",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Drain",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Dundee",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Dunes City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Durham",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Eagle Point",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Elgin",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Enterprise",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Estacada",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Eugene",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Forest Grove",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Fossil",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Four Corners",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Fruitdale",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Garden Home-Whitford",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Gearhart",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Gervais",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Gilliam County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Gladstone",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Glide",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Gold Beach",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Gold Hill",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Grand Ronde",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Grants Pass",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Green",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Gresham",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Happy Valley",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Harbor",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Harney County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Harrisburg",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Hayesville",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Heppner",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Hermiston",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Hillsboro",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Hines",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Hood River",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Hood River County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Hubbard",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Irrigon",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Island City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Jacksonville",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Jennings Lodge",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "John Day",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Joseph",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Josephine County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Junction City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Keizer",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Kenton",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "King City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Klamath County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Klamath Falls",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "La Grande",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "La Pine",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lafayette",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lake Oswego",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lakeside",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lakeview",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lane County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lents",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lincoln Beach",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lincoln City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Linn County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lowell",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Lyons",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Madras",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Malheur County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "McMinnville",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Medford",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Merlin",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Metzger",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Mill City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Millersburg",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Milton-Freewater",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Milwaukie",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Mission",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Molalla",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Monmouth",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Moro",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Morrow County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Mount Angel",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Mount Hood Village",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Mulino",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Multnomah County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Myrtle Creek",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Myrtle Point",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "New Hope",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Newberg",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "North Bend",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "North Plains",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "North Portland",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Nyssa",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Oak Grove",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Oak Hills",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Oakridge",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Oatfield",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Odell",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Ontario",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Oregon City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Pacific City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Pendleton",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Philomath",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Phoenix",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Pilot Rock",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Port Orford",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Portland",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Prineville",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Rainier",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Raleigh Hills",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Redmond",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Redwood",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Reedsport",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Riddle",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Rockaway Beach",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Rockcreek",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Rogue River",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Rose Lodge",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Roseburg",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Roseburg North",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Saint Helens",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Sandy",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Scappoose",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Seaside",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Shady Cove",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Sheridan",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Sherman County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Sherwood",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Siletz",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Silverton",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Sisters",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "South Lebanon",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Stafford",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Stanfield",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Stayton",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Sublimity",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Sunriver",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Sutherlin",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Sweet Home",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Talent",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Tangent",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Terrebonne",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "The Dalles",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Three Rivers",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Tigard",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Tillamook",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Tillamook County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Toledo",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Tri-City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Troutdale",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Tualatin",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Turner",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Umatilla",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Umatilla County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Union",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Vale",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Veneta",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Vernonia",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Waldport",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Wallowa County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Warm Springs",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Warrenton",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Wasco County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "West Haven",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "West Haven-Sylvan",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "West Linn",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "West Slope",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Wheeler County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "White City",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Willamina",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Williams",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Wilsonville",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Winston",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Wood Village",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Woodburn",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Yamhill",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Yamhill County",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Yoncalla",
    country_code: "US",
    state_code: "OR",
  },
  {
    name: "Abbottstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Adamstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Akron",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Albion",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Alburtis",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Aldan",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Aliquippa",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Allegheny County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Alleghenyville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Allentown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Allison Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Almedia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Altoona",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ambler",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ambridge",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Amity Gardens",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ancient Oaks",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Annville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Apollo",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Archbald",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ardmore",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Arlington Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Armstrong County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Arnold",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ashley",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Aspinwall",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Atglen",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Audubon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Avalon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Avis",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Avoca",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Avon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Avondale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Avonia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Back Mountain",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Baden",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Baidland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bainbridge",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bakerstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bala-Cynwyd",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Baldwin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bally",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bangor",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Barnesboro",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bath",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bear Rocks",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Beaver",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Beaver County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Beaver Falls",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Beaverdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bedford County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bedminster",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Beech Mountain Lakes",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Belfast",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bell Acres",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Belle Vernon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bellefonte",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Belleville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bellwood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Belmont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ben Avon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bentleyville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Berks County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Berlin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Berwick",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Berwyn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bessemer",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bethel Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bethlehem",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Big Bass Lake",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Big Beaver",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Biglerville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Birchwood Lakes",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Birdsboro",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Black Lick",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Blair County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Blairsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Blakely",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Blandon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Blawnox",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bloomfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bloomsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Blossburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Blue Ball",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Blue Bell",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Boalsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Boiling Springs",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bonneauville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Boothwyn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Boswell",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bowmansville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Boyertown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brackenridge",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Braddock",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Braddock Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bradford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bradford County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bradford Woods",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Breinigsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brentwood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bressler",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brickerville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bridgeville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brittany Farms-Highlands",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brockway",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brodheadsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brookhaven",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brookville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Broomall",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brownstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Brownsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Browntown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bryn Athyn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bryn Mawr",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Bucks County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Burgettstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Burnham",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Butler",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Butler County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "California",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Caln",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Calumet",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cambria County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cambridge Springs",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cameron County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Camp Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Campbelltown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Canonsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Carbon County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Carbondale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Carlisle",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Carnegie",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Carnot-Moon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Carroll Valley",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Castanea",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Castle Shannon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Catasauqua",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Catawissa",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cecil-Bishop",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cementon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Center City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Central City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Centre County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Centre Hall",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cetronia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chalfont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chambersburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Charleroi",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cherryville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chester County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chester Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chester Springs",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chesterbrook",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cheswick",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chevy Chase Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chicora",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Chinchilla",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Christiana",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Church Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Churchill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Churchville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clairton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clarion",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clarion County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clarks Green",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clarks Summit",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clay",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Claysburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clearfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clearfield County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cleona",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clifton Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clinton County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Clymer",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Coaldale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Coatesville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cochranton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Collegeville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Collingdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Collinsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Colonial Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Colony Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Columbia County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Colwyn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Conashaugh Lakes",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Conemaugh",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Conestoga",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Conneaut Lakeshore",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Connellsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Conshohocken",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Conway",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Conyngham",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Coopersburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Coplay",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Coraopolis",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cornwall",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cornwells Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Corry",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Coudersport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Crafton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cranberry Township",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cresson",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cressona",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Croydon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Cumberland County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Curtisville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Curwensville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dallas",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dallastown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dalton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Darby",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dauphin County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Davidsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Delaware County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Delmont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Denver",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Derry",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Devon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dewart",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dickson City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dillsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Donora",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dormont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dorneyville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Downingtown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Doylestown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dravosburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dresher",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Drexel Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dublin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "DuBois",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Duboistown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dunbar",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Duncannon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Duncansville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dunmore",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dunnstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Dupont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Duquesne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Duryea",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Eagleview",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Eagleville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Earlston",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Bangor",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Berlin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Berwick",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Conemaugh",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Earl",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Greenville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Lansdowne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East McKeesport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Petersburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Pittsburgh",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Stroudsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Uniontown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East Washington",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "East York",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Eastlawn Gardens",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Easton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ebensburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Economy",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Eddington",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Eddystone",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Edgewood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Edgeworth",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Edinboro",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Edwardsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Effort",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Egypt",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Elim",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Elizabeth",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Elizabethtown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Elizabethville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Elk County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Elkland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ellport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ellwood City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Elverson",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Elysburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Emerald Lakes",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Emigsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Emmaus",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Emporium",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Emsworth",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Enhaut",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Enlow",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Enola",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ephrata",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Erie",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Erie County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Espy",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Etna",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Evans City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Evansburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Everett",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Exeter",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Exton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Factoryville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fairchance",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fairdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fairhope",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fairless Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fairview-Ferndale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Falls Creek",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Farrell",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Faxon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fayetteville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Feasterville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fellsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ferndale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fernway",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fivepointville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fleetwood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Flemington",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Flourtown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Flying Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Folcroft",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Folsom",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ford City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Forest City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Forest County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Forest Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fort Washington",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Forty Fort",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Foster Brook",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fountain Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fox Chapel",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fox Chase",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fox Run",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Frackville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Franklin Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fredericksburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Freedom",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Freeland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Freemansburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Freeport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Friedens",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fullerton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Fulton County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Galeton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Gallitzin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Gap",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Garden View",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Gastonville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Geistown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Gettysburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Gibsonia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Gilbertsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Girard",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Girardville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Glassport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Glen Lyon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Glen Rock",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Glenolden",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Glenshaw",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Glenside",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Gold Key Lake",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Grantley",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Green Tree",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Greencastle",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Greenfields",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Greenock",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Greensburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Greenwood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Grill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Grove City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Guilford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Guilford Siding",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Halfway House",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hallam",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hallstead",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hamburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hanover",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Harleigh",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Harleysville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Harrisburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Harveys Lake",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hasson Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hastings",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hatboro",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hatfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hawley",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hazleton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hebron",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Heidelberg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hellertown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hemlock Farms",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hermitage",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hershey",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Highland Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Highspire",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hilldale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hiller",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hokendauqua",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hollidaysburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Homeacre-Lyndora",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Homer City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Homestead",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hometown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Honesdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Honey Brook",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hopwood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Horsham",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Houserville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Houston",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hughestown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hughesville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hummels Wharf",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hummelstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Huntingdon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Huntingdon County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hyde",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Hyde Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Imperial",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Indian Mountain Lake",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Indiana",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Indiana County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Industry",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ingram",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Inkerman",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Intercourse",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Irwin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ivyland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jacksonwald",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jacobus",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jeannette",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jefferson Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jenkintown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jermyn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jerome",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jersey Shore",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jessup",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jim Thorpe",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Johnsonburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Johnstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Jonestown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Juniata County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kane",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kenhorst",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kenilworth",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kenmar",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kennett Square",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "King of Prussia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kittanning",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Knox",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kulpmont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kulpsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Kutztown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lackawanna County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lafayette Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Laflin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lake City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lake Heritage",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lake Latonka",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lake Meade",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lake Wynonah",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lakemont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lampeter",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lancaster County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Landisville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Langhorne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Langhorne Manor",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lansdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lansdowne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lansford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Laporte",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Larksville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Latrobe",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Laureldale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Laurys Station",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lawnton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lawrence Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lawson Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lebanon County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lebanon South",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Leechburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Leesport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Leetsdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lehigh County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lehighton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Leith-Hatfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lemont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lemoyne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lenape Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Leola",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Level Green",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Levittown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lewisburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lewistown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Light Street",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ligonier",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lima",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Limerick",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lincoln Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Linglestown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Linntown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Linwood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lionville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lititz",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Littlestown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lock Haven",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Loganville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lorane",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Loretto",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lower Allen",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lower Burrell",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Loyalhanna",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Luzerne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Luzerne County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lycoming County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lykens",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Lynnwood-Pricedale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Macungie",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mahanoy City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Malvern",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Manheim",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Manor",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mansfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Maple Glen",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Marcus Hook",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Marianne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Marienville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Marietta",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mars",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Marshallton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Martinsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Marysville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Masontown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Matamoras",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mayfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Maytown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McAdoo",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McConnellsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McConnellstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McDonald",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McGovern",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McKean County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McKees Rocks",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McKeesport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McMurray",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "McSherrystown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Meadowood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Meadville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mechanicsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mechanicsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Media",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mercer",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mercer County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mercersburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Meridian",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Meyersdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Middleburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Midland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Midway",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mifflin County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mifflinburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mifflintown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mifflinville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Milesburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mill Hall",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Millbourne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Millersburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Millersville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Millvale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Milroy",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Minersville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mohnton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Monaca",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Monessen",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Monongahela",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Monroeville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mont Alto",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Montgomery",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Montgomeryville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Montour County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Montoursville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Montrose",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Moosic",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Morrisville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Morton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Moscow",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Carmel",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Cobb",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Holly Springs",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Joy",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Lebanon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Oliver",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Penn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Pleasant",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Pocono",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Union",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mount Wolf",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mountain Top",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mountainhome",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mountville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Muhlenberg Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Muncy",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Mundys Corner",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Munhall",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Murrysville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Muse",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Myerstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Nanticoke",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Nanty Glo",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Narberth",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Nazareth",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Nescopeck",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Nesquehoning",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Beaver",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Berlinville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Bloomfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Brighton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Britain",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Castle",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Castle Northwest",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Columbia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Cumberland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Eagle",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Freedom",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Holland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Hope",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Kensington",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Oxford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Philadelphia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Stanton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "New Wilmington",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Newmanstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Newtown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Newtown Grant",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Newville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Nixon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Norristown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North Apollo",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North Belle Vernon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North Braddock",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North Catasauqua",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North Charleroi",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North East",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North Versailles",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North Wales",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North Warren",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "North York",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Northampton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Northampton County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Northern Cambria",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Northumberland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Northumberland County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Northwest Harborcreek",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Norwood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oak Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oakdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oakmont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oakwood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ohioville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oil City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Old Forge",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Old Orchard",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oley",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oliver",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Olyphant",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Orchard Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oreland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Orwigsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Osceola Mills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Oxford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Palmdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Palmer Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Palmerton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Palmyra",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Palo Alto",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Paoli",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Paradise",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Park Forest Village",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Parkesburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Parkside",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Parkville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Patton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Paxtang",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Paxtonia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pen Argyl",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Penbrook",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Penn Estates",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Penn Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Penn Wynne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Penndel",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pennsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pennside",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pennsport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pennville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Penryn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Perkasie",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Perryopolis",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Philadelphia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Philadelphia County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Philipsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Phoenixville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pike County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pine Grove",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pine Grove Mills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pine Ridge",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pitcairn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pittsburgh",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pittston",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Plains",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pleasant Gap",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pleasant Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pleasant Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Plum",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Plumsteadville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Plymouth Meeting",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pocono Pines",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pocono Ranch Lands",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Point Marion",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Port Allegany",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Port Carbon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Port Vue",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Portage",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Potter County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pottsgrove",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pottstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pottsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Progress",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Prospect",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Prospect Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Punxsutawney",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Pymatuning Central",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Quakertown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Quarryville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Radnor",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Rankin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Raubsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Reading",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Reamstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Red Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Red Lion",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Reiffton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Reinholds",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Rennerdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Renovo",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Republic",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Reynolds Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Reynoldsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Rheems",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Richboro",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Richland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Richlandtown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ridgway",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Ridley Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "River View Park",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Riverside",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Roaring Spring",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Robesonia",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Rockledge",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Roseto",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Rothsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Royalton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Royersford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Russell",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Russellton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Rutherford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Saint Clair",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Saint Lawrence",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Saint Marys",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Salix",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Salunga",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sanatoga",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sand Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sandy",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Saw Creek",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Saxonburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Saylorsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sayre",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Schlusser",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Schnecksville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Schoeneck",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Schuylkill County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Schuylkill Haven",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Schwenksville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Scotland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Scottdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Scranton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Selinsgrove",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sellersville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Seneca",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Seven Fields",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sewickley",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shamokin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shamokin Dam",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shanor-Northvue",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sharon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sharon Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sharpsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sharpsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shavertown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sheffield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shenandoah",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shenandoah Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shillington",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shiloh",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shinglehouse",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shippensburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shiremanstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shoemakersville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Shrewsbury",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sierra View",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Simpson",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sinking Spring",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Skippack",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Skyline View",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Slatington",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Slippery Rock",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Smethport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Snyder County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Somerset",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Somerset County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Souderton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "South Coatesville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "South Connellsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "South Greensburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "South Park Township",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "South Pottstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "South Temple",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "South Uniontown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "South Waverly",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "South Williamsport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Southmont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Southwest Greensburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Spangler",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Speers",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Spinnerstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Spring City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Spring Grove",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Spring House",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Spring Mount",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Spring Ridge",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Springdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Spry",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "State College",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "State Line",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Steelton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Stewartstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Stiles",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Stoneboro",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Stony Creek Mills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Stonybrook",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Stormstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Stowe",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Strasburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Stroudsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sturgeon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sugarcreek",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sullivan County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Summit Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sun Valley",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sunbury",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sunrise Lake",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Susquehanna",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Susquehanna County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Susquehanna Trails",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Swarthmore",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Swartzville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Swissvale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Swoyersville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Sykesville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tacony",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tamaqua",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tannersville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tarentum",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tatamy",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Taylor",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Telford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Temple",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Terre Hill",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "The Hideout",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Thompsonville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Thorndale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Throop",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tinicum",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tioga County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tionesta",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tipton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Titusville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Toftrees",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Topton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Toughkenamon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Towamensing Trails",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Towanda",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tower City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Trafford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Trainer",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Trappe",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Treasure Lake",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tremont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Trevorton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Trevose",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Trexlertown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Trooper",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Trucksville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tullytown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tunkhannock",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Turtle Creek",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Tyrone",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Union City",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Uniontown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Upland",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Upper Saint Clair",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Valley Green",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Valley View",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Vandergrift",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Venango County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Verona",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Versailles",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Village Green-Green Ridge",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Village Shires",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Vinco",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wallenpaupack Lake Estates",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Walnutport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Warminster Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Waterford",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Watsontown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Waymart",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wayne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wayne Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Waynesboro",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Waynesburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Weatherly",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Weigelstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Weissport East",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wellsboro",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wernersville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wescosville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wesleyville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Chester",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Conshohocken",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Easton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Fairview",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Grove",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Hamburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Hazleton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Homestead",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Kittanning",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Lawn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Leechburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Mayfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Mifflin",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Newton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Norriton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Pittston",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Reading",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West View",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Wyoming",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West Wyomissing",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "West York",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Westfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Westmont",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Westmoreland County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wharton",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Whitaker",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "White Haven",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "White Oak",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Whitehall",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Whitehall Township",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Whitfield",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Whitman",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wickerham Manor-Fisher",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wilkes-Barre",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wilkinsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Williamsburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Williamsport",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Williamstown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Willow Grove",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Willow Street",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wilmerding",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wilson",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wind Gap",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Windber",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wolfdale",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Womelsdorf",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Woodbourne",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Woodland Heights",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Woodlyn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Woodside",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wormleysburg",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Woxall",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wrightsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wyncote",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wyndmoor",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wyoming",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wyoming County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wyomissing",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Wyomissing Hills",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Yardley",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Yeadon",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Yeagertown",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Yoe",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "York",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "York County",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Yorklyn",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Youngsville",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Youngwood",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Zelienople",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Zion",
    country_code: "US",
    state_code: "PA",
  },
  {
    name: "Adjuntas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Aguada",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Aguadilla",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Aguas Buenas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Aguas Claras",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Aguilita",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Aibonito",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Añasco",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Animas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Antón Ruiz",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Arecibo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Arroyo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Bairoa",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Bajadero",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Bajandas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Barahona",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Barceloneta",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Barranquitas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Bartolo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Bayamon",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Benitez",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Betances",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Boqueron",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Boquerón, Cabo Rojo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Brenas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Buena Vista",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Bufalo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Caban",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Cabo Rojo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Cabo Rojo Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Cacao",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Caguas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Campanilla",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Campo Rico",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Camuy",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Candelaria",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Candelaria Arenas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Candelero Arriba",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Canóvanas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Capitanejo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Carolina",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Carrizales",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Cataño",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Cayey",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Cayuco",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Ceiba",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Ceiba Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Celada",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Central Aguirre",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Ciales",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Ciales Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Cidra",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Coamo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Coco",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Comerío",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Comerío Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Comunas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Coquí",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Corazón",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Corcovado",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Corozal",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Corozal Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Coto Laurel",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Coto Norte",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Culebra",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Culebra barrio-pueblo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Daguao",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Dorado",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Dorado Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "El Mangó",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "El Negro",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "El Ojo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Emajagua",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Esperanza",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Espino",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Estancias de Florida",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Fajardo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Fajardo Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Florida",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Franquez",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Fuig",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "G. L. Garcia",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Galateo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Garrochales",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Guánica",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Guánica Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Guayabal",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Guayama",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Guayama Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Guayanilla",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Guaynabo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Gurabo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Gurabo Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "H. Rivera Colon",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Hatillo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Hatillo Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Hato Arriba",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Hato Candal",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Hormigueros",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Hormigueros Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Humacao",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Imbery",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Indios",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Ingenio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Isabel Segunda",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Isabela",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Jagual",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Jauca",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Jayuya",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Jobos",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Juana Díaz",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Juana Díaz Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Juncal",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Juncos",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "La Alianza",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "La Dolores",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "La Fermina",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "La Luisa",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "La Parguera",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "La Playa",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "La Plena",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Lajas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Lajas Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Lamboglia",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Lares",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Las Marias",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Las Marías Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Las Ochenta",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Las Ollas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Las Piedras",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Las Piedras Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Levittown",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Liborio Negron Torres",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Lluveras",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Loíza",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Lomas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Los Llanos",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Luis Lloréns Torres",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Luis M. Cintron",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Luquillo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Luquillo Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Luyando",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Magas Arriba",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Manatí",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Maria Antonia",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Mariano Colón",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Maricao",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Maricao Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Martorell",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Marueño",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Maunabo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Maunabo Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Mayagüez",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Miranda",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Moca",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Moca Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Monserrate",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Monte Grande",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Mora",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Morovis",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Mucarabones",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Naguabo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Naguabo Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Naranjito",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Naranjito Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Olimpo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Orocovis",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Orocovis Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Pájaros",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Pajonal",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Palmarejo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Palmas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Palmer",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Palo Seco",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Palomas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Parcelas La Milagrosa",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Parcelas Nuevas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Parcelas Peñuelas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Pastos",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Patillas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Patillas Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Peña Pobre",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Peñuelas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Piedra Gorda",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Playa Fortuna",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Playita",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Playita Cortada",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Pole Ojea",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Ponce",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Potala Pastillo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Pueblito del Rio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Puerto Real",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Punta Santiago",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Quebrada",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Quebradillas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Quebradillas Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Rafael Capo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Rafael Gonzalez",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Rafael Hernandez",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Ramos",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Rincón",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Rincón Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Río Blanco",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Río Cañas Abajo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Río Grande",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Río Grande Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Rio Lajas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Río Piedras",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Rosa Sanchez",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Sabana",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Sabana Eneas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Sabana Grande",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Sabana Grande Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Sabana Hoyos",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Sabana Seca",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Salinas",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "San Antonio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "San Germán Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "San Isidro",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "San José",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "San Juan",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "San Lorenzo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "San Sebastián",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Santa Barbara",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Santa Clara",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Santa Isabel",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Santa Isabel Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Santo Domingo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Santurce",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Stella",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Suárez",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Sumidero",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Tallaboa",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Tallaboa Alta",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Tiburones",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Tierras Nuevas Poniente",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Toa Alta",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Toa Alta Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Toa Baja",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Trujillo Alto",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Trujillo Alto Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Utuado",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Utuado barrio-pueblo",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Vázquez",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Vega Alta",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Vega Alta Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Vega Baja",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Vieques",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Vieques Municipality",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Villalba",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Villalba Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Yabucoa",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Yabucoa Municipio",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Yauco",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Yaurel",
    country_code: "US",
    state_code: "PR",
  },
  {
    name: "Ashaway",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Barrington",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Bradford",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Bristol County",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Central Falls",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Charlestown",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Chepachet",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Coventry",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Cranston",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Cumberland",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Cumberland Hill",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "East Greenwich",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "East Providence",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Exeter",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Foster",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Harrisville",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Hope Valley",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Hopkinton",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Jamestown",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Johnston",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Kent County",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Melville",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Narragansett",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Narragansett Pier",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "New Shoreham",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Newport County",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Newport East",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "North Kingstown",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "North Providence",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "North Scituate",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "North Smithfield",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Pascoag",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Pawtucket",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Portsmouth",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Providence",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Providence County",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Smithfield",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "South Kingstown",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Tiverton",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Valley Falls",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Wakefield-Peacedale",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Warren",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Warwick",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "West Greenwich",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "West Warwick",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Westerly",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Woonsocket",
    country_code: "US",
    state_code: "RI",
  },
  {
    name: "Abbeville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Abbeville County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Aiken",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Aiken County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Allendale",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Allendale County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Anderson",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Anderson County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Andrews",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Arcadia",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Arial",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Awendaw",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Bamberg",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Bamberg County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Barnwell",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Barnwell County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Batesburg",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Batesburg-Leesville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Beaufort",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Beaufort County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Belton",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Belvedere",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Bennettsville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Berea",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Berkeley County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Bishopville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Blacksburg",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Blackville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Bluffton",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Blythewood",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Boiling Springs",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Bonneau Beach",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Brookdale",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Burnettown",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Burton",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Calhoun Falls",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Cane Savannah",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Catawba",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Cayce",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Central",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Chapin",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Charleston",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Charleston County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Cheraw",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Cherokee County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Cherryvale",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Chester County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Chesterfield",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Chesterfield County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "City View",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Clarendon County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Clearwater",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Clemson",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Clover",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Colleton County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Conway",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Cowpens",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Dalzell",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Darlington",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Darlington County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Denmark",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Dentsville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Dillon",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Dillon County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Dorchester County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Due West",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Duncan",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Dunean",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Easley",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "East Gaffney",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "East Sumter",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Edgefield",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Edgefield County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Edisto",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Elgin",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Estill",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Eureka Mill",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Fairfax",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Fairfield County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Fairforest",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Five Forks",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Florence County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Folly Beach",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Forest Acres",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Forestbrook",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Fort Mill",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Fountain Inn",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Gadsden",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Gaffney",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Gantt",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Garden City",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Gaston",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Georgetown County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Gloverville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Golden Grove",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Goose Creek",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Graniteville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Great Falls",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Greenville County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Greenwood",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Greenwood County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Greer",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Hampton",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Hampton County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Hanahan",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Hardeeville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Hartsville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Hilton Head",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Hilton Head Island",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Holly Hill",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Hollywood",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Homeland Park",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Honea Path",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Hopkins",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Horry County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "India Hook",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Inman",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Inman Mills",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Irmo",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Irwin",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Isle of Palms",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Iva",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "James Island",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Jasper County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Joanna",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Johnsonville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Johnston",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Judson",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Kershaw",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Kershaw County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Kiawah Island",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Kingstree",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Ladson",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lake City",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lake Murray of Richland",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lake Secession",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lake Wylie",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lakewood",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lancaster County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lancaster Mill",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Landrum",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Langley",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Latta",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Laurel Bay",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Laurens",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Laurens County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Leesville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lesslie",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lexington County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lincolnville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Little River",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Loris",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lugoff",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Lyman",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Manning",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Marlboro County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Mauldin",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Mayo",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "McColl",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "McCormick",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "McCormick County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Meggett",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Monarch Mill",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Moncks Corner",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Mount Pleasant",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Mullins",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Murphys Estates",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Murrells Inlet",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Myrtle Beach",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "New Ellenton",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Newberry",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Newberry County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Ninety Six",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "North Augusta",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "North Charleston",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "North Hartsville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "North Myrtle Beach",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Northlake",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Oak Grove",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Oconee County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Orangeburg",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Orangeburg County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Pacolet",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Pageland",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Pamplico",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Parker",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Pendleton",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Pickens",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Pickens County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Piedmont",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Pineridge",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Port Royal",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Powdersville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Privateer",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Prosperity",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Ravenel",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Red Bank",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Red Hill",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Richland County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Ridgeland",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Ridgeville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Rock Hill",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Roebuck",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Saint Andrews",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Saint George",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Saint Matthews",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Saint Stephen",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Saluda",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Saluda County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Sangaree",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Sans Souci",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Saxon",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Seabrook Island",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Seneca",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Seven Oaks",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Shell Point",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Simpsonville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Slater-Marietta",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Socastee",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "South Congaree",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "South Sumter",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Southern Shops",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Spartanburg",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Spartanburg County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Springdale",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Stateburg",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Sullivans Island",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Summerville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Sumter",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Sumter County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Surfside Beach",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Taylors",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Tega Cay",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Tigerville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Timmonsville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Travelers Rest",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Union",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Utica",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Valley Falls",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Varnville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Wade Hampton",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Walhalla",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Walterboro",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Ware Shoals",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Warrenville",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Watts Mills",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Wedgefield",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Wedgewood",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Welcome",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Wellford",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "West Columbia",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Westminster",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Whitmire",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Wilkinson Heights",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Williamsburg County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Williamston",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Williston",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Winnsboro",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Winnsboro Mills",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Woodfield",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Woodruff",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "York",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "York County",
    country_code: "US",
    state_code: "SC",
  },
  {
    name: "Aberdeen",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Alexandria",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Armour",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Aurora County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Baltic",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Beadle County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Belle Fourche",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Bennett County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Beresford",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Bison",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Blackhawk",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Bon Homme County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Box Elder",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Brandon",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Britton",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Brookings",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Brookings County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Brown County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Brule County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Buffalo County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Burke",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Butte County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Campbell County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Chamberlain",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Charles Mix County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Clark",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Clear Lake",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Codington County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Colonial Pine Hills",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Corson County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Crooks",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Custer",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Custer County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Dakota Dunes",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Davison County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Day County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "De Smet",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Deadwood",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Dell Rapids",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Deuel County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Dewey County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Dupree",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Eagle Butte",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Edmunds County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Elk Point",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Fall River County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Faulk County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Faulkton",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Flandreau",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Fort Pierre",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Fort Thompson",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Freeman",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Garretson",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Gettysburg",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Gregory",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Gregory County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Groton",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Haakon County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Hamlin County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Hand County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Hanson County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Harding County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Harrisburg",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Hayti",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Highmore",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Hot Springs",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Howard",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Hughes County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Huron",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Hutchinson County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Hyde County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Ipswich",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Jerauld County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Jones County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Kadoka",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Kennebec",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Kingsbury County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Lake Andes",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Lead",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Lemmon",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Lennox",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Leola",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Lyman County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Martin",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "McCook County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "McIntosh",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "McPherson County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Meade County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Mellette County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Milbank",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Miller",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Miner County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Minnehaha County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Mission",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Mitchell",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Mobridge",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Moody County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Mound City",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Murdo",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "North Eagle Butte",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "North Sioux City",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "North Spearfish",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Oglala",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Oglala Lakota County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Olivet",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Onida",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Parker",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Parkston",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Pennington County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Perkins County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Philip",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Pierre",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Pine Ridge",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Plankinton",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Platte",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Porcupine",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Potter County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Rapid City",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Rapid Valley",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Redfield",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Roberts County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Rosebud",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Sanborn County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Selby",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Sioux Falls",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Sisseton",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Spearfish",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Spink County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Stanley County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Sturgis",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Sully County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Summerset",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Tea",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Timber Lake",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Todd County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Tripp County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Turner County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Tyndall",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Vermillion",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Volga",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Wagner",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Walworth County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Watertown",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Webster",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Wessington Springs",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "White River",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Winner",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Woonsocket",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Yankton",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Yankton County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Ziebach County",
    country_code: "US",
    state_code: "SD",
  },
  {
    name: "Adamsville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Alamo",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Alcoa",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Algood",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Altamont",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Anderson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Apison",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Ardmore",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Ashland City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Atoka",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Banner Hill",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bartlett",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Baxter",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bean Station",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bedford County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Belle Meade",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bells",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Benton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Benton County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Blaine",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bledsoe County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bloomingdale",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Blount County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Blountville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bluff City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bolivar",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bon Aqua Junction",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bradford",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bradley County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Brentwood",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Brentwood Estates",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Brighton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Brownsville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Bruceton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Burns",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Byrdstown",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Camden",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Campbell County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Cannon County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Carter County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Carthage",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Caryville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Celina",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Central",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Chapel Hill",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Charlotte",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Chattanooga",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Cheatham County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Chester County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Christiana",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Church Hill",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Claiborne County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Clarksville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Cleveland",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Clifton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Coalfield",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Cocke County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Coffee County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Collegedale",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Collierville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Colonial Heights",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Columbia",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Condon",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Cookeville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Coopertown",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Cornersville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Covington",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Cowan",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Crockett County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Cross Plains",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Crossville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Crump",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Cumberland County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dandridge",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Davidson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Decatur County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Decaturville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Decherd",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "DeKalb County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dickson",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dickson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dodson Branch",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dresden",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dunlap",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dyer",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dyer County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Dyersburg",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Eagleton Village",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "East Brainerd",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "East Chattanooga",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "East Cleveland",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "East Ridge",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Elizabethton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Englewood",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Erin",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Erwin",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Estill Springs",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Etowah",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Fairfield Glade",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Fairmount",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Fall Branch",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Falling Water",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Farragut",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Fayetteville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Fentress County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Fincastle",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Forest Hills",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Gainesboro",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Gallatin",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Gatlinburg",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Germantown",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Gibson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Giles County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Gleason",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Goodlettsville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Gordonsville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Grainger County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Gray",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Graysville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Green Hill",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Greenback",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Greenbrier",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Greeneville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Grimsley",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Gruetli-Laager",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Grundy County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Halls",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hamblen County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hardeman County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hardin County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Harriman",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Harrison",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Harrogate",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hartsville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hawkins County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Haywood County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Henderson",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Henderson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hendersonville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hickman County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hickory Withe",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hohenwald",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hopewell",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Houston County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Humboldt",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Humphreys County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Hunter",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Huntingdon",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Huntsville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Jacksboro",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Jamestown",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Jasper",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Jefferson City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Jellico",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Johnson City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Johnsonville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Jonesborough",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Kenton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Kimball",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Kingsport",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Kingston Springs",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Knox County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Knoxville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "La Vergne",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lafayette",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "LaFollette",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lake County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lake Tansi",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lakeland",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lakesite",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lakewood",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lauderdale County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lawrence County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lawrenceburg",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lenoir City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lewis County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lewisburg",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Linden",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Livingston",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lone Oak",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lookout Mountain",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Loretto",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Loudon",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Loudon County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Louisville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Luttrell",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Lynchburg",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Macon County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Madisonville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Martin",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Maryville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Mascot",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Mason",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Maury County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Maynardville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "McEwen",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "McKenzie",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "McMinn County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "McMinnville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "McNairy County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Medina",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Meigs County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Memphis",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Middle Valley",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Midtown",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Midway",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Milan",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Millersville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Millington",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Monteagle",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Monterey",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Moore County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Morristown",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Mosheim",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Mount Carmel",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Mount Juliet",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Mount Pleasant",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Mountain City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Mowbray Mountain",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Munford",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Murfreesboro",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Nashville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "New Hope",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "New Johnsonville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "New Market",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "New South Memphis",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "New Tazewell",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "New Union",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Newbern",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Nolensville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Norris",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Oak Grove",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Oak Hill",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Oak Ridge",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Oakland",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Obion",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Obion County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Oliver Springs",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Olivet",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Oneida",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Overton County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Paris",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Park City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Parsons",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Pegram",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Perry County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Pickett County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Pigeon Forge",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Pikeville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Pine Crest",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Piperton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Plainview",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Pleasant View",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Portland",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Powells Crossroads",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Pulaski",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Putnam County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Red Bank",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Red Boiling Springs",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Rhea County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Ridgely",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Ridgetop",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Ripley",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Roan Mountain",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Roane County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Robertson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Rockwood",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Rocky Top",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Rogersville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Rural Hill",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Rutherford",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Rutherford County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Rutledge",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sale Creek",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Savannah",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Selmer",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sequatchie County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sevier County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sevierville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sewanee",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Seymour",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Shackle Island",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Shelby County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Shelbyville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Signal Mountain",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Smith County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Smithville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Smyrna",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sneedville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Soddy-Daisy",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Somerville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "South Carthage",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "South Cleveland",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "South Fulton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "South Pittsburg",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sparta",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Spencer",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Spring City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Spring Hill",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Spurgeon",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Stewart County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sullivan County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sumner County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Surgoinsville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Sweetwater",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Tazewell",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Tellico Village",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Tennessee Ridge",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Thompson's Station",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Three Way",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Tipton County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Tiptonville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Tracy City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Trenton",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Trousdale County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Tullahoma",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Tusculum",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Unicoi",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Unicoi County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Union City",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Union County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Unionville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Van Buren County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Vonore",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Walden",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Walnut Hill",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Wartburg",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Watertown",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Waverly",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Waynesboro",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Weakley County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Westmoreland",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "White Bluff",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "White County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "White House",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "White Pine",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Whiteville",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Whitwell",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Wildwood",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Wildwood Lake",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Williamson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Wilson County",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Woodbury",
    country_code: "US",
    state_code: "TN",
  },
  {
    name: "Abernathy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Abilene",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Abram",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Addison",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Agua Dulce",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alamo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alamo Heights",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Aldine",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Aledo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alice",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alief",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Allen",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alpine",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alto",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alton North (historical)",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alvarado",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alvin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alvord",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Amarillo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ames",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Anahuac",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Anderson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Anderson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Anderson Mill",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Andrews",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Andrews County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Angelina County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Angleton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Anna",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Annetta",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Anson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Anthony",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Anton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Aransas County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Aransas Pass",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Archer City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Archer County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Arcola",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Argyle",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Armstrong County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Asherton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Aspermont",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Atascocita",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Atascosa County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Atlanta",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Aubrey",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Aurora",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Austin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Austin County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Azle",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bacliff",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bailey County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Baird",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Balch Springs",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Balcones Heights",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ballinger",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bandera",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bandera County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bangs",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Barrett",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bartlett",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Barton Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bartonville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bastrop",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bastrop County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Batesville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bay City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Baylor County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bayou Vista",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Baytown",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Beach City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Beaumont",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bee Cave",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bee County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Beeville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bell County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bellaire",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bellmead",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bells",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bellville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Belton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Benavides",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Benbrook",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Benjamin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Berryville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bertram",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Beverly",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Beverly Hills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bevil Oaks",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bexar County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Big Lake",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Big Sandy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Big Spring",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bishop",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Blanco",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Blanco County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bloomington",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Blossom",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Blue Mound",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Boerne",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bogata",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Boling",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bolivar Peninsula",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bonham",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Booker",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Borden County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Borger",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bosque County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bovina",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bowie",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bowie County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Boyd",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brackettville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brady",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brazoria",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brazoria County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brazos County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Breckenridge",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brenham",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brewster County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Briar",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Briarcliff",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bridge City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Briscoe County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brooks County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brookshire",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brookside Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brown County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brownfield",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brownsboro",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brownsville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brownwood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bruceville-Eddy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Brushy Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bryan",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Buchanan Dam",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Buda",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bullard",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bulverde",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Buna",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bunker Hill Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Burkburnett",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Burleson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Burleson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Burnet",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Burnet County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Bushland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cactus",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Caddo Mills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Caldwell",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Caldwell County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Callahan County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Calvert",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cameron",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cameron County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cameron Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cameron Park Colonia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Camp County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Camp Swift",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Canadian",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Canton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Canutillo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Canyon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Canyon Lake",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Carrizo Springs",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Carson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Carthage",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cass County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Castle Hills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Castro County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Castroville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cedar Hill",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cedar Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Celina",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Center",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Central Gardens",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "César Chávez",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Chambers County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Chandler",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Channelview",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Channing",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Charlotte",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cherokee County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Chico",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Childress",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Childress County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "China",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "China Grove",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cibolo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cienegas Terrace",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cinco Ranch",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Circle D-KC Estates",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cisco",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Citrus City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Clarendon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Clarksville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Claude",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Clear Lake Shores",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cleburne",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cleveland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Clifton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Clint",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cloverleaf",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Clute",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Clyde",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cochran County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cockrell Hill",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Coke County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Coldspring",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Coleman",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Coleman County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "College Station",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Colleyville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Collin County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Collingsworth County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Collinsville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Colorado City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Colorado County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Comal County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Comanche",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Comanche County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Combes",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Combine",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Comfort",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Commerce",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Concho County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Conroe",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Converse",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cooke County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cooper",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Coppell",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Copper Canyon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Copperas Cove",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Corinth",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Corpus Christi",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Corrigan",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Corsicana",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Coryell County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cottle County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cottonwood Shores",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cotulla",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crandall",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crane",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crane County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crockett",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crockett County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crosby",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crosby County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crosbyton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cross Mountain",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crowell",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crowley",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Crystal City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cuero",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Culberson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cut and Shoot",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Cypress",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Daingerfield",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dalhart",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dallam County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dallas",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dallas County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dalworthington Gardens",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Danbury",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dawson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "De Kalb",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "De Leon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Deaf Smith County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "DeCordova",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Deer Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Del Rio",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Delta County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Denison",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Denton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Denton County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Denver City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "DeSoto",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Devine",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Deweyville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "DeWitt County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Diboll",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dickens",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dickens County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dickinson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dilley",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dimmit County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dimmitt",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Doffing",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Donley County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Donna",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Doolittle",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Double Oak",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dripping Springs",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dublin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Dumas",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Duncanville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Duval County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Eagle Lake",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Eagle Mountain",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Eagle Pass",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Early",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Earth",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "East Bernard",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Eastland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Eastland County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ector County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Edcouch",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Eden",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Edgecliff Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Edgewood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Edinburg",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Edna",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Edwards County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Eidson Road",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "El Campo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "El Cenizo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "El Lago",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "El Paso",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "El Paso County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Eldorado",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Electra",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Elgin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Elkhart",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ellis County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Elm Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Elmendorf",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Elsa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Emory",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Encantada-Ranchito-El Calaboz",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ennis",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Erath County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Escobares",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Euless",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Evadale",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Everman",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fabens",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fair Oaks Ranch",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fairchilds",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fairfield",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Falcon Lake Estates",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Falfurrias",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Falls County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fannett",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fannin County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Farmers Branch",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Farmersville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Farwell",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fate",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ferris",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fifth Street",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fisher County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Flatonia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Floresville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Flower Mound",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Floyd County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Floydada",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Foard County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Forest Hill",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Forney",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fort Bend County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fort Bliss",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fort Clark Springs",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fort Davis",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fort Hancock",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fort Hood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fort Stockton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fort Worth",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Four Corners",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Frankston",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fredericksburg",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Freeport",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Freer",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Freestone County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fresno",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Friendswood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Frio County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Friona",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Frisco",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fritch",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fulshear",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Fulton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gail",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gaines County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gainesville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Galena Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Galveston",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Galveston County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ganado",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Garden City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Garden Ridge",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gardendale",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Garfield",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Garland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Garza County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gatesville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "George West",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Georgetown",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Geronimo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gholson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Giddings",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gillespie County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gilmer",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gladewater",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Glasscock County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Glen Rose",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Glenn Heights",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Godley",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Goldthwaite",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Goliad",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Goliad County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gonzales",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gonzales County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gorman",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Graham",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Granbury",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Grand Prairie",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Grand Saline",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Grandview",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Granger",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Granite Shoals",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Grape Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Grapeland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Grapevine",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gray County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Grayson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Greatwood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Green Valley Farms",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Greenville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gregg County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gregory",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Grimes County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Groesbeck",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Groves",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Groveton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gruver",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Guadalupe County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gun Barrel City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Gunter",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Guthrie",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hackberry",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hale Center",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hale County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hall County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hallettsville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hallsville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Haltom City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hamilton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hamilton County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hamlin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hansford County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hardeman County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hardin County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Harker Heights",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Harlingen",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Harper",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Harris County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Harrison County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hart",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hartley County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Haskell",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Haskell County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Haslet",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hawkins",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hays County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hearne",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Heath",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hebbronville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hedwig Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Heidelberg",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Helotes",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hemphill",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hemphill County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hempstead",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Henderson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Henderson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Henrietta",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hereford",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hewitt",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hickory Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hico",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hidalgo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hidalgo County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hideaway",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Highland Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Highland Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Highlands",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hill Country Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hill County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hillsboro",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hilltop Lakes",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hitchcock",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hockley County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Holiday Lakes",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Holland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Holliday",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Holly Lake Ranch",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hollywood Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Homestead Meadows North",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Homestead Meadows South",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hondo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Honey Grove",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hood County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hooks",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hopkins County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Horizon City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hornsby Bend",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Horseshoe Bay",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Houston",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Houston County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Howard County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Howe",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hubbard",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hudson Bend",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hudson Oaks",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hudspeth County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hughes Springs",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Humble",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hunt County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hunters Creek Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Huntington",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Huntsville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hurst",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hutchins",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hutchinson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Hutto",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Idalou",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Indian Hills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Inez",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ingleside",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ingram",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Iowa Colony",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Iowa Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Iraan",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Irion County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Irving",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Italy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Itasca",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jacinto City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jack County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jacksboro",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jacksonville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jamaica Beach",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jarrell",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jasper",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jasper County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jayton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jeff Davis County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jersey Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jewett",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jim Hogg County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jim Wells County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Johnson City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jollyville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jones County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jones Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jonestown",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Josephine",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Joshua",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Jourdanton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Junction",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Justin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Karnes City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Karnes County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Katy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kaufman",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kaufman County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Keene",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Keller",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kemah",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kemp",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kempner",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kendall County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kenedy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kenedy County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kennedale",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kent County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kerens",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kermit",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kerr County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kerrville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kilgore",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Killeen",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kimble County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "King County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kingsland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kingsville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kingwood Area",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kinney County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kirby",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kirbyville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kleberg County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Knox City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Knox County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kountze",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Krugerville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Krum",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Kyle",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Blanca",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Coste",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Feria",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Grange",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Grulla",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Homa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Joya",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Marque",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Paloma",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Porte",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Pryor",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Salle County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Vernia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "La Villa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lackland Air Force Base",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lacy-Lakeview",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lago Vista",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Laguna Heights",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Laguna Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Laguna Vista",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lake Brownwood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lake Dallas",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lake Dunlap",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lake Jackson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lake Kiowa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lake Worth",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lakehills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lakeside",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lakeway",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lamar County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lamb County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lamesa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lampasas",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lampasas County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lantana",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Laredo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Las Lomas",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Las Palmas II",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Las Quintas Fronterizas",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Las Quintas Fronterizas Colonia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lasara",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Laughlin Air Force Base",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Laureles",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lavaca County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lavon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "League City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Leakey",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Leander",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Leon County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Leon Valley",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Leonard",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Levelland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lewisville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Liberty City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Liberty County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Liberty Hill",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Limestone County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lindale",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Linden",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lindsay",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lipscomb County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Little Elm",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Little River-Academy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Littlefield",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Live Oak",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Live Oak County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Livingston",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Llano",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Llano County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Llano Grande",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lockhart",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lockney",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lone Star",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Longview",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lopezville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lorena",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lorenzo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Los Fresnos",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Los Indios",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lost Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Loving County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lowry Crossing",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lubbock",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lubbock County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lucas",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lufkin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Luling",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lumberton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lyford",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lynn County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Lytle",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mabank",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Madisonville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Magnolia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Malakoff",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Manchaca",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Manor",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mansfield",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Manvel",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Marble Falls",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Marfa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Markham",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Marlin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Marshall",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mart",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Martin County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Martindale",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mason",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mason County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Matador",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Matagorda County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mathis",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Maud",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mauriceville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Maverick County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "McAllen",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "McCamey",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "McCulloch County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "McGregor",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "McKinney",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "McLendon-Chisholm",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "McLennan County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "McMullen County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "McQueeney",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Meadowlakes",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Meadows Place",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Medina",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Medina County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Melissa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Memphis",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Menard",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Menard County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mentone",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mercedes",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Meridian",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Merkel",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mertzon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mesquite",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mexia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Miami",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Midland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Midland County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Midlothian",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Midway North",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Midway South",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mila Doce",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Milam",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Milam County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mills County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mineola",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mineral Wells",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mission",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mission Bend",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Missouri City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mitchell County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Monahans",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mont Belvieu",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Montague",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Montague County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Monte Alto",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Moody",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Moore County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Morgans Point Resort",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Morris County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Morton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Motley County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mount Pleasant",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Muenster",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Muleshoe",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Munday",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Muniz",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Murillo Colonia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Murphy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nacogdoches",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nacogdoches County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Naples",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nash",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nassau Bay",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Natalia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Navarro County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Navasota",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nederland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Needville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nevada",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "New Boston",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "New Braunfels",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "New Summerfield",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "New Territory",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "New Waverly",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Newark",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Newton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Newton County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nixon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nocona",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nolan County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nolanville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "North Alamo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "North Richland Hills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Northcliff",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Northcrest",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Northlake",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nueces County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Nurillo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Oak Cliff Place",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Oak Leaf",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Oak Point",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Oak Ridge North",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Oak Trail Shores",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ochiltree County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Odem",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Odessa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Old River-Winfree",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Oldham County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Olivarez",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Olmito",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Olmos Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Olney",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Olton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Onalaska",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Onion Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Orange",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Orange County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Orange Grove",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ore City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Overton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ovilla",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Oyster Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ozona",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Paducah",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Paint Rock",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Palacios",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Palestine",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Palm Valley",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Palmer",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Palmhurst",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Palmview",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Palmview South",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Palo Pinto",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Palo Pinto County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Paloma Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Paloma Creek South",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pampa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Panhandle",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Panola County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Panorama Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pantego",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Paris",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Parker",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Parker County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Parmer County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pasadena",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Patton Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pearland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pearsall",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pecan Acres",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pecan Grove",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pecan Plantation",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pecos",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pecos County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pelican Bay",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Penitas",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Perezville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Perryton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Petersburg",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pflugerville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pharr",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pilot Point",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pine Island",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pinehurst",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pinewood Estates",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Piney Point Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pittsburg",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Plains",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Plainview",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Plano",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pleak",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pleasanton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Plum Grove",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ponder",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Port Aransas",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Port Arthur",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Port Isabel",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Port Lavaca",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Port Neches",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Port O'Connor",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Porter Heights",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Portland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Post",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Poteet",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Poth",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Potosi",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Potter County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Pottsboro",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Powderly",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Prairie View",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Premont",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Presidio",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Presidio County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Preston",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Primera",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Progreso",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Prosper",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Providence",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Quail Creek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Quanah",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Queen City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Quinlan",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Quitman",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rains County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ralls",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rancho Alegre",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rancho Viejo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Randall County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ranger",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rankin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ransom Canyon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Raymondville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Reagan County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Real County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Red Lick",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Red Oak",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Red River County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Redland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Redwater",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Redwood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Reeves County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Refugio",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Refugio County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rendon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Reno",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rhome",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ricardo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Richardson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Richland Hills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Richwood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Riesel",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rio Bravo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rio Grande City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rio Hondo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "River Oaks",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Roanoke",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Robert Lee",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Roberts County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Robertson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Robinson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Robstown",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Roby",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rockdale",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rockport",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rocksprings",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rockwall",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rockwall County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rogers",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rollingwood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Roma",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Roma-Los Saenz",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Roman Forest",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Roscoe",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rosebud",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rosenberg",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rosharon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rosita North",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rosita South",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rotan",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Round Rock",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rowlett",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Royse City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Runaway Bay",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Runge",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Runnels County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rusk",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Rusk County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sabinal",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sabine County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sachse",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Saginaw",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Saint Hedwig",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Saint Jo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Saint Paul",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Salado",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sam Rayburn",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Angelo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Antonio",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Augustine",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Augustine County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Benito",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Carlos",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Diego",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Elizario",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Jacinto County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Juan",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Leon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Marcos",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Patricio County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Saba",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "San Saba County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sanderson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sanger",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sansom Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Santa Anna",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Santa Fe",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Santa Rosa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sarita",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Savannah",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Scenic Oaks",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Schertz",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Schleicher County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Schulenburg",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Scissors",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Scurry County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Seabrook",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Seadrift",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Seagoville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Seagraves",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sealy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sebastian",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Seguin",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Selma",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Seminole",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Serenada",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Seth Ward",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Seven Points",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Seymour",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shackelford County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shady Hollow",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shady Shores",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shallowater",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shamrock",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shavano Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shelby County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sheldon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shenandoah",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shepherd",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sherman",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sherman County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sherwood Shores",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shiner",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Shoreacres",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sienna Plantation",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sierra Blanca",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Siesta Acres",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Siesta Shores",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Silsbee",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Silverton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sinton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Slaton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Smith County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Smithville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Snyder",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Socorro",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Socorro Mission Number 1 Colonia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Somerset",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Somervell County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Somerville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sonora",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sour Lake",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "South Alamo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "South Houston",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "South Padre Island",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "South Point",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Southlake",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Southmayd",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Southside Place",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sparks",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Spearman",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Splendora",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Spring",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Spring Valley",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Springtown",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Spur",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stafford",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stamford",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stanton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Starr County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stephens County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stephenville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sterling City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sterling County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stinnett",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stockdale",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stonewall County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stowell",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Stratford",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sugar Land",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sullivan City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sulphur Springs",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sundown",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sunnyvale",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sunray",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sutton County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sweeny",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Sweetwater",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Swisher County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Taft",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Taft Southwest (historical)",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tahoka",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Talty",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tarrant County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tatum",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Taylor",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Taylor County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Taylor Lake Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Teague",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Temple",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tenaha",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Terrell",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Terrell County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Terrell Hills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Terry County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Texarkana",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Texas City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "The Colony",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "The Hills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "The Woodlands",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Thorndale",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Three Rivers",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Throckmorton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Throckmorton County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tiki Island",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tilden",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Timberwood Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Timpson",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Titus County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tom Bean",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tom Green County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tomball",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tool",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tornillo",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Travis County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Travis Ranch",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Trinity",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Trinity County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Trophy Club",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Troup",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Troy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tulia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tye",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tyler",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Tyler County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Uhland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Universal City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "University Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Upshur County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Upton County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Uvalde",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Uvalde County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Uvalde Estates",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Val Verde County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Val Verde Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Valley Mills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Van",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Van Alstyne",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Van Horn",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Van Vleck",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Van Zandt County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Vega",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Venus",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Vernon",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Victoria",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Victoria County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Vidor",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Vinton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Von Ormy",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Waco",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wake Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Walker County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Waller",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Waller County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wallis",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Ward County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Waskom",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Watauga",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Waxahachie",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Weatherford",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Webb County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Webster",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Weimar",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wellington",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wells Branch",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Weslaco",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "West",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "West Columbia",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "West Lake Hills",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "West Livingston",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "West Odessa",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "West Orange",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "West Sharyland",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "West Tawakoni",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "West University Place",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Western Lake",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Westlake",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Weston Lakes",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Westway",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Westworth",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wharton",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wharton County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wheeler",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wheeler County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "White Oak",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "White Settlement",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Whitehouse",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Whitesboro",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Whitewright",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Whitney",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wichita County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wichita Falls",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wilbarger County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wild Peach Village",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wildwood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Willacy County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Williamson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Willis",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Willow Park",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wills Point",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wilmer",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wilson County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wimberley",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Windcrest",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Windemere",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wink",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Winkler County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Winnie",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Winnsboro",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Winters",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wise County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wolfe City",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wolfforth",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wood County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Woodbranch",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Woodcreek",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Woodsboro",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Woodville",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Woodway",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wortham",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wyldwood",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Wylie",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Yoakum",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Yoakum County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Yorktown",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Young County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Zapata",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Zapata County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Zavala County",
    country_code: "US",
    state_code: "TX",
  },
  {
    name: "Alpine",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "American Fork",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Aurora",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Ballard",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Beaver",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Beaver County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Benjamin",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Benson",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Blanding",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Bluffdale",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Bountiful",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Box Elder County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Brigham City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Cache County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Canyon Rim",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Carbon County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Carbonville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Castle Dale",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Cedar City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Cedar Hills",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Centerfield",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Centerville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Clearfield",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Coalville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Cottonwood Heights",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Daggett County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Daniel",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Davis County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Delta",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Draper",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Duchesne",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Duchesne County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Eagle Mountain",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "East Carbon City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "East Millcreek",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Elk Ridge",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Elwood",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Emery County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Enoch",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Enterprise",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Ephraim",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Erda",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Fairview",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Farmington",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Farr West",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Ferron",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Fillmore",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Fountain Green",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Francis",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Fruit Heights",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Garfield County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Garland",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Genola",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Grand County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Granite",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Grantsville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Gunnison",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Harrisville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Heber City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Helper",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Herriman",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Highland",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Hildale",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Hill Air Force Base",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Holladay",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Honeyville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Hooper",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Huntington",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Hurricane",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Hyde Park",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Hyrum",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Iron County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Ivins",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Juab County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Junction",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Kamas",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Kanab",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Kane County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Kaysville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Kearns",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "LaVerkin",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Layton",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Lehi",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Lewiston",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Liberty",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Lindon",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Little Cottonwood Creek Valley",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Loa",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Logan",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Maeser",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Magna",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Manila",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Manti",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Mapleton",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Marriott-Slaterville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Mendon",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Midvale",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Midway",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Millard County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Millcreek",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Millville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Moab",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Mona",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Morgan",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Moroni",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Mount Olympus",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Mount Pleasant",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Mountain Green",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Murray",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Naples",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Nephi",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Nibley",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "North Logan",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "North Ogden",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "North Salt Lake",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Oakley",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Ogden",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Oquirrh",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Orangeville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Orem",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Panguitch",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Park City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Parowan",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Payson",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Perry",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Piute County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Plain City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Pleasant Grove",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Pleasant View",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Price",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Providence",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Provo",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Randolph",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Rich County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Richfield",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "River Heights",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Riverdale",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Riverton",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Roosevelt",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Roy",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Saint George",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Salina",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Salt Lake City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Salt Lake County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "San Juan County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Sandy",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Sandy Hills",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Sanpete County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Santa Clara",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Santaquin",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Saratoga Springs",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Sevier County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Silver Summit",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Smithfield",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Snyderville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "South Jordan",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "South Jordan Heights",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "South Ogden",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "South Salt Lake",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "South Weber",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "South Willard",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Spanish Fork",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Spring City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Spring Glen",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Springville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Stansbury park",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Summit County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Summit Park",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Sunset",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Syracuse",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Taylorsville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Tooele",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Tooele County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Toquerville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Tremonton",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Uintah",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Uintah County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Utah County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Vernal",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Vineyard",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Wasatch County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Washington Terrace",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Weber County",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Wellington",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Wellsville",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Wendover",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "West Bountiful",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "West Haven",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "West Jordan",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "West Mountain",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "West Point",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "West Valley City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "White City",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Willard",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Wolf Creek",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Woodland Hills",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Woods Cross",
    country_code: "US",
    state_code: "UT",
  },
  {
    name: "Addison",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Addison County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Barre",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Bellows Falls",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Bennington",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Bennington County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Brandon",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Brattleboro",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Bridport",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Caledonia County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Castleton",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Charlotte",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Chelsea",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Chittenden",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Chittenden County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Clarendon",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Colchester",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Danby",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Dover",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Enosburg Falls",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Essex County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Essex Junction",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Fair Haven",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Ferrisburgh",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Grand Isle County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Guildhall",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Hardwick",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Hinesburg",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Hyde Park",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Jamaica",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Jericho",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Johnson",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Lamoille County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Leicester",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Lincoln",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Londonderry",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Lunenburg",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Lyndon",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Lyndonville",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Manchester Center",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Mendon",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Middlebury (village)",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Montgomery",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Montpelier",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Moretown",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Morristown",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Morrisville",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Mount Holly",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Newfane",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "North Bennington",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "North Hero",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Northfield",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Orange County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Orleans County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Pawlet",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Poultney",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Pownal",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Randolph",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Richford",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Rockingham",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Rutland",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Rutland County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Saint Albans",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Saint Johnsbury",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Salisbury",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "South Barre",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "South Burlington",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "St Johnsbury",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Starksboro",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Stowe",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Swanton",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Townshend",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Vergennes",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Waterbury",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "West Brattleboro",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "West Rutland",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "White River Junction",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Wilder",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Williamstown",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Williston",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Windham County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Windsor County",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Winooski",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Woodstock",
    country_code: "US",
    state_code: "VT",
  },
  {
    name: "Abingdon",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Accomac",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Accomack County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Adwolf",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Albemarle County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Alexandria",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Alleghany County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Altavista",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Amelia County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Amelia Court House",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Amherst",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Amherst County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Annandale",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Appalachia",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Apple Mountain Lake",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Appomattox",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Appomattox County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Aquia Harbour",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Arlington County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Ashburn",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Atkins",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Augusta County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Baileys Crossroads",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bassett",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Basye",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bath County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bealeton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bedford",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bedford County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Belle Haven",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bellwood",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Belmont",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Belmont Estates",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bensley",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Berryville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Big Stone Gap",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Blacksburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Blackstone",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bland",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bland County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Blue Ridge",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bluefield",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bon Air",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Boswell's Corner",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Botetourt County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bowling Green",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Boydton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bracey",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Brambleton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Brandermill",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bridgewater",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Brightwood",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Broadlands",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Broadway",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Brookneal",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Brunswick County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Buchanan",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Buchanan County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Buckhall",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Buckingham",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Buckingham County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Buena Vista",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bull Run",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Bull Run Mountain Estates",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Burke",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Campbell County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Cana",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Cape Charles",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Captains Cove",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Caroline County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Carroll County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Carrollton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Castlewood",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Cave Spring",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Cedar Bluff",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Central Garage",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Centreville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chamberlayne",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chantilly",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Charles City",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Charles City County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Charlotte County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Charlotte Court House",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Charlottesville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chase City",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chatham",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chatmoss",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Cherry Hill",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chesapeake",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chesterfield County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chesterfield Court House",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chilhowie",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Chincoteague",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Christiansburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Alexandria",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Bedford",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Bristol",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Buena Vista",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Charlottesville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Chesapeake",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Colonial Heights",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Covington",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Danville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Emporia",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Fairfax",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Falls Church",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Franklin",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Fredericksburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Galax",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Hampton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Harrisonburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Hopewell",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Lexington",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Lynchburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Manassas",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Manassas Park",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Martinsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Newport News",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Norfolk",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Norton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Petersburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Poquoson",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Portsmouth",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Radford",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Richmond",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Roanoke",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Salem",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Staunton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Suffolk",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Virginia Beach",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Waynesboro",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Williamsburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "City of Winchester",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Clarke County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Clarksville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Claypool Hill",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Clifton Forge",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Clintwood",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Cloverdale",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Coeburn",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Collinsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Colonial Beach",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Colonial Heights",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Countryside",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Courtland",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Covington",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Craig County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Crewe",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Crimora",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Crozet",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Culpeper",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Culpeper County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Cumberland",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Cumberland County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dahlgren",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dale City",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Daleville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Danville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Deltaville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dickenson County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dinwiddie County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dooms",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dranesville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dryden",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dublin",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dulles Town Center",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dumbarton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dumfries",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Dunn Loring",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "East Hampton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "East Highland Park",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "East Lexington",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Eastville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Edinburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Elkton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Emory",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Emporia",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Enon",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Essex County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Ettrick",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Exmore",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fairfax",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fairfax County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fairfax Station",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fairlawn",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Falls Church",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Falmouth",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Farmville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fauquier County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Ferrum",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fincastle",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fishersville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Floris",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Floyd",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Floyd County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fluvanna County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Forest",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fort Belvoir",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fort Hunt",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fort Lee",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Franconia",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Frederick County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Fredericksburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Front Royal",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Gainesville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Galax",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Gate City",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Giles County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Glade Spring",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Glasgow",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Glen Allen",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Gloucester County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Gloucester Courthouse",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Gloucester Point",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Goochland",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Goochland County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Gordonsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Grayson County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Great Falls",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Greenbriar",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Greene County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Greensville County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Gretna",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Grottoes",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Groveton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Grundy",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Halifax",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Halifax County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hampden Sydney",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hampton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hanover",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hanover County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Harrisonburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hayfield",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Haymarket",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Heathsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Henrico County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Henry County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Henry Fork",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Herndon",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Highland County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Highland Springs",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hillsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hollins",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hollymead",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Honaker",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hopewell",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Horse Pasture",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Huntington",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hurt",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Hybla Valley",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Idylwood",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Independent Hill",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Isle of Wight County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "James City County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Jolivue",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Jonesville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Kenbridge",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Kilmarnock",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "King and Queen County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "King and Queen Court House",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "King George",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "King George County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "King William",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "King William County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Kings Park",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Kings Park West",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lake Barcroft",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lake Monticello",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lake Ridge",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lakeside",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lancaster County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Laurel",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Laurel Hill",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lawrenceville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Laymantown",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lebanon",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lee County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Leesburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lexington",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lincolnia",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Linton Hall",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Loch Lomond",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lorton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Loudoun County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Loudoun Valley Estates",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Louisa",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Louisa County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lovettsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lovingston",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lowes Island",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lunenburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lunenburg County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Luray",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lynchburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Lyndhurst",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Madison County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Madison Heights",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Manassas",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Manassas Park",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Mantua",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Marshall",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Martinsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Massanetta Springs",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Massanutten",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Mathews",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Mathews County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Matoaca",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "McLean",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Meadowbrook",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Mechanicsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Mecklenburg County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Merrifield",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Merrimac",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Middlesex County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Middletown",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Montclair",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Monterey",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Montgomery County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Montrose",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Montross",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Motley",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Mount Hermon",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Mount Jackson",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Mountain Road",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Narrows",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Nellysford",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Nelson County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "New Baltimore",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "New Castle",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "New Kent",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "New Kent County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "New Market",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Newington",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Newport News",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Nokesville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Norfolk",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "North Shore",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "North Springfield",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Northampton County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Northumberland County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Norton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Nottoway County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Oak Grove",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Oak Hill",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Oakton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Occoquan",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Onancock",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Orange",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Orange County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Page County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Palmyra",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Pannill Fork",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Pantops",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Passapatanzy",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Patrick County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Patrick Springs",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Pearisburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Pembroke",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Pennington Gap",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Petersburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Pimmit Hills",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Pittsylvania County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Plum Creek",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Poquoson",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Portsmouth",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Portsmouth Heights",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Potomac Mills",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Powhatan County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Prices Fork",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Prince Edward County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Prince George",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Prince George County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Prince William County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Pulaski",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Pulaski County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Purcellville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Quantico Station",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Radford",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Rappahannock County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Raven",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Ravensworth",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Reston",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Richlands",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Richmond County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Roanoke",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Roanoke County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Rockbridge County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Rockingham County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Rocky Mount",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Rose Hill",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Rosslyn",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Ruckersville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Rural Retreat",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Rushmere",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Russell County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Rustburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Saltville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Saluda",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Sandston",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Scott County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Seven Corners",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Shawnee Land",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Shawsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Shenandoah",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Shenandoah County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Shenandoah Farms",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Short Pump",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Smithfield",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Smyth County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "South Boston",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "South Hill",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "South Riding",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "South Suffolk",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Southampton County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Southern Gateway",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Spotsylvania County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Spotsylvania Courthouse",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Springfield",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Springville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Stafford",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Stafford County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Stanardsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Stanley",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Stanleytown",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Staunton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Stephens City",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Sterling",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Strasburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Stuart",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Stuarts Draft",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Sudley",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Suffolk",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Sugarland Run",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Surry",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Surry County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Sussex",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Sussex County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Tappahannock",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Tazewell",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Tazewell County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Timberlake",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Timberville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Triangle",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Tuckahoe",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Twin Lakes",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Tysons Corner",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Union Hall",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "University Center",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Verona",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Victoria",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Vienna",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Vinton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Virginia Beach",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Warm Springs",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Warren County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Warrenton",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Warsaw",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Wattsville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Waverly",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Waynesboro",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Weber City",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "West Falls Church",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "West Gate",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "West Lynchburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "West Point",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "West Springfield",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Westmoreland County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Weyers Cave",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Williamsburg",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Winchester",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Wise",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Wise County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Wolf Trap",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Woodbridge",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Woodburn",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Woodlake",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Woodlawn",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Woodstock",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Wyndham",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Wythe County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Wytheville",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "York County",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Yorkshire",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Yorktown",
    country_code: "US",
    state_code: "VA",
  },
  {
    name: "Aberdeen",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ahtanum",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Airway Heights",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Alderton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Alderwood Manor",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Algona",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Allyn",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Amboy",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ames Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Anacortes",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Arlington",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Arlington Heights",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Artondale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Asotin",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Asotin County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Auburn",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ault Field",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bainbridge Island",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bangor Trident Base",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Barberton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Basin City",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Battle Ground",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Belfair",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bellingham",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Benton City",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Benton County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bethel",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Big Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Birch Bay",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Black Diamond",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Blaine",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bonney Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bothell",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bothell East",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bothell West",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Boulevard Park",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bremerton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Brewster",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Brier",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Browns Point",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Brush Prairie",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bryant",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bryn Mawr-Skyway",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Buckley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Bunk Foss",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Burbank",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Burien",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Burley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Camano",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Camas",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Canterwood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Carnation",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Carson",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Cascade Valley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Cashmere",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Castle Rock",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Cathcart",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Cathlamet",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Central Park",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Centralia",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Chehalis",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Chelan",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Chelan County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Cheney",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Chewelah",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Chico",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "City of Sammamish",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Clallam County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Clarkston",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Clarkston Heights-Vineland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Cle Elum",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Clear Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Clearview",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Clyde Hill",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Colfax",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "College Place",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Columbia County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Colville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Connell",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Cosmopolis",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Cottage Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Coulee Dam",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Country Homes",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Coupeville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Covington",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Cowlitz County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Crocker",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Dallesport",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Darrington",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Davenport",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Dayton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Deer Park",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Des Moines",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Desert Aire",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Dishman",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Dollar Corner",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "DuPont",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Duvall",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "East Hill-Meridian",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "East Port Orchard",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "East Renton Highlands",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "East Wenatchee",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "East Wenatchee Bench",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Eastgate",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Eastmont",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Eatonville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Edgewood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Edmonds",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Electric City",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Elk Plain",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ellensburg",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Elma",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Enetai",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Entiat",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Enumclaw",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ephrata",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Erlands Point-Kitsap Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Esperance",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Everett",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Everson",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fairchild Air Force Base",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fairwood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fall City",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Federal Way",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Felida",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fern Prairie",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ferndale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ferry County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fife",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fife Heights",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Finley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fircrest",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Five Corners",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fobes Hill",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fords Prairie",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Forks",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Fox Island",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Franklin County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Frederickson",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Freeland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Friday Harbor",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Garfield County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Garrett",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Geneva",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Gig Harbor",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Gleed",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Gold Bar",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Goldendale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Graham",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Grand Coulee",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Grand Mound",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Grandview",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Granger",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Granite Falls",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Grays Harbor County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Hansville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Hazel Dell",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Highland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Hobart",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Hockinson",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Home",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Hoquiam",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Indianola",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Inglewood-Finn Hill",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Island County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Issaquah",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Joint Base Lewis McChord",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kalama",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kelso",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kenmore",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kennewick",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kent",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kettle Falls",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Key Center",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "King County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kingsgate",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kingston",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kirkland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kitsap County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kittitas",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Kittitas County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Klahanie",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Klickitat County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "La Center",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lacey",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lake Forest Park",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lake Marcel-Stillwater",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lake Morton-Berrydale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lake Shore",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lake Stevens",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lake Stickney",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lakeland North",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lakeland South",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lakewood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Langley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Larch Way",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lea Hill",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Leavenworth",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lewis County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lewisville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Liberty Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lochsloy",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lofall",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Long Beach",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Longbranch",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Longview",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Longview Heights",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lynden",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Lynnwood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mabton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Machias",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Maltby",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Manson",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Maple Heights-Lake Desire",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Maple Valley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Maplewood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Marietta",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Marietta-Alderwood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Martha Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Marysville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mason County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mattawa",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "McChord Air Force Base",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "McCleary",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "McMillin",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mead",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Meadow Glade",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Meadowdale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Medical Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Medina",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mercer Island",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Midland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mill Creek",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mill Creek East",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mill Plain",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Millwood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Minnehaha",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mirrormont",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Monroe North",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Montesano",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Morton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Moses Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Moses Lake North",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mount Vernon",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mount Vista",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mountlake Terrace",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Mukilteo",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Napavine",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Navy Yard City",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Newcastle",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Newport",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Nooksack",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Normandy Park",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "North Bend",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "North Creek",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "North Fort Lewis",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "North Puyallup",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "North Yelm",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Oak Harbor",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ocean Park",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ocean Shores",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Okanogan",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Okanogan County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Olympia",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Omak",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Opportunity",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Orchards",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Oroville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Orting",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Othello",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Otis Orchards-East Farms",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Pacific",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Pacific County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Palouse",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Parkland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Parkwood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Pasco",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Peaceful Valley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Pend Oreille County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Picnic Point",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Picnic Point-North Lynnwood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Pierce County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Point Roberts",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Pomeroy",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Port Angeles",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Port Angeles East",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Port Hadlock-Irondale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Port Ludlow",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Port Orchard",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Port Townsend",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Poulsbo",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Prairie Heights",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Prairie Ridge",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Prosser",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Pullman",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Purdy",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Puyallup",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Quincy",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Rainier",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ravensdale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Raymond",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Redmond",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Renton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Republic",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Richland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ridgefield",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Ritzville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Riverbend",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Riverton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Rocky Point",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Rosedale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Royal City",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Salmon Creek",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sammamish",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "San Juan County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Seabeck",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "SeaTac",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Seattle",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sedro-Woolley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Selah",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sequim",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Shelton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Shoreline",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Silver Firs",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Silverdale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sisco Heights",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Skagit County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Skamania County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Smokey Point",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Snohomish",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Snohomish County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Snoqualmie",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Soap Lake",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "South Bend",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "South Hill",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "South Wenatchee",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Southworth",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Spanaway",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Spokane",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Spokane County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Spokane Valley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Stanwood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Steilacoom",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Stevens County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Stevenson",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sudden Valley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sultan",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sumas",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Summit",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Summit View",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sumner",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sunnyside",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Sunnyslope",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Suquamish",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tacoma",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tanglewilde",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tanglewilde-Thompson Place",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tanner",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tenino",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Terrace Heights",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Three Lakes",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Thurston County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tieton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tonasket",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Toppenish",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Town and Country",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tracyton",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Trentwood",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tukwila",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tulalip",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tulalip Bay",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Tumwater",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Union Gap",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Union Hill-Novelty Hill",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "University Place",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Vancouver",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Vashon",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Venersborg",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Veradale",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Wahkiakum County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Waitsburg",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Walla Walla",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Walla Walla County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Walla Walla East",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Waller",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Walnut Grove",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Wapato",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Warden",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Warm Beach",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Washougal",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Waterville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Wauna",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Wenatchee",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "West Clarkston-Highland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "West Lake Sammamish",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "West Lake Stevens",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "West Longview",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "West Pasco",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "West Richland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "West Side Highway",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "West Valley",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "West Wenatchee",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Westport",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Whatcom County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "White Center",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "White Salmon",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Whitman County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Wilderness Rim",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Winlock",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Wollochet",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Woodinville",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Woodland",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Woods Creek",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Woodway",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Yacolt",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Yakima",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Yakima County",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Yarrow Point",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Yelm",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Zillah",
    country_code: "US",
    state_code: "WA",
  },
  {
    name: "Alderson",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Alum Creek",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Ansted",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Barbour County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Barboursville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Barrackville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Beaver",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Beckley",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Belington",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Belle",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Benwood",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Berkeley County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Berkeley Springs",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Bethany",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Bethlehem",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Blennerhassett",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Bluefield",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Bluewell",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Boaz",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Bolivar",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Boone County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Bradley",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Braxton County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Bridgeport",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Brooke County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Brookhaven",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Brush Fork",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Buckhannon",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Cabell County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Calhoun County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Ceredo",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Chapmanville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Charles Town",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Charleston",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Cheat Lake",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Chesapeake",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Chester",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Clarksburg",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Clay",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Clay County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Clendenin",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Coal City",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Coal Fork",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Crab Orchard",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Craigsville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Cross Lanes",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Culloden",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Daniels",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Despard",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Doddridge County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Dunbar",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Eleanor",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Elizabeth",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Elkins",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Elkview",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Fairlea",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Fairmont",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Fayette County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Fayetteville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Follansbee",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Fort Ashby",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Gilbert Creek",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Gilmer County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Glendale",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Glenville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Grafton",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Grantsville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Granville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Greenbrier County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Hamlin",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Hampshire County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Hancock County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Hardy County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Harrison County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Harrisville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Hinton",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Hooverson Heights",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Huntington",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Hurricane",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Inwood",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Kanawha County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Kenova",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Keyser",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Kingwood",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Lavalette",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Lesage",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Lewis County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Lewisburg",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Logan",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Logan County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Lubeck",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mabscott",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "MacArthur",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mallory",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mannington",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Marion County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Marlinton",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Marmet",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Marshall County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Martinsburg",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mason County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "McDowell County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "McMechen",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mercer County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Middlebourne",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mineral County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mineral Wells",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mingo County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Monongah",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Monongalia County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Montgomery",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Moorefield",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Morgan County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Morgantown",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Moundsville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mount Gay-Shamrock",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mount Hope",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Mullens",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "New Cumberland",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "New Haven",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "New Martinsville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Newell",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Nicholas County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Nitro",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Nutter Fort",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Oak Hill",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Oceana",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Ohio County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Paden City",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Parkersburg",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Parsons",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Pea Ridge",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Pendleton County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Pennsboro",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Petersburg",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Philippi",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Pinch",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Pineville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Pleasant Valley",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Pleasants County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Pocahontas County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Point Pleasant",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Preston County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Prosperity",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Putnam County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Rainelle",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Raleigh County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Rand",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Randolph County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Ranson",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Ravenswood",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Richwood",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Ripley",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Ritchie County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Roane County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Romney",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Ronceverte",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Saint Albans",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Saint Marys",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Shady Spring",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Shannondale",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Shepherdstown",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Shinnston",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Sissonville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Sistersville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Sophia",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "South Charleston",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Spencer",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Stanaford",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Star City",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Stonewood",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Summers County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Summersville",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Sutton",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Taylor County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Teays Valley",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Terra Alta",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Tornado",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Tucker County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Tyler County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Union",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Upshur County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Vienna",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Washington",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Wayne",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Wayne County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Webster County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Webster Springs",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Weirton",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Weirton Heights",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Welch",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Wellsburg",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "West Liberty",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "West Union",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Weston",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Westover",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Wetzel County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Wheeling",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "White Sulphur Springs",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Wiley Ford",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Williamson",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Williamstown",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Winfield",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Wirt County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Wood County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Wyoming County",
    country_code: "US",
    state_code: "WV",
  },
  {
    name: "Abbotsford",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Adams",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Adams County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Addison",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Albany",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Algoma",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Allouez",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Alma",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Alto",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Altoona",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Amery",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Amherst",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Antigo",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Appleton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Arcadia",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Ashford",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Ashland",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Ashland County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Ashwaubenon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Athens",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Augusta",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Aztalan",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Baldwin",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Balsam Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Bangor",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Baraboo",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Barneveld",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Barron",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Barron County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Barton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Bayfield County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Bayside",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Beaver Dam",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Belgium",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Belle Plaine",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Belleville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Bellevue",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Beloit",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Berlin",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Bevent",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Big Bend",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Black Creek",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Black Earth",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Black River Falls",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Blair",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Bloomer",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Bohners Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Bonduel",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Boscobel",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Boyceville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Brice Prairie",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Brillion",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Bristol",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Brodhead",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Brookfield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Brooklyn",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Brothertown",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Brown County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Brown Deer",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Browns Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Brussels",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Buffalo County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Burlington",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Burnett County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Butler",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cadott",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Caledonia",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Calumet County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cambridge",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cameron",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Camp Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Campbellsport",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cashton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cato",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cedar Grove",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cedarburg",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Chetek",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Chilton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Chippewa County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Chippewa Falls",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Clark County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Clear Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cleveland",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Clinton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Clintonville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Colby",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Colfax",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Columbia County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Columbus",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Combined Locks",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Como",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Concord",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cooperstown",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cornell",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cottage Grove",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Crandon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Crawford County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cross Plains",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cuba City",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cudahy",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Cumberland",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Dakota",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Dane",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Dane County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Darien",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Darlington",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "De Forest",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "De Pere",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Decatur",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Deerfield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Delafield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Delavan",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Delavan Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Denmark",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Dickeyville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Dodge County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Dodgeville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Door County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Douglas County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Dousman",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Dunn County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Durand",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Eagle",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Eagle Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Eagle River",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "East Troy",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Easton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Eau Claire",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Eau Claire County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Edgar",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Edgerton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Eldorado",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Elkhorn",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Ellsworth",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Elm Grove",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Elroy",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Evansville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Evergreen",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fall Creek",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fall River",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fennimore",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fitchburg",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Florence",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Florence County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fond du Lac",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fond du Lac County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fontana",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Forest County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fort Atkinson",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fox Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fox Point",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Franklin",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Franksville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Frederic",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Fredonia",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "French Island",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Friendship",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Galesville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Genoa City",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Germantown",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Gillett",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Glendale",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Glenmore",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Glenwood City",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Grafton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Grant County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Grantsburg",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Green Bay",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Green County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Green Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Green Lake County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Greendale",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Greenfield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Greenwood",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hales Corners",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hammond",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Harrison",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hartford",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hartland",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hayward",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hazel Green",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hilbert",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hillsboro",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hobart",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Holmen",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Horicon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hortonville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Howard",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Howards Grove",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hudson",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hurley",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Hustisford",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Independence",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Iola",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Iowa County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Iron County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Ixonia",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Jackson County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Janesville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Jefferson",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Jefferson County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Johnson Creek",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Juneau",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Juneau County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kaukauna",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kenosha",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kenosha County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Keshena",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kewaskum",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kewaunee",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kewaunee County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kiel",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kimberly",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "King",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kohler",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Kronenwetter",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "La Crosse",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "La Crosse County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lac du Flambeau",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Ladysmith",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lafayette County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Delton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Geneva",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Hallie",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Koshkonong",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Mills",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Nebagamon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Ripley",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Wazeecha",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Wisconsin",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lake Wissota",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lamartine",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lancaster",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Langlade County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lannon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Legend Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Little Chute",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Little Round Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lodi",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Lomira",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Loyal",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Luck",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Luxemburg",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Madison",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Maine",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Manawa",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Manchester",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Manitowoc",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Manitowoc County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Maple Bluff",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Marathon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Marathon County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Marinette",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Marinette County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Marion",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Markesan",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Marquette County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Marshall",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Marshfield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mauston",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mayville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mazomanie",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "McFarland",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Medford",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Menasha",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Menominee County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Menomonee Falls",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Menomonie",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mequon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Merrill",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Merton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Middleton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Milford",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Milton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Milwaukee",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Milwaukee County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mineral Point",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mishicot",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mondovi",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Monona",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Monroe",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Monroe County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Montello",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Monticello",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mosinee",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mount Horeb",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mount Morris",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mount Pleasant",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Mukwonago",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Muscoda",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Muskego",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Nashotah",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Nashville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Neenah",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Neillsville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Nekoosa",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "New Berlin",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "New Glarus",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "New Holstein",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "New Lisbon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "New London",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "New Richmond",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Newburg",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Niagara",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "North Fond du Lac",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "North Hudson",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "North La Crosse",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "North Prairie",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oak Creek",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oakfield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oconomowoc",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oconto",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oconto County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oconto Falls",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Okauchee Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Omro",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Onalaska",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oneida",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oneida County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oostburg",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oregon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Orfordville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Osceola",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Oshkosh",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Osseo",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Outagamie County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Ozaukee County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Paddock Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Palmyra",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Pardeeville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Park Falls",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Pell Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Pepin County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Peshtigo",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Pewaukee",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Phillips",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Pierce County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Pittsfield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Platteville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Pleasant Prairie",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Plover",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Plymouth",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Polk County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Port Edwards",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Port Washington",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Portage",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Portage County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Portland",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Potter Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Powers Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Poynette",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Prairie du Chien",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Prairie du Sac",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Prescott",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Price County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Princeton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Pulaski",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Racine",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Racine County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Randolph",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Random Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Redgranite",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Reedsburg",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Reedsville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rhinelander",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rib Mountain",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rice Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Richfield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Richland Center",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Richland County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Richmond",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rio",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Ripon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "River Falls",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "River Hills",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Roberts",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rochester",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rock County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rome",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rosendale",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rothschild",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Roxbury",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rusk County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Rutland",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Saint Croix County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Saint Croix Falls",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Saint Francis",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Saint Peter",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Salem",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sauk City",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sauk County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Saukville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sawyer County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Schofield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Seymour",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sharon",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Shawano",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Shawano County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sheboygan",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sheboygan County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sheboygan Falls",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Shell Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sherwood",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Shorewood",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Shorewood Hills",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Shullsburg",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Silver Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Siren",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Slinger",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Somers",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Somerset",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "South Milwaukee",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sparta",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Spencer",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Spooner",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Spring Green",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Spring Valley",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Stanley",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Stevens Point",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Stoughton",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Stratford",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Strum",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sturgeon Bay",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sturtevant",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Suamico",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sun Prairie",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Superior",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Sussex",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Tainter Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Taylor County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Theresa",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Thiensville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Thorp",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Tichigan",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Tomah",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Tomahawk",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Trempealeau",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Trempealeau County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Turtle Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Twin Lakes",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Two Rivers",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Union Grove",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Vernon County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Verona",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Vilas County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Viroqua",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wales",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Walworth",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Walworth County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Washburn",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Washburn County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Washington County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Waterford",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Waterloo",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Watertown",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Waukesha",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Waukesha County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Waunakee",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Waupaca",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Waupaca County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Waupun",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wausau",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Waushara County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wautoma",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wauwatosa",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "West Allis",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "West Baraboo",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "West Bend",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "West Milwaukee",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "West Salem",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Westby",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Westfield",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Weston",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Weyauwega",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Whitefish Bay",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Whitehall",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Whitewater",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Whiting",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Williams Bay",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wind Lake",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wind Point",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Windsor",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Winnebago County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Winneconne",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wisconsin Dells",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wisconsin Rapids",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wittenberg",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wood County",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Woodville",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Wrightstown",
    country_code: "US",
    state_code: "WI",
  },
  {
    name: "Afton",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Albany County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Antelope Valley-Crestview",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Arapahoe",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Bar Nunn",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Basin",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Big Horn County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Buffalo",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Campbell County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Carbon County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Casper",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Cheyenne",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Cody",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Converse County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Crook County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Douglas",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Ethete",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Evanston",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Evansville",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Fort Washakie",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Fox Farm-College",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Fremont County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Gillette",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Glenrock",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Goshen County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Green River",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Greybull",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Guernsey",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Hoback",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Hot Springs County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Jackson",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Johnson County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Kemmerer",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Lander",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Laramie",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Laramie County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Lincoln County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Lovell",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Lusk",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Lyman",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Marbleton",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Mills",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Moorcroft",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Moose Wilson Road",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Mountain View",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Natrona County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Newcastle",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Niobrara County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "North Rock Springs",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Park County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Pine Bluffs",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Pinedale",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Platte County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Powell",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Rafter J Ranch",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Ranchettes",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Rawlins",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Riverton",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Rock Springs",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Saratoga",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Sheridan",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Sheridan County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Sleepy Hollow",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "South Greeley",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "South Park",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Star Valley Ranch",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Sublette County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Sundance",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Sweetwater County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Teton County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Thermopolis",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Torrington",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Uinta County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Upton",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Washakie County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Weston County",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Wheatland",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Wilson",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Worland",
    country_code: "US",
    state_code: "WY",
  },
  {
    name: "Wright",
    country_code: "US",
    state_code: "WY",
  },
];

export default CITIES;
