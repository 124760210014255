import styled, { css } from 'styled-components'
import { StyledRadiusInput } from '../../styles'
import * as theme from '../../../../../../styles/theme'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`

export const StyledDesignationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`

export const StyledDesignationInput = styled(StyledRadiusInput)`
  width: 200px;
`
export const StyledFullNameInput = styled(StyledRadiusInput)`
  width: 300px;
`
export const StyledPolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`
export const StyledPolicyText = styled.p`
  margin: 0;
`
export const StyledPolicyButton = styled.button`
  background-color: transparent;
  transition: transform 0.3s;
  ${({ policyVisible }) =>
    policyVisible &&
    css`
      transform: rotate(90deg);
    `}
`
export const StyledPolicyIcon = styled.i`
  color: ${theme.azul};
`
export const StyledPolicyContent = styled.p`
  margin: 0;
  color: ${theme.azul};
`

export const StyledToolsContainer = styled.div`
  margin-top: -1.3em;
  display: flex;
  justify-content: flex-end;
`
export const StyledToolsWrapper = styled.div`
  display: flex;
  gap: 0.2em;
`

export const StyledToolButton = styled.button`
  background-color: transparent;
`

export const StyledToolIcon = styled.i`
  color: ${theme.azul};
`
export const StyledImageInput = styled.input`
  display: none;
`

export const StyledImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`
export const StyledSignatureImagePreview = styled.img``
