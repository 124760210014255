import * as Yup from "yup";
import {PASSWORD_REGEX} from "../../constants/core.js";

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(6, "Old password is too Short!")
    .max(50, "Old password is too Long!")
    .matches(
      PASSWORD_REGEX,
      "Old password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password is required"),
  newPassword: Yup.string()
    .min(6, "New password is too Short!")
    .max(50, "New password is too Long!")
    .matches(
      PASSWORD_REGEX,
      "New password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("New password is required!"),
  confirmNewPassword: Yup.string()
    .when("newPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both password need to be the same!"
      ),
    })
    .required("Confirm password is required!"),
});

export const DeactivateAccountSchema = Yup.object().shape({
  isArchived: Yup.string()
    .notOneOf(["-1"], "Archive option is required!")
    .required("Archive option is required!"),
  backupEmail: Yup.string().email(),
  leavingReason: Yup.string()
    .notOneOf(["-1"], "Leaving reason is required!")
    .required("Leaving reason is required!"),
  description: Yup.string()
    .when("leavingReason", {
      is: (leavingReason) => leavingReason === "Other (please explain)",
      then: Yup.string()
        .required(
          "Description is required when select 'Other (please explain)' as leaving reason!"
        )
        .typeError(
          "Description is required when select 'Other (please explain)' as leaving reason!"
        )
        .min(6, "Description is too Short!")
        .max(750, "Description is too Long!"),
    })
    .min(6, "Description is too Short!")
    .max(750, "Description is too Long!"),
});
