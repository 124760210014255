import React from 'react'
import { StyledRadiusInput } from '../../../styles'
import Checkbox from '../../Shared/Checkbox'
import MultipleItemPanel from '../../Shared/MultipleItemPanel'
import Required from '../../Shared/Required'
import { StyledContainer } from './styles'

const OptionsComponent = ({ options, onChanged }) => {
  const {
    isRequired,
    requiredErrorMessage,
    kpiText,
    baseLineText,
    isQualitative,
    optionItems,
  } = options

  const updateValue = (key, value) => {
    onChanged(key, value)
  }

  return (
    <StyledContainer>
      <Required
        isRequired={isRequired}
        onRequiredChanged={(checked) => updateValue('isRequired', checked)}
        errorMessage={requiredErrorMessage}
        onErrorMessageChanged={(value) =>
          updateValue('requiredErrorMessage', value)
        }
      />

      <StyledRadiusInput
        className="form-control"
        placeholder="Enter KPI"
        value={kpiText}
        onChange={(e) => updateValue('kpiText', e.currentTarget.value)}
      />
      <StyledRadiusInput
        className="form-control"
        placeholder="Enter baseline"
        value={baseLineText}
        onChange={(e) => updateValue('baseLineText', e.currentTarget.value)}
      />
      <Checkbox
        checked={isQualitative}
        onCheckedChanged={(checked) => updateValue('isQualitative', checked)}
        label="Qualitative"
      />
      <MultipleItemPanel
        values={optionItems.length === 0 ? [''] : optionItems}
        onChanged={(values) => updateValue('optionItems', values)}
      />
    </StyledContainer>
  )
}

export default OptionsComponent
