import "./index.scss";
export const ViewSwitch = ({grid, list, active}) => {
  return (
    <div className="view-switch">
      <button
        onClick={grid}
        className={active === "grid" ? "grid-view active" : "grid-view"}
      ></button>
      <button
        onClick={list}
        className={active === "list" ? "list-view active" : "list-view"}
      ></button>
    </div>
  );
};
