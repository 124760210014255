import React, { useRef } from "react";

import styled from "styled-components";
import { deleteFormById } from "../../../../data/Forms/deleteForm";
import DropdownAction from "./dropdown.component";

const FormAction = ({ id, refreshPage }) => {
  const handleClickDeleteForm = e => {
    e.preventDefault();
    const init = async () => {
      const { hasSuccess } = await deleteFormById(id);
      hasSuccess && refreshPage();
    };
    init();
  };

  const Share = () => {
    const ref = useRef();

    const handleClickCopyTextToClipboard = e => {
      e.preventDefault();
      const text = `${window.location.protocol}//${window.location.host}/preview/${id}`;
      navigator.clipboard.writeText(text);
      ref.current.toggle();
    };

    const items = [
      {
        title: "Copy URL",
        slug: "copy-url",
        onClick: handleClickCopyTextToClipboard
      }
    ];
    return <DropdownAction ref={ref} ctaText="Share" items={items} />;
  };

  const Actions = () => {
    const items = [
      {
        title: "Edit",
        slug: `/business/forms/builder/${id}`
      },
      {
        title: "View",
        slug: `/preview/${id}`,
        isExternal: true
      },
      {
        title: "Submissions",
        slug: `/business/forms/${id}/submissions`
      },
      {
        title: "Delete",
        slug: `/business/forms/${id}`,
        onClick: handleClickDeleteForm
      }
    ];
    return <DropdownAction ctaText="Actions" items={items} />;
  };

  return (
    <StyledFormActionContainer>
      <Share />
      <Actions />
    </StyledFormActionContainer>
  );
};

export default FormAction;

export const StyledFormActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
