import React from "react";
import Header from "../../../components/Layout/Header/Header";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {AppliedList} from "../../../components/Services/Client/Service/Applied/List";

const ServiceApplied = () => {
  return (
    <section className="dashboard bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>SERVICES APPLIED</PageTitle>
            </PageHeader>
            <AppliedList />
          </PageContainer>
        </div>
      </div>
    </section>
  );
};

export default ServiceApplied;
