export const CompanyStatus = ({status}) => {
  return (
    <div className="status">
      {status === true ? (
        <span className="online">Online</span>
      ) : (
        <span className="offline">Offline</span>
      )}
    </div>
  );
};
