import React, { useState, useCallback } from "react";
import IconBell from "../../../../assets/icons/bell-blue.png";
import TickBlue from "../../../../assets/icons/icon-group.svg";
import IconBellPassive from "../../../../assets/icons/iconBell.png";
import { useChatContext } from "../../../../providers/Chat";
import { useGlobalContext } from "../../../../providers/global";
import {
  StyledAlertIcon,
  StyledIcon,
  StyledUserAvatar,
  StyledUserAvatarContainer,
  StyledUserInfoContainer,
  StyledUserName
} from "./Header.style";

const defaultAvatar = "https://www.w3schools.com/howto/img_avatar.png";

const useDebouncedFunction = (func, delay) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const debouncedFunction = useCallback(
    (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const id = setTimeout(() => {
        func(...args);
      }, delay);
      setTimeoutId(id);
    },
    [func, delay, timeoutId]
  );

  return debouncedFunction;
};

const isEmptyOrFalsy = value =>
  value === null || value === undefined || value === "";

const UserInfo = ({ showNewMessage, setShowNewMessage }) => {
  const { currentUser } = useGlobalContext();
  const { isReady, reconnect } = useChatContext();
  const profilePictureUrl = currentUser?.image || defaultAvatar;
  const fullName = `${
    !isEmptyOrFalsy(currentUser?.firstName) && currentUser?.firstName
  } ${!isEmptyOrFalsy(currentUser?.lastName) && currentUser?.lastName}`;
  const debouncedReconnect = useDebouncedFunction(reconnect, 3000);
  const handleAlertClick = () => setShowNewMessage(true);

  return (
    <StyledUserInfoContainer>
      <StyledUserAvatarContainer isOnline={isReady}>
        <StyledUserAvatar src={profilePictureUrl} alt={fullName} />
      </StyledUserAvatarContainer>
      <StyledUserName>{fullName}</StyledUserName>
      <StyledAlertIcon>
        <StyledIcon
          src={TickBlue}
          alt="alert icon"
          onClick={handleAlertClick}
        />
        {isReady ? (
          <StyledIcon src={IconBell} alt="alert icon" />
        ) : (
          <StyledIcon
            src={IconBellPassive}
            alt="alert icon"
            onClick={debouncedReconnect}
          />
        )}
      </StyledAlertIcon>
    </StyledUserInfoContainer>
  );
};

export default UserInfo;
