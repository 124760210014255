/* eslint-disable array-callback-return */
import { permissionLevels } from "../../constants/permissionLevels";
import { isBusiness, isClient } from "../../constants/roles";
import { modules } from "../../constants/modules";
import { getCurrentUser } from "../../utils/account";

const notUndefined = anyValue => typeof anyValue !== "undefined";

export const canAccess = (module, isPrivate) => {
  if (!isPrivate) return true;

  const hasValidUser = localStorage.getItem("access_token");
  if (!hasValidUser) return false;

  const { role, features: permissions } = getCurrentUser();

  if (isBusiness(role)) return true;

  if (isClient(role)) return true;

  if (module === modules.GLOBAL) return true;

  const availableModules = Array.isArray(permissions)
    ? permissions
        .map(permission => {
          if (permission.level === permissionLevels.Manager) {
            return permission.name;
          }
        })
        .filter(notUndefined)
    : [];
  return availableModules.includes(module);
};
