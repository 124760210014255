/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState, useEffect } from "react";
import { createCase, updateCase } from "../../../data/CaseManagement/cases";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { defaultTab } from "../../../components/CaseManagement/Cases/Form/tabs";
import CaseManagementCaseCreateSchema from "../../../schemas/CaseManagement/Case/create";
import CaseManagementCaseUpdateSchema from "../../../schemas/CaseManagement/Case/update";
import { useGlobalContext } from "../../global";
import {
  useCaseModel,
  useCaseFormData,
  useAttachments,
  useForms,
  useTasks
} from "./form.custom-hooks";

const BusinessCaseManagementCaseFormContext = createContext(null);

const useBusinessCaseManagementCaseFormContext = () => {
  const context = useContext(BusinessCaseManagementCaseFormContext);
  if (!context)
    throw new Error(
      "BusinessCaseManagementCaseFormContext must be used within a BusinessCaseManagementCaseFormProvider"
    );
  return context;
};

export const BusinessCaseManagementCaseFormProvider = props => {
  const { currentUser } = useGlobalContext();
  const { id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [formMode, setFormMode] = useState("view"); // new, edit, view
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const {
    caseManagementCase,
    summary,
    note,
    setCaseManagementCase,
    setSummary,
    setNote,
    loading,
    load,
    getModel
  } = useCaseModel();
  const caseFormData = useCaseFormData();
  const attachments = useAttachments(id);
  const forms = useForms(id);
  const tasks = useTasks(currentUser, id);

  const checkForm = async () => {
    const validateForm = async form => {
      try {
        const Schema =
          formMode === "new"
            ? CaseManagementCaseCreateSchema
            : CaseManagementCaseUpdateSchema;
        await Schema.validate(form, { abortEarly: false });
      } catch (error) {
        return error;
      }
    };
    const model = getModel();
    const validationResponse = await validateForm(model);
    if (validationResponse && validationResponse.errors) {
      setErrors([...new Set(validationResponse.errors)]);
      return false;
    }
    setErrors([]);
    return true;
  };

  const submit = async () => {
    const result = await checkForm();
    if (!result) return false;
    setSubmitting(true);
    const model = getModel();
    const { hasSuccess } = await (formMode === "new"
      ? createCase(model)
      : updateCase(id, model));
    if (hasSuccess) history.push(`/business/case-management/cases`);
    setSubmitting(false);
  };

  useEffect(() => {
    if (!caseManagementCase || !summary || !note || !formMode) return;
    (async () => {
      if (formMode && formMode !== "view") await checkForm();
    })();
  }, [caseManagementCase, summary, note, formMode]);

  useEffect(() => {
    (async () => {
      const mode = pathname.includes("/edit")
        ? "edit"
        : pathname.includes("/view")
        ? "view"
        : "new";
      setFormMode(mode);
      if (mode !== "view") await caseFormData.load();
      else await caseFormData.loadStaffs();
      if (id) await load(id, mode === "edit");
    })();
  }, [id, pathname]);

  const values = {
    setters: {
      setCaseManagementCase,
      setSummary,
      setNote,
      setSelectedTab
    },
    getters: {
      caseManagementCase,
      summary,
      note,
      errors,
      loading: loading || caseFormData.loading,
      submitting,
      formMode,
      selectedTab
    },
    submit,
    attachments,
    forms,
    caseFormData,
    tasks
  };

  return (
    <BusinessCaseManagementCaseFormContext.Provider {...props} value={values} />
  );
};

export default useBusinessCaseManagementCaseFormContext;
