import React, { useState, useContext } from "react";
import { Formik, Form, Field } from "formik";
import { businessLogin } from "../../data/Login/business";
import { useHistory } from "react-router-dom";
import { LoginSchema } from "../../schemas/Login/business";
import { GlobalContext } from "../../providers/global";
import ErrorField from "../../components/Layout/Shared/ErrorField";
import { goToAppForBusiness } from "../../helpers/history";

const BusinessForm = () => {
  const [error, setError] = useState(null);
  const history = useHistory();

  const initialValues = {
    email: "",
    password: ""
  };
  const globalContext = useContext(GlobalContext);

  const onSubmit = async (values, { setSubmitting }) => {
    const { success, data, error } = await businessLogin(values);
    if (success) {
      globalContext.login(data);
      goToAppForBusiness(history, data.user);
    } else {
      setError(error);
    }
    setSubmitting(false);
  };

  return (
    <div className="col-12">
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={onSubmit}
      >
        {({ errors }) => (
          <Form>
            <div className="form-group">
              <Field
                type="email"
                className="custom-input"
                placeholder="Business Email Address"
                name="email"
              />
              <ErrorField message={errors.email} />
            </div>
            <div className="form-group">
              <Field
                type="password"
                className="custom-input"
                placeholder="Password"
                name="password"
              />
              <ErrorField message={errors.password} />
            </div>
            {error && <ErrorField message={error} />}
            <button type="submit" className="btn btn-blue-gradient">
              SIGN IN
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BusinessForm;
