import React from "react";
import "../styles/pages/login.scss";
import LearnMore from "../components/Login/LearnMore";
import {useLocation} from "react-router-dom";

const ForgotPasswordSuccessMessage = () => {
  const {state} = useLocation();
  return (
    <div>
      <div className="container-fluid sign-in">
        <div className="row ">
          <LearnMore />
          <div className="col-lg-6 right order-1 order-md-2 order-lg-2 d-flex justify-content-center align-items-center">
            <div className="box shadow">
              <h2>
                <small>Check your email.</small>
              </h2>
              <div className="row">
                <div className="col-12">
                  <h2>
                    <small>
                      We have sent a reset password email at
                      <button className="btn-link">{` ${state.email} `}</button>
                      please click the link on your email to reset your
                      password.
                    </small>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccessMessage;
