import React from 'react'
import {
  StyledToolIcon,
  StyledToolButton,
  StyledToolsContainer,
  StyledToolsWrapper,
} from '../styles'
import { SignatureTypes } from '../types'

const Tools = ({ onClearClick, onTypeChanged }) => {
  const toolButtons = [
    {
      icon: 'bi bi-eraser',
      tooltip: 'Clear',
      onClick: onClearClick,
    },
    {
      icon: 'bi bi-pencil',
      tooltip: 'Handwriting',
      onClick: () => onTypeChanged(SignatureTypes.Drawing),
    },
    {
      icon: 'bi bi-fonts',
      tooltip: 'Text Signature',
      onClick: () => onTypeChanged(SignatureTypes.Text),
    },
    {
      icon: 'bi bi-upload',
      tooltip: 'Upload Image',
      onClick: () => onTypeChanged(SignatureTypes.Image),
    },
  ]

  return (
    <StyledToolsContainer>
      <StyledToolsWrapper>
        {toolButtons.map((tool, index) => (
          <StyledToolButton key={index} onClick={tool.onClick}>
            <StyledToolIcon
              className={tool.icon}
              title={tool.tooltip}
              data-toggle="tooltip"
              data-placement="top"
            />
          </StyledToolButton>
        ))}
      </StyledToolsWrapper>
    </StyledToolsContainer>
  )
}

export default Tools
