const radioButtonDefaultOptions = {
  value: '',
  column: '1/1',
  isRequired: false,
  requiredErrorMessage: '',
  kpiText: '',
  baseLineText: '',
  isQualitative: false,
  optionItems: [''],
}

export default radioButtonDefaultOptions
