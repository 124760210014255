import {plans as defaultPlan} from "./Business/paymentPlanDetails";
export const defaultIndustries = [
  {
    name: "Citizenship & Professional Tests",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Daily Life",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Education & Students",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Embassies",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Employment",
    selected: false,
    isIndustry: true,  
  },
  {
    name: "Entrepreneurship",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Financials",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Francophone",
    selected: false,
    isIndustry: true, 
  },
  {
    name: "Health",
    selected: false,
    isIndustry: true,
  }, 
  {
    name: "Housing",
    selected: false,
    isIndustry: true, 
  },
  {
    name: "Language Training",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Legal",
    selected: false,
    isIndustry: true,
    
  },
  {
    name: "LGBTQ20",
    selected: false,
    isIndustry: true,  
  },
  {
    name: "Mentoring",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Refugees",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Women, Youth, Seniors",
    selected: false,
    isIndustry: true,
  },
  {
    name: "Visa",
    selected: false,
    isIndustry: true,
  }
];

export const defaultBusinessInformation = {
  address: "",
  country: "",
  city: "",
  state: "",
  zipCode: "",
  phone: "",
};

export const defaultYourInformation = {
  firstName: "",
  lastName: "",
  designation: "",
};

export const defaultAddress = {
  address: "",
  country: "",
  city: "",
  state: "",
  zipCode: "",
};

export const defaultClientPersonalInformation = {
  firstName: "",
  lastName: "",
  phone: "",
};

export const getSelectedIndustries = (industries) => {
  const selectedIndustries = [];
  industries.filter((industry) =>
    industry.selected ? selectedIndustries.push(industry.name) : ""
  );
  return selectedIndustries;
};

export const getSelectedPlanDetails = (plans) => {
  let selectedPlan = plans.filter((p) => p.active);

  if (selectedPlan.length > 0) {
    selectedPlan = selectedPlan.shift();
    return {
      name: selectedPlan.name,
      isMonthly: selectedPlan.isMonthly,
      monthlyPrice: selectedPlan.monthlyPrice,
      yearlyPrice: selectedPlan.yearlyPrice,
    };
  }

  return {
    name: defaultPlan.FREE,
  };
};

export const getIndustries = () =>
  defaultIndustries.filter((industry) => industry.isIndustry);

export const getServices = () =>
  defaultIndustries.filter((industry) => industry.isService);
