const isProduction = document.location.host.startsWith("app");
const apiDomain = isProduction
  ? "https://3modi91gs3.execute-api.ca-central-1.amazonaws.com/prod"
  : "https://i1li1ejkz7.execute-api.ca-central-1.amazonaws.com/dev";

export const hostName = `${apiDomain}/auth`;
export const serviceApiHostName = `${apiDomain}/service`;
export const appointmentApiHostName = `${apiDomain}/service/appointment`;
export const contactApiHostName = `${apiDomain}/contacts`;
export const formsApiHostName = `${apiDomain}/form-builder`;
export const chatsApiHostName = `${apiDomain}/messages`;
export const chatsWebsocketEndpoint = isProduction
  ? "wss://qszrqf9i20.execute-api.ca-central-1.amazonaws.com/prod"
  : "wss://39yo9z2mih.execute-api.ca-central-1.amazonaws.com/dev";
export const clientApiHostName = `${apiDomain}/auth/client`;
export const caseManagementApiHostName = `${apiDomain}/case-management`;

export const localhostName = "http://localhost:3000";
