import {CustomButton} from "../../../../../Layout/Shared/Button";
import Modal from "../../../../../Layout/Shared/Modal";
import ErrorImage from "../../../../../../assets/images/smile-sad-red.png";
import "./index.scss";
export const ErrorModal = ({onClose, heading}) => {
  return (
    <Modal title="&nbsp;" width="480px" height="380px" close={onClose}>
      <div className="error-modal-content">
        <icon>
          <img src={ErrorImage} alt="" />
        </icon>
        <h3 className="heading mt-4">{heading}</h3>

        <div className="content">
          We couldn’t process your request at the moment. Please try again after
          some time.
        </div>
        <div className="footer">
          <CustomButton
            onClick={onClose}
            title="Okay"
            size="80px"
            color="transparent"
          />
        </div>
      </div>
    </Modal>
  );
};
