/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from "react";
import PhoneImage from "../../../../assets/images/phoneImage.png";
import {
  PaypalIcon,
  ChargeBeeIcon,
  BankIcon,
} from "../../../../assets/icons/icon";
import steps from "../steps";
import BusinessOnboardingContext from "../../../../providers/Onboarding/business";
import {Number, Cvc, Expiration} from "react-credit-card-primitives";
import ErrorField from "../../../../components/Layout/Shared/ErrorField";
import "./payment.scss";

const Payment = ({goTo}) => {
  const [submitted, setSubmitted] = useState(false);
  const [isCardNumberValid, setIsCardValid] = useState(false);
  const [isExpiryValid, setIsExpiryValid] = useState(false);
  const [isCvvValid, setIsCvvValid] = useState(false);
  const {finish} = useContext(BusinessOnboardingContext);

  const [number, setNumber] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [cvv, setCvv] = useState(null);

  const handleNumber = (value, valid) => {
    setNumber(value);
    setIsCardValid(valid);
  };
  const handleExpiry = (month, year, valid) => {
    setExpiry({year, month});
    setIsExpiryValid(valid);
  };
  const handleCvv = (value, valid) => {
    setCvv(value);
    setIsCvvValid(valid);
  };

  const finishOnboarding = async () => {
    setSubmitted(true);
    if (isCardNumberValid && isExpiryValid && isCvvValid) {
      const response = await finish({
        number,
        expiryMonth: expiry.month,
        expiryYear: expiry.year,
        cvv,
      });
      if (response) {
        goTo(steps.SUCCESS);
      }
    }
  };

  return (
    <div className="tab active" id="payment">
      <div className="row">
        <div className="col-xl-6  col-lg-6  pl-0">
          <div className="component first">
            <h2>
              <strong>Select Payment Option</strong>
            </h2>
            <div>
              <div className="form-group payment-group d-flex justify-content-between">
                <input type="radio" name="card" id="chargebee" defaultChecked />
                <label htmlFor="chargebee" className="active">
                  {ChargeBeeIcon}
                  ChargeBee
                </label>
                <input type="radio" name="card" id="paypal" disabled />
                <label htmlFor="paypal" className="active">
                  {PaypalIcon}
                  Paypal
                </label>

                <input type="radio" name="card" id="bank" disabled />
                <label htmlFor="bank">
                  {BankIcon}
                  Bank
                </label>
              </div>

              <div className="form-group">
                <label className="w-100">
                  <strong>Card Number</strong>
                </label>
                <Number
                  onChange={({value, valid}) => handleNumber(value, valid)}
                  render={({getInputProps, valid}) => (
                    <input
                      type="card"
                      placeholder="0000 0000 0000 0000"
                      id="cardnumber"
                      {...getInputProps()}
                      className={valid ? "custom-input" : "custom-input error"}
                    />
                  )}
                />
                {!isCardNumberValid && submitted && (
                  <ErrorField message={"Invalid credit card number"} />
                )}
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group w-100">
                    <label className="w-100">
                      <strong>Expiry</strong>
                    </label>
                    <Expiration
                      onChange={({month, year, valid}) =>
                        handleExpiry(month, year, valid)
                      }
                      render={({getInputProps, valid, error}) => (
                        <div>
                          <input
                            placeholder="00 / 00"
                            {...getInputProps()}
                            className={
                              valid ? "custom-input" : "custom-input error"
                            }
                          />
                        </div>
                      )}
                    />
                    {!isExpiryValid && submitted && (
                      <ErrorField message={"Invalid expiry date"} />
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group w-100">
                    <label className="w-100">
                      <strong>CVV Code</strong>
                    </label>
                    <Cvc
                      onChange={({value, valid}) => handleCvv(value, valid)}
                      render={({getInputProps, valid}) => (
                        <input
                          {...getInputProps()}
                          className={
                            valid ? "custom-input" : "custom-input error"
                          }
                        />
                      )}
                    />
                    {!isCvvValid && submitted && (
                      <ErrorField message={"Invalid expiry date"} />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group d-flex justify-content-between">
                <a>
                  <button
                    type="submit"
                    onClick={finishOnboarding}
                    className="btn btn-blue-gradient btn-green btn-steps"
                  >
                    Finish
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-md-none d-lg-none d-xl-block text-end">
          <div className="component second ">
            <img src={PhoneImage} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
