import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import PhoneImage from "../../../../assets/images/phoneImage.png";
import BusinessOnboardingContext from "../../../../providers/Onboarding/business";
import "./success.scss";
const Success = () => {
  const history = useHistory();
  const {planInformation} = useContext(BusinessOnboardingContext);
  const {planDetails} = planInformation;

  const activePlan = planDetails.find((planDetail) => planDetail.active);
  const isEnterprise = () => activePlan && activePlan.name === "ENTERPRISE";

  return (
    <div>
      <div className="tab active" id="success">
        <div className="row">
          <div className="col-xl-12  text-center">
            <span className="success-title">Awesome!</span>
            {isEnterprise() ? (
              <p className="success-message">
                Your have successfully requested for our Enterprise version.
                <br />
                <span className="text-primary d-flex justify-content-center pt-5 text-left">
                  One of our staff will contact you shortly.
                </span>
              </p>
            ) : (
              <p className="success-message">
                Your paid subscription has been started successfully.
                <br />
                You can proceed to setup your organization.
              </p>
            )}
            <button
              onClick={() => history.push("/business/dashboard")}
              type="submit"
              className="mx-auto btn btn-blue-gradient btn-steps"
            >
              Okay
            </button>
          </div>
          <div className="col-lg-12  text-center mt-5">
            <div className="component second ">
              <img src={PhoneImage} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
