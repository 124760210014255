import React, { useState } from "react";
import Modal from "../../../Layout/Shared/Modal";
import { Formik, Form, Field } from "formik";
import { LabelBlueBold } from "../../../Layout/Shared/LabelBlueBold";
import { CustomButton } from "../../../Layout/Shared/Button";
import ErrorField from "../../../Layout/Shared/ErrorField";
import FormCaseManagementStageSchema from "../../../../schemas/CaseManagement/Stage/form";
import { ListLoader } from "../../../Layout/Shared/DataLoading";
import "./index.scss";
import { createStage, updateStage } from "../../../../data/CaseManagement/stages";

const StageForm = ({ stage, setStage, onClose, onConfirm, title }) => {
  const [submitting, setSubmitting] = useState(false);

  const isEdit = stage && stage._id;

  const handleOnSubmit = async values => {
    setSubmitting(true);
    if (isEdit) {
      const { hasSuccess } = await updateStage(stage._id, {
        title: values.title,
        description: values.description
      });
      if (hasSuccess) {
        setSubmitting(false);
        onClose();
        return onConfirm(values);
      }
    } else {
      const { hasSuccess } = await createStage(values);
      if (hasSuccess) {
        setSubmitting(false);
        onClose();
        return onConfirm(values);
      }
    }
    setSubmitting(false);
    onConfirm(values);
  };

  const handleOnClose = () => {
    setSubmitting(false);
    onClose();
  };

  return (
    <Modal title={title} width="757px" height="500px" close={handleOnClose}>
      {submitting ? (
        <ListLoader height="500px" />
      ) : (
        <div className="share-modal">
          <p className="text-muted"></p>
          <Formik
            enableReinitialize
            initialValues={stage}
            validateOnBlur={false}
            validationSchema={FormCaseManagementStageSchema}
            onSubmit={handleOnSubmit}
          >
            {({ errors }) => (
              <>
                <Form>
                  <LabelBlueBold content="Title" />
                  <Field
                    className="custom-input"
                    placeholder="Enter Case Stage Title"
                    name="title"
                  />
                  <div className="mt-5">
                    <LabelBlueBold content="Description" />
                    <Field
                      as="textarea"
                      className="custom-textarea"
                      placeholder="Enter Case Stage Description"
                      name="description"
                    />
                  </div>
                  <div className="mt-5 mb-4 d-flex justify-content-end">
                    <CustomButton
                      color="blue"
                      title={isEdit ? "Update" : "Create"}
                      size="160px"
                    />
                  </div>
                  <ErrorField message={errors.title} />
                  <ErrorField message={errors.description} />
                </Form>
              </>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};

export default StageForm;
