import React from "react";
import "../styles/pages/login.scss";
import LearnMore from "../components/Login/LearnMore";
import {useLocation} from "react-router-dom";

const VerificationPage = () => {
  const {state} = useLocation();

  return (
    <div>
      <div className="container-fluid sign-in">
        <div className="row d-md-flex">
          <LearnMore />
          <div className="col-lg-6 right order-1 order-md-2 order-lg-2 d-flex justify-content-center align-items-center">
            <div className="box shadow">
              <h2>
                Verification <small>Verify your email address</small>
              </h2>
              <div className="row">
                <div className="col-12">
                  <h2>
                    <small>
                      We have sent verification email at
                      <button className="btn-link">{` ${state.email} `}</button>
                      please click on the link to verify your email address.
                    </small>
                  </h2>
                </div>
                <div className="col-sm-12 pt-5  ">
                  Haven’t received yet? <a href="/verification"> Resend Now </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationPage;
