import React from "react";
import { updateOptions } from "../../../utils";
import ComponentBox from "../Shared/Box";
import { StyledRadiusInput } from "../../styles";
import ValidationText from "../Shared/ValidationText";
import Label from "../../../../Viewer/Components/Label";

const LabelComponent = ({
  component,
  onOptionsChange,
  onOrderButtonClick,
  onDeleteClick,
  onDuplicateClick
}) => (
  <ComponentBox
    title="Add Label"
    column={component.options.column}
    previewMode={component.options.preview}
    onPreviewClick={() => {
      onOptionsChange(
        updateOptions(component.options, "preview", !component.options.preview)
      );
    }}
    onDeleteClick={onDeleteClick}
    onDuplicateClick={onDuplicateClick}
    onColumnChanged={column =>
      onOptionsChange(updateOptions(component.options, "column", column))
    }
    onOrderButtonClick={onOrderButtonClick}
  >
    {component.options.preview ? (
      <Label component={component} />
    ) : (
      <>
        <StyledRadiusInput
          className="form-control"
          placeholder="Add Heading"
          value={component.options.value}
          onChange={e =>
            onOptionsChange(
              updateOptions(component.options, "value", e.currentTarget.value)
            )
          }
        />
        {component.validations?.value && (
          <ValidationText>{component.validations.value}</ValidationText>
        )}
      </>
    )}
  </ComponentBox>
);

export default LabelComponent;
