import React from "react";
import Header from "../../../../components/Layout/Header/Header";
import { GenericPageLeft } from "../../../../components/Layout/Shared/GenericPageLeft";
import { PageContainer } from "../../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../../components/Layout/Shared/PageTitle";
import TaskList from "../../../../components/CaseManagement/Tasks/List";
import { CustomButton } from "../../../../components/Layout/Shared/Button";
import useBusinessCaseManagementTasksListContext, {
  BusinessCaseManagementTasksListProvider
} from "../../../../providers/CaseManagement/Tasks/list";
import TaskForm from "../../../../components/CaseManagement/Tasks/Form";

const CaseManagementTasksListPage = () => {
  return (
    <BusinessCaseManagementTasksListProvider>
      <section className="dashboard bg-paleGray pb-5 pr-3">
        <Header />
        <CaseManagementTasksListInnerComponent />
      </section>
    </BusinessCaseManagementTasksListProvider>
  );
};

export default CaseManagementTasksListPage;

const CaseManagementTasksListInnerComponent = () => {
  const {
    loadTasks,
    showFormModal,
    setShowFormModal,
    taskFormData,
    taskModel
  } = useBusinessCaseManagementTasksListContext();
  const {
    staffs = [],
    load: loadTaskFormData,
    loading: taskFormDataLoading
  } = taskFormData;
  const {
    task,
    setTask,
    loading: taskLoading,
    reset: resetTask,
    submit: submitTask,
    submitting: submittingTask
  } = taskModel;

  const handleOnClose = () => {
    setShowFormModal(false);
    resetTask();
  };

  const handleOnSubmit = async () => {
    const result = await submitTask();
    if (result) {
      handleOnClose();
      loadTasks();
    }
  };

  const handleOnCreate = async () => {
    resetTask();
    setShowFormModal(true);
    await loadTaskFormData();
  };

  return (
    <section className="dashboard bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Tasks Dashboard</PageTitle>
              <div className="d-flex justify-content-end align-items-center">
                <CustomButton
                  color="blue"
                  title="Create Task"
                  size="250px"
                  onClick={handleOnCreate}
                />
              </div>
            </PageHeader>
            <div className="row m-2  bg-white border rounded-xl overflow-hidden">
              <TaskList />
            </div>
            {showFormModal && (
              <TaskForm
                onClose={handleOnClose}
                onConfirm={handleOnSubmit}
                task={task}
                setTask={setTask}
                processing={
                  taskFormDataLoading || taskLoading || submittingTask
                }
                staffs={staffs}
              />
            )}
          </PageContainer>
        </div>
      </div>
    </section>
  );
};
