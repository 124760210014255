import React, {useState, useEffect} from "react";
import Header from "../../components/Layout/Header/Header";
import {PageContainer} from "../../components/Layout/Container/PageContainer";
import {PageTitle} from "../../components/Layout/Shared/PageTitle";
import BusinessCalendar from "../../components/Calendar/index";
import {PageHeader} from "../../components/Layout/Shared/PageHeader";
import {CustomButton} from "../../components/Layout/Shared/Button";
import {GenericPageLeft} from "../../components/Layout/Shared/GenericPageLeft";
import {
  getAppointmentList,
  MapToEvents,
} from "../../data/Calendar/Business/getAppointmentList";
import CalendarLoading from "../../components/Calendar/CalendarLoading/index";
import {CreateAppointment} from "../../components/Calendar/Appointment/Create";

const CalendarPage = () => {
  const [appointments, setAppointments] = useState([]);
  const [scheduleAppointment, setScheduleAppointment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getCalendarEvents = async () => {
    setIsLoading(true);
    const {data, hasSuccess} = await getAppointmentList();
    if (hasSuccess) {
      setAppointments(MapToEvents(data));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCalendarEvents();
  }, []);

  return (
    <section className="dashboard bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Calendar</PageTitle>
              <div className="d-flex justify-content-end align-items-center">
                <CustomButton
                  color="blue"
                  title="Schedule Appointment"
                  size="250px"
                  onClick={() => setScheduleAppointment(true)}
                />
              </div>
            </PageHeader>
            {isLoading ? (
              <CalendarLoading height="800px" />
            ) : (
              <BusinessCalendar
                events={appointments}
                refreshCalendar={getCalendarEvents}
              />
            )}
          </PageContainer>
        </div>
      </div>
      {scheduleAppointment && (
        <CreateAppointment
          date={new Date()}
          close={() => setScheduleAppointment(false)}
          onSuccess={getCalendarEvents}
        />
      )}
    </section>
  );
};

export default CalendarPage;
