import { hostName } from "../endpoint";
import { defaultHeaders } from "../headers";

export const businessLogin = async credentials => {
  try {
    const result = await fetch(`${hostName}/business/login`, {
      method: "POST",
      headers: defaultHeaders,
      body: JSON.stringify(credentials)
    });
    const success = result.status === 200;
    const data = await result.json();
    return {
      success,
      data: success ? data : null,
      error: success ? null : JSON.parse(data).description
    };
  } catch (err) {
    console.error("Error in businessLogin", err);
    return {
      success: false,
      error: err.message
    };
  }
};
