import React from "react";

const SendMessageIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6113 1.33357L14.8268 21.8573C14.5099 22.6934 13.7332 22.8599 13.1018 22.2278C13.1018 22.2278 8.19759 17.3236 7.75072 16.8767C7.30384 16.4298 7.45903 15.5515 8.09603 14.9243L18.9153 4.27238C19.5523 3.64513 19.493 3.57688 18.7828 4.12044L6.60265 13.4512C5.89334 13.9948 4.79565 13.9224 4.16353 13.2903L0.773777 9.90057C0.141652 9.26844 0.308215 8.49251 1.14428 8.17482L21.668 0.390256C22.5041 0.0733807 22.9282 0.497506 22.6113 1.33357ZM5.07434 15.35C4.75828 15.0339 4.4999 15.1412 4.4999 15.5881V19.3142C4.4999 19.7611 4.81353 19.9382 5.19622 19.7083L7.14703 18.5374C7.53053 18.3075 7.58497 17.8606 7.2689 17.5446L5.07434 15.35Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SendMessageIcon;
