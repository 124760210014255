import React from "react";
import {Fragment} from "react";
import {Dot} from "./FormViewStyles";

export function ThreeDots() {
  return (
    <Fragment>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
    </Fragment>
  );
}

export default ThreeDots;
