import React from "react";
import { StyledContainer, StyledHtmlElement } from "./styles";

const Memo = ({
  component: {
    options: { value }
  }
}) => (
  <StyledContainer>
    <StyledHtmlElement dangerouslySetInnerHTML={{ __html: value }} />
  </StyledContainer>
);

export default Memo;
