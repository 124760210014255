import React from "react";

const AttachmentIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.78708 12.032L16.087 5.73208C17.3983 4.42084 17.3983 2.29469 16.087 0.983437C14.7758 -0.327788 12.6507 -0.327788 11.3385 0.983293L5.00667 7.31516L4.99536 7.32685L1.40391 10.9183C-0.467969 12.7902 -0.467969 15.8239 1.40391 17.6957C3.27482 19.5666 6.30852 19.5666 8.17942 17.6957L17.1463 8.72889C17.3334 8.54177 17.3334 8.23841 17.1463 8.05125C16.9591 7.86414 16.6557 7.86414 16.4686 8.05125L7.50179 17.0181C6.00511 18.5148 3.57823 18.5148 2.08155 17.0181C0.583927 15.5205 0.583927 13.0936 2.08155 11.5959L11.5681 2.10939L11.5794 2.09769L12.016 1.66108C12.9538 0.724059 14.4724 0.724059 15.4094 1.66108C16.3464 2.59808 16.3464 4.11744 15.4094 5.05445L10.6768 9.78703C10.6733 9.79024 10.67 9.79355 10.6666 9.7969L9.10723 11.3566L9.07767 11.3862L9.07628 11.3875L5.46193 15.0025C5.08583 15.378 4.47546 15.378 4.09903 15.0022C3.7238 14.6264 3.7238 14.016 4.09961 13.6396L8.85452 8.88562C9.04168 8.69851 9.04173 8.3951 8.85462 8.20799C8.6675 8.02082 8.36409 8.02078 8.17698 8.20789L3.42173 12.9622C2.67212 13.7131 2.67212 14.9293 3.42139 15.6799C4.17254 16.4297 5.38876 16.4297 6.13932 15.6804L9.75531 12.0638L9.78708 12.032Z"
        fill="#8E8E8E"
      />
    </svg>
  );
};

export default AttachmentIcon;
