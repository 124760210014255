import "./index.scss";
export const Channels = ({channels}) => {
  return (
    <div className="communication-channels">
      <h4>Communication Channels</h4>
      <div className="channel">
        <span>Text & Message</span>
        <span>
          <div className="btn-text">
            <button className="btn btn-link p-0">
              {channels.textAndMessages}
            </button>
          </div>
        </span>
      </div>
      <div className="channel">
        <span>Calls</span>
        <span>
          <button className="btn btn-link p-0">{channels.calls}</button>
        </span>
      </div>
      <div className="channel">
        <span>Appointment</span>
        <span>
          <div>{channels.appointments}</div>
        </span>
      </div>
    </div>
  );
};
