import styled from "styled-components";
import {Question, TextArea} from "../Components/Base/BaseStyles";

export const PreviewHeading = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700 !important;
  }

  text-align: ${(props) => props.textAlignment};
`;

export const PreviewQuestion = styled(Question)`
  margin-bottom: 20px;
`;
export const PreviewDefaultMargin = styled.div`
  margin-bottom: 10px;
`;
export const PreviewInputOption = styled.input`
  width: 24px;
  height: 24px;
`;
export const PreviewLabel = styled.label`
  font-size: 16px;
  margin-top: 2.5px;
  margin-left: 15px;
`;
export const TextAreaPreview = styled(TextArea)`
  padding: 1rem 1.75rem 3.5rem 1.75rem;
  margin-top: 0.8rem;

  &::placeholder {
    font-size: 16px;
  }
`;
export const MemoPreview = styled.div`
  font-size: 16px;
`;
export const PaginationNav = styled.nav`
  margin: 10px 10px;
`;
export const PageLink = styled.a`
  padding: 0.7rem 1.2rem;
  font-size: 13px;
`;
