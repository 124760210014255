import React, {Fragment} from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {InputGroup, RadiusInput} from "../Base/BaseStyles";

export function RadioButtonEditTab({dispatch, attributes, component_key}) {
  const radio_button_question = getAttribute("question", attributes);
  return (
    <Fragment>
      <InputGroup className="input-group">
        <RadiusInput
          type="text"
          className="form-control"
          aria-label=""
          placeholder="Enter your question"
          value={radio_button_question}
          onChange={(event) =>
            dispatch({
              type: "radio_button_question_change",
              question: event.target.value,
              key: component_key,
            })
          }
        />
      </InputGroup>
    </Fragment>
  );
}

export default RadioButtonEditTab;
