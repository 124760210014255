import React from "react";
import ClientsList from "./List";
import GroupList from "./Group/List";

export const tabs = [
  {
    name: "Clients",
    component: <ClientsList />,
    isDefault: true
  },
  {
    name: "Groups",
    component: <GroupList />
  }
];
export const defaultContactTab = tabs.find(tab => tab.isDefault);
