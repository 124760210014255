import { hostName } from "../endpoint";
import { defaultHeaders } from "../headers";

export const checkClient = async (email, businessId) => {
  const result = await fetch(`${hostName}/client/check`, {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify({
      email,
      businessId
    })
  });
  return result.json();
};
