/* eslint-disable react-hooks/exhaustive-deps */
import { PageContainer } from "../../components/Layout/Container/PageContainer";
import Header from "../../components/Layout/Header/Header";
import { GenericPageLeft } from "../../components/Layout/Shared/GenericPageLeft";
import { PageHeader } from "../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../components/Layout/Shared/PageTitle";
import React, { useEffect } from "react";
import Messenger from "../../components/Messenger/Messenger.component";
import { useChatContext } from "../../providers/Chat";

const MessagesPage = () => {
  const { setSelectedChat } = useChatContext();
  useEffect(() => {
    return () => {
      setSelectedChat(undefined);
    };
  }, []);
  return (
    <section className="dashboard  bg-paleGray pb-5 pe-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Messenger</PageTitle>
            </PageHeader>
            <Messenger />
          </PageContainer>
        </div>
      </div>
    </section>
  );
};

export default MessagesPage;
