export const SelectBoxBlue = ({width, height, children, ...rest}) => (
  <div className="d-flex align-items-center justify-content-center mt-3 mb-5">
    <select
      className="blue-select"
      style={{width: width, height: height}}
      {...rest}
    >
      {children}
    </select>
  </div>
);
