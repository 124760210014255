import styled /*, { css } */ from "styled-components";
import Avatar from "../../../../../../assets/images/default-profile-picture.png";

/*
export const StyledComponentExample = styled.div`
  ${({ theme, disabled, active }) => css`
    cursor: pointer;
    pointer-events: ${disabled ? "none" : "auto"};
    opacity: ${disabled ? 0.5 : 1};
    ${active &&
    css`
      border-left: 4px solid #1b84e7;
      background-color: #f5f7fa;
    `}
  `}
`;
*/

export const CenteredBox = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 792px;
`;

export const Content = styled.div`
  width: 199px;
  height: 523px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const ClientInfo = styled.div`
  width: 82px;
  height: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const PhotoDisplay = styled.div`
  width: 179px;
  height: 179px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd
    url(${props => (props.url && props.url !== "" ? props.url : Avatar)})
    no-repeat center;
  background-size: cover;
  border: 1px solid #007bff;
`;

export const InfoBlock = styled.div`
  width: ${props => props.width}px;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AddressBlock = styled(InfoBlock)`
  height: 90px;
`;

export const Text = styled.div`
  text-align: center;
  width: 100%;
  font-weight: ${props => (props.bold ? "bold" : "normal")};
`;
