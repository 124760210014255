import React, {useEffect, useState} from "react";
import Table from "../../../../Layout/Shared/Table";
import {Options} from "../../../../Layout/Shared/DropdownOptionsMenu";
import {ThreeDotIcon} from "../../../../../assets/icons/icon";
import {DeleteModal} from "../../../../Layout/Shared/Popups/Delete";
import {get} from "../../../../../data/Settings/Business/Team/getGroups";
import {deleteGroup as deleteGroupById} from "../../../../../data/Settings/Business/Team/deleteGroup";
import DataLoading from "../../../../Layout/Shared/DataLoading";

export const Groups = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [deleteGroup, setDeleteGroup] = useState(null);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await get();
      if (hasSuccess) {
        const dataAsArray = Object.values(data);
        setGroups(dataAsArray);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const ActionButtons = ({row}) => (
    <Options
      arrow={false}
      buttonTitle={ThreeDotIcon}
      menuItems={
        <div>
          <button className="text-danger" onClick={() => setDeleteGroup(row)}>
            Delete
          </button>
        </div>
      }
    />
  );
  const handleDeleteOnConfirm = async () => {
    setIsLoading(true);
    const {hasSuccess} = await deleteGroupById(deleteGroup._id);
    if (hasSuccess) {
      setGroups(groups.filter((group) => group._id !== deleteGroup._id));
      setDeleteGroup(null);
      setIsLoading(false);
    }
  };

  const fields = [
    "name",
    {render: ({row}) => <span>{row.users?.length ?? 0}</span>},
    "description",
    {render: ActionButtons},
  ];
  const headers = [
    {
      displayName: "Group / Team Name",
      sortingFieldName: "name",
    },
    {
      displayName: "NO. of People",
      sortingFieldName: "noOfPeople",
    },
    {
      displayName: "Description",
      sortingFieldName: "description",
    },
    {
      displayName: "Action",
      sortingFieldName: "action",
    },
  ];

  const options = {
    data: groups,
    headers,
    fields,
    itemsPerPage: 25,
    pagination: true,
    search: false,
    sort: true,
  };

  return (
    <section className="p-5">
      {isLoading ? <DataLoading height="700px" /> : <Table options={options} />}
      {deleteGroup && (
        <DeleteModal
          height="260px"
          title="Delete Group/Team"
          text="You are about to delate Group/Team. Deleting this user revokes all access to your account and settings and deletes All user’s information."
          onClose={() => setDeleteGroup(null)}
          buttonText="Delete"
          cancel={false}
          onConfirm={handleDeleteOnConfirm}
        />
      )}
    </section>
  );
};
