import React from "react";
import Table from "../../../Layout/Shared/Table";
import { FormattedDate } from "../../../Layout/Shared/FormattedDate";
import { isActive } from "../../../../constants/services";
import { ActionButtons } from "./actions";
import "./index.scss";
import { SERVICE_TYPES } from "../../../../constants/services";

const ServiceList = ({ services, refreshPage }) => {
  const Status = ({ row }) =>
    isActive(row.status) ? (
      <p className="is-active">Active</p>
    ) : (
      <p className="is-inactive">Inactive</p>
    );

  const getServiceType = type => type ? SERVICE_TYPES.find(t => t.type === type)?.name : "";  
  const fields = [
    "name",
    { render: ({ row }) => <FormattedDate date={row.created_at} /> },
    { render: ({ row }) => <FormattedDate date={row.updated_at} /> },
    "owner",
    { render: ({ row }) => getServiceType(row.type) },
    { render: Status },
    {
      render: ({ row }) => (
        <ActionButtons
          status={row.status}
          id={row._id}
          refreshPage={refreshPage}
        />
      )
    }
  ];
  const headers = [
    {
      displayName: "Name",
      sortingFieldName: "name"
    },
    {
      displayName: "Date Created",
      sortingFieldName: "created_at",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Last Modified",
      sortingFieldName: "updated_at",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Owner",
      sortingFieldName: "owner"
    },
    {
      displayName: "Type",
      sortingFieldName: "type"
    },
    {
      displayName: "Status",
      sortingFieldName: "status"
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];
  const options = {
    data: services,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    headerAction: () => <p></p>
  };

  return (
    <div className="service-list">
      <Table options={options} />
    </div>
  );
};
export default ServiceList;
