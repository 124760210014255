import React, { useState } from 'react'
import { FormComponentTypes } from '../../constants'
import { calculateColumnWidth } from '../../Shared/util'
import Pagination from '../Pagination'
import { resolveComponent } from '../utils'
import {
  StyledComponentsWrapper,
  StyledComponentWrapper,
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledHeaderDot,
  StyledHeaderDotContainer,
  StyledSubmittedText,
  StyledWrapper,
} from './styles'

const Container = ({
  components = [],
  isReadOnly,
  isLoading,
  isSubmitted,
  onChange,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const groups = []

  let group = []
  components.forEach((component) => {
    if (component.type === FormComponentTypes.PageBreak) {
      groups.push(group)
      group = []
    } else {
      group.push(component)
    }
  })
  if (group.length > 0) {
    groups.push(group)
  }

  const pageCount = groups.length
  const paginationVisible = pageCount > 1 && !isSubmitted
  const pageComponents = groups[currentPage - 1]

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeader>
          <StyledHeaderDotContainer>
            <StyledHeaderDot />
            <StyledHeaderDot />
            <StyledHeaderDot />
          </StyledHeaderDotContainer>
        </StyledHeader>
        <StyledContent className="container-lg">
          <StyledComponentsWrapper className="row">
            {isLoading && <p>Loading</p>}
            {isSubmitted && (
              <StyledSubmittedText>
                Thank you for your submission!
              </StyledSubmittedText>
            )}
            {!isLoading && !isSubmitted && (
              <>
                {components.map((component, index) => {
                  const Component = resolveComponent(component)
                  const isVisible = pageComponents.some((c) => c === component)
                  return (
                    <StyledComponentWrapper
                      className={`col-${component.options?.column ? calculateColumnWidth(component.options?.column) : 12}`}
                      key={index}
                      isVisible={isVisible}
                    >
                      <Component
                        isVisible={isVisible}
                        isReadOnly={isReadOnly}
                        component={component}
                        onChange={(options) => onChange(index, options)}
                      />
                    </StyledComponentWrapper>
                  )
                })}
              </>
            )}
          </StyledComponentsWrapper>
        </StyledContent>
        {paginationVisible && (
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            onChange={setCurrentPage}
          />
        )}
      </StyledWrapper>
    </StyledContainer>
  )
}

export default Container
