const steps = {
  INDUSTRYSELECTION: "INDUSTRYSELECTION",
  BUSINESSIFORMATION: "BUSINESSIFORMATION",
  YOURINFORMATION: "YOURINFORMATION",
  PLANS: "PLANS",
  PAYMENT: "PAYMENT",
  SUCCESS: "SUCCESS",
};

export default steps;
