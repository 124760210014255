import { clientApiHostName } from "../../endpoint";
import { defaultHeaders } from "../../headers";

export const shareClient = async (id, body) => {
  try {
    const result = await fetch(`${clientApiHostName}/share/${id}`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify(body)
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const deactivateClient = async id => {
  try {
    const result = await fetch(`${clientApiHostName}/deactivate/${id}`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify({})
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const activateClient = async id => {
  try {
    const result = await fetch(`${clientApiHostName}/activate/${id}`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify({})
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};

export const migrateClientsFromContacts = async body => {
  try {
    const result = await fetch(`${clientApiHostName}/migrate-contacts`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify(body)
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
