import React  from 'react'
import { PageContainer } from '../../../components/Layout/Container/PageContainer'
import Header from '../../../components/Layout/Header/Header'
import { GenericPageLeft } from '../../../components/Layout/Shared/GenericPageLeft'
import { PageHeader } from '../../../components/Layout/Shared/PageHeader'
import { PageTitle } from '../../../components/Layout/Shared/PageTitle'
import { TabContainer } from '../../../components/Submissions/TabContainer/tabContainer-component'
 

const FormSubmissions = () => {
  return (
    <section className="dashboard  bg-paleGray pb-5 pe-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>ALL SUBMISSIONS</PageTitle>
            </PageHeader>
              <TabContainer />
          </PageContainer>
        </div>
      </div>
    </section>
  )
}

export default FormSubmissions
