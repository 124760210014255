import React from "react";
import {useBusinessAccountSettingsContext} from "../../../../../../providers/Settings/Account/business";

export const DurationToggle = () => {
  const {
    values: {isMonthly},
    setters: {setIsMonthly},
  } = useBusinessAccountSettingsContext();
  return (
    <div className="period-selector inline-plan-selector">
      <label>
        <strong>Select Plan</strong>
        <span>Monthly</span>
        <input
          type="checkbox"
          name="selectperiod"
          defaultChecked={isMonthly ? "" : "checked"}
          onChange={({target}) => setIsMonthly(!target.checked)}
        />
        <i></i>
        <span>Yearly</span>
      </label>
    </div>
  );
};
