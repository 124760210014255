import React from 'react'
import { StyledInnerWrapper } from "../styles"
import { StyledAddElementDescription, StyledAddElementTitle, StyledRectangle, StyledTitle } from "./styles";
const ComponentsEmpty = () => {
    return (
        <StyledInnerWrapper>
            <StyledTitle> Start building your form</StyledTitle>
            <StyledRectangle>
                <StyledAddElementTitle>Add Elements</StyledAddElementTitle>
                <StyledAddElementDescription>Click on elements from the left panel to create your form</StyledAddElementDescription>
            </StyledRectangle>
        </StyledInnerWrapper>
    )
}

export default ComponentsEmpty