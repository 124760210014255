import "./index.scss";
import DefaultBusinessLogo from "../../../../assets/images/default-business-image.jpg";
export const CompanyNameWithLogo = ({logo, name}) => {
  return (
    <div className="company-name-logo">
      <div className="company-logo">
        <img src={logo ? logo : DefaultBusinessLogo} alt={name} />
      </div>
      <span>{name}</span>
    </div>
  );
};
