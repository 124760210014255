import React, {Fragment} from "react";

import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {
  CheckboxHoverOptionInput,
  FormCheck,
  InputGroup,
  Question,
  RequireText,
  TextArea,
} from "../Base/BaseStyles";

export function CheckboxHover({component_key, attributes, dispatch}) {
  const question = getAttribute("question", attributes);
  const options = getAttribute("options", attributes);
  const checked = getAttribute("checked", attributes);
  const comment = getAttribute("comment", attributes);
  const require = getAttribute("require", attributes);
  const require_text = getAttribute("require_text", attributes);
  const option_groups = [];
  const row_count = Math.ceil(options.length / 2);
  for (let i = 0; i < row_count; i++) {
    option_groups[i] = options.slice(i * 2, i * 2 + 2);
  }

  return (
    <Fragment>
      <div className={"row"}>
        <div className={"col-12"}>
          <Question>{question}</Question>
        </div>
      </div>
      {option_groups.map((option_group, i) => (
        <div className={"row"} key={i}>
          {option_group.map((option, j) => (
            <div className={"col-6"} key={j}>
              <FormCheck className="form-check">
                <CheckboxHoverOptionInput
                  className="form-check-input"
                  type="checkbox"
                  checked={checked.includes(option)}
                  onChange={(e) =>
                    dispatch({
                      type: "checkbox_checked_change",
                      key: component_key,
                      checked: e.target.checked,
                      option: option,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {option}
                </label>
              </FormCheck>
            </div>
          ))}
        </div>
      ))}
      <div className={"row"}>
        <div className={"col-12"}>
          <InputGroup className="input-group">
            <TextArea
              className="form-control"
              aria-label=""
              placeholder="Enter your comment"
              value={comment}
              onChange={(e) =>
                dispatch({
                  type: "checkbox_change_comment",
                  key: component_key,
                  comment: e.target.value,
                })
              }
            />
          </InputGroup>
          {require === "true" && !comment ? (
            <RequireText>{require_text}</RequireText>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default CheckboxHover;
