import React, {useState} from "react";
import {Formik, Form, Field} from "formik";
import ErrorField from "../../components/Layout/Shared/ErrorField";
import {ResetPasswordSchema} from "../../schemas/Shared/resetPassword";
import {resetBusinessPassword} from "../../data/ForgotPassword/resetBusinessPassword";
import {resetStaffPassword} from "../../data/ForgotPassword/resetStaffPassword";
import {resetClientPassword} from "../../data/ForgotPassword/resetClientPassword";
import {useLocation, useHistory} from "react-router-dom";

export const ResetPassword = () => {
  const location = useLocation();
  const searchedStr = 'reset-password/'
  const token = location.pathname.slice(location.pathname.indexOf(searchedStr) + searchedStr.length)
  const history = useHistory();
  const isBusiness = location.pathname.startsWith("/business");
  const isStaff = location.pathname.startsWith('/staff')

  const [error, setError] = useState(null);
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const onSubmit = async (values) => {
    setError(null);
    if (values.password !== values.confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    const isSuccessful = isStaff
      ? await resetStaffPassword(values.password, token)
      : isBusiness
      ? await resetBusinessPassword(values.password, token)
      : await resetClientPassword(values.password, token)

    isSuccessful
      ? history.push("/reset-password-success")
      : setError("Something went wrong, please try again");
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ResetPasswordSchema}
      onSubmit={onSubmit}
    >
      {({errors}) => (
        <Form>
          <div className="form-group">
            <Field
              type="password"
              className="custom-input"
              placeholder="Enter New Password"
              name="password"
            />
            <ErrorField message={errors.password} />
          </div>
          <div className="form-group">
            <Field
              type="password"
              className="custom-input"
              placeholder="Confirm New Password"
              name="confirmPassword"
            />
            <ErrorField message={errors.confirmPassword} />
            {error && <ErrorField message={error} />}
          </div>
          <button type="submit" className="btn btn-blue-gradient">
            Reset Password
          </button>
        </Form>
      )}
    </Formik>
  );
};