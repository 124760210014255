/* eslint-disable array-callback-return */
import {
  PreviewFormCard,
  PreviewFormCardBody,
  PreviewFormCardHeader,
  StyledFormView,
} from "./FormViewStyles";
import React, {useState} from "react";
import ThreeDots from "./ThreeDots";
import Pagination from "./Pagination";
import {ComponentType} from "./FormViewReducerTypes";
import Components from "./Components";

export function FormViewWrapper({
  formBuilderState,
  dispatch,
  currentView,
  button,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const pageBreaks = [];
  formBuilderState.components.map((component, index) => {
    if (component.type === ComponentType.PAGE_BREAK) {
      pageBreaks.push(index);
    }
  });
  const indexOfLastComponent =
    pageBreaks[currentPage - 1] !== undefined
      ? pageBreaks[currentPage - 1]
      : formBuilderState.components.length;
  const indexOfFirstComponent =
    currentPage === 1 ? 0 : pageBreaks[currentPage - 2] + 1;
  const currentComponents = formBuilderState.components.slice(
    indexOfFirstComponent,
    indexOfLastComponent
  );
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <StyledFormView viewType={currentView}>
      <div className="row">
        <div className="col-md-12">
          <PreviewFormCard className="card">
            <PreviewFormCardHeader className="card-header">
              <ThreeDots />
            </PreviewFormCardHeader>
            <PreviewFormCardBody className="card-body container">
              <Components
                components={currentComponents}
                dispatch={dispatch}
                currentView={currentView}
              />
            </PreviewFormCardBody>
            {pageBreaks.length ? (
              <Pagination
                totalPages={pageBreaks.length + 1}
                paginate={paginate}
              />
            ) : (
              ""
            )}
          </PreviewFormCard>
          {button}
        </div>
      </div>
    </StyledFormView>
  );
}

export default FormViewWrapper;
