import { createContext, useContext, useState } from "react";
import { BusinessClientUpdateSchema } from "../../schemas/Client/Business/update";
import { get } from "../../data/Client/Business/get";
import { update } from "../../data/Client/Business/update";
import { useHistory } from "react-router";

const BusinessClientUpdateContext = createContext(null);

const useBusinessClientUpdateContext = () => {
  const context = useContext(BusinessClientUpdateContext);
  if (!context)
    throw new Error(
      `Can't use "useBusinessClientUpdateContext" without an BusinessClientUpdateProvider!`
    );
  return context;
};

const BusinessClientUpdateProvider = props => {
  const [client, setClient] = useState({});
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [profilePictureUrl, setProfilePictureUrl] = useState(null);

  const history = useHistory();
  const goToClientList = () => history.push(`/business/client/list`);

  const load = async id => {
    setIsLoading(true);
    const { hasSuccess, data } = await get(id);
    if (hasSuccess) {
      setClient(data);
      data.profilePictureUrl &&
        data.profilePictureUrl !== "" &&
        setProfilePictureUrl(data.profilePictureUrl);
      setIsLoading(false);
      return true;
    }
    setIsLoading(false);
    return false;
  };

  const validateForm = async form => {
    try {
      return await BusinessClientUpdateSchema.validate(form);
    } catch (error) {
      return error;
    }
  };

  const submit = async () => {
    const updatedClient = {
      id: client.id,
      details: {
        ...client.details,
        industries: undefined
      }
    };
    if (profilePictureUrl && profilePictureUrl !== "")
      updatedClient.details.yourInformation.profilePictureUrl =
        profilePictureUrl;

    const validationResponse = await validateForm(updatedClient);
    if (validationResponse && validationResponse.errors) {
      setErrors(validationResponse.errors);
      return false;
    }
    setIsSubmitting(true);

    const { hasSuccess } = await update(updatedClient, client.id);
    if (hasSuccess) {
      setIsSubmitting(false);
      goToClientList();
      return true;
    }

    return false;
  };

  const setters = {
    setClient,
    setErrors,
    setIsLoading,
    setIsSubmitting,
    setProfilePictureUrl
  };
  const getters = {
    client,
    errors,
    isLoading,
    isSubmitting,
    profilePictureUrl
  };
  const values = { setters, getters, load, submit };
  return <BusinessClientUpdateContext.Provider {...props} value={values} />;
};

export { BusinessClientUpdateProvider, useBusinessClientUpdateContext };
