import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import LearnMore from "../../../../components/Login/LearnMore";
import { accept } from "../../../../data/Contact/Business/acceptInvitationContact";
import { ListLoader } from "../../../../components/Layout/Shared/DataLoading";
import "../../../../styles/pages/login.scss";
import { CustomButton } from "../../../../components/Layout/Shared/Button";

const AcceptInvitation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [addContact, setAddContact] = useState(null);
  const [isInvalidOrUsedToken, setIsInvalidOrUsedToken] = useState(false);
  let { token } = useParams();
  const history = useHistory();
  const goToHome = () => history.push("/");
  useEffect(() => {
    const init = async () => {
      const { data, hasSuccess } = await accept({ token: token });
      hasSuccess ? setAddContact(data) : setIsInvalidOrUsedToken(true);
      setIsLoading(false);
    };
    token ? init() : setIsLoading(false);
  }, [token]);

  return (
    <>
      <div>
        <div className="container-fluid sign-in">
          <div className="row d-md-flex">
            <LearnMore />
            <div className="col-xl-6 col-lg-6 col-md-6 right order-1 order-md-2 order-lg-2 d-flex justify-content-center align-items-center">
              {isLoading ? (
                <ListLoader height="calc(95vh / 1.5)" />
              ) : isInvalidOrUsedToken ? (
                <InvalidOrUsedToken />
              ) : (
                <div className="box shadow">
                  <h2>
                    Verification
                    <small>Accepted Invitation Successfully</small>
                  </h2>
                  <div className="row">
                    <div className="col-12">
                      <h2 className="mb-3">
                        <small>
                          Welcome to iRadar Immigration.
                          <span className="text-primary-blue pl-2">
                            {addContact.businessName}
                          </span>
                          <br /> added you as contact.
                        </small>
                      </h2>
                    </div>
                    <div className="col-12 pt-5 mt-5">
                      <CustomButton
                        onClick={goToHome}
                        title="Back to login page"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptInvitation;

const InvalidOrUsedToken = () => {
  const history = useHistory();
  const goToHome = () => history.push("/");
  return (
    <>
      <div className="d-flex align-items-center flex-column">
        <center>
          <div></div>
          <span className="h1 mt-5 text-primary-blue"></span>
          <p className="mt-5">
            Invalid token or token has been already accepted!
          </p>
          <button className="btn-blue btn-small mt-2" onClick={goToHome}>
            Home
          </button>
        </center>
      </div>
    </>
  );
};
