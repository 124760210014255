import React, {useRef, useEffect} from "react";

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) callback(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

function DropdownBoundary({callback, children = [], isDesktop = true}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, callback);
  if (!isDesktop) {
    return <>{children}</>;
  }
  return (
    <div style={{display: "flex"}} ref={wrapperRef}>
      {children}
    </div>
  );
}
export default DropdownBoundary;
