import React, {useState, useEffect} from "react";
import LogoBlueSmall from "../../assets/logo/logoSmall";
import "../../styles/pages/Onboarding.scss";
import Plans from "../../components/Onboarding/Business/Plans";
import BusinessIformation from "../../components/Onboarding/Business/BusinessInformation";
import YourInformation from "../../components/Onboarding/Business/YourInformation";
import IndustrySelection from "../../components/Onboarding/Business/IndustrySelection";
import Payment from "../../components/Onboarding/Business/Payment";
import Success from "../../components/Onboarding/Business/Success";
import steps from "../../components/Onboarding/Business/steps";
import BusinessOnboardingContext from "../../providers/Onboarding/business";
import {planData} from "../../components/Onboarding/Business/paymentPlanDetails";
import {
  getIndustries,
  defaultBusinessInformation,
  defaultYourInformation,
  getSelectedIndustries,
  getSelectedPlanDetails,
} from "../../components/Onboarding/defaultValues.js";
import {updateBusiness} from "../../data/Onboarding/business";

const OnboardingPage = () => {
  const [activeStep, setActiveStep] = useState(steps.INDUSTRYSELECTION);
  const [industries, setIndustries] = useState(getIndustries());
  const [businessInformation, setBusinessInformation] = useState(
    defaultBusinessInformation
  );
  const [yourInformation, setYourInformation] = useState(
    defaultYourInformation
  );
  const [planDetails, setPlanDetails] = useState(planData);
  const [paymentDetails, setPaymentDetails] = useState();

  const objectToSave = {
    industries: getSelectedIndustries(industries),
    businessInformation,
    yourInformation,
    planInformation: getSelectedPlanDetails(planDetails),
    paymentInformation: paymentDetails,
  };

  const finish = (cardDetails) =>
    updateBusiness({...objectToSave, cardDetails});

  const [infoNotificationMessage, setInfoNotificationMessage] = useState(null);
  useEffect(() => {
    const timeId = setTimeout(() => {
      setInfoNotificationMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [infoNotificationMessage]);

  const finishOnPlanInformation = (planDetails) =>
    updateBusiness({
      ...objectToSave,
      cardDetails: {},
      planInformation: getSelectedPlanDetails(planDetails),
    });

  const onbooardingContext = {
    industriesInformation: {
      industries,
      setIndustries,
    },
    businessInformations: {
      businessInformation,
      setBusinessInformation,
    },
    yourInformations: {
      yourInformation,
      setYourInformation,
    },
    planInformation: {
      planDetails,
      setPlanDetails,
    },
    paymentInformation: {
      paymentDetails,
      setPaymentDetails,
    },
    finish,
    finishOnPlanInformation,
    infoNotificationMessage,
    setInfoNotificationMessage,
  };

  return (
    <BusinessOnboardingContext.Provider value={onbooardingContext}>
      <section className="onboarding">
        <div className="vertical-menu">
          <LogoBlueSmall />
          <div className="tabs">
            {
              <ul>
                <li>
                  <span
                    className={
                      activeStep === steps.INDUSTRYSELECTION ? "active" : ""
                    }
                    onClick={() => setActiveStep(steps.INDUSTRYSELECTION)}
                  >
                    Services Category Selection
                  </span>
                </li>
                <li>
                  <span
                    className={
                      activeStep === steps.BUSINESSIFORMATION ? "active" : ""
                    }
                    onClick={() => setActiveStep(steps.BUSINESSIFORMATION)}
                  >
                    Business Information
                  </span>
                </li>
                <li>
                  <span
                    className={
                      activeStep === steps.YOURINFORMATION ? "active" : ""
                    }
                    onClick={() => setActiveStep(steps.YOURINFORMATION)}
                  >
                    Your Information
                  </span>
                </li>
                <li>
                  <span
                    className={activeStep === steps.PLANS ? "active" : ""}
                    onClick={() => setActiveStep(steps.PLANS)}
                  >
                    Plans
                  </span>
                </li>
                <li>
                  <span
                    className={activeStep === steps.PAYMENT ? "active" : ""}
                    onClick={() => setActiveStep(steps.PAYMENT)}
                  >
                    Payment Detail
                  </span>
                </li>
              </ul>
            }
          </div>
        </div>
        <div className="onboarding-section">
          {activeStep === steps.SUCCESS ? null : (
            <header
              className={activeStep === steps.PLANS ? "position-absolute" : ""}
            >
              Welcome home...
              <small>Let’s get started, by setting up your account.</small>
            </header>
          )}
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="tab-content">
                  {activeStep === steps.INDUSTRYSELECTION && (
                    <IndustrySelection goTo={setActiveStep} />
                  )}
                  {activeStep === steps.BUSINESSIFORMATION && (
                    <BusinessIformation goTo={setActiveStep} />
                  )}
                  {activeStep === steps.YOURINFORMATION && (
                    <YourInformation goTo={(step) => setActiveStep(step)} />
                  )}
                  {activeStep === steps.PLANS && <Plans goTo={setActiveStep} />}
                  {activeStep === steps.PAYMENT && (
                    <Payment goTo={setActiveStep} />
                  )}
                  {activeStep === steps.SUCCESS && (
                    <Success goTo={setActiveStep} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notification-side">
          <>
            {infoNotificationMessage && (
              <div className="notifications">
                <div className="info">
                  <span>Info</span>
                  <p>{infoNotificationMessage}</p>
                  <button onClick={() => setInfoNotificationMessage(null)}>
                    &times;
                  </button>
                </div>
              </div>
            )}
          </>
        </div>
      </section>
    </BusinessOnboardingContext.Provider>
  );
};
export default OnboardingPage;
