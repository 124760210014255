import ReactStars from "react-rating-stars-component";
import "./index.scss";
export const CompanyRating = ({average, votes}) => {
  return (
    <div className="company-rating-review">
      <div className="average">
        <span className="rate">
          {average.average}
          <small>/5</small>
        </span>
        <div className="star-rating-count">
          <ReactStars value={average.stars} size={20} />{" "}
          <span className="count">({average.total})</span>
        </div>
      </div>
      <div className="percents">
        {votes.map((vote, index) => (
          <div className="rating-line" key={index}>
            {vote.star} Stars
            <div className="percent">
              <span style={{width: vote.percent}}></span>
            </div>
            <button>{vote.percent}</button>
          </div>
        ))}
      </div>
    </div>
  );
};
