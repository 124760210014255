import {Formik, Form, Field} from "formik";
import {LabelBlueBold} from "../../../Layout/Shared/LabelBlueBold";
import {RightToggler} from "../../../Layout/Shared/RightToggler/index";
import {BusinessUpdateAppointmentSchema} from "../../../../schemas/Calendar/Business/appointment";
import ErrorField from "../../../../components/Layout/Shared/ErrorField";
import {times, getOnlyDate, getOnlyTime} from "../../../../utils/date";
import {updateAppointment} from "../../../../data/Calendar/Business/updateAppointment";
import moment from "moment";

export const EditAppointment = ({close, appointment, onSuccess}) => {
  appointment = {
    id: appointment.id,
    clientEmail: appointment.clientEmail,
    firstName: appointment.firstName,
    lastName: appointment.lastName,
    appointmentDate: getOnlyDate(appointment.date),
    appointmentTime: getOnlyTime(appointment.date),
    reminder: appointment.reminder,
    pushNotification: appointment.pushNotification,
    consultant: appointment.consultant,
  };

  const onSubmit = async (values) => {
    const {
      clientEmail,
      firstName,
      lastName,
      appointmentDate,
      appointmentTime,
      reminder,
      pushNotification,
      consultant,
    } = values;
    const {hasSuccess} = await updateAppointment({
      id: appointment.id,
      clientEmail,
      firstName,
      lastName,
      date: moment(
        `${appointmentDate} ${appointmentTime}`,
        "YYYY-MM-DD HH:mm A"
      ).format(),
      reminder,
      pushNotification,
      consultant,
    });
    hasSuccess && onSuccess();
    close();
  };

  return (
    <>
      <RightToggler title="EDIT APPOINTMENT" size="500px" close={close}>
        <Formik
          validationSchema={BusinessUpdateAppointmentSchema}
          validateOnBlur={false}
          initialValues={appointment}
          onSubmit={onSubmit}
        >
          {({errors}) => (
            <Form>
              <div className="ps-5 pe-5 pt-3 pb-5">
                <div>
                  <LabelBlueBold content="Client Email" />
                  <Field
                    type="clientEmail"
                    name="clientEmail"
                    className="custom-input bg-transparent"
                    placeholder="Enter client email address"
                  />
                  <ErrorField message={errors.clientEmail} />
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <LabelBlueBold content="First Name" />
                    <Field
                      name="firstName"
                      className="custom-input bg-transparent"
                      placeholder="First Name"
                    />
                    <ErrorField message={errors.firstName} />
                  </div>
                  <div className="col-6">
                    <LabelBlueBold content="Last Name" />
                    <Field
                      name="lastName"
                      className="custom-input bg-transparent"
                      placeholder="Last Name"
                    />
                    <ErrorField message={errors.lastName} />
                  </div>
                </div>
              </div>
              <div className="clearfix border-top"></div>
              <div className="ps-5 pe-5  pt-4">
                <div className="row">
                  <div className="col-6">
                    <LabelBlueBold content="Apt. Date" />
                    <Field name="appointmentDate">
                      {({field: {value}, form: {setFieldValue}}) => (
                        <input
                          type="date"
                          className="custom-input bg-transparent"
                          placeholder="Choose Date"
                          value={value}
                          onChange={({target}) =>
                            setFieldValue("appointmentDate", target.value)
                          }
                        />
                      )}
                    </Field>
                    <ErrorField message={errors.appointmentDate} />
                  </div>
                  <div className="col-6">
                    <LabelBlueBold content="Apt. Time" />
                    <Field
                      name="appointmentTime"
                      className="primary-select bg-transparent"
                      as="select"
                    >
                      {times.map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <LabelBlueBold content="Reminder Time" />
                    <Field
                      name="reminder"
                      className="primary-select bg-transparent"
                      as="select"
                    >
                      <option value={15}>15 Minutes Earlier Apt.</option>
                      <option value={30}>30 Minutes Earlier Apt.</option>
                      <option value={60}>60 Minutes Earlier Apt.</option>
                    </Field>
                  </div>
                  <div className="col-6">
                    <LabelBlueBold content="Push Notification" />
                    <Field
                      name="pushNotification"
                      className="primary-select bg-transparent"
                      as="select"
                    >
                      <option value={true}>Enabled</option>
                      <option value={false}>Disabled</option>
                    </Field>
                  </div>
                </div>
              </div>
              <div className="mt-5 clearfix border-top"></div>
              <div className="ps-5 pe-5  pt-4 pb-5">
                <div>
                  <LabelBlueBold content="Consultant" />
                  <Field
                    type="text"
                    name="consultant.fullName"
                    className="custom-input bg-transparent"
                    placeholder="Consultant"
                  />
                  <ErrorField message={errors.consultant} />
                </div>
                <div className="row mt-4"></div>
              </div>
              <div className="toggle-footer">
                <div className="d-flex justify-content-between">
                  <button type="submit" className="btn-blue btn-small w-100">
                    Update Appointment
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </RightToggler>
    </>
  );
};
