import {contactApiHostName} from "../../endpoint";
import {defaultHeaders} from "../../headers";

export const deleteList = async (contactIdList) => {
  try {
    const result = await fetch(`${contactApiHostName}/contacts`, {
      method: "DELETE",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(contactIdList),
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    };
  }
};
