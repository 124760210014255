import React from "react";
import "../styles/pages/login.scss";
import LearnMore from "../components/Login/LearnMore";
import BusinessForm from "../components/Register/BusinessForm";
import { Link } from "react-router-dom";

const RegisterPage = () => {
  return (
    <div>
      <div className="container-fluid sign-in">
        <div className="row d-md-flex">
          <LearnMore />
          <div
            className={`col-xl-6 col-lg-6 col-md-6 right order-1 order-md-2 order-lg-2 order-xl-2 d-flex justify-content-center align-items-center  `}
          >
            <div className="change-page-wrapper">
              Already have an account?
              <Link
                className="d-flex align-items-center justify-content-center btn-transparent-border-blue mt-0   btn-small"
                to={"/business/login"}
              >
                Log in
              </Link>
            </div>
            <div className="box">
              <h2>
                Get Started
                <small>
                  It’s free to create an account and it only takes a minute
                </small>
              </h2>
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <BusinessForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
