/* eslint-disable no-extend-native */
import React, {useState, useEffect} from "react";
import Table from "../../../../Layout/Shared/Table";
import {Options} from "../../../../Layout/Shared/DropdownOptionsMenu";
import {ThreeDotIcon} from "../../../../../assets/icons/icon";
import {getInvoices} from "../../../../../data/Settings/Business/getInvoices";
import DataLoading from "../../../../Layout/Shared/DataLoading";
import {FormattedDate} from "../../../../Layout/Shared/FormattedDate";

String.prototype.capitalizeFirstLetter = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const Invoice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await getInvoices();
      if (hasSuccess) {
        setInvoices(data.invoices);
      }
      setIsLoading(false);
    };
    init();
  }, []);

  const ActionButtons = () => (
    <Options
      arrow={false}
      buttonTitle={ThreeDotIcon}
      menuItems={<div>{/* <button>Download</button> */}</div>}
    />
  );

  /*
  const data = invoices.map((invoice) => ({
    status: invoice.status,
    plan: invoice.line_items[0].entity_id,
    date: getDate(new Date(invoice.date * 1000)),
    type: invoice.line_items[0].entity_type,
  }));
  */

  const fields = [
    "plan",
    {render: ({row}) => <FormattedDate date={row.startDate} />},
    {render: ({row}) => <FormattedDate date={row.expiryDate} />},
    "type",
    "status",
    {render: ActionButtons},
  ];
  const headers = [
    {
      displayName: "Plan",
      sortingFieldName: "plan",
    },
    {
      displayName: "Start Date",
      sortingFieldName: "startDate",
    },
    {
      displayName: "Expiry Date",
      sortingFieldName: "expiryDate",
    },
    {
      displayName: "Type",
      sortingFieldName: "type",
    },
    {
      displayName: "Status",
      sortingFieldName: "status",
    },
    {
      displayName: "Options",
      sortingFieldName: "options",
    },
  ];

  const options = {
    data: invoices.map((invoice) => ({
      ...invoice,
      status: invoice.status.capitalizeFirstLetter(),
      type: invoice.type.capitalizeFirstLetter(),
    })),
    headers,
    fields,
    itemsPerPage: 25,
    pagination: true,
    search: false,
    sort: true,
  };
  return (
    <section className="p-5">
      {isLoading ? <DataLoading height="700px" /> : <Table options={options} />}
    </section>
  );
};
