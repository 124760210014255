import Modal from "../../../../../Layout/Shared/Modal";
import {Comment} from "./Comment";
import {CompanyDetails} from "./Company";
import {Ratings} from "./Rating";
import SampleLogo from "../../../../../../assets/images/default-business-image.png";
import {CustomButton} from "../../../../../Layout/Shared/Button";
const company = {
  logo: SampleLogo,
  name: "Yost - Wiza",
  service: "Loan Service",
  consultant: "John Smith",
};
const ratings = [
  {
    name: "Immigration Service",
    rating: 4,
  },
  {
    name: "Service Owner",
    rating: 3,
  },
  {
    name: "Communication",
    rating: 5,
  },
  {
    name: "Time Frame",
    rating: 1,
  },
];
const comment = {
  comment: "comment",
  date: new Date(),
};

const LastFeedback = () => {
  return (
    <div className="ms-5  ps-5 pt-1">
      <button className="btn-prev"></button>
      <button className="btn-next"></button>
    </div>
  );
};
export const Feedback = ({onClose}) => {
  return (
    <Modal
      width="800px"
      height="550px"
      extra={<LastFeedback />}
      title="Your 1st Feedback"
      close={onClose}
    >
      <div>
        <div className="d-flex justify-content-start">
          <div className="border-end pe-5 ">
            <CompanyDetails company={company} />
          </div>
          <div className="ps-5">
            <Comment comment={comment} />
            <Ratings ratings={ratings} />
          </div>
        </div>
        <div className="pt-4 d-flex justify-content-end align-items-center">
          <CustomButton
            color="transparent"
            size="114px"
            title="Close"
            onClick={onClose}
          />
          <CustomButton color="blue" size="180px" title="Give 2nd Feedback" />
        </div>
      </div>
    </Modal>
  );
};
