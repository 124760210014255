import {Formik, Form, Field} from "formik";
import {LabelBlueBold} from "../../../../../Layout/Shared/LabelBlueBold";
import {SwitchButton} from "../../../../../Layout/Shared/SwitchButtonBlue";
import "./index.scss";
export const AppointmentReminder = () => {
  return (
    <div className="ao-reminder">
      <div className="head">
        <LabelBlueBold content="Would you like to receive a reminder?" />
        <SwitchButton
          small={true}
          status={true}
          onChange={() => alert("clicked")}
        />
      </div>
      <div className="body">
        <Formik
          initialValues={{
            time: "",
            email: "",
            setAlarm: "",
            notification: "",
          }}
        >
          <Form>
            <div>
              <span className="reminder-title">Time Before Appointment</span>
              <Field as="select" name="time" className="primary-select">
                <option value="1hours">1 Hour</option>
                <option value="2hours">2 Hours</option>
                <option value="3hours">3 Hours</option>
              </Field>
            </div>
            <div className="reminder-trough">
              <span className="reminder-title">Reminder through</span>
              <Field
                type="checkbox"
                className="custom-checkbox"
                name="email"
                id="email"
              />
              <label htmlFor="email">Email</label>
              <Field
                type="checkbox"
                className="custom-checkbox disabled"
                disabled="disabled"
                name="setAlarm"
                id="setAlarm"
              />
              <label htmlFor="setAlarm">Set Alarm</label>
              <Field
                type="checkbox"
                className="custom-checkbox disabled"
                disabled="disabled"
                name="notification"
                id="notification"
              />
              <label htmlFor="notification">In App Push Notification</label>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
