import "./index.scss";
export const CalendarWeekly = () => {
  return (
    <div className="weekly-view">
      <div className="weekly-top">
        <button className="prev"></button>
        <span>Mar 09 - 13 2021</span>
        <button className="next"></button>
      </div>
      <div className="weekly-body">
        <ul>
          <li>
            <button>
              <span>Mon</span> Mar 09, 2021
            </button>
          </li>
          <li>
            <button>
              <span>Mon</span> Mar 09, 2021
            </button>
          </li>
          <li>
            <button className="active">
              <span>Mon</span> Mar 09, 2021
            </button>
          </li>
          <li>
            <button>
              <span>Mon</span> Mar 09, 2021
            </button>
          </li>
          <li>
            <button>
              <span>Mon</span> Mar 09, 2021
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};
