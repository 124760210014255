import React, {useState, useEffect} from "react";
import {times} from "../../../../../../utils/date";
import moment from "moment";

export const Day = ({schedule, onChange}) => {
  const [updatedSchedule, setUpdatedSchedule] = useState(schedule);
  const [warningMessage, setWarningMessage] = useState(null);

  const {day, isSelected, start, end} = updatedSchedule;

  const checkedClick = () => {
    const newSchedule = {
      ...updatedSchedule,
      isSelected: !isSelected,
    };
    setUpdatedSchedule(newSchedule);
    onChange(newSchedule);
  };

  const onStartTimeChange = ({target}) => {
    const message = getWarningMessage(target.value, updatedSchedule["end"]);
    if (message) setWarningMessage(message);

    const newSchedule = {
      ...updatedSchedule,
      start: target.value,
    };
    setUpdatedSchedule(newSchedule);
    onChange(newSchedule);
  };

  const onEndTimeChange = ({target}) => {
    const message = getWarningMessage(updatedSchedule["start"], target.value);
    if (message) setWarningMessage(message);

    const newSchedule = {
      ...updatedSchedule,
      end: target.value,
    };
    setUpdatedSchedule(newSchedule);
    onChange(newSchedule);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setWarningMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [warningMessage]);

  const getWarningMessage = (start, end) => {
    if (!(start && end)) return null;
    if (moment(start, ["h:m a", "H:m"]) >= moment(end, ["h:m a", "H:m"]))
      return `Start time cannot be equal to or greater than End time! \n Please select less than ${end} as start hour or greater than ${start} as the finish.`;
    return null;
  };

  return (
    <div className="schedules-days mt-4">
      <input
        id={day}
        type="checkbox"
        checked={isSelected}
        onClick={checkedClick}
      />
      <label htmlFor={day}>
        <div className="disabled"></div>
        <i></i>
        <span>{day}</span>
        <div className="d-flex justify-content-between">
          <select
            defaultValue={start}
            className="primary-select me-2  "
            onChange={onStartTimeChange}
          >
            {times.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
          <select
            defaultValue={end}
            className="primary-select ms-2 "
            onChange={onEndTimeChange}
          >
            {times.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
        </div>
      </label>
      {warningMessage && (
        <div className="notifications">
          <div className="warning">
            <span>Warning</span>
            <p>{warningMessage}</p>
            <button onClick={() => setWarningMessage(null)}>&times;</button>
          </div>
        </div>
      )}
    </div>
  );
};
