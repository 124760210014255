import { formsApiHostName } from '../endpoint'
import { defaultHeaders } from '../headers'

export const createSubmission = async (formId, data) => {
  try {
    const result = await fetch(
      `${formsApiHostName}/forms/${formId}/submissions`,
      {
        method: 'POST',
        headers: {
          ...defaultHeaders,
        },
        body: JSON.stringify(data),
      },
    )
    return {
      isSuccess: result.status === 200,
      data: await result.json(),
    }
  } catch (err) {
    return {
      isSuccess: false,
      data: err.message,
    }
  }
}
