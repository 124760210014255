import { ProfileCard } from '../ProfileCard'
import { ServiceList } from '../../../../Layout/Shared/ServiceList'
import { Formik, Form, Field } from 'formik'
import { LabelBlueBold } from '../../../../Layout/Shared/LabelBlueBold'
import { useBusinessProfileSettingsContext } from '../../../../../providers/Settings/Profile/business'
import ErrorField from '../../../../Layout/Shared/ErrorField'
import PhoneInput from 'react-phone-input-2'
import { BusinessProfileSchema } from '../../../../../schemas/ProfileSettings/business'

export const BusinessProfile = () => {
  const {
    values: { businessProfile, isStaff, industries, logoUrl },
    setters: { setBusinessProfile, setIndustries, setLogoUrl },
  } = useBusinessProfileSettingsContext()

  return (
    <div className="row ps-5 pe-5  pb-5">
      <div className="col-xl-3 col-lg-4 col-md-4 border-end pt-5">
        <ProfileCard
          title={businessProfile.name}
          buttonValue="Upload Logo"
          imageUrl={logoUrl}
          setImageUrl={setLogoUrl}
          source="business-logo"
          disabled={isStaff}
        />
      </div>
      <div className="col-xl-9 col-lg-8 col-md-8 p-5">
        <div className="row">
          <div className="col-lg-5 pe-5 ">
            <Formik
              enableReinitialize
              initialValues={businessProfile}
              validateOnBlur={false}
              validationSchema={BusinessProfileSchema}
            >
              {({ errors, values }) => (
                <>
                  {setBusinessProfile(values)}
                  <Form>
                    <div className="h2 pb-4">Business Name</div>
                    <Field
                      className="custom-input"
                      placeholder="Sole Enterprises"
                      name="name"
                      disabled
                    />
                    <div className="h2 pt-5 pb-4 mt-5">Business Address</div>
                    <Field
                      className="custom-input"
                      placeholder="Business Address"
                      name="address"
                      disabled={isStaff}
                    />
                    <div className="d-flex justify-content-between mt-5">
                      <Field
                        className="custom-input me-2"
                        placeholder="City"
                        name="city"
                        disabled={isStaff}
                      />
                      <Field
                        className="custom-input ms-2 "
                        placeholder="State"
                        name="state"
                        disabled={isStaff}
                      />
                    </div>

                    <div className="d-flex justify-content-between mt-5">
                      <Field
                        className="custom-input me-2"
                        placeholder="Country"
                        name="country"
                        disabled={isStaff}
                      />
                      <Field
                        className="custom-input ms-2 "
                        placeholder="Zip Code"
                        name="zipCode"
                        disabled={isStaff}
                      />
                    </div>
                    <div className="h2 pt-5 pb-4 mt-5">
                      Business Contact Info
                    </div>
                    <LabelBlueBold content="Email Contact" />
                    <Field
                      className="custom-input"
                      placeholder="Enter Email Address"
                      name="email"
                      disabled
                    />
                    <div className="mt-4 form-group  custom-phone">
                      <LabelBlueBold content="Phone Number" />
                      <Field name="phone">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <PhoneInput
                            enableSearch
                            placeholder="Enter Mobile Number"
                            country={'ca'}
                            value={value}
                            disabled={isStaff}
                            onChange={(number) =>
                              setFieldValue('phone', number)
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <ErrorField message={errors.address} />
                    <ErrorField message={errors.city} />
                    <ErrorField message={errors.state} />
                    <ErrorField message={errors.country} />
                    <ErrorField message={errors.zipCode} />
                    <ErrorField message={errors.phone} />
                  </Form>
                </>
              )}
            </Formik>
          </div>
          <div className="col-lg-6 ps-5">
            <div className="h2 pb-5">Select Services Category</div>
            <ServiceList
              selectedIndustries={industries}
              setSelectedIndustries={setIndustries}
              disabled={isStaff}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
