import React, { useState } from 'react'
import { StyledRadiusInput } from '../../../styles'
import {
  StyledContainer,
  StyledItemActionButton,
  StyledItemActionIcon,
  StyledItemActions,
  StyledItemWrapper,
  StyledList,
  StyledListItem,
  StyledTitle,
} from './styles'

const MultipleItemPanel = ({ values, onChanged }) => {
  const [items, setItems] = useState(values)

  const handleOnChanged = (values) => {
    onChanged(values.filter((value) => value.trim().length > 0))
  }

  const handleOnItemValueChange = (index, value) => {
    const clone = [...items]
    clone[index] = value
    setItems(clone)
    handleOnChanged(clone)
  }

  const handleAddClick = () => {
    if (!items.some((item) => item === '')) {
      setItems([...items, ''])
    }
  }

  const handleRemoveClick = (index) => {
    const clone = [...items]
    clone.splice(index, 1)
    setItems(clone)
    handleOnChanged(clone)
  }

  const isLastItem = (index) => index === items.length - 1

  return (
    <StyledContainer>
      <StyledTitle>Enter Data Options Below</StyledTitle>
      <StyledList>
        {items.map((item, index) => (
          <StyledListItem key={index}>
            <StyledItemWrapper>
              <StyledRadiusInput
                className="form-control"
                placeholder="Enter option"
                value={item}
                onChange={(e) =>
                  handleOnItemValueChange(index, e.currentTarget.value)
                }
              />
              <StyledItemActions>
                {isLastItem(index) ? (
                  <StyledItemActionButton onClick={handleAddClick}>
                    <StyledItemActionIcon className="bi bi-plus-circle-fill" />
                  </StyledItemActionButton>
                ) : (
                  <StyledItemActionButton onClick={handleRemoveClick}>
                    <StyledItemActionIcon className="bi bi-trash-fill" />
                  </StyledItemActionButton>
                )}
              </StyledItemActions>
            </StyledItemWrapper>
          </StyledListItem>
        ))}
      </StyledList>
    </StyledContainer>
  )
}

export default MultipleItemPanel
