import React from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {CheckboxLabel, FormCheck, OptionsInputGroup} from "../Base/BaseStyles";

export function QualitativeOption({component_key, dispatch, attributes}) {
  const qualitative = getAttribute("qualitative", attributes);
  const qualitativeValue = qualitative === "true";
  return (
    <OptionsInputGroup className="input-group mt-3">
      <FormCheck className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          defaultChecked={qualitativeValue}
          onChange={(event) =>
            dispatch({
              type: "qualitative_change",
              qualitative: !qualitativeValue ? "true" : "false",
              key: component_key,
            })
          }
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          <CheckboxLabel>Qualitative</CheckboxLabel>
        </label>
      </FormCheck>
    </OptionsInputGroup>
  );
}

export default QualitativeOption;
