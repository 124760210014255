import Header from "../../../../../components/Layout/Header/Header";
import { PageContainer } from "../../../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../../../components/Layout/Shared/PageTitle";
import { HeaderButtons } from "../../../../../components/Layout/Shared/PageHeader/HeaderButtons";
import { CustomButton } from "../../../../../components/Layout/Shared/Button";
import { PieChartIcon } from "../../../../../assets/icons/icon";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GenericPageLeft } from "../../../../../components/Layout/Shared/GenericPageLeft";
import { EditClientGroup } from "../../../../../components/Clients/Group/Edit";
import { ImportClientManager } from "../../../../../components/Clients/ImportClientManager";

const ClientGroupDetailsPage = () => {
  const history = useHistory();
  const goToInviteClient = () => history.push(`/business/client/create`);
  const [showImport, setShowImport] = useState(false);
  const goToContactList = () => history.push(`/business/client/list`);
  return (
    <section className="dashboard  bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>CLIENTS</PageTitle>
              <HeaderButtons>
                <CustomButton
                  color="transparent"
                  title="Migrate Client from Contacts"
                  size="200px"
                  onClick={() => setShowImport(true)}
                />
                <CustomButton
                  color="blue"
                  title="Invite Client"
                  size="150px"
                  onClick={goToInviteClient}
                />
                <button className="btn btn-icon">
                  {PieChartIcon} Analytics
                </button>
              </HeaderButtons>
            </PageHeader>
            <EditClientGroup />
          </PageContainer>
        </div>
      </div>
      <ImportClientManager
        show={showImport}
        setShow={setShowImport}
        refresh={goToContactList}
      />
    </section>
  );
};

export default ClientGroupDetailsPage;
