import React, {useState, useContext, useEffect} from "react";
import {CustomButton} from "../../../../Layout/Shared/Button";
import {LabelBlueBold} from "../../../../Layout/Shared/LabelBlueBold";
import Modal from "../../../../Layout/Shared/Modal";
import "./index.scss";
import {Formik, Form, Field} from "formik";
import {DeactivateAccount} from "../../Account/DeactivateAccount";
import {ChangePasswordSchema} from "../../../../../schemas/AccountSettings/business";
import ErrorField from "../../../../Layout/Shared/ErrorField";
import DataLoading from "../../../../Layout/Shared/DataLoading";
import {useHistory} from "react-router-dom";
import {GlobalContext} from "../../../../../providers/global";
import {changePassword} from "../../../../../data/Settings/Business/Account/changePassword";

export const ChangePassword = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const history = useHistory();
  const {logout} = useContext(GlobalContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showDeactivate, setShowDeactivate] = useState(false);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const handleOnSubmit = async (values) => {
    setIsSaving(true);
    const {hasSuccess, data} = await changePassword({
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    });
    if (hasSuccess) {
      setIsSaving(false);
      setShowPass(true);
    } else {
      setIsSaving(false);
      setErrorMessage(JSON.stringify(data));
    }
  };

  const handleSignIn = () => {
    logout();
    history.push("/login");
    setShowPass(false);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setErrorMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [errorMessage]);

  return (
    <>
      {isSaving ? (
        <DataLoading height="750px" />
      ) : (
        <section className="ps-5 pe-5  change-password-section">
          <div className="row">
            <div className="col-xl-6 border-end">
              <div className="box">
                <div className="d-block title pb-5">
                  Change Account Password
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={ChangePasswordSchema}
                  onSubmit={handleOnSubmit}
                >
                  {({errors}) => (
                    <Form>
                      <div className="form-element">
                        <LabelBlueBold content="Old Password" />
                        <Field
                          name="oldPassword"
                          type="password"
                          className="custom-input"
                          placeholder="Enter old password"
                        />
                        <ErrorField message={errors.oldPassword} />
                      </div>
                      <div className="form-element">
                        <LabelBlueBold content="New Password" />
                        <Field
                          name="newPassword"
                          type="password"
                          className="custom-input"
                          placeholder="Enter new password"
                        />
                        <ErrorField message={errors.newPassword} />
                      </div>
                      <div className="form-element mb-5">
                        <LabelBlueBold content="Confirm Password" />
                        <Field
                          name="confirmNewPassword"
                          type="password"
                          className="custom-input"
                          placeholder="Confirm password"
                        />
                        <ErrorField message={errors.confirmNewPassword} />
                      </div>
                      <CustomButton
                        color="blue"
                        title="Save Changes"
                        size="100%"
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="box">
                <div className="d-block  title pb-5">
                  Deactivate Your Account
                </div>
                <p>
                  Deactivating your account will also effect the team members,
                  clients, services and all related features and data.
                  <br /> <br />
                  So make sure before taking this step.
                </p>

                <CustomButton
                  color="red"
                  title="Deactivate Account"
                  size="100%"
                  onClick={() => setShowDeactivate(true)}
                />
              </div>
            </div>
          </div>
          {showDeactivate && (
            <DeactivateAccount
              onClose={() => setShowDeactivate(false)}
              setIsSaving={setIsSaving}
            />
          )}
          {showPass && (
            <Modal title="Password Changed!" width="510px" height="310px">
              <p>
                Your account password has been changes successfully. You are now
                logged out from all of your devices. <br />
                <br />
                You can SignIn again to use the system.
              </p>
              <div className="mt-5 text-end">
                <CustomButton
                  color="blue"
                  title="Sign In Now"
                  size="160px"
                  onClick={handleSignIn}
                />
              </div>
            </Modal>
          )}
          {errorMessage && (
            <div className="notifications">
              <div className="warning">
                <span>Warning</span>
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage(null)}>&times;</button>
              </div>
            </div>
          )}
        </section>
      )}
    </>
  );
};
