import { FormComponentTypes } from "../constants";
import labelOptions from "./Body/Components/Label/options";
import headingOptions from "./Body/Components/Heading/options";
import inputTextOptions from "./Body/Components/InputText/options";
import inputNumberOptions from "./Body/Components/InputNumber/options";
import radioButtonOptions from "./Body/Components/RadioButton/options";
import checkboxOptions from "./Body/Components/Checkbox/options";
import memoOptions from "./Body/Components/Memo/options";
import pageBreakOptions from "./Body/Components/PageBreak/options";
import eSignatureOptions from "./Body/Components/ESignature/options";
import attachmentOptions from "./Body/Components/Attachment/options";

const componentDefaultOptions = {
  [FormComponentTypes.Heading]: headingOptions,
  [FormComponentTypes.Label]: labelOptions,
  [FormComponentTypes.InputNumber]: inputNumberOptions,
  [FormComponentTypes.InputText]: inputTextOptions,
  [FormComponentTypes.RadioButton]: radioButtonOptions,
  [FormComponentTypes.Checkbox]: checkboxOptions,
  [FormComponentTypes.Memo]: memoOptions,
  [FormComponentTypes.PageBreak]: pageBreakOptions,
  [FormComponentTypes.ESignature]: eSignatureOptions,
  [FormComponentTypes.Attachment]: attachmentOptions,
};

export const createComponentModel = componentType => ({
  type: componentType,
  options: componentDefaultOptions[componentType]
});

export const updateOptions = (options, key, value) => {
  const clone = { ...options };
  clone[key] = value;
  return clone;
};

export const getComponentsForSave = components =>
  components.map(component => {
    const result = {
      type: component.type,
      options: {
        ...component.options,
        requiredErrorMessage: component.options.isRequired
          ? component.options.requiredErrorMessage
          : ""
      }
    };
    if (result.type === FormComponentTypes.Memo) {
      result.options.value = result.options.value.toString("html");
    }
    delete result.options.submission;
    delete result.options.preview;
    return result;
  });
