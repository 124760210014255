import Modal from "../../../../Layout/Shared/Modal";
import "./index.scss";
import ReactStars from "react-rating-stars-component";
import {CustomButton} from "../../../../Layout/Shared/Button";
import {getDate} from "../../../../../utils/date";

export const ReviewDetails = ({onClose, review}) => (
  <Modal title="Review Details" close={onClose}>
    <div className="client-review-popup">
      <div className="review-details">
        <div>
          <h4>Client Details</h4>
          <figure>
            <img
              src="https://placehold.it/81x81"
              className="rounded-lg"
              alt={""}
            />
          </figure>
          <div className="client-details">
            <span>Full Name</span>
            <p>{review.client.name}</p>
            <span>Email</span>
            <p>{review.client.email}</p>
            <span>Mobile No.</span>
            <p>{review.client.phone}</p>
          </div>
        </div>
        <div>
          <h4>
            Client Comment <small>{getDate(review.created_at)}</small>
          </h4>
          <p>{review.review}</p>
          <div className="mt-5 review-wrapper">
            <div>
              <strong>Services</strong>
              <p>{review.serviceName}</p>
            </div>
            <div>
              <strong>Rating</strong>
              <p>
                <ReactStars value={review.rating} size={24} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <button className="btn btn-text-blue">
          Would like to contact client?
        </button>
        <CustomButton
          color="blue"
          title="Close"
          size="160px"
          onClick={onClose}
        />
      </div>
    </div>
  </Modal>
);
