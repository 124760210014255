import {getBase64} from "../../../utils/base64";
import {serviceApiHostName} from "../../endpoint";

export const buildUploadRequest = async (file, meta, serviceId) => {
  const body = new FormData();
  const fileData = await getBase64(file);
  body.append("file", fileData);

  return {
    url: `${serviceApiHostName}/service/attachment`,
    body,
    headers: {
      s3_content_type: meta.type,
      file_name: meta.name,
      service_id: serviceId,
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
};
