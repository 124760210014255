/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import IndustryImage from "../../../assets/images/basicInfoImage.png";
import ClientOnboardingContext from "../../../providers/Onboarding/client";
import ErrorField from "../../../components/Layout/Shared/ErrorField";

const SelectIndustry = () => {
  const [error, setError] = useState(null);
  const history = useHistory();

  const { industryInformation, finish } = useContext(ClientOnboardingContext);
  const { industries, setIndustries } = industryInformation;

  const handleTagClick = e => {
    const labelValue = e.target.innerText;
    const newList = industries.map(industry => {
      return {
        name: industry.name,
        selected:
          industry.name === labelValue ? !industry.selected : industry.selected
      };
    });
    setIndustries(newList);
  };

  const handleNext = async () => {
    const isSelected = industries.some(industry => industry.selected);
    if (!isSelected) {
      setError("Please select at least one Services Category");
      return;
    }
    await finish();
    history.push("/client/dashboard");
  };
  return (
    <div className="tab active" id="phone">
      <div className="row">
        <div className="col-xl-6  col-lg-6  pl-0">
          <div className="component mt-5 first">
            <h2>
              <strong>Select Services Category</strong>
            </h2>
            <h4 className="text-muted">
              Select one or more, and click “next” to continue
            </h4>
            <div>
              <div className="form-group">
                <div className="form-group pb-4 bussines-type">
                  {industries.map(industry => (
                    <Fragment key={industry.name}>
                      <input
                        type="checkbox"
                        checked={industry.selected}
                        onChange={() => {}}
                      />
                      <label onClick={handleTagClick}>{industry.name}</label>
                    </Fragment>
                  ))}
                </div>
                {error && <ErrorField message={error} />}
              </div>
              <a>
                <button
                  onClick={handleNext}
                  className="btn btn-blue-gradient btn-steps"
                >
                  Save Details
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-md-none d-lg-none d-xl-block text-end">
          <div className="component second ">
            <img src={IndustryImage} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectIndustry;
