import styled from "styled-components";
import * as theme from "../../../../../styles/theme";

export const StyledContainer = styled.div`
  text-align: center;
  border-radius: 0px !important;
  border: solid 1px #dee2e6;
  background-color: #ffffff;
  display:flex;
  align-items:center;
`;

export const StyledDescriptionWrapper = styled.div`
  font-family: ${theme.defaultFont};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 270%;
  letter-spacing: normal;
  text-align: center;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 25px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const StyledDescriptionText = styled.span`
  color: ${theme.azul};
  background-color: white;
  font-family: ${theme.defaultFont};
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-left: 1.5em;
  flex-grow: 1;
`;

export const StyledViewSwitchContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  line-height: 270%;
`;
export const StyledViewSwitchWrapper = styled.div`
  border-left: 1.5px solid #dee2e6;
  padding-left: 10px;
`;

export const StyledViewSwitchItem = styled.a`
  text-decoration: none;
  cursor: pointer;
  padding: 0.3em;
`;
export const StyledViewSwitchItemIcon = styled.i`
  font-size: 1.2em;
  padding: 0.2em;
`;