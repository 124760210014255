import { validateComponent } from '../../../../utils'

const inputTextValidator = (component) => {
  const { options } = component

  const validators = [
    {
      validationFunction: () => options.value.trim().length > 0,
      validationKey: 'value',
      validationMessage: 'This field is required',
    },
  ]

  validateComponent(component, validators)

  return component.isValid
}

export default inputTextValidator
