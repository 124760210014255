import React, { useState } from "react";
import "../../../styles/customComponents/shared.scss";
import "./index.scss";
import { ImportClient } from "./ImportClient";
import { ImportSelectedClient } from "./ImportSelectedClient";
import { ImportSuccess } from "./ImportSuccess";

export const ImportClientManager = ({ show, setShow, refresh }) => {
  const [showImportSelectedContact, setShowImportSelectedContact] =
    useState(false);
  const [showImportSuccess, setShowImportSuccess] = useState(false);
  const [contacts, setContacts] = useState([]);

  return (
    <>
      {show && (
        <ImportClient
          onClose={() => setShow(false)}
          onConfirm={() => {
            setShowImportSelectedContact(true);
            setShow(false);
          }}
          contacts={contacts}
          setContacts={setContacts}
        />
      )}
      {showImportSelectedContact && (
        <ImportSelectedClient
          onClose={() => setShowImportSelectedContact(false)}
          onConfirm={() => {
            setShowImportSuccess(true);
            setShowImportSelectedContact(false);
          }}
          contacts={contacts}
          setContacts={setContacts}
        />
      )}
      {showImportSuccess && (
        <ImportSuccess
          onClose={() => {
            setContacts([]);
            setShowImportSuccess(false);
          }}
          onConfirm={() => {
            setContacts([]);
            setShowImportSuccess(false);
          }}
          refresh={refresh}
        />
      )}
    </>
  );
};
