import React, { useState, useEffect } from "react";
import moment from "moment";
import Modal from "../../../../../Layout/Shared/Modal";
import Table from "../../../../../Layout/Shared/Table";
import { CustomButton } from "../../../../../Layout/Shared/Button";
import { ScrollContent } from "../../../../../Layout/Shared/ScrollContent";
import { attempSearch } from "../../../../../../components/Layout/Shared/Table/search";
import { FormTypes } from '../../../../../../components/Form/constants.js'
import { getAllForms } from '../../../../../../data/Forms/getAllForms'
import { StyledInput } from './styles'

export const AddForms = ({ selectedForms, onClose, onSave }) => {
  const [type, setType] = useState('')
  const [forms, setForms] = useState([]);
  const [filteredForms, setFilteredForms] = useState(forms);

  useEffect(() => {
    const getInitialData = async () => {
      const { data: response, hasSuccess } = await getAllForms()
      hasSuccess
        ? selectPush(response.data)
        : console.log(JSON.parse(response).description)
    }
    getInitialData()
  }, [])

  const selectPush = (response) => {
    let formSelected = [];
    response.forEach((res) => {
      if (!("isSelected" in res)) {
        res["isSelected"] = false
      }
      formSelected.push(res);
    })
    setForms(formSelected)
  }

  const setIsChecked = (row) => {
    setForms(
      forms.map((item) => {
        return {
          ...item,
          isSelected: item.title === row.title ? !item.isSelected : item.isSelected,
        };
      })
    );
    setFilteredForms(
      filteredForms.map((item) => {
        return {
          ...item,
          isSelected: item.title === row.title ? !item.isSelected : item.isSelected,
        };
      })
    );
  };

  const CheckBox = ({ row }) => {
    return (
      <StyledInput
        type="checkbox"
        checked={row.isSelected}
        onChange={() => setIsChecked(row)}
      />
    )
  };

  const onAddForms = () => {
    const filteredFormData = forms.filter((item) => item?.isSelected);
    const data = filteredFormData.map((item) => ({
      id: item._id,
      name: item.title,
      lastModified: moment.utc(item.updated_at).format("MMM Do, YYYY"),
      actions: true,
    }))
    onSave(
      data
    );
    onClose();
  };

  const handleSearch = ({ value }) => {
    setFilteredForms(value !== "" ? attempSearch(forms, fields, value) : []);

  };

  const handleCategoryChange = (event) => {
    setType(event.currentTarget.value)
    const myForms = forms.filter(el => { return el.type === event.currentTarget.value })
    setFilteredForms(myForms.filter((my => !(selectedForms.map(sf => sf.id).includes(my._id)))));
  };

  const formTypeOptions = Object.keys(FormTypes).map((key) => ({
    label: key,
    value: FormTypes[key],
  }))

  const fields = ["_id", "title", "updated_at", { render: CheckBox }];
  const headers = [
    {
      displayName: "Id",
      sortingFieldName: "_id",
      sortingOptions: {
        dataType: "number",
      },
    },
    {
      displayName: "Form Name",
      sortingFieldName: "title",
    },
    {
      displayName: "Date Modified",
      sortingFieldName: "updated_at",
    },
    {
      displayName: "Select",
      sortingFieldName: "isSelected",
    },
  ];
  
  const options = {
    data: filteredForms,
    headers,
    fields,
    pagination: false,
    itemsPerPage: 100,
    sort: true,
  };
  return (
    <Modal
      title="Add Form to This Service from Existing Forms"
      width="920px"
      height="auto"
      close={onClose}
    >
      <div className="row">
        <div className="col-6">
          <select
            className="primary-select"
            value={type}
            onChange={handleCategoryChange}
          >
            <option value="-1" >
              Select Forms Category
            </option>
            {formTypeOptions.map((type) => (
              <option value={type.value}>{type.label}</option>
            ))}
          </select>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="custom-input"
            placeholder="Filter Forms by Search"
            onChange={({ target }) => handleSearch(target)}
          />
        </div>
      </div>
      <div className="col-12 font-weight-bold text-primary-blue mt-5 pb-3 pl-0">
        Select From The List Below
      </div>
      <div className="mb-4">
        <ScrollContent height="calc(70vh - 300px)">
          <Table options={options} />
        </ScrollContent>
      </div>
      <div className="d-flex  pb-5 align-items-center">
        <div className="flex-grow-1">
          <button className="btn btn-link text-primary-blue font-weight-bold">
            How to Create New Form?
          </button>
        </div>
        <button className="btn btn-text-blue me-5 " onClick={onClose}>
          Cancel
        </button>
        <CustomButton color="blue" title="Add Forms" onClick={onAddForms} />
      </div>
    </Modal>
  );
};
