import {CompanyAddress} from "./Address";
import {CompanyDistance} from "./Distance";
import {AddFavorite} from "./Favorite";
import {HoursOfOperation} from "./HoursOfOperation";
import {AvailableLanguages} from "./Language";
import {CompanyLogo} from "./Logo";
import {CompanyName} from "./Name";
import {CompanyRating} from "./Rating";
import {CompanyStatus} from "./Status";
import "./index.scss";
export const CompanyProfile = ({data}) => {
  return (
    <div className="client-company-profile">
      <div className="top">
        <div>
          <CompanyLogo logo={data.logo} />
        </div>
        <div>
          <div>
            <CompanyName name={data.companyName} />
          </div>
          <div className="rating-wrapper">
            <CompanyRating rating={data.rating} count={data.ratingCount} />
            <AddFavorite />
          </div>
          <CompanyAddress city={data.city} country={data.country} />
        </div>
      </div>
      <div className="bottom">
        <div className="status-distance">
          <CompanyStatus status={data.companyStatus} />
          <CompanyDistance distance={data.distance} />
        </div>
        <div className="time-language">
          <HoursOfOperation open={data.timeOpen} close={data.timeClose} />
          <AvailableLanguages languages={data.languages} />
        </div>
      </div>
    </div>
  );
};
