import React from 'react'
import Required from '../../Shared/Required'
import { StyledContainer } from './styles'

const Options = ({ options, onChanged }) => {
  const { isRequired, requiredErrorMessage } = options

  const updateValue = (key, value) => {
    onChanged(key, value)
  }

  return (
    <StyledContainer>
      <Required
        isRequired={isRequired}
        onRequiredChanged={(checked) => updateValue('isRequired', checked)}
        errorMessage={requiredErrorMessage}
        onErrorMessageChanged={(value) =>
          updateValue('requiredErrorMessage', value)
        }
      />
    </StyledContainer>
  )
}

export default Options
