import {hostName} from "../endpoint";
import {defaultHeaders} from "../headers";

export const updateBusiness = async (details) => {
  const result = await fetch(`${hostName}/business`, {
    method: "PUT",
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify(details),
  });
  return result.json();
};
