import React from "react";

export const EventWrapper = ({children, event}) =>
  React.cloneElement(React.Children.only(children), {
    className: event.isCancelled
      ? "rbc-event cancelled-wrapper"
      : event.isMissed
      ? " rbc-event is-missed-wrapper"
      : event.isCompleted
      ? " rbc-event completed-wrapper"
      : "rbc-event waiting-wrapper",
  });
