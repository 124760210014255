// import {setFormValid} from "components/FormBuilder/FormEdit/FormEditValidation";

import {setFormValid} from "./FormEditValidation";

export function sortComponents(components) {
  return components.sort((obj1, obj2) => {
    if (obj1.order > obj2.order) {
      return 1;
    }
    if (obj1.order < obj2.order) {
      return -1;
    }
    return 0;
  });
}

export function clone(a) {
  return JSON.parse(JSON.stringify(a));
}

export function getAttribute(key, attributes) {
  const found = attributes.find((attribute) => attribute.key === key);
  if (found) {
    return found.value;
  } else {
    return "";
  }
}

export function getEditorAttribute(key, attributes) {
  const found = attributes.find((attribute) => attribute.key === key);
  if (found) {
    return found.editorValue;
  } else {
    throw new Error("getAttribute, attribute not found! " + key);
  }
}

export function getComponent(key, components) {
  const found = components.find((component) => component.key === key);
  const foundIndex = components.findIndex((component) => component.key === key);
  if (found) {
    return {component: found, index: foundIndex};
  } else {
    throw new Error("getComponent, component not found! " + key);
  }
}

export function changeComponentAttribute(
  state,
  component_key,
  component_attribute,
  new_value,
  validator,
  new_editor_value
) {
  return setFormValid({
    ...state,
    components: state.components.map((component) => {
      if (component.key === component_key) {
        return {
          ...component,
          valid:
            validator !== undefined && state.validation
              ? validator({
                  key: component_attribute,
                  value: new_value,
                  editorValue: new_editor_value,
                })
              : state.validation
              ? component.valid
              : true,
          attributes: component.attributes.map((attribute) => {
            if (attribute.key === component_attribute) {
              return {
                key: component_attribute,
                value: new_value,
                editorValue: new_editor_value,
              };
            } else {
              return attribute;
            }
          }),
        };
      } else {
        return component;
      }
    }),
    formSaved: component_attribute === "hover" ? state.formSaved : false,
  });
}
