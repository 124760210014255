import React, { useEffect, useRef } from 'react'
import { StyledImage, StyledImageInput } from '../styles'

const ImageSignature = ({ value, onChange }) => {
  const imageInputRef = useRef()

  const handleOnChange = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      onChange(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  useEffect(() => {
    imageInputRef.current.click()
  }, [])

  return (
    <>
      <StyledImageInput
        ref={imageInputRef}
        onChange={handleOnChange}
        type="file"
        accept="image/*"
      />
      {value && <StyledImage src={value} />}
    </>
  )
}
export default ImageSignature
