import {hostName} from "../endpoint";
import {defaultHeaders} from "../headers";

export const registerBusiness = async (business) => {
  const result = await fetch(`${hostName}/business/register`, {
    method: "POST",
    headers: defaultHeaders,
    body: JSON.stringify(business),
  });
  return result.json();
};
