import {LabelBlueBold} from "../../../../../Layout/Shared/LabelBlueBold";
import "./index.scss";
export const AppointmentConsultantDetails = ({consultantData}) => {
  return (
    <div className="ao-consultant-details">
      <LabelBlueBold content="Consultant Details" />
      <div className="details">
        {consultantData.avatar && (
          <div>
            <figure>
              <img src={consultantData.avatar} alt={consultantData.name} />
            </figure>
          </div>
        )}

        <div>
          <h3>{consultantData.name}</h3>
          <span>{consultantData.email}</span>
          <span>{consultantData.phone}</span>
        </div>
      </div>
    </div>
  );
};
