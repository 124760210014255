import {hostName} from "../../../endpoint";
import {defaultHeaders} from "../../../headers";

export const setAsPrimaryPaymentMethod = async (paymentMethod) => {
  try {
    const result = await fetch(
      `${hostName}/business/account-change-payment-method`,
      {
        method: "PUT",
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(paymentMethod),
      }
    );
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err,
    };
  }
};
