/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useContext } from "react";
import BasicInfoImage from "../../../../assets/images/basicInfoImage.png";
import steps from "../steps";
import "./industrySelection.scss";
import BusinessOnboardingContext from "../../../../providers/Onboarding/business";
import { useGlobalContext } from "../../../../providers/global";

const IndustrySelection = ({ goTo }) => {
  const { industriesInformation } = useContext(BusinessOnboardingContext);
  const { industries, setIndustries } = industriesInformation;
  const { currentUser } = useGlobalContext();

  useEffect(() => {}, [industries]);

  const handleTagClick = e => {
    const labelValue = e.target.innerText;
    const newList = industries.map(industry => {
      return {
        name: industry.name,
        selected:
          industry.name === labelValue ? !industry.selected : industry.selected
      };
    });
    setIndustries(newList);
  };

  const handleNext = () => goTo(steps.BUSINESSIFORMATION);

  return (
    <div className="tab active" id="info">
      <div className="row">
        <div className="col-xl-6  col-lg-6  pl-0">
          <div className="component first">
            <h2>Business Name</h2>
            <div className="pt-1 pb-5">
              <div className="form-group pb-4">
                <input
                  type="text"
                  className="custom-input"
                  disabled
                  placeholder={currentUser.businessName}
                />
              </div>
              <h2 className="mt-5">Select Services Category</h2>
              <div className="form-group pb-4 bussines-type">
                {industries.map(industry => (
                  <Fragment key={industry.name}>
                    <input
                      type="checkbox"
                      checked={industry.selected}
                      onChange={() => {}}
                    />
                    <label onClick={handleTagClick}>{industry.name}</label>
                  </Fragment>
                ))}
              </div>
              <a>
                <button
                  type="submit"
                  className="btn btn-blue-gradient btn-steps"
                  onClick={handleNext}
                >
                  Next
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-md-none d-lg-none d-xl-block text-center">
          <div className="component second">
            <img src={BasicInfoImage} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default IndustrySelection;
