import React from "react";
export function ComponentTabsContent({
  component_key,
  attributes,
  dispatch,
  EditTab,
  OptionTab,
}) {
  return (
    <div className="tab-content" id={component_key + "TabContent"}>
      <div
        className="tab-pane fade show active"
        id={component_key + "edit"}
        role="tabpanel"
        aria-labelledby="edit-tab"
      >
        <EditTab
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      </div>
      <div
        className="tab-pane fade"
        id={component_key + "options"}
        role="tabpanel"
        aria-labelledby="options-tab"
      >
        <OptionTab
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      </div>
    </div>
  );
}

export default ComponentTabsContent;
