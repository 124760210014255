import Modal from "../../../../Layout/Shared/Modal";
import React, {useState} from "react";
import {LabelBlueBold} from "../../../../Layout/Shared/LabelBlueBold";
import {ScrollContent} from "../../../../Layout/Shared/ScrollContent";
import {CustomButton} from "../../../../Layout/Shared/Button";
import {CalendarDaily} from "./Calendar/Daily";
import {CalendarWeekly} from "./Calendar/Weekly";
import {CalendarMonthly} from "./Calendar/Monthly";

import "./index.scss";
import {SlotList} from "./Calendar/List";

export const BookAppointment = ({onClose, goToOverview}) => {
  const [calendarView, setCalendarView] = useState("daily");
  const handleChange = ({target}) => {
    setCalendarView(target.value);
  };
  return (
    <Modal
      title="Book an Appointment with Ottawa World Services"
      width="920px"
      height="590px"
      close={onClose}
    >
      <div className="book-an-appointment">
        <div className="booking-header">
          <span>Select Calendar View</span>
          <div>
            <select className="primary-select" onChange={handleChange}>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
        </div>
        <div className="booking-body">
          <div className="calendar">
            {calendarView === "daily" ? (
              <CalendarDaily />
            ) : calendarView === "weekly" ? (
              <CalendarWeekly />
            ) : (
              <CalendarMonthly />
            )}
          </div>
          <div className="slots">
            <LabelBlueBold content="Select any available slot bellow" />
            <div className="slot-list">
              <ScrollContent height="270px">
                <SlotList />
              </ScrollContent>
            </div>
          </div>
        </div>
        <div className="booking-footer">
          <button className="btn-text-blue btn-small" onClick={onClose}>
            Cancel
          </button>
          <CustomButton
            title="Next"
            onClick={goToOverview}
            color="blue"
            size="160px"
          />
        </div>
      </div>
    </Modal>
  );
};
