import React from "react";

const LogoBlueSmall = () => {
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="138" height="23" viewBox="0 0 1920.000000 320.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,320.000000) scale(0.100000,-0.100000)"
fill="#1b84e7" stroke="none">
<path d="M2209 2810 c-110 -19 -197 -91 -225 -186 -18 -63 -13 -136 13 -188
22 -44 82 -104 127 -128 86 -44 228 -43 317 2 49 25 113 99 127 148 18 58 14
151 -7 197 -52 116 -202 182 -352 155z"/>
<path d="M5110 1530 l0 -1290 265 0 265 0 0 437 c0 370 2 435 14 423 7 -8 132
-199 276 -425 144 -226 268 -416 274 -423 9 -9 83 -12 325 -10 l312 3 -331
458 c-210 290 -328 462 -324 470 8 12 376 496 536 705 l79 102 -314 0 -315 0
-252 -392 c-139 -216 -259 -400 -266 -408 -12 -12 -14 111 -14 813 l0 827
-265 0 -265 0 0 -1290z"/>
<path d="M15610 1530 l0 -1290 265 0 265 0 0 1290 0 1290 -265 0 -265 0 0
-1290z"/>
<path d="M7663 2709 c-169 -17 -372 -80 -503 -157 l-65 -38 -3 -232 -2 -232
82 62 c167 127 300 178 481 186 92 4 131 1 180 -12 137 -37 207 -129 215 -281
11 -216 -91 -355 -473 -640 -109 -82 -236 -186 -281 -231 -134 -134 -236 -295
-283 -448 -22 -72 -41 -238 -41 -368 l0 -78 810 0 810 0 0 225 0 225 -516 0
-517 0 6 35 c13 78 121 190 328 339 344 249 431 323 531 455 58 77 120 200
143 286 25 92 31 273 11 375 -69 372 -426 579 -913 529z"/>
<path d="M9800 2710 c-179 -17 -322 -55 -455 -123 -112 -57 -238 -182 -288
-287 -64 -133 -83 -294 -51 -448 39 -195 160 -347 375 -473 38 -22 137 -69
221 -105 292 -125 412 -203 458 -300 18 -36 21 -58 18 -108 -6 -102 -61 -163
-187 -208 -72 -26 -270 -36 -379 -18 -161 26 -328 95 -453 187 l-59 43 0 -266
0 -265 53 -24 c274 -125 797 -146 1112 -44 255 82 424 254 471 479 24 114 15
323 -18 411 -79 214 -245 356 -581 499 -381 163 -495 263 -473 415 15 94 117
176 253 204 80 16 257 14 353 -5 103 -19 237 -66 318 -110 l62 -35 0 249 0
249 -72 22 c-112 33 -212 49 -369 60 -158 12 -184 12 -309 1z"/>
<path d="M280 1460 l0 -1220 725 0 725 0 0 225 0 225 -455 0 -455 0 0 995 0
995 -270 0 -270 0 0 -1220z"/>
<path d="M13278 2427 l-248 -71 0 -188 0 -188 -145 0 -145 0 0 -200 0 -200
145 0 145 0 0 -463 c0 -426 2 -470 19 -539 50 -194 159 -304 351 -354 122 -32
400 -19 513 25 l27 11 0 193 0 194 -52 -18 c-63 -22 -166 -24 -211 -5 -44 19
-83 62 -104 116 -15 39 -18 94 -21 443 l-3 397 195 0 196 0 0 200 0 200 -195
0 -195 0 0 260 c0 202 -3 260 -12 259 -7 0 -124 -33 -260 -72z"/>
<path d="M14628 2427 l-248 -71 0 -188 0 -188 -145 0 -145 0 0 -200 0 -200
145 0 145 0 0 -463 c0 -426 2 -470 19 -539 50 -194 159 -304 351 -354 122 -32
400 -19 513 25 l27 11 0 193 0 194 -52 -18 c-63 -22 -166 -24 -211 -5 -44 19
-83 62 -104 116 -15 39 -18 94 -21 443 l-3 397 195 0 196 0 0 200 0 200 -195
0 -195 0 0 260 c0 202 -3 260 -12 259 -7 0 -124 -33 -260 -72z"/>
<path d="M4002 2010 c-90 -13 -181 -45 -249 -89 -61 -40 -177 -156 -195 -197
-6 -13 -15 -24 -20 -24 -4 0 -8 63 -8 140 l0 140 -265 0 -265 0 0 -870 0 -870
265 0 264 0 3 553 c3 548 3 552 26 608 27 69 88 140 149 176 55 32 167 43 240
22 65 -18 133 -86 164 -164 l24 -60 3 -567 3 -568 265 0 265 0 -4 638 c-4 714
-3 701 -78 860 -64 134 -167 219 -309 255 -75 20 -202 27 -278 17z"/>
<path d="M11652 2009 c-130 -15 -284 -76 -387 -153 -179 -133 -288 -308 -335
-536 -19 -91 -21 -127 -17 -275 4 -130 10 -189 26 -250 112 -419 463 -627 988
-585 165 13 285 38 395 82 l83 33 3 192 2 192 -67 -33 c-84 -42 -147 -64 -241
-82 -84 -16 -265 -19 -338 -5 -180 34 -288 135 -323 299 -6 29 -11 57 -11 62
0 6 216 10 570 10 l570 0 0 113 c-1 145 -15 300 -35 385 -22 90 -93 235 -148
301 -125 150 -280 227 -508 251 -106 11 -124 11 -227 -1z m230 -373 c107 -32
177 -134 186 -271 l5 -75 -322 0 c-305 0 -321 1 -321 18 0 29 37 130 65 178
33 56 116 129 168 147 54 20 161 21 219 3z"/>
<path d="M17242 2009 c-130 -15 -284 -76 -387 -153 -179 -133 -288 -308 -335
-536 -19 -91 -21 -127 -17 -275 4 -130 10 -189 26 -250 112 -419 463 -627 988
-585 165 13 285 38 395 82 l83 33 3 192 2 192 -67 -33 c-84 -42 -147 -64 -241
-82 -84 -16 -265 -19 -338 -5 -180 34 -288 135 -323 299 -6 29 -11 57 -11 62
0 6 216 10 570 10 l570 0 0 113 c-1 145 -15 300 -35 385 -22 90 -93 235 -148
301 -125 150 -280 227 -508 251 -106 11 -124 11 -227 -1z m230 -373 c107 -32
177 -134 186 -271 l5 -75 -322 0 c-305 0 -321 1 -321 18 0 29 37 130 65 178
33 56 116 129 168 147 54 20 161 21 219 3z"/>
<path d="M2010 1110 l0 -870 265 0 265 0 0 870 0 870 -265 0 -265 0 0 -870z"/>
<path d="M18651 750 c-95 -20 -180 -82 -218 -159 -26 -53 -24 -173 4 -226 54
-101 151 -157 272 -158 93 -1 144 13 207 56 72 48 108 113 112 202 5 92 -22
159 -85 215 -70 61 -192 90 -292 70z"/>
</g>
</svg>

    );
}
 export default LogoBlueSmall;