import React, { useState } from "react";

import { ViewSwitch } from "../../Layout/Shared/ViewSwitch";
import FormContainerHeader from "../Shared/header.component";
import {
  StyledGridList,
  StyledLink,
  StyledTemplateCard,
  StyledTemplateCategoriesContainer
} from "./templates.style";
import IconArrowRight from "../../../assets/icons/arrow-right";
import Table from "../../Layout/Shared/Table";
import CreateModal from "../Create/CreateModal";

const FormTemplateCategory = ({ templates }) => {
  const [viewType, setViewType] = useState("list");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [createFormModalVisible, setCreateFormModalVisible] = useState(false);

  const templateIdParameter = {
    key: "templateId",
    value: selectedTemplateId
  };

  const options = {
    headers: [
      {
        displayName: "Template name",
        sortingFieldName: "title"
      },
      {
        displayName: "Use Template"
      }
    ],
    fields: [
      {
        render: ({ row }) => <div>{row.title}</div>
      },
      {
        render: ({ row }) => (
          <div className="d-flex justify-content-end align-items-center pr-2">
            <StyledLink
              to=""
              onClick={e => {
                e.preventDefault();
                setSelectedTemplateId(row._id);
                setCreateFormModalVisible(true);
              }}
            >
              Use Template <IconArrowRight />
            </StyledLink>
          </div>
        )
      }
    ],
    pagination: true,
    search: false,
    sort: true
  };

  return (
    <>
      {createFormModalVisible && (
        <CreateModal
          extraParameters={[templateIdParameter]}
          close={() => setCreateFormModalVisible(false)}
        />
      )}
      <StyledTemplateCategoriesContainer>
        <FormContainerHeader flexEnd>
          <ViewSwitch
            grid={() => setViewType("grid")}
            list={() => setViewType("list")}
            active={viewType}
          />
        </FormContainerHeader>

        {viewType === "list" ? (
          <Table options={{ data: templates, ...options }} />
        ) : (
          <StyledGridList>
            {templates?.map((template, index) => (
              <StyledTemplateCard
                to={`/business/forms/builder/${template._id}`}
                key={index}
              >
                {template.title}
              </StyledTemplateCard>
            ))}
          </StyledGridList>
        )}
      </StyledTemplateCategoriesContainer>
    </>
  );
};

export default FormTemplateCategory;
