import styled, { css } from "styled-components";
import * as theme from "../../../../../../../styles/theme";

export const StyledSection = styled.section`
  padding: 1rem;
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  background-color: #fafafa;
  margin: 1rem 0;
`;

export const StyledComponentContainer = styled.div`
  display: flex;
`;

export const StyledArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 0.25em;
  margin-right: 0.5em;
`;

export const StyledArrowButton = styled.button`
  background-color: ${theme.azul};
  color: white;
`;

export const StyledArrowIcon = styled.i`
  font-size: 1em;
`;

export const StyledWrapper = styled.div`
  flex: 1;
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 0.5em 0;
`;

export const StyledTitle = styled.h3`
  flex: 1;
  font-family: ${theme.defaultFont};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: left;
  color: #495057;
  margin: 0;
`;

export const StyledToolsWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

export const StyledMain = styled.main`
  ${({ previewMode }) =>
    previewMode &&
    css`
      background-color: #fff;
      padding: 1em;
    `}
`;

export const StyledColumnDropdown = styled.select``;

export const StyledColumnDropdownOption = styled.option``;

export const StyledToolButton = styled.button`
  color: ${theme.azul};
`;
export const StyledToolButtonIcon = styled.i``;
