import moment from "moment";
export const Comment = ({comment}) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4>Your comment </h4>
        <span>{moment(comment.date).format("MMM d, YYYY")}</span>
      </div>

      <div>{comment.comment} </div>
    </div>
  );
};
