import React from "react";
import Header from "../../../components/Layout/Header/Header";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {TabContainer} from "../../../components/Services/Client/Service/AppliedDisabled/TabContainer";
const ServiceAppliedDisabled = () => {
  return (
    <section className="dashboard bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>LANGUAGE SERVICE (DISABLED TABS)</PageTitle>
            </PageHeader>
            <TabContainer />
          </PageContainer>
        </div>
      </div>
      <div className="d-flex justify-content-end pe-5 "></div>
    </section>
  );
};

export default ServiceAppliedDisabled;
