import React from "react";
import BodyMain from "./Main";
import { StyledContainer } from "./styles";
import BodyTitle from "./Title";

const Body = () => (
  <StyledContainer>
    <BodyTitle />
    <BodyMain />
  </StyledContainer>
);

export default Body;
