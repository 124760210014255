import React, {Fragment} from "react";
import RichTextEditor from "react-rte";
import {
  getTextAlignBlockMetadata,
  getTextAlignClassName,
} from "react-rte/lib/lib/blockStyleFunctions";
import {getEditorAttribute} from "../../FormEdit/FormEditReducerHelpers";

export function Textarea({dispatch, attributes, component_key}) {
  let title = getEditorAttribute("title", attributes);
  if (typeof title == "string") {
    title = RichTextEditor.createValueFromString(title, "html", {
      customBlockFn: getTextAlignBlockMetadata,
    });
  }
  return (
    <Fragment>
      <Fragment>
        <RichTextEditor
          value={title}
          blockStyleFn={getTextAlignClassName}
          onChange={(value) =>
            dispatch({
              type: "textarea_change",
              title: value,
              key: component_key,
            })
          }
        />
      </Fragment>
    </Fragment>
  );
}

export default Textarea;
