import styled from "styled-components";

export const StyledContainer = styled.div`
  flex: 1;
`;

export const StyledComponentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
