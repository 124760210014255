export const CustomEvent = ({event, onClick}) => (
  <div
    id={event.id}
    onClick={onClick}
    className={
      event.isCancelled
        ? "rbc-event-content cancelled"
        : event.isMissed
        ? "rbc-event-content is-missed"
        : event.isCompleted
        ? "rbc-event-content completed"
        : "rbc-event-content waiting"
    }
  >
    {event.title}
  </div>
);
