import React, {useContext, useState, useEffect} from "react";
import {TextandMessages} from "./TextMessages";
import {Calls} from "./Calls";
import {Appointments} from "./Appointments";
import BusinessServiceContext from "../../../../../providers/Service/business";

const CommunicationChannels = () => {
  const {
    values: {serviceDetails, communicationChannel},
    setters: {setCommunicationChannel},
  } = useContext(BusinessServiceContext);

  const [textAndMessages, setTextAndMessages] = useState(
    communicationChannel.textAndMessages
  );
  const [calls, setCalls] = useState(communicationChannel.calls);
  const [appointments, setAppointments] = useState(
    communicationChannel.appointments
  );

  useEffect(() => {
    setCommunicationChannel({
      textAndMessages,
      calls,
      appointments,
    });
  }, [textAndMessages, calls, appointments, setCommunicationChannel]);
  return (
    <section className="p-5">
      <TextandMessages status={textAndMessages} onChange={setTextAndMessages} />
      <Calls
        phoneNumber={serviceDetails.phone}
        status={calls}
        onChange={setCalls}
      />
      <Appointments status={appointments} onChange={setAppointments} />
    </section>
  );
};
export default CommunicationChannels;
