import React from "react";
import {AlignmentDropdown, DropdownText} from "../Base/BaseStyles";
import styled from "styled-components";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";

const DropdownLi = styled.li`
  cursor: pointer;
`;

function getLis(lis, selected, component_key, dispatch) {
  const liElements = [];
  lis = lis.filter((li) => li !== selected);
  for (const li of lis) {
    liElements.push(
      <DropdownLi
        key={li}
        onClick={(event) => {
          event.preventDefault();
          dispatch({
            type: "heading_dropdown_alignment_change",
            value: event.target.lastChild.nodeValue,
            key: component_key,
          });
        }}
      >
        <DropdownText className="dropdown-item">{li}</DropdownText>
      </DropdownLi>
    );
  }
  return liElements;
}

export function DropdownAlignment({attributes, dispatch, component_key}) {
  let selected_alignment = "";
  try {
    selected_alignment = getAttribute("selected_alignment", attributes);
  } catch (e) {
    selected_alignment = "left";
  }
  let alignment = [];
  try {
    alignment = getAttribute("alignment", attributes);
  } catch (e) {
    alignment = ["left"];
  }

  return (
    <div>
      <div className="dropdown">
        <AlignmentDropdown
          className="btn dropdown-toggle formBuilder"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {selected_alignment}
        </AlignmentDropdown>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {getLis(alignment, selected_alignment, component_key, dispatch)}
        </ul>
      </div>
    </div>
  );
}

export default DropdownAlignment;
