import React from 'react'
import { useFormBuilderContext } from '../../context'
import ValidationText from '../Components/Shared/ValidationText'
import { StyledInnerWrapper } from '../styles'
import { StyledContainer, StyledInput, StyledTitleText } from './styles'

const BodyTitle = () => {
  const {
    formTitle,
    formTitleValidated,
    isFormTitleValid,
    setFormTitle,
  } = useFormBuilderContext()
  return (
    <StyledContainer>
      <StyledInnerWrapper>
        <StyledTitleText>Form Title</StyledTitleText>
        <StyledInput
          placeholder="Enter form title..."
          value={formTitle}
          onChange={(e) => setFormTitle(e.currentTarget.value)}
        />
        {formTitleValidated && !isFormTitleValid && (
          <ValidationText>Form title is required</ValidationText>
        )}
      </StyledInnerWrapper>
    </StyledContainer>
  )
}

export default BodyTitle
