import { formsApiHostName } from '../endpoint'
import { defaultHeaders } from '../headers'

export const getSubmission = async (formId, submissionId) => {
  try {
    const result = await fetch(
      `${formsApiHostName}/forms/${formId}/submissions/${submissionId}`,
      {
        method: 'GET',
        headers: {
          ...defaultHeaders,
        },
      },
    )
    return {
      success: result.status === 200,
      data: await result.json(),
    }
  } catch (error) {
    return {
      success: false,
      data: error.message,
    }
  }
}
