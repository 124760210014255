import React, {useState, useEffect} from "react";
import PhoneInput from "react-phone-input-2";
import {PaymentCard} from "./PaymentCards";
import VisaCard from "../../../../../assets/images/visa-card-logo.png";
import MasterCard from "../../../../../assets/images/master-card-logo.png";
import Paypal from "../../../../../assets/images/paypal-logo.png";
import Bank from "../../../../../assets/images/bank-logo.png";
import {CustomButton} from "../../../../Layout/Shared/Button";
import {AddPaymentMethod} from "./AddPaymentMethod";
import {getPaymentMethods} from "./../../../../../data/Settings/Business/Account/getPaymentMethods";
import {useBusinessAccountSettingsContext} from "../../../../../providers/Settings/Account/business";
import DataLoading from "../../../../Layout/Shared/DataLoading";
import ConfirmDialog from "../../../../Layout/Shared/Popups/ConfirmDialog";
import {setAsPrimaryPaymentMethod} from "../../../../../data/Settings/Business/Account/setAsPrimaryPaymentMethod";
import {useHistory} from "react-router";
import "./index.scss";

export const Billing = ({resetTabSelection}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [
    paymentMethodForSetAsDefault,
    setPaymentMethodForSetAsDefault,
  ] = useState(null);
  const history = useHistory();
  const {
    values: {planInformation, businessInformation},
    refresh,
  } = useBusinessAccountSettingsContext();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await getPaymentMethods();
      if (hasSuccess) {
        setPaymentMethods(Object.values(data));
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const goToProfileSettings = () => history.push("/business/settings/profile");

  const getDefaultPaymentMethod = () =>
    paymentMethods.find((paymentMethod) => paymentMethod.isDefault) ||
    paymentMethods[0];

  const getIconByName = (name) =>
    name === "Visa" || name === "visa"
      ? VisaCard
      : name === "Mastercard"
      ? MasterCard
      : name === "Paypal"
      ? Paypal
      : Bank;

  const setAsDefault = (paymentMethod) => (
    <button
      className="btn-default"
      onClick={() => setPaymentMethodForSetAsDefault(paymentMethod)}
    >
      Set As Primary
    </button>
  );

  const handleSetAsPrimaryPaymentMethod = async () => {
    setIsLoading(true);
    setPaymentMethodForSetAsDefault(null);
    const {hasSuccess} = await setAsPrimaryPaymentMethod({
      payment_source_id: paymentMethodForSetAsDefault.payment_source_id,
    });
    if (hasSuccess) {
      setIsLoading(false);
      refresh();
    } else {
      setIsLoading(false);
    }
  };

  return (
    <span>
      {isLoading ? (
        <DataLoading height="700px" />
      ) : (
        <section className="p-5 payment-methods">
          <div className="row">
            <div className="col-xl-4">
              <div className="d-block title-lg">Payment Methods</div>
              <div className="title-md pb-2">Default Payment Method</div>
              {getDefaultPaymentMethod() && (
                <PaymentCard
                  icon={getIconByName(getDefaultPaymentMethod().cardType)}
                  title={
                    "XXXX-XXXX-XXXX-" +
                    getDefaultPaymentMethod().cardNumber.slice(-4)
                  }
                  isDefault={true}
                  subTitle="Default/Primary"
                />
              )}

              <div className="mt-5 pt-4">
                <CustomButton
                  color="blue"
                  title="Add New Payment Option"
                  size="230px"
                  onClick={() => setShowModal(true)}
                />
              </div>
            </div>
            <div className="col-xl-8 pt-0">
              <div className="d-block title-md mt-5 pt-5 pb-2">
                Other Saved Methods
              </div>
              <div className="d-block d-flex flex-wrap">
                {paymentMethods.map((item, index) => (
                  <div key={index}>
                    {!item.isDefault ? (
                      <PaymentCard
                        icon={getIconByName(item.cardType)}
                        title={
                          item.cardNumber
                            ? "XXXX-XXXX-XXXX-" + item.cardNumber.slice(-4)
                            : item.email
                        }
                        isDefault={false}
                        subTitle={setAsDefault(item)}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="d-block title-lg mt-5 pt-5">
                Default Billing Address
                <small>
                  <button onClick={goToProfileSettings}>Edit</button>
                </small>
              </div>
              <div>
                {businessInformation.address},{businessInformation.city}, <br />
                {businessInformation.state},{businessInformation.zipCode},
                {businessInformation.country}
              </div>
              <div className="d-block title-lg mt-5 pt-5">
                Phone Number
                <small>
                  <button onClick={goToProfileSettings}>Edit</button>
                </small>
              </div>
              <div>
                <PhoneInput
                  value={businessInformation.phone}
                  disabled={true}
                  inputClass={"readOnlyPhoneInput"}
                  buttonClass={"readOnlyPhoneInputButton"}
                />
              </div>
            </div>
            <div className="col-xl-8 pt-1">
              <div className="d-block title-lg mt-5 pt-5">
                Subscription
                <small>
                  <button onClick={() => resetTabSelection()}>Edit</button>
                </small>
              </div>
              <p>
                {planInformation.name} Plan <br />
                Your payment currency is :
                <span className="text-primary font-weight-bold">USD</span>
              </p>
            </div>
          </div>
          {showModal && (
            <AddPaymentMethod onClose={() => setShowModal(false)} />
          )}
          {paymentMethodForSetAsDefault && (
            <ConfirmDialog
              title="Change your default payment method"
              description="Are you sure you want to change your payment method?"
              onClose={() => setPaymentMethodForSetAsDefault(null)}
              onConfirm={handleSetAsPrimaryPaymentMethod}
            ></ConfirmDialog>
          )}
        </section>
      )}
    </span>
  );
};
