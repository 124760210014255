import * as Yup from "yup";

export const BusinessInformationSchema = Yup.object().shape({
  address: Yup.string()
    .min(3, "Address is too short")
    .max(100, "Address is too long")
    .required("Address is required"),
  city: Yup.string()
    .min(2, "City is too short")
    .max(50, "City is too long")
    .required("City is required"),
  state: Yup.string()
    .min(2, "State is too short")
    .max(50, "State is too long")
    .required("State is required"),
  country: Yup.string()
    .min(2, "Country is too short")
    .max(60, "Country is too long")
    .required("Country is required"),
  zipCode: Yup.string()
    .min(2, "Zip Code is too short")
    .max(50, "Zip Code is too long")
    .required("Zip Code is required"),
  phone: Yup.string()
    .min(3, "Phone number is too short")
    .max(15, "Phone number is too long")
    .required("Phone number is required"),
});
