import styled from 'styled-components'
import * as theme from '../../../../styles/theme'

export const StyledContainer = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const StyledInnerWrapper = styled.div`
  width: 100%;
  padding: 0 20%;
`

export const StyledRadiusInput = styled.input`
  border-radius: 25px 25px 25px 25px;
  padding: 1rem 1.75rem 1rem 1.75rem;
  height: 32px !important;
  font-size: 13px;
  box-shadow: 0 4px 6px 0 rgb(167 190 255 / 37%);

  &::placeholder {
    color: #b7bdc3;
    font-family: ${theme.defaultFont};
    font-size: 13px;
  }
  &:read-only {
    background-color: #f5f5f5;
  }
`

export const StyledRadiusTextArea = styled.textarea`
  border-radius: 25px 25px 25px 25px;
  padding: 1rem 1.75rem 1rem 1.75rem;
  font-size: 13px;
  box-shadow: 0 4px 6px 0 rgb(167 190 255 / 37%);

  &::placeholder {
    color: #b7bdc3;
    font-family: ${theme.defaultFont};
    font-size: 13px;
  }
`
