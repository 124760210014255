import styled, { css } from "styled-components";

export const StyledChatCard = styled.div`
  ${({ disabled, active, type, name }) => css`
    position: relative;
    display: grid;
    grid-template-columns: 42px 1fr 70px;
    align-items: center;
    grid-gap: 6px;
    padding: 10px 16px 10px 6px;
    border-bottom: 1px solid #e9ecef;
    cursor: pointer;
    pointer-events: ${disabled ? "none" : "auto"};
    opacity: ${disabled ? 0.5 : 1};
    border-left: 4px solid ${getBorderColor(type)};
    &:hover {
      border-left: 4px solid ${getHoverBorderColor(type)};
      background-color: #f5f7fa;
    }
    &:hover::after {
      content: "${getHoverText(type, name)}";
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background-color: #fff;
      padding: 2px 6px;
      border-radius: 3px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      font-size: 12px;
      color: ${getHoverBorderColor(type)};
    }
    &:hover ${StyledMessageCount} {
      display: none;
    }
    ${active &&
    css`
      border-left: 4px solid ${getHoverBorderColor(type)};
      background-color: #f5f7fa;
    `}
  `}
`;

const getBorderColor = type => {
  switch (type) {
    case "business":
      return "#1b84e7"; // blue
    case "staff":
      return "#28a745"; // green
    case "client":
      return "#ffc107"; // yellow
    case "group":
      return "#6c757d"; // grey
    default:
      return "transparent";
  }
};

const getHoverBorderColor = type => {
  switch (type) {
    case "business":
      return "#155a9f"; // dark blue
    case "staff":
      return "#19692c"; // dark green
    case "client":
      return "#d39e00"; // dark yellow
    case "group":
      return "#5a6268"; // dark grey
    default:
      return "#1b84e7"; // blue
  }
};

const isFalsyOrEmpty = value => !value || value.length === 0;

const getHoverText = (type, name) => {
  switch (type) {
    case "business":
      return !isFalsyOrEmpty(name) ? `Business - ${name}` : "Business";
    case "staff":
      return !isFalsyOrEmpty(name) ? `Staff - ${name}` : "Staff";
    case "client":
      return !isFalsyOrEmpty(name) ? `Client - ${name}` : "Client";
    case "group":
      return !isFalsyOrEmpty(name) ? `Group - ${name}` : "Group";
    default:
      return "";
  }
};

export const StyledAvatar = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

export const StyledUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const StyledUserName = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #495057;
`;

export const StyledMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

export const StyledMessageCount = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
`;

export const StyledCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #1b84e7;
  color: #fff;
  font-weight: 600;
`;

export const StyledDate = styled.div`
  font-size: 11px;
  margin-top: 4px;
  color: #b7bdc3;
`;
