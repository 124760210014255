export const Permissions = [
  "View",
  "Create",
  "Edit",
  "Archive",
  "Delete",
  "Share",
  "Upload",
  "Import",
];

const getLevelPermissions = (levelPermissions = []) =>
  Permissions.map((permission, index) => ({
    name: permission,
    isActive: levelPermissions.includes(permission),
    order: index + 1,
  }));

const editorPermissions = ["View", "Edit", "Share"];

const viewerPermissions = ["View", "Share"];

const managerPermissions = getLevelPermissions(Permissions);

export const AccessLevels = [
  {
    _id: "manager",
    name: "Manager",
    permissions: managerPermissions,
    order: 1,
  },
  {
    _id: "editor",
    name: "Editor",
    permissions: getLevelPermissions(editorPermissions),
    order: 2,
  },
  {
    _id: "viewer",
    name: "Viewer",
    permissions: getLevelPermissions(viewerPermissions),
    order: 3,
  },
];

export const Features = [
  {
    name: "Contacts",
    level: "Manager",
    permissions: managerPermissions,
    order: 1,
  },
  {
    name: "Calendar",
    level: "Manager",
    permissions: managerPermissions,
    order: 2,
  },
  {
    name: "Services",
    level: "Manager",
    permissions: managerPermissions,
    order: 3,
  },
  {
    name: "Settings",
    level: "Manager",
    permissions: managerPermissions,
    order: 4,
  },
  {
    name: "Messages",
    level: "Manager",
    permissions: managerPermissions,
    order: 5,
  },
  {
    name: "Forms",
    level: "Manager",
    permissions: managerPermissions,
    order: 6,
  },  {
    name: "Submissions",
    level: "Manager",
    permissions: managerPermissions,
    order: 7,
  },
];
