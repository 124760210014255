import moment from "moment";

export const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

export const times = [
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM"
];

export const defaultStart = "09:00 AM";
export const defaultEnd = "09:00 AM";

const findDayIndex = day => weekDays.findIndex(weekDay => weekDay === day);

export const sorterByDay = (day1, day2) => {
  const day1Index = findDayIndex(day1);
  const day2Index = findDayIndex(day2);
  return day1Index < day2Index ? -1 : day1Index > day2Index ? 1 : 0;
};

export const getDate = date => moment(date).format("LL");
export const getOnlyDate = date => moment(date).format("YYYY-MM-DD");
export const getOnlyTime = date => moment(date).format("hh:mm A");
export const getDateTime = date => moment(date).format("YYYY-MM-DD HH:mm");
export const getFullDateTime = date =>
  moment(date).format("YYYY-MM-DD HH:mm A");

export const getYesterday = () => new Date(Date.now() - 864e5);
export const isPast = date => moment(date).isBefore();

const pad = v => {
  return v < 10 ? "0" + v : v;
};

const getDateString = d => {
  let year = d.getFullYear();
  let month = pad(d.getMonth() + 1);
  let day = pad(d.getDate());
  let hour = pad(d.getHours());
  let min = pad(d.getMinutes());
  let sec = pad(d.getSeconds());
  return `${year}${month}${day}${hour}${min}${sec}`;
};

export const getUniqueString = () => getDateString(new Date());

export const getOneWeekAgoDate = () => new Date(Date.now() - 604800000);

export const getShortDate = date => moment(date).format("MMM DD, YYYY");

export const getTime = dateTimeString => {
  const extractTime = dateTimeString => {
    const parts = dateTimeString.split(" ");
    return parts[4];
  };
  const time12And24HoursFormat = timeString => {
    const [hours, minutes, seconds] = timeString.split(":");
    const time24 = `${hours}:${minutes}:${seconds}`;
    let hours12 = parseInt(hours, 10);
    const ampm = hours12 >= 12 ? "PM" : "AM";
    hours12 = hours12 % 12;
    hours12 = hours12 ? hours12 : 12;
    const time12 = `${hours12
      .toString()
      .padStart(2, "0")}:${minutes}:${seconds} ${ampm}`;

    return { time24, time12 };
  };

  try {
    const timeAsString = extractTime(dateTimeString);
    const { time24, time12 } = time12And24HoursFormat(timeAsString);
    return `${time12} ${time24}`;
  } catch {
    return dateTimeString;
  }
};
