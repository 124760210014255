import React from "react";
import styled, { css } from "styled-components";

const StyledSvg = styled.svg`
  ${({ width, height, fill }) => css`
    width: ${width || "24px"};
    height: ${height || "24px"};
    fill: ${fill || "black"};
  `}
`;

const GenericFileIcon = ({
  width = "24px",
  height = "24px",
  margin = "0",
  border = "0",
  fill = "black"
}) => (
  <StyledSvg
    width={width}
    height={height}
    margin={margin}
    border={border}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.000000 512.000000"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={fill}
      stroke="none"
    >
      <path
        d="M1138 4835 c-205 -50 -378 -226 -429 -435 -12 -45 -14 -375 -14
      -1850 l0 -1795 27 -80 c67 -196 227 -344 419 -390 96 -22 2742 -22 2838 0 192
      46 352 194 419 390 l27 80 0 1430 0 1430 -24 60 c-22 56 -57 94 -548 587 -429
      429 -536 531 -582 555 l-56 28 -1010 2 c-834 1 -1020 -1 -1067 -12z m1902
      -674 c0 -331 2 -375 18 -429 34 -107 105 -187 209 -235 46 -21 61 -22 443 -27
      l395 -5 0 -1336 0 -1335 -30 -59 c-34 -66 -94 -118 -155 -135 -26 -7 -474 -9
      -1382 -8 l-1343 3 -48 28 c-26 16 -61 47 -78 70 -63 87 -59 -28 -57 1888 l3
      1745 30 59 c33 64 82 109 141 130 32 12 201 14 947 15 l907 0 0 -369z m580
      -116 l255 -255 -242 0 c-206 0 -244 2 -257 16 -14 13 -16 51 -16 255 0 131 1
      239 3 239 1 0 117 -115 257 -255z"
      />
      <path
        d="M1650 3029 c-14 -6 -36 -20 -48 -32 -78 -72 -68 -185 21 -251 28 -21
      31 -21 937 -21 906 0 909 0 937 21 98 73 98 195 0 268 -28 21 -32 21 -925 23
      -582 1 -906 -2 -922 -8z"
      />
      <path
        d="M1632 2273 c-49 -24 -75 -68 -80 -132 -5 -70 22 -115 88 -148 l44
      -23 876 0 876 0 44 23 c66 33 93 78 88 148 -5 65 -32 109 -82 133 -31 14 -124
      16 -928 16 -802 0 -896 -2 -926 -17z"
      />
      <path
        d="M1640 1528 c-66 -34 -93 -79 -88 -149 5 -65 32 -109 82 -133 30 -14
      95 -16 556 -16 440 0 525 2 550 15 62 32 99 115 81 183 -13 46 -65 99 -112
      112 -20 6 -235 10 -529 10 -494 0 -496 0 -540 -22z"
      />
    </g>
  </StyledSvg>
);

export default GenericFileIcon;
