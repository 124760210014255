import Modal from "../index";
import SuccessBlue from "../../../../../assets/images/big-success-blue.png";
export const GlobalSuccess = ({heading, onClose, message}) => {
  return (
    <Modal title={heading} width="610px" height="430px" close={() => onClose()}>
      <div className="d-flex align-items-center flex-column">
        <center>
          <div>
            <img src={SuccessBlue} alt="" />
          </div>
          <span className="h1 mt-5 text-primary-blue">
            <strong>SUCCESS!</strong>
          </span>
          <p className="mt-5">{message}</p>
          <button className="btn-blue btn-small mt-2" onClick={() => onClose()}>
            CLOSE
          </button>
        </center>
      </div>
    </Modal>
  );
};
