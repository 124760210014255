import React from "react";
import {InnerHeaderClient} from "../../components/Layout/Breadcrumb/Breadcrumb";
import Stats from "../../components/Dashboard/Client/Stats/Stats";
import Progress from "../../components/Dashboard/Client/Progress/Progress";
// import QuickFind from "../../components/Dashboard/Client/QuickFind/index";
// import FavouriteOrganisations from "../../components/Dashboard/Client/FavouriteOrganisation/index";
import Header from "../../components/Layout/Header/Header";
import "../../styles/pages/dashboard.scss";
import {GenericPageLeft} from "../../components/Layout/Shared/GenericPageLeft";

const DashboardPage = () => {
  return (
    <section className="dashboard pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <div className="right p-3">
            <section>
              <div className="row">
                <div className="col-lg-12">
                  <InnerHeaderClient />
                </div>
                <div className="col-lg-12">
                  <Stats />
                  <div className="mt-1"></div>
                  <div className="row">
                    <Progress />
                    {/* <FavouriteOrganisations /> <QuickFind /> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardPage;
