import styled from "styled-components";

const flexType = (props) => {
  let flexType = "flex-start";

  const flexTypes = {
    flexStart: "flex-start",
    center: "center",
    spaceBetween: "space-between",
    spaceAround: "space-around",
    flexEnd: "flex-end",
  };

  for (const key in flexTypes) {
    if (props[key]) {
      flexType = flexTypes[key];
    }
  }
  return `justify-content: ${flexType};`;
};

export const StyledFormsHeader = styled.header`
  ${(props) => flexType(props)}
  display: flex;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #dee2e0;
  margin-bottom: 1.5rem;
`;
