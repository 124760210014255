import * as Yup from "yup";
import {PASSWORD_REGEX} from "../../constants/core.js";

export const RegisterSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Business name is too Short!")
    .max(50, "Business name is too Long!")
    .required("Business name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password is too Short!")
    .max(50, "Password is too Long!")
    .matches(
      PASSWORD_REGEX,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    })
    .required("Confirm Password is required"),
});
