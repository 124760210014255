import React, { useState, useEffect } from "react";
import {
  HeartIconOrange,
  HeartIconBlueBorder
} from "../../../../assets/icons/icon";
import "./index.scss";
import { ShareModal } from "../../../Layout/Shared/Popups/Share";
import { DeleteModal } from "../../../Layout/Shared/Popups/Delete";
import { getDate, getOnlyTime } from "../../../../utils/date";
import { getBase64 } from "../../../../utils/base64";
import { uploadProfilePicture } from "../../../../data/Contact/Business/uploadProfilePicture";
import { ListLoader } from "../../../Layout/Shared/DataLoading";
import { CustomSelectFile } from "../../../Layout/Shared/SelectFile";
import { useHistory } from "react-router";
import { deleteById } from "../../../../data/Contact/Business/delete";
import DefaultImage from "../../../../assets/images/default-profile-picture.png";
import { share } from "../../../../data/Contact/Business/share";
import { exportToCSV } from "../../../../utils/export";
import { getById } from "../../../../data/Contact/Business/getById";

export const ContactCard = ({
  id,
  fullName,
  created_at,
  photoUrl,
  setPhotoUrl,
  isFavorite,
  setIsFavorite,
  groups,
  setGroups,
  setIsMainLoading
}) => {
  const [showShare, setShowShare] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const goToContactList = () => history.push(`/business/contact/list`);
  const [infoMessage, setInfoMessage] = useState(null);

  const handleFile = async ({ files }) => {
    setIsLoading(true);
    const file = files[0];
    const payload = {
      name: file.name,
      type: file.type,
      file: await getBase64(file),
      source: "contact-profile-picture"
    };
    const { hasSuccess, data } = await uploadProfilePicture(payload);
    hasSuccess && setPhotoUrl(data.Location);
    setIsLoading(false);
  };

  const removeGroup = id => {
    setGroups(groups.filter(group => group._id !== id));
  };

  const handleDelete = async () => {
    setIsMainLoading(true);
    const { hasSuccess } = await deleteById(id);
    if (hasSuccess) {
      setIsMainLoading(false);
      goToContactList();
    }
  };

  const onSubmitShareModal = async (values, { setSubmitting }) => {
    values.emails = values.emails.split(",");
    values.emails = values.emails.map(email => email.trim());
    const { hasSuccess } = await share(id, values);
    if (hasSuccess) {
      setShowShare(false);
      setSubmitting(false);
      setInfoMessage("The contact shared successfully.");
    }
  };

  const handleExport = async () => {
    const { data, hasSuccess } = await getById(id);
    hasSuccess && exportToCSV([data]);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setInfoMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [infoMessage]);

  return (
    <div className="contact-profile-card">
      <div className="row">
        <div className="col-12 text-center p-5 contact-profile-picture">
          <h2>
            {fullName}
            <button
              className="btn-icon"
              onClick={() => setIsFavorite(!isFavorite)}
            >
              {isFavorite ? HeartIconOrange : HeartIconBlueBorder}
            </button>
          </h2>
          <figure className="border rounded-xxl overflow-hidden">
            <img
              src={photoUrl ?? DefaultImage}
              onLoad={() =>
                setTimeout(() => {
                  setIsLoading(false);
                }, 500)
              }
              alt=""
              style={{
                display: isLoading ? "none" : "flex"
              }}
            />
            {isLoading && <ListLoader height={"213px"} />}
          </figure>
          <CustomSelectFile
            color="solid-blue"
            label="Upload Your Picture"
            size="210px"
            onChange={e => handleFile(e.target)}
            accept="image/*"
          />
        </div>
        <div className="border-top w-100">
          <div className="group-section">
            <h4>
              Group(s) <span>{groups.length}</span>
            </h4>
            <ul className="groups">
              {groups.map((group, index) => (
                <li key={index}>
                  {group.name}{" "}
                  <button onClick={() => removeGroup(group._id)}>
                    &times;
                  </button>
                </li>
              ))}
            </ul>
            <button className="btn btn-small btn-text-blue mt-4 w-100">
              Add to Group
            </button>

            <div className="action-buttons">
              <div className="d-flex justify-content-between">
                <button className="btn-small btn-blue me-3 ">Message</button>
                <button
                  className="btn-small btn-white-border-black text-primary"
                  onClick={() => setShowShare(true)}
                >
                  Share
                </button>
              </div>
              <button
                className="btn-small btn-white-border-black danger w-100 mt-5"
                onClick={() => setShowDelete(true)}
              >
                Delete
              </button>
            </div>
            <div className="created mt-5">
              <span>Date Created</span>
              <span>
                {getDate(created_at)} | {getOnlyTime(created_at)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {showShare && (
        <ShareModal
          title="Share Contact"
          onConfirm={onSubmitShareModal}
          onExport={handleExport}
          onClose={() => setShowShare(false)}
        />
      )}
      {showDelete && (
        <DeleteModal
          title="Delete Contact"
          text=" You are about to delete this client from your contact list. Deleting
          this client removes all client’s information and ends all previous
          communication between you and the client. 
          "
          buttonText="Delete"
          onClose={() => setShowDelete(false)}
          onConfirm={handleDelete}
        />
      )}
      {infoMessage && (
        <div className="notifications">
          <div className="info">
            <span>Info</span>
            <p>{infoMessage}</p>
            <button onClick={() => setInfoMessage(null)}>&times;</button>
          </div>
        </div>
      )}
    </div>
  );
};
