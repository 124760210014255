import {v4 as uuidv4} from "uuid";
import {ComponentType} from "../FormEdit/FormEditReducerTypes";

export const toolbox_data = [
  {description: "Heading", icon_name: "bi bi-card-heading"},
  {description: "Label", icon_name: "bi bi-bookmark"},
  {description: "Input Text", icon_name: "bi bi-file-text"},
  {description: "Input Number", icon_name: "bi bi-123"},
  {description: "Radio Button", icon_name: "bi bi-record-circle"},
  {description: "Checkbox", icon_name: "bi bi-check"},
  {description: "Memo", icon_name: "bi bi-arrow-down-circle"},
  {description: "Page Break", icon_name: "bi bi-file-break"},
  {description: "E-Signature", icon_name: "bi bi-pen"},
];

const itemCreateMappings = {
  Heading: createHeading,
  Label: createLabel,
  "Input Text": createInputText,
  "Input Number": createInputNumber,
  Checkbox: createCheckbox,
  "Radio Button": createRadioButton,
  Memo: createTextarea,
  "Page Break": createPageBrake,
  "E-Signature": createESignature,
};

function createHeading(length) {
  const newComponent = {
    key: "heading1",
    name: "heading something",
    title: "Add heading",
    order: length,
    type: ComponentType.HEADING,
    items: [],
    styles: {},
    valid: false,
    attributes: [
      {key: "title", value: ""},
      {key: "hover", value: "true"},
      {key: "confirmed", value: "false"},
      {key: "types", value: ["H1", "H2", "H3", "H4"]},
      {key: "selected_type", value: "H1"},
      {key: "selected_column_size", value: "1/1"},
      {key: "column_sizes", value: ["1/1", "1/2", "1/3", "1/4"]},
      {key: "alignment", value: ["left", "center", "right"]},
      {key: "selected_alignment", value: "left"},
    ],
  };
  newComponent.key = `new_heading_${uuidv4()}`;
  return newComponent;
}

function createLabel(length) {
  const newComponent = {
    key: "label1",
    name: "label something",
    title: "Add label",
    order: length,
    type: ComponentType.LABEL,
    items: [],
    styles: {},
    valid: false,
    attributes: [
      {key: "title", value: ""},
      {key: "hover", value: "true"},
      {key: "confirmed", value: "false"},
      {key: "selected_column_size", value: "1/1"},
      {key: "column_sizes", value: ["1/1", "1/2", "1/3", "1/4"]},
    ],
  };
  newComponent.key = `new_label_${uuidv4()}`;

  return newComponent;
}

function createInputText(length) {
  const newComponent = {
    key: "input_text1",
    name: "input text something",
    title: "Add Input Text Field",
    order: length,
    type: ComponentType.INPUT_TEXT,
    items: [],
    styles: {},
    valid: false,
    attributes: [
      {key: "question", value: ""},
      {key: "placeholder", value: ""},
      {key: "kpi", value: ""},
      {key: "baseline", value: ""},
      {key: "qualitative", value: ""},
      {key: "hover", value: "true"},
      {key: "confirmed", value: "false"},
      {key: "title", value: ""},
      {key: "require", value: "true"},
      {key: "require_text", value: ""},
      {key: "selected_column_size", value: "1/1"},
      {key: "column_sizes", value: ["1/1", "1/2", "1/3", "1/4"]},
    ],
  };
  newComponent.key = `new_input_text_${uuidv4()}`;

  return newComponent;
}

function createInputNumber(length) {
  const newComponent = {
    key: "input_number1",
    name: "input number something",
    title: "Add Input Number Field",
    order: length,
    type: ComponentType.INPUT_NUMBER,
    items: [],
    styles: {},
    valid: false,
    attributes: [
      {key: "question", value: ""},
      {key: "kpi", value: ""},
      {key: "baseline", value: ""},
      {key: "qualitative", value: ""},
      {key: "hover", value: "true"},
      {key: "confirmed", value: "false"},
      {key: "title", value: ""},
      {key: "require", value: "true"},
      {key: "require_text", value: ""},
      {key: "selected_column_size", value: "1/1"},
      {key: "column_sizes", value: ["1/1", "1/2", "1/3", "1/4"]},
    ],
  };
  newComponent.key = `new_input_number_${uuidv4()}`;

  return newComponent;
}

function createCheckbox(length) {
  const newComponent = {
    key: "checkbox1",
    name: "checkbox something",
    title: "Add Checkbox",
    order: length,
    type: ComponentType.CHECKBOX,
    items: [],
    styles: {},
    valid: false,
    attributes: [
      {key: "hover", value: "true"},
      {key: "confirmed", value: "false"},
      {key: "question", value: ""},
      {key: "kpi", value: ""},
      {key: "baseline", value: ""},
      {key: "qualitative", value: ""},
      {key: "title", value: ""},
      {key: "option_entry_title", value: ""},
      {key: "require", value: "true"},
      {key: "require_text", value: ""},
      {key: "comment", value: ""},
      {key: "options", value: []},
      {key: "checked", value: []},
      {key: "selected_column_size", value: "1/1"},
      {key: "column_sizes", value: ["1/1", "1/2", "1/3", "1/4"]},
    ],
  };
  newComponent.key = `new_checkbox_${uuidv4()}`;

  return newComponent;
}

function createRadioButton(length) {
  const newComponent = {
    key: "radioButton1",
    name: "radio something",
    title: "Add Radio Button",
    order: length,
    type: ComponentType.RADIOBUTTON,
    items: [],
    styles: {},
    valid: false,
    attributes: [
      {key: "hover", value: "true"},
      {key: "confirmed", value: "false"},
      {key: "question", value: ""},
      {key: "kpi", value: ""},
      {key: "baseline", value: ""},
      {key: "qualitative", value: ""},
      {key: "title", value: ""},
      {key: "option_entry_title", value: ""},
      {key: "require", value: "true"},
      {key: "require_text", value: ""},
      {key: "comment", value: ""},
      {key: "options", value: []},
      {key: "checked", value: ""},
      {key: "selected_column_size", value: "1/1"},
      {key: "column_sizes", value: ["1/1", "1/2", "1/3", "1/4"]},
    ],
  };
  newComponent.key = `new_radiobutton_${uuidv4()}`;

  return newComponent;
}

function createTextarea(length) {
  const newComponent = {
    key: "textArea1",
    name: "textarea something",
    title: "Add Memo",
    order: length,
    type: ComponentType.TEXTAREA,
    items: [],
    styles: {},
    valid: false,
    attributes: [
      {key: "hover", value: "true"},
      {key: "confirmed", value: "false"},
      {key: "question", value: ""},
      {key: "title", value: "", editorValue: ""},
      {key: "selected_column_size", value: "1/1"},
      {key: "column_sizes", value: ["1/1", "1/2", "1/3", "1/4"]},
    ],
  };
  newComponent.key = `new_textarea(_${uuidv4()})`;
  return newComponent;
}

function createPageBrake(length) {
  const newComponent = {
    key: "pageBreak1",
    name: "page break",
    title: "Add Page Break",
    order: length,
    type: ComponentType.PAGE_BREAK,
    items: [],
    styles: {},
    valid: true,
    attributes: [
      {key: "title", value: ""},
      {key: "hover", value: "true"},
      {key: "confirmed", value: "true"},
      {key: "selected_column_size", value: "1/1"},
      {key: "column_sizes", value: ["1/1", "1/2", "1/3", "1/4"]},
    ],
  };
  newComponent.key = `new_pagebreak_${uuidv4()}`;

  return newComponent;
}

export function createItem(item, length) {
  return itemCreateMappings[item](length);
}

export function createESignature(length) {
  const newComponent = {
    key: "esignature1",
    name: "esignature something",
    title: "E Signature",
    order: length,
    type: ComponentType.ESIGNATURE,
    items: [],
    styles: {},
    valid: false,
    attributes: [
      {key: "hover", value: "true"},
      {key: "confirmed", value: "false"},
      {key: "designation", value: ""},
      {key: "name", value: ""},
      {key: "policy_checked", value: "false"},
      {key: "input_mode", value: "signature"},
      {key: "base64", value: ""},
      {key: "text", value: ""},
      {key: "me_checked", value: "true"},
      {key: "others_checked", value: "false"},
      {key: "assignees_ids", value: []},
      {key: "assignees_emails", value: []},
      {key: "show_assignees_checked", value: "false"},
      {key: "selected_column_size", value: "1/1"},
      {key: "column_sizes", value: ["1/1", "1/2", "1/3", "1/4"]},
    ],
  };
  newComponent.key = `new_esignature_${uuidv4()}`;

  return newComponent;
}
