import "bootstrap/dist/js/bootstrap.min.js";
import React from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {PreviewHeading} from "../../Styles/PreviewStyles";

export function HeadingPreview({attributes}) {
  let level = getAttribute("selected_type", attributes);
  level = level.substr(level.length - 1);
  const title = getAttribute("title", attributes);
  let alignment = "";
  try {
    alignment = getAttribute("selected_alignment", attributes);
  } catch (e) {
    alignment = "left";
  }
  const HeadingTag = `h${level}`;
  return (
    <PreviewHeading textAlignment={alignment}>
      <HeadingTag>{title}</HeadingTag>
    </PreviewHeading>
  );
}

export default HeadingPreview;
