export const validateComponent = (component, validators) => {
  const validations = {}
  validators.forEach(
    ({ validationFunction, validationKey, validationMessage }) => {
      if (!validationFunction()) {
        validations[validationKey] = validationMessage
      }
    },
  )
  component.isValid = Object.keys(validations).length === 0
  component.validations = validations
}
