import {hostName} from "../../../endpoint";
import {defaultHeaders} from "../../../headers";

export const get = async () => {
  try {
    const result = await fetch(`${hostName}/business/team-system-staff/true`, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    };
  }
};
