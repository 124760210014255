const COUNTRIES = [
  {
    code: "AF",
    name: "Afghanistan",
    capital: "Kabul",
    region: "AS",
  },
  {
    code: "AX",
    name: "Åland Islands",
    capital: "Mariehamn",
    region: "EU",
  },
  {
    code: "AL",
    name: "Albania",
    capital: "Tirana",
    region: "EU",
  },
  {
    code: "DZ",
    name: "Algeria",
    capital: "Algiers",
    region: "AF",
  },
  {
    code: "AS",
    name: "American Samoa",
    capital: "Pago Pago",
    region: "OC",
  },
  {
    code: "AD",
    name: "Andorra",
    capital: "Andorra la Vella",
    region: "EU",
  },
  {
    code: "AO",
    name: "Angola",
    capital: "Luanda",
    region: "AF",
  },
  {
    code: "AI",
    name: "Anguilla",
    capital: "The Valley",
    region: "NA",
  },
  {
    code: "AG",
    name: "Antigua and Barbuda",
    capital: "Saint John's",
    region: "NA",
  },
  {
    code: "AR",
    name: "Argentina",
    capital: "Buenos Aires",
    region: "SA",
  },
  {
    code: "AM",
    name: "Armenia",
    capital: "Yerevan",
    region: "AS",
  },
  {
    code: "AW",
    name: "Aruba",
    capital: "Oranjestad",
    region: "SA",
  },
  {
    code: "AU",
    name: "Australia",
    capital: "Canberra",
    region: "OC",
  },
  {
    code: "AT",
    name: "Austria",
    capital: "Vienna",
    region: "EU",
  },
  {
    code: "AZ",
    name: "Azerbaijan",
    capital: "Baku",
    region: "AS",
  },
  {
    code: "BS",
    name: "Bahamas",
    capital: "Nassau",
    region: "NA",
  },
  {
    code: "BH",
    name: "Bahrain",
    capital: "Manama",
    region: "AS",
  },
  {
    code: "BD",
    name: "Bangladesh",
    capital: "Dhaka",
    region: "AS",
  },
  {
    code: "BB",
    name: "Barbados",
    capital: "Bridgetown",
    region: "NA",
  },
  {
    code: "BY",
    name: "Belarus",
    capital: "Minsk",
    region: "EU",
  },
  {
    code: "BE",
    name: "Belgium",
    capital: "Brussels",
    region: "EU",
  },
  {
    code: "BZ",
    name: "Belize",
    capital: "Belmopan",
    region: "NA",
  },
  {
    code: "BJ",
    name: "Benin",
    capital: "Porto-Novo",
    region: "AF",
  },
  {
    code: "BM",
    name: "Bermuda",
    capital: "Hamilton",
    region: "NA",
  },
  {
    code: "BT",
    name: "Bhutan",
    capital: "Thimphu",
    region: "AS",
  },
  {
    code: "BO",
    name: "Bolivia (Plurinational State of)",
    capital: "Sucre",
    region: "SA",
  },
  {
    code: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
    capital: "Kralendijk",
    region: "SA",
  },
  {
    code: "BA",
    name: "Bosnia and Herzegovina",
    capital: "Sarajevo",
    region: "EU",
  },
  {
    code: "BW",
    name: "Botswana",
    capital: "Gaborone",
    region: "AF",
  },
  {
    code: "BV",
    name: "Bouvet Island",
    capital: "",
    region: "AN",
  },
  {
    code: "BR",
    name: "Brazil",
    capital: "Brasília",
    region: "SA",
  },
  {
    code: "IO",
    name: "British Indian Ocean Territory",
    capital: "Diego Garcia",
    region: "AF",
  },
  {
    code: "UM",
    name: "United States Minor Outlying Islands",
    capital: "",
    region: "NA",
  },
  {
    code: "VG",
    name: "Virgin Islands (British)",
    capital: "Road Town",
    region: "NA",
  },
  {
    code: "VI",
    name: "Virgin Islands (U.S.)",
    capital: "Charlotte Amalie",
    region: "NA",
  },
  {
    code: "BN",
    name: "Brunei Darussalam",
    capital: "Bandar Seri Begawan",
    region: "AS",
  },
  {
    code: "BG",
    name: "Bulgaria",
    capital: "Sofia",
    region: "EU",
  },
  {
    code: "BF",
    name: "Burkina Faso",
    capital: "Ouagadougou",
    region: "AF",
  },
  {
    code: "BI",
    name: "Burundi",
    capital: "Bujumbura",
    region: "AF",
  },
  {
    code: "KH",
    name: "Cambodia",
    capital: "Phnom Penh",
    region: "AS",
  },
  {
    code: "CM",
    name: "Cameroon",
    capital: "Yaoundé",
    region: "AF",
  },
  {
    code: "CA",
    name: "Canada",
    capital: "Ottawa",
    region: "NA",
  },
  {
    code: "CV",
    name: "Cabo Verde",
    capital: "Praia",
    region: "AF",
  },
  {
    code: "KY",
    name: "Cayman Islands",
    capital: "George Town",
    region: "NA",
  },
  {
    code: "CF",
    name: "Central African Republic",
    capital: "Bangui",
    region: "AF",
  },
  {
    code: "TD",
    name: "Chad",
    capital: "N'Djamena",
    region: "AF",
  },
  {
    code: "CL",
    name: "Chile",
    capital: "Santiago",
    region: "SA",
  },
  {
    code: "CN",
    name: "China",
    capital: "Beijing",
    region: "AS",
  },
  {
    code: "CX",
    name: "Christmas Island",
    capital: "Flying Fish Cove",
    region: "OC",
  },
  {
    code: "CC",
    name: "Cocos (Keeling) Islands",
    capital: "West Island",
    region: "OC",
  },
  {
    code: "CO",
    name: "Colombia",
    capital: "Bogotá",
    region: "SA",
  },
  {
    code: "KM",
    name: "Comoros",
    capital: "Moroni",
    region: "AF",
  },
  {
    code: "CG",
    name: "Congo",
    capital: "Brazzaville",
    region: "AF",
  },
  {
    code: "CD",
    name: "Congo (Democratic Republic of the)",
    capital: "Kinshasa",
    region: "AF",
  },
  {
    code: "CK",
    name: "Cook Islands",
    capital: "Avarua",
    region: "OC",
  },
  {
    code: "CR",
    name: "Costa Rica",
    capital: "San José",
    region: "NA",
  },
  {
    code: "HR",
    name: "Croatia",
    capital: "Zagreb",
    region: "EU",
  },
  {
    code: "CU",
    name: "Cuba",
    capital: "Havana",
    region: "NA",
  },
  {
    code: "CW",
    name: "Curaçao",
    capital: "Willemstad",
    region: "SA",
  },
  {
    code: "CY",
    name: "Cyprus",
    capital: "Nicosia",
    region: "EU",
  },
  {
    code: "CZ",
    name: "Czech Republic",
    capital: "Prague",
    region: "EU",
  },
  {
    code: "DK",
    name: "Denmark",
    capital: "Copenhagen",
    region: "EU",
  },
  {
    code: "DJ",
    name: "Djibouti",
    capital: "Djibouti",
    region: "AF",
  },
  {
    code: "DM",
    name: "Dominica",
    capital: "Roseau",
    region: "NA",
  },
  {
    code: "DO",
    name: "Dominican Republic",
    capital: "Santo Domingo",
    region: "NA",
  },
  {
    code: "EC",
    name: "Ecuador",
    capital: "Quito",
    region: "SA",
  },
  {
    code: "EG",
    name: "Egypt",
    capital: "Cairo",
    region: "AF",
  },
  {
    code: "SV",
    name: "El Salvador",
    capital: "San Salvador",
    region: "NA",
  },
  {
    code: "GQ",
    name: "Equatorial Guinea",
    capital: "Malabo",
    region: "AF",
  },
  {
    code: "ER",
    name: "Eritrea",
    capital: "Asmara",
    region: "AF",
  },
  {
    code: "EE",
    name: "Estonia",
    capital: "Tallinn",
    region: "EU",
  },
  {
    code: "ET",
    name: "Ethiopia",
    capital: "Addis Ababa",
    region: "AF",
  },
  {
    code: "FK",
    name: "Falkland Islands (Malvinas)",
    capital: "Stanley",
    region: "SA",
  },
  {
    code: "FO",
    name: "Faroe Islands",
    capital: "Tórshavn",
    region: "EU",
  },
  {
    code: "FJ",
    name: "Fiji",
    capital: "Suva",
    region: "OC",
  },
  {
    code: "FI",
    name: "Finland",
    capital: "Helsinki",
    region: "EU",
  },
  {
    code: "FR",
    name: "France",
    capital: "Paris",
    region: "EU",
  },
  {
    code: "GF",
    name: "French Guiana",
    capital: "Cayenne",
    region: "SA",
  },
  {
    code: "PF",
    name: "French Polynesia",
    capital: "Papeetē",
    region: "OC",
  },
  {
    code: "TF",
    name: "French Southern Territories",
    capital: "Port-aux-Français",
    region: "AF",
  },
  {
    code: "GA",
    name: "Gabon",
    capital: "Libreville",
    region: "AF",
  },
  {
    code: "GM",
    name: "Gambia",
    capital: "Banjul",
    region: "AF",
  },
  {
    code: "GE",
    name: "Georgia",
    capital: "Tbilisi",
    region: "AS",
  },
  {
    code: "DE",
    name: "Germany",
    capital: "Berlin",
    region: "EU",
  },
  {
    code: "GH",
    name: "Ghana",
    capital: "Accra",
    region: "AF",
  },
  {
    code: "GI",
    name: "Gibraltar",
    capital: "Gibraltar",
    region: "EU",
  },
  {
    code: "GR",
    name: "Greece",
    capital: "Athens",
    region: "EU",
  },
  {
    code: "GL",
    name: "Greenland",
    capital: "Nuuk",
    region: "NA",
  },
  {
    code: "GD",
    name: "Grenada",
    capital: "St. George's",
    region: "NA",
  },
  {
    code: "GP",
    name: "Guadeloupe",
    capital: "Basse-Terre",
    region: "NA",
  },
  {
    code: "GU",
    name: "Guam",
    capital: "Hagåtña",
    region: "OC",
  },
  {
    code: "GT",
    name: "Guatemala",
    capital: "Guatemala City",
    region: "NA",
  },
  {
    code: "GG",
    name: "Guernsey",
    capital: "St. Peter Port",
    region: "EU",
  },
  {
    code: "GN",
    name: "Guinea",
    capital: "Conakry",
    region: "AF",
  },
  {
    code: "GW",
    name: "Guinea-Bissau",
    capital: "Bissau",
    region: "AF",
  },
  {
    code: "GY",
    name: "Guyana",
    capital: "Georgetown",
    region: "SA",
  },
  {
    code: "HT",
    name: "Haiti",
    capital: "Port-au-Prince",
    region: "Americas",
  },
  {
    code: "HM",
    name: "Heard Island and McDonald Islands",
    capital: "",
    region: "",
  },
  {
    code: "VA",
    name: "Holy See",
    capital: "Rome",
    region: "EU",
  },
  {
    code: "HN",
    name: "Honduras",
    capital: "Tegucigalpa",
    region: "NA",
  },
  {
    code: "HK",
    name: "Hong Kong",
    capital: "City of Victoria",
    region: "AS",
  },
  {
    code: "HU",
    name: "Hungary",
    capital: "Budapest",
    region: "EU",
  },
  {
    code: "IS",
    name: "Iceland",
    capital: "Reykjavík",
    region: "EU",
  },
  {
    code: "IN",
    name: "India",
    capital: "New Delhi",
    region: "AS",
  },
  {
    code: "ID",
    name: "Indonesia",
    capital: "Jakarta",
    region: "AS",
  },
  {
    code: "CI",
    name: "Côte d'Ivoire",
    capital: "Yamoussoukro",
    region: "AF",
  },
  {
    code: "IR",
    name: "Iran (Islamic Republic of)",
    capital: "Tehran",
    region: "AS",
  },
  {
    code: "IQ",
    name: "Iraq",
    capital: "Baghdad",
    region: "AS",
  },
  {
    code: "IE",
    name: "Ireland",
    capital: "Dublin",
    region: "EU",
  },
  {
    code: "IM",
    name: "Isle of Man",
    capital: "Douglas",
    region: "EU",
  },
  {
    code: "IL",
    name: "Israel",
    capital: "Jerusalem",
    region: "AS",
  },
  {
    code: "IT",
    name: "Italy",
    capital: "Rome",
    region: "EU",
  },
  {
    code: "JM",
    name: "Jamaica",
    capital: "Kingston",
    region: "NA",
  },
  {
    code: "JP",
    name: "Japan",
    capital: "Tokyo",
    region: "AS",
  },
  {
    code: "JE",
    name: "Jersey",
    capital: "Saint Helier",
    region: "EU",
  },
  {
    code: "JO",
    name: "Jordan",
    capital: "Amman",
    region: "AS",
  },
  {
    code: "KZ",
    name: "Kazakhstan",
    capital: "Astana",
    region: "AS",
  },
  {
    code: "KE",
    name: "Kenya",
    capital: "Nairobi",
    region: "AF",
  },
  {
    code: "KI",
    name: "Kiribati",
    capital: "South Tarawa",
    region: "OC",
  },
  {
    code: "KW",
    name: "Kuwait",
    capital: "Kuwait City",
    region: "AS",
  },
  {
    code: "KG",
    name: "Kyrgyzstan",
    capital: "Bishkek",
    region: "AS",
  },
  {
    code: "LA",
    name: "Lao People's Democratic Republic",
    capital: "Vientiane",
    region: "AS",
  },
  {
    code: "LV",
    name: "Latvia",
    capital: "Riga",
    region: "EU",
  },
  {
    code: "LB",
    name: "Lebanon",
    capital: "Beirut",
    region: "AS",
  },
  {
    code: "LS",
    name: "Lesotho",
    capital: "Maseru",
    region: "AF",
  },
  {
    code: "LR",
    name: "Liberia",
    capital: "Monrovia",
    region: "AF",
  },
  {
    code: "LY",
    name: "Libya",
    capital: "Tripoli",
    region: "AF",
  },
  {
    code: "LI",
    name: "Liechtenstein",
    capital: "Vaduz",
    region: "EU",
  },
  {
    code: "LT",
    name: "Lithuania",
    capital: "Vilnius",
    region: "EU",
  },
  {
    code: "LU",
    name: "Luxembourg",
    capital: "Luxembourg",
    region: "EU",
  },
  {
    code: "MO",
    name: "Macao",
    capital: "",
    region: "AS",
  },
  {
    code: "MK",
    name: "Macedonia (the former Yugoslav Republic of)",
    capital: "Skopje",
    region: "EU",
  },
  {
    code: "MG",
    name: "Madagascar",
    capital: "Antananarivo",
    region: "AF",
  },
  {
    code: "MW",
    name: "Malawi",
    capital: "Lilongwe",
    region: "AF",
  },
  {
    code: "MY",
    name: "Malaysia",
    capital: "Kuala Lumpur",
    region: "AS",
  },
  {
    code: "MV",
    name: "Maldives",
    capital: "Malé",
    region: "AS",
  },
  {
    code: "ML",
    name: "Mali",
    capital: "Bamako",
    region: "AF",
  },
  {
    code: "MT",
    name: "Malta",
    capital: "Valletta",
    region: "EU",
  },
  {
    code: "MH",
    name: "Marshall Islands",
    capital: "Majuro",
    region: "OC",
  },
  {
    code: "MQ",
    name: "Martinique",
    capital: "Fort-de-France",
    region: "Americas",
  },
  {
    code: "MR",
    name: "Mauritania",
    capital: "Nouakchott",
    region: "AF",
  },
  {
    code: "MU",
    name: "Mauritius",
    capital: "Port Louis",
    region: "AF",
  },
  {
    code: "YT",
    name: "Mayotte",
    capital: "Mamoudzou",
    region: "AF",
  },
  {
    code: "MX",
    name: "Mexico",
    capital: "Mexico City",
    region: "NA",
  },
  {
    code: "FM",
    name: "Micronesia (Federated States of)",
    capital: "Palikir",
    region: "OC",
  },
  {
    code: "MD",
    name: "Moldova (Republic of)",
    capital: "Chișinău",
    region: "EU",
  },
  {
    code: "MC",
    name: "Monaco",
    capital: "Monaco",
    region: "EU",
  },
  {
    code: "MN",
    name: "Mongolia",
    capital: "Ulan Bator",
    region: "AS",
  },
  {
    code: "ME",
    name: "Montenegro",
    capital: "Podgorica",
    region: "EU",
  },
  {
    code: "MS",
    name: "Montserrat",
    capital: "Plymouth",
    region: "NA",
  },
  {
    code: "MA",
    name: "Morocco",
    capital: "Rabat",
    region: "AF",
  },
  {
    code: "MZ",
    name: "Mozambique",
    capital: "Maputo",
    region: "AF",
  },
  {
    code: "MM",
    name: "Myanmar",
    capital: "Naypyidaw",
    region: "AS",
  },
  {
    code: "NA",
    name: "Namibia",
    capital: "Windhoek",
    region: "AF",
  },
  {
    code: "NR",
    name: "Nauru",
    capital: "Yaren",
    region: "OC",
  },
  {
    code: "NP",
    name: "Nepal",
    capital: "Kathmandu",
    region: "AS",
  },
  {
    code: "NL",
    name: "Netherlands",
    capital: "Amsterdam",
    region: "EU",
  },
  {
    code: "NC",
    name: "New Caledonia",
    capital: "Nouméa",
    region: "OC",
  },
  {
    code: "NZ",
    name: "New Zealand",
    capital: "Wellington",
    region: "OC",
  },
  {
    code: "NI",
    name: "Nicaragua",
    capital: "Managua",
    region: "NA",
  },
  {
    code: "NE",
    name: "Niger",
    capital: "Niamey",
    region: "AF",
  },
  {
    code: "NG",
    name: "Nigeria",
    capital: "Abuja",
    region: "AF",
  },
  {
    code: "NU",
    name: "Niue",
    capital: "Alofi",
    region: "OC",
  },
  {
    code: "NF",
    name: "Norfolk Island",
    capital: "Kingston",
    region: "OC",
  },
  {
    code: "KP",
    name: "Korea (Democratic People's Republic of)",
    capital: "Pyongyang",
    region: "AS",
  },
  {
    code: "MP",
    name: "Northern Mariana Islands",
    capital: "Saipan",
    region: "OC",
  },
  {
    code: "NO",
    name: "Norway",
    capital: "Oslo",
    region: "EU",
  },
  {
    code: "OM",
    name: "Oman",
    capital: "Muscat",
    region: "AS",
  },
  {
    code: "PK",
    name: "Pakistan",
    capital: "Islamabad",
    region: "AS",
  },
  {
    code: "PW",
    name: "Palau",
    capital: "Ngerulmud",
    region: "OC",
  },
  {
    code: "PS",
    name: "Palestine, State of",
    capital: "Ramallah",
    region: "AS",
  },
  {
    code: "PA",
    name: "Panama",
    capital: "Panama City",
    region: "NA",
  },
  {
    code: "PG",
    name: "Papua New Guinea",
    capital: "Port Moresby",
    region: "OC",
  },
  {
    code: "PY",
    name: "Paraguay",
    capital: "Asunción",
    region: "SA",
  },
  {
    code: "PE",
    name: "Peru",
    capital: "Lima",
    region: "SA",
  },
  {
    code: "PH",
    name: "Philippines",
    capital: "Manila",
    region: "AS",
  },
  {
    code: "PN",
    name: "Pitcairn",
    capital: "Adamstown",
    region: "OC",
  },
  {
    code: "PL",
    name: "Poland",
    capital: "Warsaw",
    region: "EU",
  },
  {
    code: "PT",
    name: "Portugal",
    capital: "Lisbon",
    region: "EU",
  },
  {
    code: "PR",
    name: "Puerto Rico",
    capital: "San Juan",
    region: "NA",
  },
  {
    code: "QA",
    name: "Qatar",
    capital: "Doha",
    region: "AS",
  },
  {
    code: "XK",
    name: "Republic of Kosovo",
    capital: "Pristina",
    region: "EU",
  },
  {
    code: "RE",
    name: "Réunion",
    capital: "Saint-Denis",
    region: "AF",
  },
  {
    code: "RO",
    name: "Romania",
    capital: "Bucharest",
    region: "EU",
  },
  {
    code: "RU",
    name: "Russian Federation",
    capital: "Moscow",
    region: "EU",
  },
  {
    code: "RW",
    name: "Rwanda",
    capital: "Kigali",
    region: "AF",
  },
  {
    code: "BL",
    name: "Saint Barthélemy",
    capital: "Gustavia",
    region: "NA",
  },
  {
    code: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    capital: "Jamestown",
    region: "AF",
  },
  {
    code: "KN",
    name: "Saint Kitts and Nevis",
    capital: "Basseterre",
    region: "NA",
  },
  {
    code: "LC",
    name: "Saint Lucia",
    capital: "Castries",
    region: "NA",
  },
  {
    code: "MF",
    name: "Saint Martin (French part)",
    capital: "Marigot",
    region: "NA",
  },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon",
    capital: "Saint-Pierre",
    region: "NA",
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    capital: "Kingstown",
    region: "NA",
  },
  {
    code: "WS",
    name: "Samoa",
    capital: "Apia",
    region: "OC",
  },
  {
    code: "SM",
    name: "San Marino",
    capital: "City of San Marino",
    region: "EU",
  },
  {
    code: "ST",
    name: "Sao Tome and Principe",
    capital: "São Tomé",
    region: "AF",
  },
  {
    code: "SA",
    name: "Saudi Arabia",
    capital: "Riyadh",
    region: "AS",
  },
  {
    code: "SN",
    name: "Senegal",
    capital: "Dakar",
    region: "AF",
  },
  {
    code: "RS",
    name: "Serbia",
    capital: "Belgrade",
    region: "EU",
  },
  {
    code: "SC",
    name: "Seychelles",
    capital: "Victoria",
    region: "AF",
  },
  {
    code: "SL",
    name: "Sierra Leone",
    capital: "Freetown",
    region: "AF",
  },
  {
    code: "SG",
    name: "Singapore",
    capital: "Singapore",
    region: "AS",
  },
  {
    code: "SX",
    name: "Sint Maarten (Dutch part)",
    capital: "Philipsburg",
    region: "Americas",
  },
  {
    code: "SK",
    name: "Slovakia",
    capital: "Bratislava",
    region: "EU",
  },
  {
    code: "SI",
    name: "Slovenia",
    capital: "Ljubljana",
    region: "EU",
  },
  {
    code: "SB",
    name: "Solomon Islands",
    capital: "Honiara",
    region: "OC",
  },
  {
    code: "SO",
    name: "Somalia",
    capital: "Mogadishu",
    region: "AF",
  },
  {
    code: "ZA",
    name: "South Africa",
    capital: "Pretoria",
    region: "AF",
  },
  {
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
    capital: "King Edward Point",
    region: "NA",
  },
  {
    code: "KR",
    name: "Korea (Republic of)",
    capital: "Seoul",
    region: "AS",
  },
  {
    code: "SS",
    name: "South Sudan",
    capital: "Juba",
    region: "AF",
  },
  {
    code: "ES",
    name: "Spain",
    capital: "Madrid",
    region: "EU",
  },
  {
    code: "LK",
    name: "Sri Lanka",
    capital: "Colombo",
    region: "AS",
  },
  {
    code: "SD",
    name: "Sudan",
    capital: "Khartoum",
    region: "AF",
  },
  {
    code: "SR",
    name: "Suriname",
    capital: "Paramaribo",
    region: "SA",
  },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    capital: "Longyearbyen",
    region: "EU",
  },
  {
    code: "SZ",
    name: "Swaziland",
    capital: "Lobamba",
    region: "AF",
  },
  {
    code: "SE",
    name: "Sweden",
    capital: "Stockholm",
    region: "EU",
  },
  {
    code: "CH",
    name: "Switzerland",
    capital: "Bern",
    region: "EU",
  },
  {
    code: "SY",
    name: "Syrian Arab Republic",
    capital: "Damascus",
    region: "AS",
  },
  {
    code: "TW",
    name: "Taiwan",
    capital: "Taipei",
    region: "AS",
  },
  {
    code: "TJ",
    name: "Tajikistan",
    capital: "Dushanbe",
    region: "AS",
  },
  {
    code: "TZ",
    name: "Tanzania, United Republic of",
    capital: "Dodoma",
    region: "AF",
  },
  {
    code: "TH",
    name: "Thailand",
    capital: "Bangkok",
    region: "AS",
  },
  {
    code: "TL",
    name: "Timor-Leste",
    capital: "Dili",
    region: "AS",
  },
  {
    code: "TG",
    name: "Togo",
    capital: "Lomé",
    region: "AF",
  },
  {
    code: "TK",
    name: "Tokelau",
    capital: "Fakaofo",
    region: "OC",
  },
  {
    code: "TO",
    name: "Tonga",
    capital: "Nuku'alofa",
    region: "OC",
  },
  {
    code: "TT",
    name: "Trinidad and Tobago",
    capital: "Port of Spain",
    region: "SA",
  },
  {
    code: "TN",
    name: "Tunisia",
    capital: "Tunis",
    region: "AF",
  },
  {
    code: "TR",
    name: "Turkey",
    capital: "Ankara",
    region: "AS",
  },
  {
    code: "TM",
    name: "Turkmenistan",
    capital: "Ashgabat",
    region: "AS",
  },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
    capital: "Cockburn Town",
    region: "NA",
  },
  {
    code: "TV",
    name: "Tuvalu",
    capital: "Funafuti",
    region: "OC",
  },
  {
    code: "UG",
    name: "Uganda",
    capital: "Kampala",
    region: "AF",
  },
  {
    code: "UA",
    name: "Ukraine",
    capital: "Kiev",
    region: "EU",
  },
  {
    code: "AE",
    name: "United Arab Emirates",
    capital: "Abu Dhabi",
    region: "AS",
  },
  {
    code: "GB",
    name: "United Kingdom of Great Britain and Northern Ireland",
    capital: "London",
    region: "EU",
  },
  {
    code: "US",
    name: "United States of America",
    capital: "Washington, D.C.",
    region: "NA",
  },
  {
    code: "UY",
    name: "Uruguay",
    capital: "Montevideo",
    region: "SA",
  },
  {
    code: "UZ",
    name: "Uzbekistan",
    capital: "Tashkent",
    region: "AS",
  },
  {
    code: "VU",
    name: "Vanuatu",
    capital: "Port Vila",
    region: "OC",
  },
  {
    code: "VE",
    name: "Venezuela (Bolivarian Republic of)",
    capital: "Caracas",
    region: "SA",
  },
  {
    code: "VN",
    name: "Viet Nam",
    capital: "Hanoi",
    region: "AS",
  },
  {
    code: "WF",
    name: "Wallis and Futuna",
    capital: "Mata-Utu",
    region: "OC",
  },
  {
    code: "EH",
    name: "Western Sahara",
    capital: "El Aaiún",
    region: "AF",
  },
  {
    code: "YE",
    name: "Yemen",
    capital: "Sana'a",
    region: "AS",
  },
  {
    code: "ZM",
    name: "Zambia",
    capital: "Lusaka",
    region: "AF",
  },
  {
    code: "ZW",
    name: "Zimbabwe",
    capital: "Harare",
    region: "AF",
  },
];

export default COUNTRIES;

export const getCountryNameByCountryCode = (code) =>
  COUNTRIES.find((country) => country.code === code)?.name || "";

export const CODE_USA = "US";
export const CODE_CANADA = "CA";
export const FILTERED_COUNTRIES_CODES = [CODE_USA, CODE_CANADA];
