import React, { useState } from "react";
import "./Header.scss";
import { Bell, ArrowDown } from "../../../assets/icons/icon";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../providers/global";
import defaultLogo from "../../../assets/images/default-business-image.png";
import Search from "./Search/search.component";
import { businessMenuItems, clientMenuItems } from "../Sidebar/menuItems";
import { isClient } from "../../../utils/account";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();
  const [searchArray, setSearchArray] = useState([]);
  const { currentUser, logout } = useGlobalContext();
  const { role, email, businessName, businessLogo } = currentUser;

  const handleLogout = () => {
    logout();
    history.push("/login");
  };

  const searchForItems = (value, items) => {
    const searchData = [];
    items.forEach(item => {
      searchData.push({ name: item.name, link: item.link });
      item.subItems?.forEach(subItem => {
        searchData.push({ name: subItem.name, link: subItem.link });
      });
    });

    const result = searchData.filter(item =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    return result;
  };

  return (
    <div className="container-fluid dashboard-header">
      <div className="grid-item">
        <h3 className="businessName">
          <Link to="/business/dashboard">{businessName}</Link>
        </h3>
      </div>

      <div className="grid-item ">
        <input
          type="text"
          className="custom-input"
          placeholder="Search here"
          id="searchValue"
          onChange={e => {
            if (e.target.value === "") {
              setSearchArray([]);
            } else {
              if (role === "client") {
                setSearchArray(searchForItems(e.target.value, clientMenuItems));
              } else {
                setSearchArray(
                  searchForItems(e.target.value, businessMenuItems)
                );
              }
            }
          }}
        />
        <Search search={searchArray} />
      </div>

      <div className="grid-item">
        <ul>
          <li className="bell">
            {Bell}
            <span></span>
          </li>
          <li className="avatar">
            <img src={businessLogo ?? defaultLogo} alt={email} width="37px" />
            <span className="status"></span>
          </li>
          <li className="nav">
            <span>
              {showMenu === false ? (
                <button
                  className="btn btn-link"
                  onClick={() => setShowMenu(true)}
                >
                  <span className="userName">{email}</span> {ArrowDown}
                </button>
              ) : (
                <button
                  className="btn btn-link"
                  onClick={() => setShowMenu(false)}
                >
                  <span className="userName">{email}</span> {ArrowDown}
                </button>
              )}
            </span>
            {showMenu === true ? (
              <div className="sub-menu show">
                <Link to={`${isClient(currentUser) ? "/client/settings/profile" : "/business/settings/profile"}`}>Admin Settings</Link>
                <button
                  className="btn btn-link p-0 pt-2 border-bottom-0"
                  onClick={() => handleLogout()}
                >
                  Logout
                </button>
              </div>
            ) : null}
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Header;
