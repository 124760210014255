import React from 'react';
import { CustomButton } from "../../../../../Layout/Shared/Button";
import { FormList } from "../../../../Shared/Forms";
import { StyledDiv, StyledHead } from './formStyle'

export const ServiceForms = ({ forms }) => {
  const ActionButton = ({ row }) => {
    return (
      <CustomButton
        title='View'
        size="90px"
        target="_blank"
        color="blue"
        onClick={() => window.open(`/preview/${row.id}`, "_blank")}
      />
    );
  };

  const fields = ["id", "name", "lastModified", { render: ActionButton }];
  const headers = [
    {
      displayName: "Id",
      sortingFieldName: "id",
      sortingOptions: { dataType: "number" },
    },
    {
      displayName: "Form Name",
      sortingFieldName: "name",
    },
    {
      displayName: "Date Modified",
      sortingFieldName: "lastModified",
    },
    {
      displayName: "",
      sortingFieldName: "action",
    },
  ];

  const options = {
    data: forms || [],
    headers,
    fields,
    itemsPerPage: 25,
    pagination: true,
    search: false,
    sort: true,
  };

  return (
    <StyledDiv>
      <StyledHead>Connected Forms</StyledHead>
      <FormList fields={fields} headers={headers} options={options} />
    </StyledDiv>
  );
};


