import React from "react";
import { useChatContext } from "../../../../../../providers/Chat";
import { useGlobalContext } from "../../../../../../providers/global";
import { getShortDate } from "../../../../../../utils/date";
import {
  StyledChatCard,
  StyledAvatar,
  StyledUserInfo,
  StyledUserName,
  StyledMessage,
  StyledMessageCount,
  StyledCount,
  StyledDate
} from "./ChatCard.style";

const truncateFileName = (name, maxLength = 16) => {
  if (name.length > maxLength) {
    const extension = name.split(".").pop();
    return `${name.substring(
      0,
      maxLength - extension.length - 1
    )}...${extension}`;
  }
  return name;
};

const defaultAvatar = "https://www.w3schools.com/howto/img_avatar.png";

const ChatCard = ({ chat }) => {
  const {
    createdAt,
    messages = [],
    participants = [],
    unSeenCount,
    groupName,
    groupProfilePicture
  } = chat;
  const { setSelectedChat, selectedChat } = useChatContext();
  const { currentUser } = useGlobalContext();

  const lastMessage = messages[messages.length - 1];
  const isGroupChat = participants.length > 2;

  const otherParticipant =
    !isGroupChat && participants.find(p => p.id !== currentUser.id);
  const otherParticipants =
    isGroupChat && participants.filter(p => p.id !== currentUser.id);

  const name = !isGroupChat
    ? otherParticipant?.name ||
      otherParticipant?.businessName ||
      otherParticipant?.email
    : groupName ||
      otherParticipants
        .map(p => p?.name || p?.businessName || p?.email)
        .join(", ");

  const profileImage = !isGroupChat
    ? otherParticipant?.image || defaultAvatar
    : groupProfilePicture || defaultAvatar;

  const type = !isGroupChat ? otherParticipant?.type : "group";

  const businessName = !isGroupChat
    ? otherParticipant?.businessName ||
      otherParticipant?.name ||
      otherParticipant?.email
    : otherParticipants
        .map(p => p?.businessName || p?.name || p?.email)
        .join(", ");

  const handleOnClick = () => setSelectedChat(chat);

  return (
    <StyledChatCard
      onClick={handleOnClick}
      active={chat.id === selectedChat?.id}
      type={type}
      name={businessName}
    >
      <StyledAvatar src={profileImage} />
      <StyledUserInfo>
        <StyledUserName>{name}</StyledUserName>
        {lastMessage && (
          <StyledMessage>
            {lastMessage.message.type === "text"
              ? lastMessage.message.content
              : truncateFileName(lastMessage.message.content.name)}
          </StyledMessage>
        )}
      </StyledUserInfo>
      <>
        <StyledMessageCount>
          {unSeenCount > 0 && <StyledCount> {unSeenCount}</StyledCount>}
          <StyledDate>{getShortDate(createdAt)}</StyledDate>
        </StyledMessageCount>
      </>
    </StyledChatCard>
  );
};

export default ChatCard;
