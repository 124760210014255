import React, {Fragment} from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import PageBreakEdit from "./PageBreakEdit";
import PageBreakHover from "./PageBreakHover";

export function PageBreak({dispatch, attributes, component_key}) {
  const confirmed = getAttribute("confirmed", attributes);
  return (
    <Fragment>
      {confirmed === "true" ? (
        <PageBreakHover
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      ) : (
        <PageBreakEdit
          component_key={component_key}
          attributes={attributes}
          dispatch={dispatch}
        />
      )}
    </Fragment>
  );
}

export default PageBreak;
