/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import Header from "../../../components/Layout/Header/Header";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import {TabContainer} from "../../../components/Services/Business/View/TabContainer";
import {useParams, useHistory} from "react-router-dom";
import BusinessServiceContext from "../../../providers/Service/business";
import {getServiceById} from "../../../data/Service/Business/getServiceById";
import DataLoading from "../../../components/Layout/Shared/DataLoading";
import "./createService.scss";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {ButtonAnalytics} from "../../../components/Layout/Shared/Button/Analytics";
import {CustomButton} from "../../../components/Layout/Shared/Button";
import {LastUpdate} from "../../../components/Layout/Shared/LastUpdate";

const ViewServicePage = () => {
  const history = useHistory();
  const {serviceId} = useParams();

  const goToServiceList = () => history.push("/business/service/list");
  const goToEditPage = () =>
    history.push(`/business/service/update/${serviceId}`);

  const [service, setService] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      const {data, hasSuccess} = await getServiceById(serviceId);
      hasSuccess ? setService(data) : goToServiceList();
      setService(hasSuccess ? data : {});
      setIsLoading(false);
    };
    init();
  }, []);

  return (
    <section className="dashboard  bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>{service && service.name} </PageTitle>
              <div className="d-flex justify-content-end align-items-center">
                {service && <LastUpdate date={service.updated_at} />}
                <CustomButton
                  color="transparent"
                  title="Edit Service"
                  size="150px"
                  onClick={goToEditPage}
                />
                <ButtonAnalytics color="red" />
              </div>
            </PageHeader>
            {isLoading ? (
              <DataLoading height="400px" />
            ) : (
              <BusinessServiceContext.Provider value={{service, setService}}>
                <TabContainer />
              </BusinessServiceContext.Provider>
            )}
          </PageContainer>
        </div>
      </div>
    </section>
  );
};
export default ViewServicePage;
