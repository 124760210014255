import React, { useState } from "react";
import {
  DEFAULT_SELECTION_VALUE,
  isEmptyString
} from "../../../../utils/string";
import "./index.scss";

export const Autocomplete = ({
  options,
  name,
  className,
  placeholder,
  defaultValue,
  onSelect,
  hidden = false,
  disabled
}) => {
  const [activeOption, setActiveOption] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [userInput, setUserInput] = useState(defaultValue || "");

  const handleOnChange = ({ currentTarget }) => {
    const { value } = currentTarget;

    const filteredOptions = options.filter(
      option => option.text.toLowerCase().indexOf(value.toLowerCase()) > -1
    );

    setActiveOption(0);
    setFilteredOptions(filteredOptions);
    setShowOptions(true);
    setUserInput(value);

    if (isEmptyString(value))
      onSelect({ id: DEFAULT_SELECTION_VALUE, text: "" });
  };

  const handleOnClick = option => {
    setActiveOption(0);
    setFilteredOptions([]);
    setShowOptions(false);
    setUserInput(option.text);
    onSelect(option);
  };

  const handleOnKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      setActiveOption(0);
      setShowOptions(false);
      setUserInput(filteredOptions[activeOption].text);
      onSelect(filteredOptions[activeOption]);
    } else if (keyCode === 38) {
      if (activeOption === 0) return;
      setActiveOption(activeOption - 1);
    } else if (keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) return;
      setActiveOption(activeOption + 1);
    }
  };

  let optionListPartial;
  if (showOptions && userInput) {
    optionListPartial = filteredOptions.length ? (
      <ul className="options">
        {filteredOptions.map((option, index) => {
          const className = index === activeOption ? "option-active" : "";
          return (
            <li
              key={index}
              data-id={option.id}
              data-text={option.text}
              className={className}
              onClick={() => handleOnClick(option)}
            >
              {option.text}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-options">No Option!</div>
    );
  }
  return (
    <React.Fragment>
      <div className="search-auto-complete w-100 me-2">
        <input
          name={name}
          placeholder={placeholder}
          className={className}
          type="text"
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          value={userInput}
          hidden={hidden}
          disabled={disabled}
        />
        {optionListPartial}
      </div>
    </React.Fragment>
  );
};
