import * as Yup from "yup";
import { DEFAULT_SELECTION_VALUE } from "../../../utils/string";

const CreateBusinessServiceSchema = Yup.object().shape({
  name: Yup.string()
    .required("Service name is required")
    .min(3, "Service name is too Short!")
    .max(50, "Services name is too Long!"),
  owner: Yup.string()
    .required("Service owner is required")
    .min(3, "Service owner is too Short!")
    .max(50, "Service owner is too Long!"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .min(9, "Phone number is too short")
    .max(15, "Phone number is too long"),
  industry: Yup.string()
    .notOneOf([DEFAULT_SELECTION_VALUE], "Please select Services Category!")
    .required("Services Category is required"),
  type: Yup.string()
    .notOneOf([DEFAULT_SELECTION_VALUE], "Please select Services Type!")
    .required("Services Type is required")
});

export default CreateBusinessServiceSchema;
