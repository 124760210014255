import React, {useState, useEffect} from "react";
import {ListLoader} from "../../../../../Layout/Shared/DataLoading";
import {CustomButton} from "../../../../../Layout/Shared/Button";
import {get as getAccessLevel} from "../../../../../../data/Settings/Business/Team/getAccessLevel";
import {save as saveAccessLevel} from "../../../../../../data/Settings/Business/Team/saveAccessLevel";
import {AccessLevels} from "../../../../../../static/Settings/Team/business";
import {useDebounce} from "../../../../../../utils/function";
import {order} from "../../../../../../utils/array";

export const SystemDefaultAccessLevels = ({refresh}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFirst, setIsFirst] = useState(false);
  const [accessLevels, setAccessLevels] = useState([]);
  const [save, setSave] = useState(false);
  const [infoMessage, setInfoMessage] = useState(null);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await getAccessLevel();
      if (hasSuccess) {
        const dataAsArray = Object.values(data);
        dataAsArray.length > 0
          ? setAccessLevels(dataAsArray)
          : setIsFirst(true);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const Permissions = ({row}) => (
    <span>
      {row.permissions
        .filter((permission) => permission.isActive)
        .sort(order)
        .map((permission) => permission.name)
        .join(", ")}
    </span>
  );

  const debouncedSave = useDebounce(save, 1400);

  useEffect(() => {
    if (debouncedSave && save) {
      setSave(false);
      const run = async () => {
        accessLevels.forEach((accessLevel) => {
          // remove editMode
          delete accessLevel.editMode;
        });
        const {hasSuccess} = await saveAccessLevel(accessLevels);
        hasSuccess &&
          setInfoMessage("The changes have been successfully saved.");
      };
      run();
    }
  }, [debouncedSave, save, accessLevels]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setInfoMessage(null);
    }, 4000);
    return () => {
      clearTimeout(timeId);
    };
  }, [infoMessage]);

  const handleFirstCreate = async () => {
    setIsLoading(true);
    const accessLevels = [...AccessLevels];
    accessLevels.forEach((accessLevel) => {
      // delete _id
      delete accessLevel._id;
    });
    const {hasSuccess} = await saveAccessLevel(accessLevels);
    hasSuccess && refresh();
  };

  return (
    <>
      {isLoading ? (
        <ListLoader height="275px" />
      ) : isFirst ? (
        <div className="pt-5 d-flex justify-content-center align-items-center">
          <CustomButton
            title="Initialize Access Levels "
            onClick={handleFirstCreate}
            size="280px"
            color="solid-blue"
          />
        </div>
      ) : (
        <section className="p-5 roles-list-table">
          <div className="table-responsive">
            <table className="custom-table mt-3">
              <thead>
                <tr>
                  <th style={{verticalAlign: "bottom"}}>
                    <span>System Default Access Levels</span>
                    <br />
                  </th>
                  <th style={{verticalAlign: "bottom"}}>
                    <span>Permissions</span>
                    <br />
                  </th>
                  <th style={{verticalAlign: "bottom"}}></th>
                </tr>
              </thead>
              <tbody>
                {accessLevels.sort(order).map((accessLevel, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        <span
                          className={
                            accessLevel.name === "Manager" ? "manager" : ""
                          }
                        >
                          {accessLevel.name}
                        </span>
                      </td>
                      <td>
                        <Permissions row={accessLevel} />
                      </td>
                      <td></td>
                    </tr>
                  </>
                ))}
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
        </section>
      )}
      {infoMessage && (
        <div className="notifications">
          <div className="info">
            <span>Info</span>
            <p>{infoMessage}</p>
            <button onClick={() => setInfoMessage(null)}>&times;</button>
          </div>
        </div>
      )}
    </>
  );
};
