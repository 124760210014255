import styled from "styled-components";
import * as theme from "../../../../../styles/theme";

export const StyledContainer = styled.div`
  background: ${theme.azul_opacity};
  height: ${(props) => props.height};
  padding: 1em 0 2em 0;
`;

export const StyledTitleText = styled.div`
  font-family: ${theme.defaultFont};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: ${theme.azul};
`;

export const StyledInput = styled(theme.IradarInput)`
  border: solid 1px #1b84e7;
  background: transparent;
  margin-top: 1em;
`;