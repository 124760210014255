import { clientApiHostName } from "../../endpoint";
import { defaultHeaders } from "../../headers";

export const invite = async body => {
  try {
    const result = await fetch(`${clientApiHostName}/invite`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify(body)
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
