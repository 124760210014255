import { validateComponent } from '../../../../utils'

const radioButtonValidator = (component) => {
  const { options } = component

  const validators = [
    {
      validationFunction: () => options.value.trim().length > 0,
      validationKey: 'value',
      validationMessage: 'Please enter your question!',
    },
    {
      validationFunction: () =>
        options.optionItems.filter((item) => item.trim().length > 0).length >=
        2,
      validationKey: 'optionItems',
      validationMessage: 'Please add minimum 2 options!',
    },
  ]

  validateComponent(component, validators)

  return component.isValid
}

export default radioButtonValidator
