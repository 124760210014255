import React from "react";
import Table from "../../../Layout/Shared/Table";
import { FormattedDate } from "../../../Layout/Shared/FormattedDate";
import { ActionButtons } from "./actions";
import "./index.scss";
import { StatusBadge, PriorityBadge, DisplayNo } from "../../Shared";
import { getFullNameFromObject } from "../../../../utils/string";

const CaseList = ({ cases = [], refresh }) => {
  const fields = [
    {
      render: ({ row }) => <DisplayNo no={row.no} />
    },
    {
      render: ({ row }) => <>{getFullNameFromObject(row.client)}</>
    },
    "title",
    {
      render: ({ row }) => <>{row.type.title}</>
    },
    {
      render: ({ row }) => <StatusBadge status={row.status} />
    },
    {
      render: ({ row }) => <>{row.stage.title}</>
    },
    {
      render: ({ row }) => <PriorityBadge priority={row.priority} />
    },
    {
      render: ({ row }) => <>{getFullNameFromObject(row.assignedTo)}</>
    },
    { render: ({ row }) => <FormattedDate date={row.startDate} /> },
    { render: ({ row }) => <FormattedDate date={row.dueDate} /> },
    {
      render: ({ row }) => <ActionButtons stage={row} refresh={refresh} />
    }
  ];

  const headers = [
    {
      displayName: "Case No.",
      sortingFieldName: "no"
    },
    {
      displayName: "Client Name",
      sortingFieldName: "client.firstName"
    },
    {
      displayName: "Case Title",
      sortingFieldName: "title"
    },
    {
      displayName: "Type",
      sortingFieldName: "type.title"
    },
    {
      displayName: "Status",
      sortingFieldName: "status"
    },
    {
      displayName: "Stage",
      sortingFieldName: "stage.title"
    },
    {
      displayName: "Priority",
      sortingFieldName: "priority"
    },
    {
      displayName: "Assigned To",
      sortingFieldName: "assignedTo.firstName"
    },
    {
      displayName: "Start Date",
      sortingFieldName: "startDate",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Due Date",
      sortingFieldName: "dueDate",
      sortingOptions: { dataType: "date" }
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions"
    }
  ];

  const options = {
    data: cases,
    headers,
    fields,
    pagination: true,
    search: true,
    sort: true,
    headerAction: () => <p></p>
  };

  return (
    <div className="service-list">
      <Table options={options} />
    </div>
  );
};

export default CaseList;
