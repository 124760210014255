import Summary from "./Summary";
import Attachments from "./Attachments";
import Forms from "./Forms";
import Collaborate from "./Collaborate";
import Schedule from "./Schedule";
import CommunicationChannels from "./CommunicationChannels";

export const tabs = [
  {
    name: "Summary",
    component: Summary,
    isDefault: true,
  },
  {
    name: "Attachments",
    component: Attachments,
  },
  {
    name: "Forms",
    component: Forms,
  },
  {
    name: "Collaborate",
    component: Collaborate,
  },
  {
    name: "Schedule",
    component: Schedule,
  },
  {
    name: "Communication Channels",
    component: CommunicationChannels,
  },
];

export const defaultTab = tabs.find((tab) => tab.isDefault);
