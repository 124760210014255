import React, {useState} from "react";
import Table from "../../../../Layout/Shared/Table";
import moment from "moment";
import {CompanyNameWithLogo} from "../../../../Layout/Shared/NameWithLogo";
import {ClientServiceRating} from "../List/Rating";
import {useHistory} from "react-router-dom";
import {Feedback} from "./Feedback";
import "../List/index.scss";
const ReviewedList = () => {
  const [showFeedback, setShowFeedback] = useState(false);
  const history = useHistory();
  const goToAddReview = () => history.push(`/client/service/applied-service`);
  const services = [

  ];

  const GiveFeedBack = () => {
    return (
      <button onClick={goToAddReview} className="btn btn-x-small btn-blue">
        Give Your Feedback
      </button>
    );
  };
  const ShowFeedback = () => {
    return (
      <button
        onClick={() => setShowFeedback(true)}
        className="btn btn-link text-dark"
      >
      </button>
    );
  };
  const fields = [
    {
      render: () => <ShowFeedback />,
    },
    {
      render: () => (
        <CompanyNameWithLogo
        />
      ),
    },
    {render: () => moment().format("MMM d, YYYY")},
    "feedback",
    {
      render: () =>
        services[0].status ? (
          <ClientServiceRating rating={4} count={4.2} />
        ) : (
          <GiveFeedBack />
        ),
    },
  ];

  const headers = [
    {
      displayName: "Service Name",
      sortingFieldName: "name",
    },
    {
      displayName: "Company",
      sortingFieldName: "company",
    },
    {
      displayName: "Date",
      sortingFieldName: "date",
    },
    {
      displayName: "Feedback",
      sortingFieldName: "feedback",
    },
    {
      displayName: "Ratings",
      sortingFieldName: "ratings",
    },
  ];
  const options = {
    data: services,
    headers,
    fields,
    pagination: true,
    search: false,
    sort: true,
  };

  return (
    <div className="client-service-list rounded-xl">
      <div className="header">
        <input
          type="text"
          className="custom-input search w-50"
          placeholder="Search here"
        />
      </div>
      <div className="ps-5 pe-5 ">
        <Table options={options} />
      </div>
      {showFeedback && <Feedback onClose={() => setShowFeedback(false)} />}
    </div>
  );
};
export default ReviewedList;
