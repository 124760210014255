import styled from "styled-components";

export const StyledContainer = styled.div`
    padding: 1em 0;
`;

export const StyledPageBreak = styled.div`
    width: 100%;
    height: 1px;
    background-color: #dee2e6;
`;

