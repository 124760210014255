import React, { useEffect, useRef, useState } from "react";
import AttachmentIcon from "../../../../assets/icons/attachment";
import SendMessageIcon from "../../../../assets/icons/senMessage";
import {
  StyledSendMessageFileInputContainer,
  StyledSendMessageFileInput,
  StyledSendMessageTextInputContainer,
  StyledSendMessageTextInput,
  StyledSendMessageButton,
  StyledSendMessageWrapper
} from "./SendMessage.component.style";
import { useChatContext } from "../../../../providers/Chat";
import { useGlobalContext } from "../../../../providers/global";
import { UploadAttachment } from "../../../../data/Chat/uploadAttachment";

const SUPPORTED_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/plain",
  "application/zip",
  "application/x-rar-compressed",
  "application/x-tar",
  "text/csv"
];
const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25 MB in bytes

const Notifications = ({ notifications = [], setNotifications }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      setNotifications(prev =>
        prev.filter(notification => Date.now() - notification.id < 5000)
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [notifications, setNotifications]);

  const toTitleCase = (str = "") =>
    str
      .split(" ")
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join(" ");

  // type: info danger success warning
  return (
    <div className="notifications-container">
      {notifications.map(({ id, type, message }) => (
        <div key={id} className="notifications">
          <div className={type}>
            <span>{toTitleCase(type)}</span>
            <p>{message}</p>
            <button
              onClick={() =>
                setNotifications(prev =>
                  prev.filter(notification => notification.id !== id)
                )
              }
            >
              &times;
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

const SendMessage = () => {
  const { currentUser, accessToken } = useGlobalContext();
  const { sendTextMessage, selectedChat, sendAttachmentMessage } =
    useChatContext();

  const [textMessage, setTextMessage] = useState("");
  const [notifications, setNotifications] = useState([]);

  const sendMessage = () => {
    const trimmedTextMessage = textMessage.trim();
    if (trimmedTextMessage.length === 0) {
      return;
    }
    sendTextMessage(trimmedTextMessage);
    setTextMessage("");
  };

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, [selectedChat]);

  const fileInputRef = useRef(null);

  const sendAttachment = async ({ target }) => {
    const file = target.files[0];

    if (!file) {
      pushNotification("danger", "No file selected");
      return;
    }

    if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
      pushNotification("danger", "File type not supported");
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      pushNotification("danger", "File size should be less than 25 MB");
      return;
    }

    const { hasSuccess, data: uploadAttachment } = await UploadAttachment(
      file,
      selectedChat.id,
      currentUser.id,
      accessToken
    );

    if (!hasSuccess) {
      pushNotification("danger", "Failed to upload attachment");
      return;
    }

    pushNotification("success", "Attachment uploaded successfully");

    const attachment = {
      name: file.name,
      url: uploadAttachment.Location,
      type: file.type
    };
    sendAttachmentMessage(attachment);
  };

  const handleAttachmentClick = () => fileInputRef.current.click();

  const pushNotification = (type, message) => {
    const id = Date.now();
    setNotifications(prev => [
      ...prev,
      {
        id,
        type,
        message
      }
    ]);
  };

  return (
    <StyledSendMessageWrapper>
      <StyledSendMessageFileInputContainer>
        <StyledSendMessageFileInput
          type="file"
          ref={fileInputRef}
          onChange={sendAttachment}
        />
        <label onClick={handleAttachmentClick}>
          <AttachmentIcon />
        </label>
      </StyledSendMessageFileInputContainer>
      <StyledSendMessageTextInputContainer>
        <StyledSendMessageTextInput
          ref={inputRef}
          type="text"
          placeholder="Type here"
          value={textMessage}
          onChange={({ target }) => setTextMessage(target.value)}
          onKeyDown={({ key }) => key === "Enter" && sendMessage()}
        />
        <StyledSendMessageButton
          disabled={textMessage.trim().length === 0}
          onClick={sendMessage}
        >
          <SendMessageIcon />
        </StyledSendMessageButton>
      </StyledSendMessageTextInputContainer>
      <Notifications
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </StyledSendMessageWrapper>
  );
};

export default SendMessage;
