const eSignatureDefaultOptions = {
  value: '',
  valueType: 'drawing',
  column: '1/1',
  designation: '',
  fullName: '',
  policyAccepted: false,
  isRequired: false,
  requiredErrorMessage: '',
}

export default eSignatureDefaultOptions
