import "bootstrap/dist/js/bootstrap.min.js";
import React from "react";
import {getAttribute} from "../../FormEdit/FormEditReducerHelpers";
import {HeadingInputGroup, LeftRadiusInput} from "../Base/BaseStyles";
import Dropdown from "./Dropdown";
import DropdownAlignment from "./DropdownAlignment";

export function HeadingEdit({attributes, dispatch, component_key}) {
  const title = getAttribute("title", attributes);
  return (
    <HeadingInputGroup className="input-group">
      <LeftRadiusInput
        type="text"
        className="form-control"
        aria-label="Text input with dropdown button"
        placeholder="Add Heading"
        value={title}
        onChange={(event) =>
          dispatch({
            type: "heading_title_change",
            title: event.target.value,
            key: component_key,
          })
        }
      />
      <DropdownAlignment
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      ></DropdownAlignment>
      <Dropdown
        component_key={component_key}
        attributes={attributes}
        dispatch={dispatch}
      ></Dropdown>
    </HeadingInputGroup>
  );
}

export default HeadingEdit;
