/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { LabelBlueBold } from "../../../Layout/Shared/LabelBlueBold";
import moment from "moment";
import { times, getOnlyDate, getOnlyTime } from "../../../../utils/date";
import ErrorField from "../../../../components/Layout/Shared/ErrorField";
import { BusinessCreateAppointmentSchema } from "../../../../schemas/Calendar/Business/appointment";
import { RightToggler } from "../../../../components/Layout/Shared/RightToggler";
import { createAppointment } from "../../../../data/Calendar/Business/createAppointment";

export const CreateAppointment = ({ date, close, onSuccess }) => {
  const [appointment, setAppointment] = useState({
    clientEmail: "",
    firstName: "",
    lastName: "",
    appointmentDate: getOnlyDate(date),
    appointmentTime: getOnlyTime(date),
    reminder: "15",
    pushNotification: true,
    consultant: {
      fullName: "",
      email: "",
      phone: "",
      url: ""
    }
  });

  useEffect(() => {
    if (!date) return;
    setAppointment({
      ...appointment,
      appointmentDate: getOnlyDate(date)
    });
    return () => {};
  }, [date]);

  const onSubmit = async values => {
    const {
      clientEmail,
      firstName,
      lastName,
      appointmentDate,
      appointmentTime,
      reminder,
      pushNotification,
      consultant
    } = values;
    const _date = moment(
      `${appointmentDate} ${appointmentTime}`,
      "YYYY-MM-DD HH:mm A"
    ).format();
    const { hasSuccess } = await createAppointment({
      id: appointment.id,
      clientEmail,
      firstName,
      lastName,
      date: _date,
      reminder,
      pushNotification,
      consultant,
      source: "app"
    });
    hasSuccess && onSuccess();
    close();
  };

  return (
    <RightToggler title="CREATE APPOINTMENT" size="500px" close={close}>
      <Formik
        validationSchema={BusinessCreateAppointmentSchema}
        validateOnBlur={false}
        initialValues={appointment}
        onSubmit={onSubmit}
      >
        {({ errors }) => (
          <Form>
            <div className="ps-5 pe-5 pt-3 pb-5">
              <div>
                <LabelBlueBold content="Client Email" />
                <Field
                  type="clientEmail"
                  name="clientEmail"
                  className="custom-input bg-transparent"
                  placeholder="Enter client email address"
                />
                <ErrorField message={errors.clientEmail} />
              </div>
              <div className="row mt-4">
                <div className="col-6">
                  <LabelBlueBold content="First Name" />
                  <Field
                    name="firstName"
                    className="custom-input bg-transparent"
                    placeholder="First Name"
                  />
                  <ErrorField message={errors.firstName} />
                </div>
                <div className="col-6">
                  <LabelBlueBold content="Last Name" />
                  <Field
                    name="lastName"
                    className="custom-input bg-transparent"
                    placeholder="Last Name"
                  />
                  <ErrorField message={errors.lastName} />
                </div>
              </div>
            </div>
            <div className="clearfix border-top"></div>
            <div className="ps-5 pe-5  pt-4">
              <div className="row">
                <div className="col-6">
                  <LabelBlueBold content="Apt. Date" />
                  <Field name="appointmentDate">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <input
                        type="date"
                        className="custom-input bg-transparent"
                        placeholder="Choose Date"
                        value={value}
                        onChange={({ target }) =>
                          setFieldValue("appointmentDate", target.value)
                        }
                      />
                    )}
                  </Field>
                  <ErrorField message={errors.appointmentDate} />
                </div>
                <div className="col-6">
                  <LabelBlueBold content="Apt. Time" />
                  <Field
                    name="appointmentTime"
                    className="primary-select bg-transparent"
                    as="select"
                  >
                    {times.map(time => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6">
                  <LabelBlueBold content="Reminder Time" />
                  <Field
                    name="reminder"
                    className="primary-select bg-transparent"
                    as="select"
                  >
                    <option value={15}>15 Minutes Earlier Apt.</option>
                    <option value={30}>30 Minutes Earlier Apt.</option>
                    <option value={60}>60 Minutes Earlier Apt.</option>
                  </Field>
                </div>
                <div className="col-6">
                  <LabelBlueBold content="Push Notification" />
                  <Field
                    name="pushNotification"
                    className="primary-select bg-transparent"
                    as="select"
                  >
                    <option value={true}>Enabled</option>
                    <option value={false}>Disabled</option>
                  </Field>
                </div>
              </div>
            </div>
            <div className="mt-5 clearfix border-top"></div>
            <div className="ps-5 pe-5  pt-4 pb-5">
              <div>
                <LabelBlueBold content="Consultant" />
                <Field
                  className="custom-input bg-transparent"
                  placeholder="Consultant"
                  name="consultant.fullName"
                >
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <input
                      type="text"
                      className="custom-input bg-transparent"
                      placeholder="Consultant"
                      value={value}
                      onChange={({ target }) =>
                        setFieldValue("consultant.fullName", target.value)
                      }
                    />
                  )}
                </Field>
                <ErrorField message={errors.consultant?.fullName} />
              </div>
              <div className="row mt-4"></div>
            </div>
            <div className="toggle-footer">
              <div className="d-flex justify-content-between">
                <button type="submit" className="btn-blue btn-small w-100">
                  Schedule Appointment
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </RightToggler>
  );
};
