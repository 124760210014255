import {TickBlue} from "../../../../assets/icons/icon";

export const Feature = ({plan}) => (
  <ul>
    {plan.featureList.map((feature, index) =>
      feature === "OK" ? (
        <li key={index}>{TickBlue} </li>
      ) : (
        <li key={index}>{feature}</li>
      )
    )}
  </ul>
);
