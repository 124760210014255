import styled, { css } from "styled-components";

export const StyledUserInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 20px;
  grid-gap: 1rem;
  align-items: center;
  padding: 2rem;
`;

export const StyledUserAvatarContainer = styled.div`
  ${({ isOnline }) => css`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${isOnline ? "#00ff4e" : "#ff0000"};
      border: 2px solid #fff;
      z-index: 1;
    }
  `}
`;
export const StyledUserAvatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const StyledUserName = styled.div`
  font-weight: 600;
`;

export const StyledAlertIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  width: 40px;
  height: 50px;
  padding: 0 5px;
`;

export const StyledIcon = styled.img`
  width: 20px;
  height: auto;
  object-fit: cover;
  border: none;
  margin: 0 5px;
`;
