import * as Yup from "yup";

export const BusinessShareModalSchema = Yup.object().shape({
  emails: Yup.string()
    .min(4, "Emails is too Short!")
    .max(150, "Emails is too Long!")
    .required("Emails is required"),
  comment: Yup.string()
    .min(3, "Comment is too Short!")
    .max(250, "Comment is too Long!")
    .required("Comment is required"),
});
