import React, {useState} from "react";
import {PageInnerTitle} from "../../../../../Layout/Shared/PageInnerTitle";
import {BluePlusIcon, DeleteIcon} from "../../../../../../assets/icons/icon";
import HolidayImage from "../../../../../../assets/images/icon-holiday.jpg";
import {HolidayModal} from "./modal";
import "./index.scss";

export const Holidays = ({currentHolidays, setHolidaysTosave}) => {
  const [showAddHolidayPopup, setShowAddHolidayPopup] = useState(false);
  const [holidays, setHolidays] = useState(currentHolidays || []);

  const deleteHoliday = ({name}) => {
    const updatedState = holidays.filter((item) => item.name !== name);
    setHolidays(updatedState);
    setHolidaysTosave(updatedState);
  };

  const addToHolidayList = (holiday) => {
    const updatedState = [...holidays, holiday];
    setHolidays(updatedState);
    setHolidaysTosave(updatedState);
    setShowAddHolidayPopup(false);
  };

  return (
    <div>
      <PageInnerTitle>
        Add Holidays
        <button
          onClick={() => setShowAddHolidayPopup(!showAddHolidayPopup)}
          className="btn-add-circle-blue"
        >
          {BluePlusIcon}
        </button>
      </PageInnerTitle>
      {holidays.map((holiday, index) => (
        <div className="holidays mt-4" key={index}>
          <div>
            <img src={HolidayImage} alt={holiday.name} />
          </div>
          <div>
            {holiday.name}
            <small>
              <span>{holiday.date} </span>
              <span>{holiday.recurrence}</span>
            </small>
          </div>
          <div>
            <button className="btn-icon" onClick={() => deleteHoliday(holiday)}>
              {DeleteIcon}
            </button>
          </div>
        </div>
      ))}
      {showAddHolidayPopup && (
        <HolidayModal
          onClose={() => setShowAddHolidayPopup(!showAddHolidayPopup)}
          addHoliday={addToHolidayList}
        />
      )}
    </div>
  );
};
