import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../../../components/Layout/Header/Header";
import { GenericPageLeft } from "../../../../components/Layout/Shared/GenericPageLeft";
import { PageContainer } from "../../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../../components/Layout/Shared/PageTitle";
import CaseList from "../../../../components/CaseManagement/Cases/List";
import { getCases } from "../../../../data/CaseManagement/cases";
import DataLoading from "../../../../components/Layout/Shared/DataLoading";
import { CustomButton } from "../../../../components/Layout/Shared/Button";

const CaseManagementCasesListPage = () => {
  const history = useHistory();
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const { data, hasSuccess } = await getCases();
    hasSuccess ? setCases(data) : console.log(JSON.parse(data).description);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  const handleRefresh = async () => await getData();

  const goToCreateCase = () =>
    history.push("/business/case-management/cases/form");

  return (
    <section className="dashboard bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Client Case Management</PageTitle>
              <div className="d-flex justify-content-end align-items-center">
                <CustomButton
                  color="blue"
                  title="Create Case"
                  size="250px"
                  onClick={goToCreateCase}
                />
              </div>
            </PageHeader>
            <div className="row m-2  bg-white border rounded-xl overflow-hidden">
              {loading ? (
                <DataLoading />
              ) : (
                <CaseList cases={cases} refresh={handleRefresh} />
              )}
            </div>
          </PageContainer>
        </div>
      </div>
    </section>
  );
};
export default CaseManagementCasesListPage;
