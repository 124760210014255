import React, { useState, useEffect } from "react";
import Avatar from "../../../../assets/images/default-profile-picture.png";
import { LabelBlueBold } from "../../../Layout/Shared/LabelBlueBold";
import "./index.scss";
import moment from "moment";
import { Options } from "../../../../components/Layout/Shared/DropdownOptionsMenu";
import { ThreeDotIcon } from "../../../../assets/icons/icon";
import { RightToggler } from "../../../../components/Layout/Shared/RightToggler";
import { updateAppointment } from "../../../../data/Calendar/Business/updateAppointment";
import { EditAppointment } from "../Edit/index";
import { getDate, getOnlyTime, isPast } from "../../../../utils/date";
import ConfirmDialog from "../../../Layout/Shared/Popups/ConfirmDialog";
import { DeleteModal } from "../../../Layout/Shared/Popups/Delete";

export const AppointmentDetails = ({ close, appointment, onSuccess }) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const [showCancelConfirmDialog, setShowCancelConfirmDialog] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [showMissedConfirmDialog, setShowMissedConfirmDialog] = useState(false);

  useEffect(() => {
    if (!appointment) return;
    setShowEditForm(false);
    return () => {};
  }, [appointment]);

  const updateAppointmentDetails = async fieldToUpdate => {
    const { hasSuccess } = await updateAppointment({
      id: appointment.id,
      [fieldToUpdate]: true
    });
    if (hasSuccess) {
      onSuccess();
      close();
    }
  };

  const calculateReminderTime = (date, minutes) =>
    moment(date).subtract(parseInt(minutes), "minutes").format("hh:mm A");

  const isEditButtonDisabled =
    appointment.source && appointment.source === "web";

  return (
    <>
      <RightToggler title="APPOINTMENT DETAILS" size="500px" close={close}>
        <div>
          <div className="appointment-detail">
            <div>
              <div className="client-detail ps-5 pe-5  pt-5">
                <div>
                  <img src={Avatar} alt="" />
                </div>
                <div>
                  <div className="row">
                    <div className="col-12">
                      <span className="h3 text-primary-blue">
                        {appointment.title}
                      </span>
                    </div>
                    <div className="col-12 d-flex justify-content-start">
                      <div>
                        <span>Email:</span>
                        <p>{appointment.clientEmail}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="appointment">
                <div>
                  <LabelBlueBold content="Apt. Date:" />
                  <span>{getDate(appointment.date)}</span>
                </div>
                <div>
                  <LabelBlueBold content="Apt. Time:" />
                  <span>{getOnlyTime(appointment.date)}</span>
                </div>
                <div>
                  <LabelBlueBold content="Reminder:" />
                  <span>
                    {appointment.reminder &&
                      parseInt(appointment.reminder) &&
                      calculateReminderTime(
                        appointment.date,
                        appointment.reminder
                      )}
                  </span>
                </div>
                <div>
                  <LabelBlueBold content="Status:" />
                  <span>{appointment.status}</span>
                </div>
                <div>
                  <LabelBlueBold content="Consultant:" />
                  <span>{appointment.consultant.fullName}</span>
                </div>
              </div>
            </div>
            <div className="clearfix border-bottom">
              {isEditButtonDisabled && (
                <div className="appointment">
                  *This appointment was created by the client so can not be
                  applied any changes.
                </div>
              )}
            </div>
          </div>
          <div className="toggle-footer">
            <div className="d-flex justify-content-between align-items-center">
              <button className="btn-blue btn-small w-100">Chat</button>
              <Options
                arrow={false}
                buttonTitle={ThreeDotIcon}
                style={{ top: "calc(100% - 164px)" }}
                menuItems={
                  <div>
                    {!isPast(appointment.date) && (
                      <>
                        <button
                          onClick={() => setShowEditForm(true)}
                          disabled={isEditButtonDisabled}
                        >
                          Edit Appointment
                        </button>
                        {isEditButtonDisabled && (
                          <div
                            className="tooltip"
                            style={{
                              position: "absolute",
                              left: "100%",
                              marginLeft: "10px"
                            }}
                          >
                            Appointments from the web cannot be edited.
                          </div>
                        )}
                      </>
                    )}
                    <button
                      onClick={() => setShowCancelConfirmDialog(true)}
                      disabled={isEditButtonDisabled}
                    >
                      Cancel Appointment
                    </button>
                    <button
                      onClick={() => setShowMissedConfirmDialog(true)}
                      disabled={isEditButtonDisabled}
                    >
                      Missed Appointment
                    </button>
                    <button
                      onClick={() => setShowDeleteConfirmDialog(true)}
                      disabled={isEditButtonDisabled}
                    >
                      Delete
                    </button>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </RightToggler>
      {showEditForm && (
        <EditAppointment
          close={() => setShowEditForm(false)}
          appointment={appointment}
          onSuccess={onSuccess}
        />
      )}
      {showCancelConfirmDialog && (
        <ConfirmDialog
          title="Cancel Appointment"
          description="Are you sure you want to cancel this appointment?"
          onClose={() => setShowCancelConfirmDialog(false)}
          onConfirm={() => updateAppointmentDetails("isCancelled")}
        ></ConfirmDialog>
      )}
      {showDeleteConfirmDialog && (
        <DeleteModal
          title="Delete Appointment"
          text="This appointment will be permanently deleted"
          buttonText="Delete"
          cancel={true}
          onClose={() => setShowDeleteConfirmDialog(false)}
          onConfirm={() => updateAppointmentDetails("isDeleted")}
        ></DeleteModal>
      )}
      {showMissedConfirmDialog && (
        <ConfirmDialog
          title="Missed Appointment"
          description="Are you sure you want to mark as missed this appointment?"
          onClose={() => setShowMissedConfirmDialog(false)}
          onConfirm={() => updateAppointmentDetails("isMissed")}
        ></ConfirmDialog>
      )}
    </>
  );
};
