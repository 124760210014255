import React from "react";
import "../styles/pages/login.scss";
import {BusinessForm} from "../components/ForgotPassword/BusinessForm";
import LearnMore from "../components/Login/LearnMore";

const ForgotPasswordPage = () => {
  return (
    <div>
      <div className="container-fluid sign-in">
        <div className="row d-md-flex">
          <LearnMore />
          <div className="col-lg-6 right order-1 order-md-2 order-lg-2 justify-content-center align-items-center">
            <div className="box">
              <h2>
                Forgot your password? <small>Just reset it</small>
              </h2>
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                  </div>
                  <BusinessForm />
                </div>
                <div className="col-sm-12 pt-5 mt-5">
                  Remember it now? <a href="/login"> Log in </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
