import React from "react";
import { LabelBlueBold } from "../../../Layout/Shared/LabelBlueBold";
import { Formik, Form, Field } from "formik";
import ErrorField from "../../../Layout/Shared/ErrorField";
import { useBusinessClientCreateContext } from "../../../../providers/Client/create";
import { SwitchButton } from "../../../Layout/Shared/SwitchButtonBlue";
import useDebounce from "../../../../custom-hooks/debounce.hook";

export const ClientInformation = () => {
  const { getters, setters, checkClient } = useBusinessClientCreateContext();
  const { client, errors, checkResult, checkedClient } = getters;
  const { setClient, setCheckedClient } = setters;

  const { isInvitedByAnotherBusiness } = checkResult;

  const debouncedCheckClient = useDebounce(checkClient, 600);

  return (
    <div className="pl-4">
      <h2>Client Details</h2>
      <div>
        <Formik
          enableReinitialize
          initialValues={client}
          validateOnBlur={false}
        >
          {({ values }) => (
            <>
              {setClient(values)}
              <Form>
                <div className="mt-4">
                  <LabelBlueBold content="Email Address" />
                  <Field>
                    {({ field: { value }, form: { setFieldValue } }) => {
                      return (
                        <input
                          className="custom-input"
                          placeholder="Enter Email Address"
                          name="email"
                          onChange={({ target }) => {
                            setCheckedClient(false);
                            setFieldValue("email", target.value && target.value.trim());
                            debouncedCheckClient(target.value && target.value.trim());
                          }}
                          onPaste={({ target }) => {
                            setTimeout(() => {
                              setCheckedClient(false);
                              setFieldValue("email", target.value && target.value.trim());
                              debouncedCheckClient(target.value && target.value.trim());
                            }, 100);
                          }}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="mt-4">
                  <LabelBlueBold content="Is An Immigrant?" />
                  <Field name="isImmigrant">
                    {({ field: { value }, form: { setFieldValue } }) => {
                      return (
                        <SwitchButton
                          status={value}
                          onChange={() => setFieldValue("isImmigrant", !value)}
                          label={value ? "Immigrant" : "Private Profile"}
                          disabled={checkedClient && isInvitedByAnotherBusiness}
                        />
                      );
                    }}
                  </Field>
                </div>
              </Form>
            </>
          )}
        </Formik>
        {errors &&
          errors.length > 0 &&
          errors.map((error, index) => (
            <ErrorField key={index} message={error} />
          ))}
      </div>
    </div>
  );
};
