export const initialFormBuilderState = {
  formSaved: false,
  validation: false,
  formValid: true,
  form_header: {
    title: "",
    placeholder: "Enter form title...",
    valid: false,
  },
  components: [],
};
