import {React} from "react";
import Header from "../../../components/Layout/Header/Header";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import {ReviewList} from "../../../components/Services/Business/Reviews/List";
import {PageDetails} from "../../../components/Layout/Shared/PageDetails";
import {PageStatistics} from "../../../components/Layout/Shared/PageStatistics";
import {InnerPageLeft} from "../../../components/Layout/Shared/InnerPageLeft";
import {ReviewStatistics} from "../../../components/Services/Business/Reviews/Statistics";
import {ReviewTotals} from "../../../components/Services/Business/Reviews/Statistics/total";

const ServiceReviewsPage = () => {
  return (
    <section className="dashboard  bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Reviews</PageTitle>
            </PageHeader>
            <div className="row m-2  bg-white border rounded-xl overflow-hidden">
              <div className="page-left p-0 border-end">
                <InnerPageLeft>
                  <PageDetails title="REVIEWS STATISTICS">
                    <ReviewStatistics />
                  </PageDetails>
                  <PageStatistics title="TOTAL REVIEWS">
                    <ReviewTotals />
                  </PageStatistics>
                </InnerPageLeft>
              </div>
              <div className="page-right ps-5 pe-5  pt-3">
                <ReviewList />
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
    </section>
  );
};
export default ServiceReviewsPage;
