import CheckboxPreview from "../Components/Checkbox/CheckboxPreview";
import HeadingPreview from "../Components/Heading/HeadingPreview";
import InputNumberHover from "../Components/InputNumber/InputNumberHover";
import InputTextHover from "../Components/InputText/InputTextHover";
import LabelHover from "../Components/Label/LabelHover";
import PageBreak from "../Components/PageBreak/PageBreak";
import RadioButtonPreview from "../Components/RadioButton/RadioButtonPreview";
import TextAreaPreview from "../Components/Textarea/TextareaPreview";
import ESignaturePreview from "../Components/ESignature/ESignaturePreview";

export const ComponentType = {
  HEADING: "Heading",
  LABEL: "Label",
  INPUT_TEXT: "InputText",
  INPUT_NUMBER: "InputNumber",
  CHECKBOX: "Checkbox",
  RADIOBUTTON: "RadioButton",
  TEXTAREA: "Textarea",
  PAGE_BREAK: "PageBreak",
  ESIGNATURE: "ESignature",
};

export const FormPreviewComponentTypes = {
  [ComponentType.HEADING]: HeadingPreview,
  [ComponentType.LABEL]: LabelHover,
  [ComponentType.INPUT_TEXT]: InputTextHover,
  [ComponentType.INPUT_NUMBER]: InputNumberHover,
  [ComponentType.CHECKBOX]: CheckboxPreview,
  [ComponentType.RADIOBUTTON]: RadioButtonPreview,
  [ComponentType.TEXTAREA]: TextAreaPreview,
  [ComponentType.PAGE_BREAK]: PageBreak,
  [ComponentType.ESIGNATURE]: ESignaturePreview,
};
