/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {ErrorPage} from "../components/Layout/Shared/ErrorPages";
import {isBusiness, isStaff, isClient} from "../utils/account";
import "../styles/pages/login.scss";

const NoAccessPage = () => {
  const [counter, setCounter] = useState(3);
  const history = useHistory();

  const dashboardUrl = isBusiness()
    ? "business/dashboard"
    : isStaff()
    ? "staff/dashboard"
    : isClient()
    ? "client/dashboard"
    : null;

  const goToDashboard = () => history.push(dashboardUrl);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    if (counter > 0) return;
    goToDashboard();
  }, [counter, goToDashboard]);

  return (
    <>
      <ErrorPage
        code="403"
        message="The page that you're trying to open in your web browser is a resource that you're not allowed to access!"
      />
    </>
  );
};

export default NoAccessPage;
