/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Options } from "../../../../Layout/Shared/DropdownOptionsMenu";
import { ThreeDotIcon } from "../../../../../assets/icons/icon";

export const ActionButtons = ({
  task,
  handleOnEdit,
  handleOnRemove,
  handleOnCancel,
  handleOnDelete
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading ? (
        <span className="dots-loading">{ThreeDotIcon}</span>
      ) : (
        <Options
          arrow={false}
          buttonTitle={ThreeDotIcon}
          menuItems={
            <div>
              <button
                className="text-warning"
                hidden={task.cancelled}
                onClick={handleOnRemove}
                disabled={true}
              >
                Remove
              </button>
              <button hidden={task.cancelled} onClick={handleOnEdit}>
                Edit
              </button>
              <button hidden={task.cancelled} onClick={handleOnCancel}>
                Cancel
              </button>
              <button className="text-danger" onClick={handleOnDelete}>
                Delete
              </button>
            </div>
          }
        />
      )}
    </>
  );
};
