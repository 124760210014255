import React, {useState, useContext} from "react";
import {CustomButton} from "../../../../Layout/Shared/Button";
import {Options} from "../../../../Layout/Shared/DropdownOptionsMenu/index";
import Table from "../../../../Layout/Shared/Table/index";
import "../../../../../styles/customComponents/servicePageHeader.scss";
import {ThreeDotIcon} from "../../../../../assets/icons/icon";
import {AddUserModal} from "./AddUsersModal";
import {DeleteModal} from "../../../../Layout/Shared/Popups/Delete";
import BusinessServiceContext from "../../../../../providers/Service/business";

const Collaborate = () => {
  const {
    values: {users},
    setters: {setUsers},
  } = useContext(BusinessServiceContext);
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setshowDelete] = useState(false);

  const ActionButtons = ({row}) => (
    <>
      <Options
        arrow={false}
        buttonTitle={ThreeDotIcon}
        menuItems={
          <div>
            <button
              className="text-danger"
              onClick={() => setshowDelete(!showDelete)}
            >
              Delete
            </button>
          </div>
        }
      />
      {showDelete && (
        <DeleteModal
          title="Delete Collaborate"
          text="You are about to delete this collaborate. Deleting 
this client removes all client’s information and ends all previous
communication between you and the client.
"
          buttonText="Delete"
          onConfirm={() => onDeleteUser(row.email)}
          onClose={() => setshowDelete(!showDelete)}
        />
      )}
    </>
  );
  const ActionsComponent = () => (
    <CustomButton
      color="blue"
      title="Add Users"
      size="140px"
      onClick={() => setShowModal(!showModal)}
    />
  );

  const onAddUser = (userList) => {
    setUsers(userList);
    setShowModal(false);
  };

  const onDeleteUser = (email) =>
    setUsers(users.filter((user) => user.email !== email));

  const fields = ["email", "permission", {render: ActionButtons}];
  const headers = [
    {
      displayName: "Users",
      sortingFieldName: "email",
    },
    {
      displayName: "Can do",
      sortingFieldName: "permission",
    },
    {
      displayName: "Actions",
      sortingFieldName: "actions",
    },
  ];
  const options = {
    data: users,
    headers,
    fields,
    itemsPerPage: 10,
    pagination: true,
    search: true,
    sort: true,
    headerAction: ActionsComponent,
  };
  return (
    <section className="p-5">
      <Table options={options} />
      {showModal && (
        <AddUserModal
          existingUsers={users}
          onAddUser={onAddUser}
          onClose={() => setShowModal(!showModal)}
        />
      )}
    </section>
  );
};
export default Collaborate;
