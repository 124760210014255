import {getBase64} from "../../utils/base64";
import {formsApiHostName} from "../endpoint";
import {defaultHeaders} from "../headers";

export const UploadFormAttachmentRequest = async (file, meta) => {
  const fileData = await getBase64(file);

  try {
    const result = await fetch(`${formsApiHostName}/form/attachment`, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        s3_content_type: meta.type,
        file_name: meta.name,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({ file: fileData }),
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json(),
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message,
    };
  }
};

