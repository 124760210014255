import React from "react";
import { FormComponentTypes } from "../../../constants";
import { useFormBuilderContext } from "../../context";
import { StyledContainer, StyledComponentsWrapper } from "./styles";
import HeadingComponent from "../Components/Heading";
import InputTextComponent from "../Components/InputText";
import InputNumberComponent from "../Components/InputNumber";
import ComponentsEmpty from "../Empty";
import LabelComponent from "../Components/Label";
import RadioButtonComponent from "../Components/RadioButton";
import CheckboxComponent from "../Components/Checkbox";
import MemoComponent from "../Components/Memo";
import PageBreakComponent from "../Components/PageBreak";
import ESignatureComponent from "../Components/ESignature";
import AttachmentComponent from "../Components/Attachment";

const componentMap = {
  [FormComponentTypes.Heading]: HeadingComponent,
  [FormComponentTypes.Label]: LabelComponent,
  [FormComponentTypes.InputText]: InputTextComponent,
  [FormComponentTypes.InputNumber]: InputNumberComponent,
  [FormComponentTypes.RadioButton]: RadioButtonComponent,
  [FormComponentTypes.Checkbox]: CheckboxComponent,
  [FormComponentTypes.Memo]: MemoComponent,
  [FormComponentTypes.PageBreak]: PageBreakComponent,
  [FormComponentTypes.ESignature]: ESignatureComponent,
  [FormComponentTypes.Attachment]: AttachmentComponent
};

const BodyMain = () => {
  const {
    components,
    hasComponent,
    changeComponentOrder,
    updateComponent,
    deleteComponent,
    duplicateComponent
  } = useFormBuilderContext();

  const resolveComponent = (index, component) => {
    const Component = componentMap[component.type];
    if (Component) {
      return (
        <Component
          data-id="component"
          key={index}
          component={component}
          onDeleteClick={() => deleteComponent(index)}
          onDuplicateClick={() => duplicateComponent(index)}
          onOptionsChange={newOptions => {
            component.options = newOptions;
            updateComponent(index, component);
          }}
          onOrderButtonClick={direction =>
            changeComponentOrder(component, direction)
          }
        />
      );
    }
    return null;
  };

  return (
    <StyledContainer>
      {hasComponent ? (
        <StyledComponentsWrapper>
          {components.map((component, index) =>
            resolveComponent(index, component)
          )}
        </StyledComponentsWrapper>
      ) : (
        <ComponentsEmpty />
      )}
    </StyledContainer>
  );
};

export default BodyMain;
