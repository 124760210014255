import React, { useState } from 'react'
import { useFormBuilderContext } from '../context'
import { createComponentModel } from '../utils'
import ToolboxHeader from './Header'
import ToolboxItems from './Items'
import { StyledContainer } from './styles'

const Toolbox = () => {
  const [isToolboxGridView, setIsToolboxGridView] = useState(false)
  const { addNewComponent } = useFormBuilderContext()

  const handleOnItemClick = (componentType) => {
    const newComponent = createComponentModel(componentType)
    addNewComponent(newComponent)
  }

  return (
    <StyledContainer>
      <ToolboxHeader onViewChanged={setIsToolboxGridView} />
      <ToolboxItems
        isGrid={isToolboxGridView}
        onItemClick={handleOnItemClick}
      />
    </StyledContainer>
  )
}

export default Toolbox
