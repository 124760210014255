/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  StyledMessageCard,
  StyledMessageLine,
  StyledMessage,
  StyledUserInfo,
  StyledAvatar,
  StyledUserNameAndDate,
  StyledActionsWrapper,
  StyledMessageEditInput,
  StyledAttachmentContainer,
  StyledAttachmentActionsWrapper,
  StyledAttachmentFileName,
  StyledAttachmentDownloadButton,
  StyledAttachmentTooltip,
  StyledAttachmentTopSection
} from "./MessageCard.style";
import linkifyHtml from "linkify-html";
import EmojiConvertor from "emoji-js";
import { getShortDate } from "../../../../utils/date";
import { useChatContext } from "../../../../providers/Chat";
import GenericFileIcon from "../../../../assets/icons/generic-file-icon";

const emojiConvertor = new EmojiConvertor();
const defaultAvatar = "https://www.w3schools.com/howto/img_avatar.png";

const truncateFileName = (name, maxLength = 16) => {
  if (name.length > maxLength) {
    const extension = name.split(".").pop();
    return `${name.substring(
      0,
      maxLength - extension.length - 1
    )}...${extension}`;
  }
  return name;
};

const MessageCard = ({ participants = [], messages = [], containerRef }) => (
  <>
    {messages.map((message, index) => {
      const sender = participants.find(p => p.id === message.senderId);
      return (
        <StyledMessageCard isItMe={message.isMe} key={index}>
          <StyledMessageLine>
            <StyledUserInfo isItMe={message.isMe}>
              <StyledAvatar
                src={sender.image || defaultAvatar}
                alt={sender.name}
              />
              <StyledUserNameAndDate>
                <span>{sender.name}</span> <span>|</span>
                <span>{getShortDate(message.createdAt)}</span>
              </StyledUserNameAndDate>
            </StyledUserInfo>
            {message.message.type === "text" && (
              <TextMessage containerRef={containerRef} message={message} />
            )}
            {message.message.type === "attachment" && (
              <AttachmentMessage message={message} />
            )}
          </StyledMessageLine>
        </StyledMessageCard>
      );
    })}
  </>
);

export default MessageCard;

const TextMessage = ({ message, containerRef }) => {
  const [editInputSize, setEditInputSize] = useState({ width: 0, height: 0 });
  const { updateTextMessage, deleteMessage } = useChatContext();

  const [editMessage, setEditMessage] = useState(message.message.content || "");
  const [isEditing, setIsEditing] = useState(false);

  const getTextMessageAsHtml = text => {
    let result = emojiConvertor.replace_emoticons(text);
    result = linkifyHtml(result, {
      defaultProtocol: "https",
      target: "_blank"
    });
    return result;
  };

  const saveMessage = async () => {
    if (editMessage?.trim().length > 0) {
      await updateTextMessage(message, editMessage);
      setEditMessage("");
      setIsEditing(false);
    }
  };

  const messageContentRef = useRef();
  const messageEditRef = useRef();

  useEffect(() => {
    if (isEditing) {
      messageEditRef.current.style.width = `${editInputSize.width}px`;
      messageEditRef.current.style.minHeight = "100px";
      messageEditRef.current.style.height = `${editInputSize.height + 20}px`;
    } else {
      setEditInputSize({ width: 0, height: 0 });
    }
  }, [isEditing]);

  const handleEditClick = () => {
    const { offsetHeight } = messageContentRef.current;
    setEditInputSize({
      width: containerRef.current.offsetWidth * 0.7,
      height: offsetHeight
    });
    setEditMessage(message.message.content);
    setIsEditing(true);
  };

  return (
    <StyledMessage isItMe={message.isMe}>
      {message.isMe && (
        <StyledActionsWrapper className="actions">
          {isEditing ? (
            <>
              <span onClick={saveMessage}>Save</span>
              <span onClick={() => setIsEditing(false)}>Cancel</span>
            </>
          ) : (
            <>
              <span onClick={handleEditClick}>Edit</span>
              <span onClick={() => deleteMessage(message)}>Delete</span>
            </>
          )}
        </StyledActionsWrapper>
      )}
      {isEditing ? (
        <StyledMessageEditInput
          ref={messageEditRef}
          value={editMessage}
          onChange={e => setEditMessage(e.currentTarget.value)}
        />
      ) : (
        <div
          ref={messageContentRef}
          dangerouslySetInnerHTML={{
            __html: getTextMessageAsHtml(message.message.content)
          }}
        ></div>
      )}
    </StyledMessage>
  );
};

const AttachmentMessage = ({ message }) => {
  const { name = "", url } = message.message.content;
  const { deleteMessage } = useChatContext();
  const handleOnDelete = () => deleteMessage(message);
  const truncatedName = truncateFileName(name);
  return (
    <StyledAttachmentContainer isItMe={message.isMe}>
      {message.isMe && (
        <StyledAttachmentActionsWrapper>
          <span onClick={handleOnDelete}>Delete</span>
        </StyledAttachmentActionsWrapper>
      )}
      <StyledAttachmentTopSection>
        <GenericFileIcon
          fill={message.isMe ? "white" : "black"}
          width="36px"
          height="36px"
        />
        <StyledAttachmentFileName isItMe={message.isMe}>
          {truncatedName}
          <StyledAttachmentTooltip isItMe={message.isMe}>
            {name}
          </StyledAttachmentTooltip>
        </StyledAttachmentFileName>
      </StyledAttachmentTopSection>
      <StyledAttachmentDownloadButton
        isItMe={message.isMe}
        href={url}
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </StyledAttachmentDownloadButton>
    </StyledAttachmentContainer>
  );
};
