import { getBase64 } from "../../utils/base64";
import { chatsApiHostName } from "../endpoint";
import { defaultHeaders } from "../headers";

export const UploadAttachment = async (
  file,
  chatId,
  userId,
  token,
  connectionId
) => {
  const body = new FormData();
  const fileData = await getBase64(file);
  body.append("file", fileData);
  body.append("name", file.name);
  body.append("type", file.type);
  connectionId && body.append("connectionId", connectionId);
  try {
    const url = `${chatsApiHostName}/attachments/chats/${chatId}/users/${userId}`;
    const result = await fetch(url, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
      },
      // body,
      body: JSON.stringify({
        file: fileData,
        name: file.name,
        type: file.type,
        connectionId
      })
    });
    return {
      hasSuccess: result.status === 200,
      data: await result.json()
    };
  } catch (err) {
    return {
      hasSuccess: false,
      data: err.message
    };
  }
};
