import React, {useEffect, useState} from "react";
import Modal from "../../../../../Layout/Shared/Modal";
import {ScrollContent} from "../../../../../Layout/Shared/ScrollContent";
import {CustomButton} from "../../../../../Layout/Shared/Button";
import {LabelBlueBold} from "../../../../../Layout/Shared/LabelBlueBold";
import "../../../../../../styles/customComponents/shared.scss";
import "../../../../../Contacts/Group/Create/index.scss";
import DataLoading, {
  ListLoader,
} from "../../../../../Layout/Shared/DataLoading";
import {CustomTextArea} from "../../../../../Layout/Shared/TextArea";
import ErrorField from "../../../../../Layout/Shared/ErrorField";
import {create} from "../../../../../../data/Settings/Business/Team/createGroup";
import {get as getStaffs} from "../../../../../../data/Settings/Business/Team/getStaffs";
import {string} from "yup";

export const CreateGroupTeam = ({onClose, refresh}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [filteredStaffs, setFilteredStaffs] = useState([]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const {hasSuccess, data} = await getStaffs();
      if (hasSuccess) {
        const dataAsArray = Object.values(data);
        setStaffs(dataAsArray);
        setFilteredStaffs(dataAsArray);
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const toggleChecked = (id) => {
    setStaffs(
      staffs.map((item) => {
        return {
          ...item,
          isSelected: item._id === id ? !item.isSelected : item.isSelected,
        };
      })
    );
    setFilteredStaffs(
      filteredStaffs.map((item) => {
        return {
          ...item,
          isSelected: item._id === id ? !item.isSelected : item.isSelected,
        };
      })
    );
  };

  const validateName = async (value) => {
    try {
      await string()
        .required("Group name is required")
        .min(3, "Group name is too Short!")
        .max(50, "Group name is too Long!")
        .validate(value);
    } catch (error) {
      return error;
    }
  };

  const handleSubmit = async () => {
    const validationResponse = await validateName(name);
    if (validationResponse && validationResponse.errors) {
      setError(validationResponse.errors.shift());
      return;
    } else {
      setError(null);
    }
    setIsSaving(true);
    const createGroup = {
      name,
      description,
      users: staffs
        .filter((contact) => contact.isSelected)
        .map((contact) => contact._id),
      status: "",
    };
    const {hasSuccess} = await create(createGroup);
    if (hasSuccess) {
      setIsSaving(false);
      onClose();
      refresh && refresh();
    }
  };

  return (
    <Modal
      title="Create Group / Team"
      width="920px"
      height="538px"
      close={onClose}
    >
      {isSaving ? (
        <DataLoading height="350px" />
      ) : (
        <div className="create-group-wrapper">
          <div className="create-group">
            <div>
              <LabelBlueBold content="Group / Team Name" />
              <input
                name="name"
                type="text"
                className="custom-input mb-3"
                placeholder="Enter group name"
                value={name}
                onChange={({target}) => setName(target.value)}
              />
              <LabelBlueBold content="Description" />
              <CustomTextArea
                name="description"
                value={description}
                placeholder="Enter Description"
                onChange={({target}) => setDescription(target.value)}
              />
              {error && <ErrorField message={JSON.stringify(error)} />}
            </div>
            <div className="second-column">
              <div className="titles">
                <LabelBlueBold content="All People" />
                <LabelBlueBold content="Roles" />
                <LabelBlueBold content="Selected" />
              </div>
              {isLoading ? (
                <ListLoader height={"200px"} />
              ) : (
                <ScrollContent height="220px">
                  <section className="selectable-contact-list">
                    <ul>
                      {filteredStaffs.map((staff, index) => (
                        <li key={index}>
                          <input
                            type="checkbox"
                            id={staff._id}
                            checked={staff.isSelected}
                            onChange={() => toggleChecked(staff._id)}
                          />
                          <label htmlFor={staff._id}>
                            {staff.firstName || staff.lastName ? (
                              <span>{`${staff.firstName} ${staff.lastName}`}</span>
                            ) : (
                              <span>{`${staff.email}`}</span>
                            )}
                            {staff.role}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </section>
                </ScrollContent>
              )}
            </div>
          </div>
          <div className="bottom">
            <button className="btn btn-link font-weight-bold" onClick={onClose}>
              Cancel
            </button>
            <CustomButton
              color="blue"
              title="Create Group"
              size="160px"
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
