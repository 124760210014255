import React from "react";
import Modal from "../../../Layout/Shared/Modal";
import { Formik, Form, Field } from "formik";
import { LabelBlueBold } from "../../../Layout/Shared/LabelBlueBold";
import { CustomButton } from "../../../Layout/Shared/Button";
import ErrorField from "../../../Layout/Shared/ErrorField";
import CaseManagementTaskCreateSchema from "../../../../schemas/CaseManagement/Task/create";
import CaseManagementTaskUpdateSchema from "../../../../schemas/CaseManagement/Task/update";
import { ListLoader } from "../../../Layout/Shared/DataLoading";
import "./index.scss";
import {
  CaseManagementPrivate,
  CaseManagementStatus,
  CaseManagementPriority
} from "../../../CaseManagement/Shared";
import { Autocomplete } from "../../../Layout/Shared/Autocomplete";

const TaskForm = ({
  task,
  setTask,
  onClose,
  onConfirm,
  processing,
  staffs = []
}) => {
  const isEdit = task && task._id;
  const title = isEdit ? `Edit Task: ${task.title}` : "Create Task";
  const buttonTitle = isEdit ? "Save Changes" : "Create";

  const Schema = isEdit
    ? CaseManagementTaskUpdateSchema
    : CaseManagementTaskCreateSchema;

  return (
    <Modal title={title} width="800px" height="700px" close={onClose}>
      {processing ? (
        <ListLoader height="700px" />
      ) : (
        <div className="share-modal">
          <p className="text-muted"></p>
          <Formik
            enableReinitialize
            initialValues={task}
            validateOnBlur={false}
            validationSchema={Schema}
            onSubmit={onConfirm}
          >
            {({ values, errors }) => (
              <>
                {setTask(values)}
                <Form>
                  <div className="d-flex justify-content-between mt-5">
                    <div className="form-container">
                      <div className="form-column">
                        <div className="form-group">
                          <LabelBlueBold content="Title" />
                          <Field
                            className="custom-input"
                            placeholder="Enter Task Title"
                            name="title"
                          />
                        </div>
                        <div className="form-group">
                          <LabelBlueBold content="Description" />
                          <Field
                            as="textarea"
                            className="custom-textarea"
                            placeholder="Enter Task Description"
                            name="description"
                          />
                        </div>
                        <div className="form-group">
                          <LabelBlueBold content="Due Date" />
                          <Field
                            className="custom-input ms-5"
                            placeholder="Due Date"
                            name="dueDate"
                          >
                            {({
                              field: { value },
                              form: { setFieldValue }
                            }) => (
                              <input
                                type="date"
                                className="custom-input bg-transparent"
                                placeholder="Due Date"
                                min={new Date().toISOString().split("T")[0]}
                                value={
                                  new Date(value || Date.now())
                                    .toISOString()
                                    .split("T")[0]
                                }
                                onChange={({ target }) =>
                                  setFieldValue("dueDate", target.value)
                                }
                              />
                            )}
                          </Field>
                        </div>
                        <div className="form-group">
                          <LabelBlueBold
                            content={isEdit ? "Updated By" : "Created By"}
                          />
                          <Field
                            className="custom-input"
                            placeholder={isEdit ? "Updated By" : "Created By"}
                            name="updatedBy"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-column">
                        <div className="form-group">
                          <LabelBlueBold content="Priority" />
                          <Field name="priority">
                            {({
                              field: { value },
                              form: { setFieldValue }
                            }) => (
                              <CaseManagementPriority
                                id="case-management-task-priority"
                                name="priority"
                                selectedValue={value}
                                onChange={value =>
                                  setFieldValue("priority", value)
                                }
                              />
                            )}
                          </Field>
                        </div>
                        <div className="form-group">
                          <LabelBlueBold content="Private" />
                          <Field
                            name="private"
                            className="custom-input"
                            placeholder="Private"
                          >
                            {({
                              field: { value },
                              form: { setFieldValue }
                            }) => (
                              <CaseManagementPrivate
                                name="private"
                                selectedValue={value ? "Yes" : "No"}
                                onChange={value =>
                                  setFieldValue("private", value === "Yes")
                                }
                              />
                            )}
                          </Field>
                        </div>
                        <div className="form-group">
                          <LabelBlueBold content="Assigned To" />
                          <Field
                            className="custom-input"
                            placeholder="Select Staff"
                            name="assignedTo"
                          >
                            {({
                              field: { value },
                              form: { setFieldValue }
                            }) => (
                              <Autocomplete
                                name="assignedTo"
                                className="custom-input"
                                placeholder="Select Staff"
                                options={staffs.map(item => ({
                                  id: item._id,
                                  text: item.fullName
                                }))}
                                defaultValue={
                                  staffs.find(({ _id }) => _id === value)
                                    ?.fullName
                                }
                                onSelect={({ id }) =>
                                  setFieldValue("assignedTo", id)
                                }
                              />
                            )}
                          </Field>
                        </div>
                        <div className="form-group">
                          <LabelBlueBold content="Status" />
                          <Field
                            name="status"
                            className="custom-input"
                            placeholder="Status"
                          >
                            {({
                              field: { value },
                              form: { setFieldValue }
                            }) => (
                              <CaseManagementStatus
                                id="case-management-task-status"
                                name={value}
                                selectedValue={value}
                                onChange={value =>
                                  setFieldValue("status", value)
                                }
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ErrorsDisplay errors={errors} />
                  <div className="mt-5 mb-4 d-flex justify-content-end">
                    <CustomButton
                      color="blue"
                      title={buttonTitle}
                      size="160px"
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};

export default TaskForm;

const ErrorsDisplay = ({ errors }) => {
  const errorMessages = Object.keys(errors).map(key => errors[key]);
  return (
    <div>
      {errorMessages.map((message, index) => (
        <ErrorField key={index} message={message} />
      ))}
    </div>
  );
};
