import React from "react";
import "./index.scss";

const Modal = ({title, width, height, children, extra, close}) => {
  return (
    <div className="modal-container">
      <div
        className="modal custom-modal fade show"
        style={{height: height, width: width}}
      >
        {title && (
          <div className="modal-header">
            {title} {extra}
            {close && (
              <button
                className="btn-modal-close"
                onClick={() => {
                  close();
                }}
              >
                &times;
              </button>
            )}
          </div>
        )}
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
