import React from "react";
import Header from "../../../components/Layout/Header/Header";
import {GenericPageLeft} from "../../../components/Layout/Shared/GenericPageLeft";
import {PageContainer} from "../../../components/Layout/Container/PageContainer";
import {PageHeader} from "../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../components/Layout/Shared/PageTitle";
import {TabContainer} from "../../../components/Settings/Business/Team/TabContainer";

const TeamSettingsPage = () => {
  return (
    <section className="dashboard  bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Team Settings</PageTitle>
            </PageHeader>
            <TabContainer />
          </PageContainer>
        </div>
      </div>
    </section>
  );
};
export default TeamSettingsPage;
