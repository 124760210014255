import styled from "styled-components";

export const StyledChatContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  max-height: calc(100vh - 160px);
`;
