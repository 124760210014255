import React, { Fragment, useState } from 'react'
import { defaultIndustries } from '../../../../components/Onboarding/defaultValues'
import './index.scss'

export const ServiceList = ({
  selectedIndustries,
  setSelectedIndustries,
  isService,
  disabled,
}) => {
  selectedIndustries = selectedIndustries || []

  defaultIndustries.forEach(
    (item) => (item.selected = selectedIndustries.includes(item.name)),
  )

  const [industries, setIndustries] = useState(
    isService
      ? defaultIndustries
      : defaultIndustries.filter((industry) => !industry.isService),
  )

  const handleTagClick = (e) => {
    if (!disabled) {
      const labelValue = e.target.innerText
      const newList = industries.map((industry) => {
        return {
          name: industry.name,
          selected:
            industry.name === labelValue
              ? !industry.selected
              : industry.selected,
        }
      })
      setIndustries(newList)
      setSelectedIndustries &&
        setSelectedIndustries(
          newList.filter((m) => m.selected).map((m) => m.name),
        )
    }
  }
  return (
    <div className="bussines-type">
      {industries.map((industry) => (
        <Fragment key={industry.name}>
          <input
            type="checkbox"
            disabled={disabled}
            checked={industry.selected}
          />
          <label onClick={handleTagClick}>{industry.name}</label>
        </Fragment>
      ))}
    </div>
  )
}
