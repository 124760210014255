import React, { useState, useEffect } from "react";
import Header from "../../../../components/Layout/Header/Header";
import { GenericPageLeft } from "../../../../components/Layout/Shared/GenericPageLeft";
import { PageContainer } from "../../../../components/Layout/Container/PageContainer";
import { PageHeader } from "../../../../components/Layout/Shared/PageHeader";
import { PageTitle } from "../../../../components/Layout/Shared/PageTitle";
import TypeList from "../../../../components/CaseManagement/Types/List";
import { getTypes } from "../../../../data/CaseManagement/types";
import DataLoading from "../../../../components/Layout/Shared/DataLoading";
import { CustomButton } from "../../../../components/Layout/Shared/Button";
import TypeForm from "../../../../components/CaseManagement/Types/Form";

const CaseManagementTypesListPage = () => {
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [type, setType] = useState({});

  const isEdit = type && type._id;

  const getData = async () => {
    setLoading(true);
    const { data, hasSuccess } = await getTypes();
    hasSuccess ? setTypes(data) : console.log(JSON.parse(data).description);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);

  const handleRefresh = async () => await getData();

  return (
    <section className="dashboard bg-paleGray pb-5 pr-3">
      <Header />
      <div className="container-fluid mt-5 pt-3">
        <div className="row">
          <GenericPageLeft />
          <PageContainer>
            <PageHeader>
              <PageTitle>Case Management Types</PageTitle>
              <div className="d-flex justify-content-end align-items-center">
                <CustomButton
                  color="blue"
                  title="Create Type"
                  size="250px"
                  onClick={() => setShowFormModal(true)}
                />
              </div>
            </PageHeader>
            <div className="row m-2  bg-white border rounded-xl overflow-hidden">
              {loading ? (
                <DataLoading />
              ) : (
                <TypeList
                  types={types}
                  setType={setType}
                  setShowFormModal={setShowFormModal}
                  refresh={handleRefresh}
                />
              )}
            </div>
            {showFormModal && (
              <TypeForm
                title={isEdit ? "Edit Case Type" : "Create Case Type"}
                type={type}
                setType={setType}
                onClose={async () => {
                  setShowFormModal(false);
                  setType({});
                }}
                onConfirm={async () => {
                  setShowFormModal(false);
                  setType({});
                  await handleRefresh();
                }}
              />
            )}
          </PageContainer>
        </div>
      </div>
    </section>
  );
};
export default CaseManagementTypesListPage;
