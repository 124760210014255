import {useState} from "react";
import Modal from "../../../../../Layout/Shared/Modal";
import {AddHolidaySchema} from "../../../../../../schemas/Services/Business/holiday";
import ErrorField from "../../../../../../components/Layout/Shared/ErrorField";
import {getOnlyDate} from "../../../../../../utils/date";

export const HolidayModal = ({addHoliday, onClose}) => {
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [date, setDate] = useState(getOnlyDate(new Date()));
  const [recurrence, setRecurrence] = useState("One time");

  const recurrences = ["One time", "Repeat every year"];

  const createHoliday = async () => {
    try {
      await AddHolidaySchema.validate({name, date});
    } catch (error) {
      setError(error);
      return error;
    }
    addHoliday({name, date, recurrence});
  };

  return (
    <Modal title="Add Holiday" width="780px" height="377px" close={onClose}>
      <h5>Add your national or company holidays</h5>
      <div className="form-group">
        <input
          type="text"
          value={name}
          className="custom-input"
          placeholder="Holiday Name"
          onChange={({target}) => setName(target.value)}
        />
      </div>
      <div className="form-group d-flex justify-content-between">
        <input
          type="date"
          value={date}
          className="custom-input me-2"
          placeholder="Choose Date"
          onChange={({target}) => setDate(target.value)}
        />
        <select
          className="primary-select p-2 pl-4"
          onChange={({target}) => setRecurrence(target.value)}
        >
          {recurrences.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      {error && <ErrorField message={JSON.stringify(error.errors)} />}

      <div className="form-group d-flex justify-content-end">
        <button
          onClick={onClose}
          className="mt-0 btn btn-steps pt-0 mt-1 text-primary font-weight-bold"
        >
          Cancel
        </button>
        <button
          onClick={createHoliday}
          className="mt-0 btn-blue-gradient btn-small"
        >
          Add
        </button>
      </div>
    </Modal>
  );
};
