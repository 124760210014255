import React, {useState} from "react";
import Sidebar from "../../Sidebar/Sidebar";

export const GenericPageLeft = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div>
      {showMenu === false ? (
        <button
          className="menu-mobile"
          onClick={() => setShowMenu(true)}
        ></button>
      ) : (
        <button
          className="menu-mobile"
          onClick={() => setShowMenu(false)}
        ></button>
      )}
      <div className={showMenu === true ? "left show pt-5" : "left  pt-5"}>
        <Sidebar />
      </div>
    </div>
  );
};
