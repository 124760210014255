import {CustomButton} from "../../../../../Layout/Shared/Button";
import Modal from "../../../../../Layout/Shared/Modal";
import SuccessGreen from "../../../../../../assets/images/success-green-big.png";
import "./index.scss";
export const CongratulationsModal = ({onClose, heading, email}) => {
  return (
    <Modal title="&nbsp;" width="480px" height="440px" close={onClose}>
      <div className="success-modal-content">
        <icon>
          <img src={SuccessGreen} alt="" />
        </icon>
        <h3 className="heading mt-4">{heading}</h3>

        <div className="content">
          Your appointment has been successfully scheduled.
        </div>
        <div className="content">
          Confirmation email has been also sent to <br />
          your Email ID:
          <span className="text-primary-blue">{email}</span>
        </div>
        <div className="footer">
          <CustomButton
            onClick={onClose}
            title="Okay"
            size="80px"
            color="transparent"
          />
        </div>
      </div>
    </Modal>
  );
};
