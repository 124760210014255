import styled  from "styled-components";

export const StyledIcon = styled.span`
  margin-right: 10px;
  padding: 3px;
`;

export const StyledDownload = styled.div`
  cursor: pointer;
  background: #fff;
  border-radius: 2px;
  padding: 0.1rem;
  margin-right: 1rem
`;

export const StyledDelete = styled.div`
  justify-content: flex-end;
  margin-right: 5px;
  border-radius: 2px;
  padding: 0.1rem;
`;

export const StyledDocumentCard = styled.div`
  width: 300px;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  background: #f5f6f7;
  padding: 1rem;
  display: flex;
  margin-right : 2rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledFileName = styled.span`
  padding-left: 5px;
  margin-right: auto;
`;
