import React, { useState, useEffect } from "react";
import { CustomSelectFile } from "../../../Layout/Shared/SelectFile";
import { getBase64 } from "../../../../utils/base64";
import { uploadProfilePicture } from "../../../../data/Contact/Business/uploadProfilePicture";
import { ListLoader } from "../../../Layout/Shared/DataLoading";
import {
  HeartIconBlueBorder,
  HeartIconOrange
} from "../../../../assets/icons/icon";
import { getById as getGroupById } from "../../../../data/Contact/Business/Group/getById";
import "../../Update/ContactCard/index.scss";
export const ContactProfilePicture = ({
  profilePictureUrl,
  setProfilePictureUrl,
  isFavorite,
  setIsFavorite,
  groupId,
  setGroupId
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [group, setGroup] = useState(null);
  const handleFile = async ({ files }) => {
    setIsLoading(true);
    const file = files[0];
    const payload = {
      name: file.name,
      type: file.type,
      file: await getBase64(file),
      source: "contact-profile-picture"
    };
    const { hasSuccess, data } = await uploadProfilePicture(payload);
    hasSuccess && setProfilePictureUrl(data.Location);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!groupId) {
      setGroup(null);
      return;
    }
    const init = async () => {
      const { data, hasSuccess } = await getGroupById(groupId);
      hasSuccess && setGroup(data);
    };
    init();
  }, [groupId]);

  const removeGroup = () => setGroupId(null);

  return (
    <div className="col-12 contact-profile-card contact-profile-picture">
      <div className="text-center  p-5 ">
        <h2>
          Profile Picture
          <button
            className="btn-icon"
            onClick={() => setIsFavorite(!isFavorite)}
          >
            {isFavorite ? HeartIconOrange : HeartIconBlueBorder}
          </button>
        </h2>
        <figure className="border rounded-xxl overflow-hidden">
          <img
            src={profilePictureUrl}
            onLoad={() =>
              setTimeout(() => {
                setIsLoading(false);
              }, 500)
            }
            alt=""
            style={{
              display: isLoading ? "none" : "flex"
            }}
          />
          {isLoading && <ListLoader height={"213px"} />}
        </figure>
        <CustomSelectFile
          color="solid-blue"
          label="Upload Your Picture"
          size="210px"
          onChange={e => handleFile(e.target)}
          accept="image/*"
        />
      </div>

      {group && (
        <div className="border-top w-100">
          <div className="group-section">
            <h4>Group(s)</h4>
            <ul className="groups">
              <li>
                {group.name}
                <button onClick={() => removeGroup()}>&times;</button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
