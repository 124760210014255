import React, {useState} from "react";
import {accountTabs, defaultAccountTab} from "../../tabs";
import "./index.scss";

export const TabContainer = () => {
  const [selectedTab, setSelectedTab] = useState(defaultAccountTab);
  const Component = selectedTab.component;

  const reset = () => setSelectedTab(defaultAccountTab);
  return (
    <div className="bg-white rounded-xl border pb-5">
      <ul className="generic-page-tab">
        {accountTabs.map((tab) => (
          <li
            key={tab.name}
            className={selectedTab.name === tab.name ? "active" : null}
          >
            <button onClick={() => setSelectedTab(tab)}>{tab.name}</button>
          </li>
        ))}
        {selectedTab.headerActions && <>{selectedTab.headerActions}</>}
      </ul>
      <Component
        resetTabSelection={reset}
        setSelectedTab={(tab) => setSelectedTab(tab)}
      />
    </div>
  );
};
