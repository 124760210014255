import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import Header from "../../../../components/Layout/Header/Header";
import {PageContainer} from "../../../../components/Layout/Container/PageContainer";
import {PageHeader} from "../../../../components/Layout/Shared/PageHeader";
import {PageTitle} from "../../../../components/Layout/Shared/PageTitle";
import {HeaderButtons} from "../../../../components/Layout/Shared/PageHeader/HeaderButtons";
import {TabContainer} from "../../../../components/Contacts/TabContainer";
import {CustomButton} from "../../../../components/Layout/Shared/Button";
import {PieChartIcon} from "../../../../assets/icons/icon";
import {ImportContactManager} from "../../../../components/Contacts/ImportContactManager";
import {Statistics} from "../../../../components/Contacts/ContactList/statistics";
import {GenericPageLeft} from "../../../../components/Layout/Shared/GenericPageLeft";
import {ExportContact} from "../../../../components/Contacts/ExportContact";
import BusinessContactContext from "../../../../providers/Contact/business";

const ContactsPage = () => {
  const history = useHistory();
  const goToCreateNewContact = () => history.push(`/business/contact/create`);
  const [showImport, setShowImport] = useState(false);
  const [showMainSection, setShowMainSection] = useState(true);
  const [filter, setFilter] = useState(null);

  const handleRefresh = () => {
    setShowMainSection(false);
    setTimeout(() => {
      setShowMainSection(true);
    }, 10);
  };

  return (
    <BusinessContactContext.Provider
      value={{
        values: {filter},
        setters: {setFilter},
        refresh: handleRefresh,
      }}
    >
      <section className="dashboard  bg-paleGray pb-5 pr-3">
        <Header />
        <div className="container-fluid mt-5 pt-3">
          <div className="row">
            <GenericPageLeft />
            <PageContainer>
              <PageHeader>
                <PageTitle>CONTACTS</PageTitle>
                <HeaderButtons>
                  <CustomButton
                    color="transparent"
                    title="Import Contact"
                    size="200px"
                    onClick={() => setShowImport(true)}
                  />
                  <CustomButton
                    color="blue"
                    title="Add Contact"
                    size="150px"
                    onClick={goToCreateNewContact}
                  />
                  <button className="btn btn-icon">
                    {PieChartIcon} Analytics
                  </button>
                </HeaderButtons>
              </PageHeader>
              {showMainSection && (
                <div className="row bg-white border rounded-xl m-2">
                  <div className="page-left p-0 border-end">
                    <Statistics />
                    <ExportContact></ExportContact>
                  </div>
                  <div className="page-right  pl-0 pe-5  pt-3">
                    <TabContainer refresh={handleRefresh} />
                  </div>
                </div>
              )}
              <ImportContactManager
                show={showImport}
                setShow={setShowImport}
                refresh={handleRefresh}
              />
            </PageContainer>
          </div>
        </div>
      </section>
    </BusinessContactContext.Provider>
  );
};

export default ContactsPage;
