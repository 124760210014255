import {sorterByDay} from "../../../../utils/date";
import moment from "moment";
import "./index.scss";
export const Schedule = ({schedule}) => (
  <div className="schedule">
    <h4>Service Schedule</h4>
    {schedule && (
      <ul>
        {schedule.days && (
          <>
            <li>
              <small>
                <span>Week Days</span> Timings
              </small>
            </li>
            {schedule.days.sort(sorterByDay).map((day, index) => (
              <li key={index}>
                <span>{day.day}</span>
                {day.start} to {day.end}
              </li>
            ))}
          </>
        )}
        {schedule.holidays && (
          <>
            <li>
              <small>
                <span>Holidays</span>
              </small>
            </li>
            {schedule.holidays.map((holiday, index) => (
              <li key={index}>
                <span>{holiday.name}</span>
                {moment(holiday.date).format("MMM DD YYYY")}
              </li>
            ))}
          </>
        )}
      </ul>
    )}
  </div>
);
