import { Fragment } from 'react'
import { getAttribute } from '../../FormEdit/FormEditReducerHelpers'
import ESignatureCanvas from './ESignatureCanvas'

const submitter = {
  id: localStorage.getItem('userId'),
  email: localStorage.getItem('email'),
}

export function ESignaturePreview({ dispatch, attributes, component_key }) {
  const showAssigneesChecked =
    getAttribute('show_assignees_checked', attributes) === 'true'
  const assignees_ids = getAttribute('assignees_ids', attributes)
  const assignees_emails = getAttribute('assignees_emails', attributes)
  const assignees = assignees_ids?.map((assignee_id, index) => ({
    id: assignee_id,
    email: assignees_emails[index],
  }))
  const visible = assignees_ids.filter((id) => id === submitter.id).length > 0

  return (
    <>
      {visible ? (
        <Fragment>
          {showAssigneesChecked ? (
            <Fragment>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#e6e6e6',
                  minHeight: '100px',
                  borderRadius: '10px',
                  marginLeft: '1.3em',
                  width: '91%',
                }}
              >
                <div style={{ marginLeft: '1.3em' }}>
                  <h2 style={{ marginLeft: '0.5em', marginTop: '0.3em' }}>
                    <b>Assignees</b>
                  </h2>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                      marginTop: '1.3em',
                    }}
                  >
                    {assignees.map(({ id, email }, index) => {
                      return (
                        <h3 key={id} style={{ marginLeft: '0.5em' }}>
                          {email}
                        </h3>
                      )
                    })}
                  </div>
                </div>
              </div>
              <br></br>
              <br></br>
            </Fragment>
          ) : null}
          <ESignatureCanvas
            dispatch={dispatch}
            attributes={attributes}
            component_key={component_key}
          />
        </Fragment>
      ) : null}
    </>
  )
}

export default ESignaturePreview
