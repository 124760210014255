import {useHistory} from "react-router-dom";
import {PieChartIcon} from "../../../../assets/icons/icon";
import "./index.scss";
export const ButtonAnalytics = ({color}) => {
  const history = useHistory();
  const goToAnalytics = () => history.push(`/business/analytics`);
  return (
    <button
      onClick={goToAnalytics}
      className={
        color === "red"
          ? "btn btn-icon ms-5  btn-analytics red"
          : "btn btn-icon ms-5  btn-analytics"
      }
    >
      {PieChartIcon} Analytics
    </button>
  );
};
